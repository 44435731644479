import React, { Component } from "react";
import { connect } from "react-redux";
import PDFView from "../../../../common/../common/components/PDFView";
import { procedureConsentLoadInit, setSidebarState } from "../../../../store/actions";
import ConsentSideBar from "./ConsenSidebar";
import ConsentPreCreateDocument from "./ConsentPreCreateDocument";
import { Icon } from "antd";

class Consent extends Component {
  state = {
    isSidebar: true,
    isOpenedCreatePage: false,
  };

  handleSidebar = () => {
    this.setState({ isSidebar: true });
    this.props.setSidebarState(true);
  };

  toggleSidebar = () => {
    this.props.setSidebarState(!this.state.isSidebar);
    this.setState({ isSidebar: !this.state.isSidebar });
  };

  handleCloseSidebar = () => {
    this.setState({ isSidebar: false });
    this.props.setSidebarState(false);
  };

  componentDidMount() {
    const { patientResource } = this.props;
    const patient_id = patientResource?.id;
    this.props.procedureConsentLoadInit(patient_id);
    this.props.setSidebarState(this.state.isSidebar);
  }

  componentWillUnmount() {
    this.props.setSidebarState(false);
  }

  sidebarRef = null;
  constructor(props) {
    super(props);
    this.sidebarRef = React.createRef();
  }

  render() {
    const { isSidebar, isOpenedCreatePage } = this.state;
    const { selectedDocumentData } = this.props;
    return (
      <div className={`clinicWorkflow__main-section`}>
        <div className="eventsContainer has-sub-header">
          <div style={{ width: "100%", height: "100%", paddingTop: "1rem" }}>
            <PDFView style={{ height: "100%" }} url={selectedDocumentData && selectedDocumentData?.fileurl} />
          </div>
        </div>

        {isSidebar && (
          <ConsentSideBar
            sidebarIcon={<Icon type="medicine-box" theme="twoTone" twoToneColor="#198038" />}
            ref={this.sidebarRef}
            isShow={isSidebar}
            handleCloseSidebar={this.handleCloseSidebar}
            openCreatePage={this.openCreatePage}
          />
        )}
        {isOpenedCreatePage && <ConsentPreCreateDocument closeCreatePage={this.closeCreatePage} />}
      </div>
    );
  }

  openCreatePage = () => {
    this.setState({ ...this.state, isOpenedCreatePage: true });
  };

  closeCreatePage = () => {
    this.setState({ ...this.state, isOpenedCreatePage: false });
  };
}

const mapStateToProps = (state) => ({
  patientResource: state.patientSchedule.selectedPatientResource,
  selectedDocumentData: state.procedureConsent.selectedDocumentData,
});

const mapDispatchToProps = {
  procedureConsentLoadInit,
  setSidebarState,
};

export default connect(mapStateToProps, mapDispatchToProps)(Consent);
