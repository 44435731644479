import { Empty, Icon } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import EditableInput from "../../../common/components/EditableText/EditableFormInput";
import { validateField } from "../../../services/validations";
import { getPharmacies, get_plan_patient, processAppointmentMedication, setGlobalLoading, setProcedureData } from "../../../store/actions";
import Loading from "../Loading";
import YesNo from "../YesNo";

import { getLocal } from "../../../helpers/Local";

let timer = null;

const initFre = [
  {
    value: "Qid",
    id: "qid",
  },
  {
    value: "Bid",
    id: "bid",
  },
  {
    value: "Qdaily",
    id: "qdaily",
  },
  {
    value: "Qweekly",
    id: "qweekly",
  },
  {
    value: "Tid",
    id: "tid",
  },
];

const Prescription = ({
  activeRow,
  typePlanId,
  plans,
  handleStep,
  nextSave,
  handleContinue,
  conditions,
  activeStatus,
  is_locked,
  handlePharmacyAdding,
  updateStateFrenquency,
  titlePlan,
  ...restProps
}) => {
  const [state, setState] = useState(null);
  const [localLoading, setLocalLoading] = useState(false);
  const dispatch = useDispatch();
  const current_patient_id = getLocal("current_patient_id");
  const current_appointment_id = getLocal("current_appointment_id");
  const setFirstFC = (index) => {
    dispatch(setProcedureData("timeSlot", index));
  };

  const onHandleClick = async () => {
    if (state?.prescription?.number_of_unit && state?.prescription?.number_of_refill && state?.prescription.pharmacy_id) {
      dispatch(setGlobalLoading(true));
      const params = {
        prescription_id: state?.prescription?.id,
        frequency: state?.prescription?.frequency,
        number_of_unit: state?.prescription?.number_of_unit,
        dispense_as_written: state?.prescription?.dispense_as_written,
        number_of_refill: state?.prescription?.number_of_refill,
        additional_instruction: state?.prescription?.additional_instruction,
        pharmacy_id: state?.prescription.pharmacy_id,
        pharmacy_name: state?.prescription.pharmacy_name,
      };
      await dispatch(processAppointmentMedication(state?.prescription.appointment_id, params));
      const loadSuccess = await dispatch(get_plan_patient(current_patient_id, current_appointment_id));
      if (loadSuccess) {
        if (nextSave) {
          setState(null);
          nextSave(loadSuccess);
          setFirstFC(-1);
        }
      }
      dispatch(setGlobalLoading(false));
    }
  };

  useEffect(() => {
    setState(null);
  }, [activeRow]);

  const loadingPharmacies = useSelector((state) => state.common.loadingPharmacies);
  const pharmacies = useSelector((state) => state.common.pharmacies);

  const memoizedCallback = useCallback(() => {
    setState({
      ...titlePlan,
      dispense: 0,
      numberRe: 0,
    });
  }, [titlePlan]);

  useEffect(() => {
    if (titlePlan) {
      memoizedCallback(titlePlan);
    }
  }, [titlePlan]);

  const handleChange = (type, value) => {
    if (type == "pharmacy_id") {
      let name = "";
      let id = "";
      if (value) {
        const slpitValue = value.split(" - ");
        name = slpitValue[0] || "";
        id = slpitValue[1] || "";
      }
      setState({
        ...state,
        prescription: {
          ...state["prescription"],
          [type]: id ? Number(id) : null,
          ["pharmacy_name"]: name,
        },
      });
    } else if (
      type == "frequency" ||
      type == "dispense_as_written" ||
      type == "number_of_refill" ||
      type == "additional_instruction" ||
      type == "number_of_unit"
    ) {
      setState({
        ...state,
        prescription: {
          ...state["prescription"],
          [type]: value,
        },
      });
      updateStateFrenquency &&
        updateStateFrenquency(
          {
            ...state,
            prescription: {
              ...state["prescription"],
              [type]: value,
            },
          },
          plans
        );
    } else if (type == "route" || type == "form") {
      setState({
        ...state,
        prescription: {
          ...state["prescription"],
          medication: {
            ...state["prescription"]["medication"],
            [type]: value,
          },
        },
      });
    } else {
      setState({
        ...state,
        [type]: value,
      });
    }
  };

  const searchHandle = async (value) => {
    setLocalLoading(true);
    dispatch(setGlobalLoading(true));
    clearTimeout(timer);
    timer = setTimeout(async () => {
      await dispatch(getPharmacies(value));
      setLocalLoading(false);
      dispatch(setGlobalLoading(false));
    }, 500);
  };

  return (
    <>
      <div className="resourceContent">
        <React.Fragment key={`clinic-appointment-${0}`}>
          <div
            className="resourceContent"
            style={{
              flex: "unset",
              paddingLeft: "24px",
              paddingRight: "20px",
            }}
          >
            <div className="resourceInfo d-flex align-items-end required-field">
              <EditableInput
                disabled={is_locked}
                label={"Dose"}
                value={state?.prescription?.medication?.form}
                handleChange={(value) => {
                  handleChange("form", value);
                }}
              />
            </div>
            <div className="resourceInfo d-flex align-items-end required-field">
              <EditableInput
                disabled={is_locked}
                label={"Route"}
                value={state?.prescription?.medication?.route}
                handleChange={(value) => {
                  handleChange("route", value);
                }}
              />
            </div>
            <div className="resourceInfo d-flex align-items-end required-field">
              <EditableInput
                disabled={is_locked}
                label={"Frequency"}
                options={initFre}
                value={state?.prescription?.frequency}
                handleChange={(value) => {
                  handleChange("frequency", value);
                }}
                required={true}
                optionKey={"id"}
                valueKey={"value"}
                type={"check"}
                isError={validateField("text", state?.prescription?.frequency)}
                helperText={"Frequency is Required!"}
              />
            </div>
            <div className="resourceInfo d-flex align-items-end required-field">
              <EditableInput
                disabled={is_locked}
                label={"Number of Units"}
                value={state?.prescription?.number_of_unit}
                handleChange={(value) => {
                  handleChange("number_of_unit", value);
                }}
                isError={validateField("text", state?.prescription?.number_of_unit)}
                enableErrorText={!state?.prescription?.number_of_unit}
                helperText={"Number of Units is Required!"}
                required
              />
            </div>
            <div className="resourceInfo d-flex align-items-end required-field">
              <div className="resourceInfo between half-pannel">
                <div>Dispense as written</div>
                <YesNo
                  disabled={is_locked}
                  option={state?.prescription?.dispense_as_written}
                  setOption={(value) => {
                    handleChange("dispense_as_written", value);
                  }}
                />
              </div>
            </div>
            <div className="resourceInfo d-flex align-items-end required-field">
              <EditableInput
                disabled={is_locked}
                label={"Number of Refills"}
                value={state?.prescription?.number_of_refill}
                handleChange={(value) => {
                  handleChange("number_of_refill", value);
                }}
                isError={validateField("text", state?.prescription?.number_of_refill)}
                enableErrorText={!state?.prescription?.number_of_refill}
                helperText={"Number of Refills is Required!"}
                required
              />
            </div>
            <div className="resourceInfo d-flex align-items-end required-field">
              <EditableInput
                disabled={is_locked}
                minRows={4}
                type={"area"}
                label={"Additional Instrucions"}
                value={state?.prescription?.additional_instruction}
                handleChange={(value) => {
                  handleChange("additional_instruction", value);
                }}
              />
            </div>
            <div className="resourceInfo d-flex align-items-end required-field">
              <EditableInput
                disabled={is_locked}
                label={"Pharmacy"}
                hideIconSelect={true}
                type={"check"}
                value={
                  state?.prescription?.pharmacy_name && state?.prescription?.pharmacy_id
                    ? `${state?.prescription?.pharmacy_name} - ${state?.prescription?.pharmacy_id}`
                    : undefined
                }
                options={pharmacies}
                autoClearSearchValue={false}
                onChangeSearch={(value) => {
                  searchHandle(value);
                }}
                handleChange={(value) => {
                  handleChange("pharmacy_id", value);
                }}
                toGetAll={true}
                disableFilter={true}
                notFoundContent={
                  localLoading || loadingPharmacies ? (
                    <div style={{ height: 100 }}>
                      <Loading />
                    </div>
                  ) : (
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                  )
                }
                optionKey={"id"}
                valueKey={"name"}
                isError={validateField("text", state?.prescription?.pharmacy_name) || validateField("text", state?.prescription?.pharmacy_id)}
                enableErrorText={!state?.prescription?.pharmacy_name || state?.prescription?.pharmacy_id}
                helperText={"Pharmacy is Required!"}
                required
              />
              <span
                style={{
                  width: 18,
                  position: "absolute",
                  left: 415,
                  top: 5,
                  height: "2.5rem",
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={() => {
                  if (typeof handlePharmacyAdding === "function") {
                    handlePharmacyAdding(true);
                  }
                }}
              >
                <Icon type="plus-square" theme="twoTone" twoToneColor="#0f62fe" className="pharmacy-icon" />
              </span>
            </div>
          </div>
        </React.Fragment>
      </div>
      <div className="actionContent" style={{ paddingRight: "10px", marginTop: 0, minHeight: "16px" }}>
        <button
          disabled={is_locked}
          className={`bottom-btn ${is_locked ? "disable-btn" : ""}`}
          onClick={() => {
            onHandleClick();
          }}
        >
          Continue
        </button>
      </div>
    </>
  );
};

export default Prescription;
