import React, { useState, useEffect, useMemo } from "react";
import moment from "moment";
import { SummaryEmptySVG } from "../../../common/components/icons/SVGIcon";
import ScrollWrapper from "../../../common/components/ScrollBar";
import Toastr from "../../../common/components/Toast";
import FormStepper from "../../../common/components/FormStepper";
import Finalize from "../../../common/components/Finalize";
import FirstStepContent from "./FirstStep";
import SecondStepContent from "./SecondStep";
import CommunicationStep from "./CommunicationStep";
import CallAttemptProcedureComponent from "./CallAttemptProcedure";
import Notes from "./Notes";
import { useDispatch, useSelector } from "react-redux";
import { getSingleProcedure, get_patient_notes } from "../../../store/actions";
import { Drawer } from "antd";

const initialCondition = [
  { step: "demographics", status: true },
  { step: "procedure", status: true },
  { step: "communication", status: false },
  { step: "attempt", status: false },
  { step: "notes", status: false },
  { step: "finalize", status: false },
];

const formSteps = [
  {
    title: "Demographics",
    isConfirmed: true,
    content: FirstStepContent,
  },
  {
    title: "Clinic Scheduling",
    isConfirmed: true,
    content: SecondStepContent,
  },
  {
    title: "Communication",
    isConfirmed: true,
    content: CommunicationStep,
  },
  {
    title: "Call Attempts",
    isConfirmed: true,
    content: CallAttemptProcedureComponent,
  },
  {
    title: "Notes",
    isConfirmed: true,
    content: Notes,
  },
  {
    title: "Confirmation",
    isConfirmed: true,
    content: Finalize,
  },
];

const HospitalSideBar = React.forwardRef(({ isShow, handleCloseSidebar, handleNext, selectedType }, ref) => {
  const resource = useSelector((state) => state.procedure.resource);
  const isConfirmed = useSelector((state) => state.procedure.isConfirmed);
  const patientsNote = useSelector((state) => state.newPatient?.patientsNote);
  const call_attempts = useSelector((state) => state.procedure.call_attempts);
  const checkApointment = useSelector((state) => state.common.checkApointment);
  const [check, setCheck] = useState(false);
  const procedure = useSelector((state) => state.procedure.procedure);
  const [conditions, setConditions] = useState(initialCondition);
  const [step, setStep] = useState(0);
  const [title, setTitle] = useState("");
  const [noteCancelValue, setNoteCancelValue] = useState("");
  const [showNote, setShowNote] = useState(false);
  const [timer, setTimer] = useState(0);
  const dispatch = useDispatch();

  const actionShowNote = (value) => {
    setShowNote(value);
  };

  const isToastr = useMemo(() => {
    if (isConfirmed) {
      setTimer(0);
      setTimeout(() => {
        setTimer(600);
      }, 2000);
      return true;
    }
    return false;
  }, [isConfirmed]);

  useEffect(() => {
    setStep(0);
  }, [isShow]);

  useEffect(() => {
    setStep(0);
    if (resource && resource?.patient) {
      const newTitle =
        `${resource?.patient?.first_name || ""} ${resource?.patient?.last_name || ""} ` +
        `(DOB ${resource?.patient?.date_of_birth ? moment(resource?.patient?.date_of_birth).format("MM/DD/YYYY") : ""})` +
        `(OPS ID: ` +
        resource?.patient?.id +
        `)`;
      setTitle(newTitle);
      setShowNote(false);
      setNoteCancelValue("");
      dispatch(get_patient_notes(resource?.patient?.id));
    }
  }, [resource]);

  const handleSubmit = () => {
    if (step === 0) {
      setStep(1);
      handleNext(1);
    } else {
      // Todo: handleSubmit
    }
  };
  const handleStep = (step) => {
    setStep(step);
    handleNext(step);
  };

  useEffect(() => {
    if (resource) {
      const newConditions = [...conditions];
      if (resource?.appointment_date && resource?.appointment_date.length > 0) {
        newConditions[1].status = true;
      } else {
        newConditions[1].status = false;
      }
      if (check) {
        newConditions[2].status = true;
      } else {
        newConditions[2].status = false;
      }
      if (call_attempts && call_attempts.length > 0) {
        newConditions[3].status = true;
      } else {
        newConditions[3].status = false;
      }
      if (patientsNote && patientsNote.length > 0) {
        newConditions[4].status = true;
      } else {
        newConditions[4].status = false;
      }
      setConditions([...newConditions]);
    }
  }, [resource, step, procedure, checkApointment, call_attempts, patientsNote, check]);

  const onCloseStep = async (step) => {
    if (step === 1) {
      await dispatch(getSingleProcedure(resource?.id));
    }
  };

  const setNoteCancelValueCheck = (value) => {
    setNoteCancelValue(value);
  };

  const checkAction = (value) => {
    setCheck(value);
  };

  if (!resource) {
    return (
      <Drawer
        title={(resource?.procedure_type && resource?.procedure_type?.value) || " "}
        placement="right"
        closable
        onClose={handleCloseSidebar}
        visible={isShow}
        destroyOnClose
        key="right"
      >
        <div ref={ref} className="resourceContainer new">
          <div
            className="emptyContentIcon"
            style={{
              width: "100%",
              height: "calc(100% - 160px)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <SummaryEmptySVG />
          </div>
        </div>
      </Drawer>
    );
  }

  return (
    <Drawer
      title={resource?.procedure_name ? resource?.procedure_name : " "}
      placement="right"
      closable
      onClose={handleCloseSidebar}
      visible={isShow}
      destroyOnClose
      key="right"
    >
      <div ref={ref} className="resourceContainer new">
        {isToastr && timer < 600 && (
          <div style={{ position: "fixed", top: "60px", right: "2px" }}>
            <Toastr type="Procedure" status="success" msg="Confirmed Successfully!" />
          </div>
        )}
        <ScrollWrapper>
          <FormStepper
            showNote={showNote}
            setNoteCancelValue={setNoteCancelValueCheck}
            noteCancelValue={noteCancelValue}
            isNotFollowUp={true}
            checkAction={checkAction}
            type="ClinicProcessing"
            actionShowNote={actionShowNote}
            steps={formSteps}
            currentStep={step}
            onCloseStep={onCloseStep}
            conditions={conditions}
            handleStep={handleStep}
            handleContinue={handleSubmit}
            customResource={resource}
            handleCloseSidebar={handleCloseSidebar}
          />
        </ScrollWrapper>
      </div>
    </Drawer>
  );
});

export default HospitalSideBar;
