import React, { useState, useEffect } from "react";
import EditableFormInput from "../../../../common/components/EditableText/EditableFormInput";
import EditableInputWithDiv from "../../../../common/components/EditableText/EditableInputWithDiv";

const ExaminationVitals = (props) => {
  const [data, setData] = useState([]);
  useEffect(() => {
    setData(props.data);
  }, [props.data]);

  const onChangeValue = (index, value) => {
    if (index === 9) return;
    const data_ = [...data];
    data_[index]["value"] = value;
    if (value && value !== "0" && (index === 6 || index === 7)) {
      data_[9]["value"] =
        data_[7]["value"] && data_[7]["value"].replace(/[^0-9]/g, "") !== "0"
          ? (+data_[6]["value"] / (+data_[7]["value"].replace(/[^0-9]/g, "") * 0.0254) ** 2).toFixed(2)
          : (+data_[6]["value"]).toFixed(2);
    }
    setData(data_);
    props.onChangeData(data_);
  };

  const getUnitValue = (type) => {
    if (type === "Temperature") {
      return "℉";
    }
    if (type === "HR") {
      return "BPM";
    }
    if (type === "Resp") {
      return "RR";
    }
    if (type === "SpO2") {
      return "%";
    }
    if (type === "Weight") {
      return "Kg";
    }
    if (type === "Height") {
      return "Inches";
    }
    if (type === "BP (Right)" || type === "BP (Left)") {
      return "mmHg";
    }
    if (type === "Pain Rating") {
      return "/ 10";
    }
    return undefined;
  };

  return (
    <div className="resourceInfo no-padding" style={{ paddingLeft: "0" }}>
      {(data || []).map((item, index) => (
        <div key={`vital-${index}`} className={`resourceInfo d-flex required-field`}>
          {item["type"] !== "BP (Right)" && item["type"] !== "BP (Left)" && (
            <EditableFormInput
              isErrorTextSpacing={true}
              id={
                item["type"] &&
                item["type"]
                  .replaceAll("-[object Object]", "")
                  .replaceAll(/[&\/\\#,+()$~%.'":*?<>{}]/g, "")
                  .replaceAll(" ", "_")
              }
              label={item["type"]}
              value={item["value"]}
              unit={getUnitValue(item["type"])}
              handleChange={(value) => onChangeValue(index, value)}
              required
              isError={!item["value"]}
              enableErrorText={true}
              helperText={`${item["type"]} is required.`}
              disabled={props.isLocked}
            />
          )}
          {(item["type"] === "BP (Right)" || item["type"] === "BP (Left)") && (
            <EditableInputWithDiv
              isErrorTextSpacing={true}
              label={item["type"]}
              id={
                item["type"] &&
                item["type"]
                  .replaceAll("-[object Object]", "")
                  .replaceAll(/[&\/\\#,+()$~%.'":*?<>{}]/g, "")
                  .replaceAll(" ", "_")
              }
              value={item["value"]}
              subLabel={"BP"}
              unit={"mmHg"}
              handleChange={(value) => onChangeValue(index, value)}
              required
              isError={!item["value"]}
              enableErrorText={true}
              helperText={`${item["type"]} is required.`}
              disabled={props.isLocked}
            />
          )}
        </div>
      ))}
    </div>
  );
};

export default React.memo(ExaminationVitals);
