import { createReducer } from "redux-create-reducer";
import moment from "moment";
import AT from "../actionTypes";

const initialState = {
  conditions: [],
  allConditions: [],
  descList: {},
  calList: [],
  saving: false,
  leftRight: {},
  reason_for_visit: "",
  ultrasounds: [],
  obl_hospital_appointments: [],
  has_aaa_condition: false,
};
const LOAD_CONDITION_HPI_UNTRASOUND_SUCCEEDED = `${AT.LOAD_CONDITION_HPI_UNTRASOUND}_SUCCEEDED`;
export default createReducer(initialState, {
  [`${AT.REMOVE_DATA_PATIENT}_SUCCEEDED`]() {
    return {
      conditions: [],
      allConditions: [],
      descList: {},
      saving: false,
      leftRight: {},
      reason_for_visit: "",
      ultrasounds: [],
      obl_hospital_appointments: [],
    };
  },
  [`${AT.RESET_ALL_STATES}_SUCCEEDED`](state, action) {
    return {
      ...initialState,
    };
  },
  [`${AT.HPI_GET_CONDITIONS}_SUCCEEDED`](state, action) {
    const {
      conditions,
      allConditions,
      descList,
      leftRight,
      reason_for_visit,
      ultrasounds,
      obl_hospital_appointments,
      has_aaa_condition,
    } = action?.payload || {};
    return {
      ...state,
      conditions,
      allConditions,
      descList,
      leftRight,
      reason_for_visit,
      ultrasounds,
      obl_hospital_appointments,
      has_aaa_condition,
    };
  },
  [`${AT.HPI_SET_CONDITIONS}`](state, action) {
    const { conditions } = action?.payload || {};
    return {
      ...state,
      conditions,
    };
  },
  [`${AT.HPI_SAVE_CONDITION}_STARTED`](state, action) {
    const { message } = action?.payload || {};
    return {
      ...state,
      saving: true,
    };
  },
  [`${AT.HPI_SAVE_CONDITION}_SUCCEEDED`](state, action) {
    const { leftRight } = action?.payload || {};
    return {
      ...state,
      leftRight: leftRight || {},
      saving: false,
    };
  },
  [LOAD_CONDITION_HPI_UNTRASOUND_SUCCEEDED](state, action) {
    const { calList, descList } = action?.payload;
    return Object.assign({}, state, {
      calList,
      descList,
    });
  },
});
