import React, { useEffect, useMemo, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Input } from "antd";
import { useDebounce } from "use-lodash-debounce";
import moment from "moment";
import { SearchSVG } from "../../../common/components/icons/SVGIcon";
import ScrollWrapper from "../../../common/components/ScrollBar";
import { useWindowSize, useOutsideDetect } from "../../../common/utils";
import {
  getPatientsByQuery,
  setPatientForRecords,
  setPatientHistory,
  setStepData,
  setPatientRecordData,
  getPatientSearchHistory,
  addPatientSearchHistory,
  removeDataPatient,
  get_patient_notes,
  setPatientSnapshotData,
  selectPatient,
  resetStateResource,
  setGlobalLoading,
  remove_patient_on_schedules,
} from "../../../store/actions";
import { setLocal } from "../../../helpers/Local";

const { Search } = Input;

const PatientSearch = () => {
  const ref = useRef(null);
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const history = useHistory();
  const patients = useSelector((state) => state.dashboard.patients);

  const searchHistory = useSelector((state) => state.dashboard.searchHistory);
  const addedSearchHistory = useSelector((state) => state.dashboard.addedSearchHistory);
  const dispatch = useDispatch();

  const [openPanel, setOpen] = useState(false);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [search, setSearch] = useState("");
  const [offset, setOffset] = useState(0);
  const [activeRow, setActiveRow] = useState(-1);
  const [activeSearch, setActiveSearch] = useState(-1);
  const [activeSearchRow, setActiveSearchRecords] = useState(null);
  const [initialSize, setSize] = useState({
    width: undefined,
    height: undefined,
  });

  const headerLabels = ["Patient Name", "DOB", "City", "Last Accessed"];

  const debouncedValue = useDebounce(search, 400);

  useOutsideDetect(ref, ref1, openPanel, setOpen);

  useEffect(() => {
    dispatch(getPatientSearchHistory());
  }, []);

  useEffect(() => {
    if (addedSearchHistory) {
      dispatch(getPatientSearchHistory());
    }
  }, [addedSearchHistory]);

  const fechSearch = async () => {
    if (debouncedValue && openPanel && !loadingSearch) {
      setLoadingSearch(true);
      await dispatch(getPatientsByQuery(search));
      setLoadingSearch(false);
    }
  };

  useEffect(() => {
    fechSearch();
  }, [debouncedValue]);

  useEffect(() => {
    if (ref2) {
      const initialSize = {
        width: ref2.current.clientWidth,
        height: ref2.current.clientHeight,
      };
      setSize(initialSize);
    }
  }, [ref2]);

  const tableRows = useMemo(() => {
    if (patients && patients.length > 0) {
      setOffset(0);
      setActiveSearch(0);
      return patients;
    }
    return [];
  }, [patients]);

  const rowData = useMemo(() => {
    if (!tableRows || !tableRows.length) {
      return [];
    }
    if (tableRows && tableRows.length && offset <= tableRows.length) {
      const count = Math.min(15, tableRows.length - offset);
      return tableRows.slice(0, offset + count);
    }
  }, [offset, tableRows]);

  let emptyRows = [];
  const size = useWindowSize(ref2);

  let rowCount = 0;
  if (!size.height && !initialSize.height) {
    rowCount = 5;
  }
  if (size.height) {
    rowCount = Math.floor(size.height / (16 * 2.5));
  } else {
    rowCount = Math.floor(initialSize.height / (16 * 2.5));
  }

  if (rowCount > (searchHistory ? searchHistory.length : 0)) {
    emptyRows = new Array(rowCount - (searchHistory ? searchHistory.length : 0)).fill({});
  }

  const handleSearch = (e) => {
    setSearch(e.target?.value);
    setOpen(true);
  };

  const handleSelect = async (row, index) => {
    dispatch(setGlobalLoading(true));
    await setActiveRow(index);
    await dispatch(removeDataPatient());
    await dispatch(resetStateResource());
    await dispatch(remove_patient_on_schedules());
    await dispatch(setPatientForRecords(row?.patient_id || row?.id));
    const check = await dispatch(selectPatient(row?.patient_id || row?.id));
    await dispatch(setPatientSnapshotData("resource", check));
    await dispatch(get_patient_notes(row?.patient_id || row?.id));
    await setLocal("current_patient_id", row?.patient_id);
    dispatch(setGlobalLoading(false));
    if (check) {
      history.push("/patient-records/clinical");
    }
  };

  const handleSearchSelect = (row, index) => {
    setLocal("current_patient_id", row?.id);
    setActiveSearch(index);
    setActiveSearchRecords(row);
    setSearch(
      `${row?.first_name || ""} ${row?.last_name || ""} - ${
        row?.date_of_birth && moment(row?.date_of_birth).format("MM/DD/YYYY")
      }`,
    );
    setOpen(false);

    handleAddHistory(row);
  };

  const handleAddHistory = async (searchRow = null) => {
    if (activeSearchRow) {
      setActiveRow(0);
      dispatch(setStepData("resource", null));
      dispatch(setPatientRecordData("resource", null));
      dispatch(setPatientForRecords(activeSearchRow?.id));
      const check = await dispatch(selectPatient(activeSearchRow?.id));
      await dispatch(setPatientSnapshotData("resource", check));
      dispatch(get_patient_notes(activeSearchRow?.id));
      dispatch(addPatientSearchHistory(activeSearchRow?.id));
      dispatch(setPatientHistory(activeSearchRow));
      setActiveSearchRecords(null);
      history.push("/patient-records/clinical");
    } else if (searchRow) {
      setActiveRow(0);
      dispatch(setStepData("resource", null));
      dispatch(setPatientRecordData("resource", null));
      dispatch(setPatientForRecords(searchRow?.id));
      const check = await dispatch(selectPatient(searchRow?.id));
      await dispatch(setPatientSnapshotData("resource", check));
      dispatch(get_patient_notes(searchRow?.id));
      dispatch(addPatientSearchHistory(searchRow?.id));
      dispatch(setPatientHistory(searchRow));
      setActiveSearchRecords(null);
      history.push("/patient-records/clinical");
    }
  };

  const onScroll = (values) => {
    if (values.scrollTop > rowData?.length * 20 && offset < tableRows.length) {
      const count = Math.min(15, tableRows.length - offset);
      setOffset(offset + count);
    }
  };

  const handleKeyPress = async () => {
    if (activeSearchRow) {
      const check = await dispatch(selectPatient(activeSearchRow?.id));
      await dispatch(setPatientSnapshotData("resource", check));
      setActiveRow(0);
      dispatch(setStepData("resource", null));
      dispatch(setPatientRecordData("resource", null));
      dispatch(setPatientForRecords(activeSearchRow?.id));
      dispatch(get_patient_notes(activeSearchRow?.id));
      dispatch(addPatientSearchHistory(activeSearchRow?.id));
      dispatch(setPatientHistory(activeSearchRow));
      setActiveSearchRecords(null);
      setOpen(false);
      history.push("/patient-records/clinical");
    }
  };

  const onKeyDown = (type) => {
    if (type == "down") {
      if (rowData && rowData[activeSearch + 1]) {
        setLocal("current_patient_id", rowData[activeSearch + 1].id);
        setActiveSearchRecords(rowData[activeSearch + 1]);
        setActiveSearch(activeSearch + 1);
      }
    } else {
      setActiveSearch(activeSearch - 1);
      setActiveSearchRecords(rowData[activeSearch - 1]);
      setLocal("current_patient_id", rowData[activeSearch - 1].id);
    }
  };

  return (
    <div className="patientSearch">
      <div className="patientSearch__filter">
        <div className="patientSearch__filter-search">
          <Search
            value={search || null}
            onKeyDownCapture={(e) => {
              if (e.keyCode == 40) {
                onKeyDown("down");
              } else if (e.keyCode == 38) {
                onKeyDown("up");
              }
            }}
            onPressEnter={handleKeyPress}
            placeholder="Search Patient"
            onChange={(value) => {
              handleSearch(value);
            }}
            style={{ width: "100%" }}
            suffix={<SearchSVG />}
            onFocus={() => setOpen(true)}
          />
        </div>
      </div>
      {openPanel &&
        search &&
        rowCount !== NaN &&
        rowData &&
        rowData?.length > 0 &&
        tableRows &&
        tableRows.length > 0 && (
          <div
            ref={ref1}
            className="fullContainer patientSearch__result"
            style={
              rowData && rowData?.length <= rowCount - 1
                ? {
                    height: "auto",
                    maxHeight: "unset",
                  }
                : {}
            }
          >
            <div
              className="tableSection"
              style={{
                height: rowData && rowData?.length <= rowCount - 1 ? "auto" : "100%",
                position: "relative",
              }}
            >
              <div className="tableContainer">
                <ScrollWrapper onScrollFrame={onScroll} disableScroll={rowData && rowData?.length <= rowCount - 1}>
                  <div className="tableList">
                    {rowData?.map((row, index) => (
                      <div
                        className={`tableItem ${index === activeSearch ? "active" : ""}`}
                        key={`resultTable-${index}`}
                        onClick={() => handleSearchSelect(row, index)}
                      >
                        <div
                          className="td with-icon"
                          style={{
                            width: "100%",
                            paddingLeft: "calc(1.75rem - 2px)",
                          }}
                        >
                          <p>{`${
                            (row?.first_name || "").replaceAll(
                              /\S*/g,
                              (word) => `${word.slice(0, 1)}${word.slice(1).toLowerCase()}`,
                            ) || ""
                          } ${
                            row?.last_name.replaceAll(
                              /\S*/g,
                              (word) => `${word.slice(0, 1)}${word.slice(1).toLowerCase()}`,
                            ) || ""
                          } - ${row?.date_of_birth && moment(row?.date_of_birth).format("MM/DD/YYYY")}`}</p>
                        </div>
                      </div>
                    ))}
                  </div>
                </ScrollWrapper>
              </div>
            </div>
          </div>
        )}
      <div className="fullContainer" style={{ padding: "10px 0 0 0", position: "relative" }}>
        <div className="tableSection" style={{ height: "100%" }}>
          <div ref={ref2} className="tableContainer">
            <div className="tableHeader">
              {headerLabels.map((label, index) => (
                <div className="head-cell" key={index}>
                  <span>{label}</span>
                </div>
              ))}
            </div>
            <ScrollWrapper css="no-padding">
              <div className="tableList" style={{ minWidth: "560px" }}>
                {(searchHistory || []).map((row, index) => (
                  <div
                    className={`tableItem ${index === activeRow ? "active" : ""}`}
                    key={`resultTable-${index}`}
                    onClick={() => handleSelect(row, index)}
                  >
                    <div className="td with-icon" style={{ width: "30%" }}>
                      {row?.gender && row?.gender.toLowerCase() === "male" ? (
                        <div
                          style={{
                            width: "16px",
                            height: "16px",
                            display: "flex",
                            alignItems: "center",
                            marginRight: "10px",
                          }}
                        >
                          <img
                            src={require("../../../common/components/icons/male.svg")}
                            alt="maleSvgIcon"
                            style={{ width: "100%" }}
                          />
                        </div>
                      ) : (
                        <div
                          style={{
                            width: "16px",
                            height: "16px",
                            display: "flex",
                            alignItems: "center",
                            marginRight: "10px",
                          }}
                        >
                          <img
                            src={require("../../../common/components/icons/female.svg")}
                            alt="femaleSvgIcon"
                            style={{ width: "100%" }}
                          />
                        </div>
                      )}
                      <p>{`${row?.patient_name || ""}`}</p>
                    </div>
                    <div className="td" style={{ width: "20%" }}>
                      <p>{row?.date_of_birth && moment(row?.date_of_birth).format("MM/DD/YYYY")}</p>
                    </div>
                    <div className="td" style={{ width: "20%" }}>
                      <p>{row?.address}</p>
                    </div>
                    <div className="td" style={{ width: "30%" }}>
                      <p>{row?.date && moment(row?.date).format("MM/DD/YYYY hh:mm A")}</p>
                    </div>
                  </div>
                ))}
                {(emptyRows || []).map((row, index) => (
                  <div className={`tableItem`} key={`empty-resultTable-${index}`}>
                    <div className="td with-icon" style={{ width: "30%" }} />
                    <div className="td" style={{ width: "20%" }} />
                    <div className="td" style={{ width: "30%" }} />
                    <div className="td" style={{ width: "20%" }} />
                  </div>
                ))}
              </div>
            </ScrollWrapper>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PatientSearch;
