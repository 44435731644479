import { Icon } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLocal } from "../../../helpers/Local";
import { getClinicWorkflowAppointmentsLocalApi } from "../../../services/api.services";
import { getClinicWorkflowAppointments, setGlobalLoading } from "../../../store/actions";
import DataTable from "../../components/DataTable/DataTable";
import { RedCloseSVG, RedRequiredCircleSVG } from "../../components/icons/SVGIcon";

const Appointment = ({ data, title, display, sidebarIcon }) => {
  const dispatch = useDispatch();
  const appointments = useSelector((state) => state.procedure.patientProcedures);
  const patient_id = getLocal("current_patient_id");

  const fetchAPI = async () => {
    setTableLoads([]);
    await dispatch(setGlobalLoading(true));
    await dispatch(
      getClinicWorkflowAppointments({
        patient_id: patient_id,
        paging: 1,
      })
    );
    await dispatch(setGlobalLoading(false));
  };

  const [tableLoad, setTableLoads] = useState([]);
  const [page, setPage] = useState(1);
  const [offLoad, setOffLoad] = useState(true);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchAPI();
  }, []);

  const loadMore = async (values) => {
    const { scrollTop, scrollHeight, clientHeight } = values;
    const pad = 30; // px of the bottom
    const t = (scrollTop + pad) / (scrollHeight - clientHeight);
    if (t >= 1 && Number.isFinite(t) && !loading) {
      if (offLoad) {
        setLoading(true);
        dispatch(setGlobalLoading(true));
        let listUpdate = null;
        listUpdate = await getClinicWorkflowAppointmentsLocalApi({
          types: "clinic,ultrasound,angio",
          appointment_status: "review",
          paging: page + 1,
          with_relations: ["location", "provider", "procedure_type", "ultrasound_type", "patient", "readingPhysician"],
        });

        if (listUpdate) {
          if (listUpdate && listUpdate.length > 0) {
            setTableLoads([...tableLoad, ...listUpdate]);
            setPage(page + 1);
            setLoading(false);
            dispatch(setGlobalLoading(false));
          } else {
            for (let i = 0; i < 7; i++) {
              tableLoad.push({});
            }
            setTableLoads([...tableLoad]);
            setLoading(false);
            setOffLoad(false);
            dispatch(setGlobalLoading(false));
          }
        } else {
          dispatch(setGlobalLoading(false));
        }
      }
    }
  };

  useEffect(() => {
    setTableLoads([...appointments]);
    setLoading(false);
  }, [appointments]);

  const handleRow = (row, index) => () => {};

  const toTitleCase = (s) => {
    if (typeof s === "string" && s.length > 0) {
      const words = s.split(" ");
      if (Array.isArray(words) && words.length > 0) {
        if (words.length === 1) {
          const word = words[0];
          const matches = word.charAt(0).match(/\w+/i);
          const lines = word.split("\n");
          if (Array.isArray(lines) && lines.length > 1) {
            return lines
              .map((line) => {
                return toTitleCase(line);
              })
              .join("\n");
          } else if (Array.isArray(matches)) {
            return word
              .split("")
              .map((c, i) => {
                if (i === 0) {
                  return c.toUpperCase();
                }
                return c.toLowerCase();
              })
              .join("");
          } else {
            return word.charAt(0).concat(toTitleCase(word.slice(1)));
          }
        } else {
          return words.map((word) => toTitleCase(word)).join(" ");
        }
      }
    }
    return "";
  };

  const getAppointmentType = (row) => {
    if (row && row.procedure_name) {
      if (row.codes && row.codes.length > 1) {
        return `${row.procedure_name} (${row?.codes})`;
      } else return `${row.procedure_name}`;
    } else {
      if (row.codes && row.codes.length > 1) {
        return toTitleCase(row.type) + ` (` + row.codes + `)`;
      } else return toTitleCase(row.type);
    }
  };

  const getRowChargeDate = (row) => {
    if (row && row.charge_date) {
      return moment(row.charge_date).format("MM/DD/YYYY hh:mm A");
    } else {
      if (row.appointment_status == "billed") {
        return "Billed";
      }
    }
  };

  return (
    <div className="clinicWorkflow__main-section">
      <div className="eventsContainer has-sub-header">
        <div className="fullContainer no-padding-right">
          <div className="tableSection">
            <div className="tableContainer">
              <DataTable
                isBorderThick={true}
                title="appointment_table"
                tableWidth={1060}
                labels={["Date and Time", "Appointment Status", "Appointment Type (CPT Code)", "ICD Code", "Location", "Bill Date"]}
                widths={["15%", "17%", "15%", "18%", "15%", "20%"]}
                columns={[
                  { sortable: false, key: "date_time" },
                  { sortable: false, key: "appointment_status" },
                  { sortable: false, key: "cpt_code" },
                  { sortable: false, key: "icd_code" },
                  { sortable: false, key: "location" },
                  { sortable: false, key: "bill_date" },
                ]}
                sorts={["", "", "", "", ""]}
                rowData={(tableLoad || []).map((row, index) => ({
                  id: row?.id,
                  date_time: (
                    <div
                      style={{
                        display: "flex",
                        paddingLeft: "0.25rem",
                        justifyContent: "flex-start",
                        alignItems: "center",
                      }}
                    >
                      {row.appointment_status === "billed" && (
                        <div className="has-tooltip">
                          {" "}
                          <Icon type="check-circle" theme="twoTone" twoToneColor="#24A148" />
                          <span className="tooltip tooltip-without-border" role="tooltip">
                            Billed
                          </span>
                        </div>
                      )}
                      {((row.appointment_status === "scheduled" && row.is_auto_generated !== 1) || row.appointment_status === "review") && (
                        <div className="has-tooltip">
                          {" "}
                          <Icon type="warning" theme="twoTone" twoToneColor="#FF832B" />
                          <span className="tooltip tooltip-without-border" role="tooltip">
                            Scheduled
                          </span>
                        </div>
                      )}
                      {row.appointment_status === "scheduled" && row.is_auto_generated === 1 && (
                        <div className="has-tooltip">
                          {" "}
                          <Icon type="warning" theme="twoTone" twoToneColor="#EDD15C" />
                          <span className="tooltip tooltip-without-border" role="tooltip">
                            Auto Scheduled
                          </span>
                        </div>
                      )}
                      {row.appointment_status === "cancelled" && (
                        <div className="has-tooltip">
                          {" "}
                          <Icon type="question-circle" theme="twoTone" twoToneColor="#6929C4" />
                          <span className="tooltip tooltip-without-border" role="tooltip">
                            Cancelled
                          </span>
                        </div>
                      )}
                      {row.appointment_status === "checked-in" && (
                        <div className="has-tooltip">
                          {" "}
                          <Icon type="question-circle" theme="twoTone" twoToneColor="#DA1E28" />
                          <span className="tooltip tooltip-without-border" role="tooltip">
                            Checked In
                          </span>
                        </div>
                      )}
                      {(row.appointment_status === "no_show" || row.appointment_status === "no-answer") && (
                        <div className="has-tooltip" style={{ height: "14px" }}>
                          {" "}
                          <RedCloseSVG />
                          <span className="tooltip tooltip-without-border" role="tooltip">
                            No Show
                          </span>
                        </div>
                      )}
                      {row.appointment_status === "confirmed" && (
                        <div className="has-tooltip">
                          {" "}
                          <RedRequiredCircleSVG />
                          <span className="tooltip tooltip-without-border" role="tooltip">
                            Confirmed
                          </span>
                        </div>
                      )}
                      {(row.appointment_status === "initial" || !row.appointment_status) && <span style={{ minWidth: "26px" }}></span>}
                      {row.appointment_status === "submitting" && (
                        <div className="has-tooltip">
                          {" "}
                          <Icon type="clock-circle" theme="twoTone" twoToneColor="#EDD15C" />
                          <span className="tooltip tooltip-without-border" role="tooltip">
                            Submitting
                          </span>
                        </div>
                      )}
                      <p style={{ paddingLeft: "0.75rem" }}>{row.appointment_date && moment(row.appointment_date).format("MM/DD/YYYY hh:mm A")}</p>
                    </div>
                  ),
                  appointment_status: toTitleCase(row.appointment_status),
                  cpt_code: getAppointmentType(row),
                  icd_code: row.icd_code,
                  location: row.location && row.location?.value,
                  bill_date: getRowChargeDate(row),
                }))}
                fetchList={fetchAPI}
                loadMore={loadMore}
                handleClickRow={handleRow}
                defaultActive={0}
                disableDelete={true}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Appointment;
