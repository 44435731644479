import { DatePicker, Select, TimePicker } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DeleteIconButton from "../../../common/components/icons/DeleteIconButton";
import { ExpandSVG } from "../../../common/components/icons/SVGIcon";
import SubDataTable from "../../../common/components/SubDataTable";
import {
  addProcedureCallAttempt,
  deleteProcedureCallAttempt,
  getProcedureCallAttempt,
  getUltrasoundCallback,
  getUltrasoundPending,
  setGlobalLoading,
} from "../../../store/actions";

const callResult = [
  {
    id: "call_no_answer",
    value: "Call No Answer",
  },
  {
    id: "left_message",
    value: "Left Message",
  },
];

const CallAttemptProcedureComponent = ({ handleContinue, ...restProps }) => {
  const resource = useSelector((state) => state.newPatient.resource);
  const attempt = useSelector((state) => state.procedure.call_attempts);

  const dispatch = useDispatch();

  const [active, setActive] = useState(-1);
  const [attempts, setAttemps] = useState([]);

  const tableHeader = [
    {
      title: "Date",
      width: "30%",
    },
    {
      title: "Time",
      width: "30%",
    },
    {
      title: "Call Results",
      width: "40%",
    },
  ];

  useEffect(() => {
    if (attempt) {
      const dataTableRows = (attempt || []).map((ca) => {
        let call_result = "";
        if (ca.status === "call_no_answer") {
          call_result = "call_no_answer";
        } else if (ca.status === "left_message") {
          call_result = "left_message";
        } else {
          call_result = "Success";
        }
        return {
          posted: true,
          call_result,
          id: ca?.id,
          contact_date: ca?.message_datetime && moment(ca?.message_datetime).format("MM/DD/YYYY"),
          contact_time: ca?.message_datetime && moment(ca?.message_datetime).format("hh:mm A"),
        };
      });
      setAttemps(dataTableRows);
    }
  }, [attempt]);

  const handleAddNew = () => {
    const newAttempt = {
      call_result: "",
      contact_date: moment(new Date()).format("MM/DD/YYYY"),
      contact_time: moment(new Date()).format("hh:mm A"),
      posted: false,
    };
    setAttemps([...attempts, newAttempt]);
    setActive(-1);
  };

  const handleChangeCallAttempt = (type, value, index) => {
    const attemptsToUpdate = [...attempts];
    attemptsToUpdate[index][type] = value;
    setAttemps(attemptsToUpdate);
  };

  const postAttempts = async () => {
    setActive(-1);
    const { customResource, type } = restProps;
    const patientId = customResource && customResource.patient ? customResource.patient?.id : resource?.id;
    const arrayCheck = [...attempts];
    const activeRow = arrayCheck[active];
    if (activeRow && activeRow["posted"] == false && activeRow["contact_date"] && activeRow["contact_time"] && activeRow["call_result"]) {
      await dispatch(setGlobalLoading(true));
      const saveSuccess = await dispatch(
        addProcedureCallAttempt({
          patient_id: parseInt(patientId),
          procedure_id: customResource?.id ? customResource?.id : null,
          contact_date: activeRow["contact_date"],
          contact_time: activeRow["contact_time"],
          call_result: activeRow["call_result"],
        })
      );
      if (saveSuccess) {
        await dispatch(getUltrasoundCallback({}));
        await dispatch(getUltrasoundPending({}));
        await dispatch(getProcedureCallAttempt(customResource?.id));
      }
      await dispatch(setGlobalLoading(false));
    }
    if (
      activeRow &&
      activeRow["posted"] == true &&
      activeRow["contact_date"] &&
      activeRow["contact_time"] &&
      activeRow["call_result"] &&
      activeRow["id"]
    ) {
      await dispatch(setGlobalLoading(true));
      await dispatch(getProcedureCallAttempt(customResource?.id));
      await dispatch(setGlobalLoading(false));
    }
  };

  useEffect(() => {
    if (attempts && attempts.length > 0 && active >= 0) {
      let timer = null;
      clearTimeout(timer);
      timer = setTimeout(async () => {
        postAttempts();
      }, 500);
    }
  }, [attempts]);

  const [activeDelete, setActiveDelete] = useState();

  const handleDeleteRow = async (index, row) => {
    const { customResource, type } = restProps;
    if (activeDelete != index) {
      setActiveDelete(index);
    }
    if (activeDelete == index) {
      await dispatch(setGlobalLoading(true));
      await dispatch(deleteProcedureCallAttempt(row?.id));
      await dispatch(getUltrasoundCallback({}));
      await dispatch(getUltrasoundPending({}));
      await dispatch(getProcedureCallAttempt(customResource?.id));
      await dispatch(setGlobalLoading(false));
      setActiveDelete(-1);
      setActive(-1);
    }
  };

  const getCallResults = (id) => {
    const { value } = callResult.find((el) => el?.id === id);
    return value;
  };

  return (
    <div className="resourceContent pr-7px">
      <div
        className="resourceInfo d-flex"
        style={{
          justifyContent: "space-between",
        }}
      >
        <b>Call Attempt Log</b>
        <button className="text-btn" onClick={handleAddNew}>
          Add +
        </button>
      </div>
      <div className="resourceInfo d-flex">
        <SubDataTable
          title={
            <div style={{ width: "100%", height: "100%", display: "flex" }}>
              {tableHeader?.map(({ title, width }, index) => (
                <div
                  key={index}
                  style={{
                    width,
                    minWidth: width,
                    height: "100%",
                    paddingLeft: index !== 0 ? 11 : "",
                    color: "rgba(0, 0, 0, 0.65)",
                    fontSize: 14,
                    fontWeight: 600,
                    borderTop: "1px solid rgb(223, 227, 230)",
                    borderLeft: index !== 0 ? "1px solid rgb(223, 227, 230)" : "",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {title}
                </div>
              ))}
            </div>
          }
          handleRowClick={setActive}
          data={attempts.map((row, index) => {
            return {
              date: !!row?.posted ? (
                <div className="non-editable-column">{row?.contact_date}</div>
              ) : (
                <DatePicker
                  className={!row?.contact_date && "input-error"}
                  value={row?.contact_date ? moment(row?.contact_date) : null}
                  format="MM/DD/YYYY"
                  placeholder="Date"
                  onChange={(_, dateString) => {
                    handleChangeCallAttempt("contact_date", dateString, index);
                  }}
                  style={{
                    maxWidth: "calc(100% - 5px)",
                    width: "calc(100% - 5px)",
                    marginRight: "5px",
                    borderBottom: "none",
                  }}
                  suffixIcon={<ExpandSVG />}
                />
              ),
              time: !!row?.posted ? (
                <div className="non-editable-column">{row?.contact_time}</div>
              ) : (
                <TimePicker
                  className={!row?.contact_time && "input-error"}
                  format="h:mm A"
                  use12Hours
                  placeholder="Time"
                  style={{
                    maxWidth: "calc(100% - 5px)",
                    width: "calc(100% - 5px)",
                    marginRight: "5px",
                  }}
                  suffixIcon={<ExpandSVG />}
                  value={row?.contact_time ? moment(row?.contact_time, "h:mm A") : null}
                  onChange={(_, timeString) => {
                    handleChangeCallAttempt("contact_time", timeString, index);
                  }}
                />
              ),
              callResult: (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div style={{ flex: 1, paddingRight: 10, width: "80%" }}>
                    {!!row?.posted ? (
                      <div className="non-editable-column">{getCallResults(row?.call_result)}</div>
                    ) : (
                      <Select
                        className={!row?.call_result && "input-error"}
                        suffixIcon={<ExpandSVG />}
                        allowClear={true}
                        value={row?.call_result}
                        placeholder="Call Results"
                        style={{
                          boxShadow: "none",
                          maxWidth: "calc(100% - 5px)",
                          width: "calc(100% - 5px)",
                          marginRight: "5px",
                        }}
                        onChange={(val) => handleChangeCallAttempt("call_result", val, index)}
                      >
                        {(callResult || []).map(({ id, value }, index) => {
                          return (
                            <Select.Option style={{ width: "100%" }} key={`${id}-${index}`} value={id}>
                              {value}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    )}
                  </div>
                  <DeleteIconButton
                    handleClick={() => handleDeleteRow(index, row)}
                    selected={activeDelete === index}
                    icon={"can"}
                    extraStyle={{
                      marginRight: "10px",
                    }}
                  />
                </div>
              ),
            };
          })}
          widths={["30%", "30%", "40%"]}
          options={["date", "time", "callResult"]}
        />
      </div>
    </div>
  );
};

export default CallAttemptProcedureComponent;
