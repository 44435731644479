import { Drawer, Icon } from "antd";
import { debounce } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import EditableInput from "../../../../common/components/EditableText/EditableFormInput";
import ScrollWrapper from "../../../../common/components/ScrollBar";
import ToastMessage from "../../../../common/components/Toast";
import { validateField } from "../../../../services/validations";

import { autoZip, updatePatient, updatePrimaryProviderDetail } from "../../../../store/actions";

import AddUserDetailNew from "./AddUserDetailNew";

let timer = null;
const InActiveSidebarNew = ({
  resource,
  isShow,
  handleCloseSidebar,
  usersDetail,
  updatePropsListPCPIn,
  deletePropsListPCPIn,
  updatePropsListPatientIn,
  deletePropsListPatientIn,
  type,
}) => {
  const [isToastr, setIsToastr] = useState(false);
  const [enableError, setEnableError] = useState(false);
  const [reset, setReset] = useState(true);
  const [isEnable, setIsEnable] = useState(false);
  const [isAddForm, setIsAddForm] = useState(false);
  const zipAutoData = useSelector((state) => state.common.zipAuto);
  const sideNavStatus = useSelector((state) => state.common.sideNavStatus);
  const [isNewPassword, setIsNewPassword] = useState(false);
  const [isReset, setIsReset] = useState(false);
  const [state, setState] = useState({});
  const [first, setFirst] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (usersDetail && usersDetail?.id !== state?.id) {
      fetchState();
      setReset(true);
      setIsReset(false);
      setTimeout(() => {
        setIsReset(true);
      }, 1000);
    }
  }, [usersDetail]);

  useEffect(() => {
    setFirst(true);
  }, []);

  useEffect(() => {
    if (reset) {
      setReset(false);
      setEnableError(false);
    }
  }, [reset]);

  const openAddForm = () => {
    setIsAddForm(true);
  };
  const closeAddForm = () => {
    setIsAddForm(false);
  };

  const fetchState = () => {
    if (type == "referring") {
      setState({
        id: usersDetail?.id,
        name: usersDetail?.full_name || usersDetail?.name,
        first_name: usersDetail?.first_name,
        last_name: usersDetail?.last_name,
        fax_number: usersDetail?.fax_number,
        email_address: usersDetail?.email_address,
        suite: usersDetail?.suite,
        state: usersDetail?.state,
        city: usersDetail?.city,
        zipcode: usersDetail?.zipcode,
        login: usersDetail?.username || "",
        status: usersDetail?.status,
        address: usersDetail?.address,
        phone_number: usersDetail?.phone_number,
      });
    } else {
      setState({
        id: usersDetail?.id,
        date_of_birth: usersDetail?.date_of_birth,
        gender: usersDetail?.gender,
        phone_number: usersDetail?.phone || usersDetail?.phone_number,
        email_address: usersDetail?.email || usersDetail?.email_address,
        name: usersDetail?.full_name || usersDetail?.name,
        first_name: usersDetail?.first_name,
        last_name: usersDetail?.last_name,
        suite: usersDetail?.suite,
        state: usersDetail?.state,
        city: usersDetail?.city,
        zipcode: usersDetail?.zipcode,
        login: usersDetail?.username || "",
        status: usersDetail?.status,
        address: usersDetail?.address,
      });
    }
    setIsEnable(!!usersDetail?.status);
  };

  const saveAll = async (value) => {
    if (type == "referring") {
      clearTimeout(timer);
      timer = setTimeout(async () => {
        if (value && value?.id) {
          const params = {
            ...value,
            username: value?.login,
            status: 1,
          };
          const saveSuccess = await dispatch(updatePrimaryProviderDetail(params?.id, params));
          if (saveSuccess) {
            if (updatePropsListPCPIn) {
              updatePropsListPCPIn(params?.id, params);
            }
            setIsToastr(true);
            setTimeout(() => {
              setIsToastr(false);
            }, 2000);
          }
        }
      }, 500);
    }
    if (type == "patient") {
      clearTimeout(timer);
      timer = setTimeout(async () => {
        if (value && value?.id) {
          const params = {
            ...value,
            phone: value.phone_number,
            email: value.email_address,
            status: 1,
          };
          const saveSuccess = await dispatch(updatePatient(params));
          if (saveSuccess) {
            if (updatePropsListPatientIn) {
              updatePropsListPatientIn(params?.id, {
                params,
              });
            }
            setIsToastr(true);
            setTimeout(() => {
              setIsToastr(false);
            }, 2000);
          }
        }
      }, 500);
    }
  };

  const changeHandler = useCallback(
    debounce((value) => saveAll(value), 400),
    []
  );

  const handleChange = (type, value) => {
    if (type == "zipcode") {
      clearTimeout(timer);
      timer = setTimeout(async () => {
        await dispatch(autoZip(value));
      }, 500);
      const newValue = {
        ...state,
        [type]: value,
      };
      setState(newValue);
    } else {
      const newValue = {
        ...state,
        [type]: value,
      };
      setState(newValue);
      if (!isAddForm) {
        isReset && changeHandler(newValue);
      }
    }
  };

  useEffect(() => {
    if (zipAutoData && Object.keys(zipAutoData).length > 0 && first && !isAddForm) {
      if (zipAutoData.success) {
        if (zipAutoData.addresses && zipAutoData.addresses[0]) {
          const address = zipAutoData.addresses[0];
          const param = {
            ...state,
            state: address.state_id,
            city: address.place,
          };
          setState(param);
          saveAll(param);
          setReset(true);
        }
      } else {
        const param = {
          ...state,
          state: undefined,
          city: undefined,
        };
        setState(param);
        saveAll(param);
        setReset(true);
      }
    }
  }, [zipAutoData]);

  const generatePassword = () => {
    setIsNewPassword(true);
    const randomPassword = Math.random().toString(36).substring(2, 10);
    const newValue = {
      ...state,
      password: randomPassword,
    };
    setState(newValue);
  };

  const updateProvider = () => {
    if (!isAddForm) {
      isReset && changeHandler({ ...state });
      setIsNewPassword(false);
    }
  };

  const enableClickUser = async () => {
    if (type == "referring") {
      const disableSuccess = await dispatch(
        updatePrimaryProviderDetail(state?.id, {
          ...state,
          status: 1,
        })
      );
      if (disableSuccess) {
        if (deletePropsListPCPIn) {
          deletePropsListPCPIn(state?.id, state);
        }
        setIsToastr(true);
        setTimeout(() => {
          setIsToastr(false);
          handleCloseSidebar();
          setState({});
        }, 1000);
      }
    }
    if (type == "patient") {
      const disableSuccess = await dispatch(updatePatient({ ...state, status: 0 }));
      if (disableSuccess) {
        if (deletePropsListPatientIn) {
          deletePropsListPatientIn(state?.id, state);
        }
        setIsToastr(true);
        setTimeout(() => {
          setIsToastr(false);
          handleCloseSidebar();
          setState({});
        }, 1000);
      }
    }
  };

  if (!usersDetail) {
    return null;
  }
  return (
    <Drawer
      title={usersDetail?.full_name || usersDetail?.name || " "}
      placement="right"
      closable
      className={sideNavStatus ? "wide" : ""}
      onClose={handleCloseSidebar}
      visible={isShow}
      destroyOnClose
      key="right"
    >
      <div className="resourceContainer new">
        {isToastr && <ToastMessage type="User" status="success" msg="Changed Successfully!" />}
        <ScrollWrapper>
          {isAddForm && <AddUserDetailNew closeAddForm={closeAddForm} />}
          {!isAddForm && (
            <React.Fragment>
              <div
                className="resourceContent"
                style={{
                  flex: "unset",
                  paddingLeft: "24px",
                  paddingRight: "20px",
                  marginTop: "1.5rem",
                }}
              >
                <div className="resourceInfo d-flex align-items-end required-field">
                  <EditableInput
                    isErrorTextSpacing={true}
                    label={"Fisrt Name"}
                    value={state.first_name}
                    handleChange={(value) => {
                      handleChange("first_name", value);
                    }}
                    required={true}
                    isError={validateField("text", state.first_name)}
                    helperText={"Fisrt Name is Required!"}
                  />
                </div>
                <div className="resourceInfo d-flex align-items-end required-field">
                  <EditableInput
                    isErrorTextSpacing={true}
                    label={"Last Name"}
                    value={state.last_name}
                    handleChange={(value) => {
                      handleChange("last_name", value);
                    }}
                    required={true}
                    isError={validateField("text", state.last_name)}
                    helperText={"Last Name is Required!"}
                  />
                </div>
              </div>
              <div
                className="resourceContent"
                style={{
                  flex: "unset",
                  paddingLeft: "24px",
                  paddingRight: "20px",
                }}
              >
                <div className="resourceInfo d-flex align-items-end required-field">
                  <EditableInput
                    isErrorTextSpacing={true}
                    label={"Address"}
                    value={state.address}
                    handleChange={(value) => {
                      handleChange("address", value);
                    }}
                    required={true}
                    isError={validateField("text", state.address)}
                    helperText={"Address is Required!"}
                  />
                </div>
                <div className="resourceInfo d-flex align-items-end required-field">
                  <EditableInput
                    isErrorTextSpacing={true}
                    label={"Phone"}
                    value={state.phone_number}
                    handleChange={(value) => {
                      handleChange("phone_number", value);
                    }}
                    required={true}
                    isError={validateField("text", state.phone_number)}
                    helperText={"Phone is Required!"}
                    mask="phone"
                  />
                </div>
                {type == "referring" ? (
                  <div className="resourceInfo d-flex align-items-end required-field">
                    <EditableInput
                      isErrorTextSpacing={true}
                      label={"Fax"}
                      value={state.fax_number}
                      handleChange={(value) => {
                        handleChange("fax_number", value);
                      }}
                      required={true}
                      isError={validateField("text", state.fax_number)}
                      helperText={"Fax is Required!"}
                    />
                  </div>
                ) : (
                  <>
                    <div className="resourceInfo d-flex align-items-end required-field">
                      <EditableInput
                        isErrorTextSpacing={true}
                        label={"DOB"}
                        value={state.date_of_birth}
                        handleChange={(value) => {
                          handleChange("date_of_birth", value);
                        }}
                        type={"date"}
                        required={true}
                        isError={validateField("text", state.date_of_birth)}
                        helperText={"Date Of Birth is Required!"}
                      />
                    </div>
                    <div className="resourceInfo d-flex align-items-end required-field">
                      <EditableInput
                        isErrorTextSpacing={true}
                        label={"Gender"}
                        handleChange={(value) => {
                          handleChange("gender", value);
                        }}
                        value={state.gender}
                        required={true}
                        isError={validateField("text", state.gender)}
                        enableErrorText={enableError}
                        helperText={"Gender is Required!"}
                        type={"check"}
                        optionKey={"value"}
                        valueKey={"name"}
                        options={[
                          { value: "male", name: "Male" },
                          { value: "female", name: "Female" },
                        ]}
                      />
                    </div>
                  </>
                )}
                <div className="resourceInfo d-flex align-items-end required-field">
                  <EditableInput
                    isErrorTextSpacing={true}
                    label={"Email"}
                    value={state.email_address}
                    handleChange={(value) => {
                      handleChange("email_address", value);
                    }}
                    required={true}
                    isError={validateField("text", state.email_address)}
                    helperText={"Email is Required!"}
                  />
                </div>
                <div className="resourceInfo d-flex align-items-end required-field">
                  <EditableInput
                    isErrorTextSpacing={true}
                    label={"Suite"}
                    value={state.suite}
                    handleChange={(value) => {
                      handleChange("suite", value);
                    }}
                    required={true}
                    isError={validateField("text", state.suite)}
                    helperText={"Suite is Required!"}
                  />
                </div>
                <div className="resourceInfo d-flex align-items-end required-field">
                  <EditableInput
                    isErrorTextSpacing={true}
                    label={"State"}
                    value={state.state}
                    handleChange={(value) => {
                      handleChange("state", value);
                    }}
                    required={true}
                    isError={validateField("text", state.state)}
                    helperText={"State is Required!"}
                  />
                </div>
                <div className="resourceInfo d-flex align-items-end required-field">
                  <EditableInput
                    isErrorTextSpacing={true}
                    label={"City"}
                    value={state.city}
                    handleChange={(value) => {
                      handleChange("city", value);
                    }}
                    required={true}
                    isError={validateField("text", state.city)}
                    helperText={"City is Required!"}
                  />
                </div>
                <div className="resourceInfo d-flex align-items-end required-field">
                  <EditableInput
                    isErrorTextSpacing={true}
                    label={"Zipcode"}
                    value={state.zipcode}
                    handleChange={(value) => {
                      handleChange("zipcode", value);
                    }}
                    required={true}
                    isError={validateField("text", state.zipcode)}
                    helperText={"Zipcode is Required!"}
                  />
                </div>
                <div className="resourceInfo d-flex align-items-end required-field">
                  <EditableInput
                    isErrorTextSpacing={true}
                    label={"Login"}
                    value={state.login}
                    handleChange={(value) => {
                      handleChange("login", value);
                    }}
                    required={true}
                    isError={validateField("text", state.userName)}
                    helperText={"User name is required"}
                  />
                </div>
                <div className="resourceInfo d-flex align-items-end required-field">
                  <EditableInput isErrorTextSpacing={true} label={"Password"} value={state.password} />
                  <span
                    style={{
                      width: 18,
                      marginLeft: 8,
                      height: "2.5rem",
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                    onClick={generatePassword}
                  >
                    <Icon type="plus-square" theme="twoTone" twoToneColor="#0f62fe" className="pharmacy-icon" />
                  </span>
                </div>
              </div>
              <div
                className="actionContent"
                style={{
                  marginTop: "2rem",
                  paddingRight: "1rem",
                  flexWrap: "wrap",
                }}
              >
                {isNewPassword && (
                  <button className="outlined-btn" style={{ marginTop: "0.5rem" }} onClick={updateProvider}>
                    Update
                  </button>
                )}
                <button className="outlined-btn" style={{ marginLeft: "0.5rem", marginTop: "0.5rem" }} onClick={openAddForm}>
                  Add New User
                </button>
                <button onClick={enableClickUser} className="outlined-btn" style={{ marginLeft: "0.5rem", marginTop: "0.5rem" }}>
                  Enable User
                </button>
              </div>
            </React.Fragment>
          )}
        </ScrollWrapper>
      </div>
    </Drawer>
  );
};

export default InActiveSidebarNew;
