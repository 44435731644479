import { debounce as lodashDebounce } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useDebounce } from "use-lodash-debounce";
import AutoCompleteSearch from "../../../../../common/components/AutoComplete/AutoCompleteSearch";
import EditableFormInput from "../../../../../common/components/EditableText/EditableFormInput";
import ToastMessage from "../../../../../common/components/Toast";
import { autoZip } from "../../../../../services/api.services";
import { createNewPrivateProvider, getPrimaryProviderDetails, updatePrimaryProviderDetail } from "../../../../../store/actions";

const HealthcareProviderLocation = () => {
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const [searchResult, setSearchResult] = useState([]);
  const [provider, setProvider] = useState({
    first_name: "",
    last_name: "",
    phone_number: "",
    fax_number: "",
    email_address: "",
    address: "",
    suite: "",
    state: "",
    city: "",
    zipcode: "",
  });
  const providers = useSelector((state) => state.adminSetting.providers);
  const isUpdated = useSelector((state) => state.adminSetting.isUpdated);
  const isSaved = useSelector((state) => state.adminSetting.isSaved);
  const [zipAutoData, setZipAutoData] = useState({});
  const [isUpdatedToast, setIsUpdatedToast] = useState(false);
  const [isSavedToast, setIsSavedToast] = useState(false);
  const [addNew, setAddNew] = useState(true);
  const [reset, setReset] = useState(false);
  const [isError, setIsError] = useState(false);

  const styleLeftElement = {
    width: "50%",
    paddingRight: "1rem",
    borderRight: "solid 1px #f0f3f6",
    paddingTop: "0.5rem",
  };

  const styleRightElement = {
    width: "50%",
    paddingLeft: "1rem",
    borderLeft: "solid 1px #f0f3f6",
    paddingTop: "0.5rem",
  };

  const debounce = useDebounce(search, 400);
  useEffect(() => {
    fetchData(debounce);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debounce]);

  const fetchData = (query) => {
    if (!query) {
      setSearchResult([]);
      return;
    }
    dispatch(getPrimaryProviderDetails(query));
  };

  useEffect(() => {
    if (isUpdated) {
      setIsUpdatedToast(true);
      setTimeout(() => {
        setIsUpdatedToast(false);
      }, 3000);
    }

    if (isSaved) {
      setIsSavedToast(true);
      setTimeout(() => {
        setIsSavedToast(false);
      }, 3000);
    }
  }, [isUpdated, isSaved]);

  useEffect(() => {
    if (providers.length > 0) {
      setSearchResult(providers);
    } else {
      setSearchResult([]);
    }
  }, [providers]);

  useEffect(() => {
    if (zipAutoData && Object.keys(zipAutoData).length > 0) {
      if (zipAutoData.success) {
        if (zipAutoData.addresses && zipAutoData.addresses[0]) {
          const address = zipAutoData.addresses[0];
          const newProvider = {
            ...provider,
            state: address.state_id,
            city: address.place,
          };
          setProvider(newProvider);
        }
      } else {
        const newProvider = {
          ...provider,
          state: "",
          city: "",
        };
        setProvider(newProvider);
      }
    }
  }, [zipAutoData]);

  const handleBtnSaveClicked = (value) => {
    let error;
    Object.keys(provider).forEach((field) => {
      if (field !== "email_address" && field !== "suite") {
        if (!provider[field]) {
          error = true;
          setIsError(true);
        }
      }
    });
    if (!error) {
      if (!value?.id) {
        dispatch(createNewPrivateProvider(provider));
      } else {
        dispatch(updatePrimaryProviderDetail(value?.id, value));
      }
    }
  };

  const changeHandler = useCallback(
    lodashDebounce((value) => handleBtnSaveClicked(value), 400),
    []
  );

  const handleProvider = (type) => (value) => {
    let timer;
    let newProvider = {};
    if (type === "zipcode") {
      clearTimeout(timer);
      newProvider = { ...provider, [type]: value };
      timer = setTimeout(async () => {
        const zipData = await autoZip(value);
        if (!zipData) {
          setZipAutoData({ success: false });
        } else {
          setZipAutoData(zipData);
        }
      }, 1000);
    } else {
      newProvider = { ...provider, [type]: value };
    }
    setProvider(newProvider);

    if (!addNew) {
      reset && changeHandler(newProvider);
    }
  };

  const handleBtnNewClicked = () => {
    setProvider({});
    setAddNew(true);
  };

  const handlePopulate = (value) => {
    setProvider(value);
    setAddNew(false);
    setReset(false);
    setTimeout(() => {
      setReset(true);
    }, 1000);
  };

  return (
    <div
      className="documentOcrSetting"
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        height: "100%",
      }}
    >
      <AutoCompleteSearch
        options={searchResult}
        handlePopulate={handlePopulate}
        handleSearch={(val) => setSearch(val)}
        title="Healthcare-provider-location"
        placeholder="Search Provider"
        actionLabel="Search"
        keyLabel="provider_full_name_username"
      />
      <div style={{ flex: 1, paddingTop: "1rem", width: "100%", overflow: "scroll" }}>
        {isUpdatedToast && <ToastMessage type="Primary Care Provider" status="success" msg="Successfully Updated the row!" />}
        {isSavedToast && <ToastMessage type="Primary Care Provider" status="success" msg="Successfully Saved the row!" />}
        <>
          <div
            style={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              paddingTop: "5px",
            }}
          >
            <div style={{ ...styleLeftElement, paddingTop: 0 }}>
              <EditableFormInput
                label="First Name"
                value={provider?.first_name}
                handleChange={handleProvider("first_name")}
                fullWidth
                required
                enableErrorText={isError}
                isError={!provider?.first_name}
              />
            </div>
            <div style={{ ...styleRightElement, paddingTop: 0 }}>
              <EditableFormInput
                label="Last Name"
                value={provider?.last_name}
                handleChange={handleProvider("last_name")}
                fullWidth
                required
                enableErrorText={isError}
                isError={!provider?.last_name}
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "flex-start",
            }}
          >
            <div style={styleLeftElement}>
              <EditableFormInput
                label="Suffix Field"
                value={provider?.suffix}
                type={"check"}
                options={[
                  { value: "MD", label: "MD" },
                  { value: "DO", label: "DO" },
                  { value: "NP", label: "NP" },
                  { value: "PA", label: "PA" },
                  { value: "DPM", label: "DPM" },
                ]}
                optionKey={"value"}
                valueKey={"label"}
                handleChange={handleProvider("suffix")}
                fullWidth
                required
                enableErrorText={isError}
                isError={!provider?.suffix}
              />
            </div>
            <div style={styleRightElement}>
              <EditableFormInput
                label="Phone Number"
                value={provider?.phone_number}
                mask={"phone"}
                handleChange={handleProvider("phone_number")}
                fullWidth
                required
                enableErrorText={isError}
                isError={!provider?.phone_number}
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "flex-start",
            }}
          >
            <div style={styleLeftElement}>
              <EditableFormInput
                label="Fax Number"
                value={provider?.fax_number}
                mask={"phone"}
                handleChange={handleProvider("fax_number")}
                fullWidth
                required
                enableErrorText={isError}
                isError={!provider?.fax_number}
              />
            </div>
            <div style={styleRightElement}>
              <EditableFormInput label="E-Mail" value={provider?.email_address} handleChange={handleProvider("email_address")} fullWidth />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "flex-start",
            }}
          >
            <div style={styleLeftElement}>
              <EditableFormInput
                label="Address"
                value={provider?.address}
                handleChange={handleProvider("address")}
                fullWidth
                required
                enableErrorText={isError}
                isError={!provider?.address}
              />
            </div>
            <div style={styleRightElement}>
              <EditableFormInput label="Suite" value={provider?.suite} handleChange={handleProvider("suite")} fullWidth />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "flex-start",
            }}
          >
            <div style={styleLeftElement}>
              <EditableFormInput
                label="State"
                value={provider?.state}
                handleChange={handleProvider("state")}
                fullWidth
                required
                enableErrorText={isError}
                isError={!provider?.state}
              />
            </div>
            <div style={styleRightElement}>
              <EditableFormInput
                label="City"
                value={provider?.city}
                handleChange={handleProvider("city")}
                fullWidth
                required
                enableErrorText={isError}
                isError={!provider?.city}
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "flex-start",
            }}
          >
            <div style={styleLeftElement}>
              <EditableFormInput
                label="Zip Code"
                value={provider?.zipcode}
                handleChange={handleProvider("zipcode")}
                fullWidth
                required
                enableErrorText={isError}
                isError={!provider?.zipcode}
              />
            </div>
          </div>
          <div
            style={{
              marginTop: "1.25rem",
              marginBottom: "1.5rem",
              float: "right",
              marginLeft: "10px",
              marginRight: "10px",
            }}
          >
            <button className={addNew ? "common-btn" : "common-btn outlined-btn"} onClick={addNew ? handleBtnSaveClicked : handleBtnNewClicked}>
              {!addNew ? "Create New Provider" : "Save New Provider"}
            </button>
          </div>
        </>
      </div>
    </div>
  );
};

export default HealthcareProviderLocation;
