import { Drawer } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Finalize from "../../../common/components/Finalize";
import FormStepper from "../../../common/components/FormStepper";
import { SummaryEmptySVG } from "../../../common/components/icons/SVGIcon";
import ScrollWrapper from "../../../common/components/ScrollBar";
import Toastr from "../../../common/components/Toast";
import { getSingleProcedure, get_patient_notes, saveProcedureQuestion, setProcedureData } from "../../../store/actions";
import CallAttemptProcedureComponent from "./CallAttemptProcedure";
import CommunicationStep from "./CommunicationStep";
import FirstStepContent from "./FirstStep";
import Notes from "./Notes";
import QuestionnaireStep from "./Questionnaire";
import SecondStepContent from "./SecondStep";

const initialCondition = [
  { step: "demographics", status: true },
  { step: "questions", status: true },
  { step: "procedure", status: true },
  { step: "communication", status: false },
  { step: "attempt", status: false },
  { step: "notes", status: false },
  { step: "finalize", status: false },
];

const formSteps = [
  {
    title: "Demographics",
    isConfirmed: true,
    content: FirstStepContent,
  },
  {
    title: "Questionnaire",
    isConfirmed: true,
    content: QuestionnaireStep,
  },
  {
    title: "Procedure Scheduling",
    isConfirmed: true,
    content: SecondStepContent,
  },
  {
    title: "Communication",
    isConfirmed: true,
    content: CommunicationStep,
  },
  {
    title: "Call Attempts",
    isConfirmed: true,
    content: CallAttemptProcedureComponent,
  },
  {
    title: "Notes",
    isConfirmed: true,
    content: Notes,
  },
  {
    title: "Finalize",
    isConfirmed: true,
    content: Finalize,
  },
];

const HospitalSideBar = React.forwardRef(({ isShow, handleCloseSidebar, handleNext, selectedType }, ref) => {
  const resource = useSelector((state) => state.procedure.resource);
  const isConfirmed = useSelector((state) => state.procedure.isConfirmed);
  const patientsNote = useSelector((state) => state.newPatient.patientsNote);
  const call_attempts = useSelector((state) => state.procedure.call_attempts);
  const checkApointment = useSelector((state) => state.common.checkApointment);
  const [check, setCheck] = useState(false);
  const procedure = useSelector((state) => state.procedure.procedure);
  const [conditions, setConditions] = useState(initialCondition);
  const [step, setStep] = useState(0);
  const [title, setTitle] = useState("");
  const [noteCancelValue, setNoteCancelValue] = useState("");
  const [questions, setQuestions] = useState({
    taking_metformin: -1,
    taking_eliquis: -1,
    taking_coumadin: -1,
    taking_plavix: -1,
    receive_coronary_stent: -1,
  });
  const [isQuestionnaireCompleted, setIsQuestionnaireCompleted] = useState(false);
  const [showNote, setShowNote] = useState(false);
  const [timer, setTimer] = useState(0);
  const dispatch = useDispatch();

  const actionShowNote = (value) => {
    setShowNote(value);
  };

  const isToastr = useMemo(() => {
    if (isConfirmed) {
      setTimer(0);
      setTimeout(() => {
        setTimer(600);
      }, 2000);
      return true;
    }
    return false;
  }, [isConfirmed]);

  useEffect(() => {
    if (resource && resource?.patient) {
      const newTitle = `${resource?.patient?.first_name || ""} ${resource?.patient?.last_name || ""}`;
      setTitle(newTitle);
      setStep(0);
      setShowNote(false);
      setNoteCancelValue("");
      dispatch(get_patient_notes(resource?.patient?.id));
    }
  }, [resource]);

  const handleSubmit = () => {
    if (step === 0) {
      setStep(1);
      handleNext(1);
    } else {
      // Todo: handleSubmit
    }
  };
  const handleStep = (step) => {
    setStep(step);
    handleNext(step);
  };

  useEffect(() => {
    if (
      questions?.taking_metformin == null ||
      questions.taking_metformin == -1 ||
      questions.taking_eliquis == null ||
      questions.taking_eliquis == -1 ||
      questions.taking_coumadin == null ||
      questions.taking_coumadin == -1 ||
      questions.taking_plavix == null ||
      questions.taking_plavix == -1 ||
      questions.receive_coronary_stent == null ||
      questions.receive_coronary_stent == -1
    ) {
      setIsQuestionnaireCompleted(false);
    } else {
      setIsQuestionnaireCompleted(true);
    }
  }, [questions]);

  useEffect(() => {
    if (
      procedure?.patient?.taking_metformin == null ||
      procedure?.patient?.taking_metformin == -1 ||
      procedure?.patient?.taking_eliquis == null ||
      procedure?.patient?.taking_eliquis == -1 ||
      procedure?.patient?.taking_coumadin == null ||
      procedure?.patient?.taking_coumadin == -1 ||
      procedure?.patient?.taking_plavix == null ||
      procedure?.patient?.taking_plavix == -1 ||
      procedure?.patient?.receive_coronary_stent == null ||
      procedure?.patient?.receive_coronary_stent == -1
    ) {
      setIsQuestionnaireCompleted(false);
    } else {
      setIsQuestionnaireCompleted(true);
    }
  }, [procedure]);

  useEffect(() => {
    if (resource) {
      const newConditions = [...conditions];
      if (procedure && procedure?.patient) {
        if (isQuestionnaireCompleted) {
          newConditions[1].status = true;
        } else {
          newConditions[1].status = false;
        }
        if (checkApointment) {
          newConditions[2].status = true;
        } else {
          if (procedure?.patient?.is_completed_scheduling == null || procedure?.patient?.is_completed_scheduling == 0) {
            newConditions[2].status = false;
          } else {
            newConditions[2].status = true;
          }
        }
      }
      if (check) {
        newConditions[3].status = true;
      } else {
        newConditions[3].status = false;
      }
      if (call_attempts && call_attempts.length > 0) {
        newConditions[4].status = true;
      } else {
        newConditions[4].status = false;
      }
      if (patientsNote && patientsNote.length > 0) {
        newConditions[5].status = true;
      } else {
        newConditions[5].status = false;
      }
      setConditions([...newConditions]);
    }
  }, [resource, step, procedure, isQuestionnaireCompleted, checkApointment, call_attempts, patientsNote, check]);

  const checkQuestion = (quest) => {
    setQuestions(quest);
  };

  const onCloseStep = async (step) => {
    if (step == 1) {
      await dispatch(setProcedureData("loadingSchedule", true));
      await dispatch(saveProcedureQuestion(resource?.id, questions));
      await dispatch(getSingleProcedure(resource?.id));
    }
  };

  const setNoteCancelValueCheck = (value) => {
    setNoteCancelValue(value);
  };

  const checkAction = (value) => {
    setCheck(value);
  };

  useEffect(() => {
    setStep(0);
  }, [isShow]);

  if (!resource) {
    return (
      <Drawer
        title={(resource?.procedure_type && resource?.procedure_type?.value) || " "}
        placement="right"
        closable
        onClose={handleCloseSidebar}
        visible={isShow}
        destroyOnClose
        key="right"
      >
        <div ref={ref} className="resourceContainer new">
          <div
            className="emptyContentIcon"
            style={{
              width: "100%",
              height: "calc(100% - 160px)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <SummaryEmptySVG />
          </div>
        </div>
      </Drawer>
    );
  }

  return (
    <Drawer
      title={
        resource?.procedure_name
          ? `${resource?.procedure_name}
          - ${title}`
          : " "
      }
      placement="right"
      closable
      onClose={handleCloseSidebar}
      visible={isShow}
      destroyOnClose
      key="right"
    >
      <div ref={ref} className="resourceContainer new">
        {isToastr && timer < 600 && (
          <div style={{ position: "fixed", top: "60px", right: "2px" }}>
            <Toastr type="Procedure" status="success" msg="Confirmed Successfully!" />
          </div>
        )}
        <ScrollWrapper>
          <FormStepper
            showNote={showNote}
            setNoteCancelValue={setNoteCancelValueCheck}
            noteCancelValue={noteCancelValue}
            checkAction={checkAction}
            type="Procedure"
            actionShowNote={actionShowNote}
            steps={formSteps}
            currentStep={step}
            checkQuestion={checkQuestion}
            onCloseStep={onCloseStep}
            conditions={conditions}
            handleStep={handleStep}
            handleContinue={handleSubmit}
            customResource={resource}
            handleCloseSidebar={handleCloseSidebar}
          />
        </ScrollWrapper>
      </div>
    </Drawer>
  );
});

export default HospitalSideBar;
