import { createReducer } from "redux-create-reducer";
import moment from "moment";
import AT from "../actionTypes";

const initialState = {
  hpi_conditions: [],
  ROSs: [],
  physical_examinations: [],
  vitals: [],
  comment: "",
  vessel_states: [],
  icd_codes: [],
  medical_history: [],
  previous_surgical_histories: [],
  allergies: [],
  family_history: [],
  social_history: [],
  descList: [],
  chiefComplaint: "",
  is_locked: null,
  medical_decision_making: "",
  meaningful_uses: [],
  discussion_text: [],
  loading_meaningful_uses: false,
  result_meaningful_uses: false,
  tabActive: "",
  accordion: "",
  step: "",
  loadingClinicReview: false,
  leftRight: null,
  text_template: null,
  reading_physician_id: 0,
  has_empty_hpi_question: false,
  provider_name: "",
  has_unprocessed_plans: false,
  appointment_status: "",
  ultrasound_discussion_text: [],
  reason_for_visit: "",
  has_wrong_physical_exam: false,
};

const rerenderArr = (value, typeRos, TypeExam) => {
  if (value && value.length > 0) {
    const mixCheck = (value || []).map((r) => {
      let letMix = "";
      let indexOF = null;
      let endComment = null;
      let indexOFNull = null;
      let endCommentNull = null;
      if (r?.comments && r?.default_comments && r?.comments?.length > 0 && r?.default_comments.length > 0 && r?.comments != r?.default_comments) {
        letMix = r?.comments;
        indexOF = r?.comments && r?.comments.lastIndexOf(", ");
      }
      if (Array.isArray(r?.values)) {
        let mixMap = [];
        let parse = [];
        if (r["values"] && r["values"][0] && r["values"][0]["replaced_value"]) {
          parse = r["values"] || [];
        }
        if (r["values"] && r["values"][0] && !r["values"][0]["replaced_value"]) {
          parse = r["values"][0]["option"] ? JSON.parse(r["values"][0]["option"]) : [];
        }
        if (parse.some((i) => i.active_text)) {
          let commentsCheck2 = "";
          const parseMix = parse.map((v, indexCheck) => {
            if (typeRos == 1 || TypeExam == 1) {
              if (Number(parse.length) - 1 == indexCheck) {
                if (v.checked) {
                  indexOF = r?.comments && r?.comments.lastIndexOf(`${v.option}, `) - 1;
                  endComment = `${v.option}, `;
                } else {
                  indexOF = r?.comments && r?.comments.lastIndexOf(`${v.replaced_value}, `) - 1;
                  endComment = `${v.replaced_value}, `;
                }
              }
              if (v.checked) {
                commentsCheck2 += `${v.option}, `;
              } else {
                commentsCheck2 += `${v.replaced_value}, `;
              }
            } else {
              if (Number(parse.length) - 1 == indexCheck) {
                indexOFNull = r?.comments && r?.comments.lastIndexOf(`${v.option}, `);
                endCommentNull = `${v.option}, `;
              }
              if (v.checked) {
                commentsCheck2 += `${v.option}, `;
              }
            }
            return {
              checked: false,
              option: v.inactive_text,
              replaced_value: v.active_text,
            };
          });
          mixMap = parseMix;
          if (letMix.length > 0 && commentsCheck2.length > 0) {
            if (endComment && indexOF > 0) {
              commentsCheck2 = commentsCheck2.replace(endComment, "") + letMix.slice(indexOF, letMix.length);
            } else if (endCommentNull && indexOFNull > 0) {
              commentsCheck2 = commentsCheck2.replace(endCommentNull, "") + letMix.slice(indexOFNull, letMix.length);
            } else {
              commentsCheck2 = commentsCheck2;
            }
          }
          return {
            ...r,
            comments: commentsCheck2,
            values: mixMap,
          };
        } else {
          let commentsCheck = "";
          parse.map((v, indexCheck) => {
            if (typeRos == 1 || TypeExam == 1) {
              if (Number(parse.length) - 1 == indexCheck) {
                if (v.checked) {
                  indexOF = r?.comments && r?.comments.lastIndexOf(`${v.option}, `) - 1;
                  endComment = `${v.option}, `;
                } else {
                  indexOF = r?.comments && r?.comments.lastIndexOf(`${v.replaced_value}, `) - 1;
                  endComment = `${v.replaced_value}, `;
                }
              }
              if (v.checked) {
                commentsCheck += `${v.option}, `;
              } else {
                commentsCheck += `${v.replaced_value}, `;
              }
            } else {
              if (v.checked) {
                commentsCheck += `${v.option}, `;
                if (Number(parse.length) - 1 == indexCheck) {
                  indexOFNull = r?.comments && r?.comments.lastIndexOf(`${v.option}, `);
                  endCommentNull = `${v.option}, `;
                }
              }
            }
          });
          if (letMix && commentsCheck && letMix.length > 0 && commentsCheck.length > 0) {
            if (endComment && indexOF > 0) {
              commentsCheck = commentsCheck.replace(endComment, "") + letMix.slice(indexOF, letMix.length);
            } else if (endCommentNull && indexOFNull > 0) {
              commentsCheck = commentsCheck.replace(endCommentNull, "") + letMix.slice(indexOFNull, letMix.length);
            } else {
              commentsCheck = commentsCheck;
            }
          }
          return {
            ...r,
            comments: commentsCheck,
            values: parse,
          };
        }
      } else {
        return {
          ...r,
          comments: r?.values || "",
        };
      }
    });
    return mixCheck || [];
  }
  return [];
};

const SAVE_TEMP_SUCCEEDED = `${AT.SAVE_TEMP}_SUCCEEDED`;
const GET_CPT_CODE_REVIEW_SUCCESS = `${AT.GET_CPT_CODE_REVIEW}_SUCCEEDED`;
const EDIT_ICD_SUCCESS = `${AT.EDIT_ICD}_SUCCEEDED`;

export default createReducer(initialState, {
  [EDIT_ICD_SUCCESS](state, action) {
    const ICD = action?.payload;
    return Object.assign({}, state, {
      icd_codes: ICD,
    });
  },
  [`${AT.ADD_PLAN_ME}_STARTED`](state, action) {
    return {
      ...state,
      loading_meaningful_uses: true,
    };
  },
  [`${AT.ADD_PLAN_ME}_SUCCEEDED`](state, action) {
    const result_meaningful_uses = action?.payload;
    return {
      ...state,
      loading_meaningful_uses: false,
      result_meaningful_uses,
    };
  },
  [`${AT.CHANGE_TAB}_SUCCEEDED`](state, action) {
    const { tabActive, accordion, step } = action?.payload || {};
    return {
      ...state,
      tabActive,
      accordion,
      step,
    };
  },
  [`${AT.RESET_ALL_STATES}_SUCCEEDED`](state, action) {
    return {
      ...initialState,
    };
  },
  [`${AT.CHANGE_MEDICAL_DECISION}_SUCCEEDED`](state, action) {
    const medical_decision_making = action?.payload;
    return {
      ...state,
      medical_decision_making,
    };
  },
  [`${AT.REMOVE_DATA_PATIENT}_SUCCEEDED`]() {
    return {
      hpi_conditions: [],
      ROSs: [],
      physical_examinations: [],
      vitals: [],
      comment: "",
      vessel_states: [],
      icd_codes: [],
      medical_history: [],
      previous_surgical_histories: [],
      allergies: [],
      medications: [],
      family_history: [],
      social_history: [],
      descList: [],
      chiefComplaint: "",
      is_locked: null,
      medical_decision_making: "",
      meaningful_uses: [],
      discussion_text: [],
      text_template: null,
    };
  },
  [`${AT.CLINIC_REVIEW_LOAD_INIT}_STARTED`](state) {
    return {
      ...state,
      hpi_conditions: [],
      ROSs: [],
      physical_examinations: [],
      vitals: [],
      comment: "",
      vessel_states: [],
      icd_codes: [],
      medical_history: [],
      previous_surgical_histories: [],
      allergies: [],
      medications: [],
      family_history: [],
      social_history: [],
      descList: [],
      discussion_text: [],
      chiefComplaint: "",
      text_template: null,
      meaningful_uses: [],
      loadingClinicReview: true,
      reading_physician_id: 0,
      has_empty_hpi_answer: false,
      provider_name: "",
      has_unprocessed_plans: false,
      leftRight: null,
      reason_for_visit: "",
      has_wrong_physical_exam: false,
    };
  },
  [`${AT.CLINIC_REVIEW_LOAD_INIT}_SUCCEEDED`](state, action) {
    if (!action?.payload) {
      return { ...state };
    }
    const {
      hpi_conditions,
      ROSs,
      physical_examinations,
      vitals,
      comment,
      vessel_states,
      icd_codes,
      medical_history,
      previous_surgical_histories,
      allergies,
      medications,
      family_history,
      social_history,
      descList,
      chiefComplaint,
      is_locked,
      medical_decision_making,
      reading_physician_id,
      meaningful_uses,
      text_template,
      discussion_text,
      leftRight,
      has_empty_hpi_question,
      provider_name,
      has_unprocessed_plans,
      reason_for_visit,
      has_wrong_physical_exam,
    } = action?.payload;
    return {
      ...state,
      leftRight,
      text_template,
      hpi_conditions,
      ROSs: rerenderArr(ROSs),
      physical_examinations: rerenderArr(physical_examinations),
      vitals: (vitals || []).map((item) => {
        if (item.type === "Temperature") {
          return { type: item.type, value: `${item?.value} ℉` };
        } else if (item.type === "BP (Right)" || item.type === "BP (Left)") {
          return { type: item.type, value: `${item?.value} mnHg` };
        } else if (item.type === "HR") {
          return { type: item.type, value: `${item?.value} BPM` };
        } else if (item.type === "Resp") {
          return { type: item.type, value: `${item?.value} RR` };
        } else if (item.type === "SpO2") {
          return { type: item.type, value: `${item?.value} %` };
        } else if (item.type === "Weight") {
          return { type: item.type, value: `${item?.value} Kg` };
        } else if (item.type === "Height") {
          return { type: item.type, value: `${item?.value} Inches` };
        }
        return item;
      }),
      comment,
      vessel_states,
      icd_codes,
      medical_history,
      previous_surgical_histories,
      allergies,
      medications,
      family_history,
      social_history,
      descList,
      chiefComplaint,
      is_locked,
      medical_decision_making,
      reading_physician_id,
      meaningful_uses,
      discussion_text,
      loadingClinicReview: false,
      has_empty_hpi_question,
      provider_name,
      has_unprocessed_plans,
      reason_for_visit,
      has_wrong_physical_exam,
    };
  },
  [`${AT.ULTRASOUND_REVIEW_LOAD_INIT}_STARTED`](state) {
    return {
      ...state,
      ultrasound_discussion_text: [],
    };
  },
  [`${AT.ULTRASOUND_REVIEW_LOAD_INIT}_SUCCEEDED`](state, action) {
    const { ultrasound_discussion_text, is_locked, reading_physician_id, text_template } = action?.payload || {};
    return {
      ...state,
      is_locked,
      reading_physician_id,
      text_template,
      ultrasound_discussion_text: ultrasound_discussion_text || "",
    };
  },
  [`${AT.UNLOCK_CLINIC_PROCEDURE}_SUCCEEDED`](state) {
    return Object.assign({}, state, {
      is_locked: 0,
    });
  },
  [`${AT.LOCK_CLINIC_PROCEDURE}_SUCCEEDED`](state) {
    return Object.assign({}, state, {
      is_locked: 1,
    });
  },
  // check
  [GET_CPT_CODE_REVIEW_SUCCESS](state, action) {
    const CPT = action?.payload;
    return Object.assign({}, state, {
      is_locked: CPT?.is_locked || false,
      appointment_status: CPT?.appointment_status || "",
    });
  },
  [SAVE_TEMP_SUCCEEDED](state, action) {
    const CPT = action?.payload;
    return Object.assign({}, state, {
      is_locked: CPT?.is_locked || false,
      appointment_status: CPT?.appointment_status || "",
    });
  },
});
