import { Icon, Input } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useOutsideDetect } from '../../../../common/utils';
import ReplaceN from '../../../../helpers/ReplaceN';

const { TextArea } = Input;

const ExaminationROS = ({ type, onChangeData, isLocked }) => {
  const originalData = useSelector((state) =>
    type === 'ROS' ?
      state.patientExamination.ROSs || [] :
      state.patientExamination.physical_examinations || [],
  );

  const [data, setData] = useState([]);
  const [fisrtData, setFisrtData] = useState(false);
  const [fisrtDataRos, setFisrtDataRos] = useState(false);

  useEffect(() => {
    if (originalData) {
      const fixArr = (originalData || []).map((r) => {
        return {
          ...r,
          comments: ReplaceN(r?.comments || ''),
        };
      });
      setData(fixArr);
    }
  }, [originalData]);

  useEffect(() => {
    if (
      !fisrtData &&
      originalData &&
      originalData.length > 0 &&
      type !== 'ROS'
    ) {
      setFisrtData(true);
    }
  }, [originalData]);

  useEffect(() => {
    if (
      !fisrtDataRos &&
      originalData &&
      originalData.length > 0 &&
      type === 'ROS'
    ) {
      setFisrtDataRos(true);
    }
  }, [originalData]);

  const onChangeComments = (index, comments) => {
    const data_ = [...data];
    data_[index]['comments'] = comments;
    setData(data);
  };

  const onChangeCommentsUpdate = (index, comments) => {
    const data_ = [...data];
    data_[index]['comments'] = comments;
    onChangeData(data_);
  };

  const onChangeValues = async (index, values, item) => {
    const data_ = [...data];
    data_[index]['values'] = values;
    await onChangeData(data_);
  };

  return (
    <React.Fragment>
      {(data || []).map((item, index) => {
        return (
          <ROSItem
            key={`examination-item-${index}`}
            title={item.type}
            index={index}
            comments={item.comments}
            values={item?.values}
            onChangeCommentsUpdate={(comments) =>
              onChangeCommentsUpdate(index, comments)
            }
            onChangeComments={(comments) => onChangeComments(index, comments)}
            onChangeValues={(values) => onChangeValues(index, values, item)}
            isLocked={isLocked}
          />
        );
      })}
    </React.Fragment>
  );
};

const ROSItem = (props) => {
  const [isCommentEditing, setCommentEditing] = useState(false);
  const [title, setTitle] = useState('');
  const [values, setValues] = useState([]);
  const [comments, setComments] = useState('');

  const ref = useRef();
  const ref1 = useRef();

  useEffect(() => {
    setTitle(props.title);
    setComments(props.comments);
    setValues(props?.values);
  }, [props.comments, props?.values]);

  const openCommentEditBox = () => {
    setCommentEditing(!isCommentEditing);
  };

  const onClickOption = (index) => {
    if (props.isLocked == 0) {
      const values_ = [...values];
      values_[index]['checked'] = !values_[index]['checked'];
      props.onChangeValues(values_);
      setValues(values_);
    }
  };

  const onBlurComments = () => {
    props.onChangeCommentsUpdate(comments);
    setCommentEditing(false);
  };

  useOutsideDetect(ref, ref1, isCommentEditing, onBlurComments);

  return (
    <div className={`ExaminationROSItem`}>
      <div className="ExaminationROSItem__label">{title}</div>
      <div className={`ExaminationROSItem__options`}>
        <div
          className={`ExaminationROSItem__icon ${isCommentEditing ? 'open' : ''
            }`}
          onClick={() => {
            if (!props.isLocked) openCommentEditBox();
          }}
        >
          <Icon type="edit" />
        </div>
        <div className="ExaminationROSItem__btnContainer">
          {(values || []).map((opt, index) => (
            <button
              tabIndex="1"
              key={`ros-item-${title}-${index}`}
              className={`ExaminationROSItem__option ${opt.checked && 'active'
                } ${props.isLocked == 0 ? 'not-locked' : ''}`}
              onClick={() => onClickOption(index)}
            >
              {opt.option}
            </button>
          ))}
        </div>
        <div
          ref={ref}
          style={{
            display: isCommentEditing ? 'inline-block' : 'none',
            width: '100%',
          }}
        >
          <div ref={ref1}>
            <TextArea
              placeholder={'Notes'}
              autoSize={{ minRows: 3 }}
              style={{
                marginTop: '0.5rem',
                borderRadius: 0,
                backgroundColor: '#f4f7fb',
              }}
              onChange={(e) => {
                props.onChangeComments(e.target?.value);
                setComments(e.target?.value);
              }}
              value={comments}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExaminationROS;
