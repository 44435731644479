import React from 'react';
import {Children} from './constant';
import ScrollWrapper from '../../common/components/ScrollBar';

const RegisterWrapper = ({location, isSideNav}) => {
  const getActivePath = (path) => {
    const paths = path.split('/');
    if (paths.length) {
      return paths[paths.length - 1];
    }
    return '';
  };

  const activePath = getActivePath(location.pathname);
  const ActiveContent = Children[activePath];

  return (
    <div className={`clinicWorkflow__main ${isSideNav ? '' : 'collapsed'}`}>
      <ScrollWrapper css="no-padding x-hidden">
        <ActiveContent />
      </ScrollWrapper>
    </div>
  );
};

export default RegisterWrapper;
