import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import TopBar from "../../../common/components/SubTopBar";
import ClinicWorkflowSidebar from "../../../common/views/QuickRegisterSidebar";
import PriorFilters from "./PriorFilters";
import ResultTable from "./PriorResultTable";

import {
  chooseSelectedPatientResource,
  chooseSelectedPatientResourceByRandom,
  getLocations,
  getOnlyProviders,
  getPatientSchedules,
  searchPatientSchedules,
} from "../../../store/actions";

class QuickRegisterC extends Component {
  state = {
    isSidebar: false,
    events: [],
    resources: [],
    selectedCategories: [],
    filters: {
      date: moment(new Date()).format("YYYY-MM-DD"),
      location: "",
      provider_id: "",
    },
    filter: "",
    search: "",
  };

  componentDidMount = () => {
    this.props.getLocations();
    this.props.getOnlyProviders();
    this.loadInitialPaymentJs();
    this.handleEvents(this.state.filters);
  };

  loadInitialPaymentJs = () => {
    const sqPaymentScript = document.createElement("script");
    sqPaymentScript.src = "https://js.squareup.com/v2/paymentform";
    sqPaymentScript.type = "text/javascript";
    sqPaymentScript.async = false;
    sqPaymentScript.onload = () => {};
    document.getElementsByTagName("head")[0].appendChild(sqPaymentScript);
  };

  handleSidebar = () => {
    this.setState({ isSidebar: true });
  };

  toggleSidebar = () => {
    this.setState({ isSidebar: !this.state.isSidebar });
  };

  handleCloseSidebar = () => {
    this.setState({ isSidebar: false });
  };

  handleEvents = ({ date, location, provider_id }) => {
    this.setState({
      ...this.state,
      date,
      location,
      provider_id,
    });
    this.props.getPatientSchedules({
      date,
      location,
      provider_id,
      patient_id: "",
    });
  };

  onSearch = (searchValue) => {
    this.props.searchPatientSchedules(searchValue);
  };

  onSelectResource = (patient_id) => {
    this.props.chooseSelectedPatientResource(patient_id);
    this.handleSidebar();
  };

  refreshCalendar = () => {
    const { date, location, provider_id } = this.state;
    this.props.getPatientSchedules({
      date,
      location,
      provider_id,
      patient_id: "",
    });
  };

  handleSearch = (search) => {
    this.setState({ search });
  };

  handleFilter = (filter) => {
    this.setState({ filter });
  };

  sidebarRef = null;
  constructor(props) {
    super(props);
    this.sidebarRef = React.createRef();
  }

  render() {
    const { isSidebar, filter, search } = this.state;
    const { selectedPatientResource, resource } = this.props;
    const isResource = !selectedPatientResource || !Object.keys(selectedPatientResource).length;

    return (
      <div className="clinicWorkflow__main-page">
        <TopBar title="Subsidiary Module" isBarcode={true} />
        <div className={`clinicWorkflow__main-container ${isSidebar ? "openSidebar" : ""}`}>
          <div className="clinicWorkflow__main-section">
            <div className="eventsContainer">
              <div className="fullContainer">
                <PriorFilters filter={filter} handleChangeFilter={this.handleFilter} handleSearch={this.handleSearch} />
                <div className="tableSection">
                  <ResultTable title="result" filter={filter} search={search} handleSidebar={this.handleSidebar} />
                </div>
              </div>
            </div>
            <ClinicWorkflowSidebar
              ref={this.sidebarRef}
              resource={selectedPatientResource}
              isShow={isSidebar}
              handleCloseSidebar={this.handleCloseSidebar}
              paymentForm={window.SqPaymentForm}
              refreshCalendar={this.refreshCalendar}
              hideMedication={true}
            />
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  locations: state.common.locations,
  providers: state.common.only_providers,
  schedules: state.patientSchedule.schedules,
  loadingSchedules: state.patientSchedule.loadingSchedules,
  newPatientResource: state.newPatient.resource,
  patientRecordsResource: state.patientRecords.resource,
  selectedPatientResource: state.patientSchedule.selectedPatientResource,
  resource: state.prior.resource,
});

const mapDispatchToProps = {
  getLocations,
  getOnlyProviders,
  getPatientSchedules,
  chooseSelectedPatientResourceByRandom,
  chooseSelectedPatientResource,
  searchPatientSchedules,
};

export default connect(mapStateToProps, mapDispatchToProps)(QuickRegisterC);
