import { Icon } from "antd";
import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import Notes from "../../../../common/components/Notes";
import Demographics from "../../../../common/views/PatientSnapshot/Demographics";
import MedicalHistory from "../../../../common/views/PatientSnapshot/MedicalHistory";
import ClinicWorkflowSidebar from "../../../../common/views/QuickRegisterSidebar";
import { getLocal, setLocal } from "../../../../helpers/Local";
import {
  chooseSelectedPatientResource,
  getDetailUltrasounds,
  getPatientSchedules,
  get_patient_notes,
  get_ultrasound_finding_value,
  get_ultrasound_type,
  setDataPatient,
  ultrasoundReviewLoadInit,
} from "../../../../store/actions";
import DayCalendarUltrasound from "../DayCalendarUltrasound";

const resources = [
  {
    id: "us",
    title: "Ultrasound",
  },
];

const initialConditions = [
  { step: "demographics", status: true },
  { step: "medical", status: true },
  { step: "notes", status: true },
];

const formSteps = [
  {
    title: "Demographics",
    content: Demographics,
  },
  {
    title: "Medical History",
    content: MedicalHistory,
  },
  {
    title: "Notes",
    content: Notes,
  },
];

class ClinicSchedule extends Component {
  sidebarRef = null;
  constructor(props) {
    super(props);
    this.sidebarRef = React.createRef();
  }
  state = {
    isSidebar: false,
    events: [],
    searchValue: "",
    schedulesState: [],
    block: true,
    filters: {
      date: moment(new Date()).format("YYYY-MM-DD"),
      location: "",
      provider_id: "",
    },
  };

  componentDidMount = () => {
    const { date } = this.state.filters;
    const localCheck = JSON.parse(getLocal("scheduleParams"));
    const scheduleFilters = {
      date: localCheck && localCheck?.date ? localCheck?.date || date : date,
      location: (localCheck && localCheck?.location) || "",
      provider_id: (localCheck && localCheck?.provider_id) || "",
    };
    this.handleEvents(scheduleFilters);
    this.loadInitialPaymentJs();
    this.props.getPatientSchedules({
      ...scheduleFilters,
      patient_id: "",
      only_clinics: 0,
      only_procedures: 0,
      only_us: 1,
      only_angio: 0,
    });
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.activeReview) {
      const current_ultrasound_name = getLocal("ultrasound_type_name");
      this.setState({ sidebarTitle: current_ultrasound_name });
      if (nextProps.goNextTabReview) {
        nextProps.goNextTabReview();
      }
      this.setState({
        block: true,
      });
      this.handleSidebar();
    } else {
      this.setState({
        block: false,
      });
    }
    if (nextProps.schedules) {
      this.setState({ schedulesState: nextProps.schedules });
    }
    if (nextProps?.stateStatusProps && nextProps?.schedules) {
      const indexCheck = (nextProps?.schedules || []).findIndex((r) => r?.id === nextProps?.stateStatusProps?.appointment_id);
      if (indexCheck !== -1) {
        const dataFix = [...nextProps?.schedules];
        dataFix.splice(indexCheck, 1, {
          ...dataFix[indexCheck],
          status: nextProps?.stateStatusProps?.status,
        });
        this.setState({ schedulesState: [...dataFix] });
      }
    }
  }

  loadInitialPaymentJs = () => {
    const sqPaymentScript = document.createElement("script");
    sqPaymentScript.src = "https://js.squareup.com/v2/paymentform";
    sqPaymentScript.type = "text/javascript";
    sqPaymentScript.async = false;
    sqPaymentScript.onload = () => {};
    document.getElementsByTagName("head")[0].appendChild(sqPaymentScript);
  };

  handleSidebar = () => {
    this.setState({ isSidebar: true });
  };

  toggleSidebar = () => {
    this.setState({ isSidebar: !this.state.isSidebar });
  };

  handleCloseSidebar = () => {
    this.setState({ isSidebar: false });
  };

  handleEvents = ({ date, location, provider_id }) => {
    this.setState({
      ...this.state,
      date,
      location,
      provider_id,
    });
    if (!this.state.block) {
      this.props.getPatientSchedules({
        date,
        location,
        provider_id,
        patient_id: "",
        only_clinics: 0,
        only_procedures: 0,
        only_us: 1,
        only_angio: 0,
      });
    }
  };

  onSearch = (searchValue) => {
    this.setState({ searchValue });
    if (searchValue.length > 0) {
      const searchPatientName = (this.props.schedules || []).filter((item) => {
        return item.patientName.toLowerCase().includes(searchValue.toLowerCase());
      });
      this.setState({ schedulesState: searchPatientName });
    } else {
      this.setState({ schedulesState: this.props.schedules });
    }
  };

  onSelectResource = async (patient_id, content, event) => {
    setLocal("current_patient_id", patient_id);
    setLocal("appointment_ultrasound_id", event?.id);
    this.props.ultrasoundReviewLoadInit(event?.id);
    const current_ultrasound_id = getLocal("current_ultrasound_id");
    const result = await this.props.chooseSelectedPatientResource(patient_id);
    if (result) {
      this.props.setDataPatient(result);
    }
    this.props.get_patient_notes(patient_id);
    this.props.get_ultrasound_finding_value(event?.id);
    this.props.get_ultrasound_type(current_ultrasound_id); // get ultrasound finding names per ultrasound type
    this.props.getDetailUltrasounds(current_ultrasound_id); // get detail about ultrasound (header, logic, etc)
    this.handleSidebar();
    this.props.onClick();
    this.setState({ sidebarTitle: content });
  };

  render() {
    const { isSidebar, sidebarTitle } = this.state;
    const { selectedPatientResource } = this.props;

    return (
      <div style={{ display: this.props.display ? "flex" : "none" }} className="clinicWorkflow__main-section">
        <div className="eventsContainer has-sub-header">
          <div className="calendarContainer">
            <DayCalendarUltrasound
              locations={this.props.locations || []}
              providers={this.props.providers || []}
              events={this.state.schedulesState || []}
              resources={resources}
              slotProviders={this.props.scheduleProviders || []}
              loading={this.props.loadingSchedules || this.props.loadingResource}
              fetchEvents={this.handleEvents}
              onSearch={this.onSearch}
              valueSearch={this.state.searchValue}
              onSelectResource={this.onSelectResource}
              calendarType="check-in"
              isClinicWorkFlow={this.props.isClinicWorkFlow}
            />
          </div>
        </div>
        <ClinicWorkflowSidebar
          ref={this.sidebarRef}
          resource={selectedPatientResource}
          isShow={isSidebar}
          handleCloseSidebar={this.handleCloseSidebar}
          paymentForm={window.SqPaymentForm}
          refreshCalendar={this.refreshCalendar}
          referralConditions={this.props.referralConditions || []}
          isNormal={true}
          formSteps={formSteps}
          initialConditions={initialConditions}
          disableIDImage={true}
          hasSubHeader={true}
          sidebarTitle={`${sidebarTitle} - ${selectedPatientResource?.first_name} ${selectedPatientResource?.last_name} ${
            getLocal("appointment_ultrasound_id") ? `[${getLocal("appointment_ultrasound_id")}]` : ""
          }`}
          sidebarIcon={<Icon type="funnel-plot" theme="twoTone" twoToneColor="#740937" />}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  locations: state.common.clinicNursingLocations,
  providers: state.common.providers,
  schedules: state.patientSchedule.schedules,
  scheduleProviders: state.patientSchedule.providers,
  loadingSchedules: state.patientSchedule.loadingSchedules,
  referralConditions: state.common.referralConditions,
  selectedPatientResource: state.patientSchedule.selectedPatientResource,
  loadingResource: state.patientSchedule.loadingResource,
  activeReview: state.common.activeReview,
});

const mapDispatchToProps = {
  getPatientSchedules,
  chooseSelectedPatientResource,
  getDetailUltrasounds,
  get_ultrasound_finding_value,
  get_ultrasound_type,
  get_patient_notes,
  ultrasoundReviewLoadInit,
  setDataPatient,
};

export default connect(mapStateToProps, mapDispatchToProps)(ClinicSchedule);
