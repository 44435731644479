import { DatePicker, Select, TimePicker } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ExpandSVG, LeftCharvenSVG, RedRequiredCircleSVG } from "../../../common/components/icons/SVGIcon";
import { putRequest } from "../../../services/api.services";
import {
  checkApointment,
  getAppointments,
  getEvents,
  saveClinicSchedule,
  setGlobalLoading,
  setQuestCompare,
  setStepData,
} from "../../../store/actions";

const { Option } = Select;
let timer = null;

const filterOptions = (options, filterOption, type) => {
  if (!filterOption || !filterOption.type || !filterOption.list.length) {
    return options;
  }
  if (type !== filterOption.type) {
    return options;
  }
  return options.filter((option) => filterOption.list.includes(option?.id));
};

const getValue = (options, target, key) => {
  if (key && options) {
    const opt = options.find((option) => option[key] == target);
    if (opt) {
      return opt?.value;
    }
    return "";
  }
  return "";
};

const ForthStepComponent = ({ handleContinue, ...restProps }) => {
  const resource = useSelector((state) => state.newPatient.resource);
  const questions = useSelector((state) => state.newPatient.questions);
  const questionsCompare = useSelector((state) => state.newPatient.questionsCompare);
  const loadingSchedule = useSelector((state) => state.newPatient.initialLoading);
  const schedulesCheck = useSelector((state) => state.newPatient.schedules);
  const scheduleOption = useSelector((state) => state.newPatient.scheduleOptions);
  const scheduleLoading = useSelector((state) => state.newPatient.scheduleLoading);
  const dropdownOption = useSelector((state) => state.newPatient.dropdownOption);
  const activeRows = useSelector((state) => state.newPatient.activeRows);
  const [appointment_1, setAppointment_1] = useState([]);
  const [appointment_2, setAppointment_2] = useState([]);
  const [appointment_3, setAppointment_3] = useState([]);
  const [newAppointments, setNewAppointments] = useState([]);
  const save_check_question = useSelector((state) => state.newPatient.save_check_question);
  const [activeSave, setActiveSave] = useState(false);
  const [activeMore, setActiveMore] = useState(false);
  const [checkClick2, setCheckClick2] = useState(false);
  const [checkClick1, setCheckClick1] = useState(false);
  const [lock, setLock] = useState(true);
  const [checkClick3, setCheckClick3] = useState(false);
  const [arrCheck, setArrCheck] = useState([]);
  const [activeCustom, setActiveCustom] = useState([]);
  const [customAppointment, setCustomAppointment] = useState([{}]);
  const dispatch = useDispatch();
  const [schedules, setSchedules] = useState([]);

  useEffect(() => {
    setSchedules(schedulesCheck);
  }, [schedulesCheck]);

  const { filteredLocations, filteredProviders } = restProps;

  const saveAllDate = (type) => {
    if (type === 0) {
      if (schedules && schedules[0]) {
        const appointment1 = schedules[0];
        if (appointment1[0]) {
          if (appointment_1 && !appointment_1.some((r) => r.date === appointment1[0].date)) {
            setAppointment_1([...appointment_1, appointment1[0]]);
          }
        }
      }
    }
    if (type === 1) {
      if (schedules && schedules[1]) {
        const appointment2 = schedules[1];
        if (appointment2[0]) {
          if (appointment_2 && !appointment_2.some((r) => r.date === appointment2[0].date)) {
            setAppointment_2([...appointment_2, appointment2[0]]);
          }
        }
      }
    }
    if (type === 2) {
      if (schedules && schedules[2]) {
        const appointment3 = schedules[2];
        if (appointment3[0]) {
          if (appointment_3 && !appointment_3.some((r) => r.date === appointment3[0].date)) {
            setAppointment_3([...appointment_3, appointment3[0]]);
          }
        }
      }
    }
  };

  const fetchApi = async () => {
    if (resource?.id && questions) {
      clearTimeout(timer);
      timer = setTimeout(async () => {
        await putRequest(resource?.id, questions, "questionnaire");
        await dispatch(getAppointments(resource?.id));
      }, 100);
    }
  };

  useEffect(() => {
    if (!loadingSchedule && !schedules.length) {
      fetchApi();
      restProps.initialAppointmentSchedule();
    }
  }, [resource]);

  useEffect(() => {
    if (resource && resource?.id) {
      if (questions && Object.keys(questions).length > 0 && questionsCompare && Object.keys(questionsCompare).length > 0) {
        const mapQuestion = Object.keys(questions).map((r) => {
          return questions[r];
        });
        const mapQuestionCompare = Object.keys(questionsCompare).map((r) => {
          return questionsCompare[r];
        });
        if (JSON.stringify(mapQuestionCompare) === JSON.stringify(mapQuestion)) {
        } else {
          dispatch(setQuestCompare(questions));
          fetchApi();
        }
      }
    }
  }, [questions, questionsCompare]);

  useEffect(() => {
    dispatch(setGlobalLoading(loadingSchedule || (scheduleLoading && (scheduleLoading[0] || scheduleLoading[1] || scheduleLoading[2]))));
  }, [loadingSchedule, scheduleLoading]);

  const handleActiveRows = async (type, value) => {
    setActiveMore(true);
    const actives = [...activeRows];
    if (type === 0) {
      setAppointment_2([]);
      if (schedules && schedules[0] && schedules[0].length > 0) {
        if (actives[1] && actives[2]) {
          actives[1] = -1;
          actives[2] = -1;
        }
        actives[type] = value;
        await dispatch(setStepData("activeRows", actives));
      } else {
        actives[type] = value;
        await dispatch(setStepData("activeRows", actives));
      }
    }
    if (type === 1) {
      setAppointment_3([]);
      if (schedules && schedules[1] && schedules[1].length > 0 && schedules[0].length > 0) {
        if (actives[2]) {
          actives[2] = -1;
        }
        actives[type] = value;
        await dispatch(setStepData("activeRows", actives));
      } else {
        actives[type] = value;
        await dispatch(setStepData("activeRows", actives));
      }
    }
    if (type === 2) {
      actives[type] = value;
      await dispatch(setStepData("activeRows", actives));
    }
  };

  const handleClickRow = (type, schedule) => {
    const option = { ...scheduleOption[type] };
    restProps.handleAppointmentSchedule(schedule.location, schedule.provider, option.period, schedule.date, type, "row", false, "true");
  };

  const handleChange = (type, opt) => (value) => {
    const option = { ...scheduleOption[type], [opt]: value };
    const activeRow = type > 0 ? activeRows[type - 1] : activeRows[type];
    const activeType = type > 0 ? type - 1 : type;
    const lastDate =
      activeRow !== -1
        ? (schedules && schedules[activeType] && schedules[activeType][activeRow] && schedules[activeType][activeRow].date) || null
        : (schedules && schedules[activeType] && schedules[activeType].length) || null
        ? (schedules && schedules[activeType] && schedules[activeType][0] && schedules[activeType][0].date) || null
        : "";
    if (opt == "location_id" || opt == "provider_id") {
      restProps.handleScheduleOption(type, option);
      restProps.handleAppointmentSchedule(option.location_id, option.provider_id, "", "", type, "");
    } else {
      restProps.handleScheduleOption(type, option);
      restProps.handleAppointmentSchedule(option.location_id, option.provider_id, option.period, lastDate, type, "");
    }
  };

  const moreDates = (type) => {
    saveAllDate(type);
    const option = { ...scheduleOption[type] };
    const lastDate = schedules[type].length ? schedules[type][schedules[type].length - 1].date : "";
    if (!!lastDate) {
      restProps.handleAppointmentSchedule(option.location_id, option.provider_id, option.period, lastDate, type, "");
      setActiveMore(false);
      setActiveSave(true);
    }
  };

  const previous = (type) => {
    setActiveMore(false);
    const option = { ...scheduleOption[type] };
    if (appointment_1.length > 0 && type === 0) {
      restProps.handleAppointmentSchedule(
        option.location_id,
        option.provider_id,
        option.period,
        appointment_1[appointment_1.length - 1].date,
        type,
        ""
      );
      const aa = appointment_1;
      if (aa) {
        aa.splice(appointment_1.length - 1, 1);
        setAppointment_1(aa);
      }
    }
    if (appointment_2.length > 0 && type === 1) {
      restProps.handleAppointmentSchedule(
        option.location_id,
        option.provider_id,
        option.period,
        appointment_2[appointment_2.length - 1].date,
        type,
        ""
      );
      const aa = appointment_2;
      if (aa) {
        aa.splice(appointment_2.length - 1, 1);
        setAppointment_2(aa);
      }
    }
    if (appointment_3.length > 0 && type === 2) {
      restProps.handleAppointmentSchedule(
        option.location_id,
        option.provider_id,
        option.period,
        appointment_3[appointment_3.length - 1].date,
        type,
        ""
      );
      const aa = appointment_3;
      if (aa) {
        aa.splice(appointment_3.length - 1, 1);
        setAppointment_3(aa);
      }
    }
  };

  const saveSchedule = async (schedulesCheck, resourceCheck, index) => {
    const date = moment(new Date()).format("MM/DD/YYYY");
    if (resourceCheck && schedulesCheck && schedulesCheck[0] && schedulesCheck[0].length > 0) {
      await dispatch(setGlobalLoading(true));
      let params = {};
      const dateSlotTime = moment()
        .hours(8)
        .minutes(0)
        .add(parseInt(schedulesCheck[0][index[0] !== -1 ? index[0] : 0].start_slot) * 15, "minutes")
        .format("hh:mm A");
      const firstParams = {
        appointment_1_date: schedulesCheck[0][index[0] !== -1 ? index[0] : 0].date,
        appointment_1_time: dateSlotTime || schedulesCheck[0][index[0] !== -1 ? index[0] : 0].time,
        appointment_1_provider: parseInt(schedulesCheck[0][index[0] !== -1 ? index[0] : 0].provider),
        appointment_1_location: parseInt(schedulesCheck[0][index[0] !== -1 ? index[0] : 0].location),
        appointment_1_technician: parseInt(schedulesCheck[0][index[0] !== -1 ? index[0] : 0].technician),
        appointment_1_start_slot: parseInt(schedulesCheck[0][index[0] !== -1 ? index[0] : 0].start_slot),
        appointment_1_slot: parseInt(schedulesCheck[0][index[0] !== -1 ? index[0] : 0].start_slot),
      };
      if (schedulesCheck.length === 1) {
        params = {
          ...firstParams,
        };
      } else if (schedulesCheck.length === 2) {
        params = {
          ...firstParams,
          appointment_2_date:
            schedulesCheck[1] && schedulesCheck[1][0] && schedulesCheck[1][0]["date"] ? schedulesCheck[1][index[1] !== -1 ? index[1] : 0].date : "",
          appointment_2_time:
            schedulesCheck[1] && schedulesCheck[1][0] && schedulesCheck[1][0]["time"] ? schedulesCheck[1][index[1] !== -1 ? index[1] : 0].time : "",
          appointment_2_provider:
            schedulesCheck[1] && schedulesCheck[1][0] && schedulesCheck[1][0]["provider"]
              ? parseInt(schedulesCheck[1][index[1] !== -1 ? index[1] : 0].provider)
              : "",
          appointment_2_technician:
            schedulesCheck[1] && schedulesCheck[1][0] && schedulesCheck[1][0]["technician"]
              ? parseInt(schedulesCheck[1][index[1] !== -1 ? index[1] : 0].technician)
              : "",
          appointment_2_location:
            schedulesCheck[1] && schedulesCheck[1][0] && schedulesCheck[1][0]["location"]
              ? parseInt(schedulesCheck[1][index[1] !== -1 ? index[1] : 0].location)
              : "",
          appointment_2_start_slot:
            schedulesCheck[1] && schedulesCheck[1][0] && schedulesCheck[1][0]["start_slot"]
              ? parseInt(schedulesCheck[1][index[1] !== -1 ? index[1] : 0].start_slot)
              : 0,
          appointment_2_slot:
            schedulesCheck[1] && schedulesCheck[1][0] && schedulesCheck[1][0]["start_slot"]
              ? parseInt(schedulesCheck[1][index[1] !== -1 ? index[1] : 0].start_slot)
              : 0,
        };
      } else if (schedulesCheck.length === 3) {
        params = {
          ...firstParams,
          appointment_2_date:
            schedulesCheck[1] && schedulesCheck[1][0] && schedulesCheck[1][0]["date"] ? schedulesCheck[1][index[1] !== -1 ? index[1] : 0].date : "",
          appointment_2_time:
            schedulesCheck[1] && schedulesCheck[1][0] && schedulesCheck[1][0]["time"] ? schedulesCheck[1][index[1] !== -1 ? index[1] : 0].time : "",
          appointment_2_provider:
            schedulesCheck[1] && schedulesCheck[1][0] && schedulesCheck[1][0]["provider"]
              ? parseInt(schedulesCheck[1][index[1] !== -1 ? index[1] : 0].provider)
              : "",
          appointment_2_technician:
            schedulesCheck[1] && schedulesCheck[1][0] && schedulesCheck[1][0]["technician"]
              ? parseInt(schedulesCheck[1][index[1] !== -1 ? index[1] : 0].technician)
              : "",
          appointment_2_location:
            schedulesCheck[1] && schedulesCheck[1][0] && schedulesCheck[1][0]["location"]
              ? parseInt(schedulesCheck[1][index[1] !== -1 ? index[1] : 0].location)
              : "",
          appointment_2_start_slot:
            schedulesCheck[1] && schedulesCheck[1][0] && schedulesCheck[1][0]["start_slot"]
              ? parseInt(schedulesCheck[1][index[1] !== -1 ? index[1] : 0].start_slot)
              : 0,
          appointment_2_slot:
            schedulesCheck[1] && schedulesCheck[1][0] && schedulesCheck[1][0]["start_slot"]
              ? parseInt(schedulesCheck[1][index[1] !== -1 ? index[1] : 0].start_slot)
              : 0,
          appointment_3_date:
            schedulesCheck[2] && schedulesCheck[2][0] && schedulesCheck[2][0]["date"] ? schedulesCheck[2][index[2] !== -1 ? index[2] : 0].date : "",
          appointment_3_location:
            schedulesCheck[2] && schedulesCheck[2][0] && schedulesCheck[2][0]["location"]
              ? schedulesCheck[2][index[2] !== -1 ? index[2] : 0].location
              : "",
          appointment_3_time:
            schedulesCheck[2] && schedulesCheck[2][0] && schedulesCheck[2][0]["time"] ? schedulesCheck[2][index[2] !== -1 ? index[2] : 0].time : "",
          appointment_3_provider:
            schedulesCheck[2] && schedulesCheck[2][0] && schedulesCheck[2][0]["provider"]
              ? parseInt(schedulesCheck[2][index[2] !== -1 ? index[2] : 0].provider)
              : "",
          appointment_3_technician:
            schedulesCheck[2] && schedulesCheck[2][0] && schedulesCheck[2][0]["technician"]
              ? parseInt(schedulesCheck[2][index[2] !== -1 ? index[2] : 0].technician)
              : "",
          appointment_3_start_slot:
            schedulesCheck[2] && schedulesCheck[2][0] && schedulesCheck[2][0]["start_slot"]
              ? parseInt(schedulesCheck[2][index[2] !== -1 ? index[2] : 0].start_slot)
              : 0,
          appointment_3_slot:
            schedulesCheck[2] && schedulesCheck[2][0] && schedulesCheck[2][0]["start_slot"]
              ? parseInt(schedulesCheck[2][index[2] !== -1 ? index[2] : 0].start_slot)
              : 0,
        };
      }
      if (schedulesCheck.length == 3) {
        if (params.appointment_1_date && params.appointment_2_date && params.appointment_3_date) {
          await dispatch(saveClinicSchedule(resourceCheck?.id, params));
          await dispatch(setStepData("loadingEvents", true));
          await dispatch(getEvents({ date }));
          await dispatch(checkApointment(true));
          await dispatch(setGlobalLoading(false));
        }
      } else if (schedulesCheck.length == 2) {
        if (params.appointment_1_date && params.appointment_2_date) {
          await dispatch(saveClinicSchedule(resourceCheck?.id, params));
          await dispatch(setStepData("loadingEvents", true));
          await dispatch(getEvents({ date }));
          await dispatch(checkApointment(true));
          await dispatch(setGlobalLoading(false));
        }
      } else {
        if (params.appointment_1_date) {
          await dispatch(saveClinicSchedule(resourceCheck?.id, params));
          await dispatch(setStepData("loadingEvents", true));
          await dispatch(getEvents({ date }));
          await dispatch(checkApointment(true));
          await dispatch(setGlobalLoading(false));
        }
      }
    }
  };
  useEffect(() => {
    if (!activeRows.some((r) => r == -1) && schedules.length > 0) {
      setLock(false);
    } else {
      setLock(true);
    }
  }, [schedules, resource, activeRows, activeSave]);

  const handleTimeSlot = (value, index, indexAll) => {
    const mixSlot = [...schedules];
    if (mixSlot[indexAll][index]) {
      mixSlot[indexAll][index]["start_slot"] = value;
      const dateSlot = moment()
        .hours(8)
        .minutes(0)
        .add(value * 15, "minutes")
        .format("HH:mm:ss");
      const dateSlotTime = moment()
        .hours(8)
        .minutes(0)
        .add(value * 15, "minutes")
        .format("hh:mm A");
      if (mixSlot[indexAll][index]["start"] && mixSlot[indexAll][index]["start"].includes(" ")) {
        const mixSplit = mixSlot[indexAll][index]["start"].split(" ");
        if (mixSplit[0]) {
          mixSlot[indexAll][index]["start"] = `${mixSplit[0]} ${dateSlot}`;
        }
        mixSlot[indexAll][index]["time"] = dateSlotTime;
      }
      setSchedules([...mixSlot]);
    }
  };

  const onHandleClick = () => {
    if (!loadingSchedule) {
      if (!activeRows.some((r) => r == -1)) {
        saveSchedule(schedules, resource, activeRows);
      }
    }
  };

  useEffect(() => {
    if (resource.new_appointments && resource.new_appointments.length > 0) {
      setNewAppointments(resource.new_appointments);
    }
  }, [resource]);

  useEffect(() => {
    if (resource.new_appointments && resource.new_appointments.length > 0) {
      if (schedules && schedules[0] && !checkClick1) {
        restProps.handleAppointmentSchedule(
          resource.new_appointments[0].location_id,
          resource.new_appointments[0].provider_id,
          "",
          moment(resource.new_appointments[0].appointment_date).format("MM/DD/YYYY"),
          0,
          "row",
          false,
          "true"
        );
        setCheckClick1(true);
      }
      if (schedules && schedules[1] && !checkClick2 && checkClick1) {
        if (resource.new_appointments[1]) {
          restProps.handleAppointmentSchedule(
            resource.new_appointments[1].location_id,
            resource.new_appointments[1].provider_id,
            "",
            moment(resource.new_appointments[1].appointment_date).format("MM/DD/YYYY"),
            1,
            "row",
            false,
            "true"
          );
          setCheckClick2(true);
        }
      }
      if (schedules && schedules[2] && !checkClick3 && checkClick1 && checkClick2) {
        if (resource.new_appointments[2]) {
          restProps.handleAppointmentSchedule(
            resource.new_appointments[2].location_id,
            resource.new_appointments[2].provider_id,
            "",
            moment(resource.new_appointments[2].appointment_date).format("MM/DD/YYYY"),
            2,
            "row",
            false,
            "true"
          );
          setCheckClick3(true);
        }
      }
    }
  }, [resource, schedules]);

  const handleCustomChange = (idx, type, value) => {
    const customAppointments = [...customAppointment];
    customAppointments[idx][type] = value;
    setCustomAppointment(customAppointments);
  };

  const empty = (schedules || []).map((schedule) => (schedule && schedule.length < 3 ? new Array(3 - schedule.length).fill("empty") : []));

  useEffect(() => {
    if (arrCheck && arrCheck.length > 0 && arrCheck[0] && arrCheck[0]["appointment_date"]) {
    } else {
      setArrCheck(newAppointments);
    }
  }, [newAppointments]);

  useEffect(() => {
    if (!activeSave) {
      if (schedules && schedules[0] && schedules[0].length > 0 && newAppointments && newAppointments[0] && newAppointments[0]["appointment_date"]) {
        const indexCheck = schedules[0].findIndex((r) => r.date == moment(newAppointments[0]["appointment_date"]).format("MM/DD/YYYY"));
        const actives = [...activeRows];
        actives[0] = indexCheck;
        dispatch(setStepData("activeRows", actives));
      }
      if (schedules && schedules[1] && schedules[1].length > 0 && newAppointments && newAppointments[1] && newAppointments[1]["appointment_date"]) {
        const indexCheck = schedules[1].findIndex((r) => r.date == moment(newAppointments[1]["appointment_date"]).format("MM/DD/YYYY"));
        const actives = [...activeRows];
        actives[1] = indexCheck;
        dispatch(setStepData("activeRows", actives));
      }
      if (schedules && schedules[2] && schedules[2].length > 0 && newAppointments && newAppointments[2] && newAppointments[2]["appointment_date"]) {
        const indexCheck = schedules[2].findIndex((r) => r.date == moment(newAppointments[2]["appointment_date"]).format("MM/DD/YYYY"));
        const actives = [...activeRows];
        actives[2] = indexCheck;
        dispatch(setStepData("activeRows", actives));
      }
    }
  }, [schedules, newAppointments, activeSave]);

  useEffect(() => {
    const actives = [...activeRows];
    if (!activeMore) {
      if (arrCheck && arrCheck[0] && arrCheck[0]["appointment_date"]) {
        if (schedules && schedules[0] && schedules[0].length > 0 && arrCheck && arrCheck[0] && arrCheck[0]["appointment_date"]) {
          const indexCheck = schedules[0].findIndex((r) => r.date == moment(arrCheck[0]["appointment_date"]).format("MM/DD/YYYY"));
          actives[0] = indexCheck;
          dispatch(setStepData("activeRows", [...actives]));
        }
      }
      if (arrCheck && arrCheck[1] && arrCheck[1]["appointment_date"]) {
        if (schedules && schedules[1] && schedules[1].length > 0 && arrCheck && arrCheck[1] && arrCheck[1]["appointment_date"]) {
          const indexCheck = schedules[1].findIndex((r) => r.date == moment(arrCheck[1]["appointment_date"]).format("MM/DD/YYYY"));
          actives[1] = indexCheck;
          dispatch(setStepData("activeRows", [...actives]));
        }
      }
      if (arrCheck && arrCheck[2] && arrCheck[2]["appointment_date"]) {
        if (schedules && schedules[2] && schedules[2].length > 0 && arrCheck && arrCheck[2] && arrCheck[2]["appointment_date"]) {
          const indexCheck = schedules[2].findIndex((r) => r.date == moment(arrCheck[2]["appointment_date"]).format("MM/DD/YYYY"));
          actives[2] = indexCheck;
          dispatch(setStepData("activeRows", [...actives]));
        }
      }
    }
  }, [schedules, arrCheck, newAppointments, resource, activeMore]);

  if (save_check_question) {
    return (
      <div>
        <div className="resourceContent pr-7px" style={{ position: "relative" }}>
          {(schedules || []).map((appointment, idx) => (
            <React.Fragment key={`clinic-appointment-${idx}`}>
              <div
                className="resourceInfo d-flex"
                style={{
                  justifyContent: "space-between",
                  marginTop: idx === 0 ? "0px" : "15px",
                }}
              >
                <b>{`Logic Appointment ${idx + 1}`}</b>
              </div>

              <div className="resourceInfo between mt-2 sortableHeader" style={{ background: "#f4f7fb" }}>
                <div
                  className={`th sortable w-25 hide-icon`}
                  style={{
                    paddingLeft: "2px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    alignSelf: "stretch",
                    borderLeft: "1px solid #dfe3e6",
                  }}
                >
                  <Select
                    placeholder="Location"
                    value={scheduleOption[idx]?.location_id || undefined}
                    suffixIcon={<span />}
                    allowClear={false}
                    style={{
                      maxWidth: "100%",
                      width: "100%",
                      background: "transparent",
                      borderBottom: 0,
                      boxShadow: "none",
                    }}
                    onChange={handleChange(idx, "location_id")}
                  >
                    {(filterOptions(filteredLocations, dropdownOption[idx], "location") || []).map((location, index) => (
                      <Option key={`side-location-${location?.id}-${index}`} value={location?.id}>
                        {location?.value}
                      </Option>
                    ))}
                  </Select>
                </div>

                <div
                  className={`th sortable w-25 hide-icon`}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    alignSelf: "stretch",
                    borderLeft: "1px solid #dfe3e6",
                  }}
                >
                  Date
                  <div>
                    <button
                      className="more-dates backward"
                      style={{
                        marginRight: "0.5rem",
                        padding: "2px",
                        minWidth: "unset",
                        width: "auto",
                        height: "auto",
                        border: "none",
                        background: "transparent",
                        color: "#3d70be",
                        marginTop: 0,
                      }}
                      onClick={() => {
                        previous(idx);
                      }}
                    >
                      <LeftCharvenSVG />
                    </button>
                    <button
                      className="more-dates forward"
                      style={{
                        padding: "2px",
                        minWidth: "unset",
                        width: "auto",
                        height: "auto",
                        border: "none",
                        background: "transparent",
                        color: "#3d70be",
                        marginTop: 0,
                      }}
                      onClick={() => {
                        moreDates(idx);
                      }}
                    >
                      <LeftCharvenSVG />
                    </button>
                  </div>
                </div>

                <div
                  className={`th sortable w-25 hide-icon`}
                  style={{
                    paddingLeft: "2px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    alignSelf: "stretch",
                    borderLeft: "1px solid #dfe3e6",
                  }}
                >
                  <Select
                    placeholder="Time"
                    value={scheduleOption[idx].period || undefined}
                    suffixIcon={<span />}
                    style={{
                      maxWidth: "100%",
                      width: "100%",
                      background: "transparent",
                      borderBottom: 0,
                      boxShadow: "none",
                    }}
                    onChange={handleChange(idx, "period")}
                  >
                    <Option value="am">AM</Option>
                    <Option value="pm">PM</Option>
                  </Select>
                </div>

                <div
                  className={`th sortable w-25 hide-icon`}
                  style={{
                    paddingLeft: "2px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    alignSelf: "stretch",
                    borderLeft: "1px solid #dfe3e6",
                  }}
                >
                  <Select
                    placeholder="Provider"
                    value={scheduleOption[idx].provider_id || undefined}
                    suffixIcon={<span />}
                    allowClear={false}
                    style={{
                      maxWidth: "100%",
                      width: "100%",
                      background: "transparent",
                      borderBottom: 0,
                      boxShadow: "none",
                    }}
                    onChange={handleChange(idx, "provider_id")}
                  >
                    {(filterOptions(filteredProviders, dropdownOption[idx], "provider") || []).map((provider, index) => (
                      <Option key={`side-provider-${provider?.id}-${index}`} value={provider?.id}>
                        {provider?.value}
                      </Option>
                    ))}
                  </Select>
                </div>
              </div>

              <div style={{ width: "100%", height: "120px", position: "relative" }}>
                {(appointment || []).map((schedule, index) => (
                  <React.Fragment key={`schedule-${idx}-${index}`}>
                    <div
                      id={index == 0 ? `${idx}LogicSchedule` : ""}
                      className={`resourceInfo between t-row 
               ${activeRows[idx] === index ? "active" : ""}`}
                      style={{ alignItems: "center" }}
                      onClick={() => {
                        setActiveSave(true);
                        handleActiveRows(idx, index);
                        handleClickRow(idx, schedule);
                      }}
                    >
                      <div className="w-25">{getValue(filteredLocations, schedule.location, "id")}</div>
                      <div className="w-25">{schedule.date}</div>
                      <div
                        className="w-25"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          paddingLeft: 0,
                          marginTop: 0,
                        }}
                      >
                        <Select
                          value={schedule?.start_slot}
                          suffixIcon={<ExpandSVG />}
                          allowClear={false}
                          style={{
                            maxWidth: "100%",
                            width: "100%",
                          }}
                          onChange={(value) => {
                            handleTimeSlot(value, index, idx);
                          }}
                        >
                          {(schedule?.slots || []).map((time, index) => (
                            <Option key={`time-${time}-${index}`} value={time}>
                              {moment()
                                .hours(8)
                                .minutes(0)
                                .add(time * 15, "minutes")
                                .format("hh:mm A")}
                            </Option>
                          ))}
                        </Select>
                      </div>
                      <div className="w-25">{getValue(filteredProviders, schedule.provider, "id")}</div>
                    </div>
                    <hr className="small-block" />
                  </React.Fragment>
                ))}
                {empty[idx].map((schedule, index) => (
                  <React.Fragment key={`schedule-${schedule}-${idx}-${index}`}>
                    <div className={`resourceInfo between t-row`} />
                    <hr className="small-block" />
                  </React.Fragment>
                ))}
              </div>

              {activeCustom[idx] && (
                <div
                  className="resourceInfo between custom_row"
                  style={{
                    padding: 0,
                  }}
                >
                  <div
                    className={`th w-25 hide-icon`}
                    style={{
                      paddingLeft: "0.75rem",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      alignSelf: "stretch",
                    }}
                  >
                    <Select
                      className={!customAppointment[idx].location ? "input-error" : ""}
                      placeholder="Location"
                      value={customAppointment[idx].location || undefined}
                      suffixIcon={<ExpandSVG />}
                      allowClear={false}
                      style={{
                        maxWidth: "100%",
                        width: "100%",
                        borderBottom: 0,
                        boxShadow: "none",
                      }}
                      onChange={(value) => handleCustomChange(idx, "location", value)}
                    >
                      {(filterOptions(filteredLocations, dropdownOption[idx], "location") || []).map((location, index) => (
                        <Option key={`side-location-${location?.id}-${index}`} value={location?.id}>
                          {location?.value}
                        </Option>
                      ))}
                    </Select>
                  </div>

                  <div
                    className={`th w-25 hide-icon`}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      alignSelf: "stretch",
                      paddingLeft: "0.75rem",
                    }}
                  >
                    <DatePicker
                      className={!customAppointment[idx].date ? "input-error" : ""}
                      onChange={(value) => {
                        handleCustomChange(idx, "date", moment(value).format("MM/DD/YYYY"));
                      }}
                      style={{
                        width: "100%",
                        background: "transparent",
                        borderBottom: 0,
                        boxShadow: "none",
                      }}
                      format={"MM/DD/YYYY"}
                      placeholder="Date"
                      suffixIcon={<ExpandSVG />}
                    />
                  </div>

                  <div
                    className={`th w-25 hide-icon`}
                    style={{
                      paddingLeft: "0.75rem",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      alignSelf: "stretch",
                    }}
                  >
                    <TimePicker
                      format={"h:mm A"}
                      use12Hours
                      placeholder="Time"
                      className={!customAppointment[idx].time ? "input-error" : ""}
                      style={{
                        maxWidth: "100%",
                        width: "100%",
                        background: "transparent",
                        boxShadow: "none",
                        borderBottom: 0,
                      }}
                      suffixIcon={<span />}
                      disabledHours={() => [0, 1, 2, 3, 4, 5, 20, 21, 22, 23]}
                      minuteStep={15}
                      onChange={(value) => handleCustomChange(idx, "time", moment(value).format("h:mm A"))}
                    />
                  </div>

                  <div
                    className={`th w-25 hide-icon`}
                    style={{
                      paddingLeft: "0.75rem",
                      paddingRight: "0.75rem",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      alignSelf: "stretch",
                    }}
                  >
                    <Select
                      className={!customAppointment[idx].provider ? "input-error" : ""}
                      placeholder="Provider"
                      value={customAppointment[idx].provider || undefined}
                      suffixIcon={<ExpandSVG />}
                      allowClear={false}
                      style={{
                        maxWidth: "100%",
                        width: "100%",
                        borderBottom: 0,
                        boxShadow: "none",
                        border: 0,
                      }}
                      onChange={(value) => handleCustomChange(idx, "provider", value)}
                    >
                      {(filterOptions(filteredProviders, dropdownOption[idx], "provider") || []).map((provider, index) => (
                        <Option key={`side-provider-${provider?.id}-${index}`} value={provider?.id}>
                          {provider?.value}
                        </Option>
                      ))}
                    </Select>
                  </div>
                </div>
              )}
            </React.Fragment>
          ))}
          {!lock ? (
            <div className="actionContentNew" style={{ paddingRight: "10px", marginTop: 0, minHeight: "16px" }}>
              <button
                id="save_schedule"
                className={`bottom-btn`}
                onClick={() => {
                  onHandleClick();
                }}
              >
                Save
              </button>
            </div>
          ) : null}
        </div>
      </div>
    );
  } else {
    return (
      <p
        style={{
          marginLeft: 0,
          fontSize: "0.875rem",
          color: "#da1e28",
          fontWeight: 400,
          lineHeight: 0.99,
          letterSpacing: "0.03333em",
          display: "flex",
        }}
      >
        <RedRequiredCircleSVG style={{ width: "16px" }} />
        <span
          style={{
            paddingLeft: "2px",
          }}
        >
          Questionnaire Required for Scheduling
        </span>
      </p>
    );
  }
};

export default ForthStepComponent;
