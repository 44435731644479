import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import { HPIItem } from "../../../../common/components/AutoAccordionProcedure";
import ScrollWrapper from "../../../../common/components/ScrollBar";
import { getLocal } from "../../../../helpers/Local";
import { angioPatientSnapshotLoadInit, patientExaminationSave, setProcedureReview, setSidebarState } from "../../../../store/actions";
import { DischargeItems, StaticResource } from "../../constant";
import DischargeSidebar from "./DischargeSidebar";

export class Discharge extends Component {
  state = {
    isSidebar: window.innerWidth <= 768 ? false : true,
    dataAll: {},
    discharge_data: {},
    procedure_id_event: null,
    procedure_id: null,
    post_operations_state: [],
    dataPost: [],
  };

  arrCheckRender = (valueCheck) => {
    let dataCheck = [];
    let name = "";
    const arraycheck = (valueCheck || []).map((r) => {
      if (r.type) {
        if (r.type === "Patient Admission") {
          name = "patient_admission";
        } else if (r.type === "NPO Status") {
          name = "npo_status";
        } else if (r.type === "Contrast Allergy") {
          name = "contrast_allergy";
        } else if (r.type === "Latex Allergy") {
          name = "latex_allergy";
        } else if (r.type === "Anticoagulation") {
          name = "anticoagulation";
        } else if (r.type === "Last Anticoagulation") {
          name = "last_anticoagulation";
        } else if (r.type === "Neurological") {
          name = "neurological";
        } else if (r.type === "Breath Sounds") {
          name = "breath_sounds";
        } else if (r.type === "Respiratory Pattern") {
          name = "respiratory_pattern";
        } else if (r.type === "Cardiac") {
          name = "cardiac";
        } else if (r.type === "INR") {
          name = "inr";
        } else if (r.type === "Creatinine") {
          name = "creatinine";
        } else if (r.type === "ASA Classification") {
          name = "asa_classification";
        } else if (r.type === "Reviewed") {
          name = "reviewed";
        } else if (r.type === "Informed") {
          name = "informed";
        } else if (r.type == "Recovery Entry Time") {
          name = "recovery_entry_time";
        } else if (r.type == "Recovery Bed") {
          name = "recovery_bed";
        } else if (r.type == "Accepting Nurse") {
          name = "accepting_nurse";
        } else if (r.type == "Notes") {
          name = "notes";
        } else {
          name = r.type;
        }
        dataCheck = [...dataCheck, { field_name: name, field_value: r?.value }];
      }
      return {
        type: r.type,
        comments: "",
        default_comments: "",
        value: r?.value,
      };
    });
    return { arraycheck, dataCheck };
  };

  async componentDidMount() {
    const procedure_id_event = getLocal("current_appointment_id");
    const procedure_id = getLocal("current_patient_id");
    this.setState({
      procedure_id_event: procedure_id_event,
      procedure_id: procedure_id,
    });
    this.props.setSidebarState(true);
    await this.props.angioPatientSnapshotLoadInit(procedure_id_event);
    if (this.props.discharge) {
      const { arraycheck, dataCheck } = this.arrCheckRender(this.props.discharge);
      this.setState({ dataPost: arraycheck });
      let neftData = {};
      dataCheck.map((r) => {
        neftData = { ...neftData, [r?.field_name]: r?.field_value };
      });
      this.setState({ dataAll: neftData });
      this.setState({
        discharge_data: { fields: dataCheck },
      });
    }
  }

  componentWillUnmount() {
    this.props.setSidebarState(false);
  }

  handleSidebar = () => {
    this.setState({ isSidebar: true });
  };

  handleCloseSidebar = () => {
    this.setState({ isSidebar: false });
  };

  toggleSidebar = () => {
    this.setState({ isSidebar: !this.state.isSidebar });
  };

  onChangeOperations = (value, type) => {
    const { dataPost, post_operations_state, dataAll } = this.state;
    this.setState({ dataAll: { ...dataAll, [type]: value } });
    const operationIndex = dataPost.findIndex((pre) => pre.type === type);
    if (operationIndex > -1) {
      if (type === "Patient and Family Received Verbal and Written Instructions and Verbalized Understanding of Discharge Instructions") {
        let existing_value = (dataPost[operationIndex] && dataPost[operationIndex]["value"]) || null;
        if (existing_value) {
          existing_value = moment(existing_value, "MM/DD/YYYY hh:mm A");
          if (moment.isMoment(existing_value) && existing_value.isValid()) {
            if (value?.hours && value?.minutes) {
              value = existing_value.format("MM/DD/YYYY hh:mm A");
            } else {
              value = existing_value.format("MM/DD/YYYY hh:mm A");
            }
          }
        }
      }
      dataPost[operationIndex] = { type, value };
    } else {
      dataPost.push({ type, value });
    }
    this.setState({ dataPost });
    this.props.dataDisCheckAll([...this.state.dataPost]);
    this.props.setProcedureReview("post_operations", post_operations_state);
  };

  onChangeTimePicker = (value, type) => {
    const { dataAll } = this.state;
    const time = moment(value).format("hh:mm A");
    if (type === "Recovery Entry Time") {
      this.setState({ dataAll: { ...dataAll, recovery_entry_time: time } });
    } else if (type === "Patient and Family Received Verbal and Written Ins…erbalized Understanding of Discharge Instructions") {
      const existing_value = dataAll[type];
      existing_value.setHours(time.hours());
      existing_value.setMinutes(time.minutes());
      this.setState({ dataAll: { ...dataAll, [type]: existing_value } });
    } else {
      this.setState({ dataAll: { ...dataAll, [type]: time } });
    }
    this.onChangeOperations(time, type);
  };

  onChangeDatePicker = (value, type) => {
    const req = "Patient and Family Received Verbal and Written Instructions and Verbalized Understanding of Discharge Instructions";
    const { dataAll } = this.state;
    const time = moment(value).format("MM/DD/YYYY");
    if (type === req) {
      this.setState({
        dataAll: {
          ...dataAll,
          [req]: time,
        },
      });
    }
    this.onChangeOperations(value, type);
  };

  onChangeSelect = (value, type) => {
    const { dataAll } = this.state;
    if (type === "Recovery Bed") {
      this.setState({ dataAll: { ...dataAll, recovery_bed: value } });
    } else if (type === "Accepting Nurse") {
      this.setState({ dataAll: { ...dataAll, accepting_nurse: value } });
    } else {
      this.setState({ dataAll: { ...dataAll, [type]: value } });
    }
    this.onChangeOperations(value, type);
  };

  clickChangeRow = (value, type) => {
    const { dataAll } = this.state;
    if (type === "Neurological") {
      this.setState({
        dataAll: {
          ...dataAll,
          neurological:
            dataAll && dataAll["neurological"] && dataAll["neurological"].some((r) => r === value)
              ? [...(dataAll["neurological"] || []).filter((r) => r !== value)]
              : [...(dataAll["neurological"] || []), value],
        },
      });
    } else if (type === "Breath Sounds") {
      this.setState({
        dataAll: {
          ...dataAll,
          breath_sounds:
            dataAll && dataAll["breath_sounds"] && dataAll["breath_sounds"].some((r) => r === value)
              ? [...(dataAll["breath_sounds"] || []).filter((r) => r !== value)]
              : [...(dataAll["breath_sounds"] || []), value],
        },
      });
    } else if (type === "Respiratory Pattern") {
      this.setState({
        dataAll: {
          ...dataAll,
          respiratory_pattern:
            dataAll && dataAll["respiratory_pattern"] && dataAll["respiratory_pattern"].some((r) => r === value)
              ? [...(dataAll["respiratory_pattern"] || []).filter((r) => r !== value)]
              : [...(dataAll["respiratory_pattern"] || []), value],
        },
      });
    } else if (type === "Cardiac") {
      this.setState({
        dataAll: {
          ...dataAll,
          cardiac:
            dataAll && dataAll["cardiac"] && dataAll["cardiac"].some((r) => r === value)
              ? [...(dataAll["cardiac"] || []).filter((r) => r !== value)]
              : [...(dataAll["cardiac"] || []), value],
        },
      });
    } else {
      this.setState({ dataAll: { ...dataAll, [type]: value } });
    }
    this.onChangeOperations(value, type);
  };

  onChangeInput = (value, type) => {
    const { dataAll } = this.state;
    if (type === "Notes") {
      this.setState({ dataAll: { ...dataAll, notes: value } });
    } else {
      this.setState({ dataAll: { ...dataAll, [type]: value } });
    }
    this.onChangeOperations(value, type);
  };

  componentDidUpdate(nextprops, nextstate) {
    if ([...nextstate.post_operations_state] !== [...this.state.post_operations_state] && this.state.post_operations_state.length > 0) {
      if (nextstate.dataPost && nextstate.dataPost.length > 0) {
        nextstate.post_operations_state.map((r) => {
          const indexcheck = (nextstate.dataPost || []).findIndex((index) => index.type == r.type);
          if (indexcheck != -1) {
            if (this.state.dataPost[indexcheck] && this.state.dataPost[indexcheck]["value"]) {
              // eslint-disable-next-line react/no-direct-mutation-state
              this.state.dataPost[indexcheck]["value"] = r?.value;
            }
          }
        });
      }
    }
  }

  updateDataProps = (value) => {
    const check = [...(this.state.dataPost || [])];
    const key = "Patient and Family Received Verbal and Written Instructions and Verbalized Understanding of Discharge Instructions";
    const index = check.findIndex((index) => index.type == key);
    if (check[index] && value[key]) {
      check[index]["value"] = `${value[key]["date"]} ${value[key]["time"]}`;
      this.setState({
        dataPost: [...check],
      });
      this.props.dataDisCheckAll([...check]);
    }
  };

  onChangeCommentCheck = async (value, type) => {
    const { dataPost, discharge_data } = this.state;
    const indexcheck = (dataPost || []).findIndex((index) => index.type === type);
    if (indexcheck >= 0 && dataPost[indexcheck]) {
      dataPost[indexcheck]["comments"] = value;

      if (discharge_data && discharge_data?.fields && discharge_data.fields[indexcheck] && discharge_data?.fields.length > 0) {
        discharge_data.fields[indexcheck]["comments"] = value;
        this.setState({ discharge_data: discharge_data });
      }

      this.setState({ dataPost: [...(dataPost || [])] });
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.setState({ firstCheck: true });
      }, 500);
      if (this.state.firstCheck) {
        if (this.props.dataDisCheckAll) {
          this.props.dataDisCheckAll([...this.state.dataPost]);
        }
      }
      this.setState({ discharge_data, dataPost });
    }
  };

  handleUnClick = (value, type, isMulti) => {
    const { dataAll, discharge_data } = this.state;
    if (!isMulti) {
      // fix when unCheck with not multi
      const neftType = type && type.length > 0 ? type.toLowerCase().replaceAll(" ", "_") : "";
      const dataNeft = [...(discharge_data?.fields || [])];
      if ((dataAll && dataAll[neftType] && dataAll[neftType] === value) || (dataAll && dataAll[type] && dataAll[type] === value)) {
        if (dataNeft && dataNeft.length > 0) {
          // uncheck = fix data local and data props
          const findIndexNeft = dataNeft.findIndex((r) => r?.field_name == neftType || r?.field_name == type);
          const findData = dataNeft.find((r) => r?.field_name == neftType || r?.field_name == type);
          if (findIndexNeft != -1 && findData?.field_name) {
            dataNeft.splice(findIndexNeft, 1, {
              ...findData,
              field_value: "",
            });
            this.setState({
              discharge_data: { fields: dataNeft },
            });
            this.clickChangeRow(null, type);
          }
        }
      } else {
        if (dataNeft && dataNeft.length > 0) {
          // update check = fix data local and data props
          const findIndexNeft = dataNeft.findIndex((r) => r?.field_name == neftType || r?.field_name == type);
          const findData = dataNeft.find((r) => r?.field_name == neftType || r?.field_name == type);
          if (findIndexNeft != -1 && findData?.field_name) {
            dataNeft.splice(findIndexNeft, 1, {
              ...findData,
              field_value: value,
            });
            this.setState({
              discharge_data: { fields: dataNeft },
            });
          }
        }
        this.clickChangeRow(value, type);
      }
    } else {
      this.clickChangeRow(value, type);
    }
  };

  sidebarRef = null;
  constructor(props) {
    super(props);
    this.sidebarRef = React.createRef();
  }

  render() {
    const { isSidebar, discharge_data } = this.state;
    return (
      <div className="clinicWorkflow__main-section">
        <div className="eventsContainer has-sub-header" style={{ paddingTop: "1rem" }}>
          <ScrollWrapper css="no-padding x-hidden">
            <div className="conditionList" style={{ minHeight: "100%" }}>
              <div className="listSection">
                {DischargeItems.map((condition, index) => (
                  <HPIItem
                    key={`ros-vitals-${index}`}
                    label={condition.label}
                    isIcon={condition.isIcon}
                    options={condition.options}
                    type={condition.type}
                    value={discharge_data}
                    multiple={condition.multiple}
                    multiline={condition.multiLine}
                    onChangeCommentCheck={this.onChangeCommentCheck}
                    onChangeTimePicker={(value, type) => this.onChangeTimePicker(value, type)}
                    onChangeDatePicker={(value, type) => this.onChangeDatePicker(value, type)}
                    onChangeSelect={(value, type) => this.onChangeSelect(value, type)}
                    clickChangeRow={(value, type) => {
                      this.handleUnClick(value, type, condition.multiple);
                    }}
                    onChangeInput={(value, type) => {
                      this.onChangeInput(value, type);
                    }}
                    updateDataProps={this.updateDataProps}
                    isLocked={this.props.is_locked}
                  />
                ))}
              </div>
            </div>
          </ScrollWrapper>
        </div>
        {isSidebar === true && (
          <DischargeSidebar
            ref={this.sidebarRef}
            value={this.state.discharge_data}
            resource={StaticResource}
            dataAll={this.state.dataAll}
            isShow={isSidebar}
            handleCloseSidebar={this.handleCloseSidebar}
          />
        )}
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  discharge: state.patientExamination.discharge,
  is_locked: state.common.is_locked,
  patientResource: state.patientSchedule.selectedPatientResource?.id,
});

const mapDispatchToProps = {
  setProcedureReview,
  patientExaminationSave,
  angioPatientSnapshotLoadInit,
  setSidebarState,
};

export default connect(mapStateToProps, mapDispatchToProps)(Discharge);
