import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import EditableMaterialText from '../../../common/components/EditableText/MaterialInput';
import ToastMessage from '../../../common/components/Toast';
import {
  patientScheduleVerifyInsurance
} from '../../../store/actions';

const InsuranceDetail = ({ resource, paymentForm }) => {
  const [policy_number, setPolicyNumber] = useState(
    resource?.policy_number || {},
  );
  const isInsuranceVerified = useSelector(
    (state) => state.patientSchedule.isInsuranceVerified,
  );
  const coverages = useSelector((state) => state.patientSchedule.coverages);

  const dispatch = useDispatch();

  const primaryInsurances = [];
  const secondInsurances = [];
  for (const insurance of coverages) {
    if (insurance.type === 'primary') {
      primaryInsurances.push(insurance);
    }
    if (insurance.type === 'secondary') {
      secondInsurances.push(insurance);
    }
  }

  const onVerifiy = () => {
    const patientId = resource?.id;
    dispatch(
      patientScheduleVerifyInsurance(patientId, {
        policy_number,
      }),
    );
  };

  return (
    <div className="resourceContent">
      <div className="resourceInfo d-flex">
        <EditableMaterialText
          label={'Policy No'}
          value={policy_number}
          handleChange={(value) => {
            setPolicyNumber(value);
          }}
        />
      </div>
      <hr />
      {isInsuranceVerified ? (
        <>
          <div
            className="resourceContent pr-7px"
            style={{ position: 'relative' }}
          >
            {primaryInsurances && !!primaryInsurances.length && (
              <React.Fragment>
                <div className="resourceInfo" style={{ marginTop: '0.5rem' }}>
                  <b>Primary Insurance</b>
                </div>
                <div className="resourceInfo">
                  {(primaryInsurances || []).map((primary, index) => (
                    <div key={`primary-insurance-${index}`}>
                      {primary.coverage_name}
                    </div>
                  ))}
                </div>
              </React.Fragment>
            )}
            {secondInsurances && !!secondInsurances.length && (
              <React.Fragment>
                <div className="resourceInfo" style={{ marginTop: '0.5rem' }}>
                  <b>Secondary Insurance</b>
                </div>
                <div className="resourceInfo">
                  {(secondInsurances || []).map((second, index) => (
                    <div key={`second-insurance-${index}`}>
                      {second.coverage_name}
                    </div>
                  ))}
                </div>
              </React.Fragment>
            )}
          </div>
          <div className="resourceInfo d-flex" style={{ marginTop: '0.5rem' }}>
            <ToastMessage msg={'Insurance Verified'} status={'default'} />
          </div>
        </>
      ) : (
        <div className="actionContent no-padding">
          <button onClick={onVerifiy}>Verify Insurance</button>
        </div>
      )}
    </div>
  );
};

export default InsuranceDetail;
