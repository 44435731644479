import React, { useState } from "react";
import CardBlock from "../../../common/components/Card";

import "../style.scss";
import DiseaseCondition from "./DiseaseCondition";
import IndicationCode from "./IndicationCode";
import DocumentationBlock from "./TemplateDocumentation";
import UltrasoundSearch from "./UltrasoundSearch";

const ProcedureDetail = (props) => {
  const [resource, setResource] = useState(null);

  return (
    <div className="clinicWorkflow__main-section" style={{ padding: "0 0.5rem 0 1rem", height: "calc(100vh - 208px)" }}>
      <div className="fullContainer has-no-sidebar">
        <div
          className="mainContainer"
          style={{
            marginLeft: "-10px",
            paddingTop: "0",
            paddingBottom: "0",
            display: "flex",
            flexWrap: "wrap",
            flexDirection: "row",
            alignItems: "stretch",
            width: "100%",
          }}
        >
          <CardBlock isOverFlow={true} isOverFlowHidden={true} title="Search Ultrasound" varient="half" other="" contentPadding="1rem">
            <UltrasoundSearch setResource={setResource} />
          </CardBlock>

          <CardBlock isOverFlow={true} isOverFlowHidden={true} title="Disease Conditions" varient="half" other="" contentPadding="1rem">
            <DiseaseCondition resource={resource} />
          </CardBlock>
        </div>
        <div
          className="mainContainer"
          style={{
            marginLeft: "-10px",
            paddingTop: "0",
            paddingBottom: "0",
            display: "flex",
            flexWrap: "wrap",
            flexDirection: "row",
            alignItems: "stretch",
            width: "100%",
          }}
        >
          <CardBlock isOverFlow={true} isOverFlowHidden={true} title="Indication Codes" varient="half" other="" contentPadding="1rem">
            <IndicationCode resource={resource} />
          </CardBlock>

          <CardBlock title="Template Documentation" varient="half" other="">
            <DocumentationBlock resource={resource} />
          </CardBlock>
        </div>
      </div>
    </div>
  );
};

export default ProcedureDetail;
