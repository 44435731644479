import { Tabs } from "@material-ui/core";
import { Icon, Tooltip } from "antd";
import _ from "lodash";
import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import PlanOrders from "../../../common/components/PlanOder/PlanOrders";
import PusherCheck from "../../../common/components/PusherCheck";
import TopBar from "../../../common/components/SubTopBar";
import { FemaleSVG, MaleSVG } from "../../../common/components/icons/FaxSVG";
import Appointments from "../../../common/views/Appointment";
import Medications from "../../../common/views/Medications";
import PatientChart from "../../../common/views/PatientChart";
import PatientOverview from "../../../common/views/PatientSnapshot";
import { getLocal } from "../../../helpers/Local";
import RemoveN from "../../../helpers/RemoveN";
import {
  HPISaveCondition,
  changeTab,
  clinicProviderReviewLoadInit,
  getOnlyProviders,
  patientExaminationCopyLast,
  patientExaminationSave,
  patientExaminationSetData,
  patientExaminationSetNomalCheck,
  releaseLock,
  remove_patient_on_schedules,
  resetStateResource,
  save_plan_patient,
  setActiveReviewChange,
  setFirstLoadingActive,
  setGlobalLoading,
  setGlobalToastr,
} from "../../../store/actions";
import { TabHeaders } from "../constant";
import ClinicSchedule from "./providerSubViews/ClinicSchedule";
import HPIContainer from "./providerSubViews/HPIContainer";
import PhysicalExamination from "./providerSubViews/PhysicalExamination";
import Review from "./providerSubViews/Review";
import RosVitals from "./providerSubViews/RosVitals";

class Provider extends Component {
  state = {
    activeTab: 0,
    checkSelectedInterval: null,
    isSelectedPatient: false,
    ischeck: false,
    pusherCheckBuild: [],
    pusherCheckUnfinalized: [],
    rosCoppy: false,
    physicalCoppy: false,
    isModalVisible: false,
    stateStatusChange: null,
  };

  componentDidMount() {
    this.props.getOnlyProviders();
  }

  componentWillUnmount() {
    const procedure_id = getLocal("current_appointment_id");
    this.props.releaseLock(procedure_id, {});
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.activeTab == 0 && nextProps?.patientResource && nextProps?.firstLoadingActive) {
      this.setState({
        activeTab: 7,
      });
      this.props.setFirstLoadingActive(false);
    }
    if (nextProps.loadAgian) {
      this.setState({
        activeTab: 0,
      });
    }
    if (nextProps.tabActive && nextProps.tabActive == "patient_snapshot") {
      this.setState({
        activeTab: 1,
      });
    } else if (nextProps.tabActive && nextProps.tabActive == "plan_order") {
      this.setState({
        activeTab: 6,
      });
    } else if (nextProps.tabActive && nextProps.tabActive == "vital") {
      this.setState({
        activeTab: 4,
      });
    }
    const patientResourceProps = nextProps.patientResource || {};
    if (Object.keys(patientResourceProps).length > 0) {
      this.setState({
        isSelectedPatient: true,
        rosCoppy: false,
        physicalCoppy: false,
      });
    } else {
      this.setState({
        isSelectedPatient: false,
      });
    }
  }

  refreshImageList = () => {};

  goNextTab_ = () => {
    const checkSelectedInterval = setInterval(this.goNextTab, 50);
    this.setState({ ...this.state, checkSelectedInterval });
  };

  goNextTab = () => {
    const { patientResource, editLock } = this.props;
    if (editLock.locked === 1) {
      this.props.setGlobalToastr({
        header: "Clinic Workflow",
        message: "This procedure has been locked by " + editLock.user,
        type: "failed",
        link: "Notify " + editLock.user,
        exlink: "Force release " + editLock.user,
      });
      this.setState({ ...this.state, isModalVisible: true });
      clearInterval(this.state.checkSelectedInterval);
    } else {
      if (this.state.isSelectedPatient) {
        if (patientResource && patientResource.has_valid_insurance === 0) {
          this.props.setGlobalToastr({
            header: "Clinic Workflow",
            message: "Please verify the patient insurance before proceeding",
            type: "failed",
          });
        }
        clearInterval(this.state.checkSelectedInterval);
        this.setState({
          ...this.state,
          activeTab: 1,
          checkSelectedInterval: null,
        });
      }
    }
  };

  renderSideComponent = () => {
    const { resourceCheck } = this.props;
    if (!resourceCheck || !Object.keys(resourceCheck).length) {
      return null;
    }

    const fullName = (resourceCheck?.first_name + " " + resourceCheck?.last_name).toUpperCase();
    const age = resourceCheck?.date_of_birth ? "(" + moment().diff(resourceCheck?.date_of_birth, "years") + ")" : "";
    const allerrgies = [];
    if (resourceCheck?.allergies) {
      (resourceCheck?.allergies || []).forEach((allergy, index) => {
        const allergyName =
          allergy.reaction === "anaphylaxis" || allergy.status === "anaphylaxis" ? (
            <span
              style={{
                color: "red",
                fontSize: "0.625rem",
                fontWeight: 600,
              }}
              key={`${allergy?.id}-${allergy.allergy_name}`}
            >
              {allergy.allergy_name}
              {index < resourceCheck?.allergies.length - 1 ? ", " : ""}
            </span>
          ) : (
            <>
              {allergy.allergy_name}
              {index < resourceCheck?.allergies.length - 1 ? ", " : ""}
            </>
          );
        allerrgies.push(allergyName);
      });
    }

    return (
      <div
        style={{
          display: "flex",
          textAlign: "left",
          paddingRight: "12px",
          paddingLeft: "6px",
          paddingTop: "0px",
        }}
      >
        <div>
          <h3
            style={{
              color: "#152934",
              fontWeight: 400,
              fontSize: "1.125rem",
              lineHeight: "24px",
              whiteSpace: "nowrap",
              textAlign: "right",
              margin: 0,
              position: "relative",
              left: 1.5,
            }}
          >
            {fullName} {age}
          </h3>
          {allerrgies.length === 0 && (
            <div
              style={{
                textAlign: "right",
                whiteSpace: "nowrap",
                lineHeight: "10px",
              }}
            >
              <span
                style={{
                  color: "#da1e28",
                  fontSize: "0.625rem",
                  fontWeight: 600,
                }}
              >
                {resourceCheck?.no_drug_allergies ? "NKDA" : "Pending Data"}
              </span>
            </div>
          )}
          {allerrgies.length >= 1 && allerrgies.length < 3 && (
            <div
              style={{
                textAlign: "right",
                whiteSpace: "nowrap",
                lineHeight: "10px",
                color: "black",
                fontSize: "0.625rem",
                fontWeight: 600,
              }}
            >
              {allerrgies.map((allergy, index) => (
                <React.Fragment key={`Al-${index}`}>{allergy}</React.Fragment>
              ))}
            </div>
          )}
          {allerrgies.length >= 3 && (
            <Tooltip placement="bottomRight" title={(resourceCheck?.allergies || []).map((allergy) => allergy?.allergy_name).join(", ")}>
              <div
                style={{
                  textAlign: "right",
                  whiteSpace: "nowrap",
                  lineHeight: "10px",
                  color: "black",
                  fontSize: "0.625rem",
                  fontWeight: 600,
                }}
              >
                {allerrgies.map((allergy, index) => (
                  <React.Fragment key={`Alg-${index}`}>{allergy}</React.Fragment>
                ))}
              </div>
            </Tooltip>
          )}
        </div>

        {resourceCheck?.gender && resourceCheck?.gender.toUpperCase() == "MALE" ? (
          <MaleSVG style={{ marginLeft: "8px", height: "unset", width: "26px" }} />
        ) : (
          <FemaleSVG style={{ marginLeft: "8px", height: "unset", width: "26px" }} />
        )}
      </div>
    );
  };

  conditionClickProps = (value) => {
    this.setState({
      stateCondition: value,
    });
  };

  goNextTabReview = () => {
    this.setState({
      activeTab: 7,
    });
    this.props.setActiveReviewChange(false);
  };

  RosChange = async (scope, data) => {
    await this.props.setGlobalLoading(true);
    await this.props.patientExaminationSetData(scope, data, this.props.all_normal_ros);
    await this.props.setGlobalLoading(false);
  };

  RosCoppy = async (id) => {
    await this.props.setGlobalLoading(true);
    const procedure_id = getLocal("current_appointment_id");
    await this.props.patientExaminationCopyLast(procedure_id, "ROS");
    this.setState({
      rosCoppy: true,
    });
    await this.props.setGlobalLoading(false);
  };

  RosCheckAll = async (scope, data, checked) => {
    await this.props.setGlobalLoading(true);
    await this.props.patientExaminationSetNomalCheck(checked ? 1 : 0, "ROS");
    await this.props.patientExaminationSetData(scope, data, this.props.all_normal_ros);
    await this.props.setGlobalLoading(false);
  };

  //
  PhysicalChange = async (scope, data) => {
    await this.props.setGlobalLoading(true);
    await this.props.patientExaminationSetData(scope, data, this.props.all_normal_exam);
    await this.props.setGlobalLoading(false);
  };

  PhysicalCoppy = async (id) => {
    await this.props.setGlobalLoading(true);
    const procedure_id = getLocal("current_appointment_id");
    await this.props.patientExaminationCopyLast(procedure_id, "patient_examination");
    this.setState({
      physicalCoppy: true,
    });
    await this.props.setGlobalLoading(false);
  };

  PhysicalCheckAll = async (scope, data, checked) => {
    await this.props.setGlobalLoading(true);
    await this.props.patientExaminationSetNomalCheck(checked ? 1 : 0, "Physical");
    await this.props.patientExaminationSetData(scope, data, this.props.all_normal_exam);
    await this.props.setGlobalLoading(false);
  };

  changeTabIndex = async (index) => {
    const { patientResource, is_locked } = this.props;
    const procedure_id = getLocal("current_appointment_id");
    if (index === 0) {
      await this.props.releaseLock(procedure_id, {});
      await this.props.remove_patient_on_schedules();
      await this.props.resetStateResource();
    }
    if (is_locked == 0 || is_locked == null) {
      if (
        this.state.activeTab === 0 ||
        this.state.activeTab === 1 ||
        this.state.activeTab === 2 ||
        this.state.activeTab === 8 ||
        this.state.activeTab === 9
      ) {
        if (index === 7) {
          this.props.clinicProviderReviewLoadInit(procedure_id);
        }
      }
      if (this.state.activeTab === 3) {
        const patient_id = patientResource?.id;
        const conditionCheck = this.props?.conditions;
        await this.props.setGlobalLoading(true);
        const fixArr = (conditionCheck || []).map((r) => {
          return {
            ...r,
            questions: (r?.questions || []).map((q) => {
              return {
                ...q,
                comments: RemoveN(q?.comments || ""),
              };
            }),
            text: RemoveN(r?.text || ""),
          };
        });
        await this.props.HPISaveCondition(patient_id, procedure_id, fixArr);
        if (index === 7) {
          this.props.clinicProviderReviewLoadInit(procedure_id);
        }
        await this.props.setGlobalLoading(false);
      }
      if (this.state.activeTab === 4) {
        const {
          ROSs,
          vitals,
          all_normal_ros,
          copy_from_last_visit_ros,
          original_ROSs,
          original_vitals,
          original_all_normal_ros,
          original_copy_from_last_visit_ros,
        } = this.props;
        const patient_id = patientResource?.id;
        const fixArr = (ROSs || []).map((r) => {
          return {
            ...r,
            comments: RemoveN(r?.comments || ""),
          };
        });
        await this.props.setGlobalLoading(true);
        if (
          !_.isEqual(ROSs, original_ROSs) ||
          !_.isEqual(vitals, original_vitals) ||
          all_normal_ros != original_all_normal_ros ||
          copy_from_last_visit_ros != original_copy_from_last_visit_ros
        ) {
          await this.props.patientExaminationSave(procedure_id, {
            patient_id,
            all_normal_ros,
            ROSs: fixArr,
            copy_from_last_visit_ros,
            vitals,
          });
        }
        if (index === 7) {
          this.props.clinicProviderReviewLoadInit(procedure_id);
        }
        await this.props.setGlobalLoading(false);
      }
      if (this.state.activeTab === 5) {
        const {
          physical_examinations,
          vessel_states,
          all_normal_exam,
          copy_from_last_visit_exam,
          original_physical_examinations,
          original_vessel_states,
          original_all_normal_exam,
          original_copy_from_last_visit_exam,
        } = this.props;
        const patient_id = patientResource?.id;
        const fixArr = (physical_examinations || []).map((r) => {
          return {
            ...r,
            comments: RemoveN(r?.comments || ""),
          };
        });
        const procedure_id = getLocal("current_appointment_id");
        await this.props.setGlobalLoading(true);
        if (
          !_.isEqual(physical_examinations, original_physical_examinations) ||
          !_.isEqual(vessel_states, original_vessel_states) ||
          all_normal_exam != original_all_normal_exam ||
          copy_from_last_visit_exam != original_copy_from_last_visit_exam
        ) {
          await this.props.patientExaminationSave(procedure_id, {
            all_normal_exam: all_normal_exam,
            patient_id,
            physical_examinations: fixArr,
            copy_from_last_visit_exam,
            vessel_states,
          });
        }
        if (index === 7) {
          this.props.clinicProviderReviewLoadInit(procedure_id);
        }
        await this.props.setGlobalLoading(false);
      }
      if (this.state.activeTab === 6) {
        const procedure_id = getLocal("current_appointment_id");
        await this.props.save_plan_patient(this.props.params_plan);
        if (index === 7) {
          this.props.clinicProviderReviewLoadInit(procedure_id);
        }
      }
    }
    await this.props.changeTab({
      tabActive: null,
      accordion: null,
      step: null,
    });

    this.setState({ activeTab: index });
  };

  setIndexForce = () => {
    this.props.remove_patient_on_schedules();
    this.props.resetStateResource();
    this.setState({ activeTab: 0 });
  };

  getItemBuild = (value) => {
    if (value !== null) {
      if (this.state.pusherCheckBuild.length == 0 || !this.state.pusherCheckBuild.some((r) => r == value)) {
        this.setState({
          pusherCheckBuild: [...this.state.pusherCheckBuild, value],
        });
      }
    }
  };

  dispatchAppointmentUnfinalized = (value) => {
    if (this.state.pusherCheckUnfinalized.length == 0 || !this.state.pusherCheckUnfinalized.some((r) => r == value)) {
      this.setState({
        pusherCheckUnfinalized: [...this.state.pusherCheckUnfinalized, value],
      });
    }
  };

  updateStatusPusher = (value) => {
    if (value && value.appointment_id) {
      this.setState({
        stateStatusChange: value,
      });
    }
  };

  render() {
    const { activeTab, isSelectedPatient, physicalCoppy, rosCoppy } = this.state;
    const { patientResource } = this.props;
    return (
      <div className={`clinicWorkflow__main-page`}>
        <TopBar
          title="Provider Module"
          subStatus={""}
          sideComponent={this.renderSideComponent()}
          onRefreshImageList={this.refreshImageList}
          isBarcode={isSelectedPatient}
        />

        <div className={`clinicWorkflow__main-container ${this.props.isSidebar ? "openSidebar" : ""}`}>
          <div className="clinicWorkflow__main-subTitle tabContainer">
            <div className="tabHeaders" style={{ height: "29px" }}>
              <Tabs
                TabIndicatorProps={{ style: { display: "none" } }}
                value={activeTab}
                scrollButtons="auto"
                variant="scrollable"
                aria-label="scrollable force tabs example"
              >
                {TabHeaders.map((tab, index) =>
                  (this.state.isSelectedPatient && index < 3 && this.props.editLock.locked === 0) ||
                  (this.state.isSelectedPatient && patientResource.has_valid_insurance === 1 && this.props.editLock.locked === 0) ? (
                    <div
                      id={`${tab?.label && tab?.label.length > 0 && tab?.label.replaceAll(/[&\/\\#,+()$~%.'":*?<>{}]/g, "").replaceAll(" ", "_")}`}
                      key={`tabHeaders-${tab.label}-${index}`}
                      className={`tabHeaders-item ${activeTab === index ? "active" : ""}`}
                      style={{ width: tab.width }}
                      onClick={() => this.changeTabIndex(index)}
                    >
                      {tab.label}
                    </div>
                  ) : (
                    <div
                      id={`${tab?.label && tab?.label.length > 0 && tab?.label.replaceAll(/[&\/\\#,+()$~%.'":*?<>{}]/g, "").replaceAll(" ", "_")}`}
                      key={`tabHeaders-${tab.label}-${index}`}
                      className={`tabHeaders-item ${activeTab === index ? "active" : ""} ${index > 0 && "disabled"}`}
                      style={{ width: tab.width }}
                    >
                      {tab.label}
                    </div>
                  )
                )}
              </Tabs>
            </div>
          </div>

          <PusherCheck updateStatus={this.updateStatusPusher} getItemBuild={this.getItemBuild} setIndex={this.setIndexForce}></PusherCheck>
          {activeTab >= 0 && (
            <ClinicSchedule
              stateStatusProps={this.state.stateStatusChange}
              updateStatusProps={() => {
                this.setState({ stateStatusChange: null });
              }}
              pusherCheckBuild={this.state.pusherCheckBuild}
              pusherCheckUnfinalized={this.state.pusherCheckUnfinalized}
              display={activeTab === 0}
              goNextTab={this.goNextTab_}
              goNextTabReview={this.goNextTabReview}
              isClinicWorkFlow={true}
            />
          )}
          {patientResource && activeTab === 1 && (
            <PatientOverview
              sidebarIcon={<Icon type="file-text" theme="twoTone" twoToneColor="#740937" />}
              isShowGoto={false}
              isClinic={true}
              patientResource={patientResource}
              loadingResource={this.props.loadingResource}
            />
          )}
          {patientResource && activeTab === 2 && (
            <PatientChart sidebarIcon={<Icon type="file-text" theme="twoTone" twoToneColor="#740937" />} patientResource={patientResource} />
          )}
          {patientResource && patientResource.has_valid_insurance === 1 && activeTab === 3 && <HPIContainer />}

          {patientResource && patientResource.has_valid_insurance === 1 && activeTab === 4 && (
            <RosVitals
              display={true}
              RosChange={this.RosChange}
              RosCheckAll={this.RosCheckAll}
              RosCoppy={this.RosCoppy}
              rosCoppy={rosCoppy}
              isSlide={patientResource}
            />
          )}

          {patientResource && patientResource.has_valid_insurance === 1 && activeTab === 5 && (
            <PhysicalExamination
              display={true}
              PhysicalChange={this.PhysicalChange}
              PhysicalCheckAll={this.PhysicalCheckAll}
              PhysicalCoppy={this.PhysicalCoppy}
              physicalCoppy={physicalCoppy}
              isSlide={patientResource}
            />
          )}

          {patientResource && patientResource.has_valid_insurance === 1 && activeTab === 6 && (
            <PlanOrders navigateToReview={this.goNextTabReview} sidebarIcon={<Icon type="file-text" theme="twoTone" twoToneColor="#740937" />} />
          )}

          {patientResource && patientResource.has_valid_insurance === 1 && activeTab === 7 && (
            <Review sidebarIcon={<Icon type="file-text" theme="twoTone" twoToneColor="#740937" />} />
          )}
          {patientResource && patientResource.has_valid_insurance === 1 && activeTab === 8 && (
            <Medications sidebarIcon={<Icon type="file-text" theme="twoTone" twoToneColor="#740937" />} />
          )}
          {patientResource && patientResource.has_valid_insurance === 1 && activeTab === 9 && (
            <Appointments
              sidebarIcon={<Icon type="file-text" theme="twoTone" twoToneColor="#740937" />}
              display={activeTab === 9 ? "flex" : "none"}
            />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  firstLoadingActive: state.common.firstLoadingActive,
  is_locked: state.clinicProviderReview.is_locked,
  patientResource: state.clinicProvider.patientResource,
  conditions: state.clinicProviderHPI.conditions,
  tabActive: state.clinicProviderReview.tabActive,
  loadingResource: state.clinicProvider.loadingResource,
  ROSs: state.patientExamination.ROSs,
  original_ROSs: state.patientExamination.original_ROSs,
  original_vitals: state.patientExamination.original_vitals,
  vitals: state.patientExamination.vitals,
  all_normal_ros: state.patientExamination.all_normal_ros,
  original_all_normal_ros: state.patientExamination.original_all_normal_ros,
  physical_examinations: state.patientExamination.physical_examinations || [],
  original_physical_examinations: state.patientExamination.original_physical_examinations || [],
  vessel_states: state.patientExamination.vessel_states,
  original_vessel_states: state.patientExamination.original_vessel_states,
  all_normal_exam: state.patientExamination.all_normal_exam,
  original_all_normal_exam: state.patientExamination.original_all_normal_exam,
  resourceCheck: state.patientSnapshot.resource,
  isSidebar: state.sidebar.isSidebarOpen,
  copy_from_last_visit_ros: state.patientExamination.copy_from_last_visit_ros,
  original_copy_from_last_visit_ros: state.patientExamination.original_copy_from_last_visit_ros,
  copy_from_last_visit_exam: state.patientExamination.copy_from_last_visit_exam,
  editLock: state.common.editLock,
  params_plan: state.common.params_plan,
  loadAgian: state.common.loadAgian,
});
const mapDispatchToProps = {
  patientExaminationSetData,
  patientExaminationSave,
  patientExaminationCopyLast,
  patientExaminationSetNomalCheck,
  changeTab,
  getOnlyProviders,
  HPISaveCondition,
  clinicProviderReviewLoadInit,
  setActiveReviewChange,
  setGlobalToastr,
  setGlobalLoading,
  save_plan_patient,
  releaseLock,
  setFirstLoadingActive,
  remove_patient_on_schedules,
  resetStateResource,
};
export default connect(mapStateToProps, mapDispatchToProps)(Provider);
