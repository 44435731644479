import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Icon } from "antd";
import DataTable from "../../../common/components/DataTable/DataTable";
import ToastMessage from "../../../common/components/Toast";
import { getPriorAuthorizationLocalApi } from "../../../services/api.services";
import {
  getAuthorizationNumbers,
  getPriorAuthorization,
  getPriorFilterTypes,
  selectPatient,
  selectPrior,
  setDefaultSaveResource,
  setGlobalLoading,
  setPriorData,
} from "../../../store/actions";

const PriorResultTable = ({ title, search, filter, handleSidebar, isNonNetworkTab }) => {
  const loading = useSelector((state) => state.prior.loadingPriors);
  const data = useSelector((state) => state?.prior?.priors?.data || []);
  const savedResource = useSelector((state) => state.prior.savedResoruces);
  const errorRest = useSelector((state) => state.common.globalErrorMessage);
  const dispatch = useDispatch();
  const [isToastr, setIsToastr] = useState(false);
  const [loadingLocal, setLoadingLocal] = useState(false);
  const ref = useRef(null);
  const [page, setPage] = useState(1);
  const [offLoad, setOffLoad] = useState(true);
  const [tableLoad, setTableLoads] = useState([]);

  useEffect(() => {
    dispatch(setPriorData("loadingPriors", true));
    dispatch(setPriorData("resource", null));
    dispatch(getPriorAuthorization(filter, 1));
    setPage(1);
    setOffLoad(true);
    dispatch(getPriorFilterTypes());
    dispatch(getAuthorizationNumbers());
  }, []);

  useEffect(() => {
    setPage(1);
    setTableLoads([]);
  }, [filter]);

  const saveResourceApi = async () => {
    setIsToastr(true);
    await dispatch(setPriorData("loadingPriors", true));
    await dispatch(setPriorData("loadingResource", true));
    await dispatch(setPriorData("resource", null));
    await dispatch(setDefaultSaveResource());
    await dispatch(getPriorAuthorization(filter, 1));
    setPage(1);
    setOffLoad(true);
    setTimeout(() => {
      setIsToastr(false);
    }, 2000);
  };

  useEffect(() => {
    if (savedResource) {
      saveResourceApi();
    }
  }, [savedResource]);

  useEffect(() => {
    if (data && data.length > 0) {
      setTableLoads([...data]);
    }
  }, [data]);

  const handleRow = async (index) => {
    if (tableLoad[index] && tableLoad[index]["patient_id"]) {
      dispatch(setGlobalLoading(true));
      await dispatch(selectPatient(tableLoad[index]["patient_id"]));
      await dispatch(setPriorData("loadingResource", true));
      await dispatch(selectPrior(tableLoad[index]));
      dispatch(setGlobalLoading(false));
      handleSidebar();
    }
  };

  const showToast = () => {
    return <ToastMessage type="Prior Authorizations" status={"success"} msg={"Prior Authorization Saved!"} />;
  };

  const loadMore = async (values) => {
    const { scrollTop, scrollHeight, clientHeight } = values;
    const pad = 30; // px of the bottom
    const t = (scrollTop + pad) / (scrollHeight - clientHeight);
    if (t >= 1 && Number.isFinite(t) && !loadingLocal && page >= 1) {
      if (offLoad) {
        dispatch(setGlobalLoading(true));
        setLoadingLocal(true);
        let listUpdate = null;
        listUpdate = await getPriorAuthorizationLocalApi(filter, page + 1);

        if (listUpdate.data) {
          listUpdate = listUpdate?.data?.data || [];
          if (listUpdate && listUpdate.length > 0) {
            setTableLoads([...tableLoad, ...listUpdate]);
            setPage(page + 1);
            dispatch(setGlobalLoading(false));
            setLoadingLocal(false);
          } else {
            setOffLoad(false);
            dispatch(setGlobalLoading(false));
            setLoadingLocal(false);
          }
        } else {
          setOffLoad(false);
          dispatch(setGlobalLoading(false));
          setLoadingLocal(false);
        }
      }
    }
  };

  useEffect(() => {
    dispatch(setGlobalLoading(loading));
  }, [loading]);

  return (
    <div ref={ref} className="tableContainer">
      {isToastr && !errorRest && showToast()}
      <DataTable
        title="prior_result"
        hideHeader={true}
        isHideTableCss={true}
        labels={["Name", "Type", "Date", "Insurance", "Authorization Number", "Expiration", "Status"]}
        widths={["23%", "12.83%", "12.83%", "12.83%", "12.83%", "12.83%"]}
        columns={[
          { sortable: false, key: "name" },
          { sortable: false, key: "type" },
          { sortable: false, key: "date" },
          { sortable: false, key: "insurance" },
          { sortable: false, key: "authorization" },
          { sortable: false, key: "expiration" },
          { sortable: false, key: "status" },
        ]}
        defaultActive={-1}
        disableDelete={true}
        loadMore={loadMore}
        handleClickRow={(row, index) => {
          handleRow(index);
        }}
        rowData={(isNonNetworkTab ? [] : tableLoad || []).map((row, index) => {
          return {
            name: (
              <div
                className="iconField td with-icon"
                style={{
                  display: "flex",
                  paddingLeft: "0.25rem",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                {row?.icon && <div className="d-flex">{row?.icon}</div>}
                {!row?.icon && (
                  <div className="d-flex">
                    {row?.status === "Processing" ? (
                      <Icon type="question-circle" theme="twoTone" twoToneColor="#DA1E28" />
                    ) : row?.status !== "Complete" ? (
                      <Icon type="warning" theme="twoTone" twoToneColor="#FF832B" />
                    ) : (
                      <Icon type="check-circle" theme="twoTone" twoToneColor="#24A148" />
                    )}
                  </div>
                )}
                <p style={{ paddingLeft: "0.75rem" }}>{row?.patient_name}</p>
              </div>
            ),
            type: row?.type || "",
            insurance: row?.plan_name || "",
            date: (moment(row?.date_of_procedure).isValid() && moment(row?.date_of_procedure).format("MM/DD/YYYY hh:mm A")) || "",
            authorization: row?.authorization_number,
            expiration: (moment(row?.expiration_date).isValid() && moment(row?.expiration_date).format("MM/DD/YYYY")) || "",
            status: row?.status || "",
          };
        })}
      />
    </div>
  );
};

export default PriorResultTable;
