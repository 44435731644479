import { DatePicker, Select } from "antd";
import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import { useDispatch, useSelector } from "react-redux";
import CardBlock from "../../../../common/components/Card";
import DataTable from "../../../../common/components/DataTable/WhiteHeaderTable";
import TopBar from "../../../../common/components/SubTopBar";
import { getPCMSummary } from "../../../../store/actions";
const { Option } = Select;

const Summary = () => {
  const dispatch = useDispatch();
  const dateFormat = "MM/DD/YYYY";
  const pcmSummary = useSelector((state) => state.common.pcm_summary);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [chartType, setChartType] = useState("accounts_receivable");
  const [tableData] = useState([]);
  const [categoryData, setCategoryData] = useState([]);

  const getMillionsValue = (value) => {
    let newValue = value;
    if (value >= 1000) {
      const suffixes = ["", "K", "M", "B", "T"];
      const suffixNum = Math.floor(("" + value).length / 3);
      let shortValue = "";
      for (let precision = 2; precision >= 1; precision--) {
        shortValue = parseFloat((suffixNum != 0 ? value / Math.pow(1000, suffixNum) : value).toPrecision(precision));
        const dotLessShortValue = (shortValue + "").replace(/[^a-zA-Z 0-9]+/g, "");
        if (dotLessShortValue.length <= 2) {
          break;
        }
      }
      if (shortValue % 1 != 0) shortValue = shortValue.toFixed(1);
      newValue = shortValue + suffixes[suffixNum];
    }
    return newValue;
  };

  const chartTypes = [
    {
      name: "Accounts Receivable",
      id: "accounts_receivable",
    },
    {
      name: "0-30",
      id: "0_30",
    },
    {
      name: "30-60",
      id: "30_60",
    },
    {
      name: "60-90",
      id: "60_90",
    },
    {
      name: "90-120",
      id: "90_120",
    },
    {
      name: "120+",
      id: "gt_120",
    },
    {
      name: "Billing Frequency",
      id: "billing_frequency",
    },
    {
      name: "Assignment Activity",
      id: "assignment_activity",
    },
  ];
  const chartOptions = {
    responsive: true,
    animation: false,
    maintainAspectRatio: false,
    defaults: {
      elements: {
        point: {
          backgroundColor: "#000000",
          borderColor: "#000000",
        },
      },
    },
    layout: {
      padding: 30,
    },
    plugins: {
      legend: {
        position: "right",
        display: false,
        labels: {
          color: "#152935",
          family: '"IBM plex sans", "Helvetica Neue", sans-serif',
        },
      },
    },
    scales: {
      y: {
        min: 0,
        ticks: {
          font: {
            size: "0.875rem",
            family: '"IBM plex sans", "Helvetica Neue", sans-serif',
          },
          color: "#152935",
          callback: function (value, index, values) {
            return getMillionsValue(value);
          },
        },
      },
      x: {
        ticks: {
          padding: 10,
          font: {
            size: "0.875rem",
            family: '"IBM plex sans", "Helvetica Neue", sans-serif',
          },
          color: "#000",
        },
      },
    },
  };
  const [data, setData] = useState({
    labels: [],
    datasets: [],
  });

  useEffect(() => {
    if (!chartType) return;
    dispatch(getPCMSummary(chartType, startDate, endDate));
  }, [startDate, endDate, chartType]);

  useEffect(() => {
    dispatch(getPCMSummary(chartType, startDate, endDate));
  }, []);

  useEffect(() => {
    if (!pcmSummary) return;
    const newData = {};
    newData.labels = pcmSummary.data.map((data) => {
      return data.created;
    });

    const { labels } = pcmSummary;

    setCategoryData([
      {
        category: "accounts_receivable_net",

        total: labels[0]?.accounts_receivable_net,
      },
      {
        category: "accounts_receivable_patient",

        total: labels[0]?.accounts_receivable_patient,
      },
    ]);

    switch (chartType) {
      case "billing_frequency":
        newData.datasets = [
          {
            label: "Billings",
            data: pcmSummary.data.map((item) => {
              return item.count;
            }),
            fill: false,
            backgroundColor: "blue",
            borderColor: "blue",
            pointBackgroundColor: "#000000",
          },
        ];
        break;
      case "assignment_activity":
        newData.datasets = [
          {
            label: "Less Than 30",
            data: pcmSummary.data.map((item) => {
              return item.aging_count;
            }),
            fill: true,
            backgroundColor: "green",
            borderColor: "green",
            pointBackgroundColor: "#000000",
          },
          {
            label: "Correction Team",
            data: pcmSummary.data.map((item) => {
              return item.correction_count;
            }),
            fill: false,
            backgroundColor: "red",
            borderColor: "red",
            pointBackgroundColor: "#000000",
          },
          {
            label: "Research  Team",
            data: pcmSummary.data.map((item) => {
              return item.research_count;
            }),
            fill: false,
            backgroundColor: "orange",
            borderColor: "orange",
            pointBackgroundColor: "#000000",
          },
          {
            label: "Payment Posting",
            data: pcmSummary.data.map((item) => {
              return item.count_payment_posting;
            }),
            fill: false,
            backgroundColor: "rgba(153,255,51,0.75)",
            borderColor: "rgba(153,255,51,1)",
            pointBackgroundColor: "#000000",
          },
          {
            label: "Adjust Off",
            data: pcmSummary.data.map((item) => {
              return item.count_adjust_off;
            }),
            fill: false,
            backgroundColor: "yellow",
            borderColor: "yellow",
            pointBackgroundColor: "#000000",
          },
          {
            label: "Positive Patient Balance",
            data: pcmSummary.data.map((item) => {
              return item.positive_patient_balance;
            }),
            fill: false,
            backgroundColor: "rgba(51,153,255,0.75)",
            borderColor: "rgba(51,153,255,1)",
            pointBackgroundColor: "#000000",
          },
          {
            label: "Research - Call Logged",
            data: pcmSummary.data.map((item) => {
              return item.contacted_count;
            }),
            fill: false,
            backgroundColor: "rgba(51,204,51,0.75)",
            borderColor: "rgba(51,204,51,1)",
            pointBackgroundColor: "#000000",
          },
        ];
        break;
      default:
        newData.datasets = [
          {
            label: "Patient",
            data: pcmSummary.data.map((item) => {
              return item[chartType + "_patient"];
            }),
            fill: true,
            backgroundColor: "rgba(212,187,255,0.75)",
            borderColor: "rgba(212,187,255,1)",
            pointBackgroundColor: "#000000",
          },
          {
            label: "Insurance",
            data: pcmSummary.data.map((item) => {
              return item[chartType + "_insurance"];
            }),
            fill: true,
            backgroundColor: "rgba(130,207,255,0.75)",
            borderColor: "rgba(130,207,255,1)",
            pointBackgroundColor: "#000000",
          },
          {
            label: "Net",
            data: pcmSummary.data.map((item) => {
              return item[chartType + "_net"];
            }),
            fill: true,
            backgroundColor: "rgba(255,175,210,0.75)",
            borderColor: "rgba(255,175,210,1)",
            pointBackgroundColor: "#000000",
          },
        ];
        break;
    }

    setData(newData);
  }, [pcmSummary]);

  const minRowCount = 8;
  let emptyRows = [];
  if (tableData.length < minRowCount + 1) {
    emptyRows = new Array(minRowCount - tableData.length).fill({ label: "" });
  }
  return (
    <div className="clinicWorkflow__main-page">
      <TopBar title="Summary" />
      <div className="clinicWorkflow__main-section eventsContainer">
        <div className="fullContainer">
          <div
            className="cardBlock"
            style={{
              height: "75%",
              width: "100%",
              marginLeft: 0,
              marginRight: 0,
            }}
          >
            <div className="filterContainer" style={{ padding: 0, paddingRight: "35px", paddingLeft: "35px" }}>
              <div className="cardBlock__title">
                {
                  chartTypes.find((element) => {
                    return element?.id === chartType;
                  })?.name
                }
              </div>
              <div className="statusFilter" style={{ minWidth: "200px", marginLeft: "auto" }}>
                <Select
                  placeholder="Chart Type"
                  value={chartType}
                  onChange={(val) => {
                    setChartType(val);
                  }}
                  allowClear={true}
                >
                  {chartTypes.map((items) => {
                    return (
                      <Option key={items?.id} value={items?.id}>
                        {items?.name}
                      </Option>
                    );
                  })}
                </Select>
              </div>
              <div className="statusFilter" style={{ minWidth: "200px" }}>
                <DatePicker
                  format={dateFormat}
                  placeholder="Start Date"
                  value={startDate}
                  onChange={(val) => {
                    setStartDate(val);
                  }}
                />
              </div>
              <div className="statusFilter" style={{ minWidth: "200px" }}>
                <DatePicker
                  format={dateFormat}
                  placeholder="End Date"
                  value={endDate}
                  onChange={(val) => {
                    setEndDate(val);
                  }}
                />
              </div>
            </div>
            <div style={{ height: "90%" }}>
              <Line data={data} options={chartOptions} height={75} />
            </div>
          </div>
          <div
            style={{
              width: "100%",
              height: "25%",
              marginLeft: 0,
              marginRight: 0,
              display: "flex",
            }}
          >
            <CardBlock
              title="Accounts Receivable Breakdown ($)"
              varient="one_third"
              style={{
                height: "100%",
              }}
              contentPadding="1rem"
            >
              <DataTable
                title="accounts-receivable-breakdown"
                labels={["Category", "Total"]}
                widths={["70%", "30%"]}
                tableWidth={300}
                defaultActive={-1}
                sizeHeightFix="100%"
                isFixHeight
                isNormal
                bordered
                disableIcon
                disableDelete
                columns={[
                  { sortable: false, key: "category" },
                  { sortable: false, key: "total" },
                ]}
                rowData={(categoryData || []).map((item, index) => {
                  return {
                    category: <b>{item.category}</b>,
                    total: item.total,
                  };
                })}
                emptyRows={emptyRows}
                sorts={["", ""]}
              />
            </CardBlock>
            <CardBlock
              title="Accounts Receivable Breakdown (%)"
              varient="one_third"
              style={{
                height: "100%",
              }}
              contentPadding="1rem"
            >
              <DataTable
                title="accounts-receivable-breakdown-percent"
                labels={["Category", "Total"]}
                widths={["70%", "30%"]}
                tableWidth={300}
                defaultActive={-1}
                sizeHeightFix="100%"
                isFixHeight
                isNormal
                bordered
                disableIcon
                disableDelete
                columns={[
                  { sortable: false, key: "category" },
                  { sortable: false, key: "total" },
                ]}
                rowData={(tableData || []).map((item, index) => {
                  return {
                    category: item.category,
                    total: item.total,
                  };
                })}
                emptyRows={emptyRows}
                sorts={["", ""]}
              />
            </CardBlock>
            <CardBlock
              title="Insurance Breakdown ($)"
              varient="one_third"
              style={{
                height: "100%",
              }}
              contentPadding="1rem"
            >
              <DataTable
                title="insurance-breakdown"
                labels={["Insurance", "Total"]}
                widths={["70%", "30%"]}
                tableWidth={300}
                defaultActive={-1}
                sizeHeightFix="100%"
                isFixHeight
                isNormal
                bordered
                disableIcon
                disableDelete
                columns={[
                  { sortable: false, key: "insurance" },
                  { sortable: false, key: "total" },
                ]}
                rowData={pcmSummary?.labels?.map((item, index) => {
                  return {
                    insurance: <b>accounts_receivable_insurance</b>,
                    total: item ? item.accounts_receivable_insurance : "",
                  };
                })}
                emptyRows={emptyRows}
                sorts={["", ""]}
              />
            </CardBlock>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Summary;
