import { Icon } from "antd";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DataTable from "../../../../common/components/DataTable/DataTable";
import EditableFormINput from "../../../../common/components/EditableText/EditableFormInput";
import { RedRequiredCircleSVG, YellowAlertSVG } from "../../../../common/components/icons/SVGIcon";
import ToastMessage from "../../../../common/components/Toast";
import { getLocal } from "../../../../helpers/Local";
import { add_timeoff_requests, setGlobalLoading, timeoff_requests } from "../../../../store/actions";
import { StaticResource } from "../../constant";
import TimeOffSidebar from "./TimeOffSidebar";

const options = [
  { value: "vacation", label: "Vacation" },
  { value: "illness", label: "Illness" },
  { value: "bereavement", label: "Bereavement" },
  { value: "education", label: "Education" },
  { value: "other", label: "Other" },
];

const TimeOff = () => {
  const [isSidebar, setIsSidebar] = useState(false);
  const [activeIndex, setRow] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isToastr, setIsToastr] = useState(false);

  const [allHours, setAllHours] = useState(8);
  const [startTime, setStartTime] = useState(moment("09:00", "HH:mm"));
  const [endTime, setEndTime] = useState(moment("17:00", "HH:mm"));
  const [startDate, setStartDate] = useState(moment());
  const [endDate, setEndDate] = useState(moment());
  const [allDate, setAllDate] = useState(0);
  const [reason, setReason] = useState(undefined);

  const timeRequests = useSelector((state) => state.common.timeoff_requests);
  const loadingTimeoff = useSelector((state) => state.common.loadingTimeoff);
  const currentUserInfo = useSelector((state) => state.common.currentUserInfo);
  const dispatch = useDispatch();

  useEffect(() => {
    if (window.innerWidth <= 768) {
      setIsSidebar(false);
    }
  }, []);

  const authId = getLocal("cvai-current-user");
  const tableRows = timeRequests ? timeRequests : [];

  const handleCloseSidebar = () => {
    setIsSidebar(false);
  };

  const handleRow = (_, index) => {
    setRow(index);
    setIsSidebar(true);
  };

  const checkNumberOf = () => {
    return tableRows[activeIndex] && tableRows[activeIndex].user && tableRows[activeIndex].user.vacation_hours_per_year - timeRemaining();
  };

  const timeMax_start = moment("16:00", "HH:mm").format("HH:mm").toString();
  const timeMin_start = moment("9:00", "HH:mm").format("HH:mm").toString();
  const timeMax_end = moment("17:00", "HH:mm").format("HH:mm").toString();
  const timeMin_end = moment("10:00", "HH:mm").format("HH:mm").toString();

  const changeStartTime = (value) => {
    if (value) {
      const timeChose = moment(value).format("HH:mm").toString();
      if (timeChose <= timeMax_start) {
        if (timeChose >= timeMin_start) {
          if (endTime && timeChose >= endTime.format("HH:mm").toString()) {
            alert("Time Start must less than time end");
          } else {
            setStartTime(value);
            if (endTime) {
              setAllHours(Number(moment.utc(Number(endTime - moment(value))).format("hh")) + Number(allDate));
            }
          }
        } else {
          alert("Time Must More 9:00");
        }
      } else {
        alert("Time Must Less 16:00");
      }
    }
  };

  const changeEndTime = (value) => {
    if (value) {
      const timeChose = moment(value).format("HH:mm").toString();
      if (timeChose <= timeMax_end) {
        if (timeChose >= timeMin_end) {
          if (startTime && timeChose <= startTime.format("HH:mm").toString()) {
            alert("Time End must more than time start");
          } else {
            setEndTime(value);
            if (startTime) {
              setAllHours(Number(moment.utc(Number(moment(value) - startTime)).format("hh")) + Number(allDate));
            }
          }
        } else {
          alert("Time Must More 10:00");
        }
      } else {
        alert("Time Must Less 17:00");
      }
    }
  };

  const changeStartDate = (value) => {
    if (value) {
      if (endDate && value.format("MM/DD/YY").toString() > endDate.format("MM/DD/YY").toString()) {
        alert("Start date must less then end date");
      } else {
        setStartDate(value);
        setAllDate(Number((moment.utc(Number(endDate - value)).format("DD") - 1) * 8));
        setAllHours(Number((moment.utc(Number(endDate - value)).format("DD") - 1) * 8));
        setEndTime(null);
      }
    }
  };

  const changeEndDate = (value) => {
    if (value) {
      if (startDate && value.format("MM/DD/YY").toString() < startDate.format("MM/DD/YY").toString()) {
        alert("Start date must less then end date");
      } else {
        setEndDate(value);
        setAllDate(Number((moment.utc(Number(value - startDate)).format("DD") - 1) * 8));
        setAllHours(Number((moment.utc(Number(value - startDate)).format("DD") - 1) * 8));
        setEndTime(null);
      }
    }
  };

  const fetchApi = () => {
    dispatch(timeoff_requests(authId));
  };

  const fetchData = () => {
    fetchApi();
  };

  const clearForm = () => {
    setAllHours(8);
    setStartTime(moment("09:00", "HH:mm"));
    setEndTime(moment("17:00", "HH:mm"));
    setEndDate(moment());
    setStartDate(moment());
    setAllDate(0);
    setReason(undefined);
  };

  const saveAll = async () => {
    const endDay = moment(endDate).format("MM/DD/YYYY");
    const startDay = moment(startDate).format("MM/DD/YYYY");
    const endCheckTime = moment(endTime).format("HH:mm");
    const startCheckTime = moment(startTime).format("HH:mm");

    if (allHours > checkNumberOf()) {
      alert("hours more than number of hours");
    } else if (!reason) {
      alert("Choose reason!");
    } else {
      if (endTime) {
        setLoading(true);
        const checkSuccess = await dispatch(
          add_timeoff_requests({
            user_id: authId,
            start_time: startDay + " " + startCheckTime,
            end_time: endDay + " " + endCheckTime,
            amount_of_hours: allHours,
            reason: reason,
            approved_user_id: authId,
            is_approved: false,
          })
        );
        if (checkSuccess) {
          fetchApi();
          setLoading(false);
          setIsToastr(true);
          clearForm();
          setTimeout(() => {
            setIsToastr(false);
          }, 2000);
        }
      } else {
        alert("Please select end time!");
      }
    }
  };

  const timeRemaining = () => {
    let a = 0;
    if (timeRequests.length > 0) {
      for (const item in timeRequests) {
        a += Number(timeRequests[item].amount_of_hours);
      }
    }
    return a;
  };

  useEffect(() => {
    dispatch(setGlobalLoading(loading || loadingTimeoff));
  }, [loading, loadingTimeoff]);
  const sidebarRef = useRef();
  return (
    <div className="clinicWorkflow__main-section">
      {isToastr && <ToastMessage type="Schedules" status="success" msg="Saved Successfully!" />}
      <div className="eventsContainer has-sub-header">
        <div className="fullContainer pt-2">
          <div
            className="timeOff__form"
            style={{
              paddingRight: "1rem",
              marginTop: "1rem",
            }}
          >
            <div className="timeOff__form-container">
              <div className="timeOff__form-control">
                <EditableFormINput label={"Start Date"} value={startDate} type="date" handleChange={changeStartDate} />
              </div>

              <div className="timeOff__form-control">
                <EditableFormINput label={"Start Time"} value={startTime} type="time" handleChange={changeStartTime} />
              </div>

              <div className="timeOff__form-control">
                <EditableFormINput label={"End Date"} value={endDate} type="date" handleChange={changeEndDate} />
              </div>

              <div className="timeOff__form-control">
                <EditableFormINput label={"End Time"} value={endTime && endTime} type="time" handleChange={changeEndTime} />
              </div>

              <div className="timeOff__form-control">
                <EditableFormINput label={"Hours"} value={allHours} unit="hrs" />
              </div>
              <div className="timeOff__form-control">
                <EditableFormINput
                  label={"Reason"}
                  value={allHours}
                  type="check"
                  options={options || []}
                  optionKey="value"
                  valueKey="label"
                  handleChange={setReason}
                />
              </div>
              <div className="timeOff__form-control">
                <EditableFormINput label={"Number of Hours"} value={checkNumberOf()} />
              </div>
            </div>
            <div className="timeOff__form-result">
              <div className="timeOff__form-detail">
                <div className="timeOff__form-detail--value">
                  <b>Total PTO Hours:</b>
                  <div className="w-60">30</div>
                </div>
              </div>
              <div className="timeOff__form-info">
                <span>
                  <RedRequiredCircleSVG /> Pending hours remaining are not reflected in the totals above
                </span>
              </div>
            </div>

            <div
              style={{
                textAlign: "right",
                width: "100%",
                marginTop: "1rem",
                marginBottom: "0.5rem",
              }}
            >
              <button onClick={saveAll} className="common-btn" style={{ border: 0 }}>
                Submit
              </button>
            </div>
          </div>
          <div className="fullContainer pt-2">
            <div className="tableSection">
              <div className="tableContainer">
                <DataTable
                  title="employee_time_off_request"
                  tableWidth={920}
                  labels={["Time Off Start", "Time Off End", "Reason", "Hours", "Approved By"]}
                  widths={["24%", "19%", "19%", "19%", "19%"]}
                  columns={[
                    { sortable: false, key: "start" },
                    { sortable: false, key: "end" },
                    { sortable: false, key: "reason" },
                    { sortable: false, key: "hours" },
                    { sortable: false, key: "approved_by" },
                  ]}
                  sorts={["", "", "", "", ""]}
                  rowData={(tableRows || []).map((row, index) => ({
                    id: row?.id,
                    start: (
                      <div
                        style={{
                          display: "flex",
                          paddingLeft: "0.25rem",
                          justifyContent: "flex-start",
                          alignItems: "center",
                        }}
                      >
                        {!row?.is_approved && (
                          <div className="d-flex">
                            <YellowAlertSVG />
                          </div>
                        )}
                        {!!row?.is_approved && (
                          <div className="d-flex">
                            <Icon type="check-circle" theme="twoTone" twoToneColor="#24A148" />
                          </div>
                        )}
                        <p style={{ paddingLeft: "0.75rem" }}>{!!row?.start_time && moment(row?.start_time).format("MM/DD/YYYY HH:mm")}</p>
                      </div>
                    ),
                    end: row?.end_time && moment(row?.end_time).format("MM/DD/YYYY HH:mm"),
                    reason: row?.reason,
                    hours: row?.amount_of_hours,
                    approved_by: row?.approved_user && row?.approved_user?.name,
                  }))}
                  fetchList={fetchData}
                  handleClickRow={handleRow}
                  defaultActive={0}
                  disableDelete={true}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <TimeOffSidebar
        ref={sidebarRef}
        currentUserInfo={currentUserInfo}
        resource={StaticResource}
        isShow={isSidebar}
        handleCloseSidebar={handleCloseSidebar}
      />
    </div>
  );
};

export default TimeOff;
