import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ScrollWrapper from "../../../../common/components/ScrollBar";
import { useWindowSize } from "../../../../common/utils";
import { getLocal } from "../../../../helpers/Local";
import { getCommunicationRecords, setGlobalLoading } from "../../../../store/actions";

const Communication = ({ title }) => {
  const data = useSelector((state) => state.patientRecords.communications);

  const ref = useRef(null);
  const [activeIndex, setRow] = useState(-1);
  const [initialSize, setSize] = useState({
    width: undefined,
    height: undefined,
  });
  const [takeCount, setTakeCount] = useState(30);
  const patient_id = getLocal("current_patient_id");
  const [offLoad, setOffLoad] = useState(true);
  const [loading, setLoading] = useState(true);
  const [tableLoad, setTableLoads] = useState([]);
  const [page, setPage] = useState(1);

  const dispatch = useDispatch();

  const fetchAPI = () => {
    dispatch(getCommunicationRecords({ patient_id: patient_id, paging: 1 }));
  };

  useEffect(() => {
    fetchAPI();
    if (ref) {
      const initialSize = {
        width: ref.current.clientWidth,
        height: ref.current.clientHeight,
      };

      const count = Math.round(ref.current.clientHeight / 40) + 3;
      setTakeCount(count);
      setSize(initialSize);
    }
  }, [ref]);

  useEffect(() => {
    if (data && data?.length > 0) {
      if (page > 1) {
        setTableLoads([...tableLoad, ...data]);
        setLoading(false);
      } else {
        setTableLoads([...data]);
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  }, [data]);

  let emptyRows = [];
  const size = useWindowSize(ref);

  const handleRow = (index) => () => {
    setRow(index);
  };

  let rowCount = 0;
  if (!size.height && !initialSize.height) {
    rowCount = tableLoad.length;
  }
  if (size.height) {
    rowCount = Math.round(size.height / (16 * 2.5));
  } else {
    rowCount = Math.round(initialSize.height / (16 * 2.5));
  }

  if (rowCount > tableLoad.length) {
    emptyRows = new Array(rowCount - tableLoad.length).fill({});
  }

  const loadMore = async (values) => {
    const { scrollTop, scrollHeight, clientHeight } = values;
    const pad = 30; // px of the bottom
    const t = (scrollTop + pad) / (scrollHeight - clientHeight);
    if (t >= 1 && Number.isFinite(t) && !loading) {
      if (offLoad) {
        setLoading(true);
        const listUpdate = await dispatch(
          getCommunicationRecords({ patient_id: patient_id, paging: page + 1 }),
        );
        if (listUpdate) {
          if (data && data?.length > 0) {
            setPage(page + 1);
            setLoading(false);
          } else {
            setLoading(false);
            setOffLoad(false);
          }
        }
      }
    }
  };

  useEffect(() => {
    dispatch(setGlobalLoading(loading));
  }, [loading]);

  return (
    <div
      className="clinicWorkflow__main-section"
      style={{ padding: '1rem 2rem', height: 'calc(100vh - 165px)' }}
    >
      <div className="fullContainer no-padding-right">
        <div className="tableHeader" style={{borderBottom:'2px solid #dfe3e6 '}}>
          <div className="tableItem">
            <div className="th" style={{ width: '20%',borderLeft:'none' }}>
              <b>Date</b>
            </div>
            <div className="th" style={{ width: '40%' }}>
              <b>Description</b>
            </div>
            <div className="th" style={{ width: '20%' }}>
              <b>Communication Method</b>
            </div>
            <div className="th" style={{ width: '20%' }}>
              <b>Result</b>
            </div>
          </div>
        </div>
        <div className="tableSection">
          <div ref={ref} className="tableContainer">
            <ScrollWrapper css="no-padding" onScrollFrame={loadMore}>
              <div className="tableList">
                {(tableLoad || []).map((row, index) => (
                  <div
                    className={`tableItem yellow ${activeIndex === index ? 'active' : ''
                      }`}
                    key={`resultTable-${title}-${index}`}
                    onClick={handleRow(index)}
                  >
                    <div className="td" style={{ width: '20%' }}>
                      <p>
                        {row?.attributes['created-at'] &&
                          moment(row?.attributes['created-at']).format(
                            'MM/DD/YYYY HH:mm A',
                          )}
                      </p>
                    </div>
                    <div className="td" style={{ width: '40%' }}>
                      <p>{row?.attributes.reason}</p>
                    </div>
                    <div className="td" style={{ width: '20%' }}>
                      <p>{row?.attributes.method}</p>
                    </div>
                    <div className="td" style={{ width: '20%' }}>
                      <p>{row?.attributes.result}</p>
                    </div>
                  </div>
                ))}
                {emptyRows.map((row, index) => (
                  <div
                    className={`tableItem empty`}
                    key={`resultTable-empty-${title}-${index}`}
                  >
                    <div className="td" style={{ width: '20%' }} />
                    <div className="td" style={{ width: '40%' }} />
                    <div className="td" style={{ width: '20%' }} />
                    <div className="td" style={{ width: '20%' }} />
                  </div>
                ))}
              </div>
            </ScrollWrapper>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Communication;
