import { Collapse, Drawer } from "antd";
import { EditorState, convertFromRaw, convertToRaw } from "draft-js";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useDispatch, useSelector } from "react-redux";
import AutoCompleteComponent from "src/common/components/AutoComplete";
import CptReview from "../../../../common/components/CptReview";
import EditableFormInput from "../../../../common/components/EditableText/EditableFormInput";
import EditableMaterialText from "../../../../common/components/EditableText/MaterialInput";
import ScrollWrapper from "../../../../common/components/ScrollBar";
import ToastMessage from "../../../../common/components/Toast";
import { getLocal, setLocal } from "../../../../helpers/Local";
import { getPatientAppointment, getProcedureTypeMany } from "../../../../services/api.services";
import { validateField } from "../../../../services/validations";
import {
  apply_cpt_rules,
  getDetailProcedureData,
  getPatientHospital,
  getProcedureType,
  getReferralConditions,
  goToCharting,
  removeParamsChart,
  removeStateDeleteChart,
  reset_cpt_rules,
  saveFinalizeInPatientCharting,
  save_in_patient_charting,
  search_cpt_code,
  setDashboardAppointments,
  setGlobalLoading,
  setParamsChart,
  unlock_finalize,
  update_in_patient_charting,
} from "../../../../store/actions";
import AppointmentNew from "../../../home/components/AppointmentNew";
import ExaminationROS from "./ExaminationROS";

const { Panel } = Collapse;

let timer = null;
const ChartingSideBar = React.forwardRef(
  (
    {
      isShow,
      handleCloseSidebar,
      onClickSetRow,
      callBackSave,
      setParamsState,
      setKeyPanelCheck,
      keyPanelCheck,
      handleClickShow,
      indexSlider,
      setIndexSlider,
      indexDoc,
      setIndexDoc,
      updateCheck,
      removeChart,
    },
    ref
  ) => {
    const resource = useSelector((state) => state.patientRecords.resource);
    const loadingSearchCPT = useSelector((state) => state.common.loadingSearchCPT);
    const appointments = useSelector((state) => state?.dashboard?.appointments?.appointments || []);
    const resourceHospital = useSelector((state) => state.patientRecords.resourceHospital);
    const loading = useSelector((state) => state.patientRecords.loadingResource);
    const cpt_rules = useSelector((state) => state.patientRecords.cpt_rules);
    const procedureData = useSelector((state) => state.common.procedureData);
    const conditionData = useSelector((state) => state.common.referralConditions);
    const indicationsValue = useSelector((state) => state.patientRecords.indicationsValue);
    const [enableError, setEnableError] = useState(true);
    const [valueX, setValueX] = useState("");
    const [loadingLocal, setLoadingLocal] = useState(false);
    const [isFail, setToast] = useState(false);
    const [isSave, setSave] = useState(false);
    const [disablePanel, setDisablePanel] = useState(false);
    const loadingProcedure = useSelector((state) => state.common.loadingDetailProcedureData);
    const [page, setPage] = useState(1);
    const [pageTask, setPageTask] = useState(0);
    const [activeRow, setActiveRow] = useState(-1);
    const [rowIDClick, setRowIDClick] = useState(null);
    const [selectResult, setSelectResult] = useState([]);
    const [rosLocal, setRosLocal] = useState(null);
    const [hospitalSelect, setHospitalSelect] = useState({});
    const [subPro, setSubPro] = useState([]);
    const [selectSearch, setSelectSearch] = useState(null);
    const patient_id_cookie = getLocal("current_patient_id");
    const user_id = getLocal("cvai-current-user");
    const dispatch = useDispatch();
    const locations = useSelector((state) => state.common.locations);
    const [editorState, setEditor] = useState(EditorState.createEmpty());
    const selectData = useSelector((state) => state.common.searchCPT);
    const [specifyState, setSpecifyState] = useState({});
    const [dataHospital, setDataHospital] = useState([]);
    const [params, setParams] = useState({});
    const text_param = useSelector((state) => state.patientRecords.text_param);
    const [isResult, setIsResult] = useState(false);
    const [cptData, setCptData] = useState([]);
    const [searchSub, setSearchSub] = useState("");
    const [rowSearchName, setRowSearchName] = useState([]);
    const [rowSearchID, setRowSearchID] = useState([]);
    const [filteredProcedureData, setFilteredProcedureData] = useState([]);
    const paramsChart = useSelector((state) => state.patientRecords.paramsChart);
    const [disableFinalize, setDisableFinalize] = useState(true);
    const [disableSave, setDisableSave] = useState(true);
    const [focusClass, setFocusClass] = useState("");
    const [singleAptTypeId, setSingleAptTypeId] = useState(0);
    const appointmentIdGoToCharting = useSelector((state) => state.common.appointmentIdGoToCharting);
    const [activeIndex, setActiveIndex] = useState(-1);
    const [showUnfinalize, setShowUnfinalize] = useState(false);

    useEffect(() => {
      setParams(paramsChart || {});
      if (paramsChart && paramsChart["cpt_codes"] && paramsChart["cpt_codes"].length > 0) {
        setCptData(paramsChart["cpt_codes"]);
      } else {
        setCptData([]);
      }
    }, [paramsChart]);

    useEffect(() => {
      setActiveIndex(indexSlider);
    }, [indexSlider]);

    const fetchAPI = async () => {
      setLoadingLocal(true);
      setLocal("patient_document_id", null);
      await dispatch(getReferralConditions());
      setLoadingLocal(false);
    };

    useEffect(() => {
      if (cpt_rules && cpt_rules.length > 0) {
        let nameRow = [];
        let idRow = [];
        setRowSearchName([...rowSearchName, ...nameRow]);
        setRowSearchID([...rowSearchID, ...idRow]);
        setCptData([...cpt_rules]);
        setParams({ ...params, cpt_codes: [...cpt_rules] });
      }
    }, [cpt_rules]);

    useEffect(() => {
      if (hospitalSelect && Object.keys(hospitalSelect).length > 0) {
        dispatch(reset_cpt_rules());
        let nameRow = [];
        let idRow = [];

        if (hospitalSelect.cpt_codes) {
          setRowSearchName([...rowSearchName, ...nameRow]);
          setRowSearchID([...rowSearchID, ...idRow]);
          setCptData([...cpt_rules]);
        }
        const paramsCheck = {
          ...hospitalSelect,
          appointment_type_id: hospitalSelect?.appointment_type_id,
          anesthesia: hospitalSelect?.medical_reading?.anesthesia,
          complications: hospitalSelect?.medical_reading?.complications,
          ebl: hospitalSelect?.medical_reading?.ebl,
          indications: hospitalSelect?.medical_reading?.indications,
          preoperative_dx_id: hospitalSelect?.medical_reading?.preoperative_dx_id,
          side: hospitalSelect?.medical_reading?.side,
          disposition: hospitalSelect?.medical_reading?.disposition,
        };
        setParams(paramsCheck);
        dispatch(setParamsChart("paramsChart", paramsCheck));
        dispatch(
          setParamsChart("indicationsValue", {
            indications: hospitalSelect?.medical_reading?.indications,
          })
        );
        if (hospitalSelect?.medical_reading?.indications) {
          const contentState = convertFromRaw(JSON.parse(hospitalSelect?.medical_reading?.indications));
          const editorState = EditorState.createWithContent(contentState);
          setEditor(editorState);
        }
      } else {
        setParams({});
        dispatch(setParamsChart("paramsChart", {}));
        dispatch(setParamsChart("indicationsValue", {}));
      }
    }, [hospitalSelect]);

    useEffect(() => {
      dispatch(removeStateDeleteChart());
      fetchAPI();
    }, []);

    useEffect(() => {
      dispatch(getPatientHospital(patient_id_cookie, 1));
    }, [patient_id_cookie]);

    useEffect(() => {
      if (!!procedureData?.length) {
        setFilteredProcedureData(procedureData);
      }
    }, [procedureData]);

    const makeSpecify = (value, tag) => {
      const paramState = {
        ...specifyState,
        [`specify-${tag}`]: (value || []).filter((r) => r?.group == tag),
      };
      setSpecifyState(paramState);
      let dataSpec = [];
      Object.keys(paramState).map((r) => {
        if (paramState[r]) {
          dataSpec = [...dataSpec, ...paramState[r]];
        }
      });
      setParams({
        ...params,
        specAll: dataSpec,
      });
      dispatch(
        setParamsChart("paramsChart", {
          ...params,
          specAll: dataSpec,
        })
      );
    };

    const resetWhenDelete = async () => {
      setSubPro([]);
      setSpecifyState({});
      setSingleAptTypeId(null);
      setParams({});
      setRosLocal(null);
      setHospitalSelect({});
      await dispatch(removeParamsChart());
      await dispatch(reset_cpt_rules());
      await dispatch(removeStateDeleteChart());
      removeChart && removeChart();
    };

    const handleSelect = async (type, value, tag) => {
      if (type == "appointment_type_id") {
        if (value[0]["value"]) {
          setLoadingLocal(true);
          await dispatch(removeParamsChart());
          await dispatch(reset_cpt_rules());
          await dispatch(removeStateDeleteChart());
          await dispatch(getProcedureType(value[0]["value"]));
          await dispatch(getDetailProcedureData(value[0]["value"]));
          callBackSave({
            appointment_type_id: value,
          });
          setLoadingLocal(false);
        }
      }

      if (type == "appointment_time") {
        const paramState = {
          ...params,
          [type]: value,
          appointment_date: params?.appointment_date ? `${moment(params?.appointment_date).format("MM/DD/YYYY")} ${value}` : null,
        };
        setParams(paramState);
        clearTimeout(timer);
        timer = setTimeout(() => {
          dispatch(setParamsChart("paramsChart", paramState));
          callBackSave(paramState, subPro);
        }, 500);
      } else if (type == "ros") {
        const paramState = {
          ...rosLocal,
          [`${type}-${tag}`]: (value || []).filter((r) => r?.group == tag),
        };
        setRosLocal(paramState);
        makeSpecify(value, tag);
      } else if (type == "appointment_date") {
        value = moment(value).hours(moment().hours()).minutes(moment().minutes()).format("MM/DD/YYYY hh:mm A");
        const paramState = {
          ...params,
          [type]: value,
        };
        setParams(paramState);
        clearTimeout(timer);
        timer = setTimeout(() => {
          dispatch(setParamsChart("paramsChart", paramState));
          callBackSave(paramState, subPro);
        }, 500);
      } else {
        const paramState = {
          ...params,
          [type]: value,
        };
        setParams(paramState);
        clearTimeout(timer);
        timer = setTimeout(() => {
          dispatch(setParamsChart("paramsChart", paramState));
          callBackSave(paramState, subPro);
        }, 500);
      }
    };

    useEffect(() => {
      if (params && Object.keys(params).length > 0) {
        const dataCheck = [...(resourceHospital || [])];
        if (
          (params?.providers_filter && !params?.dateFilter && !params?.typeFilter) ||
          (params?.dateFilter && !params?.providers_filter && !params?.typeFilter) ||
          (params?.typeFilter && !params?.providers_filter && !params?.dateFilter)
        ) {
          const dataFilter = dataCheck.filter((r) => {
            if (r.provider_id === params?.providers_filter) {
              return r;
            } else if (moment(r.appointment_date).format("MM/DD/YYYY") === moment(params?.dateFilter).format("MM/DD/YYYY")) {
              return r;
            } else if (r?.procedure_type?.id === params?.typeFilter) {
              return r;
            }
          });
          setDataHospital([...dataFilter]);
        } else if (
          (params?.providers_filter && params?.dateFilter && !params?.typeFilter) ||
          (params?.providers_filter && !params?.dateFilter && params?.typeFilter) ||
          (params?.dateFilter && !params?.providers_filter && params?.typeFilter)
        ) {
          let dataSet = [];
          if (params?.providers_filter && params?.dateFilter) {
            dataSet = dataCheck.filter((r) => {
              if (
                r.provider_id === params?.providers_filter &&
                moment(r.appointment_date).format("MM/DD/YYYY") === moment(params?.dateFilter).format("MM/DD/YYYY")
              ) {
                return r;
              }
            });
          } else if (params?.providers_filter && params?.typeFilter) {
            dataSet = dataCheck.filter((r) => {
              if (r.provider_id === params?.providers_filter && r?.procedure_type?.id === params?.typeFilter) {
                return r;
              }
            });
          } else if (params?.dateFilter && params?.typeFilter) {
            dataSet = dataCheck.filter((r) => {
              if (
                moment(r.appointment_date).format("MM/DD/YYYY") === moment(params?.dateFilter).format("MM/DD/YYYY") &&
                r?.procedure_type?.id === params?.typeFilter
              ) {
                return r;
              }
            });
          }
          setDataHospital([...dataSet]);
        } else {
          const dataFilter = dataCheck.filter((r) => {
            if (
              r.provider_id === params?.providers_filter &&
              moment(r.appointment_date).format("MM/DD/YYYY") === moment(params?.dateFilter).format("MM/DD/YYYY") &&
              r?.procedure_type?.id === params?.typeFilter
            ) {
              return r;
            }
          });
          setDataHospital([...dataFilter]);
        }
        if (!params?.providers_filter && !params?.dateFilter && !params?.typeFilter) {
          setDataHospital([...resourceHospital]);
        }
      }
    }, [params]);

    useEffect(() => {
      if (!loadingLocal && selectData && selectData?.length > 0) {
        setSelectResult([...selectResult, ...selectData]);
      }
    }, [selectData]);

    useEffect(() => {
      setSelectResult([]);
      setPage(1);
      clearTimeout(timer);
      setLoadingLocal(true);
      timer = setTimeout(() => {
        dispatch(search_cpt_code(selectSearch, page));
        setLoadingLocal(false);
      }, 500);
    }, [selectSearch]);

    const fetchAppointments = async () => {
      const result = await getPatientAppointment({
        columns: [],
        patient_id: Number(patient_id_cookie),
        type: "hospital",
      });
      if (result) {
        const data = result.map((item) => ({
          ...item,
          date: item.appointment_date && moment(item.appointment_date).format("MM/DD/YYYY"),
          time: item.appointment_date && moment(item.appointment_date).format("hh:mm A"),
        }));
        dispatch(setDashboardAppointments("appointments", data));
      } else {
        dispatch(setDashboardAppointments("appointments", []));
      }
    };

    const save_patient_charting = async () => {
      if (params?.location_id && params?.appointment_type_id && params.appointment_date) {
        let answerArray = [];
        if (params.ros) {
          (params.ros || []).forEach((r) => {
            (r?.values || []).forEach((i) => {
              if (i.checked) {
                answerArray = [
                  ...answerArray,
                  {
                    group: r?.group || 0,
                    is_multiple: r?.is_multiple || 0,
                    procedure_type_variable_id: i.procedure_type_variable_id,
                    answer: i.option,
                  },
                ];
              }
            });
          });
        } else {
          if (params.specifications) {
            (params?.specifications).forEach((r) => {
              answerArray = [
                ...answerArray,
                {
                  group: r?.group || 0,
                  is_multiple: r?.is_multiple || 0,
                  procedure_type_variable_id: r.procedure_type_variable_id,
                  answer: r.answer,
                },
              ];
            });
          }
        }
        const getParam = {
          procedure_type_id: params?.appointment_type_id,
          patient_id: Number(patient_id_cookie),
          provider_id: params?.provider_id,
          cpt_codes: cptData,
          answers: answerArray,
          sub_procedures: subPro,
          user_id: user_id,
          appointment_date: moment(params?.appointment_date).format("MM/DD/YYYY hh:mm A"),
          appointment_time: params?.appointment_time,
          location_id: params?.location_id,
          preoperative_dx_id: params?.preoperative_dx_id,
          admit_date: params?.admit_date,
          side: params?.side,
          anesthesia: params?.anesthesia,
          indications: indicationsValue?.indications,
          ebl: params?.ebl,
          complications: params?.complications,
          disposition: params?.disposition,
          text_template: text_param,
          id: params?.appointment_id,
        };
        if (rowIDClick) {
          await dispatch(
            update_in_patient_charting(rowIDClick, {
              ...getParam,
              appointment_time: params?.appointment_time,
            })
          );
          fetchAppointments();
          setSave(true);
          setTimeout(() => {
            setSave(false);
          }, 2000);
        } else {
          const saveSucces = await dispatch(save_in_patient_charting(getParam));
          setRowIDClick(saveSucces?.id);
          fetchAppointments();
          await dispatch(getPatientHospital(Number(patient_id_cookie), 1));
          setSave(true);
          setTimeout(() => {
            setSave(false);
          }, 2000);
        }
      } else {
        setToast(true);
        setKeyPanelCheck("work-completes");
        setTimeout(() => {
          setToast(false);
        }, 2000);
      }
    };

    useEffect(() => {
      setDataHospital(resourceHospital);
    }, [resourceHospital]);

    useEffect(() => {
      if (isShow && resource && Object.keys(resource).length) {
        dispatch(setGlobalLoading(loadingProcedure || loadingLocal || loading || loadingSearchCPT));
      }
    }, [isShow, resource, loadingProcedure, loadingLocal, loading, loadingSearchCPT]);

    useEffect(() => {
      if (appointmentIdGoToCharting && appointments.length > 0) {
        const found_index = _.findIndex(appointments, { id: appointmentIdGoToCharting });
        if (found_index > -1) {
          setActiveIndex(found_index);
          setIndexSlider && setIndexSlider(found_index);
          acctionClick(found_index);
          dispatch(
            goToCharting({
              isGoToCharting: false,
              appointmentIdGoToCharting: null,
            })
          );
        }
      }
    }, [appointmentIdGoToCharting, appointments]);

    const acctionClick = async (index, value) => {
      setIndexSlider && setIndexSlider(index);
      setIndexDoc(-1);
      const checkDetailIndex = (appointments && appointments[index]) || null;
      if (checkDetailIndex && appointments.length > 0) {
        if (checkDetailIndex && checkDetailIndex["procedure_type"] && checkDetailIndex["procedure_type"]) {
          setLoadingLocal(true);
          setSpecifyState({});
          setSubPro(checkDetailIndex?.sub_procedures);
          dispatch(setParamsChart("subPro", checkDetailIndex?.sub_procedures));
          setPageTask(0);
          await dispatch(removeParamsChart());
          await dispatch(removeStateDeleteChart());
          setRowIDClick(checkDetailIndex?.id);
          let ros = [];
          if (checkDetailIndex?.sub_procedures && checkDetailIndex?.sub_procedures.length > 0) {
            const IDgetRos = [...(checkDetailIndex?.sub_procedures || []), checkDetailIndex["procedure_type"]];
            ros = await getProcedureTypeMany(IDgetRos.map((r) => r?.id).join(","));
            await dispatch(getProcedureType(checkDetailIndex["procedure_type"]["id"]));
          } else {
            ros = await dispatch(getProcedureType(checkDetailIndex["procedure_type"]["id"]));
          }
          const detailGet = await dispatch(getDetailProcedureData(checkDetailIndex["procedure_type"]["id"]));
          const arrayCheck = (ros || []).map((item) => {
            var idvari = 0;
            return {
              comments: "",
              id: item?.id,
              type: item?.question && item?.question.length > 18 ? `${item?.question.substring(0, 18)}...` : item?.question,
              is_multiple: item.is_multiple,
              typeFull: item?.question,
              variable_name: item?.variable_name,
              group: 0,
              values: (item?.answers || []).map((r) => {
                idvari = r.procedure_type_variable_id;
                return {
                  id: r?.id,
                  option: r.answer,
                  checked: false,
                  text_template: r.text_template,
                  procedure_type_variable_id: r.procedure_type_variable_id,
                  variable_name: item?.variable_name,
                };
              }),
              procedure_type_variable_id: idvari,
            };
          });
          var checkTrue = [];
          if (checkDetailIndex && checkDetailIndex.specifications) {
            checkDetailIndex.specifications.map((r, index) => {
              let searchIndex = arrayCheck.findIndex((i) => i?.id == r.procedure_type_variable_id);
              checkTrue = [
                ...checkTrue,
                {
                  findindex: arrayCheck.findIndex((i) => i?.id == r.procedure_type_variable_id),
                  valueindex:
                    arrayCheck[searchIndex] && arrayCheck[searchIndex]["values"]
                      ? arrayCheck[searchIndex]["values"].findIndex((check) => check.option == r.answer)
                      : -1,
                  valuecheck: r.answer,
                },
              ];
            });
          }
          (checkTrue || []).map((r) => {
            if (
              arrayCheck[r.findindex] &&
              arrayCheck[r.findindex]["values"] &&
              arrayCheck[r.findindex]["values"][r?.valueindex] &&
              arrayCheck[r.findindex]["values"][r?.valueindex]["checked"] !== undefined
            ) {
              arrayCheck[r.findindex]["values"][r?.valueindex]["checked"] = true;
            }
          });
          //
          setActiveRow(index);
          if (detailGet) {
            const dataParams = {
              ...detailGet,
              ...checkDetailIndex,
              subPro: checkDetailIndex?.sub_procedures,
              ros: arrayCheck,
              ...(checkDetailIndex?.medical_reading || {}),
              appointment_type_id: [
                {
                  label: detailGet?.value,
                  value: detailGet?.id,
                },
              ],
            };
            await handleClickShow(dataParams);
            callBackSave(dataParams, [...checkDetailIndex?.sub_procedures], {
              text_template: [checkDetailIndex?.procedure_type?.text_template],
              id: checkDetailIndex?.procedure_type?.id,
            });
            await setParams(dataParams);
            await dispatch(setParamsChart("paramsChart", dataParams));
            await dispatch(setParamsChart("indicationsValue", dataParams));
            setHospitalSelect(dataParams);
            if (dataParams.appointment_status === "billed") {
              setDisableFinalize(true);
            } else {
              setDisableFinalize(false);
              setDisableSave(false);
            }
            setLoadingLocal(false);
          } else {
            setLoadingLocal(false);
          }
        }
      }
    };

    const removeRow = (index) => {
      let newRows = [];
      newRows = [...cptData];
      newRows.splice(index, 1);
      setCptData([...newRows]);
      setParams({ ...params, cpt_codes: [...newRows] });
      callBackSave({ ...params, cpt_codes: [...newRows] }, subPro);
      const nameRow = [...rowSearchName];
      nameRow.splice(index, 1);
      setRowSearchName([...nameRow]);
      const idRow = [...rowSearchID];
      idRow.splice(index, 1);
      setRowSearchID([...idRow]);
    };

    const selectItem = (itemcheck, item, type) => {
      const newRows = cptData;
      let checkExist = false;
      (cptData || []).some((r) => {
        if (r.cpt_code === item) {
          checkExist = true;
        }
      });

      if (!checkExist) {
        newRows.push({ cpt_code: item, short_desc: type });
        setCptData(newRows);
        setParams({ ...params, cpt_codes: [...newRows] });
        callBackSave({ ...params, cpt_codes: [...newRows] }, subPro);
        setIsResult(false);
        setSelectSearch("");
      } else {
        setIsResult(false);
        setSelectSearch("");
      }
    };

    if (!isShow || !resource || !Object.keys(resource).length) {
      return null;
    }

    const onScroll = (values) => {
      const isEndOfList = values.scrollTop > (3 / 5) * values.scrollHeight;
      if (isEndOfList && !loadingSearchCPT && selectData && selectData?.length > 0) {
        setPage(page + 1);
        dispatch(search_cpt_code(selectSearch, page + 1));
      }
    };

    const onFinalize = async () => {
      if (showUnfinalize === true) {
        await dispatch(unlock_finalize(rowIDClick));
        setDisableFinalize(false);
        setDisableSave(false);
        setShowUnfinalize(false);
      } else {
        if (params?.location_id && params?.appointment_type_id && params.appointment_date) {
          let answerArray = [];
          if (params.ros) {
            (params.ros || []).forEach((r) => {
              (r?.values || []).forEach((i) => {
                if (i.checked) {
                  answerArray = [
                    ...answerArray,
                    {
                      is_multiple: r?.is_multiple || 0,
                      procedure_type_variable_id: i.procedure_type_variable_id,
                      answer: i.option,
                    },
                  ];
                }
              });
            });
          } else {
            if (params.specifications) {
              (params?.specifications).forEach((r) => {
                answerArray = [
                  ...answerArray,
                  {
                    is_multiple: r?.is_multiple || 0,
                    procedure_type_variable_id: r.procedure_type_variable_id,
                    answer: r.answer,
                  },
                ];
              });
            }
          }
          const getParam = {
            procedure_type_id: params?.appointment_type_id,
            patient_id: Number(patient_id_cookie),
            provider_id: params?.provider_id,
            cpt_codes: cptData,
            answers: answerArray,
            user_id: user_id,
            appointment_date: moment(params?.appointment_date).format("MM/DD/YYYY hh:mm A"),
            appointment_time: params?.appointment_time,
            location_id: params?.location_id,
            preoperative_dx_id: params?.preoperative_dx_id,
            admit_date: params?.admit_date,
            side: params?.side,
            anesthesia: params?.anesthesia,
            indications: indicationsValue?.indications,
            ebl: params?.ebl,
            complications: params?.complications,
            disposition: params?.disposition,
            text_template: text_param,
            id: rowIDClick,
          };
          await dispatch(saveFinalizeInPatientCharting(getParam));
          await dispatch(getPatientHospital(Number(patient_id_cookie), 1));
          setDisableFinalize(true);
          setShowUnfinalize(true);
          await fetchAppointments();
          setSave(true);
          setTimeout(() => {
            setSave(false);
          }, 2000);
        }
      }
    };

    const cptRules = async () => {
      let answerArray = [];
      if (params?.ros) {
        (params.ros || []).map((r) => {
          (r?.values || []).map((i) => {
            if (i.checked) {
              answerArray = [
                ...answerArray,
                {
                  is_multiple: r?.is_multiple || 0,
                  procedure_type_variable_id: i.procedure_type_variable_id,
                  answer: i.option,
                },
              ];
            }
          });
        });
      } else {
        if (params?.specifications) {
          (params?.specifications).map((r) => {
            answerArray = [
              ...answerArray,
              {
                is_multiple: r?.is_multiple || 0,
                procedure_type_variable_id: r.procedure_type_variable_id,
                answer: r.answer,
              },
            ];
          });
        }
      }
      if (answerArray && !disableFinalize) {
        const dataSuccess = await dispatch(
          apply_cpt_rules({
            procedure_type_id: params.appointment_type_id,
            sub_procedures: subPro,
            answers: answerArray,
          })
        );
        if (dataSuccess && Array.isArray(dataSuccess)) {
          callBackSave(
            {
              ...params,
              cpt_codes: dataSuccess,
            },
            subPro
          );
        }
      }
    };

    const onChange = async (editorState) => {
      setEditor(editorState);
      let data = JSON.stringify(convertToRaw(editorState.getCurrentContent()));
      setParams({
        ...params,
        indications: data,
      });
      callBackSave(
        {
          ...params,
          indications: data,
        },
        subPro
      );
      clearTimeout(timer);
      timer = setTimeout(() => {
        dispatch(
          setParamsChart("indicationsValue", {
            ["indications"]: data,
          })
        );
      }, 500);
    };

    const handleWorkCompleted = (value) => {
      setDisableFinalize(false);
      setDisableSave(false);
      setSubPro([]);
      setSpecifyState({});
      setParams({});
      setHospitalSelect({});

      const foundProcedure = _.find(filteredProcedureData, { id: value });
      if (foundProcedure) {
        handleSelect("appointment_type_id", [
          {
            label: foundProcedure.value,
            value: value,
          },
        ]);
      }
    };

    const updateApt = (value) => {
      if (value == "billing-codes") {
        if (params?.appointment_type_id) {
          cptRules();
        }
      }
      if (value == "billing-codes") {
      }
    };

    const { first_name, last_name } = resource;

    useEffect(() => {
      if (subPro && Array.isArray(subPro) && Object.keys(paramsChart).length > 0) {
        dispatch(setParamsChart("subPro", [...subPro]));
        callBackSave(paramsChart, [...subPro]);
      }
    }, [subPro]);

    useEffect(() => {
      if (params && params.appointment_type_id && params.appointment_type_id[0]?.label.includes("Inpatient Consultation")) {
        setDisablePanel(true);
      } else {
        setDisablePanel(false);
      }
      if (params && params.appointment_type_id && params.appointment_type_id[0]) {
        setSingleAptTypeId(params.appointment_type_id[0].value);
      }
    }, [params?.appointment_type_id]);

    const onAccept = () => {
      if (rosLocal) {
        let dataRosAll = [];
        Object.keys(rosLocal).map((r) => {
          if (rosLocal[r]) {
            dataRosAll = [...dataRosAll, ...rosLocal[r]];
          }
        });
        setParams({
          ...params,
          ros: dataRosAll,
        });
        clearTimeout(timer);
        timer = setTimeout(() => {
          dispatch(
            setParamsChart("paramsChart", {
              ...params,
              ros: dataRosAll,
            })
          );
          callBackSave(
            {
              ...params,
              ros: dataRosAll,
            },
            subPro
          );
        }, 500);
      }
    };

    const onContinue = async () => {
      setPageTask(pageTask + 1);
      if (subPro?.[pageTask]?.["id"]) {
        setLoadingLocal(true);
        await dispatch(getProcedureType(subPro[pageTask]["id"]));
        setLoadingLocal(false);
      }
    };
    const onPrev = async () => {
      if (pageTask > 0 && pageTask - 1 > 0) {
        setPageTask(pageTask - 1);
        if (subPro?.[pageTask]?.["id"]) {
          setLoadingLocal(true);
          await dispatch(getProcedureType(subPro[pageTask]["id"]));
          setLoadingLocal(false);
        }
      } else {
        if (params?.appointment_type_id?.[0]?.value) {
          setPageTask(0);
          setLoadingLocal(true);
          await dispatch(getProcedureType(params.appointment_type_id[0].value));
          setLoadingLocal(false);
        }
      }
    };

    const copy = () => {
      let temp_text = text_param;
      const regex =
        /,\{\"key\":\"txtdxend_.+\{\"key\"\:\"textendtxt\",\"text\"\:\"\",\"type\"\:\"unstyled\",\"depth\"\:0,\"inlineStyleRanges\"\:\[\],\"entityRanges\"\:\[\],\"data\"\:\{\}\}/i;
      temp_text = temp_text.replace(regex, "");
      const contentState = convertFromRaw(JSON.parse(temp_text));
      const editorState = EditorState.createWithContent(contentState);
      const editorContent = editorState.getCurrentContent();
      const plainText = editorContent.getPlainText();
      navigator.clipboard.writeText(plainText);
    };

    const onContinueValueX = () => {
      const contentState = convertFromRaw(JSON.parse(text_param));
      const editorState = EditorState.createWithContent(contentState);
      updateCheck(editorState, params, subPro, null, valueX);
      setValueX(null);
    };

    return (
      <Drawer
        title={`IN-HOSPITAL DATABASE - ${first_name} ${last_name}`}
        placement="right"
        variant="permanent"
        closable={false}
        mask={false}
        visible={isShow}
        destroyOnClose
      >
        <div ref={ref} className="resourceContainer new">
          {isFail && !isSave && <ToastMessage type="In Patient Charting" status="failed" msg="You need to complete all required fields" />}
          {!isFail && isSave && <ToastMessage type="In Patient Charting" status="success" msg="Procedure is saved successfully!" />}
          <ScrollWrapper css="x-hidden">
            <div
              className="resourceContent"
              style={{
                flex: "unset",
                margin: 0,
                padding: 0,
              }}
            >
              <Collapse
                onChange={(value) => {
                  setKeyPanelCheck(value);
                  updateApt(value);
                }}
                style={{ backgroundColor: "transparent" }}
                activeKey={keyPanelCheck}
                accordion
              >
                <div className="resourceInfo d-flex">
                  <AppointmentNew resetWhenDelete={resetWhenDelete} acctionClick={acctionClick} activeIndex={activeIndex} />
                </div>
                <Panel
                  header="Work Completed"
                  key="work-completes"
                  className={params?.appointment_type_id || hospitalSelect?.appointment_type_id ? "" : "invalid"}
                >
                  <div className="resourceInfo d-flex">
                    <EditableMaterialText
                      label={"Work completed"}
                      isErrorTextSpacing={true}
                      value={singleAptTypeId}
                      handleChange={(value) => {
                        handleWorkCompleted(value);
                      }}
                      type={"check"}
                      optionKey={"id"}
                      valueKey={"label"}
                      options={filteredProcedureData || []}
                      required={true}
                      isError={validateField("check", params?.appointment_type_id || hospitalSelect?.appointment_type_id)}
                      enableErrorText={enableError}
                      helperText={"Work completed is Required"}
                    />
                  </div>
                </Panel>
                <Panel
                  header="Report Generation"
                  key="report-generation"
                  className={
                    disablePanel
                      ? "disable"
                      : (params?.appointment_date || hospitalSelect?.appointment_date) &&
                        (params?.location_id || hospitalSelect?.location_id) &&
                        (params?.preoperative_dx_id || hospitalSelect?.medical_reading?.preoperative_dx_id) &&
                        (params?.side || hospitalSelect?.medical_reading?.side) &&
                        (params?.anesthesia || hospitalSelect?.medical_reading?.anesthesia) &&
                        (params?.ebl || hospitalSelect?.medical_reading?.ebl) &&
                        (params?.complications || hospitalSelect?.medical_reading?.complications) &&
                        (params?.admit_date || hospitalSelect?.admit_date) &&
                        (params?.disposition || hospitalSelect?.medical_reading?.disposition)
                      ? ""
                      : "invalid"
                  }
                >
                  <div className="resourceInfo d-flex">
                    <EditableFormInput
                      label={"On"}
                      isErrorTextSpacing={true}
                      id="on_date_chart"
                      value={params?.appointment_date || hospitalSelect?.appointment_date}
                      handleChange={(value) => {
                        handleSelect("appointment_date", value);
                      }}
                      type={"date"}
                      required={true}
                      isError={validateField("date", params?.appointment_date || hospitalSelect?.appointment_date)}
                      enableErrorText={enableError}
                      helperText={"Date is Required"}
                      disabled={disableFinalize}
                    />
                  </div>
                  <div className="resourceInfo d-flex">
                    <EditableFormInput
                      isHospitalCharting
                      isErrorTextSpacing={true}
                      label={"Time"}
                      id="on_time_chart"
                      value={
                        (params?.appointment_date && moment(params?.appointment_date).format("hh:mm A")) ||
                        (hospitalSelect?.appointment_date && moment(hospitalSelect?.appointment_date).format("hh:mm A")) ||
                        moment().format("hh:mm A")
                      }
                      handleChange={(value) => {
                        handleSelect("appointment_time", value);
                      }}
                      type={"time"}
                      required={true}
                      isError={validateField(
                        "time",
                        (params?.appointment_date && moment(params?.appointment_date).format("hh:mm A")) ||
                          (hospitalSelect?.appointment_date && moment(hospitalSelect?.appointment_date).format("hh:mm A"))
                      )}
                      enableErrorText={enableError}
                      helperText={"Time is Required"}
                      disabled={disableFinalize}
                      disabledHours={[0, 1, 2, 3, 4, 5, 20, 21, 22, 23]}
                    />
                  </div>
                  <div className="resourceInfo d-flex">
                    <EditableMaterialText
                      isErrorTextSpacing={true}
                      label={"Location"}
                      id="on_location_chart"
                      value={params?.location_id || hospitalSelect?.location_id}
                      handleChange={(value) => {
                        handleSelect("location_id", value);
                      }}
                      type={"check"}
                      optionKey={"id"}
                      valueKey={"label"}
                      options={locations || []}
                      required={true}
                      isError={validateField("check", params?.location_id || hospitalSelect?.location_id)}
                      enableErrorText={enableError}
                      helperText={"Location is Required"}
                      disabled={disableFinalize}
                    />
                  </div>
                  <div className="resourceInfo d-flex">
                    <EditableMaterialText
                      isErrorTextSpacing={true}
                      label={"Admit Date"}
                      id="admit_date_chart"
                      value={params?.admit_date || hospitalSelect?.admit_date}
                      handleChange={(value) => {
                        handleSelect("admit_date", value);
                      }}
                      type={"date"}
                      optionKey={"id"}
                      valueKey={"label"}
                      required={true}
                      isError={validateField("date", params?.admit_date || hospitalSelect?.admit_date)}
                      enableErrorText={enableError}
                      helperText={"Admit Date is Required"}
                      disabled={disableFinalize}
                    />
                  </div>

                  <div className="resourceInfo d-flex">
                    <EditableMaterialText
                      isErrorTextSpacing={true}
                      label={"Preoperative Dx"}
                      value={params?.preoperative_dx_id || hospitalSelect?.medical_reading?.preoperative_dx_id}
                      type={"check"}
                      id="preo_chart"
                      handleChange={(value) => {
                        handleSelect("preoperative_dx_id", value);
                      }}
                      optionKey={"id"}
                      valueKey={"label"}
                      options={conditionData || []}
                      required={true}
                      isError={validateField("check", params?.preoperative_dx_id || hospitalSelect?.medical_reading?.preoperative_dx_id)}
                      enableErrorText={enableError}
                      helperText={"Preoperative is Required"}
                      disabled={disableFinalize}
                    />
                  </div>
                  <div className="resourceInfo d-flex">
                    <EditableMaterialText
                      isErrorTextSpacing={true}
                      label={"Side"}
                      id="side_chart"
                      value={params?.side || hospitalSelect?.side}
                      handleChange={(value) => {
                        handleSelect("side", value);
                      }}
                      type={"check"}
                      optionKey={"value"}
                      valueKey={"label"}
                      options={[
                        { value: "Left", label: "Left" },
                        { value: "Right", label: "Right" },
                        { value: "Bilateral", label: "Bilateral" },
                      ]}
                      required={true}
                      isError={validateField("check", params?.side || hospitalSelect?.side)}
                      enableErrorText={enableError}
                      helperText={"Side is Required"}
                      disabled={disableFinalize}
                    />
                  </div>
                  <div className="resourceInfo d-flex">
                    <EditableMaterialText
                      isErrorTextSpacing={true}
                      label={"Anesthesia"}
                      id="anesthesia_chart"
                      value={params?.anesthesia || hospitalSelect?.medical_reading?.anesthesia}
                      handleChange={(value) => {
                        handleSelect("anesthesia", value);
                      }}
                      type={"check"}
                      optionKey={"value"}
                      valueKey={"label"}
                      options={[
                        { value: "General", label: "General" },
                        { value: "Local", label: "Local" },
                        { value: "Sedation", label: "Sedation" },
                        { value: "Epidural", label: "Epidural" },
                      ]}
                      required={true}
                      isError={validateField("check", params?.anesthesia || hospitalSelect?.medical_reading?.anesthesia)}
                      enableErrorText={enableError}
                      helperText={"Anesthesia is Required"}
                      disabled={disableFinalize}
                    />
                  </div>
                  <div className="resourceInfo d-flex">
                    <EditableMaterialText
                      isErrorTextSpacing={true}
                      label={"EBL"}
                      id="ebl_chart"
                      value={params?.ebl || hospitalSelect?.medical_reading?.ebl}
                      handleChange={(value) => {
                        handleSelect("ebl", value);
                      }}
                      type={"text"}
                      required={true}
                      isError={validateField("text", params?.ebl || hospitalSelect?.medical_reading?.ebl)}
                      enableErrorText={enableError}
                      helperText={"EBL is Required"}
                      disabled={disableFinalize}
                    />
                  </div>
                  <div className="resourceInfo d-flex">
                    <EditableMaterialText
                      isErrorTextSpacing={true}
                      label={"Complications"}
                      id="com_chart"
                      value={params?.complications || hospitalSelect?.medical_reading?.complications}
                      handleChange={(value) => {
                        handleSelect("complications", value);
                      }}
                      type={"check"}
                      optionKey={"value"}
                      valueKey={"label"}
                      options={[
                        { value: "None", label: "None" },
                        { value: "Guarded", label: "Guarded" },
                        { value: "Death", label: "Death" },
                      ]}
                      required={true}
                      isError={validateField("check", params?.complications || hospitalSelect?.medical_reading?.complications)}
                      enableErrorText={enableError}
                      helperText={"Complications is Required"}
                      disabled={disableFinalize}
                    />
                  </div>
                  <div className="resourceInfo d-flex">
                    <EditableMaterialText
                      isErrorTextSpacing={true}
                      label={"Disposition"}
                      id="dis_chart"
                      value={params?.disposition || hospitalSelect?.medical_reading?.disposition}
                      handleChange={(value) => {
                        handleSelect("disposition", value);
                      }}
                      type={"check"}
                      optionKey={"value"}
                      valueKey={"label"}
                      options={[
                        { value: "ICU", label: "ICU" },
                        { value: "Surgery", label: "Surgery" },
                        { value: "Floor", label: "Floor" },
                        { value: "Home", label: "Home" },
                      ]}
                      required={true}
                      isError={validateField("check", params?.disposition || hospitalSelect?.medical_reading?.disposition)}
                      enableErrorText={enableError}
                      helperText={"Disposition is Required"}
                      disabled={disableFinalize}
                    />
                  </div>
                  <div className="resourceInfo d-flex charting_textarea">
                    <div className={`${focusClass}`} style={{ width: "100%", height: "100%" }}>
                      <Editor
                        onFocus={() => setFocusClass("rdwFocus")}
                        onBlur={() => setFocusClass("")}
                        toolbarHidden={true}
                        toolbar={{
                          options: [],
                          inline: {
                            options: [],
                          },
                          list: {
                            options: [],
                          },
                        }}
                        editorStyle={{
                          border: "1px solid #CDCDCD",
                          padding: "10px",
                          height: 130,
                          backgroundColor: "#fff",
                        }}
                        id={"review_chart"}
                        placeholder="Patient Review"
                        editorState={editorState}
                        onEditorStateChange={onChange}
                        readOnly={disableFinalize}
                      />
                    </div>
                  </div>
                </Panel>
                <Panel className={params && subPro.length > 0 ? "" : "invalid"} header="Subprocedure Order" key="subprocedure-order">
                  <AutoCompleteComponent
                    isDrag={true}
                    selectItem={(label, type, value, valueAll) => {
                      if (subPro.some((r) => r?.id === valueAll?.id)) {
                        alert("Subprocedure Order Exist");
                      } else {
                        setSubPro([...subPro, valueAll]);
                        setRosLocal([]);
                        dispatch(removeStateDeleteChart());
                      }
                    }}
                    handleSearch={(value) => {
                      setSearchSub(value);
                    }}
                    setItems={setSubPro}
                    title="inventory-notes"
                    type={"check"}
                    keyLabel={"value"}
                    rows={subPro}
                    options={
                      filteredProcedureData && filteredProcedureData.length > 0
                        ? filteredProcedureData?.filter((r) => r?.value.toUpperCase().includes(searchSub.toUpperCase()))
                        : []
                    }
                    removeItem={(value, index) => {
                      const stateCheck = [...subPro];
                      stateCheck.splice(index, 1);
                      setSubPro([...stateCheck]);
                      setRosLocal([]);
                      dispatch(removeStateDeleteChart());
                    }}
                    placeholder={"Search Subprocedures"}
                    isMinHeight
                  />
                </Panel>
                <Panel
                  header="Procedure Specifications"
                  key="procedure-specifications"
                  className={`custom
                    ${
                      (params && params["ros"] && params["ros"].length > 0) ||
                      (params && params["specifications"] && params["specifications"].length > 0)
                        ? ""
                        : "invalid"
                    }
                  `}
                >
                  <div className="resourceInfo" style={{ paddingLeft: "0px", display: "block" }}>
                    <ExaminationROS
                      subPro={subPro}
                      pageTask={pageTask}
                      setPageTask={setPageTask}
                      type="procedure"
                      keyPanelCheck={keyPanelCheck}
                      dataAll={params}
                      dataCheck={
                        (params && params["ros"] && params["ros"][activeRow]) || (dataHospital && dataHospital.length > 0 && dataHospital[activeRow])
                      }
                      onChangeData={(value, pageTask) => {
                        handleSelect("ros", value, pageTask);
                      }}
                      isLocked={disableFinalize}
                    />
                    {subPro && subPro.length > 0 && subPro[pageTask] ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          alignItems: "center",
                          paddingBottom: "2rem",
                          minHeight: 40,
                          paddingRight: "0.5rem",
                          marginTop: "2rem",
                        }}
                      >
                        {pageTask > 0 ? (
                          <button
                            className="common-btn"
                            onClick={onPrev}
                            style={{ marginTop: "2rem", marginLeft: "0.5rem" }}
                            disabled={disableFinalize === true && showUnfinalize === false}
                          >
                            Back
                          </button>
                        ) : null}
                        <button
                          className="common-btn"
                          onClick={onContinue}
                          style={{ marginTop: "2rem", marginLeft: "0.5rem" }}
                          disabled={disableFinalize === true && showUnfinalize === false}
                        >
                          Continue
                        </button>
                      </div>
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          alignItems: "center",
                          paddingBottom: "2rem",
                          minHeight: 40,
                          paddingRight: "0.5rem",
                          marginTop: "2rem",
                        }}
                      >
                        {pageTask > 0 ? (
                          <button
                            className="common-btn"
                            onClick={onPrev}
                            style={{ marginTop: "2rem", marginLeft: "0.5rem" }}
                            disabled={disableFinalize === true && showUnfinalize === false}
                          >
                            Back
                          </button>
                        ) : null}
                        <button
                          className="common-btn"
                          onClick={onAccept}
                          style={{ marginTop: "2rem", marginLeft: "0.5rem" }}
                          disabled={disableFinalize === true && showUnfinalize === false}
                        >
                          Generate Report
                        </button>
                      </div>
                    )}
                  </div>
                </Panel>
                <Panel header="Specifications" key="specifications">
                  <div className="resourceInfo d-flex align-items-end required-field">
                    <EditableFormInput
                      label={"%X%"}
                      handleChange={(e) => {
                        setValueX(e);
                      }}
                      value={valueX}
                    />
                  </div>
                  {text_param.includes("%X%") ? (
                    <div className="actionContent" style={{ paddingBottom: "2rem" }}>
                      <button className="common-btn" style={{ marginTop: "2rem", marginLeft: "0.5rem" }} onClick={onContinueValueX}>
                        Continue
                      </button>
                    </div>
                  ) : null}
                </Panel>
                <Panel
                  className={params && params["cpt_codes"] && params["cpt_codes"].length > 0 ? "" : "invalid"}
                  header="Billing Codes"
                  key="billing-codes"
                >
                  <div className="resourceInfo d-flex" style={{ paddingLeft: "1rem" }}>
                    <CptReview
                      isRmBorder={true}
                      onScroll={(value) => {
                        onScroll(value);
                      }}
                      keySearch="cpt_code_name"
                      keyValue="cpt_code"
                      keyType="short_desc"
                      title="previous-medical-cpt"
                      DataOpSearch={selectResult || []}
                      rows={(params && params["cpt_codes"]) || []}
                      handleSearch={setSelectSearch}
                      selectItem={selectItem}
                      removeItem={removeRow}
                      isResult={isResult}
                      isLocked={disableFinalize}
                      isHaveSearch
                    />
                  </div>
                </Panel>
              </Collapse>
            </div>
            <div className="actionContent" style={{ paddingBottom: "2rem" }}>
              <button
                className="common-btn"
                onClick={copy}
                style={{ marginTop: "2rem", marginRight: "10px" }}
                disabled={disableSave === true || disableFinalize === true}
              >
                Copy
              </button>
              <button
                className="common-btn"
                onClick={save_patient_charting}
                style={{ marginTop: "2rem" }}
                disabled={disableSave === true || disableFinalize === true}
              >
                Save
              </button>
              <button
                className="common-btn"
                onClick={onFinalize}
                style={{ marginTop: "2rem", marginLeft: "0.5rem" }}
                disabled={disableFinalize === true && showUnfinalize === false}
              >
                {`${showUnfinalize ? "Unfinalize" : "Finalize +"}`}
              </button>
            </div>
          </ScrollWrapper>
        </div>
      </Drawer>
    );
  }
);

export default ChartingSideBar;
