import { Input, Menu } from "antd";
import React from "react";
import { connect } from "react-redux";
import ButtonDropdown from "../../../common/components/EditableText/Dropdown";
import { finalizePatientPending, finalizeProcedurePending, finalizeUltrasoundProcessing } from "../../../services/api.services";
import {
  getCallback,
  getClinicCallback,
  getClinicPending,
  getPending,
  getProcedureCallback,
  getProcedurePending,
  getUltrasoundCallback,
  getUltrasoundPending,
  patient_cancel,
  procedure_cancel,
  setGlobalLoading,
} from "../../../store/actions";

const { TextArea } = Input;

class Finalize extends React.PureComponent {
  state = {
    buttonText: "Finalize",
  };

  componentDidMount() {
    if (this?.props?.type == "ClinicProcessing") {
      this.setState({ buttonText: "Confirm" });
    } else if (this?.props?.type == "UltrasoundProcessing") {
      this.setState({ buttonText: "Confirm" });
    }
  }

  /*
  Finalize button handle
   */
  handleCommunication = async () => {
    if (this?.props?.type == "newPatient") {
      await finalizePatientPending(this.props.newPatient?.id);
      await this.props.setGlobalLoading(true);
      await this.props.getPending({});
      await this.props.getCallback({});
      await this.props.setGlobalLoading(false);
      this.props.handleCloseSidebar();
    } else if (this?.props?.type == "Procedure") {
      await finalizeProcedurePending(this.props.resource?.id);
      await this.props.setGlobalLoading(true);
      await this.props.getProcedurePending({});
      await this.props.getProcedureCallback({});
      await this.props.setGlobalLoading(false);
      this.props.handleCloseSidebar();
    } else if (this?.props?.type == "ClinicProcessing") {
      await finalizeProcedurePending(this.props.resource?.id);
      await this.props.setGlobalLoading(true);
      await this.props.getClinicPending({});
      await this.props.getClinicCallback({});
      await this.props.setGlobalLoading(false);
      this.props.handleCloseSidebar();
    } else {
      await finalizeUltrasoundProcessing(this.props.resource?.id);
      await this.props.setGlobalLoading(true);
      await this.props.getUltrasoundPending({});
      await this.props.getUltrasoundCallback({});
      await this.props.setGlobalLoading(false);
      this.props.handleCloseSidebar();
    }
  };

  clickCancel = async (value, index) => {
    if (index !== 3) {
      if (this?.props?.type == "newPatient") {
        await this.props.setGlobalLoading(true);
        await this.props.patient_cancel(this.props.newPatient?.id, {
          reason: value,
        });
        await this.props.getPending({});
        await this.props.getCallback({});
        await this.props.setGlobalLoading(false);
      } else {
        await this.props.setGlobalLoading(true);
        await this.props.procedure_cancel(this.props.resource?.id, {
          reason: value,
        });
        await this.props.getProcedurePending({});
        await this.props.getProcedureCallback({});
        await this.props.setGlobalLoading(false);
      }
      this.props.actionShowNote(false);
      if (this.props.onCancel) {
        this.props.onCancel();
      }
    } else {
      this.props.actionShowNote(true);
    }
  };

  onChange = (value) => {
    this.props.setNoteCancelValue(value.target?.value);
  };

  clickNoteCancel = async () => {
    if (this?.props?.type == "newPatient") {
      await this.props.setGlobalLoading(true);
      await this.props.patient_cancel(this.props.newPatient?.id, {
        reason: this.props.noteCancelValue,
      });
      await this.props.getPending({});
      await this.props.getCallback({});
      await this.props.setGlobalLoading(false);
    } else {
      await this.props.setGlobalLoading(true);
      await this.props.procedure_cancel(this.props.resource?.id, {
        reason: this.props.noteCancelValue,
      });
      await this.props.getProcedurePending({});
      await this.props.getProcedureCallback({});
      await this.props.setGlobalLoading(false);
    }
  };

  menu = (
    <Menu>
      {[
        {
          display: "Entered in Error",
          value: "",
        },
        {
          display: "Patient deceased",
          value: "",
        },
        {
          display: "Other",
          value: "",
        },
      ].map((item, index) => {
        return (
          <Menu.Item
            key={index}
            onClick={() => {
              this.clickCancel(item, index);
            }}
          >
            <a>{item}</a>
          </Menu.Item>
        );
      })}
    </Menu>
  );

  render() {
    return (
      <div className="resourceContent">
        <div className="resourceInfo d-flex">
          <ButtonDropdown
            options={[
              {
                display: "Entered in Error",
                value: "Entered in Error",
              },
              {
                display: "Patient deceased",
                value: "Patient deceased",
              },
              {
                display: "Other",
                value: "Other",
              },
            ]}
            onSelect={(item, index) => {
              this.clickCancel(item, index);
            }}
            label="Cancel"
          />
          <button
            className="common-btn blue-btn"
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
            onClick={this.handleCommunication}
          >
            <span>{this.state.buttonText}</span>
            <span>+</span>
          </button>
        </div>
        {this.props.showNote && (
          <React.Fragment>
            <TextArea rows={4} value={this.props.noteCancelValue} style={{ marginTop: 20 }} showCount maxLength={100} onChange={this.onChange} />
            <div className="actionContent">
              <button
                onClick={() => {
                  this.clickNoteCancel();
                }}
                className="common-btn"
              >
                Save
              </button>
            </div>
          </React.Fragment>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  resource: state.procedure.resource,
  newPatient: state.newPatient.resource,
});

const mapDispatchToProps = {
  getPending,
  getCallback,
  getProcedureCallback,
  setGlobalLoading,
  getProcedurePending,
  finalizeProcedurePending,
  finalizePatientPending,
  patient_cancel,
  procedure_cancel,
  getClinicPending,
  getClinicCallback,
  getUltrasoundPending,
  getUltrasoundCallback,
};

export default connect(mapStateToProps, mapDispatchToProps)(Finalize);
