import moment from "moment";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Icon } from "antd";
import DataTable from "../../../../common/components/DataTable/DataTable";
import { getInventoryCostHistory, setGlobalLoading, setInventoryData } from "../../../../store/actions";
import { StaticResource } from "../../constant";
import SuppliesFilter from "./SuppliesFilter";
import SuppliesSidebar from "./SuppliesSidebar";

const filterDataByOption = (data, option) => {
  const { quantity, location, product_number, startDate, endDate } = option;
  let filteredData = data;
  if (!product_number && !location && !quantity && !startDate && !endDate) {
    return filteredData;
  }
  if (startDate) {
    filteredData = filteredData?.filter((d) => d.updated_at && moment(startDate).diff(moment(d.updated_at)) >= 0);
  }
  if (endDate) {
    filteredData = filteredData?.filter((d) => d.updated_at && moment(d.updated_at).diff(moment(endDate)) >= 0);
  }
  if (product_number) {
    filteredData = filteredData?.filter((d) => d.product_number === product_number);
  }
  if (quantity) {
    filteredData = filteredData?.filter((d) => d.quantity >= +quantity);
  }
  if (location) {
    filteredData = filteredData?.filter((d) => d.location === location);
  }
  return filteredData;
};

const Supplies = ({ data, title, locations }) => {
  const [isSidebar, setIsSidebar] = useState(false);
  const ref = useRef(null);

  const supplies = useSelector((state) => state.inventory.supplies);
  const inventory = useSelector((state) => state.inventory.inventory);
  const filterOption = useSelector((state) => state.inventory.inventoryFilter);
  const loading = useSelector((state) => state.inventory.loadingAPI);

  const dispatch = useDispatch();

  const [activeIndex, setRow] = useState(-1);
  const [offset, setOffset] = useState(0);
  const tableRows = useMemo(() => {
    if (supplies && supplies.length) {
      return filterDataByOption(supplies, filterOption);
    }
    return [];
  }, [supplies, filterOption]);

  useEffect(() => {
    dispatch(setInventoryData("inventory", null));
  }, []);

  const handleSidebar = () => {
    setIsSidebar(true);
  };

  const handleCloseSidebar = () => {
    setIsSidebar(false);
  };

  const handleRow = (index, row) => {
    setRow(index);
    dispatch(setInventoryData("inventory", rowData[index]));
    dispatch(getInventoryCostHistory(rowData[index].id, "angiographic"));
    handleSidebar();
  };

  const rowData = useMemo(() => {
    if (!tableRows || !tableRows.length) {
      return [];
    }
    if (tableRows && tableRows.length && offset <= tableRows.length) {
      const count = Math.min(50, tableRows.length - offset);
      return tableRows.slice(0, offset + count);
    }
  }, [offset, tableRows]);

  const onScroll = (values) => {
    if (values.scrollTop > values.scrollHeight - values.clientHeight - 80 && offset < tableRows.length) {
      const count = Math.min(50, tableRows.length - offset);
      setOffset(offset + count);
    }
  };

  useEffect(() => {
    dispatch(setGlobalLoading(loading));
  }, [loading]);

  const sidebarRef = useRef();

  return (
    <div className="clinicWorkflow__main-section">
      <div className="eventsContainer has-sub-header">
        <div className="fullContainer pt-2">
          <SuppliesFilter locations={locations} />
          <div className="tableSection">
            <div ref={ref} className="tableContainer">
              <DataTable
                title="inventory_supplies"
                loadMore={onScroll}
                defaultActive={-1}
                hideHeader={true}
                disableDelete={true}
                labels={["Name", "Quantity", "Location", "Specific Location"]}
                widths={["calc(72% - 130px)", "calc(28% - 130px)", "130px", "130px"]}
                columns={[
                  { sortable: false, key: "name" },
                  { sortable: false, key: "specific_location" },
                  { sortable: false, key: "quantity" },
                  { sortable: false, key: "location" },
                ]}
                rowData={rowData?.map((row, index) => {
                  return {
                    name: (
                      <div
                        className="iconField td with-icon"
                        style={{
                          display: "flex",
                          paddingLeft: "0.25rem",
                          justifyContent: "flex-start",
                          alignItems: "center",
                        }}
                      >
                        <Icon type="tag" theme="twoTone" twoToneColor="#6929C4" />
                        <p style={{ paddingLeft: "0.75rem" }}>{row?.name || ""}</p>
                      </div>
                    ),
                    quantity: row?.quantity,
                    location: row?.location,
                    specific_location: row?.specific_location,
                  };
                })}
                handleClickRow={(row, index) => handleRow(index, row)}
              />
            </div>
          </div>
        </div>
      </div>
      {inventory && activeIndex >= 0 && (
        <SuppliesSidebar resource={StaticResource} isShow={isSidebar} ref={sidebarRef} handleCloseSidebar={handleCloseSidebar} />
      )}
    </div>
  );
};

export default Supplies;
