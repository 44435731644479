import { Icon } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DataTable from "../../../../common/components/DataTable/DataTable";
import { timeoff_requests_all } from "../../../../store/actions";
import { StaticResource } from "../../constant";
import VacationRequestSidebar from "./VacationRequestSidebar";

const VacationRequest = ({ data, title }) => {
  const [isSidebar, setIsSidebar] = useState(false);
  const [activeIndex, setRow] = useState(-1);
  const [tableRows, setTableRows] = useState([]);
  const dispatch = useDispatch();
  const timeRequests = useSelector((state) => state.common.timeoff_requests_all);

  useEffect(() => {
    setTableRows(timeRequests);
  }, [timeRequests]);

  const handleSidebar = () => {
    setIsSidebar(true);
  };

  const handleCloseSidebar = () => {
    setIsSidebar(false);
  };

  const handleRow = (index, id) => {
    setRow(index);
    handleSidebar();
  };

  const fetchList = () => {
    dispatch(timeoff_requests_all());
  };

  const timeRemaining = (id) => {
    let a = 0;
    if (timeRequests.length > 0) {
      for (const item in timeRequests) {
        if (timeRequests[item].user_id === id) {
          a += Number(timeRequests[item].amount_of_hours);
        }
      }
    }
    return a;
  };

  useEffect(() => {
    fetchList();
  }, []);

  const sidebarRef = useRef();

  return (
    <div className="clinicWorkflow__main-section">
      <div className="eventsContainer has-sub-header">
        <div className="fullContainer pt-2">
          <div className="tableSection">
            <div className="tableContainer">
              <DataTable
                title="vacation_request"
                labels={["Employee Name", "Start Date / Time", "End Date / Time", "Reason"]}
                widths={["31%", "23%", "23%", "23%"]}
                columns={[
                  { sortable: false, key: "name" },
                  { sortable: false, key: "start" },
                  { sortable: false, key: "end" },
                  { sortable: false, key: "reason" },
                ]}
                disableDelete={true}
                handleClickRow={(row, index) => handleRow(index, row)}
                defaultActive={-1}
                rowData={tableRows.map((row, index) => {
                  return {
                    name: (
                      <div
                        className="td with-icon"
                        style={{
                          display: "flex",
                          paddingLeft: "0.25rem",
                          justifyContent: "flex-start",
                          alignItems: "center",
                        }}
                      >
                        {!row.is_approved ? (
                          <div className="iconField d-flex">
                            <Icon type="warning" theme="twoTone" twoToneColor="#FF832B" />
                          </div>
                        ) : null}
                        {row.is_approved ? (
                          <div className="iconField d-flex">
                            <Icon type="check-circle" theme="twoTone" twoToneColor="#24A148" />
                          </div>
                        ) : null}
                        <p>{row.user && row.user?.name}</p>
                      </div>
                    ),
                    start: row.start_time,
                    end: row.end_time,
                    reason: row.reason,
                  };
                })}
              />
            </div>
          </div>
        </div>
      </div>

      {timeRequests && timeRequests.length > 0 && activeIndex >= 0 ? (
        <VacationRequestSidebar
          ref={sidebarRef}
          timeDetail={timeRequests[activeIndex]}
          timeRemaining={timeRemaining(timeRequests[activeIndex] && timeRequests[activeIndex].user_id)}
          resource={StaticResource}
          isShow={isSidebar}
          handleCloseSidebar={handleCloseSidebar}
        />
      ) : null}
    </div>
  );
};

export default VacationRequest;
