import { Tooltip } from "antd";
import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import TopBar from "../../../common/components/SubTopBar";
import ClinicWorkflowSidebar from "../../../common/views/QuickRegisterSidebar";
import DayCalendar from "./DayCalendar";

import PusherCheck from "../../../common/components/PusherCheck";
import { FemaleSVG, MaleSVG } from "../../../common/components/icons/FaxSVG";
import { getLocal, setLocal } from "../../../helpers/Local";
import {
  chooseSelectedPatientResource,
  getClinicNursingLocations,
  getPatientSchedules,
  getProviders,
  getReferralConditions,
  get_patient_notes,
  patient_insurances_id_insurances,
  setDataPatient,
} from "../../../store/actions";

const resources = [
  {
    id: "clinic",
    title: "Clinic",
  },
  {
    id: "us",
    title: "Ultrasound",
  },
];

class CheckIn extends Component {
  sidebarRef = null;
  constructor(props) {
    super(props);
    this.sidebarRef = React.createRef();
  }

  state = {
    isSidebar: false,
    events: [],
    resources: [],
    searchValue: "",
    pusherCheckBuild: [],
    pusherCheckUnfinalized: [],
    selectedCategories: [],
    schedulesState: [],
    isSelectedPatient: false,
    filters: {
      date: moment(new Date()).format("YYYY-MM-DD"),
      location: "",
      provider_id: "",
    },
  };

  componentDidMount = () => {
    const { date } = this.state.filters;
    const localCheck = JSON.parse(getLocal("scheduleParams"));
    const scheduleFilters = {
      date: localCheck && localCheck?.date ? localCheck?.date || date : date,
      location: (localCheck && localCheck?.location) || "",
      provider_id: (localCheck && localCheck?.provider_id) || "",
    };
    this.props.getClinicNursingLocations();
    this.props.getProviders();
    this.loadInitialPaymentJs();
    this.props.getReferralConditions();
    this.handleEvents(scheduleFilters);
  };

  loadInitialPaymentJs = () => {
    const sqPaymentScript = document.createElement("script");
    sqPaymentScript.src = "https://js.squareup.com/v2/paymentform";
    sqPaymentScript.type = "text/javascript";
    sqPaymentScript.async = false;
    sqPaymentScript.onload = () => {};
    document.getElementsByTagName("head")[0].appendChild(sqPaymentScript);
  };

  handleSidebar = () => {
    this.setState({ isSidebar: true });
  };

  toggleSidebar = () => {
    this.setState({ isSidebar: !this.state.isSidebar });
  };

  handleCloseSidebar = () => {
    this.setState({ isSidebar: false });
  };

  handleEvents = ({ date, location, provider_id }) => {
    this.setState({
      ...this.state,
      date,
      location,
      provider_id,
    });
    this.props.getPatientSchedules({
      date,
      location,
      provider_id,
      patient_id: "",
      only_clinics: 1,
      only_procedures: 0,
      only_us: 1,
      only_angio: 0,
    });
  };

  onSearch = (searchValue) => {
    this.setState({ searchValue });
    if (searchValue.length > 0) {
      const searchPatientName = (this.props.schedules || []).filter((item) => {
        return item.patientName.toLowerCase().includes(searchValue.toLowerCase());
      });
      this.setState({ schedulesState: searchPatientName });
    } else {
      this.setState({ schedulesState: this.props.schedules });
    }
  };

  onSelectResource = async (patient_id) => {
    setLocal("current_patient_id", patient_id);
    const result = await this.props.chooseSelectedPatientResource(patient_id);
    if (result) {
      this.props.setDataPatient(result);
    }
    this.props.patient_insurances_id_insurances(patient_id);
    this.props.get_patient_notes(patient_id);
    this.handleSidebar();
  };

  onSelectAppointment = (appointment_id) => {
    setLocal("current_appointment_id", appointment_id);
  };

  refreshCalendar = () => {
    const { date, location, provider_id } = this.state;
    this.props.getPatientSchedules({
      date,
      location,
      provider_id,
      patient_id: "",
      only_clinics: 1,
      only_procedures: 0,
      only_us: 1,
      only_angio: 0,
    });
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.schedules) {
      this.setState({ schedulesState: nextProps.schedules });
    }
    const isSelectedPatientProps = nextProps.selectedPatientResource || {};
    if (Object.keys(isSelectedPatientProps).length > 0) {
      this.setState({
        isSelectedPatient: true,
      });
    } else {
      this.setState({
        isSelectedPatient: false,
      });
    }
  }

  updateStatusPusher = (value) => {
    if (value && value?.appointment_id && this.state?.schedulesState) {
      const indexCheck = (this.state?.schedulesState || []).findIndex((r) => r?.id === value?.appointment_id);
      if (indexCheck !== -1) {
        const dataFix = [...this.state?.schedulesState];
        dataFix.splice(indexCheck, 1, {
          ...dataFix[indexCheck],
          status: value?.status,
        });
        this.setState({ schedulesState: [...dataFix] });
      }
    }
  };

  getItemBuild = (value) => {
    if (value !== null) {
      if (this.state.pusherCheckBuild.length == 0 || !this.state.pusherCheckBuild.some((r) => r == value)) {
        this.setState({
          pusherCheckBuild: [...this.state.pusherCheckBuild, value],
        });
      }
    }
  };

  dispatchAppointmentUnfinalized = (value) => {
    if (this.state.pusherCheckUnfinalized.length == 0 || !this.state.pusherCheckUnfinalized.some((r) => r == value)) {
      this.setState({
        pusherCheckUnfinalized: [...this.state.pusherCheckUnfinalized, value],
      });
    }
  };

  renderSideComponent = () => {
    const { resourceCheck } = this.props;
    if (!resourceCheck || !Object.keys(resourceCheck).length) {
      return null;
    }

    const fullName = (resourceCheck?.first_name + " " + resourceCheck?.last_name).toUpperCase();
    const age = resourceCheck?.date_of_birth ? "(" + moment().diff(resourceCheck?.date_of_birth, "years") + ")" : "";
    const allergies = [];
    if (resourceCheck?.allergies) {
      (resourceCheck?.allergies || []).forEach((allergy, index) => {
        const allergyName =
          allergy.reaction === "anaphylaxis" || allergy.status === "anaphylaxis" ? (
            <span
              style={{
                color: "red",
                fontSize: "0.625rem",
                fontWeight: 600,
              }}
              key={`${allergy?.id}-${allergy.allergy_name}`}
            >
              {allergy.allergy_name}
              {index < resourceCheck?.allergies.length - 1 ? ", " : ""}
            </span>
          ) : (
            <>
              {allergy.allergy_name}
              {index < resourceCheck?.allergies.length - 1 ? ", " : ""}
            </>
          );
        allergies.push(allergyName);
      });
    }

    return (
      <div
        style={{
          display: "flex",
          textAlign: "left",
          paddingRight: "12px",
          paddingLeft: "6px",
          paddingTop: "0px",
        }}
      >
        <div>
          <h3
            style={{
              color: "#152934",
              fontWeight: 400,
              fontSize: "1.125rem",
              lineHeight: "24px",
              whiteSpace: "nowrap",
              textAlign: "right",
              margin: 0,
              position: "relative",
              left: 1.5,
            }}
          >
            {fullName} {age}
          </h3>
          {allergies.length === 0 && (
            <div
              style={{
                textAlign: "right",
                whiteSpace: "nowrap",
                lineHeight: "10px",
              }}
            >
              <span
                style={{
                  color: "#da1e28",
                  fontSize: "0.625rem",
                  fontWeight: 600,
                }}
              >
                {resourceCheck?.no_drug_allergies ? "NKDA" : "Pending Data"}
              </span>
            </div>
          )}
          {allergies.length >= 1 && allergies.length < 3 && (
            <div
              style={{
                textAlign: "right",
                whiteSpace: "nowrap",
                lineHeight: "10px",
                color: "black",
                fontSize: "0.625rem",
                fontWeight: 600,
              }}
            >
              {allergies.map((allergy) => allergy)}
            </div>
          )}
          {allergies.length >= 3 && (
            <Tooltip placement="bottomRight" title={(resourceCheck?.allergies || []).map((allergy) => allergy?.allergy_name).join(", ")}>
              <div
                style={{
                  textAlign: "right",
                  whiteSpace: "nowrap",
                  lineHeight: "10px",
                  color: "black",
                  fontSize: "0.625rem",
                  fontWeight: 600,
                }}
              >
                {allergies.map((allergy) => allergy)}
              </div>
            </Tooltip>
          )}
        </div>

        {resourceCheck?.gender && resourceCheck?.gender.toUpperCase() == "MALE" ? (
          <MaleSVG style={{ marginLeft: "8px", height: "unset", width: "26px" }} />
        ) : (
          <FemaleSVG style={{ marginLeft: "8px", height: "unset", width: "26px" }} />
        )}
      </div>
    );
  };

  render() {
    const { isSidebar, isSelectedPatient } = this.state;
    const { selectedPatientResource } = this.props;
    const { first_name, last_name } = selectedPatientResource;

    return (
      <div className="clinicWorkflow__main-page">
        <TopBar title="Check-In Module" isBarcode={isSelectedPatient} sideComponent={this.renderSideComponent()} />
        <PusherCheck
          updateStatus={this.updateStatusPusher}
          getItemBuild={this.getItemBuild}
          dispatchAppointmentUnfinalized={this.dispatchAppointmentUnfinalized}
        ></PusherCheck>
        <ClinicWorkflowSidebar
          ref={this.sidebarRef}
          canCheckIn={true}
          resource={selectedPatientResource}
          isShow={isSidebar}
          handleCloseSidebar={this.handleCloseSidebar}
          paymentForm={window.SqPaymentForm}
          refreshCalendar={this.refreshCalendar}
          referralConditions={this.props.referralConditions || []}
          isNormal={true}
          sidebarTitle={`PATIENT DASHBOARD - ${first_name} ${last_name} ${
            getLocal("current_appointment_id") ? `[${getLocal("current_appointment_id")}]` : ""
          }`}
        />
        <div className={`clinicWorkflow__main-container ${isSidebar ? "openSidebar" : ""}`}>
          <div className="clinicWorkflow__main-section">
            <div className="eventsContainer">
              <div className="calendarContainer">
                <DayCalendar
                  locations={this.props.locations || []}
                  providers={this.props.providers || []}
                  events={this.state.schedulesState || []}
                  pusherCheckBuild={this.state.pusherCheckBuild}
                  pusherCheckUnfinalized={this.state.pusherCheckUnfinalized}
                  slotProviders={this.props.scheduleProviders || []}
                  resources={resources}
                  loading={this.props.loadingSchedules || this.props.loadingResource}
                  fetchEvents={this.handleEvents}
                  onSearch={this.onSearch}
                  valueSearch={this.state.searchValue}
                  onSelectResource={this.onSelectResource}
                  calendarType="check-in"
                  onSelectAppointment={this.onSelectAppointment}
                  isClinicWorkFlow={true}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  locations: state.common.clinicNursingLocations,
  providers: state.common.providers,
  schedules: state.patientSchedule.schedules,
  scheduleProviders: state.patientSchedule.providers,
  loadingSchedules: state.patientSchedule.loadingSchedules,
  referralConditions: state.common.referralConditions,
  selectedPatientResource: state.patientSchedule.selectedPatientResource,
  loadingResource: state.patientSchedule.loadingResource,
  resourceCheck: state.patientSnapshot.resource,
});

const mapDispatchToProps = {
  getClinicNursingLocations,
  getProviders,
  getPatientSchedules,
  chooseSelectedPatientResource,
  getReferralConditions,
  get_patient_notes,
  patient_insurances_id_insurances,
  setDataPatient,
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckIn);
