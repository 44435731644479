import { Drawer, Empty, Icon } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PharmacyAddForm from "src/common/components/PharmacyAddForm";
import PharmacyEditForm from "src/common/components/PharmacyEditForm";
import { getSinglePharmacy } from "src/services/api.services";
import { getLocal } from "../../../helpers/Local";
import { validateField } from "../../../services/validations";
import {
  createMedication,
  getOnlyProviders,
  getPharmacies,
  getPrescriptions,
  getSingleMedication,
  refillMedication,
  searchMedications,
  setDataPatient,
  setGlobalLoading,
  setPatientForRecords,
  setPatientSnapshotData,
} from "../../../store/actions";
import EditableInput from "../../components/EditableText/EditableFormInput";
import Loading from "../../components/Loading";
import ScrollWrapper from "../../components/ScrollBar";
import ToastMessage from "../../components/Toast";
import YesNo from "../../components/YesNo";

const initFre = [
  {
    value: "Qid",
    id: "qid",
  },
  {
    value: "Bid",
    id: "bid",
  },
  {
    value: "Qdaily",
    id: "qdaily",
  },
  {
    value: "Qweekly",
    id: "qweekly",
  },
  {
    value: "Tid",
    id: "tid",
  },
];

const MedicationSidebar = React.forwardRef(
  (
    {
      resource,
      isShow,
      sidebarIcon,
      handleCloseSidebar,
      setPharmacyAddForm,
      setPharmacyEditForm,
      isPharmacyAdding,
      isPharmacyEdit,
      valuePharmacyEdit,
      onReload,
    },
    ref
  ) => {
    const pharmacies = useSelector((state) => state.common.pharmacies);
    const loadingPharmacies = useSelector((state) => state.common.loadingPharmacies);
    const medications = useSelector((state) => state.common.dataMedications);
    const loadingSearchMedications = useSelector((state) => state.common.loadingSearchMedications);
    const singleMedication = useSelector((state) => state.common.medication);
    const patientResource = useSelector((state) => state.clinicProvider.patientResource || state.patientRecords.resource);
    const [detailPharmacy, setDetailPharmacy] = useState(null);
    const only_providers = useSelector((state) => state.common.only_providers);
    const sideNavStatus = useSelector((state) => state.common.sideNavStatus);
    const dispatch = useDispatch();
    const [localLoading, setLocalLoading] = useState(false);
    const [state, setState] = useState(null);
    const [isToastr, setIsToastr] = useState(false);
    const [isAddForm, setOpenAddForm] = useState(false);
    const [error, setError] = useState(false);
    let timer = null;
    const current_patient_id = getLocal("current_patient_id");

    const fetchPharmacy = async () => {
      const detailP = await getSinglePharmacy(patientResource?.pharmacy_id);
      setDetailPharmacy(detailP);
    };

    useEffect(() => {
      setState(resource);
    }, [resource]);

    useEffect(() => {
      if (patientResource?.pharmacy_id) {
        fetchPharmacy();
      }
    }, [patientResource]);

    const onHandleClick = async () => {
      await dispatch(setGlobalLoading(true));
      let params = {
        pharmacy_id: state?.pharmacy_id,
      };
      const send_success = await dispatch(refillMedication(resource?.id, params));
      if (send_success) {
        await dispatch(getPrescriptions(resource.patient_id));
        setIsToastr(true);
        setTimeout(() => {
          setIsToastr(false);
        }, 1000);
        await dispatch(setGlobalLoading(false));
      }
    };

    const handleChange = (type, value) => {
      if (type == "pharmacy_id") {
        let name = "";
        let id = "";
        if (value) {
          const slpitValue = value.split(" - ");
          name = slpitValue[0] || "";
          id = slpitValue[1] || "";
        }
        setState({
          ...state,
          [type]: id ? Number(id) : null,
          pharmacy_name: name,
        });
      } else if (type == "medication_id") {
        let id = "";
        let name = "";
        if (value) {
          const slpitValue = value.split(" - ");
          id = slpitValue[1] || "";
          name = slpitValue[0] || "";
          setState({
            ...state,
            [type]: id ? Number(id) : null,
            drug_name: name,
          });
          dispatch(getSingleMedication(id));
        }
      } else {
        setState({
          ...state,
          [type]: value,
        });
      }
    };

    const onAddNew = async () => {
      await dispatch(setGlobalLoading(true));
      let params = {
        patient_id: current_patient_id,
        pharmacy_id: state?.pharmacy_id,
        medication_id: state?.medication_id,
        frequency: state?.frequency,
        number_of_unit: state?.number_of_unit,
        number_of_refill: state?.number_of_refill,
        dispense_as_written: state?.dispense_as_written,
        additional_instruction: state?.additional_instruction,
        user_id: state?.user_id,
      };
      if (
        validateField("text", state.pharmacy_id) ||
        validateField("text", state.medication_id) ||
        validateField("text", state.frequency) ||
        validateField("text", state.number_of_unit) ||
        validateField("text", state.number_of_refill) ||
        validateField("text", state.dispense_as_written)
      ) {
        setError(true);
        await dispatch(setGlobalLoading(false));
      } else {
        const send_success = await dispatch(createMedication(params));
        if (send_success) {
          await dispatch(getPrescriptions(current_patient_id));
          onReload && onReload();
          setIsToastr(true);
          setTimeout(() => {
            setIsToastr(false);
          }, 1000);
          setState({});
          setOpenAddForm(false);
          const result = await dispatch(setPatientForRecords(current_patient_id));
          if (result) {
            dispatch(setDataPatient(result));
          }
          await dispatch(setPatientSnapshotData("resource", result));
          await dispatch(setGlobalLoading(false));
        }
      }
    };

    const searchHandle = async (value) => {
      setLocalLoading(true);
      dispatch(setGlobalLoading(true));
      clearTimeout(timer);
      timer = setTimeout(async () => {
        await dispatch(getPharmacies(value));
        setLocalLoading(false);
        dispatch(setGlobalLoading(false));
      }, 500);
    };

    const searchHandleMedication = async (value) => {
      setLocalLoading(true);
      dispatch(setGlobalLoading(true));
      clearTimeout(timer);
      timer = setTimeout(async () => {
        await dispatch(searchMedications(value));
        setLocalLoading(false);
        dispatch(setGlobalLoading(false));
      }, 500);
    };

    const openAddForm = () => {
      setState({ dispense_as_written: 1 });
      setOpenAddForm(true);
      dispatch(getOnlyProviders());
    };

    const { first_name, last_name } = patientResource;

    return (
      <Drawer
        title={`MEDICATIONS - ${first_name} ${last_name}`}
        placement="right"
        variable="permanent"
        className={sideNavStatus ? "wide" : ""}
        closable={false}
        mask={false}
        visible={isShow}
        destroyOnClose
      >
        {isPharmacyAdding ? (
          <div ref={ref} className="resourceContainer new">
            <ScrollWrapper css="x-hidden">
              <div className="formStepContainer">
                <div key={`step`} className={`formStepper active`}>
                  <div id={`step`} className="formStepper__header">
                    <div className="formStepper__status"></div>
                    <div className="formStepper__title">{"Pharmacy Information"}</div>
                  </div>

                  <div className="formStepper__content">
                    <PharmacyAddForm
                      isMedication={true}
                      onReloadClick={onReload}
                      isPharmacyAdding={isPharmacyAdding}
                      isPharmacyEdit={isPharmacyEdit}
                      setPharmacyAddForm={setPharmacyAddForm}
                      setPharmacyEditForm={setPharmacyEditForm}
                    />
                  </div>
                </div>
              </div>
            </ScrollWrapper>
          </div>
        ) : isPharmacyEdit ? (
          <div ref={ref} className="resourceContainer new">
            <ScrollWrapper css="x-hidden">
              <div className="formStepContainer">
                <div key={`step`} className={`formStepper active`}>
                  <div id={`step`} className="formStepper__header">
                    <div className="formStepper__status"></div>
                    <div className="formStepper__title">{"Pharmacy Information"}</div>
                  </div>

                  <div className="formStepper__content">
                    <PharmacyEditForm
                      isMedication={true}
                      onReloadClick={onReload}
                      isPharmacyAdding={isPharmacyAdding}
                      isPharmacyEdit={isPharmacyEdit}
                      valuePharmacyEdit={valuePharmacyEdit}
                      setPharmacyAddForm={setPharmacyAddForm}
                      setPharmacyEditForm={setPharmacyEditForm}
                    />
                  </div>
                </div>
              </div>
            </ScrollWrapper>
          </div>
        ) : (
          <div ref={ref} className="resourceContainer new">
            {isToastr && <ToastMessage type="Appointment Medication" status="success" msg="Fax is sent successfully!" />}
            <ScrollWrapper>
              <div
                className="resourceContent"
                style={{
                  flex: "unset",
                  paddingLeft: "24px",
                  paddingRight: "20px",
                  minHeight: "calc(100% - 160px)",
                  position: "relative",
                }}
              >
                {!isAddForm && (
                  <React.Fragment>
                    <div className="resourceInfo d-flex align-items-end required-field">
                      <EditableInput label={"Drug Name"} value={resource?.medication?.full_medication} />
                    </div>
                    <div className="resourceInfo d-flex align-items-end required-field">
                      <EditableInput label={"Dose"} value={resource?.medication?.strength} />
                    </div>
                    <div className="resourceInfo d-flex align-items-end required-field">
                      <EditableInput label={"Route"} value={resource?.medication?.route} />
                    </div>
                    <div className="resourceInfo d-flex align-items-end required-field">
                      <EditableInput label={"Frequency"} value={resource?.frequency} />
                    </div>
                    <div className="resourceInfo d-flex align-items-end required-field">
                      <EditableInput label={"Number of Units"} value={resource?.number_of_unit} />
                    </div>
                    <div className="resourceInfo d-flex align-items-end required-field">
                      <EditableInput label={"Number of Refills"} value={resource?.number_of_refill} />
                    </div>
                    <div className="resourceInfo d-flex align-items-end required-field">
                      <EditableInput minRows={4} type={"area"} label={"Additional Instructions"} value={resource?.additional_instruction} />
                    </div>
                    <div className="resourceInfo d-flex align-items-end required-field">
                      <EditableInput label={"Prescriber"} value={resource?.prescriber?.name} />
                    </div>
                    <div className="resourceInfo d-flex align-items-end required-field">
                      <EditableInput label={"User"} value={resource?.user?.name} />
                    </div>
                    <div className="resourceInfo d-flex align-items-end required-field">
                      <EditableInput
                        label={"Pharmacy"}
                        hideIconSelect={true}
                        type={"check"}
                        value={resource?.pharmacy_name && resource?.pharmacy_id ? `${resource?.pharmacy_name} - ${resource?.pharmacy_id}` : undefined}
                        options={pharmacies}
                        autoClearSearchValue={false}
                        onChangeSearch={(value) => {
                          searchHandle(value);
                        }}
                        handleChange={(value) => {
                          handleChange("pharmacy_id", value);
                        }}
                        toGetAll={true}
                        disableFilter={true}
                        notFoundContent={
                          localLoading || loadingPharmacies ? (
                            <div style={{ height: 100 }}>
                              <Loading />
                            </div>
                          ) : (
                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                          )
                        }
                        optionKey={"id"}
                        valueKey={"name"}
                      />
                      <span
                        style={{
                          width: 18,
                          position: "absolute",
                          left: 415,
                          height: "2.5rem",
                          display: "flex",
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                        onClick={() => setPharmacyAddForm && setPharmacyAddForm(true)}
                      >
                        <Icon type="plus-square" theme="twoTone" twoToneColor="#0f62fe" className="pharmacy-icon" />
                      </span>
                      {detailPharmacy?.id && (
                        <span
                          style={{
                            width: 18,
                            position: "absolute",
                            left: 440,
                            height: "2.5rem",
                            display: "flex",
                            alignItems: "center",
                            cursor: "pointer",
                          }}
                          onClick={() => setPharmacyEditForm && setPharmacyEditForm(true, detailPharmacy)}
                        >
                          <Icon type="eye" theme="twoTone" twoToneColor="#0f62fe" className="pharmacy-icon" />
                        </span>
                      )}
                    </div>
                    <div className="resourceInfo d-flex align-items-end required-field">
                      <div style={{ width: "51%" }} className="resourceInfo between half-pannel">
                        <div>Dispense as written</div>
                        <YesNo option={resource?.dispense_as_written} />
                      </div>
                    </div>
                    <div
                      className="actionContent"
                      style={{
                        minHeight: 0,
                        marginTop: 0,
                        position: "absolute",
                        right: 25,
                        bottom: 0,
                      }}
                    >
                      <button
                        onClick={() => {
                          openAddForm();
                        }}
                        style={{ marginRight: "10px" }}
                      >
                        Add New
                      </button>
                      <button
                        onClick={() => {
                          onHandleClick();
                        }}
                      >
                        Refill Prescription
                      </button>
                    </div>
                  </React.Fragment>
                )}
                {isAddForm && (
                  <React.Fragment>
                    <div className="resourceInfo d-flex align-items-end required-field">
                      <EditableInput
                        label={"Medication"}
                        hideIconSelect={true}
                        type={"check"}
                        value={state?.drug_name && state?.medication_id ? `${state?.drug_name} - ${state?.medication_id}` : undefined}
                        options={medications}
                        autoClearSearchValue={false}
                        onChangeSearch={(value) => {
                          searchHandleMedication(value);
                        }}
                        handleChange={(value) => {
                          handleChange("medication_id", value);
                        }}
                        toGetAll={true}
                        disableFilter={true}
                        notFoundContent={
                          localLoading || loadingSearchMedications ? (
                            <div style={{ height: 100 }}>
                              <Loading />
                            </div>
                          ) : (
                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                          )
                        }
                        optionKey={"id"}
                        valueKey={"full_medication"}
                        required={true}
                        helperText={"Drug name is required"}
                        isError={validateField("text", state?.medication_id)}
                        enableErrorText={error}
                      />
                    </div>
                    <div className="resourceInfo d-flex align-items-end required-field">
                      <EditableInput label={"Dose"} value={singleMedication?.strength} required={true} helperText={"Dosage is required"} />
                    </div>
                    <div className="resourceInfo d-flex align-items-end required-field">
                      <EditableInput label={"Route"} value={singleMedication?.route} required={true} helperText={"Route is required"} />
                    </div>
                    <div className="resourceInfo d-flex align-items-end required-field">
                      <EditableInput
                        label={"Frequency"}
                        options={initFre}
                        value={state?.frequency}
                        handleChange={(value) => {
                          handleChange("frequency", value);
                        }}
                        required={true}
                        optionKey={"id"}
                        valueKey={"value"}
                        type={"check"}
                        isError={validateField("text", state?.frequency)}
                        helperText={"Frequency is Required!"}
                        enableErrorText={error}
                      />
                    </div>
                    <div className="resourceInfo d-flex align-items-end required-field">
                      <EditableInput
                        label={"Number of Units"}
                        value={state?.number_of_unit}
                        handleChange={(value) => {
                          handleChange("number_of_unit", value);
                        }}
                        required={true}
                        helperText={"Number of unit is required"}
                        isError={validateField("text", state?.number_of_unit)}
                        enableErrorText={error}
                      />
                    </div>
                    <div className="resourceInfo d-flex align-items-end required-field">
                      <div className="resourceInfo between half-pannel">
                        <div>Dispense as written</div>
                        <YesNo
                          option={state?.dispense_as_written}
                          setOption={(value) => {
                            handleChange("dispense_as_written", value);
                          }}
                          required={true}
                          helperText={"Dispense as written is required"}
                          isError={validateField("text", state?.dispense_as_written)}
                          enableErrorText={error}
                        />
                      </div>
                    </div>
                    <div className="resourceInfo d-flex align-items-end required-field">
                      <EditableInput
                        label={"Number of Refills"}
                        value={state?.number_of_refill}
                        handleChange={(value) => {
                          handleChange("number_of_refill", value);
                        }}
                        required={true}
                        helperText={"Number of refill is required"}
                        isError={validateField("text", state?.number_of_refill)}
                        enableErrorText={error}
                      />
                    </div>
                    <div className="resourceInfo d-flex align-items-end required-field">
                      <EditableInput
                        minRows={4}
                        type={"area"}
                        label={"Additional Instructions"}
                        value={state?.additional_instruction}
                        handleChange={(value) => {
                          handleChange("additional_instruction", value);
                        }}
                      />
                    </div>
                    <div className="resourceInfo d-flex align-items-end required-field">
                      <EditableInput
                        label={"Prescriber"}
                        type={"check"}
                        options={only_providers}
                        value={state?.user_id}
                        handleChange={(value) => {
                          handleChange("user_id", value);
                        }}
                        optionKey={"id"}
                        valueKey={"value"}
                        required={true}
                        helperText={"Provider is required"}
                        isError={validateField("text", state?.user_id)}
                        enableErrorText={error}
                      />
                    </div>
                    <div className="resourceInfo d-flex align-items-end required-field">
                      <EditableInput label={"User"} disabled={true} />
                    </div>
                    <div className="resourceInfo d-flex align-items-end required-field">
                      <EditableInput
                        label={"Pharmacy"}
                        hideIconSelect={true}
                        type={"check"}
                        value={state?.pharmacy_name && state?.pharmacy_id ? `${state?.pharmacy_name} - ${state?.pharmacy_id}` : undefined}
                        options={pharmacies}
                        autoClearSearchValue={false}
                        onChangeSearch={(value) => {
                          searchHandle(value);
                        }}
                        handleChange={(value) => {
                          handleChange("pharmacy_id", value);
                        }}
                        toGetAll={true}
                        disableFilter={true}
                        notFoundContent={
                          localLoading || loadingPharmacies ? (
                            <div style={{ height: 100 }}>
                              <Loading />
                            </div>
                          ) : (
                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                          )
                        }
                        optionKey={"id"}
                        valueKey={"name"}
                        required={true}
                        helperText={"Pharmacy is required"}
                        isError={validateField("text", state?.pharmacy_id)}
                        enableErrorText={error}
                      />
                      <span
                        style={{
                          width: 18,
                          position: "absolute",
                          left: 415,
                          top: validateField("text", state?.pharmacy_id) ? 5 : "none",
                          height: "2.5rem",
                          display: "flex",
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                        onClick={() => setPharmacyAddForm && setPharmacyAddForm(true)}
                      >
                        <Icon type="plus-square" theme="twoTone" twoToneColor="#0f62fe" className="pharmacy-icon" />
                      </span>
                      {detailPharmacy?.id && (
                        <span
                          style={{
                            width: 18,
                            position: "absolute",
                            top: validateField("text", state?.pharmacy_id) ? 5 : "none",
                            left: 440,
                            height: "2.5rem",
                            display: "flex",
                            alignItems: "center",
                            cursor: "pointer",
                          }}
                          onClick={() => setPharmacyEditForm && setPharmacyEditForm(true, detailPharmacy)}
                        >
                          <Icon type="eye" theme="twoTone" twoToneColor="#0f62fe" className="pharmacy-icon" />
                        </span>
                      )}
                    </div>
                    <div
                      className="actionContent"
                      style={{
                        minHeight: 0,
                        marginTop: 0,
                        position: "absolute",
                        right: 25,
                        bottom: 0,
                      }}
                    >
                      <button
                        onClick={() => {
                          onAddNew();
                        }}
                      >
                        Send to Pharmacy
                      </button>
                    </div>
                  </React.Fragment>
                )}
              </div>
            </ScrollWrapper>
          </div>
        )}
      </Drawer>
    );
  }
);

export default MedicationSidebar;
