import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { validateField } from "../../../services/validations";
import { createNewPrivateProvider } from "../../../store/actions";
import { stateOptions } from "../../../common/constants/initialData";
import EditableFormInput from "../../../common/components/EditableText/EditableFormInput";
import ToastMessage from "../../../common/components/Toast";
import { autoZip } from "../../../services/api.services";

let timer = null;
const ProviderAddForm = ({ ...restProps }) => {
  const dispatch = useDispatch();
  const [provider, setProvider] = useState({
    first_name: "",
    last_name: "",
    suffix: "",
    phone_number: "",
    fax_number: "",
    email_address: "",
    address: "",
    suite: "",
    state: "",
    city: "",
    zipcode: "",
  });
  const [enableError, setEnableError] = useState(false);
  const [isToastr, setIsToastr] = useState(false);
  const [first, setFirst] = useState(false);
  const [zipAutoData, setZipAutoData] = useState({});

  useEffect(() => {
    setFirst(true);
  }, []);

  const setAutoZip = async () => {
    if (zipAutoData && zipAutoData.success) {
      if (zipAutoData.addresses && zipAutoData.addresses[0]) {
        const address = zipAutoData.addresses[0];
        const newProvider = {
          ...provider,
          state: address.state_id,
          city: address.place,
        };
        setProvider(newProvider);
      }
    } else {
      const newProvider = {
        ...provider,
        state: "",
        city: "",
      };
      setProvider(newProvider);
    }
  };

  useEffect(() => {
    if (zipAutoData && Object.keys(zipAutoData).length > 0 && first) {
      setAutoZip();
    }
  }, [zipAutoData]);

  const handleChange = (type) => (value) => {
    let newProvider = {};
    if (type === "zipcode") {
      clearTimeout(timer);
      newProvider = { ...provider, [type]: value };
      timer = setTimeout(async () => {
        const zipData = await autoZip(value);
        if (!zipData) {
          setZipAutoData({ success: false });
        } else {
          setZipAutoData(zipData);
        }
      }, 1000);
    } else {
      newProvider = { ...provider, [type]: value };
    }
    setProvider(newProvider);
  };

  const backToPatient = () => {
    restProps.setProviderAddForm(false);
  };

  const saveNewProvider = async () => {
    let error;
    Object.keys(provider).forEach((field) => {
      if (field !== "email_address" && field !== "suite") {
        if (!provider[field]) {
          error = true;
          setEnableError(true);
        }
      }
    });
    if (error) return;
    const isSuccess = await dispatch(createNewPrivateProvider(provider));
    if (isSuccess) {
      if (restProps.isMedication) {
        restProps.onReloadClick && restProps.onReloadClick();
        setIsToastr(true);
        backToPatient();
        setTimeout(() => {
          setIsToastr(false);
        }, 3000);
      } else {
        setIsToastr(true);
        backToPatient();
        setTimeout(() => {
          setIsToastr(false);
        }, 3000);
      }
    }
  };

  const showToast = () => {
    return <ToastMessage type="New Provider" status="success" msg="New provider is added successfully" />;
  };

  return (
    <div className="resourceContent pr-7px" style={{ position: "relative" }}>
      {isToastr && showToast()}
      <div className="resourceInfo d-flex required-field">
        <EditableFormInput
          label={"First Name"}
          value={provider?.first_name || ""}
          handleChange={handleChange("first_name")}
          required={true}
          isError={validateField("text", provider?.first_name)}
          enableErrorText={enableError}
          helperText={"First Name is Required"}
        />
      </div>
      <div className="resourceInfo d-flex required-field">
        <EditableFormInput
          label={"Last Name"}
          value={provider?.last_name}
          handleChange={handleChange("last_name")}
          required={true}
          isError={validateField("text", provider?.last_name)}
          enableErrorText={enableError}
          helperText={"Last Name is Required"}
        />
      </div>
      <div className="resourceInfo d-flex">
        <EditableFormInput
          label={"Suffix"}
          handleChange={handleChange("suffix")}
          value={provider?.suffix}
          required={true}
          isError={validateField("text", provider.suffix)}
          enableErrorText={enableError}
          helperText={"Suffix Field is Required"}
          type={"check"}
          optionKey={"value"}
          valueKey={"label"}
          options={[
            { value: "MD", label: "MD" },
            { value: "DO", label: "DO" },
            { value: "NP", label: "NP" },
            { value: "PA", label: "PA" },
            { value: "DPM", label: "DPM" },
          ]}
        />
      </div>
      <div className="resourceInfo d-flex required-field">
        <EditableFormInput
          label={"Phone No"}
          value={provider.phone_number}
          handleChange={handleChange("phone_number")}
          required={true}
          isError={validateField("phone", provider.phone_number)}
          enableErrorText={enableError}
          helperText={"Phone Number should be (XXX) XXX-XXXX format"}
          mask={"phone"}
        />
      </div>
      <div className="resourceInfo d-flex required-field">
        <EditableFormInput
          label={"Fax No"}
          value={provider.fax_number}
          handleChange={handleChange("fax_number")}
          required={true}
          isError={validateField("phone", provider.fax_number)}
          enableErrorText={enableError}
          helperText={"Fax Number should be (XXX) XXX-XXXX format"}
          mask={"phone"}
        />
      </div>
      <div className="resourceInfo d-flex required-field">
        <EditableFormInput
          label={"E-Mail"}
          value={provider.email_address}
          handleChange={handleChange("email_address")}
        />
      </div>
      <div className="resourceInfo d-flex required-field">
        <EditableFormInput
          label={"Address"}
          value={provider.address}
          handleChange={handleChange("address")}
          required={true}
          isError={validateField("text", provider.address)}
          enableErrorText={enableError}
          helperText={"Address is required"}
        />
      </div>
      <div className="resourceInfo d-flex required-field">
        <EditableFormInput label={"Suite"} value={provider.suite} handleChange={handleChange("suite")} />
      </div>
      <div className="resourceInfo d-flex required-field">
        <EditableFormInput
          label={"State"}
          value={provider.state || undefined}
          handleChange={handleChange("state")}
          type={"check"}
          valueKey={"value"}
          optionKey={"id"}
          options={stateOptions || []}
          required={true}
          isError={validateField("text", provider.state)}
          enableErrorText={enableError}
          helperText={"State is required"}
        />
      </div>
      <div className="resourceInfo d-flex required-field">
        <EditableFormInput
          label={"City"}
          value={provider.city}
          handleChange={handleChange("city")}
          required={true}
          isError={validateField("text", provider.city)}
          enableErrorText={enableError}
          helperText={"City is required"}
        />
      </div>
      <div className="resourceInfo d-flex required-field">
        <EditableFormInput
          label={"Zip Code"}
          value={provider.zipcode}
          handleChange={handleChange("zipcode")}
          required={true}
          isError={validateField("text", provider.zipcode)}
          enableErrorText={enableError}
          helperText={"Zip Code is required"}
        />
      </div>

      <div className="actionContent" style={{ flexWrap: "wrap", marginTop: "1.5rem" }}>
        <button className="common-btn outlined-btn" style={{ marginTop: "0.5rem" }} onClick={backToPatient}>
          Back
        </button>
        <button className="common-btn" style={{ marginTop: "0.5rem", marginLeft: "0.5rem" }} onClick={saveNewProvider}>
          Save
        </button>
      </div>
    </div>
  );
};

export default ProviderAddForm;
