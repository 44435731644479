import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import EditableFormInput from "src/common/components/EditableText/EditableFormInput";
import { getTaskManager, replyTaskManager } from "src/services/api.services";
import { useOutsideDetect } from "../../../common/utils";
import { getLocal } from "../../../helpers/Local";
import { setGlobalLoading, setGlobalToastr, setTaskManager } from "../../../store/actions";
import { Collapse, Icon } from "antd";

const TaskSearch = () => {
  const ref = useRef(null);
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const dataTaskManager = useSelector((state) => state.common.dataTaskManager);
  const dispatch = useDispatch();
  const [message, setMessage] = useState("");
  const [openPanel, setOpen] = useState(false);
  const [activeRow, setActiveRow] = useState(-1);
  const [activeSearchRow, setActiveSearchRecords] = useState(null);

  const callGetTask = async () => {
    dispatch(setGlobalLoading(true));
    const userID = await getLocal("cvai-current-user");
    if (userID) {
      const data = await getTaskManager(userID);
      if (data?.data) {
        dispatch(setTaskManager(data?.data));
        dispatch(setGlobalLoading(false));
      }
    } else {
      dispatch(setGlobalLoading(false));
    }
  };

  useOutsideDetect(ref, ref1, openPanel, setOpen);

  useEffect(() => {
    callGetTask();
  }, []);

  const handleSelect = async (row, index) => {
    setActiveSearchRecords(row);
    setActiveRow(index);
  };

  const handleCreateTask = async () => {
    if (message && message.length > 0) {
      dispatch(setGlobalLoading(true));
      const userID = await getLocal("cvai-current-user-provider-id");
      await replyTaskManager(activeSearchRow?.id, {
        reply: message,
      });
      const data = await getTaskManager(userID);
      if (data?.data) {
        dispatch(setTaskManager(data?.data));
        dispatch(
          setGlobalToastr({
            header: "New Message",
            message: "Send Message Success",
            type: "success",
          })
        );
      }
      dispatch(setGlobalLoading(false));
      setActiveRow(-1);
      setActiveSearchRecords(null);
      setMessage(null);
    } else {
      dispatch(
        setGlobalToastr({
          header: "New Message",
          message: "Please enter message!",
          type: "failed",
        })
      );
    }
  };

  const renderIcons = (type, urgency) => {
    let color = null;
    if (urgency.toLowerCase() === "emergent") color = "#ff7eb6";
    if (urgency.toLowerCase() === "urgent") color = "#FF832B";
    if (urgency.toLowerCase() === "routine") color = "#fffee8";
    if (color)
      return (
        <div className="has-tooltip">
          <Icon type="phone" style={{ zIndex: 1 }} theme="twoTone" twoToneColor={color} />
        </div>
      );
    else {
      return (
        <div
          style={{
            width: "16px",
            height: "16px",
            display: "flex",
            alignItems: "center",
            marginRight: "10px",
          }}
        >
          <img src={require(`../../../common/components/icons/${type}.svg`)} alt="maleSvgIcon" style={{ width: "100%" }} />
        </div>
      );
    }
  };
  return (
    <div className="patientSearch">
      {/* table */}
      <div className="fullContainer" style={{ padding: "10px 0 0 0", position: "relative", marginTop: -30 }}>
        <div className="tableSection" style={{ height: "100%" }}>
          <div ref={ref2} className="tableContainer">
            <div className="tableHeader">
              <div className="tableItem">
                <div className="th" style={{ borderLeft: 0, width: 50 }}>
                  <b></b>
                </div>
                <div className="th" style={{ width: "calc(45% - 50px)" }}>
                  <b>Patient Name</b>
                </div>
                <div className="th" style={{ width: "55%" }}>
                  <b>Details</b>
                </div>
              </div>
            </div>
            <div style={{ height: "calc(100% - 4rem)", overflow: "auto" }}>
              <Collapse
                onChange={(e, i) => {
                  setActiveRow(e);
                  setActiveSearchRecords(dataTaskManager[e] || "");
                }}
                activeKey={activeRow}
                accordion
                expandIcon={() => <div></div>}
                style={{ paddingRight: 0, paddingLeft: 0 }}
              >
                {(dataTaskManager || []).map((row, index) => (
                  <Collapse.Panel
                    onClick={() => handleSelect(row, index)}
                    header={
                      <div style={{ display: "flex", marginLeft: -20 }}>
                        <div className="td with-icon" style={{ width: "45%", fontWeight: "normal" }}>
                          {row?.patient?.gender && row?.patient?.gender.toLowerCase() === "male"
                            ? renderIcons("male", row?.urgency)
                            : renderIcons("female", row?.urgency)}
                          <p style={{ paddingLeft: 20, fontWeight: "normal" }}>{`${row?.patient?.name || ""}`}</p>
                        </div>
                        <div className="td" style={{ width: "55%", paddingLeft: 10, fontWeight: "normal" }}>
                          <p>{row?.created_at && moment(row?.created_at).format("MM/DD/YYYY hh:mm:ss A")}</p>
                        </div>
                      </div>
                    }
                    key={index}
                  >
                    <div className="formContainer">
                      <div style={{ marginBottom: "0.5rem" }}>
                        <EditableFormInput disabled={true} label="From Name" value={activeSearchRow?.user?.name} fullWidth />
                      </div>
                      <div style={{ marginBottom: "0.5rem" }}>
                        <EditableFormInput
                          disabled={true}
                          label="Contact No 1"
                          value={activeSearchRow?.patient?.phone_number}
                          fullWidth
                          mask={"phone"}
                        />
                      </div>
                      <div style={{ marginBottom: "0.5rem" }}>
                        <EditableFormInput
                          disabled={true}
                          label="Contact No 2"
                          value={activeSearchRow?.patient?.secondary_phone}
                          fullWidth
                          mask={"phone"}
                        />
                      </div>
                      <div style={{ marginBottom: "0.5rem" }}>
                        <EditableFormInput type={"area"} rows="3" disabled={true} value={activeSearchRow?.message} fullWidth />
                      </div>
                      <EditableFormInput
                        isChangeFontSize={true}
                        type={"area"}
                        handleChange={(e) => {
                          setMessage(e);
                        }}
                        label="Message"
                        customStyle={{ marginBottom: "0.5rem" }}
                        value={message}
                        isError={message && message.length ? false : true}
                        enableErrorText={true}
                        required={true}
                        helperText="Message is Required"
                        fullWidth
                      />
                      <div className="fullContainer__actions" style={{ paddingTop: "1rem" }}>
                        <button onClick={handleCreateTask} className="common-btn blue-btn" disabled={!activeRow || !activeSearchRow}>
                          Update and Close
                        </button>
                      </div>
                    </div>
                  </Collapse.Panel>
                ))}
              </Collapse>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TaskSearch;
