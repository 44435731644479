import { Icon } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import EditableFormInput from "../../../common/components/EditableText/EditableFormInput";
import {
  delete_clinic_procedure_type_variable,
  edit_clinic_procedure_type_variable,
  list_clinic_procedure_type_variable,
  setGlobalToastr,
  setProcedureDetails,
} from "../../../store/actions";
import RegexSpecial from "src/helpers/RegexSpecial";

let timer = null;
const VariableCapture = ({ resource }) => {
  // TODO: captures integration
  const clinicVariable = useSelector((state) => state.procedureDetail.clinicVariable);
  const list_clinic_type_variable = useSelector((state) => state.procedureDetail.list_clinic_type_variable);
  const detail_clinic_type = useSelector((state) => state.procedureDetail.detail_clinic_type);
  const dispatch = useDispatch();
  const [error, setError] = useState(null);

  const updateVariable = async () => {
    const checkArray = (list_clinic_type_variable || []).map((r, index) => {
      return {
        variable_name: r?.variable_name,
        id: r?.id,
        quest: r?.question,
        answer: r?.answers,
        condition_serialize: detail_clinic_type && Object.keys(detail_clinic_type).length > 0 && detail_clinic_type?.cpt_rules,
      };
    });
    await dispatch(setProcedureDetails("clinicVariable", [...checkArray]));
  };

  useEffect(() => {
    updateVariable();
    setError(null);
  }, [list_clinic_type_variable, detail_clinic_type]);

  const updateProviders = async (type, index, value, indexAnswer, idOnRow) => {
    if (type === "quest") {
      clinicVariable[index][type] = value;
      clearTimeout(timer);
      timer = setTimeout(() => {
        dispatch(
          edit_clinic_procedure_type_variable(idOnRow?.id, {
            variable_name: idOnRow?.variable_name,
            question: value,
            procedure_type_id: detail_clinic_type?.id,
            answers: clinicVariable[index]["answer"],
          })
        );
      }, 1000);
    } else {
      clinicVariable[index]["answer"][indexAnswer][type] = value;
      clearTimeout(timer);
      timer = setTimeout(() => {
        dispatch(
          edit_clinic_procedure_type_variable(idOnRow?.id, {
            variable_name: idOnRow?.variable_name,
            question: value,
            procedure_type_id: detail_clinic_type?.id,
            answers: clinicVariable[index]["answer"],
          })
        );
      }, 1000);
    }
    await dispatch(setProcedureDetails("clinicVariable", [...clinicVariable]));
  };
  const removeProvider = async (type, index, indexAnswer, idOnRow) => {
    if (type === "quest") {
      clinicVariable.splice(index, 1);
      await dispatch(delete_clinic_procedure_type_variable(idOnRow));
    } else {
      clinicVariable[index]["answer"].splice(indexAnswer, 1);
      await dispatch(
        edit_clinic_procedure_type_variable(idOnRow?.id, {
          variable_name: idOnRow?.variable_name,
          question: idOnRow?.quest,
          procedure_type_id: detail_clinic_type?.id,
          answers: clinicVariable[index]["answer"],
        })
      );
    }
    await dispatch(list_clinic_procedure_type_variable(detail_clinic_type?.id));
  };

  const handleError = (type, index) => {
    setError({
      type,
      index,
    });
    dispatch(
      setGlobalToastr({
        header: "Alert",
        type: "failed",
        message: "Invalid characters not allowed!",
      })
    );
  };

  if (resource === null || resource === undefined) {
    return null;
  }

  return (
    <div style={{ width: "100%", height: "100%", overflow: "scroll" }}>
      {(clinicVariable || []).map((r, index) => (
        <div
          key={index}
          style={{
            background: "#dbe3ee",
            padding: 12,
            marginBottom: index !== clinicVariable?.length - 1 ? "1rem" : 0,
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              margin: "5px 0 8px",
            }}
          >
            <div style={{ width: "calc(100% - 32px)", marginRight: "8px" }}>
              <EditableFormInput
                isError={error && error["type"] === "quest" && error["index"] === index ? true : false}
                required={true}
                handleChange={(value) => {
                  if (RegexSpecial(value)) {
                    handleError("quest", index);
                  } else {
                    updateProviders("quest", index, value, null, r);
                  }
                }}
                label="Specifications"
                value={r.quest}
                fullWidth
                focusWhite
              />
            </div>
            <div
              style={{
                width: "24px",
                cursor: "pointer",
                display: "inline-flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              onClick={() => removeProvider("quest", null, index, r?.id)}
            >
              <Icon type="delete" style={{ fontSize: "16px" }} />
            </div>
          </div>
          <div style={{ marginBottom: 10 }} className="resourceInfo d-flex">
            <b style={{ minWidth: "37%" }}>Variable Name: </b>
            {r.variable_name}
          </div>
          {(r?.answer || []).map((item, indexAnswer) => (
            <div
              key={indexAnswer}
              style={{
                display: "flex",
                justifyContent: "flex-start",
                marginBottom: indexAnswer !== r.answer.length - 1 ? "8px" : "0",
              }}
            >
              <div style={{ width: "calc(40% - 8px)", marginRight: "8px" }}>
                <EditableFormInput
                  isError={error && error["type"] === "answer" && error["index"] === indexAnswer ? true : false}
                  required={true}
                  handleChange={(value) => {
                    if (RegexSpecial(value)) {
                      handleError("answer", indexAnswer);
                    } else {
                      updateProviders("answer", index, value, indexAnswer, r);
                    }
                  }}
                  value={item.answer}
                  label={`Answer ${indexAnswer + 1}`}
                  fullWidth
                  focusWhite
                  type="area"
                  isHospitalProcedure={true}
                />
              </div>
              <div style={{ width: "calc(60% - 32px)", marginRight: "8px" }}>
                <EditableFormInput
                  isError={error && error["type"] === "text_template" && error["index"] === indexAnswer ? true : false}
                  required={true}
                  handleChange={(value) => {
                    if (RegexSpecial(value)) {
                      handleError("text_template", indexAnswer);
                    } else {
                      updateProviders("text_template", index, value, indexAnswer, r);
                    }
                  }}
                  label="Sentence"
                  value={item.text_template}
                  fullWidth
                  focusWhite
                  type="area"
                  isHospitalProcedure={true}
                />
              </div>
              <div
                style={{
                  width: "24px",
                  cursor: "pointer",
                  display: "inline-flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                onClick={() => removeProvider("answer", index, indexAnswer, r)}
              >
                <Icon type="delete" style={{ fontSize: "16px" }} />
              </div>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default VariableCapture;
