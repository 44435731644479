import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Input, Select, DatePicker, Empty } from 'antd';
import { ExpandSVG, SearchSVG } from '../../../common/components/icons/SVGIcon';
import {
  getPriorAuthorization,
  search_insurance_companies,
  setGlobalLoading,
  setPriorData,
} from '../../../store/actions';
import Loading from '../../../common/components/Loading';
import moment from 'moment';

const { Search } = Input;
const { Option } = Select;
let timer = null;
const PriorFilters = ({ filter, handleSearch, handleChangeFilter }) => {
  const types = useSelector((state) => state.prior.types);
  const authNumbers = useSelector((state) => state.prior.authNumbers);
  const [loadingLocal, setLoadingLocal] = useState(false);
  const [stateFocus, setStateFocus] = useState(false);
  const [typeFill, setTypeFill] = useState(null);
  const search_insurance_companies_value = useSelector(
    (state) => state.newPatient.search_insurance_companies,
  );
  const loading_search_insurance_companies = useSelector(
    (state) => state.newPatient.loading_search_insurance_companies,
  );
  const dispatch = useDispatch();

  useEffect(() => {
    setTypeFill(types || [])
  }, [types])

  const onChangeDate = (date, dateString) => {
    const newFilter = {
      ...filter,
      date_of_service: dateString,
    };
    handleChangeFilter(newFilter);
    dispatch(setPriorData('loadingPriors', true));
    dispatch(getPriorAuthorization(newFilter));
  };

  const changeSearch = (value) => {
    handleSearch(value);
  };

  useEffect(() => {
    if (loadingLocal || loading_search_insurance_companies) {
      dispatch(setGlobalLoading(true));
    } else {
      dispatch(setGlobalLoading(false));
    }
  }, [loadingLocal, loading_search_insurance_companies]);

  const changeType = (value) => {
    if (value && value.includes("-")) {
      const valueSplit = value.split("-");
      const typeCheck = valueSplit[1];
      const valueCheck = valueSplit[0];
      const selectedType =
        (typeFill || []).find((type) => type?.id == valueCheck && type?.type == typeCheck);
      const newFilter = {
        ...filter,
        type: selectedType?.type,
        appointment_type_id: selectedType?.id
      };
      handleChangeFilter(newFilter);
      dispatch(setPriorData('loadingPriors', true));
      dispatch(getPriorAuthorization(newFilter));
    } else {
      const newFilter = {
        ...filter,
        type: null,
        appointment_type_id: null
      };
      handleChangeFilter(newFilter);
      dispatch(setPriorData('loadingPriors', true));
      dispatch(getPriorAuthorization(newFilter));
    }
  };

  const changeCode = (value) => {
    const newFilter = {
      ...filter,
      authorization_number: value,
    };
    handleChangeFilter(newFilter);
  };

  const changeInsurance = (value) => {
    const newFilter = {
      ...filter,
      insurance_company_id: value,
    };
    handleChangeFilter(newFilter);
    dispatch(setPriorData('loadingPriors', true));
    dispatch(getPriorAuthorization(newFilter));
  };

  const changeResponseStatus = (status) => {
    const newFilter = {
      ...filter,
      response_status: status,
    };
    handleChangeFilter(newFilter);
  };

  const changeExpiration = (date, dateString) => {
    const newFilter = {
      ...filter,
      expiration_date: dateString,
    };
    handleChangeFilter(newFilter);
  };

  const searchInsurance = (value) => {
    clearTimeout(timer);
    setLoadingLocal(true);
    timer = setTimeout(() => {
      setLoadingLocal(false);
      dispatch(search_insurance_companies(value));
    }, 500);
  };

  return (
    <div className="filterContainer">
      <div className="searchFieldContainer">
        <div className="searchField">
          <Search
          className='dependencySearch'
            placeholder="Search Patient"
            onChange={(e) => changeSearch(e.target?.value)}
            style={{ width: '100%' }}
            suffix={<SearchSVG />}
          />
        </div>
      </div>

      <div className="statusFilterContainer">
        <div className="typeFilter">
          <Select
            placeholder="Filter by Type..."
            suffixIcon={<ExpandSVG />}
            allowClear={true}
            onChange={changeType}
            showSearch
            style={{ width: 'calc(100% - 0.75rem)' }}
          >
            {(typeFill || []).map((type, index) => (
              <Option key={`${index}-${type?.id}-${type?.name}`} value={`${type?.id}-${type?.type}`}>
                {type?.name}
              </Option>
            ))}
          </Select>
        </div>
        <div className="dateFilter">
          <DatePicker
            format={'MM/DD/YYYY'}
            placeholder="Date of Service..."
            defaultValue={moment(moment(), "MM/DD/YYYY")}
            suffixIcon={<ExpandSVG />}
            onChange={onChangeDate}
            style={{ width: 'calc(100% - 0.75rem)' }}
          />
        </div>
        <div className="resultFilter">
          <Select
            placeholder={'Insurance...'}
            showSearch
            style={{ width: 'calc(100% - 0.75rem)' }}
            optionFilterProp="children"
            suffixIcon={<ExpandSVG />}
            allowClear={true}
            onFocus={() => {
              setStateFocus(true);
            }}
            onBlur={() => {
              setStateFocus(false);
            }}
            filterOption={(inp, opt) =>
              opt.props.children.toLowerCase().indexOf(inp.toLowerCase()) >= 0
            }
            notFoundContent={
              loading_search_insurance_companies ? (
                <div style={{ height: 100 }}>
                  <Loading />
                </div>
              ) : (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              )
            }
            onSearch={(value) => {
              searchInsurance(value);
            }}
            onChange={changeInsurance}
          >
            {(search_insurance_companies_value || []).map((item, index) => (
              <Option key={item?.id} value={item?.id}>
                {`${item?.name}`}
              </Option>
            ))}
          </Select>
        </div>
        <div className="resultFilter">
          <Select
            placeholder="Authorization Code..."
            suffixIcon={<ExpandSVG />}
            allowClear={true}
            onChange={changeCode}
            style={{ width: 'calc(100% - 0.75rem)' }}
          >
            {(authNumbers || []).map((code, index) => (
              <Option
                key={`auth-code-${code.authorization_number}-${index}`}
                value={code.authorization_number}
              >
                {code.authorization_number}
              </Option>
            ))}
          </Select>
        </div>
        <div className="resultFilter">
          <DatePicker
            format={'MM/DD/YYYY'}
            placeholder="Expiration Date..."
            suffixIcon={<ExpandSVG />}
            onChange={changeExpiration}
            style={{ width: 'calc(100% - 0.75rem)' }}
          />
        </div>
        <div className="resultFilter">
          <Select
            placeholder="Authorization Code Result..."
            suffixIcon={<ExpandSVG />}
            onChange={changeResponseStatus}
            allowClear={true}
            style={{ width: '100%' }}
          >
            {[
              { authorization_result: 'Complete' },
              { authorization_result: 'Authorization Required' },
              { authorization_result: 'Processing' },
            ].map((code, index) => (
              <Option
                key={`auth-code-${code.authorization_result}-${index}`}
                value={code.authorization_result}
              >
                {code.authorization_result}
              </Option>
            ))}
          </Select>
        </div>
      </div>
    </div>
  );
};

export default PriorFilters;
