import { Input } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DataTable from "../../../../common/components/DataTable/WhiteHeaderTable";
import { procedureConsentSelectDocument } from "../../../../store/actions";

const ConsentDocumentList = ({}) => {
  const tableRef = useRef(null);
  const dispatch = useDispatch();
  const consentDocuments = useSelector((state) => state.procedureConsent.consentDocuments);
  const selectDocument = (selectedDocumentData) => {
    dispatch(procedureConsentSelectDocument(selectedDocumentData));
  };
  const [sortOption, setSortOption] = useState({
    date: "",
    procedure: "",
    provider: "",
  });
  const [emptyRows, setEmptyRows] = useState([]);

  useEffect(() => {
    const totalRowsCount = Math.floor(tableRef.current.offsetHeight / 40);
    const emptyRowsCount = totalRowsCount - consentDocuments.length;
    const emptyRows_ = [];
    for (let i = 0; i < emptyRowsCount; i++) {
      emptyRows_.push({ document: "" });
    }
    setEmptyRows(emptyRows_);
  }, [consentDocuments]);

  return (
    <>
      <div ref={tableRef}>
        <DataTable
          hideHeaderHeight={true}
          isBorderThick={true}
          isRmBorders={true}
          title="patient_chart_imagelist"
          isNormal
          labels={["Date", "Procedure", "Provider"]}
          widths={["30%", "30%", "40%"]}
          fullHeight
          activeIndex={0}
          showIcon={false}
          columns={[
            { sortable: true, key: "date" },
            { sortable: true, key: "procedure" },
            { sortable: true, key: "provider" },
          ]}
          rowData={(consentDocuments || []).map((doc, index) => {
            return {
              key: `document-list-${index}`,
              id: doc?.id,
              date: (
                <div className="dateField" style={{ width: "30%" }}>
                  <p>{doc?.date}</p>
                </div>
              ),
              procedure: (
                <div className="linkField" style={{ width: "35%", paddingLeft: "1.25rem" }}>
                  <p onClick={() => selectDocument(doc)}>{doc?.filename}</p>
                </div>
              ),
              provider: (
                <div className="authorField" style={{ minWidth: "35%", paddingLeft: "1.25rem" }}>
                  <Input style={{ paddingLeft: 8, width: "100%" }} defaultValue={doc?.provider} placeholder={"Provider..."} allowClear={true} />
                </div>
              ),
            };
          })}
          emptyData={emptyRows}
          sorts={[sortOption.date, sortOption.document_category]}
        />
      </div>
    </>
  );
};

export default ConsentDocumentList;
