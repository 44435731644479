import { Tabs } from "@material-ui/core";
import { Icon, Tooltip } from "antd";
import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import { FemaleSVG, MaleSVG } from "../../../common/components/icons/FaxSVG";
import PlanOrders from "../../../common/components/PlanOder/PlanOrders";
import PusherCheck from "../../../common/components/PusherCheck";
import TopBar from "../../../common/components/SubTopBar";
import Appointments from "../../../common/views/Appointment";
import Education from "../../../common/views/Education";
import Medications from "../../../common/views/Medications";
import PatientChart from "../../../common/views/PatientChart";
import PatientOverview from "../../../common/views/PatientSnapshot";
import { getLocal, setLocal } from "../../../helpers/Local";
import RemoveN from "../../../helpers/RemoveN";
import {
  clinicProviderPatientSnapshotLoadInit,
  load_procedurals,
  patientExaminationSave,
  releaseLock,
  remove_selected_patient_resource,
  resetVitalMedication,
  save_intra_operative,
  save_plan_patient,
  setActiveReviewChange,
  setFirstLoadingActive,
  setGlobalLoading,
  setGlobalToastr,
  save_procedurals,
  setProcedureReview,
} from "../../../store/actions";
import { TabHeaders } from "../constant";
import ClinicSchedule from "./providerSubViews/ClinicSchedule";
import Consent from "./providerSubViews/Consen";
import Discharge from "./providerSubViews/Discharge";
import IntaOperative from "./providerSubViews/IntaOperative";
import PostOperative from "./providerSubViews/PostOperative";
import PreOperative from "./providerSubViews/PreOperative";
import Review from "./providerSubViews/Review";

export class Provider extends Component {
  state = {
    activeTab: 0,
    isSelectedPatient: false,
    dataPre: null,
    checkAllPre: false,
    stateStatusChange: null,
    pusherCheckBuild: [],
    pusherCheckUnfinalized: [],
    patient_id_event: null,
    patient_id: null,
    dataPostOp: null,
    checkAllPostOp: false,
    dataDis: null,
    dataCheckIntra: null,
    lock: {},
  };

  componentDidMount() {
    if (this.props.patientResource) {
      this.setState({ isSelectedPatient: true });
    } else {
      this.setState({ isSelectedPatient: false });
    }
    if (this.props.firstLoadingActive) {
      this.setState({ activeTab: 9 });
      this.props.setFirstLoadingActive(false);
    }
    this.setState({
      patient_id: getLocal("current_patient_id"),
      patient_id_event: getLocal("current_appointment_id"),
    });
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      lock: nextProps.editLock,
    });
    const isSelectedPatientProps = nextProps.patientResource || null;
    if (isSelectedPatientProps) {
      this.setState({
        isSelectedPatient: true,
      });
    } else {
      this.setState({
        isSelectedPatient: false,
      });
    }
  }

  goNextTab_ = async () => {
    this.props.setGlobalLoading(true);
    const current_patient_id = await getLocal("current_patient_id");
    const appointment_id = await getLocal("current_appointment_id");
    await this.props.clinicProviderPatientSnapshotLoadInit(current_patient_id, appointment_id);
    await this.props.load_procedurals(appointment_id);
    this.props.setGlobalLoading(false);
    const checkSelectedInterval = setInterval(this.goNextTab, 50);
    this.setState({ ...this.state, checkSelectedInterval });
  };

  componentWillUnmount() {
    const procedure_id = getLocal("current_appointment_id");
    this.props.releaseLock(procedure_id, {});
  }

  goNextTab = () => {
    const { patientResource } = this.props;
    const { lock } = this.state;
    if (lock.locked === 1) {
      this.props.setGlobalToastr({
        header: "OBL Workflow",
        message: "This procedure has been locked by " + lock.user,
        type: "failed",
        link: "Notify " + lock.user,
        exlink: "Force release " + lock.user,
      });
      this.setState({ ...this.state, isModalVisible: true });
      clearInterval(this.state.checkSelectedInterval);
    } else {
      if (this.state.isSelectedPatient) {
        if (patientResource.has_valid_insurance === 0) {
          this.props.setGlobalToastr({
            header: "OBL Workflow",
            message: "Please verify the patient insurance before proceeding",
            type: "failed",
          });
        }
        clearInterval(this.state.checkSelectedInterval);
        this.setState({
          ...this.state,
          activeTab: 1,
          checkSelectedInterval: null,
        });
      }
    }
  };

  dataPostCheckAll = (value, checkAll) => {
    this.setState({
      dataPre: value,
      checkAllPre: checkAll,
    });
  };

  dataPostOpCheckAll = (value, checkAll) => {
    this.setState({
      dataPostOp: value,
      checkAllPostOp: checkAll,
    });
  };

  dataDisCheckAll = (value) => {
    this.setState({
      dataDis: value,
    });
  };

  renderSideComponent = () => {
    const { resourceCheck } = this.props;
    if (!resourceCheck || !Object.keys(resourceCheck).length) {
      return null;
    }

    const fullName = (resourceCheck?.first_name + " " + resourceCheck?.last_name).toUpperCase();
    const age = resourceCheck?.date_of_birth ? "(" + moment().diff(resourceCheck?.date_of_birth, "years") + ")" : "";

    const allerrgies = [];
    if (resourceCheck?.allergies) {
      (resourceCheck?.allergies || []).forEach((allergy, index) => {
        const allergyName =
          allergy.reaction === "anaphylaxis" || allergy.status === "anaphylaxis" ? (
            <span
              style={{
                color: "red",
                fontSize: "0.625rem",
                fontWeight: 600,
              }}
              key={`${allergy?.id}-${allergy.allergy_name}`}
            >
              {allergy.allergy_name}
              {index < resourceCheck?.allergies.length - 1 ? ", " : ""}
            </span>
          ) : (
            <>
              {allergy.allergy_name}
              {index < resourceCheck?.allergies.length - 1 ? ", " : ""}
            </>
          );
        allerrgies.push(allergyName);
      });
    }

    return (
      <div
        style={{
          display: "flex",
          textAlign: "left",
          paddingTop: "0px",
          paddingRight: "12px",
          paddingLeft: "6px",
        }}
      >
        <div>
          <h3
            style={{
              color: "#152934",
              fontWeight: 400,
              fontSize: "1.125rem",
              lineHeight: "24px",
              whiteSpace: "nowrap",
              textAlign: "right",
              margin: 0,
              position: "relative",
              left: 1.5,
            }}
          >
            {fullName} {age}
          </h3>
          {allerrgies.length === 0 && (
            <div
              style={{
                textAlign: "right",
                whiteSpace: "nowrap",
                lineHeight: "10px",
              }}
            >
              <span
                style={{
                  color: "#da1e28",
                  fontSize: "0.625rem",
                  fontWeight: 600,
                }}
              >
                {resourceCheck?.no_drug_allergies ? "NKDA" : "Pending Data"}
              </span>
            </div>
          )}
          {allerrgies.length >= 1 && allerrgies.length < 3 && (
            <div
              style={{
                textAlign: "right",
                whiteSpace: "nowrap",
                lineHeight: "10px",
                color: "black",
                fontSize: "0.625rem",
                fontWeight: 600,
              }}
            >
              {allerrgies.map((allergy, index) => (
                <React.Fragment key={`Al-${index}`}>{allergy}</React.Fragment>
              ))}
            </div>
          )}
          {allerrgies.length >= 3 && (
            <Tooltip placement="bottomRight" title={(resourceCheck?.allergies || []).map((allergy) => allergy?.allergy_name).join(", ")}>
              <div
                style={{
                  textAlign: "right",
                  whiteSpace: "nowrap",
                  lineHeight: "10px",
                  color: "black",
                  fontSize: "0.625rem",
                  fontWeight: 600,
                }}
              >
                {allerrgies.map((allergy, index) => (
                  <React.Fragment key={`Alg-${index}`}>{allergy}</React.Fragment>
                ))}
              </div>
            </Tooltip>
          )}
        </div>

        {resourceCheck?.gender && resourceCheck?.gender.toUpperCase() == "MALE" ? (
          <MaleSVG style={{ marginLeft: "8px", height: "unset", width: "26px" }} />
        ) : (
          <FemaleSVG style={{ marginLeft: "8px", height: "unset", width: "26px" }} />
        )}
      </div>
    );
  };

  goNextTabReview = () => {
    this.setState({
      activeTab: 9,
    });
    this.props.setActiveReviewChange(false);
  };

  handleTabIndex = async (index) => {
    const { patientResource, is_locked } = this.props;
    const procedure_id = getLocal("current_appointment_id");
    if (index === 0) {
      await this.props.releaseLock(procedure_id, {});
      await setLocal("current_appointment_id", null);
      this.props.remove_selected_patient_resource();
    }
    await this.props.setGlobalLoading(true);
    if (this.state.activeTab === 4 && (is_locked === false || is_locked === 0)) {
      if (this.state.dataPre && this.state.dataPre.length > 0) {
        const fixArr = (this.state.dataPre || []).map((r) => {
          return {
            ...r,
            comments: RemoveN(r?.comments || ""),
          };
        });
        await this.props.patientExaminationSave(procedure_id, {
          patient_id: patientResource?.id,
          pre_operative: fixArr,
          all_normal_pre_operative: this.state.checkAllPre,
        });
        this.setState({ dataPre: null });
      }
    } else if (this.state.activeTab === 6 && (is_locked === false || is_locked === 0)) {
      if (this.state.dataPostOp && this.state.dataPostOp.length > 0) {
        const fixArr = (this.state.dataPostOp || []).map((r) => {
          return {
            ...r,
            comments: RemoveN(r?.comments || ""),
          };
        });
        await this.props.patientExaminationSave(procedure_id, {
          patient_id: patientResource?.id,
          post_operative: fixArr,
          all_normal_post_operative: this.state.checkAllPostOp || -1,
        });
        this.setState({ dataPostOp: null });
      }
    } else if (this.state.activeTab === 7 && (is_locked === false || is_locked === 0)) {
      if (this.state.dataDis && this.state.dataDis.length > 0) {
        const fixArr = (this.state.dataDis || []).map((r) => {
          return {
            ...r,
            comments: RemoveN(r?.comments || ""),
          };
        });
        await this.props.patientExaminationSave(procedure_id, {
          patient_id: patientResource?.id,
          discharge: fixArr,
        });
        this.setState({ dataDis: null });
      }
    } else if (this.state.activeTab === 8 && (is_locked === false || is_locked === 0)) {
      await this.props.save_plan_patient(this.props.params_plan);
      if (index === 9) {
      }
    } else if (this.state.activeTab === 5 && (is_locked === false || is_locked === 0)) {
      const operations = this.props.intra_operations && this.props.intra_operations.length > 0 ? this.props.intra_operations : null;
      const id_appoinment = getLocal("current_appointment_id");
      if (operations && id_appoinment) {
        await this.props.save_procedurals(id_appoinment, {
          procedurals: operations.filter((r) => r.medicals && r.medicals.length > 0),
        });
        this.props.setProcedureReview("intra_operations", null);
      }
      if (this.state.dataCheckIntra && this.state.dataCheckIntra.length > 0) {
        const fixArr = (this.state.dataCheckIntra || []).map((r) => {
          return {
            ...r,
            questions: (r?.questions || []).map((q) => {
              return {
                ...q,
                comments: RemoveN(q?.comments),
              };
            }),
          };
        });
        await this.props.save_intra_operative(procedure_id, {
          patient_id: this.state.patient_id,
          conditions: fixArr,
        });
        this.setState({ dataCheckIntra: null });
      }
    }
    await this.props.resetVitalMedication();
    await this.props.setGlobalLoading(false);
    await this.setState({ activeTab: index });
  };

  getItemBuild = (value) => {
    if (value !== null) {
      if (this.state.pusherCheckBuild.length == 0 || !this.state.pusherCheckBuild.some((r) => r == value)) {
        this.setState({
          pusherCheckBuild: [...this.state.pusherCheckBuild, value],
        });
      }
    }
  };

  dispatchAppointmentUnfinalized = (value) => {
    if (this.state.pusherCheckUnfinalized.length == 0 || !this.state.pusherCheckUnfinalized.some((r) => r == value)) {
      this.setState({
        pusherCheckUnfinalized: [...this.state.pusherCheckUnfinalized, value],
      });
    }
  };

  updateStatusPusher = (value) => {
    if (value && value.appointment_id) {
      this.setState({
        stateStatusChange: value,
      });
    }
  };

  render() {
    const { selectedResource } = this.props;
    const { activeTab, isSelectedPatient, pusherCheckBuild, pusherCheckUnfinalized } = this.state;

    return (
      <div className="clinicWorkflow__main-page">
        <TopBar title="OBL Module" subStatus={""} sideComponent={this.renderSideComponent()} isBarcode={isSelectedPatient} />
        <div className={`clinicWorkflow__main-container ${this.props.isSidebar ? "openSidebar" : ""}`}>
          <div className="clinicWorkflow__main-subTitle tabContainer">
            <div className="tabHeaders" style={{ height: "29px" }}>
              <Tabs
                TabIndicatorProps={{ style: { display: "none" } }}
                value={activeTab}
                scrollButtons="auto"
                variant="scrollable"
                aria-label="scrollable force tabs example"
              >
                {TabHeaders.map((tab, index) =>
                  (isSelectedPatient && index < 3 && this.props.editLock.locked === 0) ||
                  (isSelectedPatient && selectedResource.has_valid_insurance === 1 && this.props.editLock.locked === 0) ? (
                    <div
                      id={tab?.label && tab?.label.length > 0 && tab?.label.replaceAll(/[&\/\\#,+()$~%.'":*?<>{}]/g, "").replaceAll(" ", "_")}
                      key={`tabHeaders-${tab.label}-${index}`}
                      className={`tabHeaders-item ${activeTab === index ? "active" : ""}`}
                      style={{ minWidth: tab.width }}
                      onClick={() => {
                        this.handleTabIndex(index);
                      }}
                    >
                      {tab.label}
                    </div>
                  ) : (
                    <div
                      key={`tabHeaders-${tab.label}-${index}`}
                      className={`tabHeaders-item ${activeTab === index ? "active" : ""} ${index > 0 && "disabled"}`}
                      style={{ minWidth: tab.width }}
                    >
                      {tab.label}
                    </div>
                  )
                )}
              </Tabs>
            </div>
          </div>
          <PusherCheck
            updateStatus={this.updateStatusPusher}
            setIndex={() => {
              this.props.remove_selected_patient_resource();
              this.setState({ activeTab: 0 });
            }}
            getItemBuild={this.getItemBuild}
            dispatchAppointmentUnfinalized={this.dispatchAppointmentUnfinalized}
          ></PusherCheck>
          {activeTab >= 0 && (
            <ClinicSchedule
              stateStatusProps={this.state.stateStatusChange}
              updateStatusProps={() => {
                this.setState({ stateStatusChange: null });
              }}
              pusherCheckBuild={pusherCheckBuild}
              pusherCheckUnfinalized={pusherCheckUnfinalized}
              display={activeTab === 0}
              goNextTab={this.goNextTab_}
              goNextTabReview={this.goNextTabReview}
              isOblModule={true}
            />
          )}
          {activeTab === 1 && (
            <PatientOverview
              isShowGoto={false}
              sidebarIcon={<Icon type="medicine-box" theme="twoTone" twoToneColor="#198038" />}
              patientResource={selectedResource}
            />
          )}
          {activeTab === 2 && (
            <PatientChart sidebarIcon={<Icon type="medicine-box" theme="twoTone" twoToneColor="#198038" />} patientResource={selectedResource} />
          )}
          {selectedResource.has_valid_insurance === 1 && activeTab === 3 && <Consent />}
          {selectedResource.has_valid_insurance === 1 && activeTab === 4 && (
            <PreOperative
              dataPostCheckAll={(data, checkAll) => {
                this.dataPostCheckAll(data, checkAll);
              }}
            />
          )}
          {selectedResource.has_valid_insurance === 1 && activeTab === 5 && (
            <IntaOperative
              dataCheckIntra={(data) => {
                this.setState({
                  dataCheckIntra: data,
                });
              }}
            />
          )}
          {selectedResource.has_valid_insurance === 1 && activeTab === 6 && (
            <PostOperative
              dataPostOpCheckAll={(data, checkAll) => {
                this.dataPostOpCheckAll(data, checkAll);
              }}
            />
          )}
          {selectedResource.has_valid_insurance === 1 && activeTab === 7 && (
            <Discharge
              dataDisCheckAll={(data) => {
                this.dataDisCheckAll(data);
              }}
            />
          )}
          {selectedResource.has_valid_insurance === 1 && activeTab === 8 && (
            <PlanOrders navigateToReview={this.goNextTabReview} sidebarIcon={<Icon type="medicine-box" theme="twoTone" twoToneColor="#198038" />} />
          )}
          {selectedResource.has_valid_insurance === 1 && activeTab === 9 && (
            <Review sidebarIcon={<Icon type="medicine-box" theme="twoTone" twoToneColor="#198038" />} />
          )}
          {selectedResource.has_valid_insurance === 1 && activeTab === 10 && (
            <Medications sidebarIcon={<Icon type="file-text" theme="twoTone" twoToneColor="#740937" />} />
          )}
          {selectedResource.has_valid_insurance === 1 && activeTab === 11 && <Appointments />}
          {selectedResource.has_valid_insurance === 1 && activeTab === 12 && (
            <Education sidebarIcon={<Icon type="medicine-box" theme="twoTone" twoToneColor="#198038" />} patientResource={selectedResource} />
          )}
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  firstLoadingActive: state.common.firstLoadingActive,
  patientResource: state.patientSchedule.selectedPatientResource?.id,
  selectedResource: state.patientSchedule.selectedPatientResource,
  resourceCheck: state.patientSnapshot.resource,
  isSidebar: state.sidebar.isSidebarOpen,
  editLock: state.common.editLock,
  params_plan: state.common.params_plan,
  is_locked: state.common.is_locked,
  intra_operations: state.procedureReview.intra_operations,
});

const mapDispatchToProps = {
  patientExaminationSave,
  setActiveReviewChange,
  resetVitalMedication,
  setGlobalToastr,
  setGlobalLoading,
  releaseLock,
  save_plan_patient,
  setFirstLoadingActive,
  save_intra_operative,
  remove_selected_patient_resource,
  load_procedurals,
  clinicProviderPatientSnapshotLoadInit,
  save_procedurals,
  setProcedureReview,
};

export default connect(mapStateToProps, mapDispatchToProps)(Provider);
