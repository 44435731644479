import { DatePicker, Select, TimePicker } from "antd";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ExpandSVG, LeftCharvenSVG } from "../../../common/components/icons/SVGIcon";
import {
  filterProviders,
  getClinicCallback,
  getClinicPending,
  getTimeSlots,
  savePlanClinicSchedule,
  setGlobalLoading,
  setGlobalToastr,
  setProcedureData,
} from "../../../store/actions";

const { Option } = Select;

const getValue = (options, target, key) => {
  if (key && options) {
    const opt = options.find((option) => option[key] == target);
    if (opt) {
      return opt?.value;
    }
    return "";
  }
  return "";
};

const SecondStepComponent = ({ handleContinue, handleStep }) => {
  const locations = useSelector((state) => state.procedure.locations);
  const providers = useSelector((state) => state.procedure.providers);
  const resourceCheck = useSelector((state) => state.procedure.resource);
  const [resource, setResource] = useState({});
  const loading = useSelector((state) => state.procedure.loadingSchedule);
  const planDetails = useSelector((state) => state.procedure.planDetails);
  const timeSlotsCheck = useSelector((state) => state.procedure.timeSlots);
  const [appointmentCheck, setAppointmentCheck] = useState(null);

  const [timeSlots, setTimeSlots] = useState([]);
  const [lock, setLock] = useState(false);
  const loadingFirst = useSelector((state) => state.procedure.loadingTimeSlots);
  const firstClinic = useSelector((state) => state.procedure.timeSlot);

  useEffect(() => {
    if (firstClinic == -1 && timeSlotsCheck.length > 0) {
      setLock(true);
    } else {
      setLock(false);
    }
  }, [firstClinic, timeSlotsCheck]);

  useEffect(() => {
    setTimeSlots(timeSlotsCheck);
  }, [timeSlotsCheck]);

  useEffect(() => {
    if (resourceCheck) {
      setResource(resourceCheck);
    } else {
      resourceCheck({});
    }
  }, [resourceCheck]);

  useEffect(() => {
    dispatch(filterProviders(""));
  }, []);

  const [fristTimeCheck, setFristTimeCheck] = useState(false);
  const [endarterectomy, setEndarterectomy] = useState([]);

  const [activeCustom, setActiveCustom] = useState({
    first: false,
    second: false,
  });

  const [customAppointment, setCustomAppointment] = useState({
    first: {},
    second: {},
  });

  const dispatch = useDispatch();

  const [timeOption, setTimeOption] = useState({
    location: "",
    provider: "",
    period: "",
    prev_date: "",
  });

  const [questions, setQuestions] = useState({
    taking_metformin: -1,
    taking_eliquis: -1,
    taking_coumadin: -1,
    taking_plavix: -1,
    receive_coronary_stent: -1,
  });

  const saveAllDate = (type) => {
    if (type === "time") {
      if (timeSlots && timeSlots[0]) {
        const checkTimeSlots = timeSlots[0];
        if (checkTimeSlots) {
          if (endarterectomy && !endarterectomy.some((r) => r.start === checkTimeSlots.start)) {
            setEndarterectomy([...endarterectomy, checkTimeSlots]);
          }
        }
      }
    }
  };

  useEffect(() => {
    dispatch(setGlobalLoading(loading || loadingFirst));
  }, [loading, loadingFirst]);

  useEffect(() => {
    if (resource && resource.patient) {
      setAppointmentCheck(resource.appointment_date);
      const intialQuestions = {
        taking_metformin: resource.patient.taking_metformin !== null ? resource.patient.taking_metformin : -1,
        taking_eliquis: resource.patient.taking_eliquis !== null ? resource.patient.taking_eliquis : -1,
        taking_coumadin: resource.patient.taking_coumadin !== null ? resource.patient.taking_coumadin : -1,
        taking_plavix: resource.patient.taking_plavix !== null ? resource.patient.taking_plavix : -1,
        receive_coronary_stent: resource.patient.receive_coronary_stent !== null ? resource.patient.receive_coronary_stent : -1,
      };
      setQuestions(intialQuestions);
    }
  }, [resource]);

  const title = useMemo(() => {
    if (planDetails && planDetails.procedure) {
      return planDetails.procedure;
    }
    return "Procedure";
  }, [planDetails]);

  const setFiirst = (index) => {
    dispatch(setProcedureData("timeSlot", index));
  };

  const saveSchedule = async (firstClinicCheck, timeSlotsCheck) => {
    const timeSlot =
      timeSlotsCheck && firstClinicCheck && firstClinicCheck > -1
        ? timeSlotsCheck[firstClinicCheck]
        : timeSlotsCheck && timeSlotsCheck[0]
        ? timeSlotsCheck[0]
        : "";
    if (timeSlot) {
      const params = {
        procedure_date: moment(timeSlot.start).format("MM/DD/YYYY"),
        procedure_time: moment(timeSlot.start).format("hh:mm A"),
        procedure_location: timeSlot.location,
        procedure_provider: timeSlot.doctor,
      };
      dispatch(setGlobalLoading(true));
      const SaveSuccess = await dispatch(savePlanClinicSchedule(resource?.id, params));
      dispatch(
        setGlobalToastr({
          header: "Clinic",
          message: "The appointment has been saved",
          type: "success",
        })
      );
      await dispatch(getClinicPending({}));
      await dispatch(getClinicCallback({}));
      await dispatch(setGlobalLoading(false));
    }
  };

  const handleChangeSlots = (type) => (value) => {
    const prev_date =
      type == "location" || type == "provider"
        ? ""
        : firstClinic !== -1
        ? moment(timeSlots[firstClinic].start).format("MM/DD/YYYY")
        : timeSlots[0]
        ? moment(timeSlots[0].start).format("MM/DD/YYYY")
        : "";
    const newOption = { ...timeOption, prev_date, [type]: value };
    setTimeOption(newOption);
    dispatch(setProcedureData("loadingTimeSlots", true));
    dispatch(getTimeSlots(resource.patient_id, resource?.id, newOption));
    setFiirst(-1);
  };

  const selectTimeSlot = (row, index) => {
    setFiirst(index);
  };

  const handleMoreDates = (type) => {
    saveAllDate(type);
    if (timeSlots && timeSlots.length > 0 && type === "time") {
      const option = {
        ...timeOption,
        prev_date: moment(timeSlots[timeSlots.length - 1].start).format("MM/DD/YYYY"),
      };
      setTimeOption(option);
      dispatch(setProcedureData("loadingTimeSlots", true));
      dispatch(getTimeSlots(resource.patient_id, resource?.id, option, false, false));
      setFiirst(-1);
    }
  };

  const previous = (type) => {
    if (
      endarterectomy.length == 0 &&
      type === "time" &&
      moment(moment(appointmentCheck).subtract(7, "days").format("MM/DD/YYYY")) > moment() &&
      !fristTimeCheck
    ) {
      const option = {
        ...timeOption,
        prev_date: moment(moment(appointmentCheck).subtract(7, "days").format("MM/DD/YYYY")).format("MM/DD/YYYY"),
      };
      setFristTimeCheck(true);
      setTimeOption(option);
      dispatch(setProcedureData("loadingTimeSlots", true));
      dispatch(getTimeSlots(resource.patient_id, resource?.id, option, null));
      setFiirst(-1);
    } else if (
      endarterectomy.length == 0 &&
      type === "time" &&
      moment(moment(appointmentCheck).subtract(7, "days").format("MM/DD/YYYY")) > moment() &&
      fristTimeCheck &&
      timeSlots &&
      timeSlots[0] &&
      timeSlots[0].start &&
      moment(moment(timeSlots[0].start).subtract(7, "days").format("MM/DD/YYYY")) > moment()
    ) {
      const option = {
        ...timeOption,
        prev_date: moment(moment(timeSlots[0].start).subtract(7, "days").format("MM/DD/YYYY")).format("MM/DD/YYYY"),
      };
      setFristTimeCheck(true);
      setTimeOption(option);
      dispatch(setProcedureData("loadingTimeSlots", true));
      dispatch(getTimeSlots(resource.patient_id, resource?.id, option, null));
      setFiirst(-1);
    }
    if (endarterectomy.length > 0 && type === "time") {
      const option = {
        ...timeOption,
        prev_date: moment(endarterectomy[endarterectomy.length - 1].start).format("MM/DD/YYYY"),
      };
      //
      setTimeOption(option);
      dispatch(setProcedureData("loadingTimeSlots", true));
      dispatch(getTimeSlots(resource.patient_id, resource?.id, option, null));
      setFiirst(-1);
      const aa = endarterectomy;
      if (aa) {
        aa.splice(endarterectomy.length - 1, 1);
        setEndarterectomy(aa);
      }
    }
  };

  const handleCustomChange = (idx, type, value) => {
    const customAppointments = { ...customAppointment };
    customAppointments[idx][type] = value;
    setCustomAppointment(customAppointments);
  };

  const toggleCustom = (type) => {
    const isCustomArray = { ...activeCustom };
    const customAppointments = { ...customAppointment };
    isCustomArray[type] = !isCustomArray[type];
    customAppointments[type] = isCustomArray[type]
      ? {
          location: "",
          date: "",
          time: "",
          provider: "",
        }
      : {};

    setActiveCustom(isCustomArray);
    setCustomAppointment(customAppointments);
  };

  const emptySlots =
    timeSlots && timeSlots.length && timeSlots.length < 3
      ? new Array(3 - timeSlots.length).fill("empty")
      : !timeSlots || !timeSlots.length
      ? new Array(3).fill("empty")
      : [];

  const checkActive = (slot) => {
    if (moment(slot.start).format("MM/DD/YYYY") === moment(appointmentCheck).format("MM/DD/YYYY")) {
      return "active";
    }
    return "";
  };

  const handleTimeSlot = (value, index, slot) => {
    const mixSlot = [...timeSlots];
    mixSlot[index]["start_slot"] = value;
    const dateSlot = moment()
      .hours(8)
      .minutes(0)
      .add(value * 15, "minutes")
      .format("HH:mm:ss");
    if (mixSlot[index]["start"] && mixSlot[index]["start"].includes(" ")) {
      const mixSplit = mixSlot[index]["start"].split(" ");
      if (mixSplit[0]) {
        mixSlot[index]["start"] = `${mixSplit[0]} ${dateSlot}`;
      }
    }
    if (timeSlots[index].start && checkActive(slot) === "active") {
      const value = moment(timeSlots[index].start).format("MM/DD/YYYY HH:mm:ss");
      setAppointmentCheck(value);
    }
    setFiirst(index);
    setTimeSlots(mixSlot);
  };

  const onHandleClick = () => {
    if (!loading && !loadingFirst) {
      saveSchedule(firstClinic, timeSlots);
    }
  };

  return (
    <>
      <div className="resourceContent pr-7px" style={{ position: "relative" }}>
        <div className="resourceInfo d-flex" style={{ justifyContent: "space-between" }}>
          <b>{title}</b>
        </div>

        <div style={{ marginBottom: "4px" }}>
          <div className="resourceInfo between mt-2 sortableHeader" style={{ background: "#f4f7fb" }}>
            <div
              className={`th sortable w-25 hide-icon`}
              style={{
                paddingLeft: "2px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                alignSelf: "stretch",
                borderLeft: "1px solid #dfe3e6",
              }}
            >
              <Select
                placeholder="Location"
                value={timeOption.location || undefined}
                suffixIcon={<span />}
                allowClear={false}
                style={{
                  maxWidth: "100%",
                  width: "100%",
                  background: "transparent",
                  borderBottom: 0,
                  boxShadow: "none",
                }}
                onChange={handleChangeSlots("location")}
              >
                {(locations || []).map((loc, index) => (
                  <Option key={`location-${loc?.id}-${index}`} value={loc?.id}>
                    {loc?.value}
                  </Option>
                ))}
              </Select>
            </div>

            <div
              className={`th sortable w-25 hide-icon`}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                alignSelf: "stretch",
                borderLeft: "1px solid #dfe3e6",
              }}
            >
              Date
              <div>
                <button
                  className="more-dates backward"
                  style={{
                    marginRight: "0.5rem",
                    padding: "2px",
                    minWidth: "unset",
                    width: "auto",
                    height: "auto",
                    border: "none",
                    background: "transparent",
                    color: "#3d70be",
                    marginTop: 0,
                  }}
                  onClick={() => {
                    previous("time");
                  }}
                >
                  <LeftCharvenSVG />
                </button>
                <button
                  className="more-dates forward"
                  style={{
                    padding: "2px",
                    minWidth: "unset",
                    width: "auto",
                    height: "auto",
                    border: "none",
                    background: "transparent",
                    color: "#3d70be",
                    marginTop: 0,
                  }}
                  onClick={() => {
                    handleMoreDates("time");
                  }}
                >
                  <LeftCharvenSVG />
                </button>
              </div>
            </div>

            <div
              className={`th sortable w-25 hide-icon`}
              style={{
                paddingLeft: "2px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                alignSelf: "stretch",
                borderLeft: "1px solid #dfe3e6",
              }}
            >
              <Select
                placeholder="Time"
                value={timeOption.period || undefined}
                suffixIcon={<span />}
                style={{
                  maxWidth: "100%",
                  width: "100%",
                  background: "transparent",
                  borderBottom: 0,
                  boxShadow: "none",
                }}
                onChange={handleChangeSlots("period")}
              >
                <Option value="am">AM</Option>
                <Option value="pm">PM</Option>
              </Select>
            </div>

            <div
              className={`th sortable w-25 hide-icon`}
              style={{
                paddingLeft: "2px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                alignSelf: "stretch",
                borderLeft: "1px solid #dfe3e6",
              }}
            >
              <Select
                placeholder="Provider"
                value={timeOption.provider || undefined}
                suffixIcon={<span />}
                allowClear={false}
                style={{
                  maxWidth: "100%",
                  width: "100%",
                  background: "transparent",
                  borderBottom: 0,
                  boxShadow: "none",
                }}
                onChange={handleChangeSlots("provider")}
              >
                {(providers || []).map((provider, index) => (
                  <Option key={`provider-${provider?.id}-${index}`} value={provider?.id}>
                    {provider?.value}
                  </Option>
                ))}
              </Select>
            </div>
          </div>
          {(timeSlots || []).map((slot, index) => (
            <React.Fragment key={`time-slots-${index}`}>
              <div
                className={`resourceInfo between t-row ${
                  checkActive(slot) === "active" && firstClinic !== index && firstClinic == -1 ? "active" : ""
                } ${firstClinic === index ? "active" : ""}`}
                style={{ alignItems: "center" }}
                onClick={() => selectTimeSlot(slot, index)}
              >
                <div className="w-25">{getValue(locations, slot.location, "id")}</div>
                <div className="w-25">{slot.start && moment(slot.start).format("MM/DD/YYYY")}</div>
                <div
                  className="w-25 time-select"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    paddingLeft: 0,
                    marginTop: 0,
                  }}
                >
                  <Select
                    placeholder="Timer check all"
                    value={checkActive(slot) == "active" && appointmentCheck ? moment(appointmentCheck).format("hh:mm A") : slot?.start_slot}
                    suffixIcon={<ExpandSVG />}
                    allowClear={false}
                    style={{
                      maxWidth: "100%",
                      width: "100%",
                    }}
                    onChange={(value) => {
                      handleTimeSlot(value, index, slot);
                    }}
                  >
                    {(slot?.slots || []).map((time, index) => (
                      <Option key={`time-${time}-${index}`} value={time}>
                        {moment()
                          .hours(8)
                          .minutes(0)
                          .add(time * 15, "minutes")
                          .format("hh:mm A")}
                      </Option>
                    ))}
                  </Select>
                </div>
                <div className="w-25">{getValue(providers, slot.doctor, "id")}</div>
              </div>
              <hr className="small-block" />
            </React.Fragment>
          ))}
          {emptySlots.map((schedule, index) => (
            <React.Fragment key={`schedule-${schedule}-${index}`}>
              <div className={`resourceInfo between t-row`} />
              <hr className="small-block" />
            </React.Fragment>
          ))}
        </div>

        {activeCustom["first"] && (
          <div className="resourceInfo between mt-2 sortableHeader" style={{ background: "#f4f7fb" }}>
            <div
              className={`th sortable w-25 hide-icon`}
              style={{
                paddingLeft: "2px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                alignSelf: "stretch",
                borderLeft: "1px solid #dfe3e6",
              }}
            >
              <Select
                placeholder="Location"
                value={customAppointment["first"].location || undefined}
                suffixIcon={<span />}
                allowClear={false}
                style={{
                  maxWidth: "100%",
                  width: "100%",
                  background: "transparent",
                  borderBottom: 0,
                  boxShadow: "none",
                }}
                onChange={(value) => handleCustomChange("first", "location", value)}
              >
                {(locations || []).map((loc, index) => (
                  <Option key={`location-${loc?.id}-${index}`} value={loc?.id}>
                    {loc?.value}
                  </Option>
                ))}
              </Select>
            </div>

            <div
              className={`th sortable w-25 hide-icon`}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                alignSelf: "stretch",
                paddingLeft: 0,
                borderLeft: "1px solid #dfe3e6",
              }}
            >
              <DatePicker
                onChange={(value) => {
                  handleCustomChange("first", "date", moment(value).format("MM/DD/YYYY"));
                }}
                style={{
                  width: "100%",
                  background: "transparent",
                  boxShadow: "none",
                  borderBottom: 0,
                }}
                format={"MM/DD/YYYY"}
                placeholder="Date"
                suffixIcon={<ExpandSVG />}
              />
            </div>

            <div
              className={`th sortable w-25 hide-icon`}
              style={{
                paddingLeft: "2px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                alignSelf: "stretch",
                borderLeft: "1px solid #dfe3e6",
              }}
            >
              <TimePicker
                format={"h:mm A"}
                use12Hours
                placeholder="Time"
                style={{
                  maxWidth: "100%",
                  width: "100%",
                  background: "transparent",
                  boxShadow: "none",
                  borderBottom: 0,
                }}
                disabledHours={() => [0, 1, 2, 3, 4, 5, 20, 21, 22, 23]}
                minuteStep={15}
                onChange={(value) => handleCustomChange("first", "time", moment(value).format("h:mm A"))}
              />
            </div>

            <div
              className={`th sortable w-25 hide-icon`}
              style={{
                paddingLeft: "2px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                alignSelf: "stretch",
                borderLeft: "1px solid #dfe3e6",
              }}
            >
              <Select
                placeholder="Provider"
                value={customAppointment["first"].provider || undefined}
                suffixIcon={<span />}
                allowClear={false}
                style={{
                  maxWidth: "100%",
                  width: "100%",
                  background: "transparent",
                  borderBottom: 0,
                  boxShadow: "none",
                }}
                onChange={(value) => handleCustomChange("first", "provider", value)}
              >
                {(providers || []).map((provider, index) => (
                  <Option key={`provider-${provider?.id}-${index}`} value={provider?.id}>
                    {provider?.value}
                  </Option>
                ))}
              </Select>
            </div>
          </div>
        )}
        {!lock ? (
          <div className="actionContentNew" style={{ paddingRight: "10px", marginTop: 0, minHeight: "16px" }}>
            <button
              className={`bottom-btn`}
              onClick={() => {
                onHandleClick();
              }}
            >
              Save
            </button>
          </div>
        ) : null}
      </div>
    </>
  );
};

export default SecondStepComponent;
