import { Drawer } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ScrollWrapper from "../../../../common/components/ScrollBar";
import VesselBody from "../../../../common/components/VesselBody";
import { getLocal } from "src/helpers/Local";
import { HPIGetConditions, clinicProviderReviewLoadInit, setGlobalToastr } from "src/store/actions";

const PhysicalExaminationSidebar = React.forwardRef(
  ({ isShow, handleCloseSidebar, onChangeDataVes, checkedAllNormalsP, sidebarIcon, isLocked }, ref) => {
    const patientResource = useSelector((state) => state.clinicProvider.patientResource);
    const dispatch = useDispatch();
    const fullName = (patientResource?.first_name + " " + patientResource?.last_name).toUpperCase();
    const vesselStates = useSelector((state) => state.patientExamination.vessel_states);
    const leftRight = useSelector((state) => state.clinicProviderReview.leftRight);
    const conditions = useSelector((state) => state.clinicProviderHPI.conditions);
    const sideNavStatus = useSelector((state) => state.common.sideNavStatus);
    const [dots, setDots] = useState([]);
    const [first, setFirst] = useState(false);
    const [vessel, setVessel] = useState(null);
    const [newError, setNewError] = useState({
      left: false,
      right: false,
    });
    useEffect(() => {
      const procedure_id = getLocal("current_appointment_id");
      dispatch(HPIGetConditions(procedure_id));
      dispatch(clinicProviderReviewLoadInit(procedure_id));
    }, []);

    const checkConditionLeftRight = (dots_, select) => {
      if (leftRight && Object.keys(leftRight).length > 0) {
        let fixDot = [...dots_];
        const checkArr = ["Claudication", "Sudden Pain", "Rest Pain", "Wound/Gangrene", "Restenosis"];
        // check right
        let rightCheck = false;
        for (let index = 0; index < checkArr.length; index++) {
          const element = checkArr[index];
          if (leftRight["m_choice"]) {
            if (leftRight["m_choice"].indexOf(element) > -1) {
              rightCheck = true;
            }
          }
        }
        if (rightCheck) {
          let error = false;
          ["(R) DP", "(R) Popliteal", "(R) PT"].map((r) => {
            const indexCheckR = fixDot.findIndex((d) => d?.label == r);
            if (indexCheckR != -1 && fixDot[indexCheckR]["state"] == "2") {
              error = true;
            }
          });
          setNewError((prev) => {
            return {
              ...prev,
              right: true,
            };
          });
          if (error && select) {
            dispatch(
              setGlobalToastr({
                header: "Save Fail!",
                message: "Patients pulse examination is not concordant with Condition",
                type: "failed",
              })
            );
          }
        } else {
          setNewError((prev) => {
            return {
              ...prev,
              right: false,
            };
          });
        }
        // check left
        let leftCheck = false;
        for (let index = 0; index < checkArr.length; index++) {
          const element = checkArr[index];
          if (leftRight["n_choice"]) {
            if (leftRight["n_choice"].indexOf(element) > -1) {
              leftCheck = true;
            }
          }
        }
        if (leftCheck) {
          let error = false;
          ["(L) DP", "(L) Popliteal", "(L) PT"].map((r) => {
            const indexCheckL = fixDot.findIndex((d) => d?.label == r);
            if (indexCheckL != -1 && fixDot[indexCheckL]["state"] == "2") {
              error = true;
            }
          });
          setNewError((prev) => {
            return {
              ...prev,
              left: true,
            };
          });
          if (error && select) {
            dispatch(
              setGlobalToastr({
                header: "Save Fail!",
                message: "Patients pulse examination is not concordant with Condition",
                type: "failed",
              })
            );
          }
        } else {
          setNewError((prev) => {
            return {
              ...prev,
              left: false,
            };
          });
        }

        if (!select && !first && fixDot && fixDot.length > 0) {
          const dataSaveVessel = fixDot.reduce((acc, cur) => {
            return {
              ...acc,
              [cur?.field]: cur?.state,
            };
          }, {});
          setFirst(true);
          setVessel(dataSaveVessel);
          onChangeDataVes(dataSaveVessel);
        }
        return [...(fixDot || [])];
      }
      return dots_;
    };

    useEffect(() => {
      if (vesselStates) {
        let dots_ = [
          {
            left: 69,
            top: 18,
            rightDir: true,
            field: "Right Carotid",
            label: "(R) Carotid",
            state: vesselStates["Right Carotid"] || null,
          },
          {
            right: 69,
            top: 18,
            field: "Left Carotid",
            label: "(L) Carotid",
            state: vesselStates["Left Carotid"] || null,
          },
          {
            left: 69,
            top: 35,
            rightDir: true,
            field: "Right Radial",
            label: "(R) Radial",
            state: vesselStates["Right Radial"] || null,
          },
          {
            right: 69,
            top: 35,
            field: "Left Radial",
            label: "(L) Radial",
            state: vesselStates["Left Radial"] || null,
          },
          {
            left: 69,
            top: 46,
            rightDir: true,
            field: "Right Ulnar",
            label: "(R) Ulnar",
            state: vesselStates["Right Ulnar"] || null,
          },
          {
            right: 69,
            top: 46,
            field: "Left Ulnar",
            label: "(L) Ulnar",
            state: vesselStates["Left Ulnar"] || null,
          },
          {
            left: 69,
            top: 59,
            rightDir: true,
            field: "Right Femoral",
            label: "(R) Femoral",
            state: vesselStates["Right Femoral"] || null,
          },
          {
            right: 69,
            top: 59,
            field: "Left Femoral",
            label: "(L) Femoral",
            state: vesselStates["Left Femoral"] || null,
          },
          {
            left: 69,
            top: 69,
            rightDir: true,
            field: "Right Popliteal",
            label: "(R) Popliteal",
            state: vesselStates["Right Popliteal"] || null,
          },
          {
            right: 69,
            top: 69,
            field: "Left Popliteal",
            label: "(L) Popliteal",
            state: vesselStates["Left Popliteal"] || null,
          },
          {
            left: 69,
            top: 78,
            rightDir: true,
            field: "Right Dorsalis Pedis",
            label: "(R) DP",
            state: vesselStates["Right Dorsalis Pedis"] || null,
          },
          {
            right: 69,
            top: 78,
            field: "Left Dorsalis Pedis",
            label: "(L) DP",
            state: vesselStates["Left Dorsalis Pedis"] || null,
          },
          {
            left: 69,
            top: 86,
            rightDir: true,
            field: "Right Posterior Tibial",
            label: "(R) PT",
            state: vesselStates["Right Posterior Tibial"] || null,
          },
          {
            right: 69,
            top: 86,
            field: "Left Posterior Tibial",
            label: "(L) PT",
            state: vesselStates["Left Posterior Tibial"] || null,
          },
        ];
        dots_ = checkConditionLeftRight(dots_, false);
        setVessel(vesselStates);
        setDots(dots_);
      }
    }, [vesselStates, conditions, leftRight, first]);

    const onChangeState = (field, state) => {
      let dots_ = [...dots];
      for (const node of dots_) {
        if (node.field === field) {
          node.state = state;
        }
      }
      dots_ = checkConditionLeftRight(dots_, true);
      setDots(dots_);
      const vesselStates_ = { ...vessel };
      vesselStates_[field] = state;
      onChangeDataVes(vesselStates_);
    };

    return (
      <Drawer
        title={`PULSE EXAMINATION - ${fullName}`}
        placement="right"
        variable="permanent"
        className={sideNavStatus ? "wide" : ""}
        closable={false}
        mask={false}
        visible={isShow}
        destroyOnClose
      >
        <div ref={ref} className="resourceContainer new">
          <ScrollWrapper>
            <div
              className="resourceContent"
              style={{
                paddingLeft: "24px",
                paddingRight: "8px",
                height: "calc(100% - 55px)",
                display: "flex",
                justifyContent: "center",
                minHeight: "600px",
                position: "relative",
              }}
            >
              <VesselBody newError={newError} dots={dots} onChangeState={(field, state) => onChangeState(field, state)} isLocked={isLocked} />
            </div>
          </ScrollWrapper>
        </div>
      </Drawer>
    );
  }
);

export default PhysicalExaminationSidebar;
