import React, {useState, useEffect, useRef} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import AutoCompleteSearch from '../../../common/components/AutoComplete/AutoCompleteSearch';
import DataTable from '../../../common/components/DataTable/WhiteHeaderTable';
import {
  getDiseases,
  updateDetailUltrasounds,
  getDetailUltrasounds,
} from '../../../store/actions';
import ToastMessage from '../../../common/components/Toast';

let timer = null;
const IndicationCode = ({resource}) => {
  const tableRef = useRef(null);
  const [isToastr, setIsToastr] = useState(false);
  const detail_untrasound_list_type = useSelector(
      (state) => state.procedureDetail.detail_untrasound_list_type,
  );
  const [dataICD_ID, setDataICD_ID] = useState([]);
  const diseases = useSelector((state) => state.common.diseases);
  const loadingDiseases = useSelector((state) => state.common.loadingDiseases);
  const [localLoading, setLocalLoading] = useState(false);
  const [dataICD, setDataICD] = useState([]);
  const [search, setSearch] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    if (detail_untrasound_list_type && detail_untrasound_list_type.codes) {
      setDataICD(detail_untrasound_list_type.codes);
      setDataICD_ID(
          (detail_untrasound_list_type.codes || []).map((r) => r?.id),
      );
    } else {
      setDataICD([]);
      setDataICD_ID([]);
    }
  }, [detail_untrasound_list_type]);

  useEffect(() => {
    if (search) {
      clearTimeout(timer);
      setLocalLoading(true);
      timer = setTimeout(() => {
        setLocalLoading(false);
        dispatch(getDiseases(search));
      }, 500);
    }
  }, [search]);

  const updateICD = async (value) => {
    if (
      !detail_untrasound_list_type?.codes?.some((el) => {
        return el.icd_code === value.icd_code;
      })
    ) {
      if (detail_untrasound_list_type && detail_untrasound_list_type?.id) {
        const checkID = detail_untrasound_list_type.codes.map((r) => {
          return r?.id;
        });
        const params = {
          codes: [...checkID, value?.id],
        };
        await dispatch(
            updateDetailUltrasounds(detail_untrasound_list_type?.id, params),
        );
        await dispatch(getDetailUltrasounds(detail_untrasound_list_type?.id));
      }
    } else {
      setIsToastr(true);
      setTimeout(() => {
        setIsToastr(false);
      }, 2000);
    }
  };

  const removeProvider = async (index) => {
    if (detail_untrasound_list_type && detail_untrasound_list_type?.id) {
      dataICD_ID.splice(index, 1);
      const params = {
        codes: [...dataICD_ID],
      };
      await dispatch(
          updateDetailUltrasounds(detail_untrasound_list_type?.id, params),
      );
      await dispatch(getDetailUltrasounds(detail_untrasound_list_type?.id));
    }
  };

  if (resource === null || resource === undefined) {
    return null;
  }

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {isToastr && (
        <ToastMessage
          type="Indication Code Already Exists"
          status="failed"
          msg="Can Not Add Same Value!"
        />
      )}
      <div style={{width: '100%', minheight: '50px'}}>
        <AutoCompleteSearch
        isLargeHeight={true}
          title="Procedure-Location-Support"
          options={diseases}
          loading={localLoading || loadingDiseases}
          handlePopulate={(value) => updateICD(value)}
          handleSearch={(val) => setSearch(val)}
          placeholder="Search Indication Code"
          keyLabel="icd_code_name"
          actionLabel="Add"
        />
      </div>

      <div ref={tableRef} style={{flex: 1, width: '100%'}}>
        <DataTable
          title="indication_code"
          labels={['ICD', 'Description']}
          widths={['30%', '70%']}
          fullHeight={true}
          parentHeight={
            tableRef && tableRef.current && tableRef.current.clientHeight
          }
          columns={[
            {sortable: true, key: 'icd'},
            {sortable: true, key: 'desc'},
          ]}
          handleDelete={(value, index) => removeProvider(index)}
          rowData={(dataICD || []).map((item, index) => {
            return {
              icd: item.icd_code,
              desc: item.icd_name,
            };
          })}
          emptyRows={new Array(4).fill(1)}
          sorts={['', '']}
        />
      </div>
    </div>
  );
};

export default IndicationCode;
