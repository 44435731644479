import { Icon } from 'antd';
import React from 'react';
import {
  YellowAlertSVG
} from '../../common/components/icons/SVGIcon';
import Endovascular from './components/PCMComponents/Endovascular';
import NoneEndovascular from './components/PCMComponents/NoneEndovascular';
import Overview from './components/PCMComponents/Overview';
import ProviderReport from './components/PCMComponents/ProviderReport';
import Summary from './components/PCMComponents/Summary';

export const Children = {
  summary: Summary,
  overview: Overview,
  nonEndovascular: NoneEndovascular,
  providerReport: ProviderReport,
  endovasculars: Endovascular,
};

export const PCMHeaders = [
  {label: 'Endovascular', width: '88px'},
  {label: 'Non-Endovascular', width: '120px'},
  {label: 'Provider Report', width: '103px'},
];

export const DashboardRows = [
  {icon: <YellowAlertSVG />},
  {icon: <Icon type="check-circle" theme="twoTone" twoToneColor="#24A148" />},
];

export const StaticResource = {
  first_name: 'James',
  last_name: 'Fisher',
  date_of_birth: '06/09/1973',
  phone: '(989) 897-8797',
  secondary_phone: '(989) 897-8797',
  email: 'haqqani@hotmail.com',
  ssn: '890-09-8977',
  address: '1109 W St Andrews Rd',
  apt_number: '',
  suite: '',
  bldg: '',
  state: 'Michigan',
  city: 'Midland',
  zipcode: '48642',
  clinic_date: '07/04/2019',
  result: '',
  icon: <YellowAlertSVG />,
};
const RedSnap = ({info}) => {
  return (
    <div
      style={{
        color: 'rgb(224, 24, 45)',
        border: '2px solid rgb(224, 24, 45)',
        width: '1.5rem',
        height: '1.5rem',
        borderRadius: '0.875rem',
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '0.875rem',
        fontWeight: '600',
        lineHeight: '0.875rem',
      }}
    >
      {info}
    </div>
  );
};

const YellowSnap = ({info}) => {
  return (
    <div
      style={{
        color: 'rgb(239, 193, 0)',
        border: '2px solid rgb(239, 193, 0)',
        width: '1.5rem',
        height: '1.5rem',
        borderRadius: '0.875rem',
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '0.875rem',
        fontWeight: '600',
        lineHeight: '0.875rem',
      }}
    >
      {info}
    </div>
  );
};
export const SnapData = [
  {
    title: 'Carotid Ultrasound',
    actionDate: '02/05/2019',
    status: <RedSnap info={1} />,
    description: [
      'Right carotid ICA 50-79% (PSV 254 cm/sec)',
      'Left carotid ICA 50-79% (PSV 189 cm/sec)',
    ],
  },
  {
    title: 'Carotid Ultrasound',
    actionDate: '02/05/2019',
    status: <RedSnap info={2} />,
    description: [
      'Right carotid ICA 50-79% (PSV 254 cm/sec)',
      'Left carotid ICA 50-79% (PSV 189 cm/sec)',
    ],
  },
  {
    title: 'Carotid Ultrasound',
    actionDate: '02/05/2019',
    status: <RedSnap info={3} />,
    description: [
      'Right carotid ICA 50-79% (PSV 254 cm/sec)',
      'Left carotid ICA 50-79% (PSV 189 cm/sec)',
    ],
  },
  {
    title: 'Right Carotid Endarterectomy',
    actionDate: '02/05/2019',
    status: <YellowSnap info={4} />,
    description: [
      'Right Carotid Endarterectomy with bovine pericardial patch placement with Dr Omar Haqqani, MD at Bay McLaren Hospital',
    ],
  },
  {
    title: 'Carotid Ultrasound',
    actionDate: '02/05/2019',
    status: <RedSnap info={5} />,
    description: [
      'Right carotid ICA 50-79% (PSV 254 cm/sec)',
      'Left carotid ICA 50-79% (PSV 189 cm/sec)',
    ],
  },
];

export const ChartTableList = [
  {date: '02/03/2017', link: '#', title: 'New Patient Referral', athor: ''},
  {
    date: '06/12/2018',
    link: '#',
    title: 'Insurance Documentation',
    athor: '',
  },
  {
    date: '09/02/2018',
    link: '#',
    title: 'LLE Angiogram with Stenting',
    athor: 'Aized',
  },
  {date: '05/09/2019', link: '#', title: 'F/U Appointment', athor: 'Haqqani'},
  {date: '', link: '#', title: '', athor: ''},
  {date: '', link: '#', title: '', athor: ''},
  {date: '', link: '#', title: '', athor: ''},
  {date: '', link: '#', title: '', athor: ''},
];

export const ReviewTableList = [
  {label: 'Rx Atorvastatin 40 mg po qhs', value: 'E-Prescribed'},
  {label: 'Right Carotid Endarterectomy at Bay McLaren', value: 'Faxed'},
  {label: 'Clinic Followup 2/4/2020 11:00 AM', value: 'Scheduled'},
  {label: 'Referral to Rheumatology Henry Ford', value: 'Faxed'},
  {label: '', value: ''},
  {label: '', value: ''},
  {label: '', value: ''},
  {label: '', value: ''},
];
