import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import { getLocal, setLocal } from "../../../../helpers/Local";
import {
  choosePatientOnSchedules,
  clinicProviderReviewLoadInit,
  getClinicNursingLocations,
  getLock,
  getPatientSchedules,
  getProviders,
  get_patient_notes,
  patient_insurances_id_insurances,
  removeDataPatient,
  remove_patient_on_schedules,
  resetStateResource,
  setDataPatient,
  setErrorHPI,
  setPatientSnapshotData,
} from "../../../../store/actions";
import DayCalendar from "../DayCalendar";

const resources = [
  {
    id: "clinic",
    title: "Clinic",
  },
  {
    id: "us",
    title: "Ultrasound",
  },
];

class ClinicSchedule extends Component {
  sidebarRef = null;
  constructor(props) {
    super(props);
    this.sidebarRef = React.createRef();
  }

  state = {
    isSidebar: false,
    events: [],
    searchValue: "",
    schedulesState: [],
    filters: {
      date: moment(new Date()).format("YYYY-MM-DD"),
      location: "",
      provider_id: "",
    },
  };

  componentDidMount = () => {
    const { date, location } = this.state.filters;
    const localCheck = JSON.parse(getLocal("scheduleParams"));
    // update save local filter
    const scheduleFilters = {
      date: localCheck && localCheck?.date ? localCheck?.date || date : date,
      location: (localCheck && localCheck?.location) || "",
      provider_id: (localCheck && localCheck?.provider_id) || "",
    };
    this.props.getClinicNursingLocations();
    this.props.getProviders();
    this.handleEvents(scheduleFilters);
    this.loadInitialPaymentJs();
  };

  loadInitialPaymentJs = () => {
    const sqPaymentScript = document.createElement("script");
    sqPaymentScript.src = "https://js.squareup.com/v2/paymentform";
    sqPaymentScript.type = "text/javascript";
    sqPaymentScript.async = false;
    sqPaymentScript.onload = () => {};
    document.getElementsByTagName("head")[0].appendChild(sqPaymentScript);
  };

  handleSidebar = () => {
    this.setState({ isSidebar: true });
  };

  handleCloseSidebar = () => {
    this.setState({ isSidebar: false });
  };

  handleEvents = ({ date, location, provider_id }) => {
    this.setState({
      ...this.state,
      date,
      location,
      provider_id,
    });
    this.props.getPatientSchedules({
      date,
      location,
      provider_id,
      patient_id: "",
      only_clinics: 1,
      only_procedures: 0,
      only_us: 1,
      only_angio: 0,
    });
  };

  onSearch = (searchValue) => {
    this.setState({ searchValue });
    if (searchValue.length > 0) {
      const searchPatientName = (this.props.schedules || []).filter((item) => {
        return item.patientName.toLowerCase().includes(searchValue.toLowerCase());
      });
      this.setState({ schedulesState: searchPatientName });
    } else {
      this.setState({ schedulesState: this.props.schedules });
    }
  };

  onSelectResource = async (patient_id) => {
    const { goNextTab } = this.props;
    await this.props.remove_patient_on_schedules();
    await this.props.resetStateResource();
    setLocal("current_patient_id", patient_id);
    const result = await this.props.choosePatientOnSchedules(patient_id);
    if (result) {
      this.props.setDataPatient(result);
    }
    await this.props.removeDataPatient();
    await this.props.get_patient_notes(patient_id);
    await this.props.setPatientSnapshotData("resource", { ...result });
    await this.props.patient_insurances_id_insurances(patient_id);
    goNextTab();
  };

  onSelectAppointment = (appointment_id) => {
    setLocal("current_appointment_id", appointment_id);
    this.props.clinicProviderReviewLoadInit(appointment_id);
    this.props.getLock(appointment_id);
    this.props.setErrorHPI(false);
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.activeReview) {
      if (nextProps.goNextTabReview) {
        nextProps.goNextTabReview();
      }
    }
    if (nextProps.schedules) {
      this.setState({ schedulesState: nextProps.schedules });
    }
    if (nextProps?.stateStatusProps && nextProps?.schedules) {
      const indexCheck = (nextProps?.schedules || []).findIndex((r) => r?.id === nextProps?.stateStatusProps?.appointment_id);
      if (indexCheck !== -1) {
        const dataFix = [...nextProps?.schedules];
        dataFix.splice(indexCheck, 1, {
          ...dataFix[indexCheck],
          status: nextProps?.stateStatusProps?.status,
        });
        this.setState({ schedulesState: [...dataFix] });
      }
    }
  }

  render() {
    const { schedulesState } = this.state;

    return (
      <div style={{ display: this.props.display ? "flex" : "none" }} className="clinicWorkflow__main-section">
        <div className="eventsContainer has-sub-header">
          <div className="calendarContainer">
            <DayCalendar
              locations={this.props.locations || []}
              providers={this.props.providers || []}
              pusherCheckBuild={this.props.pusherCheckBuild}
              pusherCheckUnfinalized={this.props.pusherCheckUnfinalized}
              events={schedulesState || []}
              resources={resources}
              slotProviders={this.props.scheduleProviders || []}
              loading={this.props.loadingSchedules || this.props.loadingResource}
              fetchEvents={this.handleEvents}
              valueSearch={this.state.searchValue}
              onSearch={this.onSearch}
              onSelectResource={this.onSelectResource}
              calendarType="clinic-schedule"
              onSelectAppointment={this.onSelectAppointment}
              isClinicWorkFlow={this.props.isClinicWorkFlow}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  locations: state.common.clinicNursingLocations,
  providers: state.common.providers,
  schedules: state.clinicProvider.schedules,
  scheduleProviders: state.patientSchedule.providers,
  loadingSchedules: state.clinicProvider.loadingSchedules,
  loadingResource: state.clinicProvider.loadingResource,
  activeReview: state.common.activeReview,
});

const mapDispatchToProps = {
  getClinicNursingLocations,
  getProviders,
  getPatientSchedules,
  choosePatientOnSchedules,
  get_patient_notes,
  removeDataPatient,
  patient_insurances_id_insurances,
  clinicProviderReviewLoadInit,
  setPatientSnapshotData,
  setDataPatient,
  remove_patient_on_schedules,
  resetStateResource,
  getLock,
  setErrorHPI,
};

export default connect(mapStateToProps, mapDispatchToProps)(ClinicSchedule);
