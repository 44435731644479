import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AutoCompleteSearch from "../../../common/components/AutoComplete/AutoCompleteSearch";
import DataTable from "../../../common/components/DataTable/WhiteHeaderTable";
import ToastMessage from "../../../common/components/Toast";
import { edit_clinic_procedure_type, getLocations } from "../../../store/actions";

const LocationsAndSupport = ({ resource }) => {
  const tableRef = useRef(null);
  const [isToastr, setIsToastr] = useState(false);
  const detail_clinic_type = useSelector((state) => state.procedureDetail.detail_clinic_type);
  const providersSearch = useSelector((state) => state.common.locations);
  const [searchResult, setSearchResult] = useState([]);
  const [locations, setLocations] = useState([]);
  const [search, setSearch] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    if (detail_clinic_type && detail_clinic_type?.locations && detail_clinic_type?.locations.length > 0) {
      const dataArr = detail_clinic_type?.locations.map((r) => {
        return {
          firstCase: "",
          location: r?.id,
          name_location: r?.value,
        };
      });
      setLocations(dataArr);
    } else {
      setLocations([]);
    }
  }, [detail_clinic_type]);

  const updateProviders = async (type, index, value) => {
    if (type === "location") {
      if (!locations.some((r) => r.location === value?.id)) {
        setLocations([...locations, { name_location: value?.value, location: value?.id, firstCase: "" }]);
        callApiUpdate([...locations, { name_location: value?.value, location: value?.id, firstCase: "" }]);
      } else {
        setIsToastr(true);
        setTimeout(() => {
          setIsToastr(false);
        }, 2000);
      }
    } else {
      setLocations([...locations]);
      callApiUpdate(locations);
    }
  };

  const removeProvider = async (index) => {
    locations.splice(index, 1);
    setLocations([...locations]);
    callApiUpdate(locations);
  };

  useEffect(() => {
    dispatch(getLocations());
  }, []);

  const callApiUpdate = async (arrLocations) => {
    if (arrLocations && detail_clinic_type && Object.keys(detail_clinic_type).length > 0) {
      const renderArray = (arrLocations || []).map((r) => {
        return r.location;
      });
      await dispatch(
        edit_clinic_procedure_type(detail_clinic_type?.id, {
          name: detail_clinic_type?.name,
          locations: renderArray,
        })
      );
    }
  };

  useEffect(() => {
    const arrayFilter = [...providersSearch];
    const arraySucces = arrayFilter.filter((item) => {
      return item?.value.toLowerCase().includes(search.toLowerCase());
    });
    setSearchResult([...arraySucces]);
  }, [search]);

  const emptyRows = useMemo(() => {
    if (tableRef && tableRef.current) {
      const total = Math.floor(tableRef.current.clientHeight / 40);

      let count = locations && locations.length > 0 ? total - locations.length : total;
      if (count < 0) count = 0;
      return new Array(count).fill(1);
    }
    return [];
  }, [tableRef, locations]);

  if (resource === null || resource === undefined) {
    return null;
  }

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {isToastr && <ToastMessage type="Location Already Exists" status="failed" msg="Can Not Add Same Value!" />}
      <div style={{ width: "100%", minheight: "50px" }}>
        <AutoCompleteSearch
          isLargeHeight={true}
          title="Procedure-Location-Support"
          options={searchResult}
          handlePopulate={(value) => updateProviders("location", locations.length, value)}
          handleSearch={(val) => setSearch(val)}
          placeholder="Search Locations"
          keyLabel="value"
          actionLabel="Add"
        />
      </div>

      <div ref={tableRef} style={{ flex: 1, width: "100%" }}>
        <DataTable
          title="location_support"
          labels={["Location"]}
          widths={["100%"]}
          fullHeight={true}
          columns={[{ sortable: true, key: "location" }]}
          handleDelete={(value, index) => removeProvider(index)}
          rowData={(locations || []).map((item, index) => {
            return {
              location: item?.name_location,
            };
          })}
          emptyRows={emptyRows}
          sorts={["", ""]}
        />
      </div>
    </div>
  );
};

export default LocationsAndSupport;
