import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import CardBlock from "../../../common/components/Card";

import { Icon } from "antd";
import {
  addProcedureDetails,
  angio_condition_type_cpt,
  angio_condition_type_icd,
  edit_angio_type_variable,
  get_angio_type_variable,
  save_angio_type_variable,
  setProcedureDetails,
  sortConditionVariables,
} from "../../../store/actions";
import "../style.scss";
import AngioSearch from "./AngioSearch";
import CPTCodeLogicBlock from "./cptCodeLogic";
import UltrasoundBlock from "./FollowUpUltrasounds";
import ProviderBlock from "./ProcedureProviders";
import DocumentationBlock from "./TemplateDocumentation";
import VariableCaptureBlock from "./VariableCapture";

const AngioDetail = (props) => {
  const [activeCard, setActiveCard] = useState("");
  const [resource, setResource] = useState(null);
  const [isOffTemAndVari, setIsOffTemAndVari] = useState(false);
  const [isVariableSelected, setIsVariableSelected] = useState({
    index: null,
    selectedVariable: {
      id: null,
    },
  });
  const [isDisabled, setIsDisabled] = useState(false);
  const selectedReferralCondition = useSelector((state) => state.adminReferralCondition.selectedReferralCondition);
  const angioVariable = useSelector((state) => state.procedureDetail.angioVariable);
  const cptCodesAngio = useSelector((state) => state.procedureDetail.cptCodesAngio);
  const icdCodesAngio = useSelector((state) => state.procedureDetail.icdCodesAngio);
  const dispatch = useDispatch();
  const detail_angio_type = useSelector((state) => state.procedureDetail.detail_angio_type);
  const handleAddNewData = async (type, data) => {
    if (detail_angio_type && Object.keys(detail_angio_type).length > 0) {
      await dispatch(addProcedureDetails(type, data));
      await dispatch(
        save_angio_type_variable({
          question: "",
          angio_procedure_type_id: detail_angio_type?.id,
          answers: [],
        })
      );
      await dispatch(get_angio_type_variable(detail_angio_type?.id));
    }
  };

  useEffect(() => {
    if (
      detail_angio_type?.name &&
      (detail_angio_type?.name.includes("Peripheral Angiogram") ||
        detail_angio_type?.name.includes("Venogram") ||
        detail_angio_type?.name.includes("Venous Lysis"))
    ) {
      setIsOffTemAndVari(true);
    } else {
      setIsOffTemAndVari(false);
    }
  }, [detail_angio_type?.name]);

  const moveCard = async (type) => {
    const currentIndex = isVariableSelected?.index;
    if (type === "up") {
      const previousIndex = currentIndex - 1;
      const source = angioVariable[currentIndex]["order"];
      const destination = angioVariable[previousIndex]["order"];
      try {
        setIsDisabled(true);
        const result = await dispatch(sortConditionVariables(source, destination, resource));
        if (!result) {
          return;
        } else {
          setIsDisabled(false);
          setIsVariableSelected((previousVariable) => {
            return {
              index: previousIndex,
              selectedVariable: { ...previousVariable?.selectedVariable },
            };
          });
        }
      } catch (error) {
        return error;
      }
    }
    if (type === "down") {
      const nextIndex = currentIndex + 1;
      const source = angioVariable[currentIndex]["order"];
      const destination = angioVariable[nextIndex]["order"];
      try {
        setIsDisabled(true);
        const result = await dispatch(sortConditionVariables(source, destination, resource));
        if (!result) {
          return;
        } else {
          setIsDisabled(false);
          setIsVariableSelected((previousVariable) => {
            return {
              index: nextIndex,
              selectedVariable: { ...previousVariable?.selectedVariable },
            };
          });
        }
      } catch (error) {
        return error;
      }
    }
  };

  const updateCPT = async () => {
    const arrayADD = cptCodesAngio.map((r) => {
      return {
        cpt_code_id: r?.cpt_code_id,
        procedure_type_id: detail_angio_type?.id,
        cpt_code: r?.cpt_code,
        condition_serialize: r?.condition_serialize
          ? [...r?.condition_serialize]
          : [
              {
                cpt_code: { code: "", short_desc: "" },
                variable_id: undefined,
                answer: undefined,
                variable_name: undefined,
                comparison: ">=",
                operator: "Select",
                modifier: undefined,
              },
            ],
      };
    });
    const param = {
      cpt_code_id: 1,
      procedure_type_id: detail_angio_type?.id,
      cpt_code: { code: "", short_desc: "" },
      condition_serialize: [
        {
          cpt_code: { code: "", short_desc: "" },
          variable_id: undefined,
          variable_name: undefined,
          answer: undefined,
          comparison: ">=",
          operator: "Select",
          modifier: undefined,
        },
      ],
    };
    await dispatch(setProcedureDetails("cptCodes", arrayADD && Object.keys(arrayADD).length > 0 ? [...arrayADD, param] : [param]));
    await dispatch(
      angio_condition_type_cpt(detail_angio_type?.id, {
        rules: arrayADD && Object.keys(arrayADD).length > 0 ? [...arrayADD, param] : [param],
      })
    );
  };

  const update = async () => {
    if (detail_angio_type && Object.keys(detail_angio_type).length > 0) {
      const index = isVariableSelected?.index;
      const id = isVariableSelected?.selectedVariable?.id;
      if (angioVariable?.length > 0 && id) {
        angioVariable[index]["answer"] = [
          ...angioVariable[index]["answer"],
          {
            answer: "",
            text_template: "",
          },
        ];
        await dispatch(setProcedureDetails("angioVariable", [...angioVariable]));
        await dispatch(
          edit_angio_type_variable(id && id, {
            variable_name: angioVariable[index]["variable_name"],
            question: angioVariable[index]["quest"],
            procedure_type_id: detail_angio_type?.id,
            answers: angioVariable[index]["answer"],
          })
        );
        await dispatch(get_angio_type_variable(detail_angio_type?.id));
      }
    }
  };

  return (
    <div className="clinicWorkflow__main-section" style={{ padding: "0 0.5rem 0 1rem", height: "calc(100vh - 208px)" }}>
      <div className="fullContainer has-no-sidebar">
        <div
          className="mainContainer admin_clinical_logic"
          style={{
            marginLeft: "-10px",
            paddingTop: "0",
            paddingBottom: "0",
            display: "flex",
            flexWrap: "wrap",
            flexDirection: "row",
            alignItems: "stretch",
            width: "100%",
          }}
        >
          <CardBlock
            isOverFlow={true}
            isOverFlowHidden={true}
            title="Search OBL Procedures"
            onClick={() => {
              setActiveCard("obl_procedures");
            }}
            varient={activeCard == "" || activeCard == "obl_procedures" ? "half" : "quarter"}
            other=""
            contentPadding="1rem"
          >
            <AngioSearch setResource={setResource} />
          </CardBlock>

          <CardBlock
            isOverFlow={true}
            isOverFlowHidden={true}
            title="OBL Procedure Providers"
            onClick={() => {
              setActiveCard("obl_providers");
            }}
            varient={activeCard == "obl_providers" ? "half" : "quarter"}
            other=""
            contentPadding="1rem"
          >
            <ProviderBlock resource={resource} />
          </CardBlock>

          <CardBlock
            isOverFlow={true}
            isOverFlowHidden={true}
            title="Follow-Up Ultrasounds"
            onClick={() => {
              setActiveCard("follow_ultrasounds");
            }}
            varient={activeCard == "follow_ultrasounds" ? "half" : "quarter"}
            other=""
            contentPadding="1rem"
          >
            <UltrasoundBlock resource={resource} />
          </CardBlock>

          {!isOffTemAndVari && (
            <CardBlock
              title="Variable Capture"
              varient={"half"}
              other=""
              contentPadding="1rem"
              subAction={
                <div
                  style={{
                    display: "inline-flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                  }}
                >
                  <div
                    style={{
                      width: 40,
                      height: 16,
                      position: "absolute",
                      top: 2,
                      left: -35,
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                      gap: 8,
                    }}
                  >
                    {isVariableSelected?.index >= 1 && angioVariable?.length > 1 ? (
                      <Icon
                        style={{
                          fontSize: 16,
                          cursor: isDisabled ? "not-allowed" : "pointer",
                        }}
                        type="up-square"
                        theme="twoTone"
                        twoToneColor="#0f62fe"
                        onClick={() => !isDisabled && moveCard("up")}
                      />
                    ) : (
                      ""
                    )}
                    {(isVariableSelected?.index || isVariableSelected?.index === 0) &&
                    angioVariable?.length > 1 &&
                    isVariableSelected?.index !== angioVariable?.length - 1 ? (
                      <Icon
                        style={{
                          fontSize: 16,
                          cursor: isDisabled ? "not-allowed" : "pointer",
                        }}
                        type="down-square"
                        theme="twoTone"
                        twoToneColor="#0f62fe"
                        onClick={() => !isDisabled && moveCard("down")}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                  <button
                    disabled={selectedReferralCondition && Object.keys(selectedReferralCondition).length <= 0}
                    className="text-btn"
                    style={{ marginLeft: "1rem" }}
                    onClick={() => {
                      handleAddNewData("angioVariable", {
                        quest: "",
                        answer: [],
                      });
                    }}
                  >
                    Add Specification +
                  </button>
                  <button className="text-btn" style={{ marginLeft: "1rem" }} onClick={update}>
                    Add Answer +
                  </button>
                </div>
              }
            >
              <VariableCaptureBlock resource={resource} rowAction={setIsVariableSelected} />
            </CardBlock>
          )}
          <CardBlock
            isOverFlow={true}
            title="CPT Code Logic"
            varient={isOffTemAndVari ? "full" : "quarter"}
            other=""
            contentPadding="1rem"
            subAction={
              <div
                style={{
                  display: "inline-flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                <button
                  disabled={detail_angio_type && Object.keys(detail_angio_type).length <= 0}
                  className="text-btn"
                  style={{ marginLeft: "1rem" }}
                  onClick={updateCPT}
                >
                  Add +
                </button>
              </div>
            }
          >
            <CPTCodeLogicBlock isOffTemAndVari={isOffTemAndVari} resource={resource} />
          </CardBlock>
          {!isOffTemAndVari && (
            <CardBlock title="Template Documentation" varient="quarter" other="" contentPadding="1rem">
              <DocumentationBlock resource={resource} />
            </CardBlock>
          )}
        </div>
      </div>
    </div>
  );
};

export default AngioDetail;
