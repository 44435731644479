import moment from "moment";
import React from "react";
import ReplaceN from "../../../helpers/ReplaceN";

const TableOutput = (props) => {
  const { row, column, caption, cell, valueHeader, valueHeader2, valueFind, leftRight, hpi_conditions } = props;
  const rows = [];
  const theadRowGroup = [];
  let arrayLabel = [];

  let rowCaptionL = null;
  let rowCaptionR = null;
  let qlfortable2L = null;
  let qlfortable2R = null;

  for (const key in hpi_conditions || []) {
    if (hpi_conditions.hasOwnProperty(key)) {
      let row = hpi_conditions[key];
      for (let sub_row of row || []) {
        if (sub_row["plans"] && sub_row["plans"].length > 0) {
          if (key.includes("Peripheral Artery")) {
            qlfortable2L =
              sub_row["choices"] && sub_row["choices"][3] && sub_row["choices"][3]["comments"] ? ReplaceN(sub_row["choices"][3]["comments"]) : "";
            qlfortable2R =
              sub_row["choices"] && sub_row["choices"][0] && sub_row["choices"][0]["comments"] ? ReplaceN(sub_row["choices"][0]["comments"]) : "";
            if (sub_row["plans"].some((r) => r.includes("Left "))) {
              rowCaptionL = `Left Peripheral Angiogram (${moment(sub_row?.left_appointment_date).format("MM/DD/YYYY")})`;
            }
            if (sub_row["plans"].some((r) => r.includes("Right "))) {
              rowCaptionR = `Right Peripheral Angiogram (${moment(sub_row?.right_appointment_date).format("MM/DD/YYYY")})`;
            }
          }
        }
      }
    }
  }

  const LabelHeader = {
    1: `<b>Acute limb ischemia (Urgent)</b>
Authorization criteria: Endovascular intervention, peripheral artery For BCN HMO (commercial) and BCN Advantage members.  For Medicare Plus Blue PPO members`,
    2: `<b>LCD L35998</b> – Specific Arterial Indications for PTA and Stenting: PTA and stenting for critical limb ischemia is considered appropriate under the following conditions: Limb threatening lower extremity ischemia. {Critical limb definition - Critical limb ischemia (CLI) is categorized as ischemic rest pain, nonhealing ischemic ulceration, or gangrene.}`,
    3: `<b>LCD L36767</b> – Indications for stand-alone iliac or lower extremity angiography must be documented by pre-procedure clinical assessment. This assessment should include the following:`,
    4: `<b>Vein graft stenosis</b>
Authorization criteria: Endovascular intervention, peripheral artery For BCN HMO (commercial) and BCN Advantage members.  For Medicare Plus Blue PPO members`,
    5: `<b>LCD L35998</b> – Specific Arterial Indications for PTA and Stenting: PTA and stenting for critical limb ischemia is considered appropriate under the following conditions: Limb threatening lower extremity ischemia. {Critical limb definition - Critical limb ischemia (CLI) is categorized as ischemic rest pain, nonhealing ischemic ulceration, or gangrene.}`,
    6: `<b>LCD L36767</b> – Indications for stand-alone iliac or lower extremity angiography must be documented by pre-procedure clinical assessment. This assessment should include the following:`,
    7: `<b>In-stent or recurrent stenosis</b>
Either must be met Authorization criteria: Endovascular intervention, peripheral artery For BCN HMO (commercial) and BCN Advantage members.  For Medicare Plus Blue PPO members`,
    8: `<b>LCD L35998</b> – Specific Arterial Indications for PTA and Stenting: PTA and stenting for critical limb ischemia is considered appropriate under the following conditions: Limb threatening lower extremity ischemia. {Critical limb definition - Critical limb ischemia (CLI) is categorized as ischemic rest pain, nonhealing ischemic ulceration, or gangrene.}`,
    9: `<b>LCD L36767</b> – Indications for stand-alone iliac or lower extremity angiography must be documented by pre-procedure clinical assessment. This assessment should include the following:`,
    10: `<b>In-stent or recurrent stenosis</b>
Either must be met Authorization criteria: Endovascular intervention, peripheral artery For BCN HMO (commercial) and BCN Advantage members.  For Medicare Plus Blue PPO members`,
    11: `<b>LCD L35998</b> – Specific Arterial Indications for PTA and Stenting: PTA and stenting for critical limb ischemia is considered appropriate under the following conditions: Limb threatening lower extremity ischemia. {Critical limb definition - Critical limb ischemia (CLI) is categorized as ischemic rest pain, nonhealing ischemic ulceration, or gangrene.}`,
    12: `<b>LCD L36767</b> – Indications for stand-alone iliac or lower extremity angiography must be documented by pre-procedure clinical assessment. This assessment should include the following:`,
    13: `<b>Rest pain</b>
Authorization criteria: Endovascular intervention, peripheral artery For BCN HMO (commercial) and BCN Advantage members.  For Medicare Plus Blue PPO members `,
    14: `<b>LCD L35998</b> – Specific Arterial Indications for PTA and Stenting: PTA and stenting for critical limb ischemia is considered appropriate under the following conditions: Limb threatening lower extremity ischemia. {Critical limb definition - Critical limb ischemia (CLI) is categorized as ischemic rest pain, nonhealing ischemic ulceration, or gangrene.}`,
    15: `<b>LCD L36767</b> – Indications for stand-alone iliac or lower extremity angiography must be documented by pre-procedure clinical assessment. This assessment should include the following:`,
    16: `<b>Nonhealing ulcer or wound of the lower leg or foot</b>
Authorization criteria: Endovascular intervention, peripheral artery For BCN HMO (commercial) and BCN Advantage members.  For Medicare Plus Blue PPO members`,
    17: `<b>LCD L35998</b> – Specific Arterial Indications for PTA and Stenting: PTA and stenting for critical limb ischemia is considered appropriate under the following conditions: Limb threatening lower extremity ischemia. {Critical limb definition - Critical limb ischemia (CLI) is categorized as ischemic rest pain, nonhealing ischemic ulceration, or gangrene.}`,
    18: `<b>LCD L36767</b> – Indications for stand-alone iliac or lower extremity angiography must be documented by pre-procedure clinical assessment. This assessment should include the following:`,
    19: `<b>Claudication of the common iliac artery, external iliac artery, femoral artery or popliteal artery</b>
Authorization criteria: Endovascular intervention, peripheral artery For BCN HMO (commercial) and BCN Advantage members.  For Medicare Plus Blue PPO members`,
    20: `<b>LCD L35998</b> – PTA and stenting for claudication may be appropriate under the following conditions: Individuals who have failed medical management and home exercise program and continue to have significant activity limiting disease, with an anatomically suitable lesion for intervention. Medical management of peripheral artery disease (PAD) should include Class I recommendations for antiplatelet therapy, statins, home exercise program, smoking cessation including planning, counseling or behavior modification and pharmacotherapy if needed.`,
    21: `<b>LCD L36767</b> – Indications for stand-alone iliac or lower extremity angiography must be documented by pre-procedure clinical assessment. This assessment should include the following:`,
  };

  for (let i = 1; i <= 21; i += 1) {
    const fillGroup = valueFind.filter((r) => r?.finding_name && r?.finding_name.includes(`Table${i} `));
    theadRowGroup.push({
      ...fillGroup,
      label: LabelHeader[i] || null,
    });
  }
  if (row > 1) {
    for (let i = 1; i < row; i += 1) {
      const cols = [];
      for (let j = 0; j < column; j += 1) {
        cols.push(
          <td style={{ width: 100, textAlign: "left" }} key={`${i} ${j}`}>
            {cell && cell[i] && cell[i][j]}
          </td>
        );
      }
      rows.push(
        <tr style={{ textAlign: "left" }} key={i}>
          {cols}
        </tr>
      );
    }
  }

  const insideCol = (key, value) => {
    return (
      <td style={{ width: 100, textAlign: "center", paddingLeft: 10 }} key={key}>
        <b style={{ textAlign: "center" }}>{value}</b>
      </td>
    );
  };

  const renderRow = (value, index, type) => {
    const mapArrayRow = (value || []).map((r) => {
      return {
        label: r.finding_name.replace(`Table${index} `, ""),
        [`${valueHeader?.headers[0]}`]: r[`${valueHeader?.headers[0]}`],
        [`${valueHeader?.headers[1]}`]: r[`${valueHeader?.headers[1]}`],
        [`${valueHeader?.headers[2]}`]: r[`${valueHeader?.headers[2]}`],
        [`${valueHeader?.headers[3]}`]: r[`${valueHeader?.headers[3]}`],
        [`${valueHeader?.headers[4]}`]: r[`${valueHeader?.headers[4]}`],
        [`${valueHeader?.headers[5]}`]: r[`${valueHeader?.headers[5]}`],
        [`${valueHeader?.headers[6]}`]: r[`${valueHeader?.headers[6]}`],
        [`${valueHeader?.headers[7]}`]: r[`${valueHeader?.headers[7]}`],
      };
    });

    for (let i = 0; i < mapArrayRow.length; i += 1) {
      arrayLabel = {
        ...arrayLabel,
        [0]: cell[0],
        [i + 1]: [
          mapArrayRow[i]["label"],
          mapArrayRow[i][`${valueHeader?.headers[0]}`],
          mapArrayRow[i][`${valueHeader?.headers[1]}`],
          mapArrayRow[i][`${valueHeader?.headers[2]}`],
          mapArrayRow[i][`${valueHeader?.headers[3]}`],
          mapArrayRow[i][`${valueHeader?.headers[4]}`],
          mapArrayRow[i][`${valueHeader?.headers[5]}`],
          mapArrayRow[i][`${valueHeader?.headers[6]}`],
          mapArrayRow[i][`${valueHeader?.headers[7]}`],
        ],
      };
    }
    let minx = [];
    if (mapArrayRow.length + 1 > 1) {
      for (let i = 1; i < mapArrayRow.length + 1; i += 1) {
        const cols = [];
        for (let j = 0; j < column; j += 1) {
          if (j < 1) {
            cols.push(
              <td style={{ width: 100, textAlign: "left" }} key={`${i} ${j}`}>
                <span style={{ fontWeight: "500", fontSize: 14, textAlign: "left" }}>{arrayLabel && arrayLabel[i] && arrayLabel[i][j]}</span>
              </td>
            );
          } else {
            switch (true) {
              // qestion 1
              //table 2 check index 1
              case index === 2 && i === 1 && type === "Left":
                cols.push(insideCol(`${i} ${j}`, qlfortable2L));
                break;
              case index === 2 && i === 1 && type === "Right":
                cols.push(insideCol(`${i} ${j}`, qlfortable2R));
                break;
              // Tab1
              case index === 1 && i === 1 && type === "Left" && leftRight?.question_1 && leftRight?.question_1?.left[0]:
                cols.push(insideCol(`${i} ${j}`, "Yes"));
                break;
              case index === 1 && i === 1 && type === "Right" && leftRight?.question_1 && leftRight?.question_1?.right[0]:
                cols.push(insideCol(`${i} ${j}`, "Yes"));
                break;
              case index === 1 && i === 1 && type === "Left" && leftRight?.question_1 && !leftRight?.question_1?.left[0]:
                cols.push(insideCol(`${i} ${j}`, "No"));
                break;
              case index === 1 && i === 1 && type === "Right" && leftRight?.question_1 && !leftRight?.question_1?.right[0]:
                cols.push(insideCol(`${i} ${j}`, "No"));
                break;
              // Tab2
              case index === 1 && i === 2 && type === "Left" && leftRight?.question_1 && leftRight?.question_1?.left[1]:
                cols.push(insideCol(`${i} ${j}`, "Yes"));
                break;
              case index === 1 && i === 2 && type === "Right" && leftRight?.question_1 && leftRight?.question_1?.right[1]:
                cols.push(insideCol(`${i} ${j}`, "Yes"));
                break;
              case index === 1 && i === 2 && type === "Left" && leftRight?.question_1 && !leftRight?.question_1?.left[1]:
                cols.push(insideCol(`${i} ${j}`, "No"));
                break;
              case index === 1 && i === 2 && type === "Right" && leftRight?.question_1 && !leftRight?.question_1?.right[1]:
                cols.push(insideCol(`${i} ${j}`, "No"));
                break;
              // Tab3
              case index === 1 && i === 3 && type === "Left" && leftRight?.question_1 && leftRight?.question_1?.left[2]:
                cols.push(insideCol(`${i} ${j}`, "Yes"));
                break;
              case index === 1 && i === 3 && type === "Right" && leftRight?.question_1 && leftRight?.question_1?.right[2]:
                cols.push(insideCol(`${i} ${j}`, "Yes"));
                break;
              case index === 1 && i === 3 && type === "Left" && leftRight?.question_1 && !leftRight?.question_1?.left[2]:
                cols.push(insideCol(`${i} ${j}`, "No"));
                break;
              case index === 1 && i === 3 && type === "Right" && leftRight?.question_1 && !leftRight?.question_1?.right[2]:
                cols.push(insideCol(`${i} ${j}`, "No"));
                break;
              // qestion 2
              case index === 4 && type === "Left" && leftRight?.question_2 && leftRight?.question_2?.left[0]:
                cols.push(insideCol(`${i} ${j}`, "Yes"));
                break;
              case index === 4 && type === "Right" && leftRight?.question_2 && leftRight?.question_2?.right[0]:
                cols.push(insideCol(`${i} ${j}`, "Yes"));
                break;
              case index === 4 && type === "Left" && leftRight?.question_2 && !leftRight?.question_2?.left[0]:
                cols.push(insideCol(`${i} ${j}`, "No"));
                break;
              case index === 4 && type === "Right" && leftRight?.question_2 && !leftRight?.question_2?.right[0]:
                cols.push(insideCol(`${i} ${j}`, "No"));
                break;
              // qestion 3
              // Tab1
              case index === 7 && i === 1 && type === "Left" && leftRight?.question_3 && leftRight?.question_3?.left[0]:
                cols.push(insideCol(`${i} ${j}`, "Yes"));
                break;
              case index === 7 && i === 1 && type === "Right" && leftRight?.question_3 && leftRight?.question_3?.right[0]:
                cols.push(insideCol(`${i} ${j}`, "Yes"));
                break;
              case index === 7 && i === 1 && type === "Left" && leftRight?.question_3 && !leftRight?.question_3?.left[0]:
                cols.push(insideCol(`${i} ${j}`, "No"));
                break;
              case index === 7 && i === 1 && type === "Right" && leftRight?.question_3 && !leftRight?.question_3?.right[0]:
                cols.push(insideCol(`${i} ${j}`, "No"));
                break;
              // Tab2
              case index === 7 && i === 2 && type === "Left" && leftRight?.question_3 && leftRight?.question_3?.left[1]:
                cols.push(insideCol(`${i} ${j}`, "Yes"));
                break;
              case index === 7 && i === 2 && type === "Right" && leftRight?.question_3 && leftRight?.question_3?.right[1]:
                cols.push(insideCol(`${i} ${j}`, "Yes"));
                break;
              case index === 7 && i === 2 && type === "Left" && leftRight?.question_3 && !leftRight?.question_3?.left[1]:
                cols.push(insideCol(`${i} ${j}`, "No"));
                break;
              case index === 7 && i === 2 && type === "Right" && leftRight?.question_3 && !leftRight?.question_3?.right[1]:
                cols.push(insideCol(`${i} ${j}`, "No"));
                break;
              // Tab3
              case index === 7 && i === 3 && type === "Left" && leftRight?.question_3 && leftRight?.question_3?.left[2]:
                cols.push(insideCol(`${i} ${j}`, "Yes"));
                break;
              case index === 7 && i === 3 && type === "Right" && leftRight?.question_3 && leftRight?.question_3?.right[2]:
                cols.push(insideCol(`${i} ${j}`, "Yes"));
                break;
              case index === 7 && i === 3 && type === "Left" && leftRight?.question_3 && !leftRight?.question_3?.left[2]:
                cols.push(insideCol(`${i} ${j}`, "No"));
                break;
              case index === 7 && i === 3 && type === "Right" && leftRight?.question_3 && !leftRight?.question_3?.right[2]:
                cols.push(insideCol(`${i} ${j}`, "No"));
                break;
              // qestion 4
              // Tab1
              case index === 10 && i === 1 && type === "Left" && leftRight?.question_4 && leftRight?.question_4?.left[0]:
                cols.push(insideCol(`${i} ${j}`, "Yes"));
                break;
              case index === 10 && i === 1 && type === "Right" && leftRight?.question_4 && leftRight?.question_4?.right[0]:
                cols.push(insideCol(`${i} ${j}`, "Yes"));
                break;
              case index === 10 && i === 1 && type === "Left" && leftRight?.question_4 && !leftRight?.question_4?.left[0]:
                cols.push(insideCol(`${i} ${j}`, "No"));
                break;
              case index === 10 && i === 1 && type === "Right" && leftRight?.question_4 && !leftRight?.question_4?.right[0]:
                cols.push(insideCol(`${i} ${j}`, "No"));
                break;
              // Tab2
              case index === 10 && i === 2 && type === "Left" && leftRight?.question_4 && leftRight?.question_4?.left[1]:
                cols.push(insideCol(`${i} ${j}`, "Yes"));
                break;
              case index === 10 && i === 2 && type === "Right" && leftRight?.question_4 && leftRight?.question_4?.right[1]:
                cols.push(insideCol(`${i} ${j}`, "Yes"));
                break;
              case index === 10 && i === 2 && type === "Left" && leftRight?.question_4 && !leftRight?.question_4?.left[1]:
                cols.push(insideCol(`${i} ${j}`, "No"));
                break;
              case index === 10 && i === 2 && type === "Right" && leftRight?.question_4 && !leftRight?.question_4?.right[1]:
                cols.push(insideCol(`${i} ${j}`, "No"));
                break;
              // Tab3
              case index === 10 && i === 3 && type === "Left" && leftRight?.question_4 && leftRight?.question_4?.left[2]:
                cols.push(insideCol(`${i} ${j}`, "Yes"));
                break;
              case index === 10 && i === 3 && type === "Right" && leftRight?.question_4 && leftRight?.question_4?.right[2]:
                cols.push(insideCol(`${i} ${j}`, "Yes"));
                break;
              case index === 10 && i === 3 && type === "Left" && leftRight?.question_4 && !leftRight?.question_4?.left[2]:
                cols.push(insideCol(`${i} ${j}`, "No"));
                break;
              case index === 10 && i === 3 && type === "Right" && leftRight?.question_4 && !leftRight?.question_4?.right[2]:
                cols.push(insideCol(`${i} ${j}`, "No"));
                break;
              // qestion 56w
              // Tab1
              case index === 16 &&
                leftRight?.n_choice &&
                i === 1 &&
                type === "Left" &&
                leftRight?.question_5_6_w &&
                leftRight?.question_5_6_w?.left[0]:
                cols.push(insideCol(`${i} ${j}`, "Yes"));
                break;
              case index === 16 &&
                leftRight?.m_choice &&
                i === 1 &&
                type === "Right" &&
                leftRight?.question_5_6_w &&
                leftRight?.question_5_6_w?.right[0]:
                cols.push(insideCol(`${i} ${j}`, "Yes"));
                break;
              case index === 16 &&
                leftRight?.n_choice &&
                i === 1 &&
                type === "Left" &&
                leftRight?.question_5_6_w &&
                !leftRight?.question_5_6_w?.left[0]:
                cols.push(insideCol(`${i} ${j}`, "No"));
                break;
              case index === 16 &&
                leftRight?.m_choice &&
                i === 1 &&
                type === "Right" &&
                leftRight?.question_5_6_w &&
                !leftRight?.question_5_6_w?.right[0]:
                cols.push(insideCol(`${i} ${j}`, "No"));
                break;
              // Tab2
              case index === 16 &&
                leftRight?.n_choice &&
                i === 2 &&
                type === "Left" &&
                leftRight?.question_5_6_w &&
                leftRight?.question_5_6_w?.left[1]:
                cols.push(insideCol(`${i} ${j}`, "Yes"));
                break;
              case index === 16 &&
                leftRight?.m_choice &&
                i === 2 &&
                type === "Right" &&
                leftRight?.question_5_6_w &&
                leftRight?.question_5_6_w?.right[1]:
                cols.push(insideCol(`${i} ${j}`, "Yes"));
                break;
              case index === 16 &&
                leftRight?.n_choice &&
                i === 2 &&
                type === "Left" &&
                leftRight?.question_5_6_w &&
                !leftRight?.question_5_6_w?.left[1]:
                cols.push(insideCol(`${i} ${j}`, "No"));
                break;
              case index === 16 &&
                leftRight?.m_choice &&
                i === 2 &&
                type === "Right" &&
                leftRight?.question_5_6_w &&
                !leftRight?.question_5_6_w?.right[1]:
                cols.push(insideCol(`${i} ${j}`, "No"));
                break;
              // Tab3
              case index === 16 &&
                leftRight?.n_choice &&
                i === 3 &&
                type === "Left" &&
                leftRight?.question_5_6_w &&
                leftRight?.question_5_6_w?.left[2]:
                cols.push(insideCol(`${i} ${j}`, "Yes"));
                break;
              case index === 16 &&
                leftRight?.m_choice &&
                i === 3 &&
                type === "Right" &&
                leftRight?.question_5_6_w &&
                leftRight?.question_5_6_w?.right[2]:
                cols.push(insideCol(`${i} ${j}`, "Yes"));
                break;
              case index === 16 &&
                leftRight?.n_choice &&
                i === 3 &&
                type === "Left" &&
                leftRight?.question_5_6_w &&
                !leftRight?.question_5_6_w?.left[2]:
                cols.push(insideCol(`${i} ${j}`, "No"));
                break;
              case index === 16 &&
                leftRight?.m_choice &&
                i === 3 &&
                type === "Right" &&
                leftRight?.question_5_6_w &&
                !leftRight?.question_5_6_w?.right[2]:
                cols.push(insideCol(`${i} ${j}`, "No"));
                break;
              // qestion 56r
              // Tab1
              case index === 13 && i === 1 && type === "Left" && leftRight?.question_5_6_r && leftRight?.question_5_6_r?.left[0]:
                cols.push(insideCol(`${i} ${j}`, "Yes"));
                break;
              case index === 13 && i === 1 && type === "Right" && leftRight?.question_5_6_r && leftRight?.question_5_6_r?.right[0]:
                cols.push(insideCol(`${i} ${j}`, "Yes"));
                break;
              case index === 13 && i === 1 && type === "Left" && leftRight?.question_5_6_r && !leftRight?.question_5_6_r?.left[0]:
                cols.push(insideCol(`${i} ${j}`, "No"));
                break;
              case index === 13 && i === 1 && type === "Right" && leftRight?.question_5_6_r && !leftRight?.question_5_6_r?.right[0]:
                cols.push(insideCol(`${i} ${j}`, "No"));
                break;
              // Tab2
              case index === 13 && i === 2 && type === "Left" && leftRight?.question_5_6_r && leftRight?.question_5_6_r?.left[1]:
                cols.push(insideCol(`${i} ${j}`, "Yes"));
                break;
              case index === 13 && i === 2 && type === "Right" && leftRight?.question_5_6_r && leftRight?.question_5_6_r?.right[1]:
                cols.push(insideCol(`${i} ${j}`, "Yes"));
                break;
              case index === 13 && i === 2 && type === "Left" && leftRight?.question_5_6_r && !leftRight?.question_5_6_r?.left[1]:
                cols.push(insideCol(`${i} ${j}`, "No"));
                break;
              case index === 13 && i === 2 && type === "Right" && leftRight?.question_5_6_r && !leftRight?.question_5_6_r?.right[1]:
                cols.push(insideCol(`${i} ${j}`, "No"));
                break;
              // Tab3
              case index === 13 && i === 3 && type === "Left" && leftRight?.question_5_6_r && leftRight?.question_5_6_r?.left[2]:
                cols.push(insideCol(`${i} ${j}`, "Yes"));
                break;
              case index === 13 && i === 3 && type === "Right" && leftRight?.question_5_6_r && leftRight?.question_5_6_r?.right[2]:
                cols.push(insideCol(`${i} ${j}`, "Yes"));
                break;
              case index === 13 && i === 3 && type === "Left" && leftRight?.question_5_6_r && !leftRight?.question_5_6_r?.left[2]:
                cols.push(insideCol(`${i} ${j}`, "No"));
                break;
              case index === 13 && i === 3 && type === "Right" && leftRight?.question_5_6_r && !leftRight?.question_5_6_r?.right[2]:
                cols.push(insideCol(`${i} ${j}`, "No"));
                break;
              //
              default:
                cols.push(
                  <td style={{ width: 100, textAlign: "center", paddingLeft: 10 }} key={`${i} ${j}`}>
                    <b>{arrayLabel && arrayLabel[i] && arrayLabel[i][j]}</b>
                  </td>
                );
                break;
            }
          }
        }
        minx = [...minx, <tr key={i}>{cols}</tr>];
      }
      return minx;
    } else {
      return null;
    }
  };

  const renderCol = (value, index) => {
    let minx = [];
    for (let i = 0; i < 2; i += 1) {
      minx = [
        ...minx,
        <th key={i} style={{ width: "50%", textAlign: "left", paddingLeft: 10 }}>
          {cell[0][i]}
        </th>,
      ];
    }
    return minx || null;
  };

  {
    /* // table 1,2,3 on qs 1
    ...question1,
    // table 4,5,6 on qs 2
    ...question2,
    // table 7,8,9 on qs 3
    ...question3,
    // table 10,11,12 on qs 4
    ...question4,
    // table 13,14,15 on qs question5_6_res
    ...question5_6_res,
    // table 16,17,18 on qs 5_6_wound
    ...question5_6_wound,
    // table 19,20,21 on qs question7_8_9
    ...question7_8_9, */
  }
  const checkID = {
    qs1: [1, 2, 3],
    qs2: [4, 5, 6],
    qs3: [7, 8, 9],
    qs4: [10, 11, 12],
    qs56r: [13, 14, 15],
    qs56w: [16, 17, 18],
    qs789: [19, 20, 21],
  };

  const idHeaderTable = [1, 4, 7, 10, 13, 16, 19];

  const checkHeaderRender = (value) => {
    const [head10, ...itemHead10] = value && value.split(`<b>`);
    const checkHead10 = (itemHead10 || []).map((item) => {
      return item.split(`</b>`)[0];
    });
    return (
      <>
        <span style={{ textAlign: "left" }}>
          <b style={{ textAlign: "left" }}>{checkHead10 && checkHead10[0]}</b>
          <i style={{ textAlign: "left" }}>{value.replace(`<b>${checkHead10[0]}</b>`, "")}</i>
        </span>
      </>
    );
  };

  const renderCaption = (label) => {
    let perfix_left = `Peripheral Angiogram`;
    if (leftRight?.left && leftRight?.left[6] && leftRight?.left[7] && leftRight?.left[8]) {
      perfix_left = `Left ` + perfix_left;
    } else if (leftRight?.left && leftRight?.left[0]) {
      perfix_left = `Left ` + perfix_left;
    } else if (leftRight?.left && leftRight?.left[1]) {
      perfix_left = `Left ` + perfix_left;
    } else if (leftRight?.left && leftRight?.left[2]) {
      perfix_left = `Left ` + perfix_left;
    } else if (leftRight?.left && leftRight?.left[3]) {
      perfix_left = `Left ` + perfix_left;
    } else if (
      leftRight?.left &&
      leftRight?.left[4] &&
      leftRight?.left[5] &&
      leftRight?.n_choice &&
      leftRight?.n_choice.indexOf("Wound/Gangrene") > -1
    ) {
      perfix_left = `Left ` + perfix_left;
    } else if (leftRight?.left && leftRight?.left[4] && leftRight?.left[5] && leftRight?.n_choice && leftRight?.n_choice.indexOf("Rest Pain") > -1) {
      perfix_left = `Left ` + perfix_left;
    }
    let prefix_right = `Peripheral Angiogram`;
    if (leftRight?.right && leftRight?.right[6] && leftRight?.right[7] && leftRight?.right[8]) {
      prefix_right = `Right ` + prefix_right;
    } else if (leftRight?.right && leftRight?.right[0]) {
      prefix_right = `Right ` + prefix_right;
    } else if (leftRight?.right && leftRight?.right[1]) {
      prefix_right = `Right ` + prefix_right;
    } else if (leftRight?.right && leftRight?.right[2]) {
      prefix_right = `Right ` + prefix_right;
    } else if (leftRight?.right && leftRight?.right[3]) {
      prefix_right = `Right ` + prefix_right;
    } else if (
      leftRight?.right &&
      leftRight?.right[4] &&
      leftRight?.right[5] &&
      leftRight?.m_choice &&
      leftRight?.m_choice.indexOf("Wound/Gangrene") > -1
    ) {
      prefix_right = `Right ` + prefix_right;
    } else if (
      leftRight?.right &&
      leftRight?.right[4] &&
      leftRight?.right[5] &&
      leftRight?.m_choice &&
      leftRight?.m_choice.indexOf("Rest Pain") > -1
    ) {
      prefix_right = `Right ` + prefix_right;
    }

    return (
      <caption style={{ fontWeight: 500, color: "black", textAlign: "left" }}>
        {label === "Left" ? rowCaptionL || perfix_left : label === "Right" ? rowCaptionR || prefix_right : null}
      </caption>
    );
  };

  const intoComponentOnly = (value, index, type) => {
    return (
      <table className="hover-table" key={`${type}-only-${index + 1}`}>
        {idHeaderTable.some((r) => r === index + 1) ? renderCaption(type) : renderCaption("")}
        <thead key={`thead ${index}`}>
          <tr>
            <td style={{ textAlign: "left", paddingLeft: 5 }} colSpan="2">
              {checkHeaderRender(value?.label)}
            </td>
          </tr>
          <tr style={{ textAlign: "left" }}>
            {renderCol(
              valueFind.filter((r) => r?.finding_name && r?.finding_name.includes(`Table${index + 1} `)),
              index + 1
            )}
          </tr>
        </thead>
        <tbody style={{ textAlign: "left" }}>
          {renderRow(
            valueFind.filter((r) => r?.finding_name && r?.finding_name.includes(`Table${index + 1} `)),
            index + 1,
            type
          )}
        </tbody>
      </table>
    );
  };

  const intoComponentTwoLeft = (value, index) => {
    return (
      <table className="hover-table" key={`Left${index + 1}`}>
        {idHeaderTable.some((r) => r === index + 1) ? renderCaption("Left") : renderCaption("")}
        <thead key={`thead ${index}`}>
          <td style={{ textAlign: "left", paddingLeft: 5 }} colSpan="2">
            {checkHeaderRender(value?.label)}
          </td>
          <tr style={{ textAlign: "left" }}>
            {renderCol(
              valueFind.filter((r) => r?.finding_name && r?.finding_name.includes(`Table${index + 1} `)),
              index + 1
            )}
          </tr>
        </thead>
        <tbody style={{ textAlign: "left" }}>
          {renderRow(
            valueFind.filter((r) => r?.finding_name && r?.finding_name.includes(`Table${index + 1} `)),
            index + 1,
            "Left"
          )}
        </tbody>
      </table>
    );
  };

  const intoComponentTwoRight = (value, index) => {
    return (
      <table className="hover-table" key={`Right${index + 1}`}>
        {idHeaderTable.some((r) => r === index + 1) ? renderCaption("Right") : renderCaption("")}
        <thead key={`thead ${index}`}>
          <td style={{ textAlign: "left", paddingLeft: 5 }} colSpan="2">
            {checkHeaderRender(value?.label)}
          </td>
          <tr style={{ textAlign: "left" }}>
            {renderCol(
              valueFind.filter((r) => r?.finding_name && r?.finding_name.includes(`Table${index + 1} `)),
              index + 1
            )}
          </tr>
        </thead>
        <tbody style={{ textAlign: "left" }}>
          {renderRow(
            valueFind.filter((r) => r?.finding_name && r?.finding_name.includes(`Table${index + 1} `)),
            index + 1,
            "Right"
          )}
        </tbody>
      </table>
    );
  };

  const reRender = () => {
    const renderCheck = [];
    const renderCheck2 = [];

    if (leftRight?.left && leftRight?.left[6] && leftRight?.left[7] && leftRight?.left[8]) {
      renderCheck.push(intoComponentOnly(theadRowGroup[18], 18, "Left"));
      renderCheck.push(intoComponentOnly(theadRowGroup[19], 19, "Left"));
      renderCheck.push(intoComponentOnly(theadRowGroup[20], 20, "Left"));
    }

    if (leftRight?.left && leftRight?.left[0]) {
      renderCheck.push(intoComponentOnly(theadRowGroup[0], 0, "Left"));
      renderCheck.push(intoComponentOnly(theadRowGroup[1], 1, "Left"));
      renderCheck.push(intoComponentOnly(theadRowGroup[2], 2, "Left"));
    }

    if (leftRight?.left && leftRight?.left[1]) {
      renderCheck.push(intoComponentOnly(theadRowGroup[3], 3, "Left"));
      renderCheck.push(intoComponentOnly(theadRowGroup[4], 4, "Left"));
      renderCheck.push(intoComponentOnly(theadRowGroup[5], 5, "Left"));
    }

    if (leftRight?.left && leftRight?.left[2]) {
      renderCheck.push(intoComponentOnly(theadRowGroup[6], 6, "Left"));
      renderCheck.push(intoComponentOnly(theadRowGroup[7], 7, "Left"));
      renderCheck.push(intoComponentOnly(theadRowGroup[8], 8, "Left"));
    }

    if (leftRight?.left && leftRight?.left[3]) {
      renderCheck.push(intoComponentOnly(theadRowGroup[9], 9, "Left"));
      renderCheck.push(intoComponentOnly(theadRowGroup[10], 10, "Left"));
      renderCheck.push(intoComponentOnly(theadRowGroup[11], 11, "Left"));
    }

    if (leftRight?.left && leftRight?.left[4] && leftRight?.left[5] && leftRight?.n_choice && leftRight?.n_choice.indexOf("Wound/Gangrene") > -1) {
      renderCheck.push(intoComponentOnly(theadRowGroup[15], 15, "Left"));
      renderCheck.push(intoComponentOnly(theadRowGroup[16], 16, "Left"));
      renderCheck.push(intoComponentOnly(theadRowGroup[17], 17, "Left"));
    }

    if (leftRight?.left && leftRight?.left[4] && leftRight?.left[5] && leftRight?.n_choice && leftRight?.n_choice.indexOf("Rest Pain") > -1) {
      renderCheck.push(intoComponentOnly(theadRowGroup[12], 12, "Left"));
      renderCheck.push(intoComponentOnly(theadRowGroup[13], 13, "Left"));
      renderCheck.push(intoComponentOnly(theadRowGroup[14], 14, "Left"));
    }

    // riight
    if (leftRight?.right && leftRight?.right[6] && leftRight?.right[7] && leftRight?.right[8]) {
      renderCheck.push(intoComponentOnly(theadRowGroup[18], 18, "Right"));
      renderCheck.push(intoComponentOnly(theadRowGroup[19], 19, "Right"));
      renderCheck.push(intoComponentOnly(theadRowGroup[20], 20, "Right"));
    }

    if (leftRight?.right && leftRight?.right[0]) {
      renderCheck.push(intoComponentOnly(theadRowGroup[0], 0, "Right"));
      renderCheck.push(intoComponentOnly(theadRowGroup[1], 1, "Right"));
      renderCheck.push(intoComponentOnly(theadRowGroup[2], 2, "Right"));
    }

    if (leftRight?.right && leftRight?.right[1]) {
      renderCheck.push(intoComponentOnly(theadRowGroup[3], 3, "Right"));
      renderCheck.push(intoComponentOnly(theadRowGroup[4], 4, "Right"));
      renderCheck.push(intoComponentOnly(theadRowGroup[5], 5, "Right"));
    }

    if (leftRight?.right && leftRight?.right[2]) {
      renderCheck.push(intoComponentOnly(theadRowGroup[6], 6, "Right"));
      renderCheck.push(intoComponentOnly(theadRowGroup[7], 7, "Right"));
      renderCheck.push(intoComponentOnly(theadRowGroup[8], 8, "Right"));
    }

    if (leftRight?.right && leftRight?.right[3]) {
      renderCheck.push(intoComponentOnly(theadRowGroup[9], 9, "Right"));
      renderCheck.push(intoComponentOnly(theadRowGroup[10], 10, "Right"));
      renderCheck.push(intoComponentOnly(theadRowGroup[11], 11, "Right"));
    }

    if (leftRight?.right && leftRight?.right[4] && leftRight?.right[5] && leftRight?.m_choice && leftRight?.m_choice.indexOf("Wound/Gangrene") > -1) {
      renderCheck.push(intoComponentOnly(theadRowGroup[15], 15, "Right"));
      renderCheck.push(intoComponentOnly(theadRowGroup[16], 16, "Right"));
      renderCheck.push(intoComponentOnly(theadRowGroup[17], 17, "Right"));
    }

    if (leftRight?.right && leftRight?.right[4] && leftRight?.right[5] && leftRight?.m_choice && leftRight?.m_choice.indexOf("Rest Pain") > -1) {
      renderCheck.push(intoComponentOnly(theadRowGroup[12], 12, "Right"));
      renderCheck.push(intoComponentOnly(theadRowGroup[13], 13, "Right"));
      renderCheck.push(intoComponentOnly(theadRowGroup[14], 14, "Right"));
    }

    return [...renderCheck, ...renderCheck2];
  };

  return <div>{reRender()}</div>;
};

const TableBlock = (props) => {
  const { contentState, block, blockProps, valueHeader, valueHeader2, valueFind, leftRight, hpi_conditions } = props;
  const entity = contentState.getEntity(block.getEntityAt(0));
  const shape = entity.getData();

  return (
    <TableOutput
      valueHeader2={valueHeader2}
      valueHeader={valueHeader}
      valueFind={valueFind}
      row={shape.row}
      column={shape.column}
      caption={shape.caption}
      hpi_conditions={hpi_conditions}
      cell={shape.cell}
      block={block}
      blockProps={blockProps}
      leftRight={leftRight}
    />
  );
};

export default TableBlock;
