import React from "react";
import {
  AtherectomyDirectionalIcon,
  AtherectomyLaserIcon,
  AtherectomyRotationalIcon,
  AtherectomyTransluminalIcon,
  BalloonIcon,
  IVUSIcon,
  UltrasoundProbeIcon,
  BloodVesselPlaque0,
  BloodVesselPlaque100,
  BloodVesselPlaque20,
  BloodVesselPlaque40,
  BloodVesselPlaque60,
  BloodVesselPlaque80,
  BloodVesselPlaqueStent0,
  BloodVesselPlaqueStent100,
  BloodVesselPlaqueStent20,
  BloodVesselPlaqueStent40,
  BloodVesselPlaqueStent60,
  BloodVesselPlaqueStent80,
  StentBalloonCoveredIcon,
  StentBalloonIcon,
  StentCoveredIcon,
  StentIcon,
} from "../components/icons/OperationIcon";
import { DevicesSVG, DugsSVG } from "../components/icons/SVGIcon";

export const Medicals = {
  "stenosis-0": {
    label: "Stenosis 0%",
    value: "0%",
    type: "status",
    state: "stenosis",
    fields: ["postStenosis"],
    icon: <BloodVesselPlaque0 />,
  },
  "stenosis-20": {
    label: "Stenosis 20%",
    value: "20%",
    type: "status",
    state: "stenosis",
    fields: ["postStenosis"],
    icon: <BloodVesselPlaque20 />,
  },
  "stenosis-40": {
    label: "Stenosis 40%",
    value: "40%",
    type: "status",
    state: "stenosis",
    fields: ["postStenosis"],
    icon: <BloodVesselPlaque40 />,
  },
  "stenosis-60": {
    label: "Stenosis 60%",
    value: "60%",
    type: "status",
    state: "stenosis",
    fields: ["postStenosis"],
    icon: <BloodVesselPlaque60 />,
  },
  "stenosis-80": {
    label: "Stenosis 80%",
    value: "80%",
    type: "status",
    state: "stenosis",
    fields: ["postStenosis"],
    icon: <BloodVesselPlaque80 />,
  },
  "stenosis-100": {
    label: "Stenosis 100%",
    value: "100%",
    type: "status",
    state: "stenosis",
    fields: ["postStenosis"],
    icon: <BloodVesselPlaque100 />,
  },
  "in-stent-0": {
    label: "In-Stent 0%",
    value: "0%",
    type: "status",
    state: "in-stent",
    fields: ["postStenosis"],
    icon: <BloodVesselPlaqueStent0 />,
  },
  "in-stent-20": {
    label: "In-Stent 20%",
    value: "20%",
    type: "status",
    state: "in-stent",
    fields: ["postStenosis"],
    icon: <BloodVesselPlaqueStent20 />,
  },
  "in-stent-40": {
    label: "In-Stent 40%",
    value: "40%",
    type: "status",
    state: "in-stent",
    fields: ["postStenosis"],
    icon: <BloodVesselPlaqueStent40 />,
  },
  "in-stent-60": {
    label: "In-Stent 60%",
    value: "60%",
    type: "status",
    state: "in-stent",
    fields: ["postStenosis"],
    icon: <BloodVesselPlaqueStent60 />,
  },
  "in-stent-80": {
    label: "In-Stent 80%",
    value: "80%",
    type: "status",
    state: "in-stent",
    fields: ["postStenosis"],
    icon: <BloodVesselPlaqueStent80 />,
  },
  "in-stent-100": {
    label: "In-Stent 100%",
    value: "100%",
    type: "status",
    state: "in-stent",
    fields: ["postStenosis"],
    icon: <BloodVesselPlaqueStent100 />,
  },
  ivus: {
    label: "IVUS",
    value: "IVUS",
    icon: <IVUSIcon />,
    fields: [
      "stenosis",
      "diameter",
      "proximal",
      "distal",
      "remodeling",
      "plaque",
    ],
  },
  ultrasound: {
    label: "Ultrasound",
    value: "Ultrasound",
    type: "sub-status",
    icon: <UltrasoundProbeIcon />,
  },
  access: {
    label: "Access",
    value: "Access",
    type: "sub-status",
    icon: <DevicesSVG />,
  },
  "closure-device": {
    label: "Closure Device",
    value: "Closure Device",
    type: "sub-status",
    icon: (
      <img
        src="/images/closure-featured.png"
        alt="closure device"
        style={{
          width: "32px",
          height: "32px",
          marginLeft: 0,
          marginBottom: "5px",
        }}
      />
    ),
  },
  "local-anesthesia": {
    label: "Local Anesthesia",
    value: "Local Anesthesia",
    type: "sub-status",
    icon: <DugsSVG />,
  },
  "directional-atherectomy": {
    label: "Directional Atherectomy",
    value: "Directional",
    icon: <AtherectomyDirectionalIcon />,
    fields: ["numberOfPasses", "postStenosis"],
  },
  "laser-atherectomy": {
    label: "Laser Atherectomy",
    value: "Laser",
    icon: <AtherectomyLaserIcon />,
    fields: ["numberOfPasses", "pulsesDelivered", "frequency", "postStenosis"],
  },
  "rotational-atherectomy": {
    label: "Rotational Atherectomy",
    value: "Rotational",
    icon: <AtherectomyRotationalIcon />,
    fields: ["numberOfPasses", "postStenosis"],
  },
  "obital-atherectomy": {
    label: "Obital Atherectomy",
    value: "Obital",
    icon: <AtherectomyTransluminalIcon />,
    fields: ["numberOfPasses", "postStenosis"],
  },
  "balloon-angioplasty": {
    label: "Balloon Angioplasty",
    value: "Balloon",
    icon: <BalloonIcon />,
    fields: ["atmInflation", "inflationTime", "postStenosis"],
  },
  "balloon-expandable-covered-stent": {
    label: "Balloon Expandable Covered Stent",
    value: "Stent",
    icon: <StentBalloonCoveredIcon />,
    fields: ["atmInflation", "inflationTime", "postStenosis"],
  },
  "covered-stent": {
    label: "Covered Stent",
    value: "Covered",
    icon: <StentCoveredIcon />,
    fields: ["postStenosis"],
  },
  "balloon-expandable-stent": {
    label: "Balloon Expandable Stent",
    value: "Stemt",
    icon: <StentBalloonIcon />,
    fields: ["atmInflation", "inflationTime", "postStenosis"],
  },
  "self-expanding-stent": {
    label: "Self Expanding Stent",
    value: "Self",
    icon: <StentIcon />,
    fields: ["postStenosis"],
  },
};

export const getMedical = (medical) => {
  return Medicals[medical];
};
