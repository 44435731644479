import { Drawer, Icon } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import EditableFormInput from "../../../../common/components/EditableText/EditableFormInput";
import ScrollWrapper from "../../../../common/components/ScrollBar";
import SidebarTitle from "../../../../common/components/SidebarTitle";
import { getLocal } from "../../../../helpers/Local";
import { save_appointment_notes, save_chief_complaint } from "../../../../store/actions";
import ExaminationVitals from "./ExaminationVitals";

let timer = null;
const ROSSidebar = React.forwardRef(({ onChangeData, isShow, handleCloseSidebar, isLocked, sidebarIcon }, ref) => {
  const patientResource = useSelector((state) => state.clinicProvider.patientResource);
  const fullName = (patientResource?.first_name + " " + patientResource?.last_name).toUpperCase();
  const procedure_id = getLocal("current_appointment_id");
  const vitals = useSelector((state) => state.patientExamination.vitals);
  const sideNavStatus = useSelector((state) => state.common.sideNavStatus);

  const [chiefComplaint, setChiefComplaint] = useState("");
  const [comment, setComment] = useState("");
  const chiefComplaintAPI = useSelector((state) => state.patientExamination.chiefComplaint);
  const commentAPI = useSelector((state) => state.clinicProviderReview.comment);

  useEffect(() => {
    setChiefComplaint(chiefComplaintAPI);
  }, [chiefComplaintAPI]);

  useEffect(() => {
    setComment(commentAPI);
  }, [commentAPI]);

  const dispatch = useDispatch();

  const onchangeChief = (value) => {
    setChiefComplaint(value);
    clearTimeout(timer);
    timer = setTimeout(() => {
      dispatch(save_chief_complaint(procedure_id, { chief_complaint: value }));
    }, 500);
  };

  const onchangeComment = (value) => {
    setComment(value);
    clearTimeout(timer);
    timer = setTimeout(() => {
      dispatch(save_appointment_notes(procedure_id, { comment: value }));
    }, 500);
  };

  return (
    <Drawer
      title={`CHIEF COMPLAINT- ${fullName}`}
      placement="right"
      variable="permanent"
      className={sideNavStatus ? "wide" : ""}
      closable={false}
      mask={false}
      visible={isShow}
      destroyOnClose
    >
      <div ref={ref} className="resourceContainer new">
        <ScrollWrapper>
          <SidebarTitle
            icon={<Icon type="interaction" theme="twoTone" twoToneColor="#740937" />}
            title={<span className="text-uppercase">Vital Signs</span>}
            subTitle=""
          />

          <div className="resourceContent" style={{ paddingLeft: "24px", paddingRight: "8px" }}>
            <ExaminationVitals data={vitals} onChangeData={(data) => onChangeData("vitals", data)} isLocked={isLocked} />
          </div>
        </ScrollWrapper>
      </div>
    </Drawer>
  );
});

export default ROSSidebar;
