import React, {useState} from 'react';
import moment from 'moment';
import {Icon} from 'antd';

import {TabHeaders} from '../constant';

import TopBar from '../../../common/components/SubTopBar';
import DataTable from '../../../common/components/DataTable/DataTable';
import ScrollWrapper from '../../../common/components/ScrollBar';

const AllTickets = () => {
  const [activeTab] = useState(0);
  const [data] = useState([
    {
      updated_at: new Date(),
      subject: 'Loading not working',
      link: 'Loading not working',
    },
    {
      updated_at: new Date(),
      subject: 'Dashboard',
      link: 'Dashboard',
    },
    {
      updated_at: new Date(),
      subject: 'I\'m having problems with searching',
      link: 'I\'m having problems with searching',
    },
  ]);

  return (
    <div className="clinicWorkflow__main-page">
      <TopBar title="Quick Register" subStatus={''} />
      <div className="clinicWorkflow__main-container">
        <div className="clinicWorkflow__main-subTitle tabContainer">
          <div className="tabHeaders" style={{height: '29px'}}>
            <ScrollWrapper css="no-padding tab-header-scroll">
              <div style={{display: 'flex'}}>
                {TabHeaders.map((tab, index) => (
                  <div
                    key={`tabHeaders-${tab.label}-${index}`}
                    className={`tabHeaders-item ${
                      activeTab === index ? 'active' : ''
                    }`}
                    style={{minWidth: tab.width}}
                  >
                    {tab.label}
                  </div>
                ))}
              </div>
            </ScrollWrapper>
          </div>
        </div>
        <div className="clinicWorkflow__main-section">
          <div className="eventsContainer">
            <div className="fullContainer pt-2">
              <div className="tableSection">
                <div className="tableContainer">
                  <DataTable
                    title={'support_tickets'}
                    labels={['Date Uploaded', 'Subject', 'Link']}
                    widths={['35%', '25%', '40%']}
                    columns={[
                      {sortable: false, key: 'date'},
                      {sortable: false, key: 'subject'},
                      {sortable: false, key: 'link'},
                    ]}
                    disableDelete={true}
                    defaultActive={-1}
                    rowData={(data || []).map((row) => {
                      return {
                        date: (
                          <div
                            className="iconField td with-icon"
                            style={{
                              display: 'flex',
                              paddingLeft: '0.25rem',
                              justifyContent: 'flex-start',
                              alignItems: 'center',
                            }}
                          >
                            <Icon
                              type="warning"
                              theme="twoTone"
                              twoToneColor="#FF832B"
                            />
                            <p style={{paddingLeft: '0.75rem'}}>
                              {moment(row?.updated_at).format(
                                  'MM/DD/YYYY HH:mm',
                              )}
                            </p>
                          </div>
                        ),
                        subject: row?.subject,
                        link: row?.link,
                      };
                    })}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllTickets;
