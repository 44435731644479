import { Drawer, Icon, TimePicker } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import CptReview from "../../../../common/components/CptReview/index";
import { default as EditableFormInput, default as EditableInput } from "../../../../common/components/EditableText/EditableFormInput";
import ScrollWrapper from "../../../../common/components/ScrollBar";
import { default as ExtendDataTable, default as SubDataTable } from "../../../../common/components/SubDataTable/ExtendDataTable";
import { getLocal } from "../../../../helpers/Local";
import {
  getOnlyProviders,
  getPatientSchedules,
  get_cpt_code_review,
  get_plan_patient,
  search_cpt_code,
  setGlobalLoading,
  setGlobalToastr,
  set_cpt_code_review,
  unlock_finalize,
} from "../../../../store/actions";
const titleStyle = {
  textAlign: "left",
  paddingLeft: "11px",
  color: "rgba(0, 0, 0, 0.65)",
  fontWeight: 600,
  borderLeft: "1px solid #dfe3e6",
  display: "flex",
  height: "100%",
  alignItems: "center",
  fontSize: "14px",
};

const ReviewSideBar = React.forwardRef(
  ({ isShow, valueText, onChangeCPT, sidebarIcon, handleChangeTime, handleChangeProvider, status_abort }, ref) => {
    const patientResource = useSelector((state) => state.clinicProvider?.patientResource);
    const fullName = (patientResource?.first_name + " " + patientResource?.last_name).toUpperCase();
    const usersCheck = useSelector((state) => state.common.currentUserInfo);
    const [dataSearch, setDataSearch] = useState([]);
    const [statusAbort, setStatusAbort] = useState("");
    const [cptData, setCptData] = useState([]);
    const [isResult, setIsResult] = useState(false);
    const [searchInput, setInput] = useState("");
    const [loading, setLoading] = useState(false);
    const current_patient_id = getLocal("current_patient_id");
    const current_appointment_id = getLocal("current_appointment_id");
    const dispatch = useDispatch();
    const procedure_id = getLocal("current_appointment_id");
    const oblScheduleParams = JSON.parse(getLocal("oblScheduleParams"));
    const CPT = useSelector((state) => state.common.CPT);
    const only_providers = useSelector((state) => state.common.only_providers);
    const cpt_codes = useSelector((state) => state.patientExamination.cpt_codes);
    const icd_codes = useSelector((state) => state.patientExamination.icd_codes);
    const searchData = useSelector((state) => state.common.searchCPT);
    const sideNavStatus = useSelector((state) => state.common.sideNavStatus);
    const patient_plan = useSelector((state) => state.procedure.get_plan_patient);
    const showBack = useSelector((state) => state.common?.showBack);
    const rvu_arr = useSelector((state) => state.patientExamination.rvu_arr);
    const [dataPlan, setDataPlan] = useState([]);
    const [medicalDecision, setMeicalDecision] = useState("");
    const [isLock, setIsLock] = useState(0);
    const [loadingButton, setLoadingButton] = useState(true);
    const [icdData, setIcdData] = useState([]);
    const [tableRows, setTableRows] = useState([]);
    const [localProviderId, setLocalProviderId] = useState(0);
    const [localFinalizedDate, setLocalFinalizedDate] = useState(moment().format("MM/DD/YYYY"));

    let timer = null;
    const authId = getLocal("cvai-current-user");

    const history = new useHistory();

    useEffect(() => {
      setStatusAbort(status_abort);
    }, [status_abort]);

    useEffect(() => {
      if (!only_providers) {
        dispatch(getOnlyProviders());
      }
    }, []);

    useEffect(() => {
      if (CPT.is_locked && CPT.is_locked === 1) {
        setIsLock(CPT.is_locked);
        setLoadingButton(false);
      } else {
        setLoadingButton(false);
      }

      if (CPT.finalized_date) {
        setLocalFinalizedDate(CPT?.finalized_date);
      }

      if (CPT.provider_id) {
        setLocalProviderId(CPT?.provider_id);
      }
    }, [CPT]);

    useEffect(() => {
      if (!rvu_arr) return;

      setTableRows(rvu_arr);
    }, [rvu_arr]);

    useEffect(() => {
      if (cpt_codes && cpt_codes.length > 0) {
        const checkedArray = cpt_codes.map((r) => {
          return {
            cpt_code: r.code,
            short_desc: r.short_desc,
            pivot: r.pivot,
          };
        });
        onChangeCPT([...checkedArray]);
        setCptData(checkedArray);
      }
      if (icd_codes && icd_codes.length > 0) {
        const checkedArray = icd_codes.map((r) => {
          return {
            icd_code: r.icd_code,
            icd_name: r.icd_name,
          };
        });
        setIcdData(checkedArray);
      }
    }, [CPT, cpt_codes, icd_codes]);

    const medical_decision_making = useSelector((state) => state.clinicProviderReview.medical_decision_making);

    const orderTitle = () => {
      return (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <div
            style={{
              ...titleStyle,
              minWidth: "50%",
              width: "50%",
              paddingLeft: 15,
              borderLeft: 0,
            }}
          >
            Review
          </div>
          <div style={{ ...titleStyle, minWidth: "25%", width: "25%" }}>Result</div>
        </div>
      );
    };

    useEffect(() => {
      setMeicalDecision(medical_decision_making);
    }, [medical_decision_making]);

    useEffect(() => {
      dispatch(get_plan_patient(current_patient_id, current_appointment_id));
    }, []);
    const removeRow = (index) => {
      let newRows = [];
      newRows = [...cptData];
      newRows.splice(index, 1);
      setCptData([...newRows]);
      onChangeCPT([...newRows]);
    };

    const selectItem = (itemcheck, item, type) => {
      const newRows = cptData;
      let checkExist = false;
      (cptData || []).some((r) => {
        if (r.cpt_code === item) {
          checkExist = true;
        }
      });
      if (!checkExist) {
        newRows.push({ cpt_code: item, short_desc: type });
        setCptData(newRows);
        onChangeCPT([...newRows]);
        setIsResult(false);
        setInput("");
      } else {
        setIsResult(false);
        setInput("");
      }
    };

    useEffect(() => {
      if (patient_plan && patient_plan.length > 0) {
        let checkArray = [];
        patient_plan.map((item) => {
          (item?.plan || []).map((r) => {
            if (r.bundle === "ultrasound_test") {
              if (r.side && r.side.length > 0 && r.side !== "Not applicable") {
                checkArray = [
                  ...checkArray,
                  {
                    value: r?.side + " " + r?.ultrasound?.ultrasound_type?.name,
                    processed: r.processed,
                  },
                ];
              } else {
                checkArray = [
                  ...checkArray,
                  {
                    value: r?.ultrasound?.ultrasound_type?.name,
                    processed: r.processed,
                  },
                ];
              }
            } else if (r.bundle === "procedure") {
              if (r.side && r.side.length > 0 && r.side !== "Not applicable") {
                checkArray = [
                  ...checkArray,
                  {
                    value: r?.side + " " + r?.procedure?.procedure_type?.value,
                    processed: r.processed,
                  },
                ];
              } else {
                checkArray = [
                  ...checkArray,
                  {
                    value: r?.procedure?.procedure_type?.value,
                    processed: r.processed,
                  },
                ];
              }
            } else if (r.bundle === "general" || r.bundle === "referral" || r.bundle === "ct") {
              checkArray = [
                ...checkArray,
                {
                  value: r?.procedure_extra?.procedure_type?.name,
                  processed: r.processed,
                },
              ];
            } else if (r.bundle === "angio") {
              if (r.side && r.side.length > 0 && r.side !== "Not applicable") {
                checkArray = [
                  ...checkArray,
                  {
                    value: r?.side + " " + r?.angio?.angio_type?.name,
                    processed: r.processed,
                  },
                ];
              } else {
                checkArray = [
                  ...checkArray,
                  {
                    value: r?.angio?.angio_type?.name,
                    processed: r.processed,
                  },
                ];
              }
            } else if (r.bundle === "clinic") {
              checkArray = [
                ...checkArray,
                {
                  value: r?.procedure?.clinic_type?.name,
                  processed: r.processed,
                },
              ];
            } else if (r.bundle === "prescription") {
              checkArray = [
                ...checkArray,
                {
                  value: r?.prescription?.medication?.full_medication + " " + r?.prescription?.frequency,
                  processed: r.processed,
                },
              ];
            }
          });
        });
        setDataPlan(checkArray);
      }
    }, [patient_plan]);

    const handleSearch = async (search) => {
      await setInput(search);
      clearTimeout(timer);
      setLoading(true);
      timer = setTimeout(async () => {
        await dispatch(search_cpt_code(search));
        setLoading(false);
      }, 1000);
    };

    const saveAll = async () => {
      if (isLock == 0 && icdData && icdData.length == 0) {
        await dispatch(
          setGlobalToastr({
            header: "Finalize Fail!",
            message: "You need to enable ICD for at least one condition",
            type: "failed",
          })
        );
      } else {
        setGlobalLoading(true);
        setLoading(true);
        const param = {
          text_template: valueText,
          cpt_codes: cptData?.map((r) => {
            return r.cpt_code;
          }),
          medical_decision_making: medicalDecision,
          finalized_date: localFinalizedDate,
          provider_id: localProviderId,
        };

        if (isLock == 1) {
          await dispatch(unlock_finalize(procedure_id));
          setIsLock(0);
        } else {
          await dispatch(set_cpt_code_review(procedure_id, param));
          setIsLock(1);
          await dispatch(
            setGlobalToastr({
              message: "The procedure is finalized",
            })
          );
        }
        await dispatch(get_cpt_code_review(procedure_id));
        await dispatch(
          getPatientSchedules({
            date: oblScheduleParams?.date || moment().format("MM/DD/YYYY"),
            location: oblScheduleParams?.location || "",
            provider_id: oblScheduleParams?.provider_id || "",
            patient_id: "",
            only_clinics: 0,
            only_procedures: 1,
            only_us: 0,
            only_angio: 1,
          })
        );

        setLoading(false);
        setGlobalLoading(false);
      }
    };

    useEffect(() => {
      if (searchData?.length > 0) {
        const checkedArray = searchData?.map((item) => {
          return {
            id: item?.id,
            cpt_code: item.code,
            short_desc: item.short_desc,
            created_at: item.created_at,
            updated_at: item.updated_at,
            fee: item.fee,
            cpt_code_name: item.cpt_code_name,
          };
        });
        setDataSearch(checkedArray);
      }
    }, [searchData]);

    useEffect(() => {
      if (isShow) {
        dispatch(setGlobalLoading(loading));
      }
    }, [loading, isShow]);

    const getTitle = () => {
      return (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <div
            style={{
              ...titleStyle,
              minWidth: "25%",
              width: "25%",
              borderLeft: 0,
              paddingLeft: 0,
            }}
          >
            ICD Code
          </div>
          <div style={{ ...titleStyle, minWidth: "75%", width: "75%" }}>Diagnosis</div>
        </div>
      );
    };

    const getTitleRvu = () => {
      return (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <div
            style={{
              ...titleStyle,
              minWidth: "33%",
              width: "33%",
              borderLeft: 0,
              paddingLeft: 0,
            }}
          >
            wRVU
          </div>
          <div style={{ ...titleStyle, minWidth: "33%", width: "33%" }}>Conversion Factor</div>
          <div style={{ ...titleStyle, minWidth: "33%", width: "33%" }}>Unit</div>
        </div>
      );
    };

    const handleGoBackReview = (e) => {
      e.stopPropagation();
      e.isPropagationStopped();
      history.push("/administrator/records-review");
    };

    const handleDate = (value) => {
      if (value) {
        setLocalFinalizedDate(value);
        handleChangeTime(value);
      } else {
        setLocalFinalizedDate(moment().format("MM/DD/YYYY"));
        handleChangeTime(moment().format("MM/DD/YYYY hh:mm:ss A"));
      }
    };

    const handleTime = (value) => {
      let temp = moment(localFinalizedDate).hours(value.hours()).minutes(value.minutes()).seconds(value.seconds()).format("MM/DD/YYYY hh:mm:ss A");
      setLocalFinalizedDate(temp);
      handleChangeTime(temp);
    };

    return (
      <Drawer
        title={`Summary Dashboard - ${fullName}`}
        placement="right"
        className={sideNavStatus ? "wide" : ""}
        closable={false}
        mask={false}
        visible={isShow}
        destroyOnClose
      >
        <div ref={ref} className="resourceContainer new">
          <ScrollWrapper>
            <div
              className="resourceContent"
              style={{
                flex: "unset",
                paddingLeft: "24px",
                paddingRight: "8px",
                paddingBottom: "1rem",
              }}
            >
              <div
                className="resourceInfo"
                style={{
                  marginTop: "1rem",
                }}
              >
                <SubDataTable
                  isBorderThick={true}
                  isBorderLeftNone={true}
                  isFixed
                  title={orderTitle()}
                  data={(dataPlan || []).map((item) => ({
                    label: (
                      <p
                        style={{
                          display: "flex",
                          alignItems: "center",
                          paddingRight: "8px",
                        }}
                      >
                        <span className="oneLineText">{item?.value}</span>
                      </p>
                    ),
                    value: (
                      <div
                        style={{
                          display: "flex",
                        }}
                      >
                        <div style={{ marginLeft: 5 }}>
                          {item.processed === 1 ? (
                            <span
                              className="normal-icon"
                              style={{
                                width: "1rem",
                                minWidth: "1rem",
                                height: "18px",
                              }}
                            >
                              <Icon type="check-circle" theme="twoTone" twoToneColor="#24A148" />
                            </span>
                          ) : (
                            <span
                              className="normal-icon"
                              style={{
                                width: "1rem",
                                minWidth: "1rem",
                                height: "18px",
                              }}
                            >
                              <Icon type="question-circle" theme="twoTone" twoToneColor="#DA1E28" />
                            </span>
                          )}
                        </div>
                      </div>
                    ),
                  }))}
                  options={["label", "value"]}
                  widths={["50%", "50%"]}
                  minRows={7}
                />
              </div>

              <div
                className="resourceInfo"
                style={{
                  marginTop: "1rem",
                }}
              >
                <ExtendDataTable
                  isBorderLeftNone={true}
                  isBorderThick={true}
                  isFixed
                  title={getTitle()}
                  data={icdData}
                  options={["icd_code", "icd_name"]}
                  widths={["25%", "75%"]}
                  minRows={7}
                />
              </div>
              {Number(authId) == 5 ? (
                <div
                  className="resourceInfo between"
                  style={{
                    marginTop: "1rem",
                    height: 680,
                  }}
                >
                  <CptReview
                    isRmBorder={true}
                    DataOpSearch={dataSearch}
                    keySearch="cpt_code_name"
                    keyType="short_desc"
                    title="cpt_review"
                    keyValue="cpt_code"
                    rows={cptData}
                    removeItem={removeRow}
                    isResult={isResult}
                    query={searchInput}
                    isModifier={true}
                    selectItem={selectItem}
                    handleSearch={handleSearch}
                    minRows={15}
                    isLocked={isLock}
                  />
                </div>
              ) : null}
              <div
                className="resourceInfo"
                style={{
                  marginTop: "1rem",
                }}
              >
                <ExtendDataTable
                  isBorderLeftNone={true}
                  isBorderThick={true}
                  isFixed
                  title={getTitleRvu()}
                  data={tableRows}
                  options={["rvu", "conversion_factor", "unit"]}
                  widths={["33%", "33%", "33%"]}
                  minRows={3}
                />
              </div>
              {usersCheck && usersCheck.id == 5 ? (
                <>
                  <div className="resourceInfo d-flex align-items-end required-field">
                    <EditableInput
                      label={"Provider"}
                      type={"check"}
                      options={only_providers}
                      value={localProviderId}
                      handleChange={(value) => {
                        setLocalProviderId(value);
                        handleChangeProvider(value);
                      }}
                      optionKey={"id"}
                      valueKey={"value"}
                      required={true}
                      helperText={"Provider is required"}
                      disabled={isLock}
                    />
                  </div>
                  <div className="resourceInfo d-flex required-field">
                    <EditableFormInput
                      label={"Finalized Date"}
                      value={localFinalizedDate ? localFinalizedDate : null}
                      type="date"
                      handleChange={handleDate}
                      disabled={isLock}
                    />
                  </div>
                  <div className="resourceInfo d-flex">
                    <div className="editableText material">
                      <div className={`MuiFormControl-root MuiTextField-root date-picker`}>
                        <TimePicker
                          disabled={isLock}
                          format={"h:mm:ss A"}
                          use12Hours
                          placeholder="Time"
                          onChange={handleTime}
                          value={localFinalizedDate ? moment(localFinalizedDate) : null}
                        />
                      </div>
                    </div>
                  </div>
                </>
              ) : null}
            </div>
            {usersCheck && (usersCheck?.is_provider == 1 || usersCheck?.is_admin == 1) ? (
              <React.Fragment>
                {!loadingButton && (
                  <div
                    className="actionContent"
                    style={{
                      marginTop: "2rem",
                      paddingRight: "1rem",
                    }}
                  >
                    {usersCheck?.is_admin == 1 && showBack && (
                      <button
                        style={{
                          marginRight: 10,
                        }}
                        onClick={handleGoBackReview}
                      >
                        Go back to review
                      </button>
                    )}
                    <button
                      id="finalize_click"
                      style={{
                        backgroundColor: statusAbort === "aborted" ? "#C6C6C6" : "#1164F2",
                      }}
                      onClick={saveAll}
                      disabled={statusAbort === "aborted"}
                    >
                      {isLock == 1 && statusAbort !== "aborted" ? "Unfinalize" : "Finalize +"}
                    </button>
                  </div>
                )}
              </React.Fragment>
            ) : (
              <React.Fragment>
                {!loadingButton && (
                  <div
                    className="actionContent"
                    style={{
                      marginTop: "2rem",
                      paddingRight: "1rem",
                    }}
                  >
                    <button
                      style={{
                        backgroundColor: statusAbort === "aborted" ? "#C6C6C6" : "#1164F2",
                      }}
                    >
                      {"Save for Review"}
                    </button>
                  </div>
                )}
              </React.Fragment>
            )}
          </ScrollWrapper>
        </div>
      </Drawer>
    );
  }
);

export default ReviewSideBar;
