import { convertFromRaw, convertToRaw, EditorState } from "draft-js";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { BoldButton, ItalicButton, OrderedListButton, UnderlineButton, UnorderedListButton } from "draft-js-buttons";
import createMentionPlugin from "draft-js-mention-plugin";
import Editor from "draft-js-plugins-editor";
import createToolbarPlugin from "draft-js-static-toolbar-plugin";
import { update_text_clinic_procedure_type } from "../../../store/actions";

import ScrollWrapper from "../../../common/components/ScrollBar";

const mentionPlugin = createMentionPlugin({
  mentionTrigger: "[",
});

const toolbarPlugin = createToolbarPlugin();

let timer = null;
const TemplateDocumentation = ({ resource }) => {
  const [editorState, setEditor] = useState(EditorState.createEmpty());
  const [variableCheck, setVariableCheck] = useState([]);
  const [focusEditor, setFocusEditor] = useState(false);
  const dispatch = useDispatch();
  const clinicVariable = useSelector((state) => state.procedureDetail.clinicVariable);
  const detail_clinic_type = useSelector((state) => state.procedureDetail.detail_clinic_type);

  const onChange = (editorState) => {
    setEditor(editorState);
    const data = JSON.stringify(convertToRaw(editorState.getCurrentContent()));
    if (detail_clinic_type && Object.keys(detail_clinic_type).length > 0 && focusEditor) {
      clearTimeout(timer);
      timer = setTimeout(() => {
        dispatch(
          update_text_clinic_procedure_type(detail_clinic_type?.id, {
            text_template: data,
          })
        );
      }, 1000);
    }
  };

  useEffect(() => {
    if (clinicVariable) {
      const variableMap = (clinicVariable || []).map((r) => {
        return {
          link: r?.id,
          name: `[${r.variable_name}]`,
          check: r.variable_name,
        };
      });
      setVariableCheck([...variableMap]);
    }
  }, [clinicVariable]);

  useEffect(() => {
    if (detail_clinic_type && Object.keys(detail_clinic_type).length > 0) {
      if (detail_clinic_type.text_template && detail_clinic_type.text_template.length > 0) {
        try {
          const contentState = convertFromRaw(JSON.parse(detail_clinic_type.text_template));
          const editorState = EditorState.createWithContent(contentState);
          setEditor(editorState);
        } catch (error) {
          setEditor(EditorState.createEmpty());
        }
      } else {
        setEditor(EditorState.createEmpty());
      }
    }
  }, [detail_clinic_type]);

  const onSearchChange = ({ value }) => {};

  const onAddMention = (value) => {};

  const { MentionSuggestions } = mentionPlugin;
  const { Toolbar } = toolbarPlugin;
  const plugins = [mentionPlugin, toolbarPlugin];

  return (
    <div style={{ height: "100%", position: "relative" }}>
      <div className="custom-toolbar-editor">
        <Toolbar>
          {(externalProps) => (
            <div className="editor-custom-toolbar">
              <BoldButton {...externalProps} />
              <ItalicButton {...externalProps} />
              <UnderlineButton {...externalProps} />
              <UnorderedListButton {...externalProps} />
              <OrderedListButton {...externalProps} />
            </div>
          )}
        </Toolbar>
      </div>
      <div
        style={{
          border: "1px solid #f2f2f2",
          padding: "10px",
          height: "calc(100% - 40px)",
          width: "100%",
          overflowY: "auto",
        }}
      >
        <ScrollWrapper css="no-padding x-hidden">
          <Editor
            onFocus={() => {
              setFocusEditor(true);
            }}
            onBlur={() => {
              setFocusEditor(false);
            }}
            editorState={editorState}
            onChange={onChange}
            plugins={plugins}
            placeholder="Procedure Details"
          />
        </ScrollWrapper>
      </div>
      <div className="mention-suggestion">
        <MentionSuggestions onSearchChange={onSearchChange} suggestions={variableCheck} onAddMention={onAddMention} />
      </div>
    </div>
  );
};

export default TemplateDocumentation;
