import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ScrollWrapper from "../../components/ScrollBar";
import Angio from "./Angio";
import Clinic from "./Clinic";
import ClinicDisease from "./ClinicDisease";
import Ct from "./Ct";
import Ultrasound from "./Disease";
import General from "./General";
import Prescription from "./Prescription";
import Referral from "./Referral";
import { Drawer, Icon } from "antd";
import { getPrimaryProviderDetailPatient } from "src/services/api.services";
import { setGlobalLoading } from "src/store/actions";
import "../FormStepper/style.scss";
import PharmacyAddForm from "../PharmacyAddForm";
import ProviderAddForm from "../ProviderAddForm";
import ProviderEditForm from "../ProviderEditForm";

const PlanSidebar = React.forwardRef(
  (
    {
      isShow,
      handleCloseSidebar,
      plans,
      planTypes,
      typePlanId,
      sidebarIcon,
      changeSetProcessHandle,
      activeRow,
      is_locked,
      handleUpdateStateFrenquency,
      ...restProps
    },
    ref
  ) => {
    const dispatch = useDispatch();
    const [step, setStep] = useState(0);
    const [formSteps, setFormSteps] = useState([]);
    const [conditions, setConditions] = useState([]);
    const [isPharmacyAdding, setIsPharmacyAdding] = useState(false);
    const [isProviderAdding, setIsProviderAdding] = useState(false);
    const [valueProviderEdit, setValueProviderEdit] = useState(null);
    const [isProviderEdit, setIsProviderEdit] = useState(false);
    const [bundle, setBundle] = useState("");
    const [stateDetail, setStateDetail] = useState(null);
    const [activeStatus, setActiveStatus] = useState(false);
    const sideNavStatus = useSelector((state) => state.common.sideNavStatus);
    const [propsDataRefer, setPropsDataRefer] = useState(null);

    const checkSet = (value, active) => {
      if (
        (value && value["plan"] && value["plan"][active] && value["plan"][active]["processed"] && value["plan"][active]["processed"] == 1) ||
        (value && value["plan"] && value["plan"][active] && value["plan"][active]["processed"])
      ) {
        setActiveStatus(true);
      } else {
        setActiveStatus(false);
      }
      if (value && value["plan"] && value["plan"][active] && value["plan"][active]["bundle"] && value["plan"][active]["bundle"] === "procedure") {
        setBundle("procedure");
      } else if (
        value &&
        value["plan"] &&
        value["plan"][active] &&
        value["plan"][active]["bundle"] &&
        value["plan"][active]["bundle"] === "ultrasound_test"
      ) {
        setBundle("ultrasound");
      } else if (value && value["plan"] && value["plan"][active] && value["plan"][active]["bundle"] && value["plan"][active]["bundle"] === "clinic") {
        setBundle("clinic");
      } else if (
        value &&
        value["plan"] &&
        value["plan"][active] &&
        value["plan"][active]["bundle"] &&
        value["plan"][active]["bundle"] === "general"
      ) {
        setBundle("general");
      } else if (value && value["plan"] && value["plan"][active] && value["plan"][active]["bundle"] && value["plan"][active]["bundle"] === "angio") {
        setBundle("angio");
      } else if (
        value &&
        value["plan"] &&
        value["plan"][active] &&
        value["plan"][active]["bundle"] &&
        value["plan"][active]["bundle"] === "referral"
      ) {
        setBundle("referral");
      } else if (value && value["plan"] && value["plan"][active] && value["plan"][active]["bundle"] && value["plan"][active]["bundle"] === "ct") {
        setBundle("ct");
      } else {
        setBundle("prescription");
      }
    };

    useEffect(() => {
      if (plans && plans.length > 0) {
        const check = plans.find((r) => r.condition_id == typePlanId);
        if (check) {
          checkSet(check, activeRow);
          setPropsDataRefer(null);
        }
      }
    }, [plans, typePlanId, activeRow]);

    const handleSubmit = () => {};

    const memoizedValue = useCallback(() => setStateDetail(restProps?.titlePlan), [restProps?.titlePlan]);

    useEffect(() => {
      memoizedValue(restProps?.titlePlan);
    }, [restProps?.titlePlan]);

    const onReloadProvider = async () => {
      dispatch(setGlobalLoading(true));
      const dataProvider = await getPrimaryProviderDetailPatient(valueProviderEdit?.npi || valueProviderEdit?.NPI);
      setValueProviderEdit(dataProvider);
      dispatch(setGlobalLoading(false));
    };

    if (!restProps.procedure_id_row) {
      return null;
    }

    return (
      <Drawer
        title={isPharmacyAdding ? "ADD NEW PHARMACY" : "PATIENT DASHBOARD"}
        placement="right"
        variable="permanent"
        className={sideNavStatus ? "wide" : ""}
        closable={false}
        mask={false}
        visible={isShow}
        destroyOnClose
      >
        <div ref={ref} className="resourceContainer new">
          <ScrollWrapper css="x-hidden">
            <div className="formStepContainer">
              <div className={`formStepper active`}>
                <div className="formStepper__header">
                  <div className="formStepper__status">
                    {!activeStatus && (
                      <span className="formStepper__status-check">
                        <Icon type="question-circle" theme="twoTone" twoToneColor="#DA1E28" />
                      </span>
                    )}
                    {activeStatus && (
                      <span className="formStepper__status-check">
                        <Icon type="check-circle" theme="twoTone" twoToneColor="#24A148" />
                      </span>
                    )}
                  </div>
                  <div className="formStepper__title">
                    {isPharmacyAdding ? "Pharmacy Information" : restProps?.titlePlan?.prescription?.medication?.drug_name || restProps?.titlePlan}
                  </div>
                </div>
              </div>

              <div className="formStepper__content">
                {isPharmacyAdding ? (
                  <PharmacyAddForm setPharmacyAddForm={setIsPharmacyAdding} />
                ) : isProviderAdding ? (
                  <ProviderAddForm setProviderAddForm={setIsProviderAdding} />
                ) : isProviderEdit ? (
                  <ProviderEditForm
                    isMedication={true}
                    onReloadClick={onReloadProvider}
                    valueProviderEdit={valueProviderEdit}
                    setProviderEditForm={setIsProviderEdit}
                  />
                ) : (
                  bundle &&
                  bundle.length > 0 && (
                    <React.Fragment>
                      {bundle == "procedure" ? (
                        <ClinicDisease
                          activeRow={activeRow}
                          titlePlan={stateDetail}
                          activeStatus={activeStatus}
                          changeSetProcessHandle={changeSetProcessHandle}
                          steps={formSteps}
                          is_locked={is_locked}
                          currentStep={step}
                          typePlanId={typePlanId}
                          plans={plans}
                          handleStep={setStep}
                          handleContinue={handleSubmit}
                          conditions={conditions}
                          {...restProps}
                        />
                      ) : bundle == "ultrasound" ? (
                        <Ultrasound
                          activeRow={activeRow}
                          titlePlan={stateDetail}
                          activeStatus={activeStatus}
                          steps={formSteps}
                          is_locked={is_locked}
                          currentStep={step}
                          typePlanId={typePlanId}
                          plans={plans}
                          handleStep={setStep}
                          handleContinue={handleSubmit}
                          conditions={conditions}
                          {...restProps}
                        />
                      ) : bundle == "angio" ? (
                        <Angio
                          activeRow={activeRow}
                          titlePlan={stateDetail}
                          changeSetProcessHandle={changeSetProcessHandle}
                          activeStatus={activeStatus}
                          steps={formSteps}
                          currentStep={step}
                          is_locked={is_locked}
                          typePlanId={typePlanId}
                          plans={plans}
                          handleStep={setStep}
                          handleContinue={handleSubmit}
                          conditions={conditions}
                          {...restProps}
                        />
                      ) : bundle == "clinic" ? (
                        <Clinic
                          activeRow={activeRow}
                          activeStatus={activeStatus}
                          steps={formSteps}
                          currentStep={step}
                          titlePlan={stateDetail}
                          typePlanId={typePlanId}
                          is_locked={is_locked}
                          plans={plans}
                          handleStep={setStep}
                          handleContinue={handleSubmit}
                          conditions={conditions}
                          {...restProps}
                        />
                      ) : bundle == "general" ? (
                        <General
                          activeRow={activeRow}
                          steps={formSteps}
                          activeStatus={activeStatus}
                          titlePlan={stateDetail}
                          currentStep={step}
                          typePlanId={typePlanId}
                          plans={plans}
                          handleStep={setStep}
                          is_locked={is_locked}
                          handleContinue={handleSubmit}
                          conditions={conditions}
                          {...restProps}
                        />
                      ) : bundle === "ct" ? (
                        <Ct
                          activeRow={activeRow}
                          steps={formSteps}
                          activeStatus={activeStatus}
                          titlePlan={stateDetail}
                          currentStep={step}
                          typePlanId={typePlanId}
                          plans={plans}
                          handleStep={setStep}
                          is_locked={is_locked}
                          handleContinue={handleSubmit}
                          conditions={conditions}
                          {...restProps}
                        />
                      ) : bundle === "referral" ? (
                        <Referral
                          activeRow={activeRow}
                          steps={formSteps}
                          activeStatus={activeStatus}
                          currentStep={step}
                          typePlanId={typePlanId}
                          titlePlan={stateDetail}
                          plans={plans}
                          handleStep={setStep}
                          is_locked={is_locked}
                          setPropsDataRefer={setPropsDataRefer}
                          propsDataRefer={propsDataRefer}
                          handleContinue={handleSubmit}
                          conditions={conditions}
                          valueProviderEdit={valueProviderEdit}
                          setIsProviderAdding={setIsProviderAdding}
                          setValueProviderEdit={setValueProviderEdit}
                          setIsProviderEdit={setIsProviderEdit}
                          {...restProps}
                        />
                      ) : (
                        <Prescription
                          activeRow={activeRow}
                          typePlanId={typePlanId}
                          activeStatus={activeStatus}
                          plans={plans}
                          is_locked={is_locked}
                          handleStep={setStep}
                          titlePlan={stateDetail}
                          updateStateFrenquency={handleUpdateStateFrenquency}
                          handleContinue={handleSubmit}
                          conditions={conditions}
                          handlePharmacyAdding={setIsPharmacyAdding}
                          {...restProps}
                        />
                      )}
                    </React.Fragment>
                  )
                )}
              </div>
            </div>
            <div style={{ paddingBottom: "2rem", width: "100%" }} />
          </ScrollWrapper>
        </div>
      </Drawer>
    );
  }
);

export default PlanSidebar;
