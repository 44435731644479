import { Checkbox } from "antd";
import React from "react";
import "./style.scss";

const YesNo = ({ title, subStatus, option, setOption, horizontal, id }) => {
  return (
    <div style={{ minWidth: horizontal ? "70px" : "115px" }}>
      <Checkbox
        id={`yes${id}`}
        style={{ marginLeft: horizontal ? "8px" : 0 }}
        checked={option === 1}
        onChange={() => setOption(1)}
      >
        Yes
      </Checkbox>
      <Checkbox id={`no${id}`} checked={option === 0} onChange={() => setOption(0)}>
        No
      </Checkbox>
    </div>
  );
};

export default YesNo;
