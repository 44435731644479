import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AutoCompleteSearch from '../../../common/components/AutoComplete/AutoCompleteSearch';
import DataTable from '../../../common/components/DataTable/WhiteHeaderTable';
import ToastMessage from '../../../common/components/Toast';
import {
  addProcedureDetails,
  edit_angio_type, getUltrasounds, setProcedureDetails
} from '../../../store/actions';

const FollowUpUltrasounds = ({resource}) => {
  const tableRef = useRef(null);
  const [isToastr, setIsToastr] = useState(false);
  const ultrasounds_angio_type = useSelector(
      (state) => state.procedureDetail.ultrasounds_angio_type,
  );
  const ultraSearch = useSelector((state) => state.common.ultrasounds);
  const detail_angio_type = useSelector(
      (state) => state.procedureDetail.detail_angio_type,
  );
  const [searchResult, setSearchResult] = useState([]);
  const [firstCheck, setFirstCheck] = useState(false);

  const [search, setSearch] = useState('');
  const dispatch = useDispatch();

  const updateProviders = async (type, index, value) => {
    setFirstCheck(true);
    if (!ultrasounds_angio_type.some((r) => r.ultrasound_types === value?.id)) {
      await dispatch(
          addProcedureDetails('ultrasounds_angio_type', {
            name_ultrasound: value?.name,
            ultrasound_types: value?.id,
          }),
      );
    } else {
      setIsToastr(true);
      setTimeout(() => {
        setIsToastr(false);
      }, 2000);
    }
    setFirstCheck(false);
  };

  const removeProvider = async (index) => {
    setFirstCheck(true);
    ultrasounds_angio_type.splice(index, 1);
    await dispatch(
        setProcedureDetails('ultrasounds_angio_type', [...ultrasounds_angio_type]),
    );
    setFirstCheck(false);
  };

  useEffect(() => {
    if (firstCheck) {
      callApiUpdate();
    }
  }, [ultrasounds_angio_type]);

  useEffect(() => {
    if (detail_angio_type) {
      const providerDetail = (detail_angio_type.ultrasound_types || []).map(
          (r) => {
            return {
              name_ultrasound: r?.name,
              ultrasound_types: r?.id,
            };
          },
      );
      dispatch(
          setProcedureDetails('ultrasounds_angio_type', [...providerDetail]),
      );
    }
  }, [detail_angio_type]);

  useEffect(() => {
    if (ultraSearch && ultraSearch.length > 0) {
      setSearchResult(ultraSearch);
    }
  }, [ultraSearch]);

  useEffect(() => {
    if (search && search.length > 0) {
      const checkfilter = ultraSearch.filter((r) =>
        r?.name.toLowerCase().includes(search.toLowerCase()),
      );
      setSearchResult(checkfilter);
    } else {
      setSearchResult(ultraSearch);
    }
  }, [search]);

  const callApiUpdate = () => {
    if (
      ultrasounds_angio_type &&
      detail_angio_type &&
      Object.keys(detail_angio_type).length > 0
    ) {
      const renderArray = ultrasounds_angio_type.map((r) => {
        return r.ultrasound_types;
      });
      dispatch(
          edit_angio_type(detail_angio_type?.id, {
            value: detail_angio_type?.value,
            sort: detail_angio_type?.sort,
            side: detail_angio_type?.side,
            is_first_case: detail_angio_type?.is_first_case,
            number_of_week_followup: detail_angio_type?.number_of_week_followup,
            range_between_procedure: detail_angio_type?.range_between_procedure,
            ultrasounds: renderArray,
          }),
      );
    }
  };

  useEffect(() => {
    dispatch(getUltrasounds());
  }, []);

  if (resource === null || resource === undefined || !resource) {
    return null;
  }

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {isToastr && (
        <ToastMessage
          type="Ultrasound Already Exists"
          status="failed"
          msg="Can Not Add Same Value!"
        />
      )}
      <div style={{width: '100%', minheight: '50px'}}>
        <AutoCompleteSearch
        isLargeHeight={true}
          title="Procedure-Follow-Up-Ultrasound"
          options={searchResult}
          handlePopulate={(value) =>
            updateProviders(
                'ultrasound_types',
                ultrasounds_angio_type.length,
                value,
            )
          }
          handleSearch={(val) => setSearch(val)}
          placeholder="Search Ultrasounds"
          keyLabel={'name'}
          actionLabel="Add"
        />
      </div>

      <div ref={tableRef} style={{flex: 1, width: '100%'}}>
        <DataTable
          title="follow_up_ultrasounds_angio_type"
          labels={['Ultrasound']}
          widths={['100%']}
          fullHeight={true}
          parentHeight={
            tableRef && tableRef.current && tableRef.current.clientHeight
          }
          handleDelete={(value, index) => removeProvider(index)}
          columns={[{sortable: true, key: 'ultrasound'}]}
          rowData={(ultrasounds_angio_type || []).map((item, index) => {
            return {
              ultrasound: item?.name_ultrasound,
            };
          })}
          emptyRows={new Array(4).fill(1)}
          sorts={['']}
        />
      </div>
    </div>
  );
};

export default FollowUpUltrasounds;
