import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import DataTable from "../../../../common/components/DataTable/DataTable";
import TopBar from "../../../../common/components/SubTopBar";
import { getPatientsByQuery, get_meaningful_statistics, setGlobalLoading } from "../../../../store/actions";

import { Icon } from "antd";
import { StaticResource } from "../../constant";
import Filter from "./filter";
import Sidebar from "./sidebar";

let timer;

const MeaningfulUse = ({ data, title }) => {
  const [isSidebar, setIsSidebar] = useState(false);
  const [loading, setLoading] = useState(true);
  const [tableLoad, setTableLoads] = useState([]);
  const [offLoad, setOffLoad] = useState(true);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [forFilter, setForFilter] = useState(false);
  const [activeIndex, setRow] = useState(-1);
  const patients = useSelector((state) => state.dashboard.patients);
  const adminMeantingfuls = useSelector((state) => state.adminMeaningful.adminMeantingfuls);
  const loading_get_meantingful = useSelector((state) => state.adminMeaningful.loading_get_meantingful);
  const dispatch = useDispatch();
  const [sortOption, setSortOption] = useState({});

  useEffect(() => {
    setLoading(loading_get_meantingful);
  }, [loading_get_meantingful]);

  useEffect(() => {
    if (search) {
      dispatch(getPatientsByQuery(search));
    }
  }, [search]);

  const sortList = async () => {
    const sortColumn = [];
    for (const sortOpt of Object.keys(sortOption)) {
      if (!!sortOption[sortOpt]) {
        sortColumn.push({ name: sortOpt, direction: sortOption[sortOpt] });
      }
    }
    setTableLoads([]);
    setLoading(true);
    setPage(1);
    if (!forFilter) {
      await dispatch(
        get_meaningful_statistics({
          start_dos: startDate,
          patient_name: search,
          per_page: 100,
          page: 1,
          column: sortColumn,
        })
      );
    } else {
      await dispatch(
        get_meaningful_statistics({
          start_dos: startDate,
          patient_name: search,
          per_page: 100,
          page: 1,
          column: sortColumn,
        })
      );
    }

    setLoading(false);
  };

  useEffect(() => {
    sortList();
  }, [sortOption]);

  const handleSortOption = (name, direction) => {
    const sortValue = { ...sortOption, [name]: direction };
    setSortOption(sortValue);
  };

  const handleSidebar = () => {
    setIsSidebar(true);
  };

  const valueSearch = (value) => {
    clearTimeout(timer);
    setLoading(true);
    timer = setTimeout(() => {
      setSearch(value);
      setForFilter(true);
      setPage(1);
      setLoading(false);
    }, 1000);
  };

  const handlePopulate = (item) => {
    setLoading(true);
    setTableLoads([]);
    setForFilter(true);
    setPage(1);
    setLoading(false);
    dispatch(
      get_meaningful_statistics({
        start_dos: startDate,
        patient_name: `${item?.first_name} ${item?.last_name} - ${item?.date_of_birth}`,
        per_page: 100,
        page: 1,
        column: sortOption,
      })
    );
  };

  const handleCloseSidebar = () => {
    setIsSidebar(false);
  };

  const handleRow = async (row, index) => {
    if (activeIndex !== index) {
      setRow(index);
      handleSidebar();
    }
  };

  const loadMore = async (values) => {
    const { scrollTop, scrollHeight, clientHeight } = values;
    const pad = 30; // px of the bottom
    const t = (scrollTop + pad) / (scrollHeight - clientHeight);
    const sortColumn = [];
    for (const sortOpt of Object.keys(sortOption)) {
      if (!!sortOption[sortOpt]) {
        sortColumn.push({ name: sortOpt, direction: sortOption[sortOpt] });
      }
    }
    if (t >= 1 && Number.isFinite(t) && !loading && !loading_get_meantingful) {
      if (offLoad) {
        setLoading(true);
        const list = await dispatch(
          get_meaningful_statistics({
            start_dos: startDate,
            patient_name: search,
            per_page: 100,
            page: page + 1,
            column: sortOption,
          })
        );
        if (list) {
          if (adminMeantingfuls && adminMeantingfuls.length > 0) {
            setPage(page + 1);
            setLoading(false);
          } else {
            setLoading(false);
            setOffLoad(false);
          }
        }
      }
    }
  };

  const onChangeDate = (value) => {
    setTableLoads([]);
    const date = moment(value).format("MM/DD/YYYY");
    dispatch(
      get_meaningful_statistics({
        start_dos: date,
        patient_name: "",
        per_page: 100,
        page: 1,
        column: sortOption,
      })
    );
    setStartDate(date);
  };

  useEffect(() => {
    if (!loading_get_meantingful && adminMeantingfuls && adminMeantingfuls.length > 0) {
      if (page > 1) {
        setTableLoads([...tableLoad, ...adminMeantingfuls]);
        setLoading(false);
      } else {
        setTableLoads([...tableLoad, ...adminMeantingfuls]);
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  }, [adminMeantingfuls]);

  useEffect(() => {
    dispatch(setGlobalLoading(loading));
  }, [loading]);

  const renderSubStatus = () => {
    let check = 0;
    let red = 0;

    if (tableLoad && tableLoad.length > 0) {
      check =
        tableLoad.filter(
          (row) =>
            row.vaccination === "Yes" &&
            row.bmi === "Yes" &&
            row.advance_directive === "Yes" &&
            row.smoking_education === "Yes" &&
            row.hypertension_education === "Yes" &&
            row.alcoholic_education === "Yes" &&
            row.pain_management === "Yes" &&
            row.hypertension === "Yes" &&
            row.medication_reconciliation === "Yes"
        ).length || 0;

      red =
        tableLoad.filter(
          (row) =>
            !row.vaccination !== "Yes" ||
            !row.bmi !== "Yes" ||
            !row.advance_directive !== "Yes" ||
            !row.smoking_education !== "Yes" ||
            !row.hypertension_education !== "Yes" ||
            !row.alcoholic_education !== "Yes" ||
            !row.pain_management !== "Yes" ||
            !row.hypertension !== "Yes" ||
            !row.medication_reconciliation !== "Yes"
        ).length || 0;
    }

    return (
      <div className="statusHealth">
        <Icon type="check-circle" theme="twoTone" twoToneColor="#24A148" />
        {check}
        <span style={{ minWidth: "10px", display: "inline-block" }} />
        <Icon type="warning" theme="twoTone" twoToneColor="#FF832B" />
        {red}
      </div>
    );
  };

  const renderStatusIcon = (row) => {
    if (
      row.vaccination !== "No" &&
      row.bmi !== "No" &&
      row.advance_directive !== "No" &&
      row.smoking_education !== "No" &&
      row.hypertension_education !== "No" &&
      row.alcoholic_education !== "No" &&
      row.pain_management !== "No" &&
      row.hypertension !== "No" &&
      row.medication_reconciliation !== "No"
    ) {
      return <Icon type="check-circle" theme="twoTone" twoToneColor="#24A148" />;
    }
    return <Icon type="warning" theme="twoTone" twoToneColor="#FF832B" />;
  };

  const sidebarRef = useRef();
  return (
    <div className="clinicWorkflow__main-page">
      <TopBar title="Meaningful Use" subStatus={renderSubStatus()} isBarcode={false} />
      <div className={`clinicWorkflow__main-container ${isSidebar ? "openSidebar" : ""}`}>
        <div className="clinicWorkflow__main-section">
          <div className="eventsContainer">
            <div className="fullContainer pt-2">
              <Filter
                autoCompleteOptions={patients}
                onChangeDate={onChangeDate}
                valueSearch={(value) => valueSearch(value)}
                handlePopulate={handlePopulate}
              />
              <div className="tableSection">
                <div className="tableContainer">
                  <DataTable
                    isBorderThick={true}
                    title="meaningful_use"
                    tableWidth={1120}
                    labels={[
                      "Patient Name",
                      "DOB",
                      "DOS",
                      "Pneumococcal",
                      "Influenza",
                      "BMI",
                      "Advance Directives",
                      "Smoking Education",
                      "Hypertension Education",
                      "Alcoholic Education",
                      "Pain Management",
                      "Hypertension",
                      "Medication",
                    ]}
                    widths={["11%", "8%", "8%", "7%", "7%", "5%", "7%", "8%", "8%", "8%", "8%", "7%", "8%"]}
                    columns={[
                      { sortable: true, key: "patient_name" },
                      { sortable: false, key: "patient_dob" },
                      { sortable: true, key: "dos" },
                      { sortable: true, key: "vaccination" },
                      { sortable: true, key: "influenza" },
                      { sortable: true, key: "bmi" },
                      { sortable: true, key: "advanced_directive" },
                      { sortable: true, key: "smoking_education" },
                      { sortable: true, key: "hypertension_education" },
                      { sortable: true, key: "alcoholic_education" },
                      { sortable: true, key: "pain_management" },
                      { sortable: true, key: "hypertension" },
                      { sortable: true, key: "medication_reconciliation" },
                    ]}
                    sorts={[
                      sortOption.patient_name,
                      "",
                      sortOption.dos,
                      sortOption.vaccination,
                      "",
                      sortOption.bmi,
                      sortOption.advanced_directive,
                      sortOption.smoking_education,
                      sortOption.hypertension_education,
                      sortOption.alcoholic_education,
                      sortOption.pain_management,
                      sortOption.hypertension,
                      sortOption.medication_reconciliation,
                    ]}
                    rowData={(tableLoad || []).map((row, index) => ({
                      id: row?.id,
                      patient_name: (
                        <div
                          style={{
                            display: "flex",
                            paddingLeft: "0.25rem",
                            justifyContent: "flex-start",
                            alignItems: "center",
                          }}
                        >
                          <div className="has-tooltip" style={{ paddingBottom: "4px" }}>
                            {renderStatusIcon(row)}
                          </div>
                          <p
                            style={{
                              paddingLeft: "0.625rem",
                              textTransform: "capitalize",
                            }}
                          >
                            {row?.patient && row?.patient?.name?.toLowerCase()}
                          </p>
                        </div>
                      ),
                      patient_dob:
                        row?.patient && row?.patient?.date_of_birth && moment(row?.patient && row?.patient?.date_of_birth).format("MM/DD/YYYY"),
                      dos:
                        row?.appointment &&
                        row?.appointment?.appointment_date &&
                        moment(row?.appointment && row?.appointment?.appointment_date).format("MM/DD/YYYY"),
                      vaccination: row?.vaccination,
                      influenza: row?.influenza,
                      bmi: row?.bmi,
                      advanced_directive: row?.advance_directive,
                      smoking_education: row?.smoking_education,
                      hypertension_education: row?.hypertension_education,
                      alcoholic_education: row?.alcoholic_education,
                      pain_management: row?.pain_management,
                      hypertension: row?.hypertension,
                      medication_reconciliation: row?.medication_reconciliation,
                    }))}
                    handleSort={(value, item) => {
                      handleSortOption(value, item);
                    }}
                    loadMore={loadMore}
                    handleClickRow={handleRow}
                    defaultActive={-1}
                    disableDelete={true}
                  />
                </div>
              </div>
            </div>
          </div>

          {activeIndex >= 0 && (
            <Sidebar
              ref={sidebarRef}
              detailPCM={activeIndex >= 0 && tableLoad && tableLoad[activeIndex] ? tableLoad[activeIndex] : undefined}
              resource={StaticResource}
              isShow={isSidebar}
              handleCloseSidebar={handleCloseSidebar}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default MeaningfulUse;
