import { Empty, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import DataTable from "../../../common/components/DataTable/WhiteHeaderTable";
import { ExpandSVG } from "../../../common/components/icons/SVGIcon";
import Loading from "../../../common/components/Loading";
import { getLocal } from "../../../helpers/Local";
import { search_cpt_code, setProcedureDetails, update_cpt_rule } from "../../../store/actions";

let timer = null;
const CptCodeLogic = ({ resource, rowAction }) => {
  const cptCodes = useSelector((state) => state.procedureDetail.cptCodes);
  const selectData = useSelector((state) => state.common.searchCPT);
  const loadingSearchCPT = useSelector((state) => state.common.loadingSearchCPT);
  const [searchResult, setSearchResult] = useState([]);
  const [page, setPage] = useState(1);
  const [selectResult, setSelectResult] = useState([]);
  const [selectSearch, setSelectSearch] = useState(null);
  const [localLoading, setLocalLoading] = useState(false);
  const [focusState, setFocusState] = useState(false);
  const [IDRow, setIDRow] = useState(null);
  const [activeDelete, setActiveDelete] = useState([]);
  const [removableParent, setRemovableParent] = useState(-1);
  const id_procedure_type = getLocal("id_procedure_type");

  const variable = useSelector((state) => state.procedureDetail.variable);
  const dispatch = useDispatch();
  const detailProcedureData = useSelector((state) => state.common.detailProcedureData);
  const modifiers = ["RT", "LT", "22", "50", "51", "52", "53", "62", "66", "73", "74", "PA", "PB", "PC", "XU"];
  const handleChange = async (type, value, index, indexCPT) => {
    if (cptCodes && cptCodes[indexCPT]) {
      if (type === "cpt_code_id") {
        cptCodes[indexCPT]["cpt_code"]["code"] = value[1];
        cptCodes[indexCPT]["cpt_code"]["short_desc"] = value[2];
        cptCodes[indexCPT]["cpt_code_id"] = value[0];
        cptCodes[indexCPT]["cpt_code"]["id"] = value[0];
        await dispatch(setProcedureDetails("cptCodes", [...cptCodes]));
      } else if (type === "modifier") {
        cptCodes[indexCPT]["condition_serialize"][index] = { modifier: value };
        await dispatch(setProcedureDetails("cptCodes", [...cptCodes]));
      } else {
        cptCodes[indexCPT]["condition_serialize"][index][type] = value === "THAN" && type === "operator" ? "THAN" : value;
        await dispatch(setProcedureDetails("cptCodes", [...cptCodes]));
      }
    }
  };

  const handleChangeOperator = async (value, index, indexCPT) => {
    const nextIndex = index + 1;
    if (cptCodes[indexCPT] && cptCodes[indexCPT]["condition_serialize"] && cptCodes[indexCPT]["condition_serialize"].length > 0) {
      const plan = cptCodes[indexCPT]["condition_serialize"][nextIndex];
      const paramNU = {
        variable_id: plan && typeof plan["variable_id"] != "undefined" ? plan["variable_id"] : "",
        variable_name: plan && typeof plan["variable_name"] != "undefined" ? plan["variable_name"] : "",
        answer: plan && typeof plan["answer"] != "undefined" ? plan["answer"] : "",
        comparison: plan && typeof plan["comparison"] != "undefined" ? plan["comparison"] : ">=",
        operator: plan && typeof plan["operator"] != "undefined" ? plan["operator"] : "",
      };
      const paramUN = {
        variable_id: undefined,
        variable_name: undefined,
        answer: undefined,
        comparison: undefined,
        operator: undefined,
      };
      if (cptCodes && cptCodes[indexCPT]) {
        if (value !== "THAN") {
          cptCodes[indexCPT]["condition_serialize"][index + 1] = { ...paramNU };
          await dispatch(setProcedureDetails("cptCodes", [...cptCodes]));
        } else {
          const abc = cptCodes[indexCPT];
          abc["condition_serialize"].map((item, indexMap) => {
            if (indexMap > index) {
              abc["condition_serialize"][indexMap] = null;
            }
          });
          const check = abc["condition_serialize"].filter((item) => {
            if (item != null) {
              return item;
            }
          });
          cptCodes[indexCPT]["condition_serialize"] = [...check, paramUN];
          await dispatch(setProcedureDetails("cptCodes", [...cptCodes]));
        }
      }
    }
  };

  useEffect(() => {
    if (detailProcedureData && Object.keys(detailProcedureData).length > 0) {
      dispatch(setProcedureDetails("cptCodes", detailProcedureData?.cpt_rules ? detailProcedureData?.cpt_rules : []));
    }
  }, [detailProcedureData]);

  useEffect(() => {
    const variableMap = (variable || []).map((r) => {
      return { id: r?.id, value: r.variable_name };
    });
    setSearchResult([...variableMap]);
  }, [variable]);

  useEffect(() => {
    if (!localLoading) {
      setSelectResult(selectData);
    }
  }, [selectData]);

  useEffect(() => {
    clearTimeout(timer);
    setLocalLoading(true);
    setSelectResult([]);
    setPage(1);
    timer = setTimeout(() => {
      dispatch(search_cpt_code(selectSearch, page));
      setLocalLoading(false);
    }, 1000);
  }, [selectSearch]);

  const handleClickRow = (row, index) => {
    setIDRow(index);
    if (rowAction) {
      rowAction(row);
    }
  };

  const removeProvider = async (index, indexCPT) => {
    const checkCPTRow = cptCodes[indexCPT];
    if (checkCPTRow && checkCPTRow["condition_serialize"] && checkCPTRow["condition_serialize"].length > 1) {
      if (checkCPTRow["condition_serialize"][index].operator === "THAN" || checkCPTRow["condition_serialize"][index].operator === "") {
        checkCPTRow["condition_serialize"].splice(index, 2);
      } else {
        checkCPTRow["condition_serialize"].splice(index, 1);
      }

      const check = checkCPTRow["condition_serialize"].filter((item) => {
        if (item != null) {
          return item;
        }
      });
      if (check && check.length > 0) {
        cptCodes[indexCPT]["condition_serialize"] = [...check];
      } else {
        cptCodes.splice(indexCPT, 1);
      }

      await dispatch(setProcedureDetails("cptCodes", [...cptCodes]));
    } else {
      cptCodes[indexCPT] = null;
      const checkArray = cptCodes.filter((r) => {
        if (r != null) {
          return r;
        }
      });
      await dispatch(setProcedureDetails("cptCodes", [...checkArray]));
      await dispatch(update_cpt_rule(id_procedure_type, { rules: [...checkArray] }));
    }
  };

  const update = async () => {
    if (cptCodes && cptCodes[IDRow] && cptCodes[IDRow]["condition_serialize"]) {
      const arrayADD = cptCodes.map((r) => {
        return {
          cpt_code_id: r?.cpt_code_id,
          procedure_type_id: id_procedure_type,
          cpt_code: r?.cpt_code,
          condition_serialize: r?.condition_serialize ? [...r?.condition_serialize] : [{}],
        };
      });
      await dispatch(update_cpt_rule(id_procedure_type, { rules: arrayADD }));
    }
  };

  useEffect(() => {
    update();
  }, [cptCodes]);

  if (resource === null || resource === undefined) {
    return null;
  }

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {cptCodes.map((itemCPT, indexCPT) => {
        return (
          <div
            key={`main-${indexCPT + 1}`}
            onClick={() => handleClickRow(itemCPT, indexCPT)}
            style={{
              width: "100%",
              background: "rgb(219, 227, 238)",
              border: IDRow === indexCPT ? "2px solid rgb(15, 98, 254)" : "",
              padding: itemCPT?.condition_serialize?.length > 0 ? "7px 0" : 0,
              marginBottom: itemCPT?.condition_serialize?.length > 0 ? "1rem" : 0,
            }}
          >
            <DataTable
              isOverFlow={true}
              isAlignTableItem={true}
              title="cpt_code"
              hideHeader={true}
              sizeHeightFix="100%"
              isNonScrollable
              isFixHeight={true}
              hideHeaderHeight="0px"
              allActive={true}
              handleDelete={(value, index) => removeProvider(index, indexCPT)}
              parentIndex={indexCPT}
              handleActiveDelete={(val) => {
                const activeDeletes = [...activeDelete];
                activeDeletes[indexCPT] = val;
                if (removableParent !== indexCPT) {
                  activeDeletes[removableParent] = -1;
                  setRemovableParent(indexCPT);
                } else {
                  if (val === -1) setRemovableParent(-1);
                }
                setActiveDelete(activeDeletes);
              }}
              activeParent={activeDelete[indexCPT]}
              labels={["Variable", "Option", "Answer", "Operator"]}
              widths={["35%", "15%", "20%", "30%"]}
              columns={[
                { sortable: false, key: "variable" },
                { sortable: false, key: "option" },
                { sortable: false, key: "answer" },
                { sortable: false, key: "operator" },
              ]}
              rowData={(itemCPT?.condition_serialize || []).map((item, index) => {
                let arrayAnswer = [];
                const answerCheck = (variable || []).find((r) => {
                  return r.variable_name == item?.variable_name?.value;
                });
                if (answerCheck) {
                  const allAnswerCheck = (answerCheck.answer || []).map((item, index) => {
                    return { id: item?.id, answer: item.answer };
                  });
                  arrayAnswer = [...allAnswerCheck];
                }
                return {
                  key: index,
                  disableDelete: typeof item?.operator === "undefined" && true,
                  fullsize: typeof item?.operator === "undefined" && true,
                  variable:
                    typeof item?.operator === "undefined" ? (
                      <div style={{ width: "100%" }}>
                        <Select
                          key={`key-${index}`}
                          showSearch
                          placeholder="Search CPT Code"
                          value={`${itemCPT?.cpt_code?.code} ${itemCPT?.cpt_code?.short_desc}`}
                          onSearch={(value) => {
                            setSelectSearch(value);
                          }}
                          onDropdownVisibleChange={(open) => {
                            setFocusState(open);
                          }}
                          onBlur={() => {
                            setSelectSearch(null);
                          }}
                          notFoundContent={
                            localLoading || loadingSearchCPT ? (
                              <div style={{ height: 100 }}>
                                <Loading />
                              </div>
                            ) : (
                              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                            )
                          }
                          autoClearSearchValue={false}
                          suffixIcon={<ExpandSVG />}
                          allowClear={false}
                          onChange={(value) => {
                            handleChange("cpt_code_id", value, index, indexCPT);
                          }}
                          style={{ width: "72.5%", borderBottom: 0 }}
                        >
                          {(selectResult || []).map((r, index) => (
                            <Select.Option key={r?.id} value={focusState ? [r?.id, r.code, r.short_desc] : `${r.code} ${r.short_desc}`}>
                              {r.code} {r.short_desc}
                            </Select.Option>
                          ))}
                        </Select>
                        <Select
                          placeholder={"Modifier"}
                          onChange={(value) => {
                            handleChange("modifier", value, index, indexCPT);
                          }}
                          key={index}
                          value={item?.modifier}
                          suffixIcon={<ExpandSVG />}
                          allowClear={false}
                          style={{ borderBottom: 0, marginLeft: "0.6rem" }}
                        >
                          {modifiers.map((el) => (
                            <Select.Option key={`modifier-${el}`} value={el}>
                              {el}
                            </Select.Option>
                          ))}
                        </Select>
                      </div>
                    ) : (
                      <Select
                        placeholder={"Variable"}
                        suffixIcon={<ExpandSVG />}
                        allowClear={false}
                        value={item?.variable_id}
                        onChange={(value) => {
                          handleChange("variable_id", value, index, indexCPT);
                          handleChange(
                            "variable_name",
                            searchResult.find((r) => r?.id == value),
                            index,
                            indexCPT
                          );
                        }}
                        style={{ width: "100%", borderBottom: 0 }}
                      >
                        {(searchResult || []).map((opt, index) => (
                          <Select.Option key={`variable-${index}-${opt?.id}`} value={opt?.id}>
                            {opt?.value}
                          </Select.Option>
                        ))}
                      </Select>
                    ),
                  option:
                    typeof item?.operator === "undefined" ? (
                      ""
                    ) : (
                      <Select
                        placeholder={"Opt"}
                        suffixIcon={<ExpandSVG />}
                        allowClear={false}
                        value={item?.comparison}
                        showArrow={false}
                        onChange={(value) => {
                          handleChange("comparison", value, index, indexCPT);
                        }}
                        style={{ width: "100%", borderBottom: 0 }}
                      >
                        {["<=", "=", "!=", ">="].map((opt) => (
                          <Select.Option key={`option--${opt}`} value={opt}>
                            {opt}
                          </Select.Option>
                        ))}
                      </Select>
                    ),
                  answer:
                    typeof item?.operator === "undefined" ? (
                      ""
                    ) : (
                      <Select
                        placeholder={"Answer"}
                        suffixIcon={<ExpandSVG />}
                        allowClear={false}
                        value={item?.answer}
                        onChange={(value) => {
                          handleChange("answer", value, index, indexCPT);
                        }}
                        style={{ width: "100%", borderBottom: 0 }}
                      >
                        {(arrayAnswer || []).map(
                          (opt) =>
                            opt.answer && (
                              <Select.Option key={`answer-${opt.answer}`} value={opt.answer}>
                                {opt.answer}
                              </Select.Option>
                            )
                        )}
                      </Select>
                    ),
                  operator:
                    typeof item?.operator === "undefined" ? (
                      ""
                    ) : (
                      <Select
                        placeholder={"Operator"}
                        suffixIcon={<ExpandSVG />}
                        allowClear={false}
                        value={item?.operator || undefined}
                        onChange={(value) => {
                          handleChange("operator", value, index, indexCPT);
                          handleChangeOperator(value, index, indexCPT);
                        }}
                        style={{ width: "100%", borderBottom: 0 }}
                      >
                        {["OR", "AND", "THAN"].map((opt) => (
                          <Select.Option key={`operator-${opt}`} value={opt}>
                            {opt}
                          </Select.Option>
                        ))}
                      </Select>
                    ),
                };
              })}
            />
          </div>
        );
      })}
    </div>
  );
};

export default CptCodeLogic;
