import { convertFromRaw, convertToRaw, EditorState } from "draft-js";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useDispatch, useSelector } from "react-redux";
import { getLocal } from "../../../../helpers/Local";
import { getLocations, getProcedureData, setGlobalLoading, setGlobalToastr, setParamsChart, setSidebarState } from "../../../../store/actions";
import ChartingSidebar from "./ChartingSidebar";
import dataHeader from "./formarterData";
import dataHeaderFull from "./formarterDataFull";

let timer = null;
const Charting = ({ setIndexSlider, indexSlider, setIndexDoc, indexDoc }) => {
  const authName = getLocal("cvai-current-user-name");
  const [text, setText] = useState(EditorState.createEmpty());
  const [textRender, setTextRender] = useState({});
  const [text_template_detail, set_text_template_detail] = useState([]);
  const [text_template_hospital, set_text_template_hospital] = useState([]);
  const [keyPanelCheck, setKeyPanelCheck] = useState(undefined);
  const [check_render_ag, setCheckRenderAG] = useState("");
  const text_param = useSelector((state) => state.patientRecords.text_param);
  const [isSidebar, setIsSidebar] = useState(true);
  const detailProcedureData = useSelector((state) => state.common.detailProcedureData);
  const [detailHospital, setDetailHospital] = useState({});
  const conditionData = useSelector((state) => state.common.referralConditions);
  const procedureData = useSelector((state) => state.common.procedureData);
  const providers = useSelector((state) => state.common.providers);
  const paramsChart = useSelector((state) => state.patientRecords.paramsChart);
  const editorState = EditorState.createEmpty();
  const dataParams = useSelector((state) => state.patientRecords.dataParams);
  const dispatch = useDispatch();

  const handleCloseSidebar = () => {
    setIsSidebar(false);
  };

  const clickSetRow = (value, item) => {
    setDetailHospital(item);
  };

  const handleClickShow = async (dataCheck) => {
    let data = dataCheck?.medical_reading?.text_template || dataHeaderFull.text_template;
    clearTimeout(timer);
    timer = setTimeout(() => {
      dispatch(setParamsChart("text_param", data));
    }, 500);

    if (data) {
      await dispatch(setGlobalLoading(true));
      const contentState = convertFromRaw(JSON.parse(data));
      const editorState = EditorState.createWithContent(contentState);
      setTimeout(() => {
        updateCheck(editorState, dataCheck);
      }, 500);
    }
  };

  useEffect(() => {
    setText(editorState);
  }, []);

  useEffect(() => {
    dispatch(setSidebarState(true));
    dispatch(getProcedureData());
    dispatch(getLocations());
    if (window.innerWidth <= 768) {
      setIsSidebar(false);
    }
    return () => {
      dispatch(setSidebarState(false));
    };
  }, []);

  useEffect(() => {
    if (detailProcedureData && detailProcedureData?.text_template_serialized) {
      set_text_template_detail({
        text_template: [detailProcedureData?.text_template_serialized],
        id: detailProcedureData?.id,
      });
    } else {
      set_text_template_detail([]);
    }
    if (detailHospital && detailHospital.medical_reading && detailHospital.medical_reading.text_template) {
      set_text_template_hospital([detailHospital.medical_reading.text_template]);
    } else {
      set_text_template_hospital([]);
    }
  }, [detailProcedureData, detailHospital]);

  const onChange = (editorState) => {
    setText(editorState);
    const data = JSON.stringify(convertToRaw(editorState.getCurrentContent()));
    clearTimeout(timer);
    timer = setTimeout(() => {
      dispatch(setParamsChart("text_param", data));
    }, 500);
  };

  const updateCheck = (textCheck, dataParams, subProState, textTemplate, valueX) => {
    if ((dataParams && Object.keys(dataParams).length > 0 && dataParams?.appointment_type_id) || (dataParams && Object.keys(dataParams).length > 0)) {
      let data = JSON.stringify(convertToRaw(textCheck.getCurrentContent()));
      if (data?.includes("SIGNATURE:")) {
        if (procedureData && procedureData?.length > 0) {
          const dataIDDiff = (procedureData || []).map((r) => r?.id);
          const DataID = (dataParams?.appointment_type_id || []).map((r) => r?.value);
          const a = dataIDDiff;
          const b = DataID;
          const b1 = new Set(b);
          const difference = [...new Set([...a].filter((x) => !b1.has(x)))];
          const check = "";
          difference.map((r) => {
            const [headDiff, ...itemHeadDiff] =
              data &&
              data?.split(`,{"key":"txtdxhead${r}","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`);
            const checkHeadDiff = (itemHeadDiff || []).map((item) => {
              return item.split(`{"key":"txtdxend${r}","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`)[0];
            });
            if (checkHeadDiff && checkHeadDiff[0]) {
              if (
                data?.includes(
                  `,{"key":"txtdxhead${r}","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}${checkHeadDiff[0]}{"key":"txtdxend${r}","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`
                )
              ) {
                data = data?.replace(
                  `{"key":"txtdxhead${r}","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}${checkHeadDiff[0]}{"key":"txtdxend${r}","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`,
                  ``
                );
              } else if (
                data?.includes(
                  `,{"key":"txtdxhead${r}","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}${checkHeadDiff[0]}{"key":"txtdxend${r}","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`
                )
              ) {
                data = data?.replace(
                  `,{"key":"txtdxhead${r}","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}${checkHeadDiff[0]}{"key":"txtdxend${r}","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`,
                  ``
                );
              }
            }
          });
        }
        let textCPT = "";
        if (dataParams?.cpt_codes && dataParams?.cpt_codes.length > 0) {
          for (let index = 0; index < dataParams?.cpt_codes.length; index++) {
            const element = dataParams?.cpt_codes[index];
            textCPT += element?.cpt_code_name
              ? `{"key":"csomp-${index}-${element?.cpt_code_name}","text":"${element?.cpt_code} ${element?.short_desc} (${element?.pivot?.modifier})","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`
              : "";
          }
        }

        // head 1
        const [head1, ...itemHead1] = data && data?.split(`},{"key":"text1","text":"`);
        const checkHead1 = (itemHead1 || []).map((item) => {
          return item.split(`",`)[0];
        });
        if (checkHead1) {
          data = data?.replace(
            `{"key":"text1","text":"${checkHead1[0]}","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`,
            `{"key":"txtbrl1","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"text1","text":"${
              dataParams?.appointment_date ? moment(dataParams?.appointment_date).format("MM/DD/YYYY") : ""
            }","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}${
              dataParams?.appointment_date
                ? `,{"key":"5td5s","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`
                : ""
            }`
          );
        }
        // head 2
        const [head2, ...itemHead2] = data && data?.split(`},{"key":"text2","text":"`);
        const checkHead2 = (itemHead2 || []).map((item) => {
          return item.split(`",`)[0];
        });
        if (checkHead2) {
          data = data?.replace(
            `{"key":"text2","text":"${checkHead2[0]}","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`,
            `{"key":"txtbrl2","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"text2","text":"${
              dataParams?.side ? `${dataParams?.side} ` : ""
            }${
              dataParams?.preoperative_dx_id ? ((conditionData || []).find((r) => r?.id == dataParams?.preoperative_dx_id) || {}).value : ""
            }","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}${
              dataParams?.preoperative_dx_id || dataParams?.side
                ? `,{"key":"e01nq","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`
                : ""
            }`
          );
        }
        // head 3
        const [head3, ...itemHead3] = data && data?.split(`},{"key":"text3","text":"`);
        const checkHead3 = (itemHead3 || []).map((item) => {
          return item.split(`",`)[0];
        });
        if (checkHead3) {
          data = data?.replace(
            `{"key":"text3","text":"${checkHead3[0]}","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`,
            `{"key":"txtbrl3","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"text3","text":"${
              dataParams?.side ? `${dataParams?.side} ` : ""
            }${
              dataParams?.preoperative_dx_id ? ((conditionData || []).find((r) => r?.id == dataParams?.preoperative_dx_id) || {}).value : ""
            }","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}${
              dataParams?.preoperative_dx_id || dataParams?.side
                ? `,{"key":"37ke","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`
                : ""
            }`
          );
        }
        // head 4
        if (data?.includes(`{"key":"text4","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`)) {
          const [head4, ...itemHead4] =
            data && data?.split(`{"key":"text4","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`);
          const checkHead4 = (itemHead4 || []).map((item) => {
            return item.split(`{"key":"b16nu","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`)[0];
          });
          if (dataParams?.appointment_type_id && dataParams.appointment_type_id.length > 0) {
            if (checkHead4) {
              data = data?.replace(
                `{"key":"text4","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}${checkHead4[0]}`,
                `{"key":"text4","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},${textCPT}`
              );
            }
          } else {
            data = data?.replace(
              `{"key":"text4","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}${checkHead4[0]}`,
              `{"key":"text4","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`
            );
          }
        }
        // head 5
        const [head5, ...itemHead5] = data && data?.split(`},{"key":"text5","text":"`);
        const checkHead5 = (itemHead5 || []).map((item) => {
          return item.split(`",`)[0];
        });
        if (checkHead5) {
          data = data?.replace(
            `{"key":"text5","text":"${checkHead5[0]}","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`,
            `{"key":"txtbrl5","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"text5","text":"${
              dataParams?.disposition ? dataParams?.disposition : ""
            }","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}${
              dataParams?.disposition
                ? `,{"key":"a9p8d","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`
                : ""
            }`
          );
        }
        // head 6
        const [head6, ...itemHead6] = data && data?.split(`},{"key":"text6","text":"`);
        const checkHead6 = (itemHead6 || []).map((item) => {
          return item.split(`",`)[0];
        });
        if (checkHead6) {
          data = data?.replace(
            `{"key":"text6","text":"${checkHead6[0]}","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`,
            `{"key":"txtbrl6","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"text6","text":"${
              dataParams?.complications ? dataParams?.complications : ""
            }","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}${
              dataParams?.complications
                ? `,{"key":"48v4j","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`
                : ""
            }`
          );
        }
        // head 7
        const [head7, ...itemHead7] = data && data?.split(`},{"key":"text7","text":"`);
        const checkHead7 = (itemHead7 || []).map((item) => {
          return item.split(`",`)[0];
        });
        if (checkHead7) {
          data = data?.replace(
            `{"key":"text7","text":"${checkHead7[0]}","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`,
            `{"key":"txtbrl7","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"text7","text":"${
              dataParams?.ebl ? `${dataParams?.ebl} cc` : ""
            }","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}${
              dataParams?.ebl ? `,{"key":"8mesm","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}` : ""
            }`
          );
        }
        // head 8
        const [head8, ...itemHead8] =
          data &&
          data?.split(
            `{"key":"6rddso2","text":"INDICATIONS:","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":12,"style":"BOLD"},{"offset":0,"length":12,"style":"UNDERLINE"}],"entityRanges":[],"data":{}},`
          );
        const checkHead8 = (itemHead8 || []).map((item) => {
          return item.split(`{"key":"4i8qs","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`)[0];
        });
        if (checkHead8 && checkHead8[0] && checkHead8[0] !== "") {
          let valueImport = dataParams?.indications && dataParams?.indications.length > 0 ? dataParams?.indications.replace(`{"blocks":[`, "") : null;
          // const renderBlock = valueImport ? valueImport.replace(`],"entityMap":{}}`, "") : null;
          if (valueImport && valueImport.includes(`"entityMap":[{`)) {
            const [enti, ...entis] = valueImport && valueImport.split(`],"entityMap":[{"`);
            const checkEnti = (entis || []).map((item) => {
              return item.split(`]}`)[0];
            });
            if (checkEnti && checkEnti[0].length > 0) {
              valueImport = valueImport.replace(`],"entityMap":[{"${checkEnti[0]}]}`, ``);
            }
            if (valueImport.includes(`],"entityMap":[]}`)) {
              valueImport = valueImport.replace(`],"entityMap":[]}`, "");
            }
          } else {
            if (valueImport && valueImport.includes(`],"entityMap":{}}`)) {
              valueImport = valueImport.replace(`],"entityMap":{}}`, "");
            }
          }

          if (valueImport) {
            data = data?.replace(
              `${checkHead8[0]}`,
              `{"key":"txtbrl8","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},${valueImport},{"key":"textrenderspaces","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"textend","text":"The patient was made aware of the risks and benefits of the procedure including indication, cardiac events, stroke, limb loss and death. The patient wishes to proceed. All questions have been answered in full.","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`
            );
          }
        } else {
          if (dataParams && dataParams?.indications && dataParams?.indications.length > 0) {
            let valueImport = dataParams?.indications.replace(`{"blocks":[`, "");
            if (valueImport.includes(`"entityMap":[{`)) {
              const [enti, ...entis] = valueImport && valueImport.split(`],"entityMap":[{"`);
              const checkEnti = (entis || []).map((item) => {
                return item.split(`]}`)[0];
              });
              if (checkEnti && checkEnti[0].length > 0) {
                valueImport = valueImport.replace(`],"entityMap":[{"${checkEnti[0]}]}`, ``);
              }
              if (valueImport.includes(`],"entityMap":[]}`)) {
                valueImport = valueImport.replace(`],"entityMap":[]}`, "");
              }
            } else {
              if (valueImport.includes(`],"entityMap":{}}`)) {
                valueImport = valueImport.replace(`],"entityMap":{}}`, "");
              }
            }
            const clear = data?.replace(
              `{"key":"6rddso2","text":"INDICATIONS:","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":12,"style":"BOLD"},{"offset":0,"length":12,"style":"UNDERLINE"}],"entityRanges":[],"data":{}},`,
              `{"key":"6rddso2","text":"INDICATIONS:","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":12,"style":"BOLD"},{"offset":0,"length":12,"style":"UNDERLINE"}],"entityRanges":[],"data":{}},{"key":"txtbrl8","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},${valueImport},{"key":"textrenderspaces","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"textend","text":"The patient was made aware of the risks and benefits of the procedure including indication, cardiac events, stroke, limb loss and death. The patient wishes to proceed. All questions have been answered in full.","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`
            );
            data = clear;
          }
        }
        // head 9
        const [head9, ...itemHead9] = data && data?.split(`},{"key":"text9","text":"`);
        const checkHead9 = (itemHead9 || []).map((item) => {
          return item.split(`",`)[0];
        });
        if (checkHead9) {
          data = data?.replace(
            `{"key":"text9","text":"${checkHead9[0]}","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`,
            `{"key":"txtbrl9","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"text9","text":"${
              dataParams?.provider_id ? ((providers || []).find((r) => r?.id == dataParams?.provider_id) || {}).value : `${authName}`
            }","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"txtbr2l9","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`
          );
        }
        // head 11
        const [head11, ...itemHead11] = data && data?.split(`},{"key":"text11","text":"`);
        const checkHead11 = (itemHead11 || []).map((item) => {
          return item.split(`",`)[0];
        });
        if (checkHead11) {
          data = data?.replace(
            `{"key":"text11","text":"${checkHead11[0]}","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`,
            `{"key":"txtbrl11","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"text11","text":"${
              dataParams?.anesthesia ? dataParams?.anesthesia : ""
            }","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}
              ${
                dataParams?.anesthesia
                  ? `,{"key":"8ca3sm","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`
                  : ""
              }`
          );
        }
        // SIGNATURE STARTS

        const [headSig, ...itemHeadSig] =
          data && data?.split(`{"key":"textsigsmall","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`);
        const checkHeadSig = (itemHeadSig || []).map((item) => {
          return item.split(
            `{"key":"textsigsmallempty","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`
          )[0];
        });
        const textSigText = `I, ${authName || ""} , hereby attest that the medical record entry for ${moment().format(
          "MM/DD/YYYY"
        )} accurately reflects signatures/notations that I made in my capacity as the provider when I treated/diagnosed the above listed beneficiary. I do hereby attest that this information is true, accurate and complete to the best of my knowledge and I understand that any falsification, omission, or concealment of material fact may subject me to administrative, civil, or criminal liability.`;
        let singalKey = `
            {"key":"txtporscio","text":"${authName}","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},
            {"key":"txtporscioempty","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},
            {"key":"textsigsText","text":"${textSigText}","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`;
        if (authName.indexOf("Amy") > -1 || authName.indexOf("Leah") > -1 || authName.indexOf("Jennifer") > -1) {
          singalKey += `{"key":"txtporscioempty2","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"txtporscioempty3","text":"My collaborating physician is Omar Haqqani, MD and Majid Aized, MD.","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`;
        }
        if (checkHeadSig && checkHeadSig[0] && checkHeadSig[0].length > 0) {
          data = data?.replace(
            `${checkHeadSig[0]},{"key":"textsigsmallempty","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`,
            `${singalKey}{"key":"textsigsmallempty","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`
          );
        } else {
          if (!data?.includes(singalKey)) {
            data = data?.replace(
              `{"key":"textsigsmall","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`,
              `{"key":"textsigsmall","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},
              ${singalKey}`
            );
          }
        }
        let mapText_Check = [];
        let array_text_detail = [];
        if ((text_template_detail && text_template_detail?.text_template) || (textTemplate && textTemplate?.text_template)) {
          const ckm = `${(text_template_detail.text_template || textTemplate?.text_template || []).map((r, index) => {
            if (r && r.length > 1 && r.includes("blocks")) {
              const mapText_ = JSON.parse(r);
              if (mapText_) {
                (mapText_.blocks || []).map((r, i) => {
                  if (mapText_.blocks.length <= 1) {
                    mapText_Check = [
                      ...mapText_Check,
                      r,
                      {
                        key: "txtdxend_" + text_template_detail?.id || textTemplate?.id,
                        text: "",
                        type: "unstyled",
                        depth: 0,
                        inlineStyleRanges: [],
                        entityRanges: [],
                        data: {},
                      },
                    ];
                  } else {
                    if (i == 0) {
                      mapText_Check = [...mapText_Check, r];
                    }
                    if (i == mapText_.blocks.length - 1) {
                      mapText_Check = [
                        ...mapText_Check,
                        r,
                        {
                          key: "txtdxend_" + text_template_detail?.id || textTemplate?.id,
                          text: "",
                          type: "unstyled",
                          depth: 0,
                          inlineStyleRanges: [],
                          entityRanges: [],
                          data: {},
                        },
                      ];
                    }
                    if (i != 0 && i !== mapText_.blocks.length - 1) {
                      mapText_Check = [...mapText_Check, r];
                    }
                  }
                });
              }
              array_text_detail = mapText_Check;
            }
          })}`;
        }
        if (subProState && subProState?.length > 0) {
          for (let i = 0; i < subProState.length; i++) {
            const element = subProState[i];
            // eslint-disable-next-line no-loop-func
            const update = `${(element?.text_template_serialized ? [element?.text_template_serialized] : []).map((r, index) => {
              if (r && r.length > 1 && r.includes("blocks")) {
                const mapText_ = JSON.parse(r);
                if (mapText_) {
                  (mapText_.blocks || []).map((r, i) => {
                    if (mapText_.blocks.length <= 1) {
                      mapText_Check = [
                        ...mapText_Check,
                        r,
                        {
                          key: "txtdxend_" + element?.id,
                          text: "",
                          type: "unstyled",
                          depth: 0,
                          inlineStyleRanges: [],
                          entityRanges: [],
                          data: {},
                        },
                      ];
                    } else {
                      if (i == 0) {
                        mapText_Check = [...mapText_Check, r];
                      }
                      if (i == mapText_.blocks.length - 1) {
                        mapText_Check = [
                          ...mapText_Check,
                          r,
                          {
                            key: "txtdxend_" + element?.id,
                            text: "",
                            type: "unstyled",
                            depth: 0,
                            inlineStyleRanges: [],
                            entityRanges: [],
                            data: {},
                          },
                        ];
                      }
                      if (i != 0 && i !== mapText_.blocks.length - 1) {
                        mapText_Check = [...mapText_Check, r];
                      }
                    }
                  });
                }
                array_text_detail = mapText_Check;
              }
            })}`;
          }
        }
        // Head end PROCEDURE PERFORMED 2

        if (array_text_detail && array_text_detail.length > 0) {
          if (!data.includes("txtdxend_")) {
            if (data?.includes("DATE OF PROCEDURE") && data?.includes("SIGNATURE:")) {
              const check = data?.replace(
                `{"key":"5td5x","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`,
                `{"key":"textfixedtopempty","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"textfixedtop","text":"The patient was brought to the operating room and laid in the supine position. IV access was obtained and ${
                  dataParams?.anesthesia
                } was administered. Clippers were utilized to remove the unwanted hairs from the surgical field. A surgical timeout was ascertained. Medical record number, site and location of the procedure were confirmed. The areas of surgical access were prepped and draped in the standard, usual fashion.","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"5td5x","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},
              ${`${array_text_detail.map((r) => JSON.stringify(r)).join()}`},`
              );
              data = check;
            }
          }
        }
        //
        const [headend, ...itemHeadend] =
          data && data?.split(`{"key":"5td5x","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`);
        const checkHeadend = (itemHeadend || []).map((item) => {
          return item.split(
            `{"key":"sikcma","text":"SIGNATURE:","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":10,"style":"BOLD"},{"offset":0,"length":10,"style":"UNDERLINE"}],"entityRanges":[],"data":{}},`
          )[0];
        });

        // replace vari
        let checkArray = [];
        if (dataParams && dataParams.ros && dataParams.ros.length > 0) {
          (dataParams.ros || []).map((r, index) => {
            return (r?.values || []).map((vl, ivl) => {
              if (vl.checked || vl.checked == true) {
                checkArray = [
                  ...checkArray,
                  {
                    type: r.typeFull,
                    variable_name: r.variable_name,
                    checked: true,
                    text_template: vl.text_template.replaceAll("\n", " "),
                    option: vl.option,
                  },
                ];
              }
            });
          });
        }
        if (checkArray.length > 0 && checkArray.length == 1) {
          let fixText = data;
          checkArray.map((r, indexr) => {
            if (r.checked) {
              setTextRender({
                ...textRender,
                [`${r.variable_name}`]: r.text_template.replaceAll("\n", " "),
              });
              if (fixText.includes(`[${r.variable_name}]`)) {
                const a = fixText.split(`[${r.variable_name}]`);
                (a || []).map((i, indexi) => {
                  fixText = fixText.replace(`[${r.variable_name}]`, `${r.text_template.replaceAll("\n", " ")}`);
                });
              } else {
                const a = fixText.split(`${textRender[`${r.variable_name}`]}`);
                (a || []).map((i, indexi) => {
                  fixText = fixText.replace(`${textRender[`${r.variable_name}`]}`, `${r.text_template.replaceAll("\n", " ")}`);
                });
              }
            }
          });
          if (!!fixText.length) {
            data = fixText;
          }
        } else if (checkArray.length > 1) {
          let mixText = {};
          let fixText = data;

          checkArray.map((r, indexr) => {
            if (r.checked) {
              mixText = {
                ...mixText,
                [r.variable_name]: mixText[r.variable_name]
                  ? mixText[r.variable_name] + `${r?.text_template.replaceAll("\n", " ")} `
                  : `${r?.text_template.replaceAll("\n", " ")} `,
              };
            }
          });
          checkArray.map((r, indexr) => {
            if (r.checked) {
              if (fixText.includes(`[${r.variable_name}]`)) {
                const a = fixText.split(`[${r.variable_name}]`);
                (a || []).map((i, indexi) => {
                  fixText = fixText.replace(`[${r.variable_name}]`, `${mixText[r.variable_name] || ""}`);
                });
              }
            }
          });
          if (!!fixText.length) {
            data = fixText;
          }
        }
        // SIGNATURE ENDS

        // DATE FINALIZED STARTS

        let date_signed_text = "";
        date_signed_text = `{"key":"keydatesigned","text":"${moment().format(
          "MM/DD/YYYY HH:mm A"
        )}","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`;

        const [headDate, ...itemHeadDate] = data && data?.split(`{"key":"keydatesigned",`);
        const checkHeadDate = (itemHeadDate || []).map((item) => {
          return item.split(`,"data":{}}`)[0];
        });
        if (checkHeadDate && checkHeadDate[0] && checkHeadDate[0].length > 0) {
          data = data?.replace(`{"key":"keydatesigned",${checkHeadDate[0]},"data":{}}`, `${date_signed_text}`);
        } else {
          data = data?.replace(
            `{"key":"text20","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`,
            `${date_signed_text},{"key":"text20","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`
          );
        }
        const dataMix = JSON.parse(data) ? { ...JSON.parse(data) } : null;
        // make %X%
        if (dataMix?.blocks && dataMix?.blocks.length > 0) {
          if (valueX) {
            const indexMix = dataMix?.blocks.findIndex((r) => r?.text?.includes("%X%"));
            if (indexMix != -1) {
              const valueMix = dataMix["blocks"][indexMix];
              let textMix = valueMix?.text;
              textMix = textMix.replace("%X%", valueX);
              const params = {
                ...valueMix,
                text: textMix,
                inlineStyleRanges: [{ offset: textMix?.indexOf("%X%"), length: valueX.length, style: "UNDERLINE" }],
              };
              dataMix["blocks"][indexMix] = params;

              if (params?.text?.includes("%X%")) {
                const fixParamsRed = {
                  ...params,
                  inlineStyleRanges: [
                    { offset: params?.text?.indexOf("%X%"), length: 3, style: "BOLD" },
                    { offset: params?.text?.indexOf("%X%"), length: 3, style: "color-red" },
                    { offset: params?.text?.indexOf("%X%"), length: 3, style: "UNDERLINE" },
                  ],
                };
                dataMix["blocks"][indexMix] = fixParamsRed;
              } else {
                const indexMix = dataMix?.blocks.findIndex((r) => r?.text?.includes("%X%"));
                if (indexMix != -1) {
                  const fixParamsRed = {
                    ...dataMix["blocks"][indexMix],
                    inlineStyleRanges: [
                      { offset: dataMix["blocks"][indexMix]?.text?.indexOf("%X%"), length: 3, style: "BOLD" },
                      { offset: dataMix["blocks"][indexMix]?.text?.indexOf("%X%"), length: 3, style: "color-red" },
                      { offset: dataMix["blocks"][indexMix]?.text?.indexOf("%X%"), length: 3, style: "UNDERLINE" },
                    ],
                  };
                  dataMix["blocks"][indexMix] = fixParamsRed;
                }
              }
            }
          } else {
            const indexMix = dataMix?.blocks.findIndex((r) => r?.text?.includes("%X%"));
            if (indexMix != -1) {
              const fixParamsRed = {
                ...dataMix["blocks"][indexMix],
                inlineStyleRanges: [
                  { offset: dataMix["blocks"][indexMix]?.text?.indexOf("%X%"), length: 3, style: "BOLD" },
                  { offset: dataMix["blocks"][indexMix]?.text?.indexOf("%X%"), length: 3, style: "color-red" },
                  { offset: dataMix["blocks"][indexMix]?.text?.indexOf("%X%"), length: 3, style: "UNDERLINE" },
                ],
              };
              dataMix["blocks"][indexMix] = fixParamsRed;
            }
          }
        }
        data = JSON.stringify(dataMix);
        // DATE FINALIZED ENDS
        clearTimeout(timer);
        timer = setTimeout(() => {
          dispatch(setParamsChart("text_param", data));
          const contentState = convertFromRaw(JSON.parse(data));
          const editorState = EditorState.createWithContent(contentState);
          setText(editorState);
        }, 500);
        dispatch(setGlobalLoading(false));
      }
    } else {
      setText(EditorState.createEmpty());
      dispatch(setGlobalLoading(false));
    }
  };

  const callBackSave = async (dataParams, subProState, textTemplate, valueX) => {
    if (dataParams?.appointment_type_id && dataParams?.appointment_type_id.length > 0) {
      if (
        dataParams?.location_id ||
        dataParams?.admit_date ||
        dataParams?.anesthesia ||
        dataParams?.appointment_date ||
        dataParams?.complications ||
        dataParams?.disposition ||
        dataParams?.ebl ||
        dataParams?.preoperative_dx_id ||
        dataParams?.side
      ) {
        await dispatch(setGlobalLoading(true));

        let dataTemplateApi =
          text_param.includes("SIGNATURE:") &&
          text_param.includes("PROCEDURE PERFORMED") &&
          text_param.includes("INDICATIONS:") &&
          text_param?.includes("SURGEON")
            ? text_param
            : dataParams?.medical_reading?.text_template || null;
        const data = dataTemplateApi || dataHeaderFull.text_template;
        const contentState = convertFromRaw(JSON.parse(data));
        const editorState = EditorState.createWithContent(contentState);
        setTimeout(() => {
          updateCheck(editorState, dataParams, subProState, textTemplate, valueX);
        }, 500);
      } else if (
        !dataParams?.location_id &&
        !dataParams?.admit_date &&
        !dataParams?.anesthesia &&
        !dataParams?.appointment_date &&
        !dataParams?.complications &&
        !dataParams?.disposition &&
        !dataParams?.ebl &&
        !dataParams?.preoperative_dx_id &&
        !dataParams?.side
      ) {
        await dispatch(setGlobalLoading(true));
        const contentState = convertFromRaw(JSON.parse(dataHeader.text_template));
        const editorState = EditorState.createWithContent(contentState);
        setTimeout(() => {
          updateCheck(editorState, dataParams, subProState);
        }, 500);
      }
    }
  };

  const updateForm = async () => {
    if (
      (paramsChart?.location_id ||
        paramsChart?.admit_date ||
        paramsChart?.anesthesia ||
        paramsChart?.appointment_date ||
        paramsChart?.complications ||
        paramsChart?.disposition ||
        paramsChart?.ebl ||
        paramsChart?.preoperative_dx_id ||
        paramsChart?.side) &&
      (!paramsChart?.appointment_type_id || paramsChart.appointment_type_id.length == 0)
    ) {
      dispatch(
        setGlobalToastr({
          header: "Hospital Charting",
          message: "Please pick first Work completed",
          type: "failed",
        })
      );
      setKeyPanelCheck("work-completes");
    }
  };

  useEffect(() => {
    updateForm();
  }, [paramsChart, dataParams, procedureData, text_template_detail]);

  useEffect(() => {
    if (text) {
      const data = JSON.stringify(convertToRaw(text.getCurrentContent()));
      const [headend, ...itemHeadend] =
        data && data?.split(`{"key":"textendtxt","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`);
      const checkHeadend = (itemHeadend || []).map((item) => {
        return item.split(`],"entityMap"`)[0];
      });
      if (checkHeadend && checkHeadend[0]) {
        setCheckRenderAG(`${checkHeadend[0]}`);
      }
    }
  }, [text]);

  const removeChart = () => {
    const contentState = convertFromRaw(JSON.parse(dataHeader.text_template));
    const editorState = EditorState.createWithContent(contentState);
    setText(editorState);
    setTimeout(() => {
      updateCheck(editorState);
    }, 500);
  };

  const sidebarRef = useRef();
  return (
    <div className="clinicWorkflow__main-section">
      <div className="eventsContainer has-sub-header" style={{ paddingTop: "1rem" }}>
        <div style={{ width: "100%", height: "100%" }} className="rm-toolbar">
          <Editor
            toolbar={{
              options: ["inline", "list"],
              inline: {
                options: ["bold", "italic", "underline"],
              },
              list: {
                options: ["unordered", "ordered"],
              },
            }}
            editorStyle={{
              border: "1px solid #f2f2f2",
              padding: "10px",
              height: "100%",
              backgroundColor: "#fff",
            }}
            placeholder="Patient Review"
            editorState={text}
            onEditorStateChange={onChange}
          />
        </div>
      </div>
      <ChartingSidebar
        ref={sidebarRef}
        callBackSave={callBackSave}
        removeChart={removeChart}
        keyPanelCheck={keyPanelCheck}
        updateCheck={updateCheck}
        setKeyPanelCheck={setKeyPanelCheck}
        handleClickShow={handleClickShow}
        onClickSetRow={(value, item) => {
          clickSetRow(value, item);
        }}
        indexSlider={indexSlider}
        setIndexSlider={setIndexSlider}
        indexDoc={indexDoc}
        setIndexDoc={setIndexDoc}
        isShow={isSidebar}
        handleCloseSidebar={handleCloseSidebar}
      />
    </div>
  );
};

export default Charting;
