import {createActionThunk} from 'redux-thunk-actions';
import AT from '../actionTypes';
import * as API from '../../services/api.services';

export const clinicProviderReviewLoadInit = createActionThunk(
    AT.CLINIC_REVIEW_LOAD_INIT,
    (procedure_id) => API.clinicProviderReviewLoadInit(procedure_id),
);

export const ultrasoundReviewLoadInit = createActionThunk(
    AT.ULTRASOUND_REVIEW_LOAD_INIT,
    (procedure_id) => API.ultrasoundReviewLoadInit(procedure_id),
);

export const add_plan_me = createActionThunk(AT.ADD_PLAN_ME, (id, params) =>
  API.add_plan_me(id, params),
);

export const unlockClinicProcedure = createActionThunk(
    AT.UNLOCK_CLINIC_PROCEDURE,
    (action) => action,
);

export const lockClinicProcedure = createActionThunk(
    AT.LOCK_CLINIC_PROCEDURE,
    (action) => action,
);

export const editICD = createActionThunk(AT.EDIT_ICD, (action) => action);

export const saveAppointmentIcd = createActionThunk(
    AT.SAVE_APPOINTMENT_ICD,
    (procedure_id, params) => API.saveAppointmentIcd(procedure_id, params),
);
