import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import EditableFormInput from "../../../common/components/EditableText/EditableFormInput";
import DeleteIcon from "../../../common/components/icons/DeleteIconButton";
import YesNo from "../../../common/components/YesNo";
import {
  delete_angio_type_variable,
  edit_angio_type_variable,
  get_angio_type_variable,
  setGlobalToastr,
  setProcedureDetails,
} from "../../../store/actions";
import RegexSpecial from "src/helpers/RegexSpecial";

let timer = null;
const VariableCapture = ({ resource, rowAction }) => {
  const [activeDeleteQuestion, setActiveDeleteQuestion] = useState(null);
  const [activeDeleteAnswer, setActiveDeleteAnswer] = useState(null);
  const [IDRow, setIDRow] = useState(null);
  const [state, setState] = useState([]);
  const [error, setError] = useState(null);
  const [inputTimeout, setInputTimeout] = useState(null);
  // TODO: captures integration
  const angioVariable = useSelector((state) => state.procedureDetail.angioVariable);
  const angio_type_variable_list = useSelector((state) => state.procedureDetail.angio_type_variable_list);
  const detail_angio_type = useSelector((state) => state.procedureDetail.detail_angio_type);
  const dispatch = useDispatch();

  useEffect(() => {
    setState(angioVariable);
    setError(null);
  }, [angioVariable]);

  useEffect(() => {
    setIDRow(-1);
    if (rowAction) {
      rowAction({
        index: null,
        selectedVariable: null,
      });
    }
  }, [resource, rowAction]);

  const handleClickRow = (row, index) => {
    setIDRow(row?.id);
    if (rowAction) {
      rowAction({ index, selectedVariable: row });
    }
  };

  const updateVariable = async () => {
    const checkArray = (angio_type_variable_list || []).map((r, index) => {
      return {
        variable_name: r?.variable_name,
        id: r?.id,
        quest: r?.question,
        answer: r?.answers,
        is_multiple: r?.is_multiple,
        condition_serialize: detail_angio_type && Object.keys(detail_angio_type).length > 0 && detail_angio_type?.cpt_rules,
      };
    });
    await dispatch(setProcedureDetails("angioVariable", [...checkArray]));
  };

  useEffect(() => {
    setError(null);
    updateVariable();
  }, [angio_type_variable_list, detail_angio_type]);

  useEffect(() => () => clearTimeout(inputTimeout), [inputTimeout]);

  const updateProviders = async (type, index, value, indexAnswer, idOnRow) => {
    if (type === "quest") {
      const tempVariable = [...state];
      tempVariable[index][type] = value;
      setState(tempVariable);
      if (inputTimeout) clearTimeout(inputTimeout);
      setInputTimeout(
        setTimeout(() => {
          dispatch(
            edit_angio_type_variable(idOnRow?.id, {
              variable_name: idOnRow?.variable_name,
              question: value,
              procedure_type_id: detail_angio_type?.id,
              answers: angioVariable[index]["answer"],
              is_multiple: angioVariable[index]["is_multiple"],
            })
          );
          dispatch(setProcedureDetails("angioVariable", [...angioVariable]));
        }, 1000)
      );
    } else if (type === "is_multiple") {
      const tempVariable = [...state];
      tempVariable[index][type] = value;
      setState(tempVariable);
      if (inputTimeout) clearTimeout(inputTimeout);
      setInputTimeout(
        setTimeout(() => {
          dispatch(
            edit_angio_type_variable(idOnRow?.id, {
              variable_name: idOnRow?.variable_name,
              question: idOnRow?.quest,
              procedure_type_id: detail_angio_type?.id,
              answers: angioVariable[index]["answer"],
              is_multiple: value,
            })
          );
          dispatch(setProcedureDetails("angioVariable", [...angioVariable]));
        }, 1000)
      );
    } else {
      const tempVariable = [...state];
      tempVariable[index]["answer"][indexAnswer][type] = value;
      setState(tempVariable);
      if (inputTimeout) clearTimeout(inputTimeout);
      setInputTimeout(
        setTimeout(() => {
          dispatch(
            edit_angio_type_variable(idOnRow?.id, {
              variable_name: idOnRow?.variable_name,
              question: idOnRow?.quest,
              procedure_type_id: detail_angio_type?.id,
              answers: angioVariable[index]["answer"],
              is_multiple: angioVariable[index]["is_multiple"],
            })
          );
          dispatch(setProcedureDetails("angioVariable", [...angioVariable]));
        }, 1000)
      );
    }
  };
  const removeProvider = async (type, index, indexAnswer, idOnRow) => {
    if (type === "quest") {
      if (!activeDeleteQuestion) {
        setActiveDeleteQuestion(`quest-${index}`);
      } else {
        if (activeDeleteQuestion === `quest-${index}`) {
          angioVariable.splice(index, 1);
          setActiveDeleteQuestion(null);
          await dispatch(delete_angio_type_variable(idOnRow));
        } else {
          setActiveDeleteQuestion(`quest-${index}`);
        }
      }
    } else {
      if (!activeDeleteAnswer) {
        setActiveDeleteAnswer(`answer-${index}-${indexAnswer}`);
      } else {
        if (activeDeleteAnswer === `answer-${index}-${indexAnswer}`) {
          angioVariable[index]["answer"].splice(indexAnswer, 1);
          setActiveDeleteAnswer(null);
          await dispatch(
            edit_angio_type_variable(idOnRow?.id, {
              variable_name: idOnRow?.variable_name,
              question: idOnRow?.quest,
              procedure_type_id: detail_angio_type?.id,
              answers: angioVariable[index]["answer"],
            })
          );
        } else {
          setActiveDeleteAnswer(`answer-${index}-${indexAnswer}`);
        }
      }
    }
    await dispatch(get_angio_type_variable(detail_angio_type?.id));
  };

  const handleError = (type, index) => {
    setError({
      type,
      index,
    });
    dispatch(
      setGlobalToastr({
        header: "Alert",
        type: "failed",
        message: "Invalid characters not allowed!",
      })
    );
  };

  if (resource === null || resource === undefined) {
    return null;
  }

  return (
    <div style={{ width: "100%", height: "100%", overflow: "scroll" }}>
      {state?.map((r, index) => (
        <div
          key={index}
          style={{
            background: "#dbe3ee",
            padding: 12,
            border: IDRow === r?.id ? "2px solid rgb(15, 98, 254)" : "",
            marginBottom: index !== angioVariable?.length - 1 ? "1rem" : 0,
          }}
          onClick={() => handleClickRow(r, index)}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              margin: "5px 0 8px",
            }}
          >
            <div style={{ width: "calc(100% - 32px)", marginRight: "8px" }}>
              <EditableFormInput
                label="Specifications"
                isError={error && error["type"] === "quest" && error["index"] === index ? true : false}
                required={true}
                value={r.quest}
                fullWidth
                focusWhite
                handleChange={(value) => {
                  if (RegexSpecial(value)) {
                    handleError("quest", index);
                  } else {
                    updateProviders("quest", index, value, null, r);
                  }
                }}
              />
            </div>
            <DeleteIcon
              icon="can"
              selected={activeDeleteQuestion === `quest-${index}`}
              handleClick={() => removeProvider("quest", index, null, r?.id)}
            />
          </div>
          <div style={{ marginBottom: 10 }} className="resourceInfo d-flex">
            <b style={{ minWidth: "37%" }}>Variable Name: </b>
            {r.variable_name}
          </div>
          <div style={{ marginBottom: 10 }} className="resourceInfo d-flex">
            <b style={{ minWidth: "37%" }}>Is Multiple: </b>
            <YesNo
              option={r?.is_multiple}
              setOption={(value) => {
                updateProviders("is_multiple", index, value, null, r);
              }}
            />
          </div>
          <div>
            {r?.answer?.map((item, indexAnswer) => (
              <div
                key={`answer${indexAnswer + 1}`}
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  marginBottom: indexAnswer !== r.answer.length - 1 ? "8px" : "0",
                }}
              >
                <div style={{ width: "calc(40% - 8px)", marginRight: "8px" }}>
                  <EditableFormInput
                    isError={error && error["type"] === "answer" && error["index"] === indexAnswer ? true : false}
                    required={true}
                    handleChange={(value) => {
                      if (RegexSpecial(value)) {
                        handleError("answer", indexAnswer);
                      } else {
                        updateProviders("answer", index, value, indexAnswer, r);
                      }
                    }}
                    value={item.answer}
                    label={`Answer ${indexAnswer + 1}`}
                    fullWidth
                    focusWhite
                    type="area"
                    isHospitalProcedure={true}
                  />
                </div>
                <div style={{ width: "calc(60% - 32px)", marginRight: "8px" }}>
                  <EditableFormInput
                    isError={error && error["type"] === "text_template" && error["index"] === indexAnswer ? true : false}
                    required={true}
                    handleChange={(value) => {
                      if (RegexSpecial(value)) {
                        handleError("text_template", indexAnswer);
                      } else {
                        updateProviders("text_template", index, value, indexAnswer, r);
                      }
                    }}
                    label="Sentence"
                    value={item.text_template}
                    fullWidth
                    focusWhite
                    type="area"
                    isHospitalProcedure={true}
                  />
                </div>
                <DeleteIcon
                  icon="can"
                  selected={activeDeleteAnswer === `answer-${index}-${indexAnswer}`}
                  handleClick={() => removeProvider("answer", index, indexAnswer, r)}
                />
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default VariableCapture;
