import React from 'react';
import { LoadingSVG } from '../icons/SVGIcon';

import './style.scss';

const Loading = ({extra, color}) => (
  <div
    style={{
      position: 'absolute',
      inset: 0,
      backgroundColor: 'rgba(0,0,0, 0.5)',
      zIndex: 499,
    }}
  >
    <div
      className="loading-icon"
      style={{
        position: 'fixed',
        width: '120px',
        height: '120px',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        zIndex: 500,
        ...extra,
      }}
    >
      <LoadingSVG color={color} />
    </div>
  </div>
);

export default Loading;
