import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Icon } from 'antd';
import DataTable from '../../../../common/components/DataTable/DataTable';
import { getPCPList, setGlobalLoading } from '../../../../store/actions';

let timer = null;

const InActiveReferring = ({
  activeIndex,
  title,
  handleRow,
  search,
  propsListTableIn,
  deleteListPCPIn,
}) => {
  
  const pcpList = useSelector((state) => state.common.pcpList);
  const loading_get_pcp = useSelector((state) => state.common.loading_get_pcp);
  const [page, setPage] = useState(1);
  const [offLoad, setOffLoad] = useState(true);
  const [loading, setLoading] = useState(true);
  const [tableRows, setTableRows] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    setPage(1);
    setTableRows([]);
    clearTimeout(timer);
    timer = setTimeout(() => {
      dispatch(getPCPList(1, search));
    }, 500);
  }, [search]);

  const fetchApi = async () => {
    await setTableRows([]);
    await dispatch(getPCPList(page));
  };

  useEffect(() => {
    if (
      tableRows &&
      tableRows.length > 0 &&
      deleteListPCPIn &&
      Object.keys(deleteListPCPIn).length > 0
    ) {
      if (deleteListPCPIn?.id) {
        const index = tableRows.findIndex((r) => r?.id == deleteListPCPIn?.id);
        tableRows.splice(index, 1);
        setTableRows([...tableRows]);
      }
    }
  }, [deleteListPCPIn]);

  useEffect(() => {
    if (
      tableRows &&
      tableRows.length > 0 &&
      propsListTableIn &&
      Object.keys(propsListTableIn).length > 0
    ) {
      if (propsListTableIn?.id) {
        const index = tableRows.findIndex((r) => r?.id == propsListTableIn?.id);
        tableRows.splice(index, 1, {
          ...propsListTableIn,
          first_name: propsListTableIn?.first_name,
          last_name: propsListTableIn?.last_name,
          name: `${propsListTableIn?.first_name} ${propsListTableIn?.last_name}`,
          full_name: `${propsListTableIn?.first_name} ${propsListTableIn?.last_name}`,
        });
        setTableRows([...tableRows]);
      }
    }
  }, [propsListTableIn]);

  useEffect(() => {
    if (!loading_get_pcp && pcpList && pcpList['inactive_pcp']) {
      setTableRows([...tableRows, ...pcpList['inactive_pcp']]);
    }
  }, [pcpList]);

  useEffect(() => {
    dispatch(setGlobalLoading(loading_get_pcp));
  }, [loading_get_pcp]);

  const onScroll = async (values) => {
    const { scrollTop, scrollHeight, clientHeight } = values;
    const pad = 30; // px of the bottom
    const t = (scrollTop + pad) / (scrollHeight - clientHeight);
    if (t >= 1 && Number.isFinite(t) && !loading_get_pcp) {
      if (offLoad) {
        setLoading(true);
        const list = await dispatch(getPCPList(page + 1, search));
        if (list) {
          if (
            pcpList &&
            pcpList['inactive_pcp'] &&
            pcpList['inactive_pcp'].length > 0
          ) {
            setPage(page + 1);
            setLoading(false);
          } else {
            setLoading(false);
            setOffLoad(false);
          }
        }
      }
    }
  };

  return (
    <div className="fullContainer pt-2">
      <DataTable
        title="administrator_user_inactive"
        tableWidth={960}
        labels={['Provider Name', 'Login', 'Last Access Date and Time']}
        widths={['40%', '30%', '30%']}
        columns={[
          { sortable: false, key: 'provider_name' },
          { sortable: false, key: 'login' },
          { sortable: false, key: 'last' },
        ]}
        sorts={['', '', '', '']}
        rowData={(tableRows || []).map((row, index) => ({
          id: row?.id,
          full_name: row.full_name,
          name: row.full_name,
          first_name: row.first_name,
          last_name: row.last_name,
          address: row.address,
          phone_number: row.phone_number,
          status: row.status,
          zipcode: row.zipcode,
          state: row.state,
          suite: row.suite,
          city: row.city,
          email_address: row.email_address,
          fax_number: row.fax_number,
          provider_name: (
            <div
              style={{
                display: 'flex',
                paddingLeft: '0.25rem',
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}
            >
              {!row.status ? (
                <div className="d-flex">
                  <Icon
                    type="question-circle"
                    theme="twoTone"
                    twoToneColor="#DA1E28"
                  />
                </div>
              ) : null}
              {row.status ? (
                <div className="d-flex">
                  <Icon
                    type="check-circle"
                    theme="twoTone"
                    twoToneColor="#24A148"
                  />
                </div>
              ) : null}
              <p style={{ paddingLeft: '0.75rem' }}>{row.full_name}</p>
            </div>
          ),
          login: row?.username,
          pass: row?.password,
          last: row?.last_login,
        }))}
        loadMore={onScroll}
        fetchList={fetchApi}
        handleClickRow={(row, index) => handleRow(index, row)()}
        defaultActive={-1}
        disableDelete={true}
      />
    </div>
  );
};

export default InActiveReferring;
