import { Icon } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import DeleteIconButton from "../icons/DeleteIconButton";
import { SortingArrowIcon } from "../icons/SortingIcon";
import ScrollWrapper from "../ScrollBar";

import "./style.scss";

const WhiteHeaderTable = ({
  labels,
  widths,
  rowData,
  columns,
  sorts,
  title,
  hideHeader,
  hideHeaderHeight,
  allActive,
  activeTable,
  handleClickRow,
  onClickColumn,
  noneAllActive,
  handleSort,
  emptyRows,
  fullHeight,
  disableDelete,
  handleDelete,
  isNormal,
  disableFirstBorder,
  showIcon,
  editable,
  handleEdit,
  fullsize,
  isSide,
  componentSide,
  isNonScrollable,
  parentHeight,
  isFixHeight,
  sizeHeightFix,
  isNotFirstactive,
  activeParent,
  handleActiveDelete,
  parentIndex,
  activeIndex,
  keyCheck,
  loadMore,
  customSorting = {},
  isShowPaddingHeight,
  noColPad,
  bordered,
  isICD,
  isTableNew,
  isHospitalCharting,
  isOverFlow,
  isAlignTableItem,
  isBorderRadiusFalse,
  isRmBorders,
  isBorderThick,
  isRmHeaderBorder,
  isShowBodyBorders,
  isMarginTop,
  indexItem,
}) => {
  const [sortOptions, setSortOptions] = useState([]);
  const [activeRow, setActiveRow] = useState(!isNotFirstactive ? 0 : -1);
  const [activeDelete, setActiveDelete] = useState(-1);
  useEffect(() => {
    if (sorts) {
      setSortOptions(sorts);
    }
  }, [sorts]);

  useEffect(() => {
    setActiveRow(activeIndex);
  }, [activeIndex]);

  const emptyData = useMemo(() => {
    if (parentHeight && parentHeight > 0) {
      const total = Math.floor(parentHeight / 40);
      const count = rowData && rowData.length > 0 ? total - rowData.length : total;
      if (count >= 0) {
        return new Array(count).fill(1);
      }
    }
    return [];
  }, [parentHeight, rowData]);

  const handleSortOption = (index, sort) => {
    const newSorts = [...sortOptions];
    newSorts[index] = sort;
    setSortOptions(newSorts);

    if (handleSort) {
      handleSort(newSorts);
    }
  };

  const handleRow = (index, row) => {
    setActiveRow(index);

    if (handleClickRow) {
      handleClickRow(row, index);
    }
  };

  const handleDeleteRow = (index, row) => {
    if (handleActiveDelete && activeParent !== index) {
      handleActiveDelete(index);
      return;
    }
    if (!handleActiveDelete && activeDelete !== index) {
      setActiveDelete(index);
      return;
    }
    if (handleDelete) {
      handleDelete(row, index);
      setActiveDelete(-1);
      if (handleActiveDelete) handleActiveDelete(-1);
    }
  };

  const renderClass = (index) => {
    if (index === labels?.length - 1) {
      if (!disableDelete && editable) {
        return "last-value with-delete-edit p";
      } else if (!disableDelete) {
        return "last-value with-delete p";
      } else {
        return "last-value p";
      }
    } else {
      if (labels || !labels.length) {
        return "pr-0 p";
      } else {
        return "p";
      }
    }
  };

  const onLoadMore = (values) => {
    if (loadMore) {
      loadMore(values);
    }
  };
  const obj = isOverFlow ? { overflow: "hidden" } : { true: true };

  return (
    <div
      style={{ height: isFixHeight ? sizeHeightFix : "100&", ...obj, ...(isRmHeaderBorder && { border: "none" }) }}
      className={
        isTableNew
          ? `whiteHeaderTable-appointment-new ${isHospitalCharting && isTableNew && "hospital_tab_border"}`
          : `whiteHeaderTable ${isHospitalCharting && "hospital_tab_border"}`
      }
    >
      <div
        className={`${isTableNew ? "whiteHeaderTable-appointment-new-header" : "whiteHeaderTable-header"} sortableHeader ${isNormal ? "normal" : ""}`}
        style={{
          minHeight: hideHeaderHeight === undefined ? "50px" : hideHeaderHeight,
          ...(isBorderThick && { borderBottom: "2px solid #dfe3e6" }),
        }}
      >
        {labels &&
          labels.length > 0 &&
          labels.map((label, index) => (
            <div
              className={`th sortable ${index === 0 && disableFirstBorder ? "disable-first-border" : ""} ${!sortOptions[index] ? "hide-icon" : ""}`}
              style={{
                ...(isRmBorders && index === 0 && { borderLeft: "none" }),
                width: showIcon && index === 0 ? `calc(${widths[index]} - 2rem` : widths[index] || `calc(100% / ${labels.length})`,
                minWidth: showIcon && index === 0 ? `calc(${widths[index]} - 2rem` : widths[index] || `calc(100% / ${labels.length})`,
                display: hideHeader ? "none" : "flex",
                alignItems: "center",
                height: isHospitalCharting ? "49px" : hideHeaderHeight === undefined ? "40px" : hideHeaderHeight,
                marginLeft: showIcon && index === 0 ? "2rem" : 0,
                ...(isHospitalCharting && index === 0 && { borderLeft: "0" }),
              }}
              key={`${title}-head-${index}`}
            >
              {Object.keys(customSorting).length > 0 && customSorting.sortType && customSorting.node && customSorting.sortType === label ? (
                customSorting.node
              ) : (
                <div
                  className={`${isTableNew ? "whiteHeaderTable-appointment-new-header__label" : "whiteHeaderTable-header__label"}`}
                  style={{
                    width: "100%",
                    minWidth: "20%",
                    borderBottom: "none",
                    paddingLeft: "0.75rem",
                    color: "rgba(0, 0, 0, 0.65)",
                    ...(isMarginTop && { marginTop: "3px" }),
                  }}
                >
                  {label}
                </div>
              )}
              {columns && columns[index] && columns[index].sortable && (
                <SortingArrowIcon
                  sort={sortOptions[index]}
                  handleSortASC={() => handleSortOption(index, "asc")}
                  handleSortDESC={() => handleSortOption(index, "desc")}
                />
              )}
            </div>
          ))}
      </div>

      <div
        style={
          isFixHeight
            ? {
                height: `calc(${sizeHeightFix} - 50px)`,
              }
            : {
                maxHeight: `calc(100% - ${hideHeaderHeight === undefined ? "50px" : hideHeaderHeight})`,
              }
        }
        className={`${isTableNew ? "whiteHeaderTable-appointment-new-body" : "whiteHeaderTable-body"}  ${isShowBodyBorders && " isShowBodyBorders"} `}
      >
        <div
          className={`tableList ${bordered ? "bordered" : ""}`}
          style={
            isFixHeight
              ? {
                  height: sizeHeightFix === "100%" ? "100%" : `calc(${sizeHeightFix} - 50px)`,
                }
              : { height: "100%", ...(!isHospitalCharting && { minHeight: "15vh" }) }
          }
        >
          {!isNonScrollable ? (
            <ScrollWrapper
              onScrollFrame={onLoadMore}
              style={isFixHeight ? { height: sizeHeightFix } : {}}
              css={
                isICD
                  ? "no-padding-icd x-hidden-icd"
                  : isTableNew
                  ? `no-padding-new-appointment x-hidden-new-appointment ${isHospitalCharting && isTableNew && "hospital_tab_height"}`
                  : "no-padding-plan x-hidden-plan"
              }
            >
              <div
                style={{
                  border: activeTable ? " 2px solid #0f62fe" : "",
                }}
              >
                {rowData &&
                  rowData.length > 0 &&
                  (rowData || []).map((row, index) => {
                    return (
                      <React.Fragment key={`${title}-body-${index}-${row.key}`}>
                        <div
                          className={`tableItem yellow 
                      ${noneAllActive && row?.keyIdPlan == keyCheck && activeRow === index ? "active" : ""}
                      ${!noneAllActive && activeRow === index ? "active" : ""} ${!noneAllActive && allActive ? "active" : ""}`}
                          style={{
                            backgroundColor: activeRow === index ? "#dbe3ee" : "",
                            position: "relative",
                          }}
                          onClick={() => handleRow(index, row)}
                        >
                          {columns &&
                            columns.length > 0 &&
                            columns.map((col, idx) => {
                              if (col.key === "commentBox") {
                                return row[col.key];
                              } else {
                                return (
                                  <div
                                    className={`${isTableNew ? "tableItemField-new-appointment" : "tableItemField"}`}
                                    style={{
                                      width: row.fullsize ? "calc(100% - 40px)" : widths[idx] || `calc(100% / ${labels.length})`,
                                      minWidth: row.fullsize ? "calc(100% - 40px)" : widths[idx] || `calc(100% / ${labels.length})`,
                                    }}
                                    key={`${title}-col-${index}-${idx}`}
                                    onClick={() => onClickColumn && onClickColumn(idx)}
                                  >
                                    <div
                                      className={isBorderRadiusFalse ? renderClass(idx) + " br-none" : renderClass(idx)}
                                      style={{
                                        padding: noColPad ? 0 : col?.noPadding ? 0 : "",
                                      }}
                                    >
                                      {row[col.key]}
                                    </div>
                                    {editable && idx === labels.length - 1 && (
                                      <div
                                        style={{
                                          display: "inline-block",
                                          width: 16,
                                          height: 16,
                                          fontSize: 16,
                                          lineHeight: 16,
                                          position: "absolute",
                                          right: "2.5rem",
                                          top: 10,
                                          zIndex: 5,
                                          cursor: "pointer",
                                        }}
                                        onClick={() => handleEdit && handleEdit(index)}
                                      >
                                        <Icon type="edit" />
                                      </div>
                                    )}
                                    {!disableDelete && idx === labels.length - 1 && !row.disableDelete && (
                                      <DeleteIconButton
                                        id={`delete_${indexItem}_${index}`}
                                        handleClick={() => handleDeleteRow(index, row)}
                                        selected={handleActiveDelete ? activeParent === index : activeDelete === index}
                                        icon={"can"}
                                        extraStyle={{
                                          position: "absolute",
                                          display: "inline-block",
                                          right: "0.75rem",
                                          top: "10px",
                                          cursor: "pointer",
                                        }}
                                      />
                                    )}
                                  </div>
                                );
                              }
                            })}
                        </div>
                        {row.isSide && (
                          <div
                            style={{
                              width: "100%",
                              backgroundColor: "#dbe3ee",
                              paddingLeft: 15,
                              paddingTop: 5,
                              paddingBottom: 5,
                            }}
                          >
                            <div
                              style={{
                                width: "calc(100% - 40px)",
                                minWidth: "calc(100% - 40px)",
                              }}
                            >
                              {row["componentSide"] && row["componentSide"]}
                            </div>
                          </div>
                        )}
                      </React.Fragment>
                    );
                  })}
                {parentHeight &&
                  parentHeight > 0 &&
                  (emptyData || []).map((item, index) => (
                    <div className="tableItem" key={`chart-empty-table-${index}`}>
                      <div
                        className={`${isTableNew ? "tableItemField-new-appointment" : "tableItemField"}`}
                        style={{ width: "100%", minWidth: "100%" }}
                      />
                    </div>
                  ))}
                {!parentHeight &&
                  (emptyRows || []).map((item, index) => (
                    <div className="tableItem" key={`chart-empty-table-${index}`}>
                      <div
                        className={`${isTableNew ? "tableItemField-new-appointment" : "tableItemField"}`}
                        style={{ width: "100%", minWidth: "100%" }}
                      />
                    </div>
                  ))}
              </div>
            </ScrollWrapper>
          ) : (
            <div
              style={{
                border: activeTable ? " 2px solid #0f62fe" : "",
              }}
            >
              {rowData &&
                rowData.length > 0 &&
                (rowData || []).map((row, index) => {
                  return (
                    <React.Fragment key={`${title}-body-${index}-${row.key}`}>
                      <div
                        className={`tableItem yellow 
                        ${isAlignTableItem && "hp_ccl_table"}
                      ${noneAllActive && row?.keyIdPlan == keyCheck && activeRow === index ? "active" : ""}
                      ${!noneAllActive && activeRow === index ? "active" : ""} ${!noneAllActive && allActive ? "active" : ""}`}
                        style={{
                          backgroundColor: activeRow === index ? "#dbe3ee" : "",
                          position: "relative",
                        }}
                        onClick={() => handleRow(index, row)}
                      >
                        {columns &&
                          columns.length > 0 &&
                          columns.map((col, idx) => {
                            if (col.key === "commentBox") {
                              return row[col.key];
                            } else {
                              return (
                                <div
                                  className={`${isTableNew ? "tableItemField-new-appointment" : "tableItemField"}`}
                                  style={{
                                    width: row.fullsize ? "calc(100% - 40px)" : widths[idx] || `calc(100% / ${labels.length})`,
                                    minWidth: row.fullsize ? "calc(100% - 40px)" : widths[idx] || `calc(100% / ${labels.length})`,
                                  }}
                                  key={`${title}-col-${index}-${idx}`}
                                  onClick={() => onClickColumn && onClickColumn(idx)}
                                >
                                  <div className={renderClass(idx)}>{row[col.key]}</div>
                                  {editable && idx === labels.length - 1 && (
                                    <div
                                      style={{
                                        display: "inline-block",
                                        width: 16,
                                        height: 16,
                                        fontSize: 16,
                                        lineHeight: 16,
                                        position: "absolute",
                                        right: "2.5rem",
                                        top: 10,
                                        zIndex: 5,
                                        cursor: "pointer",
                                      }}
                                      onClick={() => handleEdit && handleEdit(index)}
                                    >
                                      <Icon type="edit" />
                                    </div>
                                  )}
                                  {!disableDelete && idx === labels.length - 1 && !row.disableDelete && (
                                    <DeleteIconButton
                                      id={`delete_${indexItem}_${index}`}
                                      handleClick={() => handleDeleteRow(index, row)}
                                      selected={handleActiveDelete ? activeParent === index : activeDelete === index}
                                      icon={"can"}
                                      extraStyle={{
                                        position: "absolute",
                                        display: "inline-block",
                                        right: "0.75rem",
                                        top: "10px",
                                        cursor: "pointer",
                                      }}
                                    />
                                  )}
                                </div>
                              );
                            }
                          })}
                      </div>
                      {row.isSide && (
                        <div
                          style={{
                            width: "100%",
                            backgroundColor: "#dbe3ee",
                            paddingLeft: 15,
                            paddingTop: 5,
                            paddingBottom: 5,
                          }}
                        >
                          <div
                            style={{
                              width: "calc(100% - 40px)",
                              minWidth: "calc(100% - 40px)",
                            }}
                          >
                            {row["componentSide"] && row["componentSide"]}
                          </div>
                        </div>
                      )}
                    </React.Fragment>
                  );
                })}
              {parentHeight &&
                parentHeight > 0 &&
                (emptyData || []).map((item, index) => (
                  <div className="tableItem" key={`chart-empty-table-${index}`}>
                    <div
                      className={`${isTableNew ? "tableItemField-new-appointment" : "tableItemField"}`}
                      style={{ width: "100%", minWidth: "100%" }}
                    />
                  </div>
                ))}
              {!parentHeight &&
                (emptyRows || []).map((item, index) => (
                  <div className="tableItem" key={`chart-empty-table-${index}`}>
                    <div
                      className={`${isTableNew ? "tableItemField-new-appointment" : "tableItemField"}`}
                      style={{ width: "100%", minWidth: "100%" }}
                    />
                  </div>
                ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default WhiteHeaderTable;
