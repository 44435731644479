/* eslint-disable react-hooks/exhaustive-deps */
import { debounce } from "lodash";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Icon } from "antd";
import AutoCompleteSearch from "../../../common/components/AutoComplete/AutoCompleteSearch";
import EditableFormInput from "../../../common/components/EditableText/EditableFormInput";
import Loading from "../../../common/components/Loading";
import { RedRequiredCircleSVG } from "../../../common/components/icons/SVGIcon";
import { newSnapshotOption, stateOptions } from "../../../common/constants/initialData";
import { getLocal } from "../../../helpers/Local";
import {
  addNewPatient as addPatient,
  editDemographicsOfPatient,
  getPrimaryProviderDetailPatient,
  getSinglePharmacy,
} from "../../../services/api.services";
import { validateField, validateSteps } from "../../../services/validations";
import {
  autoZip,
  getPharmacies,
  patientScheduleConfirmCheckin,
  searchPcpNpe,
  setGlobalLoading as setCommonGlobalLoading,
  setGlobalLoading,
  setPatientSnapshotData,
} from "../../../store/actions";
import PusherScan from "../../components/PusherScan";
import ToastMessage from "../../components/Toast";

const { demographics } = newSnapshotOption;

let timer = null;
const Demographics = ({ handleContinue, isProcedureProcessing, ...restProps }) => {
  const dispatch = useDispatch();

  const resource = useSelector((state) => state.patientSnapshot.resource);
  const scanToken = useSelector((state) => state.scanDriverLicense.scanToken);
  const pcpDataRef = useSelector((state) => state.newPatient.pcpDataRef);
  const loadingSearchPCP = useSelector((state) => state.newPatient.loadingSearchPCP);
  const idCardInfo = useSelector((state) => state.scanDriverLicense?.idCardInfo);
  const errorRest = useSelector((state) => state.common.globalErrorMessage);
  const loadingPharmacies = useSelector((state) => state.common.loadingPharmacies);
  const pharmacies = useSelector((state) => state.common.pharmacies);
  const tabActive = useSelector((state) => state.clinicProviderReview.tabActive);
  const zipAutoData = useSelector((state) => state.common.zipAuto);

  const [checkInStatus, setCheckInStatus] = useState(false);
  const [loadingGlobal, setLoadingGlobal] = useState(false);
  const [isToastr, setIsToastr] = useState(false);
  const [patient, setPatient] = useState({});
  const [enableError, setEnableError] = useState(false);
  const [first, setFirst] = useState(false);
  const [qrData, setQRData] = useState();
  const [clickedScanID, setClickedScanID] = useState(false);
  const [localLoading, setLocalLoading] = useState(false);
  const [dataPCPRef, setDataPCPRef] = useState([]);
  const [referData, setReferData] = useState([]);
  const [detailProvider, setDetailProvider] = useState(null);
  const [detailProviderPCP, setDetailProviderPCP] = useState(null);
  const [detailPharmacy, setDetailPharmacy] = useState(null);

  const fectchDetailProvider = async () => {
    const detail = await getPrimaryProviderDetailPatient(patient?.referral_provider);
    setDetailProvider(detail);
    const detailPCP = await getPrimaryProviderDetailPatient(patient?.primary_care_provider_id);
    setDetailProviderPCP(detailPCP);
  };

  const fetchPharmacy = async () => {
    const detailP = await getSinglePharmacy(patient?.pharmacy_id);
    setDetailPharmacy(detailP);
  };

  useEffect(() => {
    if (patient?.primary_care_provider_id || patient?.referral_provider) {
      fectchDetailProvider();
    }
    if (patient?.pharmacy_id) {
      fetchPharmacy();
    }
  }, [patient]);

  useEffect(() => {
    if (restProps?.referralConditions) {
      setReferData(restProps?.referralConditions);
    }
  }, [restProps]);

  useEffect(() => {
    setDataPCPRef(pcpDataRef);
  }, [pcpDataRef]);

  const [reset, setReset] = useState(false);

  useEffect(() => {
    setFirst(true);
  }, []);

  const fetchSearchNew = async () => {
    await dispatch(setGlobalLoading(true));
    await dispatch(searchPcpNpe(1, "a"));
    await dispatch(setGlobalLoading(false));
  };

  useEffect(() => {
    fetchSearchNew();
  }, []);

  // ID card display disable => panamacheng modified.
  const { disableIDImage } = restProps; // remove this row after implementing the parent function for props.

  const procedure_id = getLocal("current_appointment_id");

  useEffect(() => {
    dispatch(setCommonGlobalLoading(loadingGlobal));
  }, [loadingGlobal]);

  useEffect(() => {
    if (resource && resource?.id !== patient?.id) {
      let address = "";
      if (resource.address) {
        const splitedAddress = resource.address.split(" ").map((addr) => {
          if (!addr || !addr.length) {
            return "";
          }
          if (addr.length < 2) {
            return addr.charAt(0).toUpperCase();
          }
          return addr.charAt(0).toUpperCase() + addr.substr(1).toLowerCase();
        });
        address = splitedAddress.join(" ");
      }
      setPatient({
        ...resource,
        address,
        pharmacy_name: resource?.pharmacy_name || (resource?.pharmacy && resource?.pharmacy?.name),
      });
      restProps.setOpenAddForm(false);
      setEnableError(true);
      setTimeout(() => {
        setReset(true);
      }, 1000);
    }
  }, [resource]);

  useEffect(() => {
    if (idCardInfo?.card) {
      setClickedScanID(false);
    } else {
      onClickedScanID();
    }
  }, [idCardInfo, resource, scanToken]);

  const onClickedScanID = () => {
    const inputSignData = {
      type: "input-sign",
      signature_data: {
        patient_id: resource?.id,
        card_type: "drivers_license_card",
        token: scanToken,
      },
    };

    setClickedScanID(true);
    setQRData(inputSignData);
  };

  const handleScanQRCode = () => {
    setClickedScanID(false);
  };

  const onChangePatient = (type) => async (value) => {
    if (type == "zipcode") {
      clearTimeout(timer);
      timer = setTimeout(async () => {
        await dispatch(autoZip(value));
      }, 500);
      const newPatient = {
        ...patient,
        [type]: value,
      };
      setPatient(newPatient);
    } else if (type == "pharmacy_id") {
      let name = "";
      let id = "";
      if (value) {
        const slpitValue = value.split(" - ");
        name = slpitValue[0] || "";
        id = slpitValue[1] || "";
      }
      const newPatient = {
        ...patient,
        [type]: id ? Number(id) : null,
        ["pharmacy_name"]: name,
      };
      dispatch(setPatientSnapshotData("resource", newPatient));
      setPatient(newPatient);
      setEnableError(false);
      if (!restProps.isAddForm) {
        reset && changeHandler(newPatient);
      }
    } else if (type === "referral_provider" || type === "primary_care_provider_id") {
      let name = "";
      let id = "";
      if (value) {
        const slpitValue = value.split(" - ");
        name = slpitValue[0] || "";
        id = slpitValue[1] || "";
      }
      const newPatient = {
        ...patient,
        [`${type}_name`]: name,
        [type]: id,
      };
      dispatch(setPatientSnapshotData("resource", newPatient));
      setPatient(newPatient);
      setEnableError(false);
      if (!restProps.isAddForm) {
        reset && changeHandler(newPatient);
      }
    } else {
      const newPatient = {
        ...patient,
        [type]: value,
      };
      setPatient(newPatient);
      setEnableError(false);
      if (!restProps.isAddForm) {
        reset && changeHandler(newPatient);
      }
    }
  };

  useEffect(() => {
    if (zipAutoData && Object.keys(zipAutoData).length > 0 && first) {
      if (zipAutoData.success) {
        if (zipAutoData.addresses && zipAutoData.addresses[0]) {
          const address = zipAutoData.addresses[0];
          const param = {
            ...patient,
            state: address.state_id,
            city: address.place,
            pharmacy_name: patient?.pharmacy_name || (patient?.pharmacy && patient?.pharmacy?.name),
          };
          setPatient(param);
          updatePatient(param);
          setReset(true);
        }
      } else {
        const param = {
          ...patient,
          state: undefined,
          city: undefined,
          pharmacy_name: patient?.pharmacy_name || (patient?.pharmacy && patient?.pharmacy?.name),
        };
        setPatient(param);
        updatePatient(param);
        setReset(true);
      }
    }
  }, [zipAutoData]);

  const onChangeAddress = (type) => (value) => {
    let address = "";
    if (value) {
      const splitedAddress = value.split(" ").map((addr) => {
        if (!addr || !addr.length) {
          return "";
        }
        if (addr.length < 2) {
          return addr.charAt(0).toUpperCase();
        }
        return addr.charAt(0).toUpperCase() + addr.substr(1).toLowerCase();
      });
      address = splitedAddress.join(" ");
    }

    const newPatient = {
      ...patient,
      [type]: address,
      pharmacy_name: patient?.pharmacy_name || (patient?.pharmacy && patient?.pharmacy?.name),
    };
    setPatient(newPatient);
    setEnableError(false);
    if (!restProps.isAddForm) {
      reset && changeHandler(newPatient);
    }
  };

  const checkIn = () => {
    setCheckInStatus(true);
    dispatch(patientScheduleConfirmCheckin(procedure_id));
    restProps.refreshCalendar();
  };

  const addNewPatient = async () => {
    if (!validateSteps(patient, demographics)) {
      setEnableError(true);
      return;
    }
    setLoadingGlobal(true);
    try {
      const result = await addPatient(patient);
      if (result) {
        restProps.setOpenAddForm(false);
        setPatient(result?.data && result?.data?.data);
        await dispatch(setPatientSnapshotData("resource", result?.data && result?.data?.data));
        setLoadingGlobal(false);

        setIsToastr(true);
        setTimeout(() => {
          setIsToastr(false);
        }, 2000);
      } else {
        alert("please try again");
      }
    } catch (error) {
      restProps.setOpenAddForm(false);
      setPatient(resource);
      setLoadingGlobal(false);
    }
  };

  const updatePatient = async (patient) => {
    const updatedPatient = { ...patient };
    updatedPatient["date_of_birth"] = patient["date_of_birth"] && moment(patient["date_of_birth"]).format("MM/DD/YYYY");
    updatedPatient["referral_date"] = patient.referral_date && moment(patient.referral_date).format("MM/DD/YYYY");
    updatedPatient["primary_care_provider_id"] = patient["primary_care_provider_id"] || patient.pcp;
    updatedPatient["pharmacy_name"] = patient?.pharmacy_name;
    updatedPatient["pharmacy_id"] = patient?.pharmacy_id;
    setLoadingGlobal(true);
    try {
      await editDemographicsOfPatient(updatedPatient);
      restProps.setOpenAddForm(false);
      await dispatch(setPatientSnapshotData("resource", updatedPatient));
      restProps.refreshCalendar();
      setIsToastr(true);
      setLoadingGlobal(false);
      setTimeout(() => {
        setIsToastr(false);
      }, 2000);
    } catch (error) {
      restProps.setOpenAddForm(false);
      setLoadingGlobal(false);
    }
  };

  const changeHandler = useCallback(
    debounce((patient) => updatePatient(patient), 1000),
    []
  );

  const showToast = () => {
    return <ToastMessage type="New Patient" status={"success"} msg={"New patient is added successfully"} />;
  };

  const cancelAddForm = () => {
    restProps.setOpenAddForm(false);
    setEnableError(true);
    setPatient({
      ...resource,
      pharmacy_name: resource?.pharmacy_name || (resource?.pharmacy && resource?.pharmacy?.name),
    });
  };

  const searchHandle = async (value) => {
    setLocalLoading(true);
    dispatch(setGlobalLoading(true));
    clearTimeout(timer);
    timer = setTimeout(async () => {
      await dispatch(getPharmacies(value));
      setLocalLoading(false);
      dispatch(setGlobalLoading(false));
    }, 500);
  };

  const fetchSearch = async (value) => {
    setLocalLoading(true);
    clearTimeout(timer);
    timer = setTimeout(async () => {
      const getDetailSuccess = await dispatch(searchPcpNpe(1, value));
      if (getDetailSuccess) {
        setLocalLoading(false);
      }
    }, 500);
  };

  return (
    <div className="resourceContent pr-7px" style={{ position: "relative" }}>
      {isToastr && !errorRest && showToast()}
      {!restProps.isAddForm && (
        <React.Fragment>
          <div className="resourceInfo d-flex required-field">
            <EditableFormInput
              isErrorTextSpacing={true}
              label={"First Name"}
              value={patient?.first_name}
              handleChange={onChangePatient("first_name")}
              required={true}
              isError={validateField("text", patient?.first_name)}
              enableErrorText={enableError}
              helperText={"First Name is Required!"}
            />
          </div>
          <div className="resourceInfo d-flex required-field">
            <EditableFormInput
              isErrorTextSpacing={true}
              label={"Last Name"}
              value={patient?.last_name}
              handleChange={onChangePatient("last_name")}
              required={true}
              isError={validateField("text", patient?.last_name)}
              enableErrorText={enableError}
              helperText={"Last Name is Required!"}
            />
          </div>
          <div className="resourceInfo d-flex">
            <EditableFormInput
              isErrorTextSpacing={true}
              label={"Deceased"}
              handleChange={onChangePatient("deceased")}
              value={patient.deceased}
              type={"check"}
              required={true}
              isError={validateField("text", patient.deceased)}
              enableErrorText={enableError}
              helperText={"Deceased is Required!"}
              optionKey={"value"}
              valueKey={"name"}
              options={[
                { value: "yes", name: "Yes" },
                { value: "no", name: "No" },
              ]}
            />
          </div>
          <div className="resourceInfo d-flex required-field">
            <EditableFormInput
              isErrorTextSpacing={true}
              label={"DOB"}
              value={patient.date_of_birth}
              handleChange={onChangePatient("date_of_birth")}
              required={true}
              type="date"
              isError={validateField("date", patient.date_of_birth)}
              enableErrorText={enableError}
              helperText={"Birthday should be MM/DD/YYYY!"}
            />
          </div>
          <div className="resourceInfo d-flex">
            <EditableFormInput
              isErrorTextSpacing={true}
              label={"Gender"}
              handleChange={onChangePatient("gender")}
              value={patient.gender}
              required={true}
              isError={validateField("text", patient.gender)}
              enableErrorText={enableError}
              helperText={"Gender is Required!"}
              type={"check"}
              optionKey={"value"}
              valueKey={"name"}
              options={[
                { value: "male", name: "Male" },
                { value: "female", name: "Female" },
              ]}
            />
          </div>
          <div className="resourceInfo d-flex">
            <EditableFormInput
              isErrorTextSpacing={true}
              label={"Language"}
              handleChange={onChangePatient("preferred_language")}
              value={patient.preferred_language}
              type={"check"}
              optionKey={"value"}
              valueKey={"name"}
              options={[
                { value: "English", name: "English" },
                { value: "Spanish", name: "Spanish" },
                { value: "Other", name: "Other" },
              ]}
            />
          </div>
          <div className="resourceInfo d-flex">
            <EditableFormInput
              isErrorTextSpacing={true}
              label={"Marital Status"}
              handleChange={onChangePatient("marital_status")}
              value={patient.marital_status}
              type={"check"}
              optionKey={"value"}
              valueKey={"name"}
              options={[
                { value: "Married", name: "Married" },
                { value: "Single", name: "Single" },
                { value: "Divorced", name: "Divorced" },
                { value: "Widowed", name: "Widowed" },
              ]}
            />
          </div>
          <div className="resourceInfo d-flex">
            <EditableFormInput
              isErrorTextSpacing={true}
              label={"Ethnicity"}
              handleChange={onChangePatient("ethnicity")}
              value={patient.ethnicity}
              type={"check"}
              optionKey={"value"}
              valueKey={"name"}
              options={[
                { value: "White", name: "White" },
                { value: "Hispanic", name: "Hispanic" },
                {
                  value: "Not Hispanic or Latino",
                  name: "Not Hispanic or Latino",
                },
                { value: "Other", name: "Other" },
              ]}
            />
          </div>
          <div className="resourceInfo d-flex required-field">
            <EditableFormInput
              isErrorTextSpacing={true}
              label={"Phone No"}
              value={patient.phone}
              handleChange={onChangePatient("phone")}
              required={true}
              isError={validateField("phone", patient.phone)}
              enableErrorText={enableError}
              helperText={"Phone Number should be (XXX) XXX-XXXX format"}
              mask={"phone"}
            />
          </div>
          <div className="resourceInfo d-flex">
            <EditableFormInput
              isErrorTextSpacing={true}
              label={"Phone No"}
              value={patient.secondary_phone}
              handleChange={onChangePatient("secondary_phone")}
              mask={"phone"}
            />
          </div>
          <div className="resourceInfo d-flex required-field">
            <EditableFormInput isErrorTextSpacing={true} label={"E-Mail"} value={patient.email} handleChange={onChangePatient("email")} />
          </div>
          <div className="resourceInfo d-flex required-field">
            <EditableFormInput
              isErrorTextSpacing={true}
              label={"Address"}
              value={patient.address}
              handleChange={onChangeAddress("address")}
              required={true}
              isError={validateField("text", patient.address)}
              enableErrorText={enableError}
              helperText={"Address is required"}
            />
          </div>
          <div className="resourceInfo d-flex">
            <EditableFormInput isErrorTextSpacing={true} label={"Apt"} value={patient.apt_number} handleChange={onChangePatient("apt_number")} />
          </div>
          <div className="resourceInfo d-flex required-field">
            <EditableFormInput
              isErrorTextSpacing={true}
              label={"Zip Code"}
              value={patient.zipcode}
              handleChange={onChangePatient("zipcode")}
              required={true}
              isError={validateField("text", patient.zipcode)}
              enableErrorText={enableError}
              helperText={"Zip Code is required"}
            />
          </div>
          <div className="resourceInfo d-flex required-field">
            <EditableFormInput
              isErrorTextSpacing={true}
              label={"State"}
              value={patient.state || undefined}
              handleChange={onChangePatient("state")}
              type={"check"}
              valueKey={"value"}
              optionKey={"id"}
              options={stateOptions || []}
              required={true}
              isError={validateField("text", patient.state)}
              enableErrorText={enableError}
              helperText={"State is required"}
            />
          </div>
          <div className="resourceInfo d-flex required-field">
            <EditableFormInput
              isErrorTextSpacing={true}
              label={"City"}
              value={patient.city}
              handleChange={onChangePatient("city")}
              required={true}
              isError={validateField("text", patient.city)}
              enableErrorText={enableError}
              helperText={"City is required"}
            />
          </div>
          <hr />
          <div className="resourceInfo d-flex required-field">
            <EditableFormInput
              isErrorTextSpacing={true}
              label={"Diagnosis"}
              value={patient.referral_condition || undefined}
              handleChange={onChangePatient("referral_condition")}
              required={true}
              type={"check"}
              isError={validateField("text", patient.referral_condition)}
              optionKey={"value"}
              valueKey={"value"}
              options={referData || []}
              enableErrorText={enableError}
              helperText={"Diagnosis is required"}
            />
          </div>
          {patient.referral_condition === "Other" && (
            <div className="resourceInfo d-flex required-field">
              <EditableFormInput
                isErrorTextSpacing={true}
                label={"Diagnosis Specifics"}
                value={patient.diagnosis_specifics}
                handleChange={onChangePatient("diagnosis_specifics")}
              />
            </div>
          )}
          <div className="resourceInfo d-flex required-field ins_info isErrorTextSpacing">
            <AutoCompleteSearch
              options={!localLoading && !loadingSearchPCP ? dataPCPRef || [] : []}
              value={patient?.referral_provider && patient?.referral_provider_name ? `${patient?.referral_provider_name}` : undefined}
              onClear={onChangePatient("referral_provider")}
              handlePopulate={onChangePatient("referral_provider")}
              handleSearch={(val) => fetchSearch(val)}
              title="referring"
              placeholder="Referring"
              actionLabel="Search"
              keyLabel="provider_name_suffix_address"
              optionKey="id"
              backgroundWhite
              isModified
              disableSearchIcon={patient?.referral_provider && patient?.referral_provider_name ? true : false}
              customStyle={patient?.referral_provider && patient?.referral_provider_name ? true : false}
            />
            {(!patient?.referral_provider || !patient?.referral_provider_name) && (
              <p className="alert_message">
                <span>
                  <RedRequiredCircleSVG /> Search using First Name, Last Name, State
                </span>
              </p>
            )}
            <span
              style={{
                width: 18,
                position: "absolute",
                left: 415,
                height: "2.5rem",
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={() => restProps.setProviderAddForm(true)}
            >
              <Icon type="plus-square" theme="twoTone" twoToneColor="#0f62fe" className="pharmacy-icon" />
            </span>
            {detailProvider?.id && (
              <span
                style={{
                  width: 18,
                  position: "absolute",
                  left: 440,
                  height: "2.5rem",
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={() => restProps.setProviderEditForm(true, detailProvider)}
              >
                <Icon type="eye" theme="twoTone" twoToneColor="#0f62fe" className="pharmacy-icon" />
              </span>
            )}
          </div>
          <div className="resourceInfo d-flex required-field ins_info isErrorTextSpacing">
            <AutoCompleteSearch
              options={!localLoading && !loadingSearchPCP ? dataPCPRef || [] : []}
              value={
                patient?.primary_care_provider_id && patient?.primary_care_provider_id_name ? `${patient?.primary_care_provider_id_name}` : undefined
              }
              onClear={onChangePatient("primary_care_provider_id")}
              handlePopulate={onChangePatient("primary_care_provider_id")}
              handleSearch={(val) => fetchSearch(val)}
              title="primary-care-provider"
              placeholder="PCP"
              actionLabel="Search"
              keyLabel="provider_name_suffix_address"
              optionKey="id"
              backgroundWhite
              isModified
              disableSearchIcon={patient?.primary_care_provider_id && patient?.primary_care_provider_id_name ? true : false}
              customStyle={patient?.primary_care_provider_id && patient?.primary_care_provider_id_name ? true : false}
            />
            {(!patient?.primary_care_provider_id || !patient?.primary_care_provider_id_name) && (
              <p className="alert_message">
                <span>
                  <RedRequiredCircleSVG /> Search using First Name, Last Name, State
                </span>
              </p>
            )}
            <span
              style={{
                width: 18,
                position: "absolute",
                left: 415,
                height: "2.5rem",
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={() => restProps.setProviderAddForm(true)}
            >
              <Icon type="plus-square" theme="twoTone" twoToneColor="#0f62fe" className="pharmacy-icon" />
            </span>
            {detailProviderPCP?.id && (
              <span
                style={{
                  width: 18,
                  position: "absolute",
                  left: 440,
                  height: "2.5rem",
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={() => restProps.setProviderEditForm(true, detailProviderPCP)}
              >
                <Icon type="eye" theme="twoTone" twoToneColor="#0f62fe" className="pharmacy-icon" />
              </span>
            )}
          </div>

          <div className="resourceInfo d-flex required-field">
            <EditableFormInput
              isErrorTextSpacing={true}
              label={"Referral Date"}
              value={patient.referral_date}
              type="date"
              handleChange={onChangePatient("referral_date")}
              required={true}
              isError={validateField("text", patient.referral_date)}
              enableErrorText={enableError}
              helperText={"Referral Date is required"}
            />
          </div>
          <div className="resourceInfo d-flex isErrorTextSpacing ">
            <AutoCompleteSearch
              options={!localLoading && !loadingPharmacies ? pharmacies || [] : []}
              value={patient?.pharmacy_name && patient?.pharmacy_id ? `${patient?.pharmacy_name} - ${patient?.pharmacy_id}` : undefined}
              onClose={onChangePatient("pharmacy_id")}
              handlePopulate={onChangePatient("pharmacy_id")}
              handleSearch={(val) => searchHandle(val)}
              title="pharmacy"
              placeholder="Pharmacy"
              actionLabel="Search"
              keyLabel="name"
              optionKey="id"
              backgroundWhite
              isModified
              disableSearchIcon={patient?.pharmacy_name && patient?.pharmacy_id ? true : false}
              customStyle={patient?.pharmacy_name && patient?.pharmacy_id ? true : false}
            />
            <span
              style={{
                width: 18,
                position: "absolute",
                left: 415,
                height: "2.5rem",
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={() => restProps.setPharmacyAddForm && restProps.setPharmacyAddForm(true)}
            >
              <Icon type="plus-square" theme="twoTone" twoToneColor="#0f62fe" className="pharmacy-icon" />
            </span>
            {detailPharmacy?.id && (
              <span
                style={{
                  width: 18,
                  position: "absolute",
                  left: 440,
                  height: "2.5rem",
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={() => restProps.setPharmacyEditForm && restProps.setPharmacyEditForm(true, detailPharmacy)}
              >
                <Icon type="eye" theme="twoTone" twoToneColor="#0f62fe" className="pharmacy-icon" />
              </span>
            )}
          </div>
          <div className="resourceInfo d-flex">
            <EditableFormInput
              isErrorTextSpacing={true}
              label={"Preferred Provider"}
              value={patient.preferred_provider || undefined}
              handleChange={onChangePatient("preferred_provider")}
              type={"check"}
              optionKey={"id"}
              valueKey={"value"}
              options={restProps.providers || []}
            />
          </div>
          <div className="resourceInfo d-flex required-field">
            <EditableFormInput
              isErrorTextSpacing={true}
              label={"Advance Directives"}
              value={patient.advanced_directive || undefined}
              handleChange={onChangePatient("advanced_directive")}
              required={true}
              type={"check"}
              isError={validateField("text", patient.advanced_directive)}
              optionKey={"value"}
              valueKey={"value"}
              options={[{ value: "No Advance Directives On File" }, { value: "Advance Directives On File" }]}
              enableErrorText={tabActive ? true : false}
              helperText={"Advance Directives"}
            />
          </div>

          {!!restProps.isScan && (
            <>
              {!disableIDImage && (
                <React.Fragment>
                  <hr />
                  {resource?.id === undefined ? (
                    <Loading />
                  ) : (
                    <PusherScan
                      signType="input-sign" // define component type
                      scanData={resource} // define initial data.
                      cardType="drivers_license_card"
                      handleScanQRCode={handleScanQRCode}
                      isQRcode={clickedScanID}
                      qrData={qrData}
                    />
                  )}
                  <hr />
                </React.Fragment>
              )}
            </>
          )}
        </React.Fragment>
      )}
      {restProps.isAddForm && (
        <React.Fragment>
          <div className="resourceInfo d-flex required-field">
            <EditableFormInput
              isErrorTextSpacing={true}
              label={"First Name"}
              value={patient?.first_name}
              handleChange={onChangePatient("first_name")}
              required={true}
              isError={validateField("text", patient?.first_name)}
              enableErrorText={enableError}
              helperText={"First Name is Required!"}
            />
          </div>
          <div className="resourceInfo d-flex required-field">
            <EditableFormInput
              isErrorTextSpacing={true}
              label={"Last Name"}
              value={patient?.last_name}
              handleChange={onChangePatient("last_name")}
              required={true}
              isError={validateField("text", patient?.last_name)}
              enableErrorText={enableError}
              helperText={"Last Name is Required!"}
            />
          </div>
          <div className="resourceInfo d-flex required-field">
            <EditableFormInput
              isErrorTextSpacing={true}
              label={"Deceased"}
              handleChange={onChangePatient("deceased")}
              value={patient?.deceased}
              type={"check"}
              required={true}
              isError={validateField("text", patient.deceased)}
              enableErrorText={enableError}
              helperText={"Deceased is Required!"}
              optionKey={"value"}
              valueKey={"name"}
              options={[
                { value: "yes", name: "Yes" },
                { value: "no", name: "No" },
              ]}
            />
          </div>
          <div className="resourceInfo d-flex required-field">
            <EditableFormInput
              isErrorTextSpacing={true}
              label={"DOB"}
              value={patient.date_of_birth}
              handleChange={onChangePatient("date_of_birth")}
              required={true}
              type="date"
              isError={validateField("date", patient.date_of_birth)}
              enableErrorText={enableError}
              helperText={"Birthday should be MM/DD/YYYY!"}
            />
          </div>
          <div className="resourceInfo d-flex">
            <EditableFormInput
              isErrorTextSpacing={true}
              label={"Gender"}
              handleChange={onChangePatient("gender")}
              value={patient.gender}
              required={true}
              isError={validateField("text", patient.gender)}
              enableErrorText={enableError}
              helperText={"Gender is Required!"}
              type={"check"}
              optionKey={"value"}
              valueKey={"name"}
              options={[
                { value: "male", name: "Male" },
                { value: "female", name: "Female" },
              ]}
            />
          </div>
          <div className="resourceInfo d-flex">
            <EditableFormInput
              isErrorTextSpacing={true}
              label={"Language"}
              handleChange={onChangePatient("preferred_language")}
              value={patient.preferred_language}
              type={"check"}
              optionKey={"value"}
              valueKey={"name"}
              options={[
                { value: "English", name: "English" },
                { value: "Spanish", name: "Spanish" },
                { value: "Other", name: "Other" },
              ]}
            />
          </div>
          <div className="resourceInfo d-flex">
            <EditableFormInput
              isErrorTextSpacing={true}
              label={"Marital Status"}
              handleChange={onChangePatient("marital_status")}
              value={patient.marital_status}
              type={"check"}
              optionKey={"value"}
              valueKey={"name"}
              options={[
                { value: "Married", name: "Married" },
                { value: "Single", name: "Single" },
                { value: "Divorced", name: "Divorced" },
                { value: "Widowed", name: "Widowed" },
              ]}
            />
          </div>
          <div className="resourceInfo d-flex">
            <EditableFormInput
              isErrorTextSpacing={true}
              label={"Ethnicity"}
              handleChange={onChangePatient("ethnicity")}
              value={patient.ethnicity}
              type={"check"}
              optionKey={"value"}
              valueKey={"name"}
              options={[
                { value: "White", name: "White" },
                { value: "Hispanic", name: "Hispanic" },
                {
                  value: "Not Hispanic or Latino",
                  name: "Not Hispanic or Latino",
                },
                { value: "Other", name: "Other" },
              ]}
            />
          </div>
          <div className="resourceInfo d-flex required-field">
            <EditableFormInput
              isErrorTextSpacing={true}
              label={"Phone No"}
              value={patient.phone}
              handleChange={onChangePatient("phone")}
              required={true}
              isError={validateField("phone", patient.phone)}
              enableErrorText={enableError}
              helperText={"Phone Number should be (XXX) XXX-XXXX format"}
              mask={"phone"}
            />
          </div>
          <div className="resourceInfo d-flex">
            <EditableFormInput
              isErrorTextSpacing={true}
              label={"Phone No"}
              value={patient.secondary_phone}
              handleChange={onChangePatient("secondary_phone")}
              mask={"phone"}
            />
          </div>
          <div className="resourceInfo d-flex required-field">
            <EditableFormInput isErrorTextSpacing={true} label={"E-Mail"} value={patient.email} handleChange={onChangePatient("email")} />
          </div>
          <div className="resourceInfo d-flex required-field">
            <EditableFormInput
              isErrorTextSpacing={true}
              label={"Address"}
              value={patient.address}
              handleChange={onChangeAddress("address")}
              required={true}
              isError={validateField("text", patient.address)}
              enableErrorText={enableError}
              helperText={"Address is required"}
            />
          </div>
          <div className="resourceInfo d-flex">
            <EditableFormInput isErrorTextSpacing={true} label={"Apt"} value={patient.apt_number} handleChange={onChangePatient("apt_number")} />
          </div>
          <div className="resourceInfo d-flex required-field">
            <EditableFormInput
              isErrorTextSpacing={true}
              label={"Zip Code"}
              value={patient.zipcode}
              handleChange={onChangePatient("zipcode")}
              required={true}
              isError={validateField("text", patient.zipcode)}
              enableErrorText={enableError}
              helperText={"Zip Code is required"}
            />
          </div>
          <div className="resourceInfo d-flex required-field">
            <EditableFormInput
              isErrorTextSpacing={true}
              label={"State"}
              value={patient.state}
              handleChange={onChangePatient("state")}
              required={true}
              isError={validateField("text", patient.state)}
              enableErrorText={enableError}
              helperText={"State is required"}
            />
          </div>
          <div className="resourceInfo d-flex required-field">
            <EditableFormInput
              isErrorTextSpacing={true}
              label={"City"}
              value={patient.city}
              handleChange={onChangePatient("city")}
              required={true}
              isError={validateField("text", patient.city)}
              enableErrorText={enableError}
              helperText={"City is required"}
            />
          </div>
          <hr />
          <div className="resourceInfo d-flex required-field">
            <EditableFormInput
              isErrorTextSpacing={true}
              label={"Diagnosis"}
              value={patient.referral_condition || undefined}
              handleChange={onChangePatient("referral_condition")}
              required={true}
              type={"check"}
              isError={validateField("text", patient.referral_condition)}
              optionKey={"value"}
              valueKey={"value"}
              options={referData || []}
              enableErrorText={enableError}
              helperText={"Diagnosis is required"}
            />
          </div>
          {patient.referral_condition === "Other" && (
            <div className="resourceInfo d-flex required-field">
              <EditableFormInput
                isErrorTextSpacing={true}
                label={"Diagnosis Specifics"}
                value={patient.diagnosis_specifics}
                handleChange={onChangePatient("diagnosis_specifics")}
              />
            </div>
          )}
          <div className="resourceInfo d-flex required-field ins_info isErrorTextSpacing ">
            <AutoCompleteSearch
              options={!localLoading && !loadingSearchPCP ? dataPCPRef || [] : []}
              value={patient?.referral_provider && patient?.referral_provider_name ? `${patient?.referral_provider_name}` : undefined}
              onClear={onChangePatient("referral_provider")}
              handlePopulate={onChangePatient("referral_provider")}
              handleSearch={(val) => fetchSearch(val)}
              title="referring"
              placeholder="Referring"
              actionLabel="Search"
              keyLabel="provider_name_suffix_address"
              optionKey="id"
              backgroundWhite
              isModified
            />
            {(!patient?.referral_provider || !patient?.referral_provider_name) && (
              <p className="alert_message">
                <span>
                  <RedRequiredCircleSVG /> Search using First Name, Last Name, State
                </span>
              </p>
            )}
            <span
              style={{
                width: 18,
                position: "absolute",
                left: 415,
                height: "2.5rem",
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={() => restProps.setProviderAddForm(true)}
            >
              <Icon type="plus-square" theme="twoTone" twoToneColor="#0f62fe" className="pharmacy-icon" />
            </span>
          </div>
          <div className="resourceInfo d-flex required-field ins_info isErrorTextSpacing ">
            <AutoCompleteSearch
              options={!localLoading && !loadingSearchPCP ? dataPCPRef || [] : []}
              value={
                patient?.primary_care_provider_id && patient?.primary_care_provider_id_name ? `${patient?.primary_care_provider_id_name}` : undefined
              }
              onClear={onChangePatient("primary_care_provider_id")}
              handlePopulate={onChangePatient("primary_care_provider_id")}
              handleSearch={(val) => fetchSearch(val)}
              title="primary-care-provider"
              placeholder="PCP"
              actionLabel="Search"
              keyLabel="provider_name_suffix_address"
              optionKey="id"
              backgroundWhite
              isModified
            />
            {(!patient?.primary_care_provider_id || !patient?.primary_care_provider_id_name) && (
              <p className="alert_message">
                <span>
                  <RedRequiredCircleSVG /> Search using First Name, Last Name, State
                </span>
              </p>
            )}
            <span
              style={{
                width: 18,
                position: "absolute",
                left: 415,
                height: "2.5rem",
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={() => restProps.setProviderAddForm(true)}
            >
              <Icon type="plus-square" theme="twoTone" twoToneColor="#0f62fe" className="pharmacy-icon" />
            </span>
          </div>

          <div className="resourceInfo d-flex required-field">
            <EditableFormInput
              isErrorTextSpacing={true}
              label={"Referral Date"}
              value={patient.referral_date}
              type="date"
              handleChange={onChangePatient("referral_date")}
              required={true}
              isError={validateField("text", patient.referral_date)}
              enableErrorText={enableError}
              helperText={"Referral Date is required"}
            />
          </div>
          <div className="resourceInfo d-flex isErrorTextSpacing ">
            <AutoCompleteSearch
              options={!localLoading && !loadingPharmacies ? pharmacies || [] : []}
              value={patient?.pharmacy_name && patient?.pharmacy_id ? `${patient?.pharmacy_name} - ${patient?.pharmacy_id}` : undefined}
              onClose={onChangePatient("pharmacy_id")}
              handlePopulate={onChangePatient("pharmacy_id")}
              handleSearch={(val) => searchHandle(val)}
              title="pharmacy"
              placeholder="Pharmacy"
              actionLabel="Search"
              keyLabel="name"
              optionKey="id"
              backgroundWhite
              isModified
            />
            <span
              style={{
                width: 18,
                position: "absolute",
                left: 415,
                height: "2.5rem",
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={() => restProps.setPharmacyAddForm && restProps.setPharmacyAddForm(true)}
            >
              <Icon type="plus-square" theme="twoTone" twoToneColor="#0f62fe" className="pharmacy-icon" />
            </span>
            <span
              style={{
                width: 18,
                position: "absolute",
                left: 440,
                height: "2.5rem",
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={() => restProps.setPharmacyEditForm(true, "TODO")}
            >
              <Icon type="eye" theme="twoTone" twoToneColor="#0f62fe" className="pharmacy-icon" />
            </span>
          </div>
          <div className="resourceInfo d-flex">
            <EditableFormInput
              isErrorTextSpacing={true}
              label={"Preferred Provider"}
              value={patient.preferred_provider || undefined}
              handleChange={onChangePatient("preferred_provider")}
              type={"check"}
              optionKey={"id"}
              valueKey={"value"}
              options={restProps.providers || []}
            />
          </div>
          <div className="resourceInfo d-flex required-field">
            <EditableFormInput
              isErrorTextSpacing={true}
              label={"Advance Directives"}
              value={patient.advanced_directive || undefined}
              handleChange={onChangePatient("advanced_directive")}
              required={true}
              type={"check"}
              isError={validateField("text", patient.advanced_directive)}
              optionKey={"value"}
              valueKey={"value"}
              options={[{ value: "No Advance Directives On File" }, { value: "Advance Directives On File" }]}
              enableErrorText={tabActive ? true : false}
              helperText={"Advance Directives"}
            />
          </div>
        </React.Fragment>
      )}

      {!restProps.isAddForm && (
        <div className="actionContent" style={{ flexWrap: "wrap" }}>
          {restProps.canCheckIn && (
            <>
              <button
                className={checkInStatus ? "outlined-btn" : "common-btn"}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginLeft: "0.5rem",
                  marginBottom: "0.5rem",
                }}
                onClick={checkIn}
              >
                <span>Check In</span>
                <span>+</span>
              </button>
            </>
          )}
        </div>
      )}
      {restProps.isAddForm && (
        <div className="actionContent">
          <button className="common-btn outlined-btn" style={{ marginRight: "0.5rem" }} onClick={cancelAddForm}>
            Back
          </button>
          <button className="common-btn" onClick={addNewPatient}>
            Save
          </button>
        </div>
      )}
    </div>
  );
};

export default Demographics;
