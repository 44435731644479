import { Input } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import {
  SearchSVG
} from "../../../../common/components/icons/SVGIcon";
import Loading from "../../../../common/components/Loading";
import ScrollWrapper from "../../../../common/components/ScrollBar";
import SidebarExtension from "../../../../common/components/SidebarExtension";
import { useWindowSize } from "../../../../common/utils";
import KnowledgeBaseSidebar from "./Sidebar";

const { Search } = Input;

const KnowledgeBase = ({ data, title, loading }) => {
  const [isSidebar, setIsSidebar] = useState(false);
  const ref = useRef(null);
  const [activeIndex, setRow] = useState(-1);
  const [initialSize, setSize] = useState({
    width: undefined,
    height: undefined,
  });
  const tableRows = [];
  let emptyRows = [];
  const size = useWindowSize(ref);
  const currentUserInfo = useSelector((state) => state.common.currentUserInfo);

  const handleSidebar = () => {
    setIsSidebar(true);
  };

  const handleCloseSidebar = () => {
    setIsSidebar(false);
  };

  const toggleSidebar = () => {
    setIsSidebar(!isSidebar);
  };

  const handleRow = (index) => () => {
    setRow(index);
  };


  useEffect(() => {
    handleSidebar();
    if (ref) {
      const initialSize = {
        width: ref.current.clientWidth,
        height: ref.current.clientHeight,
      };
      setSize(initialSize);
    }
  }, [ref]);

  let rowCount = 0;
  if (!size.height && !initialSize.height) {
    rowCount = tableRows.length;
  }
  if (size.height) {
    rowCount = Math.round(size.height / (16 * 2.5));
  } else {
    rowCount = Math.round(initialSize.height / (16 * 2.5));
  }

  if (rowCount > tableRows.length) {
    emptyRows = new Array(rowCount - tableRows.length).fill({});
  }
  const sidebarRef = useRef();
  return (
    <div className="clinicWorkflow__main-section">
      <div className="eventsContainer has-sub-header">
        <div className="fullContainer pt-2">
          <div className="filterContainer">
            <div className="searchFieldContainer">
              <div
                className="searchField"
                style={{ justifyContent: 'flex-end' }}
              >
                <Search
                  placeholder="Search Patient"
                  onChange={(e) => console.log(e.target?.value)}
                  style={{ width: '100%', maxWidth: '560px' }}
                  suffix={<SearchSVG />}
                />
              </div>
            </div>
          </div>

          <div className="tableHeader">
            <div className="tableItem">
              <div
                className="th"
                style={{ width: 'calc(22% - 2rem)', marginLeft: '2rem' }}
              >
                <b>Date</b>
              </div>
              <div className="th" style={{ width: '18%' }}>
                <b>ID</b>
              </div>
              <div className="th" style={{ width: '30%' }}>
                <b>Subject</b>
              </div>
              <div className="th" style={{ width: '30%' }}>
                <b>Programmer Comments</b>
              </div>
            </div>
          </div>
          <div className="tableSection">
            <div ref={ref} className="tableContainer">
              {loading && <Loading />}
              <ScrollWrapper css="no-padding">
                <div className="tableList">
                  {tableRows.map((row, index) => (
                    <div
                      className={`tableItem yellow ${activeIndex === index ? 'active' : ''
                        }`}
                      key={`resultTable-${title}-${index}`}
                      onClick={handleRow(index)}
                    >
                      <div className="td with-icon" style={{ width: '22%' }}>
                        {row?.icon && row?.icon}
                        <p>{row?.date}</p>
                      </div>
                      <div className="td" style={{ width: '18%' }}>
                        <p>{row?.id}</p>
                      </div>
                      <div className="td" style={{ width: '30%' }}>
                        <p>{row?.subject}</p>
                      </div>
                      <div className="td" style={{ width: '30%' }}>
                        <p>{row?.comments}</p>
                      </div>
                    </div>
                  ))}
                  {emptyRows.map((row, index) => (
                    <div
                      className={`tableItem empty`}
                      key={`resultTable-empty-${title}-${index}`}
                    >
                      <div className="td" style={{ width: '22%' }} />
                      <div className="td" style={{ width: '18%' }} />
                      <div className="td" style={{ width: '30%' }} />
                      <div className="td" style={{ width: '30%' }} />
                    </div>
                  ))}
                </div>
              </ScrollWrapper>
            </div>
          </div>
        </div>
      </div>
      <KnowledgeBaseSidebar
        ref={sidebarRef}
        currentUserInfo={currentUserInfo}
        isShow={isSidebar}
        handleCloseSidebar={handleCloseSidebar}
      />

      <SidebarExtension
        startingWidth="calc(28.3% - 20px + 0.3rem)"
        sidebarReference={sidebarRef}
        sidebarToggle={toggleSidebar}
        sidebarState={isSidebar}
      />
    </div>
  );
};

export default KnowledgeBase;
