import { Collapse, Icon } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ReplaceNN from '../../../helpers/ReplaceNN';
import './PatientOverviewList.scss';
import SnapChart from './SnapChart';
import SnapList from './SnapList';

const PatientOverviewList = ({
  onClickConditionPanel,
  removeItem,
  activeDelete,
  indexDelete,
}) => {
  const list = useSelector((state) => state.clinicProviderPatientSnapshot.list);
  const calList = useSelector(
    (state) => state.clinicProviderPatientSnapshot.calList,
  );
  const descList = useSelector(
    (state) => state.clinicProviderPatientSnapshot.descList,
  );

  const [selectedDate, setSelectedDate] = useState('');
  const onSelect = (selectedDate_) => {
    setSelectedDate(selectedDate_);
  };

  const [descListState, setDescListState] = useState([]);

  useEffect(() => {
    if (descList?.length && descList[0]) {
      setSelectedDate(moment(descList[0].date).format('MM/DD/YYYY'));
    }
  }, [descList]);

  useEffect(() => {
    const sublist =
      (descList || []).map((r) => {
        return {
          ...r,
          values:
            (r?.values || []).map((v) => {
              return {
                ...v,
                value: ReplaceNN(v.value) || '',
              };
            }) || [],
        };
      }) || [];
    setDescListState(sublist);
  }, [descList]);

  const PanelToolbar = ({ onRemoveCondition, row, index }) => {
    if (row.is_system === 0) {
      return (
        <div
          className="atuoAccordion__result-item-close_btn"
          onClick={(e) => {
            e.stopPropagation();
            onRemoveCondition && onRemoveCondition(index, row);
          }}
        >
          <Icon
            style={{
              color: activeDelete && indexDelete === index ? 'red' : '#000',
            }}
            type="delete"
          />
        </div>
      );
    } else {
      return (
        <div
          className="atuoAccordion__result-item-close_btn"
          onClick={(e) => {
            e.stopPropagation();
          }}
        ></div>
      );
    }
  };

  return (
    <Collapse accordion onChange={(key) => onClickConditionPanel(key)}>
      {(list || []).map((data, index) => (
        <Collapse.Panel
          header={data?.condition}
          key={`collapse-${data['id']}`}
          extra={
            <PanelToolbar
              onRemoveCondition={() => removeItem && removeItem(index, data)}
              row={data}
              index={index}
            />
          }
        >
          {calList && descListState && (
            <div className="content">
              <SnapChart
                items={calList}
                selectedDate={selectedDate}
                onSelect={onSelect}
              />
              <div className="snapDetails">
                <div className="snapDetails__title" style={{ borderBottom: '0.5px solid rgb(223, 227, 230)' }}>Clinical Summary</div>
                <SnapList
                  items={descListState}
                  selectedDate={selectedDate}
                  onChangeSelectedDate={onSelect}
                  isClinicWorkFlow={true}
                  collapseLabel={data?.condition}
                />
              </div>
            </div>
          )}
        </Collapse.Panel>
      ))}
    </Collapse>
  );
};

export default PatientOverviewList;
