import { Checkbox, Icon } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import { getLocal } from "../../../../helpers/Local";
import RemoveN from "../../../../helpers/RemoveN";
import {
  patientExaminationLoadInit,
  patientExaminationSave,
  patientExaminationSetData,
  setGlobalToastr,
  setSidebarState,
  setVitalOG,
} from "../../../../store/actions";
import ExaminationROS from "./ExaminationROS";
import ROSSidebar from "./ROSSidebar";

class RosVitals extends Component {
  sidebarRef = null;
  constructor(props) {
    super(props);
    this.sidebarRef = React.createRef();
  }

  state = {
    isSidebar: true,
    checkedCopyLast: false,
    saveQueued: false,
    checkSavingFuncId: null,
    checkedAllNormals: null,
    ROSsCheck: [],
    first: false,
    firstCheck: false,
  };
  timer = null;
  async componentDidMount() {
    const current_appointment_id = getLocal("current_appointment_id");
    this.props.setSidebarState(true);
    const dataCheck = await this.props.patientExaminationLoadInit(current_appointment_id);
    const dataMock =
      (dataCheck?.vitals || []).map((r) => {
        return {
          ...r,
          extra: "",
        };
      }) || [];
    await this.props.setVitalOG(
      dataMock.map((r) => {
        return r;
      })
    );
    const { patientResource, all_normal_ros, copy_from_last_visit_ros } = this.props;
    if (patientResource) {
      this.setState({
        ...this.state,
        isSidebar: window.innerWidth <= 768 ? false : true,
      });
    }
    this.setState({
      first: false,
    });
    if (copy_from_last_visit_ros == 1) {
      this.setState({
        checkedCopyLast: true,
      });
    } else if (copy_from_last_visit_ros == 0) {
      this.setState({
        checkedCopyLast: false,
      });
    } else {
      this.setState({
        checkedCopyLast: null,
      });
    }
    if (all_normal_ros == 1) {
      this.setState({
        checkedAllNormals: true,
      });
    } else if (all_normal_ros == 0) {
      this.setState({
        checkedAllNormals: false,
      });
    } else {
      this.setState({
        checkedAllNormals: null,
      });
    }
  }

  componentWillUnmount() {
    this.props.setSidebarState(true);
  }

  componentWillReceiveProps(nextProps) {
    const { all_normal_ros } = nextProps;
    if (all_normal_ros == 1) {
      this.setState({
        checkedAllNormals: true,
      });
    } else if (all_normal_ros == 0) {
      this.setState({
        checkedAllNormals: false,
      });
    } else {
      this.setState({
        checkedAllNormals: null,
      });
    }
    this.setState({
      first: false,
    });
  }

  handleSidebar = () => {
    this.setState({ ...this.state, isSidebar: true });
  };

  toggleSidebar = () => {
    this.setState({ isSidebar: !this.state.isSidebar });
  };

  handleCloseSidebar = () => {
    this.setState({ ...this.state, isSidebar: false });
  };

  onChangeData = async (scope, data) => {
    await this.props.patientExaminationSetData(scope, data, this.state.checkedAllNormals);
    await this.setState({ ...this.state, saveQueued: true });
    clearTimeout(this.timer);
    this.timer = setTimeout(async () => {
      this.setState({
        firstCheck: true,
      });
    }, 1000);
    if (this.state.firstCheck) {
      this.saveData();
    }
  };

  saveData = async () => {
    const { ROSs, vitals, all_normal_ros, patientResource, is_locked, copy_from_last_visit_ros } = this.props;
    const procedure_id = getLocal("current_appointment_id");

    const patient_id = patientResource?.id;
    if (is_locked == 0 || is_locked == null) {
      clearTimeout(this.timer);
      this.timer = setTimeout(async () => {
        const fixArr = (ROSs || []).map((r) => {
          return {
            ...r,
            comments: RemoveN(r?.comments || ""),
          };
        });
        this.props.patientExaminationSave(procedure_id, {
          patient_id,
          all_normal_ros,
          ROSs: fixArr,
          copy_from_last_visit_ros: copy_from_last_visit_ros,
          vitals,
        });
      }, 1000);
      this.setState({ ...this.state, saveQueued: false });
    } else {
      this.props.setGlobalToastr({
        header: "Save Fail!",
        message: "Please Unlock Finalize",
        type: "failed",
      });
    }
  };

  doCopyLast = (checked) => {
    const { patientResource, is_locked, ROSs } = this.props;
    if (checked) {
      if (is_locked == 0 || is_locked == null) {
        this.setState(
          {
            checkedCopyLast: true,
          },
          () => {
            this.props.RosCoppy(patientResource?.id);
          }
        );
      } else {
        this.props.setGlobalToastr({
          header: "Save Fail!",
          message: "Please Unlock Finalize",
          type: "failed",
        });
      }
    } else {
      if (is_locked == 0 || is_locked == null) {
        this.setState(
          {
            checkedCopyLast: false,
          },
          () => {
            const removeRos = (ROSs || []).map((r) => {
              return {
                ...r,
                comments: "",
                values: (r?.values || []).map((v) => {
                  return {
                    ...v,
                    checked: false,
                  };
                }),
              };
            });
            this.props.patientExaminationSetData("all_normal_ros", 0);
            this.props.patientExaminationSetData("ROSs", removeRos);
          }
        );
      } else {
        this.props.setGlobalToastr({
          header: "Save Fail!",
          message: "Please Unlock Finalize",
          type: "failed",
        });
      }
    }
  };

  checkAllNormals = async (checked) => {
    await this.setState({
      first: false,
    });
    const { ROSs, vitals, is_locked, patientResource } = this.props;
    const patient_id = patientResource?.id;
    const procedure_id = getLocal("current_appointment_id");
    if (is_locked == 0 || is_locked == null) {
      await this.setState({
        ...this.state,
        saveQueued: true,
        checkedAllNormals: checked,
      });
      const ROSs_ = [...ROSs];
      const fixArr = (ROSs_ || []).map((r) => {
        return {
          ...r,
          comments: RemoveN(r?.comments || ""),
        };
      });
      await this.props.RosCheckAll("ROSs", fixArr, checked);
      await this.props.patientExaminationSave(procedure_id, {
        patient_id: patient_id,
        all_normal_ros: checked ? 1 : 0,
        ROSs: fixArr,
        vitals,
      });
    } else {
      this.props.setGlobalToastr({
        header: "Save Fail!",
        message: "Please Unlock Finalize",
        type: "failed",
      });
    }
  };

  removeState = () => {
    if (this.props.ROSs && this.props.ROSs.length > 0) {
      this.props.patientExaminationSetData(
        "ROSs",
        this.props.ROSs.map((r) => {
          return {
            ...r,
            comments: "",
          };
        })
      );
    }
  };

  render() {
    const { isSidebar } = this.state;
    const { checkedCopyLast, checkedAllNormals } = this.state;
    return (
      <div style={{ display: this.props.display ? "flex" : "none" }} className="clinicWorkflow__main-section">
        <div className="eventsContainer has-sub-header" style={{ paddingTop: "1rem" }}>
          <div id="check_box" className="conditionList">
            <Checkbox
              checked={checkedCopyLast}
              onChange={(e) => this.doCopyLast(e.target.checked)}
              disabled={this.props.can_copy_from_previous_visit === 0}
            >
              Copy from previous visit
            </Checkbox>
            <Checkbox
              id={"all_negative_check"}
              checked={checkedAllNormals}
              onChange={(e) => {
                this.checkAllNormals(e.target.checked);
                this.removeState();
              }}
              disabled={this.props.is_locked}
            >
              All negative except selections
            </Checkbox>
            <div className="listSection">
              <ExaminationROS type={"ROS"} onChangeData={(data) => this.onChangeData("ROSs", data)} isLocked={this.props.is_locked} />
            </div>
          </div>
        </div>
        {this.props.isSlide && (
          <ROSSidebar
            ref={this.sidebarRef}
            sidebarIcon={<Icon type="file-text" theme="twoTone" twoToneColor="#740937" />}
            onChangeData={this.onChangeData}
            isShow={isSidebar}
            handleCloseSidebar={this.handleCloseSidebar}
            isLocked={this.props.is_locked}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  is_locked: state.clinicProviderReview.is_locked,
  patientResource: state.clinicProvider.patientResource,
  ROSs: state.patientExamination.ROSs,
  vitals: state.patientExamination.vitals,
  all_normal_ros: state.patientExamination.all_normal_ros,
  can_copy_from_previous_visit: state.patientExamination.can_copy_from_previous_visit,
  copy_from_last_visit_ros: state.patientExamination.copy_from_last_visit_ros,
});

const mapDispatchToProps = {
  patientExaminationLoadInit,
  patientExaminationSetData,
  patientExaminationSave,
  setVitalOG,
  setGlobalToastr,
  setSidebarState,
};

export default connect(mapStateToProps, mapDispatchToProps)(RosVitals);
