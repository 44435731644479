import { DatePicker } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import { useDispatch, useSelector } from "react-redux";

import CardBlock from "../../common/components/Card";
import DataTable from "../../common/components/DataTable/WhiteHeaderTable";
import EditableFormInput from "../../common/components/EditableText/EditableFormInput";
import { getProviders, getUltrasoundAnalytics } from "../../store/actions";

const dateFormat = "MM/DD/YYYY";

const UltrasoundAnalytics = () => {
  const dispatch = useDispatch();
  const ultrasound_analytics = useSelector((state) => state.common.ultrasound_analytics);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [tableData, setTableData] = useState([]);
  const [referralsProvider, setReferralsProvider] = useState([]);
  const [ultrasoundLocation, setUltrasoundLocation] = useState([]);
  const [data, setData] = useState({
    labels: [],
    datasets: [],
  });
  const only_providers = useSelector((state) => state.common.providers);
  const [selectedProvider, setSelectedProvider] = useState(0);

  useEffect(() => {
    if (!only_providers) {
      dispatch(getProviders());
    }
  }, []);

  const getMillionsValue = (value) => {
    let newValue = value;
    if (value >= 1000) {
      const suffixes = ["", "K", "M", "B", "T"];
      const suffixNum = Math.floor(("" + value).length / 3);
      let shortValue = "";
      for (let precision = 2; precision >= 1; precision--) {
        shortValue = parseFloat((suffixNum != 0 ? value / Math.pow(1000, suffixNum) : value).toPrecision(precision));
        const dotLessShortValue = (shortValue + "").replace(/[^a-zA-Z 0-9]+/g, "");
        if (dotLessShortValue.length <= 2) {
          break;
        }
      }
      if (shortValue % 1 != 0) shortValue = shortValue.toFixed(1);
      newValue = shortValue + suffixes[suffixNum];
    }
    return newValue;
  };

  const chartOptions = {
    responsive: true,
    animation: false,
    maintainAspectRatio: false,
    defaults: {
      elements: {
        point: {
          backgroundColor: "#000000",
          borderColor: "#000000",
        },
      },
    },
    layout: {
      padding: 30,
    },
    plugins: {
      legend: {
        position: "right",
        display: false,
        labels: {
          color: "#152935",
          family: '"IBM plex sans", "Helvetica Neue", sans-serif',
        },
      },
    },
    scales: {
      y: {
        min: 0,
        stacked: true,
        ticks: {
          font: {
            size: "0.875rem",
            family: '"IBM plex sans", "Helvetica Neue", sans-serif',
          },
          color: "#152935",
          callback: function (value, index, values) {
            return getMillionsValue(value);
          },
        },
      },
      x: {
        ticks: {
          padding: 10,
          font: {
            size: "0.875rem",
            family: '"IBM plex sans", "Helvetica Neue", sans-serif',
          },
          color: "#000",
        },
      },
    },
  };
  useEffect(() => {
    dispatch(getUltrasoundAnalytics(startDate, endDate, selectedProvider));
  }, [startDate, endDate]);

  useEffect(() => {
    if (!ultrasound_analytics) return;

    const newData = {};
    const {
      labels,
      referrals_provider,
      us_confirmed_but_no_show_data,
      us_not_confirmed_data,
      us_checked_in_data,
      us_finalized_data,
      referrals_location,
    } = ultrasound_analytics || {};

    newData.labels = us_finalized_data?.map((procedure) => {
      return moment(procedure.procedure_date, "MM-YYYY").format("MMM-YY");
    });

    setReferralsProvider(referrals_provider);
    setUltrasoundLocation(referrals_location);

    setTableData([
      {
        procedure: "Total ultrasound appointments that are finalized",
        total: labels?.clinic_finalized_label,
      },
      {
        procedure: "Total ultrasound appointments that are checked-in",
        total: labels?.clinic_checked_in_label,
      },
      {
        procedure: "Total ultrasound appointments that are confirmed but no show",
        total: labels?.clinic_confirmed_but_no_show_label,
      },
      {
        procedure: "Total ultrasound appointments that are not confirmed",
        total: labels?.clinic_not_confirmed_label,
      },
    ]);

    newData.datasets = [
      {
        label: "US Checked in",
        data: us_checked_in_data?.map((procedure) => {
          return procedure?.total;
        }),
        fill: true,
        backgroundColor: "rgba(130,207,255,0.75)",
        borderColor: "rgba(130,207,255,1)",
        pointBackgroundColor: "#000000",
      },
      {
        label: "US Confirmed but No Show",
        data: us_confirmed_but_no_show_data?.map((procedure) => {
          return procedure?.total;
        }),
        fill: true,
        backgroundColor: "rgba(255,231,163,0.75)",
        borderColor: "rgba(255,231,163,1)",
        pointBackgroundColor: "#000000",
      },
      {
        label: "US Not Confirmed in",
        data: us_not_confirmed_data?.map((procedure) => {
          return procedure?.total;
        }),
        fill: true,
        backgroundColor: "rgba(255,175,210,0.75)",
        borderColor: "rgba(255,175,210,1)",
        pointBackgroundColor: "#000000",
      },
    ];

    setData(newData);
  }, [ultrasound_analytics]);

  const minRowCount = 8;
  let emptyRows = [];
  if (tableData?.length < minRowCount + 1) {
    emptyRows = new Array(minRowCount - tableData?.length).fill({ label: "" });
  }

  const changeProvider = (value) => {
    setSelectedProvider(value);
    dispatch(getUltrasoundAnalytics(startDate, endDate, value));
  };

  return (
    <React.Fragment>
      <div className="cardBlock" style={{ height: "calc(65% - 20px)", width: "100%" }}>
        <div className="filterContainer" style={{ padding: 0, paddingRight: "35px", paddingLeft: "35px" }}>
          <div className="cardBlock__title">Ultrasound Volume</div>
          <div className="statusFilter" style={{ minWidth: "200px" }}>
            <EditableFormInput
              label={"Provider"}
              value={selectedProvider}
              handleChange={changeProvider}
              fullWidth={true}
              type={"check"}
              tabIndex={3}
              optionKey={"id"}
              valueKey={"value"}
              options={only_providers || []}
            />
          </div>
          <div className="statusFilter" style={{ minWidth: "200px" }}>
            <DatePicker
              format={dateFormat}
              placeholder="Start Date"
              value={startDate}
              onChange={(val) => {
                setStartDate(val);
              }}
            />
          </div>
          <div className="statusFilter" style={{ minWidth: "200px" }}>
            <DatePicker
              format={dateFormat}
              placeholder="End Date"
              value={endDate}
              onChange={(val) => {
                setEndDate(val);
              }}
            />
          </div>
        </div>
        <div style={{ height: "90%" }}>
          <Line data={data} options={chartOptions} height={75} />
        </div>
      </div>
      <div
        style={{
          width: "100%",
          height: "calc(35% - 10px)",
          display: "flex",
        }}
      >
        <CardBlock title="Ultrasound Breakdown" varient="one_third" other="" contentPadding="1rem">
          <DataTable
            title="ultrasound-breakdown"
            labels={["Ultrasound", "Total"]}
            widths={["70%", "30%"]}
            tableWidth={300}
            defaultActive={-1}
            sizeHeightFix="100%"
            isFixHeight
            isNormal
            bordered
            disableIcon
            disableDelete
            columns={[
              { sortable: false, key: "procedure" },
              { sortable: false, key: "total" },
            ]}
            rowData={tableData?.map((item, index) => {
              return {
                procedure: item.procedure,
                total: item.total,
              };
            })}
            emptyRows={emptyRows}
            sorts={["", ""]}
          />
        </CardBlock>
        <CardBlock title="RVT Ultrasound Volume" varient="one_third" other="" contentPadding="1rem">
          <DataTable
            title="rvt-ultrasound-volume"
            labels={["RVT Technologist", "Total"]}
            widths={["70%", "30%"]}
            tableWidth={300}
            defaultActive={-1}
            sizeHeightFix="100%"
            isFixHeight
            isNormal
            bordered
            disableIcon
            disableDelete
            columns={[
              { sortable: false, key: "value" },
              { sortable: false, key: "total" },
            ]}
            rowData={referralsProvider?.map((provider, index) => {
              return {
                value: provider?.value,
                total: provider.total,
              };
            })}
            emptyRows={emptyRows}
            sorts={["", ""]}
          />
        </CardBlock>
        <CardBlock title="Location Ultrasound Volume" varient="one_third" other="" contentPadding="1rem">
          <DataTable
            title="location-ultrasound-volume"
            labels={["Location", "Total"]}
            widths={["70%", "30%"]}
            tableWidth={300}
            defaultActive={-1}
            sizeHeightFix="100%"
            isFixHeight
            isNormal
            bordered
            disableIcon
            disableDelete
            columns={[
              { sortable: false, key: "value" },
              { sortable: false, key: "total" },
            ]}
            rowData={ultrasoundLocation?.map((item, index) => {
              return {
                value: item?.value,
                total: item.total,
              };
            })}
            emptyRows={emptyRows}
            sorts={["", ""]}
          />
        </CardBlock>
      </div>
    </React.Fragment>
  );
};

export default UltrasoundAnalytics;
