import { Drawer } from "antd";
import pdf2base64 from "pdf-to-base64";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ToastMessage from "../../../common/components/Toast";
import { getProviders, reset_fax_scan_document_log, setGlobalLoading, set_default_toast } from "../../../store/actions";
import ScrollWrapper from "../../components/ScrollBar";
import ImageList from "../PatientChart/ImageList";

const EducationSideBar = React.forwardRef(({ resource, sidebarIcon, isShow }, ref) => {
  const selectedDocument = useSelector((state) => state.clinicProvider.selectedDocument);
  const patientResource = useSelector((state) => state.clinicProvider?.patientResource);
  const print_patient_document = useSelector((state) => state.patientRecords.print_patient_document);
  const [isToastr, setIsToastr] = useState(false);

  const loading_print = useSelector((state) => state.patientRecords.loading_print_patient_document);
  const errorRest = useSelector((state) => state.common.globalErrorMessage);
  const isToast = useSelector((state) => state.patientRecords.isToast);
  const sideNavStatus = useSelector((state) => state.common.sideNavStatus);
  const [reset, setReset] = useState(true);
  const dispatch = useDispatch();
  const [printLink, setPrintLink] = useState("");
  const [message, setMessage] = useState(null);

  useEffect(() => {
    dispatch(getProviders());
  }, []);

  useEffect(() => {
    if (isShow) {
      dispatch(setGlobalLoading(loading_print));
    }
  }, [loading_print, isShow]);

  useEffect(() => {
    if (selectedDocument) {
      setReset(true);
    }
  }, [selectedDocument]);

  useEffect(() => {
    if (reset) {
      setReset(false);
    }
  }, [reset]);

  useEffect(() => {
    if (isToast) {
      setIsToastr(true);
      setTimeout(() => {
        dispatch(set_default_toast());
        setIsToastr(false);
      }, 2000);
    }
  }, [isToast]);

  useEffect(() => {
    if (print_patient_document && Object.keys(print_patient_document).length > 0) {
      checkPrint();
    }
  }, [print_patient_document]);

  useEffect(() => {
    if (printLink) {
      printPdf(printLink);
      setTimeout(() => {
        setPrintLink("");
      }, 1000);
    }
  }, [printLink]);

  const printPdf = function (b64) {
    const b64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
      const byteCharacters = atob(b64Data);
      const byteArrays = [];
      for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);
        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
      }
      const blob = new Blob(byteArrays, { type: contentType });
      return blob;
    };

    const contentType = "application/pdf";
    const blob = b64toBlob(b64, contentType);
    const blobUrl = URL.createObjectURL(blob);

    let iframe = null;

    iframe = document.createElement("iframe");
    document.body.appendChild(iframe);

    iframe.style.display = "none";
    iframe.onload = function () {
      setTimeout(function () {
        iframe.focus();
        iframe.contentWindow.print();
      }, 500);
    };

    iframe.src = blobUrl;
  };

  const checkPrint = async () => {
    const base64 = await pdf2base64(print_patient_document.url);
    setMessage("Print Successfully!");
    setIsToastr(true);
    setPrintLink(base64);
    await dispatch(reset_fax_scan_document_log());
    setTimeout(() => {
      setIsToastr(false);
      setMessage(null);
    }, 2000);
  };

  const showToast = () => {
    return <ToastMessage type="Document" status={"success"} msg={message ? message : "Update Successfully!"} />;
  };

  const { first_name, last_name } = patientResource;

  return (
    <Drawer
      title={`Education Correspondence - ${first_name} ${last_name}`}
      placement="right"
      className={sideNavStatus ? "wide" : ""}
      closable={false}
      mask={false}
      visible={isShow}
      destroyOnClose
    >
      <div ref={ref} className="resourceContainer new">
        {isToastr && !errorRest && showToast()}
        <ScrollWrapper css="x-hidden">
          <div
            className="resourceContent"
            style={{
              flex: "unset",
              paddingLeft: "24px",
              paddingRight: "20px",
              paddingBottom: "2rem",
              height: "auto",
              display: "block",
            }}
          >
            <ImageList />

            <div
              className="resourceInfo d-flex"
              style={{
                alignItems: "flex-end",
                justifyContent: "flex-end",
                marginTop: "1.5rem",
              }}
            >
              <button className="common-btn outlined-btn">Email</button>
              <button className="common-btn" style={{ marginLeft: "0.5rem" }}>
                Print
              </button>
            </div>
          </div>
        </ScrollWrapper>
      </div>
    </Drawer>
  );
});

export default EducationSideBar;
