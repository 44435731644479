import React, { useEffect, useRef, useState } from "react";
import { AiOutlineRedo } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import EditableFormInput from "src/common/components/EditableText/EditableFormInput";
import EditableInputWithDiv from "../../../../common/components/EditableText/EditableInputWithDiv";
import EditableInputWithSingleDiv from "../../../../common/components/EditableText/EditableInputWithSingleDiv";
import { getLocal } from "../../../../helpers/Local";
import { post_ultrasound_question, setGlobalLoading } from "../../../../store/actions";

const QUESTIONS = [
  "Does the patient have a vein graft stenosis (>75%) by Duplex?",
  "Does the patient have in-stent or recurring stenosis (> 75%) by Duplex?",
  "Does the patient have (> 75%) stenosis A. Common iliac arterial lesion B. External iliac arterial lesion C. Femoral arterial lesion D. Popliteal arterial lesion by Duplex?",
  "Tibial Arterial Lesion (> 75%) by Duplex?",
];
const BUTTONS = [
  {
    text: "Right",
    value: "right",
  },
  {
    text: "Left",
    value: "left",
  },
  {
    text: "None",
    value: "none",
  },
];

const Questionnaire = ({ ...restProps }) => {
  const dataRef = useRef();
  const [data, setData] = useState({
    rightResting: "",
    leftResting: "",
    rightExercise: "",
    leftExercise: "",
    rightToePressure: "",
    leftToePressure: "",
    [`question-0`]: "",
    [`question-1`]: "",
    [`question-2`]: "",
    [`question-3`]: "",
    abi_right_resting: 0,
    abi_left_resting: 0,
    abi_right_exercise: 0,
    abi_left_exercise: 0,
    tbi_right: 0,
    tbi_left: 0,
  });
  const [showCondition, setShowCondition] = useState(false);
  const [hideRightAbiResting, setHideRightAbiResting] = useState(false);
  const [hideLeftAbiResting, setHideLeftAbiResting] = useState(false);
  const [hideRightAbiExercise, setHideRightAbiExercise] = useState(false);
  const [hideLeftAbiExercise, setHideLeftAbiExercise] = useState(false);
  const [hideTbiRight, setHideTbiRight] = useState(false);
  const [hideTbiLeft, setHideTbiLeft] = useState(false);

  useEffect(() => {
    if (restProps?.sidebarTitle === "Lower Extremity Arteries") {
      setShowCondition(true);
    } else {
      setShowCondition(false);
    }
  }, [restProps.sidebarTitle]);

  const appointment_ultrasound_id = getLocal("appointment_ultrasound_id");

  const questionnaireData = useSelector((state) => state.ultrasound.questionnaireData);
  const dispatch = useDispatch();
  const loadingGetQuestion = useSelector((state) => state.ultrasound.loadingGetQuestion);
  const { rightResting, leftResting, rightExercise, leftExercise, rightToePressure, leftToePressure } = data;

  useEffect(() => {
    if (
      rightResting &&
      leftResting &&
      rightExercise &&
      leftExercise &&
      rightToePressure &&
      leftToePressure &&
      data[`question-0`] &&
      data[`question-1`] &&
      data[`question-2`] &&
      data[`question-3`]
    ) {
      restProps.checkQuestionaire(true);
    } else {
      restProps.checkQuestionaire(false);
    }
  }, [data]);

  const refreshRightRestingAbi = (data) => {
    let a2 = 0;
    let b2 = 0;
    let a1 = 0;
    let b1 = 0;
    let stateCheck = {};
    let maxA = 0;

    if (data && data["rightResting"]) {
      if (data["rightResting"].split("/")[1]) {
        a2 = data["rightResting"].split("/")[1];
      }
      if (data["rightResting"].split("/")[0]) {
        a1 = data["rightResting"].split("/")[0];
      }
    }
    if (data && data["leftResting"]) {
      if (data["leftResting"].split("/")[1]) {
        b2 = data["leftResting"].split("/")[1];
      }
      if (data["leftResting"].split("/")[0]) {
        b1 = data["leftResting"].split("/")[0];
      }
    }

    maxA = a2 > b2 ? a2 : b2;
    stateCheck = {
      ...data,
      abi_right_resting: Number(a1 / maxA).toFixed(1),
    };
    setData(stateCheck);
    dataRef.current = stateCheck;
    setHideRightAbiResting(true);
  };

  const refreshLeftRestingAbi = (data) => {
    let a2 = 0;
    let b2 = 0;
    let b1 = 0;
    let stateCheck = {};
    let maxA = 0;

    if (data && data["rightResting"]) {
      if (data["rightResting"].split("/")[1]) {
        a2 = data["rightResting"].split("/")[1];
      }
    }
    if (data && data["leftResting"]) {
      if (data["leftResting"].split("/")[1]) {
        b2 = data["leftResting"].split("/")[1];
      }
      if (data["leftResting"].split("/")[0]) {
        b1 = data["leftResting"].split("/")[0];
      }
    }

    maxA = a2 > b2 ? a2 : b2;
    stateCheck = {
      ...data,
      abi_left_resting: Number(b1 / maxA).toFixed(1),
    };
    setData(stateCheck);
    dataRef.current = stateCheck;
    setHideLeftAbiResting(true);
  };

  const refreshRightExerciseAbi = (data) => {
    let c2 = 0;
    let d2 = 0;
    let c1 = 0;
    let stateCheck = {};
    let maxC = 0;

    if (data && data["rightExercise"]) {
      if (data["rightExercise"].split("/")[1]) {
        c2 = data["rightExercise"].split("/")[1];
      }
      if (data["rightExercise"].split("/")[0]) {
        c1 = data["rightExercise"].split("/")[0];
      }
    }
    if (data && data["leftExercise"]) {
      if (data["leftExercise"].split("/")[1]) {
        d2 = data["leftExercise"].split("/")[1];
      }
    }

    maxC = c2 > d2 ? c2 : d2;
    stateCheck = {
      ...data,
      abi_right_exercise: Number(c1 / maxC).toFixed(1),
    };
    setData(stateCheck);
    dataRef.current = stateCheck;
    setHideRightAbiExercise(true);
  };

  const refreshLeftExerciseAbi = (data) => {
    let c2 = 0;
    let d2 = 0;
    let d1 = 0;
    let stateCheck = {};
    let maxC = 0;

    if (data && data["rightExercise"]) {
      if (data["rightExercise"].split("/")[1]) {
        c2 = data["rightExercise"].split("/")[1];
      }
    }
    if (data && data["leftExercise"]) {
      if (data["leftExercise"].split("/")[1]) {
        d2 = data["leftExercise"].split("/")[1];
      }
      if (data["leftExercise"].split("/")[0]) {
        d1 = data["leftExercise"].split("/")[0];
      }
    }

    maxC = c2 > d2 ? c2 : d2;
    stateCheck = {
      ...data,
      abi_left_exercise: Number(d1 / maxC).toFixed(1),
    };
    setData(stateCheck);
    dataRef.current = stateCheck;
    setHideLeftAbiExercise(true);
  };

  const refreshTbiRight = (data) => {
    let e = 0;
    let a2 = 0;
    let b2 = 0;
    let maxA = 0;
    let tbi_right = 0;
    let stateCheck = {};

    if (data && data["rightResting"]) {
      if (data["rightResting"].split("/")[1]) {
        a2 = data["rightResting"].split("/")[1];
      }
    }
    if (data && data["leftResting"]) {
      if (data["leftResting"].split("/")[1]) {
        b2 = data["leftResting"].split("/")[1];
      }
    }

    maxA = a2 > b2 ? a2 : b2;

    if (e > 0 && maxA > 0) {
      tbi_right = e / maxA;
      if (tbi_right < 0.1) tbi_right = 0.1;
      else tbi_right = Number(tbi_right).toFixed(1);
    } else {
      tbi_right = null;
    }

    stateCheck = {
      ...data,
      tbi_right: tbi_right,
    };
    setData(stateCheck);
    dataRef.current = stateCheck;
    setHideTbiRight(true);
  };

  const refreshTbiLeft = (data) => {
    let f = 0;
    let a2 = 0;
    let b2 = 0;
    let maxA = 0;
    let tbi_left = 0;
    let stateCheck = {};

    if (data && data["rightResting"]) {
      if (data["rightResting"].split("/")[1]) {
        a2 = data["rightResting"].split("/")[1];
      }
    }
    if (data && data["leftResting"]) {
      if (data["leftResting"].split("/")[1]) {
        b2 = data["leftResting"].split("/")[1];
      }
    }

    maxA = a2 > b2 ? a2 : b2;

    if (f > 0 && maxA > 0) {
      tbi_left = f / maxA;
      if (tbi_left < 0.1) tbi_left = 0.1;
      else tbi_left = Number(tbi_left).toFixed(1);
    } else {
      tbi_left = null;
    }

    stateCheck = {
      ...data,
      tbi_left: tbi_left,
    };
    setData(stateCheck);
    dataRef.current = stateCheck;
    setHideTbiLeft(true);
  };

  const checkCondition = (data) => {
    if (showCondition) {
      let a2 = null;
      let b2 = null;
      let c2 = null;
      let d2 = null;
      let a1 = null;
      let b1 = null;
      let c1 = null;
      let d1 = null;
      let e = null;
      let f = null;
      let maxA = 0;
      let maxC = 0;
      let stateCheck = {};
      let tbi_right = null;
      let tbi_left = null;
      let abi_right_resting = 0;
      let abi_left_resting = 0;
      let abi_right_exercise = 0;
      let abi_left_exercise = 0;

      if (data && data["rightToePressure"]) {
        e = data["rightToePressure"];
      }
      if (data && data["leftToePressure"]) {
        f = data["leftToePressure"];
      }
      if (data && data["rightResting"]) {
        if (data["rightResting"].split("/")[1]) {
          a2 = data["rightResting"].split("/")[1];
        }
        if (data["rightResting"].split("/")[0]) {
          a1 = data["rightResting"].split("/")[0];
        }
      }
      if (data && data["leftResting"]) {
        if (data["leftResting"].split("/")[1]) {
          b2 = data["leftResting"].split("/")[1];
        }
        if (data["leftResting"].split("/")[0]) {
          b1 = data["leftResting"].split("/")[0];
        }
      }
      if (data && data["rightExercise"]) {
        if (data["rightExercise"].split("/")[1]) {
          c2 = data["rightExercise"].split("/")[1];
        }
        if (data["rightExercise"].split("/")[0]) {
          c1 = data["rightExercise"].split("/")[0];
        }
      }
      if (data && data["leftExercise"]) {
        if (data["leftExercise"].split("/")[1]) {
          d2 = data["leftExercise"].split("/")[1];
        }
        if (data["leftExercise"].split("/")[0]) {
          d1 = data["leftExercise"].split("/")[0];
        }
      }
      maxA = a2 > b2 ? a2 : b2;
      maxC = c2 > d2 ? c2 : d2;
      if (e > 0 && maxA > 0) {
        tbi_right = e / maxA;
        if (tbi_right < 0.1) tbi_right = 0.1;
        else tbi_right = Number(tbi_right).toFixed(1);
      } else {
        tbi_right = null;
      }
      if (f > 0 && maxA > 0) {
        tbi_left = f / maxA;
        if (tbi_left < 0.1) tbi_left = 0.1;
        else tbi_left = Number(tbi_left).toFixed(1);
      } else {
        tbi_left = null;
      }
      if (a1 > 0 && maxA > 0) {
        abi_right_resting = Number(a1 / maxA).toFixed(1);
      } else {
        abi_right_resting = null;
      }
      if (b1 > 0 && maxA > 0) {
        abi_left_resting = Number(b1 / maxA).toFixed(1);
      } else {
        abi_left_resting = null;
      }
      if (c1 > 0 && maxC > 0) {
        abi_right_exercise = Number(c1 / maxC).toFixed(1);
      } else {
        abi_right_exercise = null;
      }
      if (d1 > 0 && maxC > 0) {
        abi_left_exercise = Number(d1 / maxC).toFixed(1);
      } else {
        abi_left_exercise = null;
      }

      stateCheck = {
        ...data,
        abi_right_resting: abi_right_resting,
        abi_left_resting: abi_left_resting,
        abi_right_exercise: abi_right_exercise,
        abi_left_exercise: abi_left_exercise,
        tbi_right: tbi_right,
        tbi_left: tbi_left,
      };
      setData(stateCheck);
      dataRef.current = stateCheck;
    } else {
      setData(data);
      dataRef.current = data;
    }
  };

  const handleChange = async (key, value) => {
    const dataParams = {
      ...data,
      [key]: value,
    };
    checkCondition(dataParams);
  };

  const initButtons = (data) => {
    let a1 = null;
    let a2 = null;
    let b1 = null;
    let b2 = null;
    let c1 = null;
    let c2 = null;
    let d1 = null;
    let d2 = null;
    let maxA = null;
    let maxC = null;
    let abi_right_resting = null;
    let abi_left_resting = null;
    let abi_left_exercise = null;
    let abi_right_exercise = null;
    let tbi_left = null;
    let tbi_right = null;
    let e = null;
    let f = null;

    if (data && data["rightToePressure"]) {
      e = data["rightToePressure"];
    }
    if (data && data["leftToePressure"]) {
      f = data["leftToePressure"];
    }

    if (data && data["rightResting"]) {
      if (data["rightResting"].split("/")[1]) {
        a2 = data["rightResting"].split("/")[1];
      }
      if (data["rightResting"].split("/")[0]) {
        a1 = data["rightResting"].split("/")[0];
      }
    }
    if (data && data["leftResting"]) {
      if (data["leftResting"].split("/")[1]) {
        b2 = data["leftResting"].split("/")[1];
      }
      if (data["leftResting"].split("/")[0]) {
        b1 = data["leftResting"].split("/")[0];
      }
    }
    if (data && data["rightExercise"]) {
      if (data["rightExercise"].split("/")[1]) {
        c2 = data["rightExercise"].split("/")[1];
      }
      if (data["rightExercise"].split("/")[0]) {
        c1 = data["rightExercise"].split("/")[0];
      }
    }
    if (data && data["leftExercise"]) {
      if (data["leftExercise"].split("/")[1]) {
        d2 = data["leftExercise"].split("/")[1];
      }
      if (data["leftExercise"].split("/")[0]) {
        d1 = data["leftExercise"].split("/")[0];
      }
    }
    maxA = a2 > b2 ? a2 : b2;
    maxC = c2 > d2 ? c2 : d2;

    if (a1 > 0 && maxA > 0) {
      abi_right_resting = Number(a1 / maxA).toFixed(1);
    } else {
      abi_right_resting = null;
    }

    if (abi_right_resting == data["abi_right_resting"]) {
      setHideRightAbiResting(true);
    }

    if (b1 > 0 && maxA > 0) {
      abi_left_resting = Number(b1 / maxA).toFixed(1);
    } else {
      abi_left_resting = null;
    }

    if (abi_left_resting == data["abi_left_resting"]) {
      setHideLeftAbiResting(true);
    }

    if (c1 > 0 && maxC > 0) {
      abi_right_exercise = Number(c1 / maxC).toFixed(1);
    } else {
      abi_right_exercise = null;
    }

    if (abi_right_exercise == data["abi_right_exercise"]) {
      setHideRightAbiExercise(true);
    }

    if (d1 > 0 && maxC > 0) {
      abi_left_exercise = Number(d1 / maxC).toFixed(1);
    } else {
      abi_left_exercise = null;
    }

    if (abi_left_exercise == data["abi_left_exercise"]) {
      setHideLeftAbiExercise(true);
    }

    if (e > 0 && maxA > 0) {
      tbi_right = e / maxA;
      if (tbi_right < 0.1) tbi_right = 0.1;
      else tbi_right = Number(tbi_right).toFixed(1);
    } else {
      tbi_right = null;
    }

    if (tbi_right == data["tbi_right"]) {
      setHideTbiRight(true);
    }

    if (f > 0 && maxA > 0) {
      tbi_left = f / maxA;
      if (tbi_left < 0.1) tbi_left = 0.1;
      else tbi_left = Number(tbi_left).toFixed(1);
    } else {
      tbi_left = null;
    }

    if (tbi_left == data["tbi_left"]) {
      setHideTbiLeft(true);
    }
  };

  useEffect(() => {
    var dataMix = {};

    if (questionnaireData && questionnaireData.length > 0) {
      questionnaireData.map((r) => {
        dataMix = { ...dataMix, [r.key]: r?.value };
      });
      setData(dataMix);
      initButtons(dataMix);
      dataRef.current = dataMix;
      if (restProps?.ultrasounds_store) {
        const data_ = {
          "question-0": data["question-0"],
          "question-1": data["question-1"],
          "question-2": data["question-2"],
          "question-3": data["question-3"],
        };
        data_["question-0"] = "none";
        data_["question-1"] = "none";
        data_["question-2"] = "none";
        data_["question-3"] = "none";
        const value = restProps?.ultrasounds_store || [];
        value.map(function (item, index) {
          if (item["Stenosis"] == "Severe (> 75%)" && (item["Intervention"] == "Stent" || item["Intervention"] == "Restenosis")) {
            const finding_name = item["finding_name"].indexOf("Right") > -1 ? "Right" : "Left";
            if (finding_name == "Right") {
              if (data_["question-1"] == "none") {
                data_["question-1"] = "right";
              } else {
                data_["question-1"] += ",right";
              }
            } else {
              if (data_["question-1"] == "none") {
                data_["question-1"] = "left";
              } else {
                data_["question-1"] += ",left";
              }
            }
          }
          if (item["Intervention"] == "Vein Graft" && item["Stenosis"] == "Severe (> 75%)") {
            const finding_name = item["finding_name"].indexOf("Right") > -1 ? "Right" : "Left";
            if (finding_name == "Right") {
              if (data_["question-0"] == "none") {
                data_["question-0"] = "right";
              } else {
                data_["question-0"] += ",right";
              }
            } else {
              if (data_["question-0"] == "none") {
                data_["question-0"] = "left";
              } else {
                data_["question-0"] += ",left";
              }
            }
          }
          if (item["Stenosis"] == "Severe (> 75%)") {
            if (
              item["finding_name"] == "Right TPT" ||
              item["finding_name"] == "Right ATA" ||
              item["finding_name"] == "Right PTA" ||
              item["finding_name"] == "Right Peroneal"
            ) {
              if (data_["question-3"] == "none") {
                data_["question-3"] = "right";
              } else {
                data_["question-3"] += ",right";
              }
            }
            if (
              item["finding_name"] == "Left TPT" ||
              item["finding_name"] == "Left ATA" ||
              item["finding_name"] == "Left PTA" ||
              item["finding_name"] == "Left Peroneal"
            ) {
              if (data_["question-3"] == "none") {
                data_["question-3"] = "left";
              } else {
                data_["question-3"] += ",left";
              }
            }
            if (
              item["finding_name"] == "Left Common Femoral" ||
              item["finding_name"] == "Left Profunda Femoris" ||
              item["finding_name"] == "Left Proximal SFA" ||
              item["finding_name"] == "Left Middle SFA" ||
              item["finding_name"] == "Left Distal SFA" ||
              item["finding_name"] == "Left Popliteal"
            ) {
              if (data_["question-2"] == "none") {
                data_["question-2"] = "left";
              } else {
                data_["question-2"] += ",left";
              }
            }
            if (
              item["finding_name"] == "Right Common Femoral" ||
              item["finding_name"] == "Right Profunda Femoris" ||
              item["finding_name"] == "Right Proximal SFA" ||
              item["finding_name"] == "Right Middle SFA" ||
              item["finding_name"] == "Right Distal SFA" ||
              item["finding_name"] == "Right Popliteal"
            ) {
              if (data_["question-2"] == "none") {
                data_["question-2"] = "right";
              } else {
                data_["question-2"] += ",right";
              }
            }
          }
        });
        const params = { ...dataMix, ...data_ };
        setData(params);
        dataRef.current = params;
      }
    }
  }, [questionnaireData, restProps?.ultrasounds_store]);

  useEffect(() => {
    return async () => {
      const data = dataRef?.current;
      if (data && Object.keys(data).length > 0) {
        const arrCheck = Object.keys(data).map((r) => {
          return {
            key: r,
            value: data[r],
          };
        });
        await dispatch(setGlobalLoading(true));
        await dispatch(
          post_ultrasound_question({
            procedure_id: appointment_ultrasound_id,
            values: arrCheck,
          })
        );
        await dispatch(setGlobalLoading(false));
      }
    };
  }, []);

  const handleChangeCustom = (value, type) => {
    const params = {
      ...data,
      [type]: value,
    };
    setData(params);
    dataRef.current = params;
    // eslint-disable-next-line default-case
    switch (type) {
      case "abi_right_resting":
        setHideRightAbiResting(false);
        break;
      case "abi_left_resting":
        setHideLeftAbiResting(false);
        break;
      case "abi_right_exercise":
        setHideRightAbiExercise(false);
        break;
      case "abi_left_exercise":
        setHideLeftAbiExercise(false);
        break;
      case "tbi_right":
        setHideTbiRight(false);
        break;
      case "tbi_left":
        setHideTbiLeft(false);
        break;
    }
  };

  const checkRightRAbi = () => {
    refreshRightRestingAbi(data);
  };

  const checkLeftRAbi = () => {
    refreshLeftRestingAbi(data);
  };

  const checkRightEAbi = () => {
    refreshRightExerciseAbi(data);
  };

  const checkLeftEAbi = () => {
    refreshLeftExerciseAbi(data);
  };

  const checkRightT = () => {
    refreshTbiRight(data);
  };

  const checkLeftT = () => {
    refreshTbiLeft(data);
  };

  return (
    <div className="resourceContent pr-7px">
      <div className="resourceInfo no-padding" style={{ paddingLeft: "0" }}>
        <div className="resourceInfo d-flex required-field newFlex ">
          <EditableInputWithDiv
            isErrorTextSpacing={true}
            label="ABI (Right) Resting (Ankle SBP / Brachial SBP)"
            value={rightResting}
            subLabel="BP"
            unit="mmHg"
            handleChange={(value) => handleChange("rightResting", value)}
            required
            isError={!rightResting}
            enableErrorText={true}
            helperText="ABI (Right) Resting is required."
            disabled={restProps?.isLocked}
          />
          {showCondition ? (
            <div className="new-input-right-ultrasound-question" style={{ gap: "5px" }}>
              <EditableFormInput
                handleChange={(value) => handleChangeCustom(value, "abi_right_resting")}
                required
                isError={!rightResting}
                value={isFinite(data?.abi_right_resting) ? data?.abi_right_resting : 0}
              />
              <div
                className="actionContent"
                style={{
                  marginTop: 0,
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                {!hideRightAbiResting && <AiOutlineRedo color="#0f62fe" style={{ fontSize: "22px" }} onClick={checkRightRAbi} />}
              </div>
            </div>
          ) : null}
        </div>
        <div className="resourceInfo d-flex required-field newFlex">
          <EditableInputWithDiv
            isErrorTextSpacing={true}
            label="ABI (Left) Resting (Ankle SBP / Brachial SBP)"
            value={leftResting}
            subLabel="BP"
            unit="mmHg"
            handleChange={(value) => handleChange("leftResting", value)}
            required
            isError={!leftResting}
            enableErrorText={true}
            helperText="ABI (Left) Resting is required."
            disabled={restProps?.isLocked}
          />
          {showCondition ? (
            <div className="new-input-right-ultrasound-question" style={{ gap: "5px" }}>
              <EditableFormInput
                required
                isError={!leftResting}
                handleChange={(value) => handleChangeCustom(value, "abi_left_resting")}
                value={isFinite(data?.abi_left_resting) ? data?.abi_left_resting : 0}
              />
              <div
                className="actionContent"
                style={{
                  marginTop: 0,
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                {!hideLeftAbiResting && <AiOutlineRedo color="#0f62fe" style={{ fontSize: "22px" }} onClick={checkLeftRAbi} />}
              </div>
            </div>
          ) : null}
        </div>
        <div className="resourceInfo d-flex required-field newFlex">
          <EditableInputWithDiv
            isErrorTextSpacing={true}
            label="ABI (Right) Exercise"
            value={rightExercise}
            subLabel="BP"
            unit="mmHg"
            handleChange={(value) => handleChange("rightExercise", value)}
            required
            isError={!rightExercise}
            enableErrorText={true}
            helperText="ABI (Right) Exercise is required."
            disabled={restProps?.isLocked}
          />
          {showCondition ? (
            <div className="new-input-right-ultrasound-question" style={{ gap: "5px" }}>
              <EditableFormInput
                required
                isError={!rightExercise}
                handleChange={(value) => handleChangeCustom(value, "abi_right_exercise")}
                value={isFinite(data?.abi_right_exercise) ? data?.abi_right_exercise : 0}
              />
              <div
                className="actionContent"
                style={{
                  marginTop: 0,
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                {!hideRightAbiExercise && <AiOutlineRedo color="#0f62fe" style={{ fontSize: "22px" }} onClick={checkRightEAbi} />}
              </div>
            </div>
          ) : null}
        </div>
        <div className="resourceInfo d-flex required-field newFlex">
          <EditableInputWithDiv
            isErrorTextSpacing={true}
            label="ABI (Left) Exercise"
            value={leftExercise}
            subLabel="BP"
            unit="mmHg"
            handleChange={(value) => handleChange("leftExercise", value)}
            required
            isError={!leftExercise}
            enableErrorText={true}
            helperText="ABI (Left) Exercise is required."
            disabled={restProps?.isLocked}
          />
          {showCondition ? (
            <div className="new-input-right-ultrasound-question" style={{ gap: "5px" }}>
              <EditableFormInput
                required
                isError={!leftExercise}
                handleChange={(value) => handleChangeCustom(value, "abi_left_exercise")}
                value={isFinite(data?.abi_left_exercise) ? data?.abi_left_exercise : 0}
              />
              <div
                className="actionContent"
                style={{
                  marginTop: 0,
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                {!hideLeftAbiExercise && <AiOutlineRedo color="#0f62fe" style={{ fontSize: "22px" }} onClick={checkLeftEAbi} />}
              </div>
            </div>
          ) : null}
        </div>
        <div className="resourceInfo d-flex required-field newFlex">
          <EditableInputWithSingleDiv
            isErrorTextSpacing={true}
            label="Toe Pressure (Right)"
            value={rightToePressure}
            subLabel="BP"
            unit="mmHg"
            handleChange={(value) => handleChange("rightToePressure", value)}
            required
            isError={!rightToePressure}
            enableErrorText={true}
            helperText="Toe Pressure (Right) is required."
            disabled={restProps?.isLocked}
          />
          {showCondition ? (
            <div className="new-input-right-ultrasound-question" style={{ gap: "5px" }}>
              <EditableFormInput
                required
                isError={!rightToePressure}
                handleChange={(value) => handleChangeCustom(value, "tbi_right")}
                value={isFinite(data?.tbi_right) ? data?.tbi_right : 0}
              />
              <div
                className="actionContent"
                style={{
                  marginTop: 0,
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                {!hideTbiRight && <AiOutlineRedo color="#0f62fe" style={{ fontSize: "22px" }} onClick={checkRightT} />}
              </div>
            </div>
          ) : null}
        </div>
        <div className="resourceInfo d-flex required-field newFlex">
          <EditableInputWithSingleDiv
            isErrorTextSpacing={true}
            label="Toe Pressure (Left)"
            value={leftToePressure}
            subLabel="BP"
            unit="mmHg"
            handleChange={(value) => handleChange("leftToePressure", value)}
            required
            isError={!leftToePressure}
            enableErrorText={true}
            helperText="Toe Pressure (Left) is required."
            disabled={restProps?.isLocked}
          />
          {showCondition ? (
            <div className="new-input-right-ultrasound-question" style={{ gap: "5px" }}>
              <EditableFormInput
                required
                isError={!leftToePressure}
                handleChange={(value) => handleChangeCustom(value, "tbi_left")}
                value={isFinite(data?.tbi_left) ? data?.tbi_left : 0}
              />
              <div
                className="actionContent"
                style={{
                  marginTop: 0,
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                {!hideTbiLeft && <AiOutlineRedo color="#0f62fe" style={{ fontSize: "22px" }} onClick={checkLeftT} />}
              </div>
            </div>
          ) : null}
        </div>
        {QUESTIONS.map((question, index) => (
          <div
            key={`question-${index}`}
            className="resourceInfo d-flex"
            style={{
              justifyContent: "space-between",
              alignItems: "flex-start",
              gap: 8,
              borderTop: index === 0 ? ".5px solid #dfe3e6" : 0,
              borderBottom: ".5px solid #dfe3e6",
              padding: "0.5rem 0 5px",
              margin: "0 0.5rem",
              marginTop: index === 0 ? "0.5rem" : 0,
            }}
          >
            <p
              style={{
                fontSize: 12,
                fontWeight: "bold",
              }}
            >
              {question}
            </p>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: 12,
              }}
            >
              {BUTTONS.map(({ text, value }, indexBtn) => (
                <button
                  disabled={restProps?.isLocked}
                  key={`btn-${index}-${indexBtn}`}
                  style={{
                    height: "2rem",
                    backgroundColor: data[`question-${index}`] && data[`question-${index}`].indexOf(value) > -1 ? "#dbe3ee" : "#f4f7fb",
                    color: "#152935",
                    border: data[`question-${index}`] && data[`question-${index}`].indexOf(value) > -1 ? "1px solid #0f62fe" : "1px solid #dfe3e6",
                    minWidth: 75,
                    maxWidth: 75,
                    padding: "0 0.5rem",
                    cursor: "pointer",
                    fontSize: 12,
                    fontWeight: 400,
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                  }}
                >
                  {text}
                </button>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Questionnaire;
