import { Drawer } from 'antd';
import { debounce } from 'lodash';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AutoCompleteComponent from '../../../../common/components/AutoComplete';
import EditFormInput from '../../../../common/components/EditableText/EditableFormInput';
import ScrollWrapper from '../../../../common/components/ScrollBar';
import SubDataTable from '../../../../common/components/SubDataTable';
import {
  addInventoryCostHistory, addNewInventory,
  deleteInventoryItem, setGlobalLoading, setInventoryData, updateInventoryNotes, updateNarcoticInventory
} from '../../../../store/actions';

const filterSuggestion = (suggestions, userInput) => {
  let filteredSuggestions = [];
  if (!userInput) {
    filteredSuggestions = suggestions;
  }
  if (userInput) {
    filteredSuggestions = suggestions.filter(
        (suggestion) =>
          suggestion.toLowerCase().indexOf(userInput.toLowerCase()) > -1,
    );
  }
  return filteredSuggestions;
};

const titleStyle = {
  textAlign: 'left',
  paddingLeft: '11px',
  color: 'rgba(0, 0, 0, 0.65)',
  fontWeight: 600,
  borderLeft: '1px solid #dfe3e6',
  display: 'flex',
  height: '100%',
  alignItems: 'center',
  fontSize: '14px',
};

const getTitle = () => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        justifyContent: 'space-between',
      }}
    >
      <div
        style={{...titleStyle, width: '50%', paddingLeft: 0, borderLeft: 0}}
      >
        Date
      </div>
      <div style={{width: '50%', textAlign: 'left'}}>Cost Per Item</div>
    </div>
  );
};

const NarcoticSidebar = React.forwardRef(
    ({resource, isShow, handleCloseSidebar}, ref) => {
      const inventoryItem = useSelector((state) => state.inventory.inventory);
      const loading = useSelector((state) => state.inventory.loadingAPI);
      const updated = useSelector((state) => state.inventory.isUpdated);
      const deleted = useSelector((state) => state.inventory.isDeleted);
      const costHistory = useSelector(
          (state) => state.inventory.inventoryCostHistory,
      );
      const loadingCost = useSelector(
          (state) => state.inventory.loadingCostHistory,
      );
      const sideNavStatus = useSelector((state) => state.common.sideNavStatus);
      const locations = useSelector((state) => state.common.locations);

      const [notes, setNotes] = useState([]);
      const [searchInput, setInput] = useState('');
      const [item, setItem] = useState();
      const [isAdding, setIsAdding] = useState(false);
      const [purchaseNumber, setPurchaseNumber] = useState();
      const [isPurchase, setIsPurchase] = useState(false);
      const [isDelete, setIsDelete] = useState(false);

      const dispatch = useDispatch();

      useEffect(() => {
        if (inventoryItem) {
          const initialNotes = inventoryItem.notes ?
          inventoryItem.notes.split(',') :
          [];
          setNotes(initialNotes);
        }
        setItem(inventoryItem);
        setIsDelete(false);
      }, [inventoryItem]);

      useEffect(() => {
        if (updated) {
          dispatch(setInventoryData('isUpdated', false));
          setIsAdding(false);
        }
        if (deleted) {
          dispatch(setInventoryData('isDeleted', false));
          dispatch(setInventoryData('inventory', null));
          dispatch(setInventoryData('inventoryCostHistory', []));
          setIsDelete(false);
          setItem(null);
        }
      }, [updated, deleted]);

      useEffect(() => {
        if (inventoryItem && notes.length) {
          const itemNotes = notes.join(',');
          const updatedItem = {...item, notes: itemNotes};
          setItem(updatedItem);
        }
      }, [notes]);

      useEffect(() => {
        if (isShow && item) {
          dispatch(setGlobalLoading(loading || loadingCost));
        }
      }, [loading, loadingCost, isShow, item]);

      const noteRows = useMemo(() => {
        if (searchInput) {
          return filterSuggestion(notes, searchInput);
        }
        return notes;
      }, [notes, searchInput]);

      const handleSearch = (search) => {
        setInput(search);
      };

      const updateNotes = (note) => {
        const request = {
          type: inventoryItem.type,
          notes: note,
        };
        dispatch(updateInventoryNotes(inventoryItem?.id, request));
      };

      const addNote = () => {
        const newNotes = [...notes, searchInput];
        setNotes(newNotes);
        setInput('');
        updateNotes(newNotes.join(','));
      };

      const removeNote = (row, index) => {
        const newNotes = [...notes];
        newNotes.splice(index, 1);
        setNotes(newNotes);
        updateNotes(newNotes.join(','));
      };

      const openAddingForm = () => {
        setIsAdding(true);
      };

      const handleAddInventory = (inventory) => {
        dispatch(addNewInventory(inventory));
      };

      const cost_per_item = useMemo(() => {
        if (item && item.cost) {
          const quantity = item.quantity ? item.quantity : 1;
          return (item.cost / quantity).toFixed(2);
        }
        return null;
      }, [item]);

      const costRows = useMemo(() => {
        if (costHistory && costHistory.length) {
          return costHistory.map((ch) => ({
            item: moment(ch.created_at).format('MM/DD/YYYY'),
            amount: '$' + parseFloat(ch.cost_per_item).toFixed(2),
          }));
        }
        if (cost_per_item) {
          return [
            {
              item: moment().format('MM/DD/YYYY'),
              amount: '$' + parseFloat(cost_per_item).toFixed(2),
            },
          ];
        }
        return [];
      }, [costHistory]);

      const validatePurchaseNumber = () => {
        const regex = /^\d+$/;
        if (regex.test(purchaseNumber) && purchaseNumber > 0) {
          return false;
        }
        return true;
      };

      // Save updated item
      const onUpdateItem = (value) => {
        const costRequest = {
          type: 'narcotic',
          cost_per_item: cost_per_item,
        };

        dispatch(updateNarcoticInventory(inventoryItem?.id, value));
        if (!costHistory.length && inventoryItem.cost) {
          const quantity = inventoryItem.quantity ? inventoryItem.quantity : 1;
          const costItem =
          Math.round((inventoryItem.cost * 100) / quantity) / 100;

          dispatch(
              addInventoryCostHistory(inventoryItem?.id, {
                type: inventoryItem.type,
                cost_per_item: costItem,
              }),
          );
        }
        if (
          inventoryItem.cost &&
        value.cost &&
        inventoryItem.cost !== value.cost
        ) {
          dispatch(addInventoryCostHistory(inventoryItem?.id, costRequest));
        }
      };

      const changeHandler = useCallback(
          debounce((value) => onUpdateItem(value), 400),
          [],
      );

      const handleChange = (type, value) => {
        const newValue = {
          ...item,
          [type]: value,
        };
        setItem(newValue);
        changeHandler(newValue);
      };

      const onChangeInventory = (type) => (value) => {
        handleChange(type, value);
      };

      // Delete inventory item
      const onDelete = () => {
        if (!isDelete) {
          setIsDelete(true);
          return;
        }
        if (isDelete) {
          const param = {
            type: 'narcotic',
          };
          dispatch(deleteInventoryItem(inventoryItem?.id, param));
          setIsDelete(false);
        }
      };

      if (!item) {
        return null;
      }

      return (
        <Drawer
          title={!isAdding ? (item?.name ? item?.name : ' ') : ' '}
          placement="right"
          closable
          className={sideNavStatus ? 'wide' : ''}
          onClose={handleCloseSidebar}
          visible={isShow}
          destroyOnClose
          key="right"
        >
          <div ref={ref} className="resourceContainer new">
            <ScrollWrapper>
              {!isAdding && (
                <div
                  className="resourceContent"
                  style={{
                    flex: 'unset',
                    paddingLeft: '24px',
                    paddingRight: '20px',
                  }}
                >
                  <div className="resourceInfo d-flex">
                    <EditFormInput
                      label={'Name'}
                      value={item?.name}
                      handleChange={onChangeInventory('name')}
                    />
                  </div>
                  <div className="resourceInfo d-flex">
                    <EditFormInput
                      label={'Product No'}
                      value={item.product_number}
                    />
                  </div>
                  <div className="resourceInfo d-flex">
                    <EditFormInput
                      label={'Code No'}
                      value={item.code_number}
                      handleChange={onChangeInventory('code_number')}
                    />
                  </div>
                  <div className="resourceInfo d-flex">
                    <EditFormInput
                      label={'Category'}
                      value={item.type}
                      handleChange={onChangeInventory('type')}
                      type={'check'}
                      options={[
                        {value: 'angiographic', label: 'Angiographic Supply'},
                        {value: 'narcotic', label: 'Narcotic Inventory'},
                        {value: 'other', label: 'Medications'},
                      ]}
                      valueKey={'label'}
                      optionKey={'value'}
                    />
                  </div>
                  <div className="resourceInfo d-flex">
                    <EditFormInput
                      label={'Brand'}
                      value={item.brand}
                      handleChange={onChangeInventory('brand')}
                    />
                  </div>
                  <div className="resourceInfo d-flex">
                    <EditFormInput
                      label={'PAR Level'}
                      value={item.par_level}
                      handleChange={onChangeInventory('par_level')}
                    />
                  </div>
                  <div className="resourceInfo d-flex">
                    <EditFormInput
                      label={'Bin Rate'}
                      value={item.bin_rate}
                      handleChange={onChangeInventory('bin_rate')}
                    />
                  </div>
                  <div className="resourceInfo d-flex">
                    <EditFormInput
                      label={'Location'}
                      value={item.location}
                      handleChange={onChangeInventory('location')}
                      type={'check'}
                      options={locations || []}
                      optionKey={'value'}
                      valueKey={'value'}
                    />
                  </div>
                  <div className="resourceInfo d-flex">
                    <EditFormInput
                      label={'Specific Location'}
                      value={item.specific_location}
                      handleChange={onChangeInventory('specific_location')}
                    />
                  </div>
                  <div className="resourceInfo d-flex">
                    <EditFormInput
                      label={'Supplier E-Mail'}
                      value={item.supplier_email}
                      handleChange={onChangeInventory('supplier_email')}
                    />
                  </div>
                  <div className="resourceInfo d-flex">
                    <EditFormInput
                      label={'Supplier Fax'}
                      value={item.supplier_fax}
                      handleChange={onChangeInventory('supplier_fax')}
                    />
                  </div>
                  <div className="resourceInfo d-flex">
                    <EditFormInput
                      label={'Quantity per unit'}
                      value={item.quantity}
                      handleChange={onChangeInventory('quantity')}
                    />
                  </div>
                  <div className="resourceInfo d-flex">
                    <EditFormInput
                      label={'Unit'}
                      value={item.unit}
                      handleChange={onChangeInventory('unit')}
                      type={'check'}
                      options={[
                        {value: 'Case', label: 'Case'},
                        {value: 'Box', label: 'Box'},
                        {value: 'Pack', label: 'Pack'},
                        {value: 'Each', label: 'Each'},
                      ]}
                      valueKey={'label'}
                      optionKey={'value'}
                    />
                  </div>
                  <div className="resourceInfo d-flex">
                    <EditFormInput
                      label={'Cost'}
                      value={item.cost}
                      prefix={'$'}
                      handleChange={onChangeInventory('cost')}
                    />
                  </div>
                  <div className="resourceInfo d-flex">
                    <EditFormInput
                      label={'Cost/Item'}
                      value={cost_per_item}
                      prefix={'$'}
                    />
                  </div>
                  <div
                    className="resourceInfo d-flex"
                    style={{marginTop: '0.5rem'}}
                  >
                    <b style={{minWidth: '35%'}}>Cost History </b>
                  </div>
                  <div className="resourceInfo">
                    <SubDataTable
                      title={getTitle()}
                      data={costRows}
                      half={true}
                    />
                  </div>
                  <hr />

                  <div className="resourceInfo d-flex">
                    <b style={{minWidth: '35%'}}>Notes </b>
                  </div>
                  <div className="resourceInfo d-flex">
                    <AutoCompleteComponent
                      title="narcotic-inventory-notes"
                      rows={noteRows}
                      removeItem={removeNote}
                      addItem={addNote}
                      handleSearch={handleSearch}
                      disableOptions={true}
                      placeholder={'Add Information Note'}
                      disableSearchIcon={true}
                    />
                  </div>
                  <div
                    className="resourceInfo d-flex"
                    style={{marginTop: '2rem', alignItems: 'center'}}
                  >
                    <b style={{marginRight: '0.25rem'}}>Purchase </b>
                    <div
                      style={{
                        width: '110px',
                        minWidth: '110px',
                        maxWidth: '110px',
                      }}
                    >
                      <EditFormInput
                        label={'Number'}
                        required={true}
                        value={purchaseNumber}
                        enableErrorText={isPurchase}
                        handleChange={(value) => setPurchaseNumber(value)}
                        isError={validatePurchaseNumber()}
                        helperText={''}
                      />
                    </div>
                    <b style={{marginLeft: '0.25rem'}}>{` at $${
                    item.cost ? '$' + parseFloat(item.cost).toFixed(2) : ''
                    } ${item.unit || ''}`}</b>
                  </div>
                  <hr />
                </div>
              )}
              {!isAdding && (
                <div
                  className="actionContent"
                  style={{
                    marginTop: '1.5rem',
                    paddingRight: '1rem',
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                  }}
                >
                  <button
                    className={isDelete ? 'outlined-delete-btn' : 'outlined-btn'}
                    style={{marginTop: '0.5rem'}}
                    onClick={onDelete}
                  >
                    {isDelete ? 'Confirm Remove?' : 'Remove'}
                  </button>
                </div>
              )}
            </ScrollWrapper>
          </div>
        </Drawer>
      );
    },
);

export default NarcoticSidebar;
