import React, { Component } from "react";
import ReactPlayer from "react-player";
import { connect } from "react-redux";
import { setURLImage } from "../../../store/actions";
import Dicom from "../../components/Dicom";
import { Tabs } from "@material-ui/core";

class VideoImageViewer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      images: [],
      Hidelink: false,
      Videofile: false,
      imageCheck: "",
      width: 800,
      height: 500,
      url: "",
      checkRender: true,
      idActive: -1,
      isPlay: true,
    };
  }

  componentWillReceiveProps(nextProp) {
    if (nextProp.image_list && nextProp.image_list.length > 0 && nextProp?.id_image === 0) {
      this.setState({
        Hidelink: false,
        images: (nextProp.image_list && nextProp.image_list[0] && nextProp.image_list[0].images) || [],
      });
    } else if (nextProp.image_list && nextProp.image_list.length > 0 && nextProp?.id_image > 0) {
      this.setState({
        Hidelink: false,
        images: (nextProp.image_list && nextProp.image_list[`${nextProp?.id_image}`] && nextProp.image_list[`${nextProp?.id_image}`].images) || [],
      });
    } else if (!nextProp.image_list || nextProp.image_list.length === 0) {
      this.setState({
        Hidelink: true,
      });
    }
    if (nextProp?.resetClick) {
      this.setState({
        images: [],
        Hidelink: false,
        Videofile: false,
        imageCheck: "",
        width: 800,
        height: 500,
        url: "",
        checkRender: false,
        isPlay: true,
      });
      nextProp.setResetClick(false);
    }
  }

  actionClick = async (value, id) => {
    this.setState({
      idActive: id,
    });
    if (value.indexOf("MP4") > -1 || value.indexOf("mp4") > -1) {
      await this.setState({
        Videofile: true,
        imageCheck: "",
        url: value,
      });
    } else {
      await this.setState({
        Videofile: false,
        checkRender: false,
      });
      if (!this.state.Videofile) {
        await this.props.setURLImage(value);
        this.setState({
          checkRender: true,
        });
      }
    }
  };

  render() {
    const { Hidelink, checkRender } = this.state;
    const { Videofile, url, idActive } = this.state;
    return (
      <div style={{ height: "100%", width: "100%" }}>
        {!Videofile ? (
          <div
            style={{
              backgroundColor: "black",
              height: "calc(100% - 85px)",
              width: "100%",
            }}
          >
            {checkRender ? <Dicom widthMax={"100%"} /> : <div className="size-dicom-default-view"></div>}
          </div>
        ) : (
          <div className="player-wrapper video-size-dicom">
            <ReactPlayer
              ref="videos"
              className="react-player"
              url={url}
              width="100%"
              height="100%"
              playing={this.state.isPlay}
              muted={true}
              volume={0}
              loop
              playsinline={true}
              autoPlay
            />
            <div className="player-control">
              <div className="player-control-container">
                <div
                  className="button-video-back"
                  onClick={() => {
                    this.setState({
                      isPlay: false,
                    });
                    this.refs.videos.seekTo(this.refs.videos.getCurrentTime() - 1);
                  }}
                >
                  <i className="play-backwards"></i>
                </div>
                <div
                  className="button-video-play-pause"
                  onClick={() => {
                    this.setState({
                      isPlay: !this.state.isPlay,
                    });
                  }}
                >
                  {this.state.isPlay ? <i className="play-pause"></i> : <i className="play-button"></i>}
                </div>
                <div
                  className="button-forward"
                  onClick={() => {
                    this.setState({
                      isPlay: false,
                    });
                    this.refs.videos.seekTo(this.refs.videos.getCurrentTime() + 1);
                  }}
                >
                  <i className="play-forwards"></i>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="tabHeaders" style={{ height: "10px" }}>
          {!Hidelink ? (
            <div
              style={{
                height: 80,
                marginTop: 5,
              }}
              className="reponsive-image-chart-dcom"
            >
              <Tabs
                TabIndicatorProps={{ style: { display: "none", backgroundColor: "red" } }}
                scrollButtons="auto"
                variant="scrollable"
                classes="test-ad"
                centered={true}
              >
                {this.state.images.map((items, index) => (
                  <div
                    key={`tabHeaders-${items.id}`}
                    className={`tabHeaders-item ${idActive === items?.id ? "active" : ""} image-dicom`}
                    onClick={() => {
                      this.actionClick(items.image_location, items?.id);
                    }}
                    style={{
                      backgroundImage: "url(" + items.thumbnail_url + ")",
                      marginLeft: index > 0 ? 5 : 0,
                    }}
                  ></div>
                ))}
              </Tabs>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  image_list: state.common.image_list,
  id_image: state.common?.id_image,
});

const mapDispatchToProps = {
  setURLImage,
};

export default connect(mapStateToProps, mapDispatchToProps)(VideoImageViewer);
