import React, { useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ToggleButton from '../ToggleButton';
import SideNav from '../SideNav';
import HeaderMenu from '../HeaderMenu';
import HeaderIconMenu from '../HeaderIconMenu';
import Loading from '../Loading';
import './style.scss';
import { getLocal } from '../../../helpers/Local';

export default function Header() {
  const location = useLocation();
  const [openSideMenu, setSideMenu] = useState(false);
  const isLogin = location.pathname === '/login';
  const isReset = location.pathname === '/resetPassword';
  const hideNavLinks = isLogin || isReset;
  const isMonitor = (location.pathname.includes('/mon/'))
  const userRole = getLocal("cvai-auth-role");
  const loading = useSelector((state) => state.dashboard.loadingPatients);
  const globalLoading = useSelector((state) => state.common.globalLoading);

  const handleClick = () => {
    if (openSideMenu) {
      setSideMenu(!openSideMenu);
    }
  };

  return (
    <header className={`headerNav ${hideNavLinks ? 'hide' : ''}`}>
      <div className='leftContainer headerWrapper'>
        <div className='brandContainer'>
          <div className='toggleMenu' style={{ marginRight: 0 }}>
            <ToggleButton handleClick={() => { setSideMenu(true) }} openSideBar={openSideMenu} />
          </div>
          <div className='logoContainer' style={{ paddingLeft: (isMonitor ? '10px' : '') }}>
            <NavLink
              to={userRole === "patient" ?
                "/patient-dashboard" :
                userRole === "provider" ?
                  "/referral-dashboard" : "/"
              }
            >
              <img
                src={'/images/faact_logo_v2.svg'}
                alt="faact"
                style={{ height: '33px', marginLeft: '-10px' }}
              />
            </NavLink>
          </div>
          {(globalLoading || loading) && (
            <div style={{ position: "relative" }}>
              <Loading
                extra={{
                  width: '40px',
                  height: '40px',
                  position: 'absolute',
                  transform: 'translate(0%, -50%)',
                }}
                color="#ffffff"
              />
            </div>
          )}
        </div>

        <HeaderMenu shouldDisplay={!isMonitor && userRole === 'user'} />
        {!isMonitor && (
          <SideNav handleClick={handleClick} active={openSideMenu} />
        )}
      </div>
      <div className='rightContainer headerWrapper' style={{ display: (isMonitor ? 'none' : '') }}>
        <HeaderIconMenu />
      </div>
    </header>
  );
}
