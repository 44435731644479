import FormControl from "@material-ui/core/FormControl";
import InputAdornment from "@material-ui/core/InputAdornment";
import InputLabel from "@material-ui/core/InputLabel";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import TextField from "@material-ui/core/TextField";
import { DatePicker, Input, Select, TimePicker } from "antd";
import moment from "moment";
import React, { useEffect, useMemo, useRef, useState } from "react";
import InputMask from "react-input-mask";
import MultiSelect from "react-multi-select-component";
import { ExpandSVG, RedRequiredCircleSVG, SearchSVG } from "../icons/SVGIcon";
import CustomItem from "./CustomItem";
import "./style.scss";

const { Option } = Select;
const { Password } = Input;
const dateFormat = "MM/DD/YYYY";
const timeFormat = "h:mm A";
const phoneMask = "(999) 999-9999";
const ssnMask = "999-99-9999";
const dateMask = "99/99/9999";
const specialMask = "999 / 999";

const getLocaleString = (str) => {
  if (!str) {
    return "0.00";
  }
  const num = parseFloat(str).toFixed(2);
  const decimals = num.split(".")[1];
  if (!decimals || decimals === "00") {
    return Math.round(parseFloat(str)).toLocaleString() + ".00";
  }
  if (decimals.charAt(1) === "0") {
    return (Math.round(parseFloat(str) * 100) / 100).toLocaleString() + "0";
  }
  return (Math.round(parseFloat(str) * 100) / 100).toLocaleString();
};

const EditableFormInput = ({
  multiline,
  label,
  value,
  handleChange,
  type,
  isError,
  required,
  options,
  optionKey,
  valueKey,
  enableErrorText,
  helperText,
  mask,
  focusWhite,
  fullWidth,
  noEditable,
  prefix,
  searchIcon,
  unit,
  autoFocus,
  tabIndex,
  multiple,
  reset,
  onCustomBlur,
  customStyle,
  onChangeSearch,
  autoClearSearchValue,
  notFoundContent,
  hideIconSelect,
  disabled,
  rmBorder,
  disableSearch = false,
  disableMultipleCheckAll,
  toGetAll,
  disableFilter,
  ref,
  allowClear = false,
  isHospitalProcedure,
  mainStyle,
  isBackgroundTransparent,
  isLoginScreen,
  isHospitalCharting,
  disabledHours,
  id,
  isErrorTextSpacing,
  rows,
  isChangeFontSize,
}) => {
  const [error, setError] = useState(false);
  const [enableHelper, setEnableHelper] = useState(false);
  const [focus, setFocus] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [selectedValue, setSelectedValue] = useState(null);
  const [openTime, setOpenTime] = useState(false);
  const [remove, setRemove] = useState(false);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const selectRef = useRef(null);

  useEffect(() => {
    if (selectRef && selectRef.current && selectRef.current.rcSelect.inputRef) {
      selectRef.current.rcSelect.inputRef.tabIndex = tabIndex;
    }
  }, [selectRef]);

  useEffect(() => {
    const handleKeyDown = ({ key }) => {
      if (key === "Tab" && selectRef && tabIndex >= 0) {
        setFocus(document.activeElement.tabIndex === tabIndex);
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  useEffect(() => {
    if (!isError) {
      setError(false);
      setEnableHelper(false);
    }
    if (enableErrorText && required && isError) {
      setEnableHelper(true);
      setError(true);
    }
    if (required && isError) {
      setEnableHelper(true);
      setError(true);
    }
    if (value && prefix !== "$") {
      setInputValue(value);
    }

    if (value && prefix === "$") {
      const str = value.toString().replace(/,/g, "");
      if (focus) {
        setInputValue(str);
      } else {
        setInputValue(getLocaleString(str));
      }
    }
  }, [value, isError, enableErrorText]);

  const enableBlur = () => {};

  const changeHandler = (str, op) => handleChange && handleChange(str, op);

  const checkBlurState = (e) => {
    if (prefix === "$") {
      const str = inputValue.replace(/,/g, "");
      setInputValue(getLocaleString(str));
      changeHandler(str);
    }
    setFocus(false);
  };

  const onFocusState = (e) => {
    if (prefix === "$") {
      const str = inputValue.replace(/,/g, "");
      setInputValue(str);
    }
    setFocus(true);
  };

  const onChange = (e) => {
    const targetValue = e.target?.value;
    const str = targetValue.replace(/[^0-9.'"]/g, "");
    if (unit === "℉" && str.length < 6) {
      if (str.length === 3 && !str.includes(".")) {
        const checkPush = str.split("").map((r, index) => {
          if (index === 2) {
            return `.${r}`;
          }
          return r;
        });
        setInputValue(checkPush.join(""));
        changeHandler(checkPush.join(""));
      } else {
        setInputValue(str);
        changeHandler(str);
      }
    } else if (unit === "mnHg" && str.length < 4) {
      setInputValue(str);
      changeHandler(str);
    } else if (unit === "Kg" && str.length < 6) {
      if (str.length === 4 && !str.includes(".")) {
        const checkPush = str.split("").map((r, index) => {
          if (index === 2) {
            return `.${r}`;
          }
          return r;
        });
        setInputValue(checkPush.join(""));
        changeHandler(checkPush.join(""));
      } else {
        setInputValue(str);
        changeHandler(str);
      }
    } else if (unit === "BPM" && str.length < 4) {
      setInputValue(str);
      changeHandler(str);
    } else if (unit === "RR" && str.length < 3) {
      setInputValue(str);
      changeHandler(str);
    } else if (unit === "%" && str.length < 4) {
      setInputValue(str);
      changeHandler(str);
    } else if (unit === "/ 10" && str.length < 3) {
      setInputValue(str);
      changeHandler(str);
    } else if (unit === "Inches" && str.length < 3) {
      setInputValue(str);
      changeHandler(str);
    } else if (
      unit !== "℉" &&
      unit !== "mnHg" &&
      unit !== "Kg" &&
      unit !== "BPM" &&
      unit !== "RR" &&
      unit !== "/ 10" &&
      unit !== "Inches" &&
      unit !== "%"
    ) {
      setInputValue(targetValue);

      if (prefix === "$") {
        changeHandler(targetValue.replace(/,/g, ""));
      } else {
        changeHandler(targetValue);
      }
    }
  };

  const onChangeSelect = (opt, value) => {
    if (opt) {
      const findoption = options.find((r) => r && r?.id === opt);
      changeHandler(value[optionKey] || opt, findoption);
    } else {
      changeHandler(null);
    }
    setFocus(false);
    if (selectRef?.current) {
      selectRef.current.blur();
    }
  };

  const onChangeMultiSelect = (value) => {
    changeHandler(value);
  };

  const onSearch = (opt, value) => {
    if (onChangeSearch && opt) {
      onChangeSearch(value || opt);
    }
  };

  const onChangeDate = (date, dateString) => {
    changeHandler(dateString);
  };

  const onChangeTime = (time, timeString) => {
    changeHandler(timeString);
  };

  const lableContent = useMemo(() => {
    if (required) {
      return <span>{label}</span>;
    }
    return label;
  }, [required, label]);

  const errorText = useMemo(() => {
    if (enableHelper && helperText) {
      return (
        <span>
          <RedRequiredCircleSVG /> {helperText || ""}
        </span>
      );
    }
    return "";
  }, [enableHelper]);

  const maskValue = useMemo(() => {
    if (mask === "phone") {
      return phoneMask;
    }
    if (mask === "ssn") {
      return ssnMask;
    }
    if (mask === "date") {
      return dateMask;
    }
    if (mask === "special") {
      return specialMask;
    }
    return "";
  }, [mask]);

  const getValue = () => {
    const optionValue = options.find((condition) => condition[optionKey] === value || condition[valueKey] === value);
    if (optionValue) {
      return optionValue[valueKey];
    }
    return value;
  };

  return (
    <div
      className={`editableText material ${fullWidth ? "fullWidth" : ""} ${disabled ? "disabled" : ""} ${isBackgroundTransparent && "bgTransparent"} ${
        isErrorTextSpacing && "isErrorTextSpacing"
      }`}
      style={{ ...mainStyle }}
    >
      {noEditable && !mask && (
        <TextField
          id={id}
          multiline={multiline}
          error={error}
          helperText={errorText}
          ref={ref}
          variant="outlined"
          label={lableContent}
          value={value || ""}
          InputProps={{ inputProps: { tabIndex } }}
          style={{ pointerEvents: "none" }}
        />
      )}
      {noEditable && mask && (
        <InputMask mask={mask === "phone" ? phoneMask : ssnMask} value={value || ""} onBlur={enableBlur} maskPlaceholder={null}>
          <TextField
            id={id}
            variant="outlined"
            label={lableContent}
            value={value || ""}
            ref={ref}
            InputProps={{ inputProps: { tabIndex } }}
            style={{ pointerEvents: "none" }}
          />
        </InputMask>
      )}
      {!noEditable && prefix && !unit && !type && (
        <FormControl fullWidth variant="outlined" className={`${errorText ? "error" : ""} ${focus ? "Mui-focused Mui-focused" : ""}`}>
          <InputLabel ref={ref} htmlFor={id} className={`legend-label ${errorText ? "error" : ""}`}>
            {lableContent}
          </InputLabel>
          <OutlinedInput
            id={id}
            value={inputValue || ""}
            onChange={onChange}
            ref={ref}
            error={errorText}
            onFocus={onFocusState}
            autoFocus={!!autoFocus}
            onBlur={checkBlurState}
            inputProps={{ tabIndex }}
            style={{ pointerEvents: disabled ? "none" : "auto" }}
            startAdornment={<InputAdornment position="start">{prefix}</InputAdornment>}
          />
          {!!errorText && <p className={`MuiFormHelperText-root MuiFormHelperText-contained Mui-error`}>{errorText}</p>}
        </FormControl>
      )}
      {!noEditable && unit && !prefix && !type && (
        <FormControl fullWidth variant="outlined" className={`${errorText ? "error" : ""} ${focus ? "Mui-focused Mui-focused" : ""}`}>
          <InputLabel ref={ref} htmlFor={id} className={`legend-label ${errorText ? "error" : ""}`}>
            {lableContent}
          </InputLabel>
          <OutlinedInput
            id={id}
            value={value || ""}
            ref={ref}
            onChange={onChange}
            error={errorText}
            onFocus={() => setFocus(true)}
            autoFocus={!!autoFocus}
            inputProps={{ tabIndex }}
            style={{ pointerEvents: disabled ? "none" : "auto" }}
            endAdornment={<InputAdornment position="end">{unit}</InputAdornment>}
          />
          {!!errorText && <p className={`MuiFormHelperText-root MuiFormHelperText-contained Mui-error`}>{errorText}</p>}
        </FormControl>
      )}
      {!noEditable && prefix && unit && !type && (
        <FormControl fullWidth variant="outlined" className={`${errorText ? "error" : ""} ${focus ? "Mui-focused Mui-focused" : ""}`}>
          <InputLabel ref={ref} htmlFor={id} className={`${errorText ? "error" : ""}`}>
            {lableContent}
          </InputLabel>
          <OutlinedInput
            id={id}
            value={inputValue || ""}
            onChange={onChange}
            ref={ref}
            error={errorText}
            autoFocus={!!autoFocus}
            onFocus={onFocusState}
            onBlur={checkBlurState}
            inputProps={{ tabIndex }}
            style={{ pointerEvents: disabled ? "none" : "auto" }}
            startAdornment={<InputAdornment position="start">{prefix}</InputAdornment>}
            endAdornment={<InputAdornment position="end">{unit}</InputAdornment>}
          />
          {!!errorText && <p className={`MuiFormHelperText-root MuiFormHelperText-contained Mui-error`}>{errorText}</p>}
        </FormControl>
      )}
      {!noEditable &&
        !mask &&
        !prefix &&
        !unit &&
        !type &&
        (isLoginScreen ? (
          <TextField
            id={id}
            variant="outlined"
            className={"emailInput"}
            value={value || ""}
            placeholder={"Enter your Username"}
            onChange={onChange}
            ref={ref}
            error={error}
            onBlur={!!onCustomBlur ? onCustomBlur : enableBlur}
            helperText={errorText}
            autoComplete={"off"}
            style={{ pointerEvents: disabled ? "none" : "auto" }}
            autoFocus={!!autoFocus}
            InputProps={{ inputProps: { tabIndex } }}
          />
        ) : (
          <TextField
            id={id}
            variant="outlined"
            className={focusWhite ? "focus-white" : ""}
            label={lableContent}
            value={value || ""}
            onChange={onChange}
            ref={ref}
            error={error}
            onBlur={!!onCustomBlur ? onCustomBlur : enableBlur}
            helperText={errorText}
            autoComplete={"off"}
            style={{ pointerEvents: disabled ? "none" : "auto" }}
            autoFocus={!!autoFocus}
            InputProps={{ inputProps: { tabIndex } }}
          />
        ))}
      {!noEditable && mask && !type && (
        <InputMask
          mask={maskValue}
          value={value || ""}
          onChange={onChange}
          onBlur={!!onCustomBlur ? onCustomBlur : enableBlur}
          maskPlaceholder={null}
        >
          <TextField
            id={id}
            variant="outlined"
            label={lableContent}
            error={error}
            ref={ref}
            helperText={errorText}
            autoFocus={!!autoFocus}
            style={{ pointerEvents: disabled ? "none" : "auto" }}
            InputProps={{ inputProps: { tabIndex } }}
          />
        </InputMask>
      )}
      {!noEditable && type && type === "area" && (
        <TextField
          id={id}
          variant="outlined"
          {...(isHospitalProcedure && { className: "hospital-multiline" })}
          {...(isChangeFontSize && { className: "task-search-area" })}
          label={lableContent}
          value={value || ""}
          multiline={multiline ? multiline : true}
          onChange={onChange}
          error={error}
          ref={ref}
          onBlur={enableBlur}
          helperText={errorText}
          autoComplete={"off"}
          autoFocus={!!autoFocus}
          style={{ ...customStyle, pointerEvents: disabled ? "none" : "auto" }}
          InputProps={{ inputProps: { tabIndex } }}
          rows={rows}
        />
      )}
      {!noEditable && !mask && type && type === "password" && (
        <div className={`MuiFormControl-root MuiTextField-root password-input ${errorText ? "error" : ""} ${focus ? "Mui-focused Mui-focused" : ""}`}>
          <Password
            id={id}
            value={value || ""}
            onChange={onChange}
            ref={ref}
            onFocus={() => setFocus(true)}
            onBlur={() => setFocus(false)}
            autoComplete={"new-password"}
            placeholder={"Enter your Password"}
            tabIndex={tabIndex}
          />
          {!!errorText && <p className={`MuiFormHelperText-root MuiFormHelperText-contained Mui-error`}>{errorText}</p>}
        </div>
      )}
      {!noEditable && type && type === "date" && (
        <div className={`MuiFormControl-root MuiTextField-root date-picker ${errorText ? "error" : ""} ${focus ? "Mui-focused Mui-focused" : ""}`}>
          {!!value && (
            <label className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-outlined MuiInputLabel-shrink datePicker-label">
              {lableContent}
            </label>
          )}
          <DatePicker
            id={id}
            value={!!value && moment(value).isValid() ? moment(value) : null}
            format={dateFormat}
            placeholder={label}
            ref={ref}
            onChange={onChangeDate}
            suffixIcon={<ExpandSVG />}
            tabIndex={tabIndex}
            style={{ pointerEvents: disabled ? "none" : "auto" }}
            onFocus={() => setFocus(true)}
            onBlur={() => setFocus(false)}
          />
          {!!errorText && <p className={`MuiFormHelperText-root MuiFormHelperText-contained Mui-error`}>{errorText}</p>}
        </div>
      )}
      {!noEditable && type && type === "time" && (
        <div className={`MuiFormControl-root MuiTextField-root date-picker ${errorText ? "error" : ""} ${focus ? "Mui-focused Mui-focused" : ""}`}>
          {!!value && (
            <label className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-outlined MuiInputLabel-shrink datePicker-label">
              {lableContent}
            </label>
          )}
          <TimePicker
            style={{
              ...customStyle,
              width: "100%",
              pointerEvents: disabled ? "none" : "auto",
            }}
            format={timeFormat}
            className={isHospitalCharting ? "hospital_time_picker" : ""}
            use12Hours
            value={!!value && moment(value, timeFormat).isValid() ? moment(value, timeFormat) : null}
            placeholder={label}
            tabIndex={tabIndex}
            ref={ref}
            suffixIcon={<ExpandSVG />}
            onChange={onChangeTime}
            onOpenChange={(open) => {
              setFocus(open);
              setOpenTime(open);
            }}
            onFocus={() => setFocus(true)}
            onBlur={() => {
              if (!openTime) {
                setFocus(false);
              }
            }}
            disabledHours={() => disabledHours}
          />
          {!!errorText && <p className={`MuiFormHelperText-root MuiFormHelperText-contained Mui-error`}>{errorText}</p>}
        </div>
      )}
      {!noEditable && type && type === "check" && !multiple && (
        <div className={`MuiFormControl-root MuiTextField-root date-picker ${errorText ? "error" : ""}`}>
          {!!value && (
            <label className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-outlined MuiInputLabel-shrink datePicker-label">
              {lableContent}
            </label>
          )}
          {searchIcon && (
            <span className="search-icon">
              <SearchSVG />
            </span>
          )}
          <Select
            id={id}
            showSearch={true}
            ref={selectRef}
            optionFilterProp="children"
            placeholder={label}
            className={`${error ? "error" : ""} ${rmBorder ? "rmBorder" : ""} ${searchIcon ? "with-search" : ""}`}
            suffixIcon={<ExpandSVG onClick={() => setFocus(!focus)} />}
            value={options && (value || undefined)}
            onChange={onChangeSelect}
            showArrow={hideIconSelect ? false : true}
            onSearch={onSearch}
            autoClearSearchValue={autoClearSearchValue ? true : false}
            notFoundContent={notFoundContent ? notFoundContent : null}
            allowClear={allowClear}
            tabIndex={tabIndex}
            style={{
              ...customStyle,
              pointerEvents: disabled ? "none" : "auto",
            }}
            open={focus}
            onFocus={(e) => {
              setFocus(true);
              selectRef.current.rcSelect.inputRef.focus();
            }}
            onBlur={() => {
              setFocus(false);
              selectRef.current.blur();
            }}
            filterOption={
              disableFilter
                ? false
                : (input, opt) => input && opt.props.children && opt.props.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {(options || []).map((condition, index) => (
              <Option
                id={`option_click${index}`}
                style={{
                  textTransform: "capitalize",
                }}
                key={`condition-${condition?.id} - ${index}`}
                value={toGetAll ? `${condition[valueKey]} - ${condition[optionKey || "id"]}` : condition[optionKey || "id"]}
              >
                {`${condition[valueKey]}` || `${condition?.value}`}
              </Option>
            ))}
          </Select>
          {!!errorText && <p className={`MuiFormHelperText-root MuiFormHelperText-contained Mui-error`}>{errorText}</p>}
        </div>
      )}
      {!noEditable && type && type === "check" && multiple && (
        <div className={`MuiFormControl-root MuiTextField-root date-picker ${errorText ? "error" : ""}`}>
          {!!value && (
            <label className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-outlined MuiInputLabel-shrink datePicker-label">
              {lableContent}
            </label>
          )}
          <MultiSelect
            id={id}
            ref={ref}
            className="multi-select"
            allowClear={allowClear}
            disableSearch={disableSearch}
            hasSelectAll={disableMultipleCheckAll ? false : true}
            overrideStrings={{ selectSomeItems: label }}
            options={(options || []).map((opt) => ({
              label: opt[valueKey],
              value: opt[optionKey],
              suffix: opt["suffix"],
            }))}
            value={value ? value : []}
            style={{
              pointerEvents: disabled ? "none" : "auto",
              ...customStyle,
            }}
            onChange={onChangeMultiSelect}
            ItemRenderer={CustomItem}
          />
          {!!errorText && <p className={`MuiFormHelperText-root MuiFormHelperText-contained Mui-error`}>{errorText}</p>}
        </div>
      )}
    </div>
  );
};

export default EditableFormInput;
