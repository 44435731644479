export const ArteryLabels = {
  'm-body': 'Abdominal Aorta',
  'l-neck-1': 'Left Carotid Artery',
  'r-body-2': 'Right Renal Artery',
  'l-body-0': 'Left Renal Artery',
  'r-neck-1': 'Right Carotid Artery',
  'r-arm-4': 'Right Axillary Artery',
  'r-arm-3': 'Right Brachial Artery',
  'r-arm-1l': 'Right Radial Artery',
  'r-arm-0': 'Right Radial-Ulnar Artery',
  'r-arm-1r': 'Right Ulnar Artery',
  'r-arm-2': 'Right Ulnar-Radial Artery',
  'l-arm-4': 'Left Axillary Artery',
  'l-arm-3': 'Left Brachial Artery',
  'l-arm-1r': 'Left Radial Artery',
  'l-arm-0': 'Left Radial-Ulnar Artery',
  'l-arm-1l': 'Left Ulnar Artery',
  'l-arm-2': 'Left Ulnar-Radial Artery',
  'r-leg-0': 'Right External Iliac Artery',
  'r-leg-1': 'Right Common Femoral Artery',
  'r-leg-2': 'Right Proximal Superficial Femoral Artery',
  'r-leg-3': 'Right Mid Superficial Femoral Artery',
  'r-leg-5': 'Right Tibioperoneal Trunk',
  'r-leg-4': 'Right Popliteal Artery',
  'r-leg-7l': 'Right Mid Anterior Tibial Artery',
  'r-leg-8l': 'Right Distal Anterior Tibial Artery',
  'r-leg-9': 'Right Plantar Arch',
  'r-leg-8r': 'Right Distal Posterior Tibial Artery',
  'r-leg-7r': 'Right Mid Posterior Tibial Artery',
  'r-leg-6r': 'Right Proximal Posterior Tibial Artery',
  'r-leg-o-3': 'Right Distal Peroneal Artery',
  'r-leg-o-1': 'Right Profunda Femoris',
  'r-leg-o-0': 'Right Internal Iliac Artery',
  'r-leg-o-2': 'Right Proximal Peroneal Artery',
  'r-leg-6l': 'Right Proximal Anterior Tibial Artery',
  'r-neck-0': 'Right Common Carotid Artery',
  'r-lung': 'Right Subclavian Artery',
  'l-lung': 'Left Subciavian Artery',
  'r-body-1': 'Superior Mesenteric Artery',
  'r-body-0': 'Celiac Artery',
  'l-leg-0': 'Left External Iliac Artery',
  'l-leg-1': 'Left Common Femoral Artery',
  'l-leg-2': 'Left Proximal Superficial Femoral Artery',
  'l-leg-3': 'Left Mid Superficial Femoral Artery',
  'l-leg-5': 'Left Tibioperoneal Trunk',
  'l-leg-4': 'Left Popliteal Artery',
  'l-leg-7r': 'Left Mid Anterior Tibial Artery',
  'l-leg-8r': 'Left Distal Anterior Tibial Artery',
  'l-leg-9': 'Left Plantar Arch',
  'l-leg-8l': 'Left Distal Posterior Tibial Artery',
  'l-leg-7l': 'Left Mid Posterior Tibial Artery',
  'l-leg-6l': 'Left Proximal Posterior Tibial Artery',
  'l-leg-o-3': 'Left Distal Peroneal Artery',
  'l-leg-o-1': 'Left Profunda Femoris',
  'l-leg-o-0': 'Left Internal Iliac Artery',
  'l-leg-o-2': 'Left Proximal Peroneal Artery',
  'l-leg-6r': 'Left Proximal Anterior Tibial Artery',
  'r-leg': 'Right Common Iliac Artery',
  'l-leg': 'Left Common Iliac Artery',
  'l-neck-0': 'Left Common Carotid Artery',
};

export const getArteryLabel = (body) => {
  return ArteryLabels[body];
};
