import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DataTable from "../../../../common/components/DataTable/DataTable";
import EditableFormINput from "../../../../common/components/EditableText/EditableFormInput";
import ToastMessage from "../../../../common/components/Toast";
import { getLocal } from "../../../../helpers/Local";
import { getEmployeeContributionMargin, getPayperiods, setGlobalLoading } from "../../../../store/actions";
import ContributionMarginSidebar from "./ContributionMarginSidebar";

const ContributionMargin = () => {
  const [loading, setLoading] = useState(false);
  const [isToastr, setIsToastr] = useState(false);
  const [payperiods, setPayperiods] = useState(0);

  const pay_periods = useSelector((state) => state.common.payperiods);
  const loadingTimeoff = useSelector((state) => state.common.loadingTimeoff);
  const employee_cm = useSelector((state) => state.procedure.employee_cm);
  const currentUserInfo = useSelector((state) => state.common.currentUserInfo);
  const dispatch = useDispatch();
  const [tableLoad, setTableLoad] = useState([]);
  const options = pay_periods.map((item) => {
    return {
      value: `${moment(item?.from_date).format("MM/DD/YYYY")} - ${moment(item?.to_date).format("MM/DD/YYYY")}`,
      label: `${moment(item?.from_date).format("MM/DD/YYYY")} - ${moment(item?.to_date).format("MM/DD/YYYY")}`,
    };
  });
  const [isSidebar, setIsSidebar] = useState(false);

  const minRowCount = 8;
  let emptyRows = [];
  if (tableLoad?.length < minRowCount + 1) {
    emptyRows = new Array(minRowCount - tableLoad?.length).fill({ label: "" });
  }

  const changePayPeriod = async (value) => {
    if (value) {
      setPayperiods(value);
      const selected_pay_period = value.split(" - ");
      await dispatch(setGlobalLoading(true));
      await dispatch(getEmployeeContributionMargin(selected_pay_period[0], selected_pay_period[1]));
      await dispatch(setGlobalLoading(false));
    }
  };

  useEffect(() => {
    if (employee_cm?.data?.length > 0) {
      setTableLoad(employee_cm?.data);
    }
  }, [employee_cm]);

  useEffect(() => {
    dispatch(getPayperiods());
  }, [dispatch]);

  useEffect(() => {
    dispatch(setGlobalLoading(loading || loadingTimeoff));
  }, [dispatch, loading, loadingTimeoff]);

  const sidebarRef = useRef();

  const handleSidebar = () => {
    setIsSidebar(true);
  };

  const handleCloseSidebar = () => {
    setIsSidebar(false);
  };

  const handleRow = (index, data) => {
    handleSidebar();
  };

  return (
    <div className="clinicWorkflow__main-section">
      {isToastr && <ToastMessage type="Schedules" status="success" msg="Saved Successfully!" />}
      <div className="eventsContainer has-sub-header">
        <div className="fullContainer pt-2">
          <div
            className="timeOff__form"
            style={{
              paddingRight: "1rem",
              marginTop: "1rem",
            }}
          >
            <div className="timeOff__form-container">
              <div className="timeOff__form-control">
                <EditableFormINput
                  label={"Pay Period"}
                  value={payperiods}
                  type="check"
                  options={options || []}
                  optionKey="value"
                  valueKey="label"
                  handleChange={(value) => changePayPeriod(value)}
                />
              </div>
            </div>
          </div>
          <div className="fullContainer pt-2">
            <div className="tableSection">
              <div className="tableContainer">
                <DataTable
                  title="employee_contribution_margin"
                  tableWidth={920}
                  labels={["Patient Name", "DOS", "Location", "Contribution Margin"]}
                  widths={["24%", "19%", "19%", "19%", "19%"]}
                  columns={[
                    { sortable: false, key: "name" },
                    { sortable: false, key: "dos" },
                    { sortable: false, key: "location" },
                    { sortable: false, key: "contribution_margin" },
                  ]}
                  sorts={["", "", "", "", ""]}
                  defaultActive={0}
                  disableDelete={true}
                  rowData={(tableLoad || []).map((item, index) => {
                    return {
                      name: <b>{item?.patient && item?.patient?.name}</b>,
                      dos: moment(item.appointment_date).format("MM/DD/YYYY"),
                      location: item?.location && item?.location?.value,
                      contribution_margin: item.contribution_margin,
                    };
                  })}
                  emptyRows={emptyRows}
                  handleClickRow={(row, index) => handleRow()}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <ContributionMarginSidebar
        ref={sidebarRef}
        resource={employee_cm}
        isShow={isSidebar}
        handleCloseSidebar={handleCloseSidebar}
        currentUserInfo={currentUserInfo}
      />
    </div>
  );
};

export default ContributionMargin;
