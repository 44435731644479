import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import EditableFormInput from "../../../common/components/EditableText/EditableFormInput";
import ToastMessage from "../../../common/components/Toast";
import { stateOptions } from "../../../common/constants/initialData";
import { autoZip } from "../../../services/api.services";
import { validateField } from "../../../services/validations";
import { savePharmacy, getPrescriptions } from "../../../store/actions";

let timer = null;
const PharmacyAddForm = ({ isMedication, ...restProps }) => {
  const dispatch = useDispatch();
  const [pharmacy, setPharmacy] = useState({
    company_name: "",
    phone: "",
    fax1: "",
    address_1: "",
    address_2: "",
    state: "",
    city: "",
    zipcode: "",
  });
  const [enableError, setEnableError] = useState(false);
  const [isToastr, setIsToastr] = useState(false);
  const [first, setFirst] = useState(false);
  const [zipAutoData, setZipAutoData] = useState({});

  useEffect(() => {
    setFirst(true);
  }, []);

  const setAutoZip = async () => {
    if (zipAutoData && zipAutoData.success) {
      if (zipAutoData.addresses && zipAutoData.addresses[0]) {
        const address = zipAutoData.addresses[0];
        const newPharmacy = {
          ...pharmacy,
          state: address.state_id,
          city: address.place,
        };
        setPharmacy(newPharmacy);
      }
    } else {
      const newPharmacy = {
        ...pharmacy,
        state: "",
        city: "",
      };
      setPharmacy(newPharmacy);
    }
  };

  useEffect(() => {
    if (zipAutoData && Object.keys(zipAutoData).length > 0 && first) {
      setAutoZip();
    }
  }, [zipAutoData]);

  const handleChange = (type) => (value) => {
    let newPharmacy = {};
    if (type === "zipcode") {
      clearTimeout(timer);
      newPharmacy = { ...pharmacy, [type]: value };
      timer = setTimeout(async () => {
        const zipData = await autoZip(value);
        if (!zipData) {
          setZipAutoData({ success: false });
        } else {
          setZipAutoData(zipData);
        }
      }, 1000);
    } else {
      newPharmacy = { ...pharmacy, [type]: value };
    }
    setPharmacy(newPharmacy);
  };

  const backToPatient = () => {
    restProps.setPharmacyAddForm && restProps.setPharmacyAddForm(false);
  };

  const saveNewProvider = async () => {
    let error;
    Object.keys(pharmacy).forEach((field) => {
      if (field !== "address_2") {
        if (!pharmacy[field]) {
          error = true;
          setEnableError(true);
        }
      }
    });
    if (error) return;
    const isSuccess = await dispatch(savePharmacy(pharmacy));
    if (isSuccess) {
      if (restProps.isMedication || isMedication) {
        restProps.onReloadClick && restProps.onReloadClick();
        setIsToastr(true);
        setTimeout(() => {
          setIsToastr(false);
          backToPatient();
        }, 3000);
      } else {
        setIsToastr(true);
        setTimeout(() => {
          setIsToastr(false);
          backToPatient();
        }, 3000);
      }
    }
  };

  const showToast = () => {
    return <ToastMessage type="New Pharmacy" status="success" msg="New pharmacy is added successfully" />;
  };

  return (
    <div className="resourceContent pr-7px" style={{ position: "relative" }}>
      {isToastr && showToast()}
      <div className="resourceInfo d-flex required-field">
        <EditableFormInput
          label={"Pharmacy Name"}
          value={pharmacy.company_name}
          handleChange={handleChange("company_name")}
          required={true}
          isError={validateField("text", pharmacy.company_name)}
          enableErrorText={enableError}
          helperText={"Company Name is Required"}
        />
      </div>
      <div className="resourceInfo d-flex required-field">
        <EditableFormInput
          label={"Phone No"}
          value={pharmacy.phone}
          handleChange={handleChange("phone")}
          required={true}
          isError={validateField("phone", pharmacy.phone)}
          enableErrorText={enableError}
          helperText={"Phone Number should be (XXX) XXX-XXXX format"}
          mask={"phone"}
        />
      </div>
      <div className="resourceInfo d-flex required-field">
        <EditableFormInput
          label={"Fax No"}
          value={pharmacy.fax1}
          handleChange={handleChange("fax1")}
          required={true}
          isError={validateField("phone", pharmacy.fax1)}
          enableErrorText={enableError}
          helperText={"Fax Number should be (XXX) XXX-XXXX format"}
          mask={"phone"}
        />
      </div>
      <div className="resourceInfo d-flex required-field">
        <EditableFormInput
          label={"Address"}
          value={pharmacy.address_1}
          handleChange={handleChange("address_1")}
          required={true}
          isError={validateField("text", pharmacy.address_1)}
          enableErrorText={enableError}
          helperText={"Address is required"}
        />
      </div>
      <div className="resourceInfo d-flex required-field">
        <EditableFormInput label={"Suite"} value={pharmacy.address_2} handleChange={handleChange("address_2")} />
      </div>
      <div className="resourceInfo d-flex required-field">
        <EditableFormInput
          label={"Zip Code"}
          value={pharmacy.zipcode}
          handleChange={handleChange("zipcode")}
          required={true}
          isError={validateField("text", pharmacy.zipcode)}
          enableErrorText={enableError}
          helperText={"Zip Code is required"}
        />
      </div>
      <div className="resourceInfo d-flex required-field">
        <EditableFormInput
          label={"State"}
          value={pharmacy.state || undefined}
          handleChange={handleChange("state")}
          type={"check"}
          valueKey={"value"}
          optionKey={"id"}
          options={stateOptions || []}
          required={true}
          isError={validateField("text", pharmacy.state)}
          enableErrorText={enableError}
          helperText={"State is required"}
        />
      </div>
      <div className="resourceInfo d-flex required-field">
        <EditableFormInput
          label={"City"}
          value={pharmacy.city}
          handleChange={handleChange("city")}
          required={true}
          isError={validateField("text", pharmacy.city)}
          enableErrorText={enableError}
          helperText={"City is required"}
        />
      </div>

      <div className="actionContent" style={{ flexWrap: "wrap", marginTop: "1.5rem" }}>
        <button className="common-btn outlined-btn" style={{ marginTop: "0.5rem" }} onClick={backToPatient}>
          Back
        </button>
        <button className="common-btn" style={{ marginTop: "0.5rem", marginLeft: "0.5rem" }} onClick={saveNewProvider}>
          Save
        </button>
      </div>
    </div>
  );
};

export default PharmacyAddForm;
