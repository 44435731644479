export const NewPatientProcessingTabHeaders = [
  {
    label: 'Overview',
    width: 'auto',
  },
  {
    label: "Clinical Calendar",
    width: "auto",
  },
  {
    label: "Analytics",
    width: "auto",
  }
];

export const ProcedureProcessingTabHeaders = [
  {
    label: 'Overview',
    width: 'auto',
  },
  {
    label: "Procedure Calendar",
    width: "auto",
  },
  {
    label: "Analytics",
    width: "auto",
  }
];

export const ClinicProcessingTabHeaders = [
  {
    label: 'Overview',
    width: 'auto',
  },
  {
    label: "Clinic Calendar",
    width: "auto",
  },
  {
    label: "Analytics",
    width: "auto",
  }
];

export const UltrasoundProcessingTabHeaders = [
  {
    label: 'Overview',
    width: 'auto',
  },
  {
    label: 'Ultrasound Calendar',
    width: 'auto',
  },
  {
    label: "Analytics",
    width: "auto",
  }
];
export const DependencyProcessingTabHeaders = [
  {
    label: "Prior Authorizations",
    width: "auto",
  },
  {
    label: "Non-Network Scheduling",
    width: "auto",
  },
];
