import { Checkbox, Icon } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import { getLocal } from "../../../../helpers/Local";
import RemoveN from "../../../../helpers/RemoveN";
import { patientExaminationSave, patientExaminationSetData, setGlobalLoading, setGlobalToastr } from "../../../../store/actions";
import { setSidebarState } from "../../../../store/actions/sidebar";
import ExaminationROS from "./ExaminationROS";
import PhysicalExaminationSidebar from "./PhysicalExaminationSidebar";

class PhysicalExamination extends Component {
  sidebarRef = null;
  constructor(props) {
    super(props);
    this.sidebarRef = React.createRef();
  }
  state = {
    isSidebar: true,
    checkedCopyLast: false,
    saveQueued: false,
    checkSavingFuncId: null,
    checkedAllNormalsP: null,
    first: false,
    firstCheck: false,
    twoCheck: false,
  };
  timer = null;
  componentDidMount() {
    this.props.setSidebarState(true);
    const { patientResource, all_normal_exam, copy_from_last_visit_exam } = this.props;
    if (patientResource) {
      this.setState({
        ...this.state,
        isSidebar: window.innerWidth <= 768 ? false : true,
      });
    }
    this.setState({
      first: false,
    });
    if (copy_from_last_visit_exam == 1) {
      this.setState({
        checkedCopyLast: true,
      });
    } else if (copy_from_last_visit_exam == 0) {
      this.setState({
        checkedCopyLast: false,
      });
    } else {
      this.setState({
        checkedCopyLast: null,
      });
    }
    if (all_normal_exam == 1) {
      this.setState({
        checkedAllNormalsP: true,
      });
    } else if (all_normal_exam == 0) {
      this.setState({
        checkedAllNormalsP: false,
      });
    } else {
      this.setState({
        checkedAllNormalsP: null,
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    const { all_normal_exam } = nextProps;
    this.setState({
      first: false,
    });
    if (all_normal_exam == 1) {
      this.setState({
        checkedAllNormalsP: true,
      });
    } else if (all_normal_exam == 0) {
      this.setState({
        checkedAllNormalsP: false,
      });
    } else {
      this.setState({
        checkedAllNormalsP: null,
      });
    }
  }

  componentWillUnmount() {
    this.props.setSidebarState(false);
  }

  handleSidebar = () => {
    this.setState({ ...this.state, isSidebar: true });
  };

  toggleSidebar = () => {
    this.setState({ isSidebar: !this.state.isSidebar });
  };

  handleCloseSidebar = () => {
    this.setState({ ...this.state, isSidebar: false });
  };

  onChangeData = async (scope, data) => {
    await this.props.setGlobalLoading(true);
    await this.props.PhysicalChange(scope, data);
    this.setState({ ...this.state, saveQueued: true });
    this.setState({
      firstCheck: true,
    });
    if (this.state.firstCheck) {
      this.saveData();
    }
    await this.props.setGlobalLoading(false);
  };

  onChangeDataVes = async (scope, data) => {
    await this.props.setGlobalLoading(true);
    await this.props.PhysicalChange(scope, data);
    this.setState({ ...this.state, saveQueued: true });
    clearTimeout(this.timer);
    this.timer = setTimeout(async () => {
      this.setState({
        twoCheck: true,
      });
    }, 1000);
    if (this.state.twoCheck) {
      this.saveData();
    }
    await this.props.setGlobalLoading(false);
  };

  saveData = async () => {
    await this.props.setGlobalLoading(true);
    const { physical_examinations, vessel_states, all_normal_exam, is_locked, copy_from_last_visit_exam } = this.props;
    const { patientResource } = this.props;
    const patient_id = patientResource?.id;
    if (is_locked == 0 || is_locked == null) {
      clearTimeout(this.timer);
      const procedure_id = getLocal("current_appointment_id");
      const fixArr = (physical_examinations || []).map((r) => {
        return {
          ...r,
          comments: RemoveN(r?.comments || ""),
        };
      });
      this.timer = setTimeout(async () => {
        await this.props.patientExaminationSave(procedure_id, {
          all_normal_exam: all_normal_exam,
          patient_id,
          physical_examinations: fixArr,
          copy_from_last_visit_exam: copy_from_last_visit_exam,
          vessel_states,
        });
        await this.props.setGlobalLoading(false);
      }, 1000);
    } else {
      await this.props.setGlobalLoading(false);
      this.props.setGlobalToastr({
        header: "Save Fail!",
        message: "Please Unlock Finalize",
        type: "failed",
      });
    }
    this.setState({ ...this.state, saveQueued: false });
  };

  doCopyLast = async (checked) => {
    const { patientResource, is_locked, physical_examinations } = this.props;
    await this.props.setGlobalLoading(true);
    if (checked) {
      if (is_locked == 0 || is_locked == null) {
        clearTimeout(this.timer);
        this.setState({ checkedCopyLast: true }, async () => {
          await this.props.PhysicalCoppy(patientResource?.id);
          await this.props.setGlobalLoading(false);
        });
      } else {
        await this.props.setGlobalToastr({
          header: "Save Fail!",
          message: "Please Unlock Finalize",
          type: "failed",
        });
        await this.props.setGlobalLoading(false);
      }
    } else {
      if (is_locked == 0 || is_locked == null) {
        clearTimeout(this.timer);
        const removePhy = (physical_examinations || []).map((r) => {
          return {
            ...r,
            comments: "",
            values: (r?.values || []).map((v) => {
              return {
                ...v,
                checked: false,
              };
            }),
          };
        });
        this.setState({ checkedCopyLast: false }, async () => {
          await this.props.patientExaminationSetData("all_normal_exam", 0);
          await this.props.patientExaminationSetData("physical_examinations", removePhy);
          await this.props.setGlobalLoading(false);
        });
      } else {
        this.props.setGlobalToastr({
          header: "Save Fail!",
          message: "Please Unlock Finalize",
          type: "failed",
        });
        await this.props.setGlobalLoading(false);
      }
    }
  };

  checkAllNormals = async (checked) => {
    await this.setState({
      first: false,
    });
    await this.props.setGlobalLoading(true);
    const { physical_examinations, vessel_states, patientResource, is_locked, copy_from_last_visit_exam } = this.props;
    const patient_id = patientResource?.id;
    const procedure_id = getLocal("current_appointment_id");
    if (is_locked == 0 || is_locked == null) {
      const vesselStates_ = { ...vessel_states };
      for (const key in vesselStates_) {
        if (vesselStates_.hasOwnProperty(key)) {
          if (checked) {
            vesselStates_[key] = "2";
          } else {
            vesselStates_[key] = "2";
          }
        }
      }
      this.onChangeDataVes("vessel_states", vesselStates_);
      await this.setState({
        ...this.state,
        saveQueued: true,
        checkedAllNormalsP: checked,
      });
      const fixArr = (physical_examinations || []).map((r) => {
        return {
          ...r,
          comments: RemoveN(r?.comments || ""),
        };
      });
      await this.props.PhysicalCheckAll("physical_examinations", fixArr, checked);
      await this.props.patientExaminationSave(procedure_id, {
        all_normal_exam: checked ? 1 : 0,
        patient_id: patient_id,
        copy_from_last_visit_exam: copy_from_last_visit_exam,
        physical_examinations: fixArr,
        vessel_states,
      });
      await this.props.setGlobalLoading(false);
    } else {
      this.props.setGlobalToastr({
        header: "Save Fail!",
        message: "Please Unlock Finalize",
        type: "failed",
      });
      await this.props.setGlobalLoading(true);
    }
  };

  removeState = () => {
    if (this.props.physical_examinations && this.props.physical_examinations.length > 0) {
      this.props.patientExaminationSetData(
        "physical_examinations",
        this.props.physical_examinations.map((r) => {
          return {
            ...r,
            comments: "",
          };
        })
      );
    }
  };

  render() {
    const { isSidebar } = this.state;
    const { checkedCopyLast, checkedAllNormalsP } = this.state;

    return (
      <div style={{ display: this.props.display ? "flex" : "none" }} className="clinicWorkflow__main-section">
        <div className="eventsContainer has-sub-header" style={{ paddingTop: "1rem" }}>
          <div className="conditionList">
            <Checkbox
              checked={checkedCopyLast}
              onChange={(e) => this.doCopyLast(e.target.checked)}
              disabled={this.props.can_copy_from_previous_visit === 0}
            >
              Copy from previous visit
            </Checkbox>
            <Checkbox
              checked={checkedAllNormalsP}
              onChange={(e) => {
                this.checkAllNormals(e.target.checked);
                this.removeState();
              }}
              disabled={this.props.is_locked}
            >
              All normal except selections
            </Checkbox>
            <div className="listSection">
              <ExaminationROS
                type={"physical_examination"}
                onChangeData={(data) => {
                  this.onChangeData("physical_examinations", data);
                }}
                isLocked={this.props.is_locked}
              />
            </div>
          </div>
        </div>
        {this.props.isSlide && (
          <PhysicalExaminationSidebar
            ref={this.sidebarRef}
            isShow={isSidebar}
            sidebarIcon={<Icon type="file-text" theme="twoTone" twoToneColor="#740937" />}
            checkedAllNormalsP={checkedAllNormalsP}
            handleCloseSidebar={this.handleCloseSidebar}
            onChangeDataVes={(data) => this.onChangeDataVes("vessel_states", data)}
            isLocked={this.props.is_locked}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  is_locked: state.clinicProviderReview.is_locked,
  patientResource: state.clinicProvider.patientResource,
  physical_examinations: state.patientExamination.physical_examinations || [],
  vessel_states: state.patientExamination.vessel_states,
  all_normal_exam: state.patientExamination.all_normal_exam,
  can_copy_from_previous_visit: state.patientExamination.can_copy_from_previous_visit,
  copy_from_last_visit_exam: state.patientExamination.copy_from_last_visit_exam,
});

const mapDispatchToProps = {
  patientExaminationSetData,
  patientExaminationSave,
  setGlobalToastr,
  setSidebarState,
  setGlobalLoading,
};

export default connect(mapStateToProps, mapDispatchToProps)(PhysicalExamination);
