import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const Examination = ({ type, onChangeData, dataCheck, keyPanel, dataAll, isLocked, params }) => {
  const [data, setData] = useState([]);
  const [array_type, set_array_type] = useState([]);
  const intraAnswers = useSelector((state) => state.common.intraAnswers);
  const angio_type_variable_list = useSelector((state) => state.procedureDetail.angio_type_variable_list);

  const onChangeComments = (index, comments) => {
    const data_ = [...data];
    data_[index]["comments"] = comments;
    onChangeData(data_);
  };
  const onChangeValues = (index, values) => {
    const data_ = [...data];
    data_[index]["values"] = values;
    onChangeData(data_);
  };

  useEffect(() => {
    const arrayCheck = (array_type || []).map((item) => {
      let idvari = 0;
      return {
        comments: "",
        id: item?.id,
        type: item?.question && item?.question.length > 18 ? `${item?.question.substring(0, 18)}...` : item?.question,
        is_multiple: item?.is_multiple,
        typeFull: item?.question,
        variable_name: item?.variable_name,
        values: (item?.answers || []).map((r) => {
          idvari = r.angio_procedure_type_id;
          return {
            id: r?.id,
            option: r.answer,
            checked: false,
            text_template: r.text_template,
            angio_procedure_type_id: r.angio_procedure_type_id,
            variable_name: item?.variable_name,
          };
        }),
        angio_procedure_type_id: idvari,
      };
    });
    let checkTrue = [];
    //
    const mapArrJoin = [];
    (intraAnswers || []).map((r) => {
      if (r?.checked_choice) {
        if (r?.checked_choice.includes(",")) {
          (r?.checked_choice || "").split(",").map((sp) => {
            mapArrJoin.push({
              ...r,
              checked_choice: sp,
            });
          });
        } else {
          mapArrJoin.push({
            ...r,
          });
        }
      }
    });
    if (mapArrJoin) {
      mapArrJoin.map((r, index) => {
        const indexID = arrayCheck.findIndex((i) => i?.id == r.angio_procedure_type_variable_id);
        if (indexID != -1) {
          checkTrue = [
            ...checkTrue,
            {
              findindex: arrayCheck.findIndex((i) => i?.id == r.angio_procedure_type_variable_id),
              valueindex:
                arrayCheck[indexID] && arrayCheck[indexID]["values"]
                  ? arrayCheck[indexID]["values"].findIndex((check) => check.option == r.checked_choice)
                  : -1,
              valuecheck: r.checked_choice,
            },
          ];
        }
      });
    }
    (checkTrue || []).map((r) => {
      if (
        arrayCheck[r.findindex] &&
        arrayCheck[r.findindex]["values"] &&
        arrayCheck[r.findindex]["values"][r?.valueindex] &&
        arrayCheck[r.findindex]["values"][r?.valueindex]["checked"] !== undefined
      ) {
        arrayCheck[r.findindex]["values"][r?.valueindex]["checked"] = true;
      }
    });
    setData([...arrayCheck]);
    if (dataAll && dataAll["intra"] && dataAll["intra"].length > 0) {
      setData([...dataAll["intra"]]);
    }
  }, [intraAnswers, array_type]);

  useEffect(() => {
    set_array_type(angio_type_variable_list || []);
  }, [angio_type_variable_list]);

  return (
    <React.Fragment>
      {data?.map((item, index) => (
        <ROSItem
          key={`examination-item-${index}`}
          title={item.typeFull}
          comments={item.comments}
          values={item?.values}
          items={item}
          onChangeComments={(comments) => onChangeComments(index, comments)}
          onChangeValues={(values) => onChangeValues(index, values)}
          isLocked={isLocked}
        />
      ))}
    </React.Fragment>
  );
};

const ROSItem = (props) => {
  const [title, setTitle] = useState("");
  const [values, setValues] = useState([]);

  useEffect(() => {
    setTitle(props.title);
    setValues(props?.values);
  }, [props.comments, props?.values]);

  const onClickOption = (index, is_multiple) => {
    if (is_multiple) {
      const values_ = [...values];
      values_[index]["checked"] = !values_[index]["checked"];
      props.onChangeValues(values_);
      setValues(values_);
    } else {
      const values_ = [...values];
      values_.map((r, i) => {
        values_[i]["checked"] = false;
      });
      values_[index]["checked"] = !values_[index]["checked"];
      props.onChangeValues(values_);
      setValues(values_);
    }
  };

  if (props?.items.is_multiple == 1 || props?.items.is_multiple == true) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          position: "relative",
        }}
        className={`ExaminationROSItem`}
      >
        <div style={{ width: "40%" }}>
          <div className="ExaminationROSItem__label" style={{ width: "100%", overflow: "hidden" }}>
            {title}
          </div>
        </div>
        <div
          style={{
            width: "60%",
          }}
        >
          <div style={{ width: "100%" }} className={`ExaminationROSItem__options`}>
            {(values || []).map((opt, index) => (
              <button
                key={`intra-item-${title}-${index}`}
                className={`ExaminationROSItem__option ${opt.checked && "active"}`}
                onClick={() => onClickOption(index, true)}
                disabled={props.isLocked}
              >
                {opt.option}
              </button>
            ))}
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          position: "relative",
        }}
        className={`ExaminationROSItem`}
      >
        <div style={{ width: "40%" }}>
          <div className="ExaminationROSItem__label" style={{ width: "100%", overflow: "hidden" }}>
            {title}
          </div>
        </div>
        <div
          style={{
            width: "60%",
          }}
        >
          <div style={{ width: "100%" }} className={`ExaminationROSItem__options`}>
            {(values || []).map((opt, index) => (
              <button
                key={`intra-item-${title}-${index}`}
                className={`ExaminationROSItem__option ${opt.checked && "active"}`}
                onClick={() => onClickOption(index)}
                disabled={props.isLocked}
              >
                {opt.option}
              </button>
            ))}
          </div>
        </div>
      </div>
    );
  }
};

export default Examination;
