import { Icon } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ScrollWrapper from '../../../../common/components/ScrollBar';
import { useWindowSize } from '../../../../common/utils';
import { get_list_patients, setGlobalLoading } from '../../../../store/actions';

let timer = null;
const ActivePatient = ({
  activeIndex,
  title,
  handleRow,
  propsListTable,
  deleteListPatient,
  addProps,
  search,
}) => {
  const ref = useRef(null);

  const [initialSize, setSize] = useState({
    width: undefined,
    height: undefined,
  });

  const [page, setPage] = useState(1);
  const [offLoad, setOffLoad] = useState(true);
  const [loading, setLoading] = useState(true);
  const [tableRows, setTableRows] = useState([]);

  useEffect(() => {
    if (addProps && Object.keys(addProps).length > 0) {
      if (addProps?.id) {
        tableRows.unshift(addProps);
        setTableRows([...tableRows]);
      }
    }
  }, [addProps]);

  useEffect(() => {
    setPage(1);
    setTableRows([]);
    clearTimeout(timer);
    timer = setTimeout(() => {
      dispatch(get_list_patients(1, 1, search));
    }, 500);
  }, [search]);

  useEffect(() => {
    if (deleteListPatient && Object.keys(deleteListPatient).length > 0) {
      if (deleteListPatient?.id) {
        const index = tableRows.findIndex(
          (r) => r?.id == deleteListPatient?.id,
        );
        tableRows.splice(index, 1);
        setTableRows([...tableRows]);
      }
    }
  }, [deleteListPatient]);

  useEffect(() => {
    if (propsListTable && Object.keys(propsListTable).length > 0) {
      if (propsListTable?.id) {
        const index = tableRows.findIndex((r) => r?.id == propsListTable?.id);
        tableRows.splice(index, 1, {
          ...propsListTable,
          first_name: propsListTable?.first_name,
          last_name: propsListTable?.last_name,
          name: `${propsListTable?.first_name} ${propsListTable?.last_name}`,
          full_name: `${propsListTable?.first_name} ${propsListTable?.last_name}`,
        });
        setTableRows([...tableRows]);
      }
    }
  }, [propsListTable]);

  const list_patients = useSelector((state) => state.common.list_patients);
  const loading_get_patients = useSelector(
    (state) => state.common.loading_get_patients,
  );

  const dispatch = useDispatch();
  const size = useWindowSize(ref);

  useEffect(() => {
    fetchApi();
    if (ref) {
      const initialSize = {
        width: ref.current.clientWidth,
        height: ref.current.clientHeight,
      };
      setSize(initialSize);
    }
  }, [ref]);

  useEffect(() => {
    dispatch(setGlobalLoading(loading_get_patients));
  }, [loading_get_patients]);

  useEffect(() => {
    if (!loading_get_patients) {
      setTableRows([...tableRows, ...list_patients]);
    }
  }, [list_patients]);

  let emptyRows = [];

  const fetchApi = async () => {
    await dispatch(get_list_patients(page, 1));
  };

  let rowCount = 0;
  if (!size.height && !initialSize.height) {
    rowCount = tableRows.length;
  }
  if (size.height) {
    rowCount = Math.round(size.height / (16 * 2.5));
  } else {
    rowCount = Math.round(initialSize.height / (16 * 2.5));
  }

  if (rowCount > tableRows.length) {
    emptyRows = new Array(rowCount - tableRows.length).fill({});
  }

  const getLocaleString = (str) => {
    if (!str) {
      return '$0.00';
    }
    const num = parseFloat(str).toFixed(2);
    const decimals = num.split('.')[1];
    if (!decimals || decimals === '00') {
      return '$' + Math.round(parseFloat(str)).toLocaleString() + '.00';
    }
    if (decimals.charAt(1) === '0') {
      return (
        '$' + (Math.round(parseFloat(str) * 100) / 100).toLocaleString() + '0'
      );
    }
    return '$' + (Math.round(parseFloat(str) * 100) / 100).toLocaleString();
  };

  const onScroll = async (values) => {
    const { scrollTop, scrollHeight, clientHeight } = values;
    const pad = 30; // px of the bottom
    const t = (scrollTop + pad) / (scrollHeight - clientHeight);
    if (t >= 1 && Number.isFinite(t) && !loading_get_patients) {
      if (offLoad) {
        setLoading(true);
        const list = await dispatch(get_list_patients(page + 1, 1, search));
        if (list) {
          if (list_patients && list_patients.length > 0) {
            setPage(page + 1);
            setLoading(false);
          } else {
            setLoading(false);
            setOffLoad(false);
          }
        }
      }
    }
  };

  return (
    <div className="fullContainer pt-2">
      <div className="tableHeader">
        <div className="tableItem">
          <div
            className="th"
            style={{ width: 'calc(40% - 3rem)', marginLeft: '3rem' }}
          >
            <b>Provider Name</b>
          </div>
          <div className="th" style={{ width: '30%' }}>
            <b>Login</b>
          </div>
          <div className="th" style={{ width: '30%' }}>
            <b>Last Access Date and Time</b>
          </div>
        </div>
      </div>
      <div className="tableSection">
        <div ref={ref} className="tableContainer">
          <ScrollWrapper css="no-padding" onScrollFrame={onScroll}>
            <div className="tableList">
              {tableRows.map((row, index) => (
                <div
                  className={`tableItem yellow ${activeIndex === index ? 'active' : ''
                    }`}
                  key={`resultTable-${title}-${index}`}
                  onClick={handleRow(index, row)}
                >
                  <div className="td with-icon" style={{ width: '40%' }}>
                    <div className="iconField d-flex">
                      <Icon
                        type="check-circle"
                        theme="twoTone"
                        twoToneColor="#24A148"
                      />
                    </div>
                    <p>{row?.name || row?.full_name}</p>
                  </div>
                  <div className="td" style={{ width: '30%' }}>
                    <p>{row?.username}</p>
                  </div>
                  <div className="td" style={{ width: '30%' }}>
                    <p>{row?.last_login}</p>
                  </div>
                </div>
              ))}
              {emptyRows.map((row, index) => (
                <div
                  className={`tableItem empty`}
                  key={`resultTable-empty-${title}-${index}`}
                >
                  <div className="td" style={{ width: '31%' }} />
                  <div className="td" style={{ width: '23%' }} />
                  <div className="td" style={{ width: '23%' }} />
                  <div className="td" style={{ width: '23%' }} />
                </div>
              ))}
            </div>
          </ScrollWrapper>
        </div>
      </div>
    </div>
  );
};

export default ActivePatient;
