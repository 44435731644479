import React, { useState, useRef, useEffect } from "react";
import { NavLink, useHistory } from "react-router-dom";
import { Icon } from "antd";
import { useSelector, useDispatch } from "react-redux";
import ScrollWrapper from "../ScrollBar";
import { setGlobalLoading } from "../../../store/actions";
import "./style.scss";
import { getLocal } from "../../../helpers/Local";

const useOutsideDetect = (ref, handleClick, active) => {
  const handleClickOutside = (event) => {
    if (active && ref.current && !ref.current.contains(event.target)) {
      handleClick();
    }
  };

  useEffect(() => {
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });
};

const SideNav = ({ handleClick, active }) => {
  const usersCheck = useSelector((state) => state.common.currentUserInfo);
  const sideWrapper = useRef(null);
  const [loading, setLoading] = useState(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const userRole = getLocal("cvai-auth-role");
  useOutsideDetect(sideWrapper, handleClick, active);

  useEffect(() => {
    if (usersCheck && Object.keys(usersCheck).length > 0) {
      setLoading(false);
      dispatch(setGlobalLoading(false));
    } else {
      setLoading(true);
      dispatch(setGlobalLoading(true));
    }
  }, [usersCheck]);

  return (
    <>
      <div ref={sideWrapper} className={`sideNavContainer ${active ? "active" : ""}`}>
        <div className="closeRow" />
        <ScrollWrapper css="no-padding">
          <nav className="sideNavBar">
            <ul className="sideMainNav phoneView">
              <li className="sideMenuItem">
                <NavLink to="/catalog">
                  <Icon type="calendar" />
                  Catalog
                </NavLink>
              </li>
              <li className="sideMenuItem">
                <NavLink to="/support">
                  <Icon type="question-circle" />
                  Support
                </NavLink>
              </li>
              <li className="sideMenuItem">
                <NavLink to="/docs">
                  <Icon type="file-text" />
                  Docs
                </NavLink>
              </li>
              <li className="sideMenuItem">
                <NavLink to="/manage">
                  <Icon type="setting" />
                  Manage
                </NavLink>
              </li>
            </ul>
            <hr className="phoneView" />

            <ul className="sideMainNav">
              <li className="sideMenuItem">
                <NavLink
                  to={userRole === "patient" ? "/patient-dashboard" : userRole === "provider" ? "/referral-dashboard" : "/"}
                  className={`dashboaard ${history.location.pathname === "/" ? "is-active" : ""}`}
                  onClick={handleClick}
                >
                  <Icon type="dashboard" />
                  Dashboard
                </NavLink>
              </li>
              <hr />

              {userRole === "user" && usersCheck && !loading && (
                <div>
                  {usersCheck && usersCheck.status === 1 ? (
                    <li id={"new-patient-processing"} className="sideMenuItem">
                      <NavLink to="/new-patient-binder" onClick={handleClick}>
                        <Icon type="contacts" />
                        New Patient Processing
                      </NavLink>
                    </li>
                  ) : null}
                  {usersCheck && usersCheck.status === 1 ? (
                    <li className="sideMenuItem">
                      <NavLink to="/clinic-procedure" onClick={handleClick}>
                        <Icon type="message" />
                        Clinic Processing
                      </NavLink>
                    </li>
                  ) : null}
                  {usersCheck && usersCheck.status === 1 ? (
                    <li id={"procedure_processing"} className="sideMenuItem">
                      <NavLink to="/hospital-procedure" onClick={handleClick}>
                        <Icon type="fork" />
                        Procedure Processing
                      </NavLink>
                    </li>
                  ) : null}
                  {usersCheck && usersCheck.status === 1 ? (
                    <li className="sideMenuItem">
                      <NavLink to="/ultrasound-procedure" onClick={handleClick}>
                        <Icon type="scan" />
                        Ultrasound Processing
                      </NavLink>
                    </li>
                  ) : null}
                  {usersCheck && usersCheck.status === 1 ? (
                    <li className="sideMenuItem">
                      <NavLink to="/prior-authorizations" onClick={handleClick}>
                        <Icon type="lock" />
                        Dependency Processing
                      </NavLink>
                    </li>
                  ) : null}
                  <hr />
                  {usersCheck && usersCheck.status === 1 ? (
                    <ul className="sideMainNav">
                      <li id="clinic_workflow" className="sideMenuItem">
                        <NavLink
                          to="/clinic-workflow/check-in"
                          className={history.location.pathname.includes("clinic-workflow") ? "active" : ""}
                          onClick={handleClick}
                        >
                          <Icon type="apartment" />
                          Clinic Workflow
                        </NavLink>
                      </li>
                    </ul>
                  ) : null}
                  <hr />
                  {usersCheck && usersCheck.status === 1 ? (
                    <ul className="sideMainNav">
                      <li className="sideMenuItem">
                        <NavLink
                          to="/communication/faxing"
                          onClick={handleClick}
                          className={history.location.pathname.includes("communication") ? "active" : ""}
                        >
                          <Icon type="printer" />
                          Communication
                        </NavLink>
                      </li>
                    </ul>
                  ) : null}
                  <hr />
                  {usersCheck && usersCheck.status === 1 ? (
                    <ul className="sideMainNav">
                      <li className="sideMenuItem">
                        <NavLink
                          to="/employee-workflow/employee"
                          onClick={handleClick}
                          className={history.location.pathname.includes("employee-workflow") ? "active" : ""}
                        >
                          <Icon type="solution" />
                          Employee Workflow
                        </NavLink>
                      </li>
                    </ul>
                  ) : null}

                  <hr />

                  {usersCheck && usersCheck.status === 1 ? (
                    <ul className="sideMainNav">
                      <li className="sideMenuItem">
                        <NavLink
                          to="/inventory-workflow/inventory"
                          onClick={handleClick}
                          className={history.location.pathname.includes("inventory-workflow") ? "active" : ""}
                        >
                          <Icon type="gold" />
                          Inventory Workflow
                        </NavLink>
                      </li>
                    </ul>
                  ) : null}

                  <hr />

                  {usersCheck && usersCheck.status === 1 ? (
                    <ul className="sideMainNav">
                      <li className="sideMenuItem">
                        <NavLink
                          id={"obl_clinic"}
                          to="/obl-workflow/check-in"
                          onClick={handleClick}
                          className={history.location.pathname.includes("obl-workflow") ? "active" : ""}
                        >
                          <Icon type="key" />
                          OBL Workflow
                        </NavLink>
                      </li>
                    </ul>
                  ) : null}

                  <hr />
                  {(usersCheck && usersCheck.is_admin === 1) || (usersCheck && usersCheck.is_billing === 1) ? (
                    <ul className="sideMainNav">
                      <li className="sideMenuItem">
                        <NavLink
                          to="/billing/summary"
                          onClick={handleClick}
                          className={history.location.pathname.includes("billing") ? "active" : ""}
                        >
                          <Icon type="dollar" />
                          Billing
                        </NavLink>
                      </li>
                      <hr />
                    </ul>
                  ) : null}
                  {usersCheck && usersCheck.is_admin === 1 && (
                    <ul className="sideMainNav">
                      <li className="sideMenuItem">
                        <NavLink
                          to="/administrator/employee"
                          onClick={handleClick}
                          className={history.location.pathname.includes("administrator") ? "active" : ""}
                        >
                          <Icon type="laptop" />
                          Administrator
                        </NavLink>
                      </li>
                      <hr />
                    </ul>
                  )}

                  {usersCheck && usersCheck.status === 1 ? (
                    <ul className="sideMainNav">
                      <li className="sideMenuItem">
                        <NavLink
                          to="/support/details"
                          onClick={handleClick}
                          className={history.location.pathname.includes("support") ? "active" : ""}
                        >
                          <Icon type="alert" />
                          Support
                        </NavLink>
                      </li>
                    </ul>
                  ) : null}
                </div>
              )}
            </ul>
          </nav>
        </ScrollWrapper>
      </div>
    </>
  );
};

export default SideNav;
