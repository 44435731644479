import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  getPatientsTableAnalytics,
  getPatientReferralTypes,
  getPatientReferralProviders,
} from '../../../store/actions';
import CardBlock from '../../../common/components/Card';
import DataTable from '../../../common/components/DataTable/WhiteHeaderTable';

const PatientAnalytics = ({ startDate, endDate }) => {
  const dispatch = useDispatch();

  const patientStatistics = useSelector(
    (state) => state.common.patientStatistics
  );

  const patientReferralTypes = useSelector(
    (state) => state.common.patientReferralTypes
  );

  const patientReferralProviders = useSelector(
    (state) => state.common.patientReferralProviders
  );

  const [tableData] = useState([]);
  const [referralsProvider, setReferralsProvider] = useState([]);
  const [referralsType, setReferralsType] = useState([]);
  const [milestone, setMileStone] = useState([]);

  useEffect(() => {
    dispatch(getPatientsTableAnalytics(startDate, endDate));
    dispatch(getPatientReferralTypes(startDate, endDate));
    dispatch(getPatientReferralProviders(startDate, endDate));
  }, [startDate, endDate]);

  useEffect(() => {
    if (!patientStatistics) return;

    setMileStone([
      {
        name: 'Total Number of New Patient Referrals',
        total: patientStatistics?.total_new_referrals,
      },
      {
        name: 'Total Number of New Patient Referrals that were scheduled',
        total: patientStatistics?.total_new_scheduled_referrals,
      },
      {
        name: 'Total Number of New Patient Referrals that were not scheduled',
        total: patientStatistics?.total_new_unscheduled_referrals,
      },
      {
        name: 'Average Time from initial referral to scheduling of appointments',
        total: patientStatistics?.average_time,
      },
    ]);
  }, [patientStatistics]);

  useEffect(() => {
    if (!patientReferralTypes) return;

    setReferralsType(patientReferralTypes);
  }, [patientReferralTypes]);

  useEffect(() => {
    if (!patientReferralProviders) return;

    setReferralsProvider(patientReferralProviders);
  }, [patientReferralProviders]);

  const minRowCount = 8;
  let emptyRows = [];
  if (tableData?.length < minRowCount + 1) {
    emptyRows = new Array(minRowCount - tableData?.length).fill({ label: '' });
  }

  return (
    <div
      style={{
        width: '100%',
        height: 'calc(35% - 10px)',
        display: 'flex',
      }}
    >
      <CardBlock
        title='Processing Statistics'
        varient='one_third'
        other=''
        contentPadding='1rem'
      >
        <DataTable
          title='processing-statistics'
          labels={['Milestones', 'Total']}
          widths={['70%', '30%']}
          tableWidth={300}
          defaultActive={-1}
          sizeHeightFix='100%'
          isFixHeight
          isNormal
          bordered
          disableIcon
          disableDelete
          columns={[
            { sortable: false, key: 'milestone' },
            { sortable: false, key: 'total' },
          ]}
          rowData={milestone.map((item) => {
            return {
              milestone: <b>{item.name}</b>,
              total: item.total,
            };
          })}
          emptyRows={emptyRows}
          sorts={['', '']}
        />
      </CardBlock>
      <CardBlock
        title='Referral Type'
        varient='one_third'
        other=''
        contentPadding='1rem'
      >
        <DataTable
          title='processing-statistics'
          labels={['Condition', 'Total']}
          widths={['70%', '30%']}
          tableWidth={300}
          defaultActive={-1}
          sizeHeightFix='100%'
          isFixHeight
          isNormal
          bordered
          disableIcon
          disableDelete
          columns={[
            { sortable: false, key: 'condition' },
            { sortable: false, key: 'total' },
          ]}
          rowData={referralsType.map((type, index) => {
            return {
              id: index,
              condition: type.referral_condition,
              total: type.total,
            };
          })}
          emptyRows={emptyRows}
          sorts={['', '']}
        />
      </CardBlock>
      <CardBlock
        title='Referral Providers'
        varient='one_third'
        other=''
        contentPadding='1rem'
      >
        <DataTable
          title='processing-statistics'
          labels={['Provider', 'Total']}
          widths={['70%', '30%']}
          tableWidth={300}
          defaultActive={-1}
          sizeHeightFix='100%'
          isFixHeight
          isNormal
          bordered
          disableIcon
          disableDelete
          columns={[
            { sortable: false, key: 'provider' },
            { sortable: false, key: 'total' },
          ]}
          rowData={referralsProvider.map((provider, index) => {
            return {
              id: index,
              provider: provider.full_name,
              total: provider.total,
            };
          })}
          emptyRows={emptyRows}
          sorts={['', '']}
        />
      </CardBlock>
    </div>
  );
};

export default PatientAnalytics;