import { Tabs } from "@material-ui/core";
import { Tooltip } from "antd";
import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import { FemaleSVG, MaleSVG } from "../../../common/components/icons/FaxSVG";
import PusherCheck from "../../../common/components/PusherCheck";
import TopBar from "../../../common/components/SubTopBar";
import { getLocal } from "../../../helpers/Local";
import {
  getLocations,
  getProviders,
  getReferralConditions,
  getUltrasounds,
  get_ultrasound_finding_value,
  remove_selected_patient_resource,
  save_ultrasound_finding_value,
  setActiveReviewChange,
  setFirstLoadingActive,
  setGlobalLoading,
  setGlobalToastr,
} from "../../../store/actions";
import { UltrasoundTabHeaders } from "../constant";
import ClinicSchedule from "./ultrasoundViews/ClinicSchedule";
import Review from "./ultrasoundViews/Review";
import UltrasoundFindings from "./ultrasoundViews/UltrasoundFindings";

class UltrasoundModule extends Component {
  state = {
    activeTab: 0,
    isSelectedPatient: false,
    valueSaveFindding: null,
    stateStatusChange: null,
  };

  componentDidMount = () => {
    this.props.getLocations();
    this.props.getProviders();
    this.props.getReferralConditions();
    this.props.getUltrasounds();
  };

  componentWillReceiveProps(nextProps) {
    const isSelectedPatientProps = nextProps?.selectedPatientResource || {};
    if (this.state.activeTab == 0 && Object.keys(isSelectedPatientProps).length > 0 && nextProps?.firstLoadingActive) {
      this.setState({
        activeTab: 2,
      });
      this.props.setFirstLoadingActive(false);
    }
    if (Object.keys(isSelectedPatientProps).length > 0) {
      this.setState({
        isSelectedPatient: true,
      });
    } else {
      this.setState({
        isSelectedPatient: false,
      });
    }
  }

  renderSideComponent = () => {
    const { selectedPatientResource, resourceCheck } = this.props;
    if (!resourceCheck || !Object.keys(resourceCheck).length) {
      return null;
    }

    const fullName = (resourceCheck?.first_name + " " + resourceCheck?.last_name).toUpperCase();
    const age = resourceCheck?.date_of_birth ? "(" + moment().diff(resourceCheck?.date_of_birth, "years") + ")" : "";
    const allerrgies = [];
    if (resourceCheck?.allergies) {
      (resourceCheck?.allergies || []).forEach((allergy, index) => {
        const allergyName =
          allergy.reaction === "anaphylaxis" || allergy.status === "anaphylaxis" ? (
            <span
              style={{
                color: "red",
                fontSize: "0.625rem",
                fontWeight: 600,
              }}
              key={`${allergy?.id}-${allergy.allergy_name}`}
            >
              {allergy.allergy_name}
              {index < resourceCheck?.allergies.length - 1 ? ", " : ""}
            </span>
          ) : (
            <>
              {allergy.allergy_name}
              {index < resourceCheck?.allergies.length - 1 ? ", " : ""}
            </>
          );
        allerrgies.push(allergyName);
      });
    }

    return (
      <div
        style={{
          display: "flex",
          textAlign: "left",
          paddingRight: "12px",
          paddingLeft: "6px",
          paddingTop: "0px",
        }}
      >
        <div>
          <h3
            style={{
              color: "#152934",
              fontWeight: 400,
              fontSize: "1.125rem",
              lineHeight: "24px",
              whiteSpace: "nowrap",
              textAlign: "right",
              margin: 0,
              position: "relative",
              left: 1.5,
            }}
          >
            {fullName} {age}
          </h3>
          {allerrgies.length === 0 && (
            <div
              style={{
                textAlign: "right",
                whiteSpace: "nowrap",
                lineHeight: "10px",
              }}
            >
              <span
                style={{
                  color: "#da1e28",
                  fontSize: "0.625rem",
                  fontWeight: 600,
                }}
              >
                {resourceCheck?.no_drug_allergies ? "NKDA" : "Pending Data"}
              </span>
            </div>
          )}
          {allerrgies.length >= 1 && allerrgies.length < 3 && (
            <div
              style={{
                textAlign: "right",
                whiteSpace: "nowrap",
                lineHeight: "10px",
                color: "black",
                fontSize: "0.625rem",
                fontWeight: 600,
              }}
            >
              {allerrgies.map((allergy, index) => (
                <React.Fragment key={`Al-${index}`}>{allergy}</React.Fragment>
              ))}
            </div>
          )}
          {allerrgies.length >= 3 && (
            <Tooltip placement="bottomRight" title={(resourceCheck?.allergies || []).map((allergy) => allergy?.allergy_name).join(", ")}>
              <div
                style={{
                  textAlign: "right",
                  whiteSpace: "nowrap",
                  lineHeight: "10px",
                  color: "black",
                  fontSize: "0.625rem",
                  fontWeight: 600,
                }}
              >
                {allerrgies.map((allergy, index) => (
                  <React.Fragment key={`Alg-${index}`}>{allergy}</React.Fragment>
                ))}
              </div>
            </Tooltip>
          )}
        </div>

        {selectedPatientResource.gender && selectedPatientResource.gender.toUpperCase() == "MALE" ? (
          <MaleSVG style={{ marginLeft: "8px", height: "unset", width: "26px" }} />
        ) : (
          <FemaleSVG style={{ marginLeft: "8px", height: "unset", width: "26px" }} />
        )}
      </div>
    );
  };

  goNextTabReview = () => {
    this.setState({
      activeTab: 2,
    });
    this.props.setActiveReviewChange(false);
  };

  conditionClickProps = (value) => {
    this.setState({
      stateCondition: value,
    });
  };

  checkClick = () => {
    const { selectedPatientResource } = this.props;
    if (selectedPatientResource && selectedPatientResource.has_valid_insurance === 0) {
      this.props.setGlobalToastr({
        header: "Ultrasound Workflow",
        message: "Please verify the patient insurance before proceeding",
        type: "failed",
      });
    }
  };

  changeTabIndex = async (index) => {
    if (index === 0) {
      this.props.remove_selected_patient_resource();
    }
    if (this.state.activeTab === 1 && this.state.valueSaveFindding) {
      this.props.setGlobalLoading(true);
      await this.props.save_ultrasound_finding_value(this.state.valueSaveFindding);
      const appointment_ultrasound_id = getLocal("appointment_ultrasound_id");
      await this.props.get_ultrasound_finding_value(appointment_ultrasound_id);
      this.props.setGlobalLoading(false);
    }

    this.setState({ activeTab: index, valueSaveFindding: null });
  };

  saveChangeFindding = (value) => {
    this.setState({
      valueSaveFindding: value,
    });
  };

  updateStatusPusher = (value) => {
    if (value && value.appointment_id) {
      this.setState({
        stateStatusChange: value,
      });
    }
  };

  render() {
    const { activeTab, isSelectedPatient } = this.state;
    const { selectedPatientResource, lockUltrasound } = this.props;

    return (
      <div className="clinicWorkflow__main-page">
        <TopBar title="Ultrasound Module" isBarcode={isSelectedPatient} sideComponent={this.renderSideComponent()} subStatus={""} />
        <div className={`clinicWorkflow__main-container ${this.props.isSidebar ? "openSidebar" : ""}`}>
          <div className="clinicWorkflow__main-subTitle tabContainer">
            <div className="tabHeaders" style={{ height: "29px" }}>
              <Tabs
                TabIndicatorProps={{ style: { display: "none" } }}
                value={activeTab}
                scrollButtons="auto"
                variant="scrollable"
                aria-label="scrollable force tabs example"
              >
                {UltrasoundTabHeaders.map((tab, index) =>
                  this.state.isSelectedPatient && !lockUltrasound && selectedPatientResource.has_valid_insurance === 1 ? (
                    <div
                      id={`${tab?.label && tab?.label.length > 0 && tab.label.replaceAll(" ", "_")}`}
                      key={`tabHeaders-${tab.label}-${index}`}
                      className={`tabHeaders-item ${activeTab === index ? "active" : ""}`}
                      style={{ width: tab.width }}
                      onClick={() => this.changeTabIndex(index)}
                    >
                      {tab.label}
                    </div>
                  ) : (
                    <div
                      id={`${tab?.label && tab?.label.length > 0 && tab.label.replaceAll(" ", "_")}`}
                      key={`tabHeaders-${tab.label}-${index}`}
                      className={`tabHeaders-item ${activeTab === index ? "active" : ""} ${index > 0 && "disabled"}`}
                      style={{ width: tab.width }}
                    >
                      {tab.label}
                    </div>
                  )
                )}
              </Tabs>
            </div>
          </div>
          <PusherCheck updateStatus={this.updateStatusPusher} />
          {activeTab >= 0 && (
            <ClinicSchedule
              onClick={() => {
                this.checkClick();
              }}
              stateStatusProps={this.state.stateStatusChange}
              updateStatusProps={() => {
                this.setState({ stateStatusChange: null });
              }}
              display={activeTab === 0}
              goNextTab={this.goNextTab_}
              goNextTabReview={this.goNextTabReview}
              isClinicWorkFlow={true}
            />
          )}
          {!lockUltrasound && selectedPatientResource && selectedPatientResource.has_valid_insurance === 1 && activeTab === 1 && (
            <UltrasoundFindings saveChange={this.saveChangeFindding} patientResource={selectedPatientResource} />
          )}
          {!lockUltrasound && selectedPatientResource && selectedPatientResource.has_valid_insurance === 1 && activeTab === 2 && (
            <Review patientResource={selectedPatientResource} />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  firstLoadingActive: state.common.firstLoadingActive,
  selectedPatientResource: state.patientSchedule.selectedPatientResource,
  resourceCheck: state.patientSnapshot.resource,
  isSidebar: state.sidebar.isSidebarOpen,
  lockUltrasound: state.common.lockUltrasound,
});
const mapDispatchToProps = {
  getLocations,
  getProviders,
  getReferralConditions,
  getUltrasounds,
  setActiveReviewChange,
  setGlobalToastr,
  setFirstLoadingActive,
  save_ultrasound_finding_value,
  get_ultrasound_finding_value,
  setGlobalLoading,
  remove_selected_patient_resource,
};
export default connect(mapStateToProps, mapDispatchToProps)(UltrasoundModule);
