import { Checkbox, Icon } from "antd";
import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import { HPIItem } from "../../../../common/components/AutoAccordionProcedure";
import ScrollWrapper from "../../../../common/components/ScrollBar";
import { getLocal } from "../../../../helpers/Local";
import { angioPatientSnapshotLoadInit, patientExaminationSave, setSidebarState } from "../../../../store/actions";
import { OperativesTemp, StaticResource } from "../../constant";
import PreOperativeSideBar from "./PreOperativeSidebar";

export class PreOperative extends Component {
  state = {
    isSidebar: window.innerWidth <= 768 ? false : true,
    dataAll: {},
    patient_id_event: null,
    patient_id: null,
    pre_operative_procedure_data: {},
    pre_operations_state: [],
    dataPost: [],
    checkedAllNormalsP: false,
    firstCheck: false,
  };
  timer = null;

  fetchCheck = async () => {
    const id = getLocal("current_appointment_id");
    const pre = await this.props.angioPatientSnapshotLoadInit(id);
    if (pre) {
      this.updateState(pre);
    }
  };

  arrCheckRender = (valueArr) => {
    let dataCheck = [];
    let name = "";
    const arraycheck = (valueArr || []).map((r) => {
      if (r.type) {
        if (r.type === "Patient Admission") {
          name = "patient_admission";
        } else if (r.type === "NPO Status") {
          name = "npo_status";
        } else if (r.type === "Contrast Allergy") {
          name = "contrast_allergy";
        } else if (r.type === "Latex Allergy") {
          name = "latex_allergy";
        } else if (r.type === "Anticoagulation") {
          name = "anticoagulation";
        } else if (r.type === "Last Anticoagulation") {
          name = "last_anticoagulation";
        } else if (r.type === "Breath Sounds") {
          name = "breath_sounds";
        } else if (r.type === "Respiratory Pattern") {
          name = "respiratory_pattern";
        } else if (r.type === "Cardiac") {
          name = "cardiac";
        } else if (r.type === "INR") {
          name = "inr";
        } else if (r.type === "Creatinine") {
          name = "creatinine";
        } else if (r.type === "ABI") {
          name = "abi";
        } else if (r.type === "ASA Classification") {
          name = "asa_classification";
        } else if (r.type === "Reviewed") {
          name = "reviewed";
        } else if (r.type === "Informed") {
          name = "informed";
        } else {
          name = r.type;
        }
        dataCheck = [
          ...dataCheck,
          {
            field_name: name,
            field_value: r?.value || r?.values || "",
            default_comments: r.default_comments,
            comments: r.comments,
          },
        ];
      }
      if (r.type == "Respiratory" || r.type == "Cardiovascular" || r.type == "Constitutional" || r.type == "Neurological") {
        return {
          type: r.type,
          comments: r.comments,
          default_comments: r.default_comments,
          extra: r.extra,
          values: r?.value || r?.values || "",
        };
      } else {
        return {
          type: r.type,
          comments: r.comments,
          default_comments: r.default_comments,
          extra: r.extra,
          value: r?.value || r?.values || "",
        };
      }
    });
    return { arraycheck, dataCheck };
  };

  updateState = (pre) => {
    if (pre?.all_normal_pre_operative == true || pre?.all_normal_pre_operative == 1) {
      this.setState({
        checkedAllNormalsP: true,
      });
    } else {
      this.setState({
        checkedAllNormalsP: false,
      });
    }
    if (pre?.pre_operative) {
      // check arr Global
      const { arraycheck, dataCheck } = this.arrCheckRender(pre?.pre_operative);
      //
      let neftData = {};
      dataCheck.map((r) => {
        neftData = { ...neftData, [r?.field_name]: r?.field_value };
      });
      this.setState({ dataAll: neftData });
      this.setState({ dataPost: arraycheck });
      this.setState({
        pre_operative_procedure_data: { fields: dataCheck },
      });
    }
  };

  async componentDidMount() {
    this.setState({
      patient_id_event: getLocal("current_appointment_id"),
      patient_id: getLocal("current_patient_id"),
    });
    this.props.setSidebarState(this.state.isSidebar);
    this.fetchCheck();
  }

  componentWillUnmount() {
    this.props.setSidebarState(false);
  }

  handleSidebar = () => {
    this.setState({ isSidebar: true });
  };

  handleCloseSidebar = () => {
    this.setState({ isSidebar: false });
  };

  toggleSidebar = () => {
    this.setState({ isSidebar: !this.state.isSidebar });
  };

  onChangeOperations = async (value, type) => {
    const { pre_operations_state, dataPost } = this.state;
    const operationIndex = pre_operations_state.findIndex((pre) => pre.type === type);
    if (operationIndex > -1) {
      pre_operations_state[operationIndex] = { type, value };
    } else {
      pre_operations_state.push({ type, value });
    }
    pre_operations_state.map((r) => {
      const indexcheck = (this.state.dataPost || []).findIndex((index) => index.type == r.type);
      if (indexcheck != -1 && this.state.dataPost[indexcheck]) {
        this.state.dataPost[indexcheck]["value"] = r?.value;
      }
    });
    this.setState({ pre_operations_state, dataPost });
    if (this.props.dataPostCheckAll) {
      this.props.dataPostCheckAll([...(this.state.dataPost || [])], this.state.checkedAllNormalsP);
    }
  };

  onChangeTimePicker = (value, type) => {
    const { dataAll } = this.state;
    const time = moment(value).format("hh:mm A");
    if (type === "Patient Admission") {
      this.setState({ dataAll: { ...dataAll, patient_admission: time } });
    }
    this.onChangeOperations(value, type);
  };

  onChangeDatePicker = (value, type) => {
    const { dataAll } = this.state;
    const time = moment(value).format("MM/DD/YYYY");
    if (type === "Last Anticoagulation") {
      this.setState({ dataAll: { ...dataAll, last_anticoagulation: time } });
    }
    this.onChangeOperations(value, type);
  };

  onChangeSelect = (value, type) => {
    const { dataAll } = this.state;
    if (type === "NPO Status") {
      this.setState({ dataAll: { ...dataAll, npo_status: value } });
    } else if (type === "Anticoagulation") {
      this.setState({ dataAll: { ...dataAll, anticoagulation: value } });
    } else if (type === "ASA Classification") {
      this.setState({ dataAll: { ...dataAll, asa_classification: value } });
    }
    this.onChangeOperations(value, type);
  };

  clickChangeRow = (value, type) => {
    const { dataAll } = this.state;
    if (type === "Contrast Allergy") {
      this.setState({
        dataAll: {
          ...dataAll,
          contrast_allergy: value,
        },
      });
    } else if (type === "Latex Allergy") {
      this.setState({
        dataAll: {
          ...dataAll,
          latex_allergy: value,
        },
      });
    } else if (type === "Breath Sounds") {
      this.setState({
        dataAll: {
          ...dataAll,
          breath_sounds:
            dataAll && dataAll["breath_sounds"] && dataAll["breath_sounds"].some((r) => r === value)
              ? [...(dataAll["breath_sounds"] || []).filter((r) => r !== value)]
              : [...(dataAll["breath_sounds"] || []), value],
        },
      });
    } else if (type === "Respiratory Pattern") {
      this.setState({
        dataAll: {
          ...dataAll,
          respiratory_pattern:
            dataAll && dataAll["respiratory_pattern"] && dataAll["respiratory_pattern"].some((r) => r === value)
              ? [...(dataAll["respiratory_pattern"] || []).filter((r) => r !== value)]
              : [...(dataAll["respiratory_pattern"] || []), value],
        },
      });
    } else if (type === "Cardiac") {
      this.setState({
        dataAll: {
          ...dataAll,
          cardiac:
            dataAll && dataAll["cardiac"] && dataAll["cardiac"].some((r) => r === value)
              ? [...(dataAll["cardiac"] || []).filter((r) => r !== value)]
              : [...(dataAll["cardiac"] || []), value],
        },
      });
    } else {
      this.setState({
        dataAll: {
          ...dataAll,
          [`${type}`]: value,
        },
      });
    }
    this.onChangeOperations(value, type);
  };

  onChangeInput = (value, type) => {
    const { dataAll } = this.state;
    if (type === "INR") {
      this.setState({ dataAll: { ...dataAll, inr: value } });
    } else if (type === "Creatinine") {
      this.setState({ dataAll: { ...dataAll, creatinine: value } });
    } else if (type === "ABI") {
      this.setState({ dataAll: { ...dataAll, abi: value } });
    }
    this.onChangeOperations(value, type);
  };

  onCheckBox = (value, type) => {
    const { dataAll } = this.state;
    if (type === "Reviewed") {
      this.setState({ dataAll: { ...dataAll, reviewed: value ? 1 : 0 } });
    } else {
      this.setState({ dataAll: { ...dataAll, informed: value ? 1 : 0 } });
    }
    this.onChangeOperations(value, type);
  };

  onChangeCommentCheck = (value, type) => {
    const { dataPost, pre_operative_procedure_data } = this.state;
    const indexcheck = (dataPost || []).findIndex((index) => index.type === type);
    if (indexcheck >= 0) {
      dataPost[indexcheck]["comments"] = value;
      if (
        pre_operative_procedure_data &&
        pre_operative_procedure_data?.fields &&
        pre_operative_procedure_data.fields[indexcheck] &&
        pre_operative_procedure_data?.fields.length > 0
      ) {
        pre_operative_procedure_data.fields[indexcheck]["comments"] = value;
      }
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.setState({ firstCheck: true });
      }, 500);
      if (this.state.firstCheck) {
        if (this.props.dataPostCheckAll) {
          this.props.dataPostCheckAll([...(this.state.dataPost || [])], this.state.checkedAllNormalsP);
        }
      }
      this.setState({ pre_operative_procedure_data, dataPost });
    }
  };

  handleUnClick = (value, type, isMulti) => {
    const { dataAll, pre_operative_procedure_data } = this.state;
    if (!isMulti) {
      // fix when unCheck with not multi
      const neftType = type && type.length > 0 ? type.toLowerCase().replaceAll(" ", "_") : "";
      const dataNeft = [...(pre_operative_procedure_data?.fields || [])];
      if ((dataAll && dataAll[neftType] && dataAll[neftType] === value) || (dataAll && dataAll[type] && dataAll[type] === value)) {
        if (dataNeft && dataNeft.length > 0) {
          // uncheck = fix data local and data props
          const findIndexNeft = dataNeft.findIndex((r) => r?.field_name == neftType || r?.field_name == type);
          if (findIndexNeft != -1) {
            dataNeft[findIndexNeft]["field_value"] = null;
            this.setState({
              pre_operative_procedure_data: { fields: dataNeft },
            });
            this.clickChangeRow(null, type);
          }
        }
      } else {
        if (dataNeft && dataNeft.length > 0) {
          // update check = fix data local and data props
          const findIndexNeft = dataNeft.findIndex((r) => r?.field_name == neftType || r?.field_name == type);
          if (findIndexNeft != -1) {
            dataNeft[findIndexNeft]["field_value"] = value;
            this.setState({
              pre_operative_procedure_data: { fields: dataNeft },
            });
          }
        }
        this.clickChangeRow(value, type);
      }
    } else {
      this.clickChangeRow(value, type);
    }
  };

  sidebarRef = null;
  constructor(props) {
    super(props);
    this.sidebarRef = React.createRef();
  }

  render() {
    const { isSidebar, pre_operative_procedure_data } = this.state;
    return (
      <div className="clinicWorkflow__main-section">
        <div className="eventsContainer has-sub-header" style={{ paddingTop: "1rem" }}>
          <ScrollWrapper css="no-padding x-hidden">
            <div className="conditionList" style={{ minHeight: "100%" }}>
              <Checkbox
                checked={this.state.checkedAllNormalsP}
                onChange={(e) =>
                  this.setState({
                    checkedAllNormalsP: e.target.checked,
                  })
                }
                disabled={this.props.is_locked}
              >
                All negative except selections
              </Checkbox>

              <div className="listSection">
                {OperativesTemp.map((condition, index) => (
                  <HPIItem
                    checkedAllNormalsP={this.state.checkedAllNormalsP}
                    mode={condition.mode}
                    format={condition.format}
                    key={`ros-vitals-${index}`}
                    label={condition.label}
                    isIcon={condition.isIcon}
                    options={condition.options}
                    value={pre_operative_procedure_data}
                    type={condition.type}
                    multiple={condition.multiple}
                    onChangeCommentCheck={(value, type) => {
                      this.onChangeCommentCheck(value, type);
                    }}
                    onChangeTimePicker={(value, type) => this.onChangeTimePicker(value, type)}
                    onChangeDatePicker={(value, type) => this.onChangeDatePicker(value, type)}
                    onChangeSelect={(value, type) => this.onChangeSelect(value, type)}
                    clickChangeRow={(value, type) => {
                      this.handleUnClick(value, type, condition.multiple);
                    }}
                    onChangeInput={(value, type) => {
                      this.onChangeInput(value, type);
                    }}
                    onCheckBox={(value, type) => {
                      this.onCheckBox(value, type);
                    }}
                    isLocked={this.props.is_locked}
                  />
                ))}
              </div>
            </div>
          </ScrollWrapper>
        </div>
        {isSidebar === true && (
          <PreOperativeSideBar
            sidebarIcon={<Icon type="copy" theme="twoTone" twoToneColor="#198038" />}
            ref={this.sidebarRef}
            value={this.state.pre_operative_procedure_data}
            resource={StaticResource}
            dataAll={this.state.dataAll}
            isShow={isSidebar}
            handleCloseSidebar={this.handleCloseSidebar}
          />
        )}
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  all_normal_pre_operative: state.patientExamination.all_normal_pre_operative,
  pre_operative: state.patientExamination.pre_operative,
  is_locked: state.common.is_locked,
});

const mapDispatchToProps = {
  patientExaminationSave,
  angioPatientSnapshotLoadInit,
  setSidebarState,
};

export default connect(mapStateToProps, mapDispatchToProps)(PreOperative);
