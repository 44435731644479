import { Input } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DeleteIcon from "../../../common/components/icons/DeleteIconButton";
import { SearchSVG } from "../../../common/components/icons/SVGIcon";
import { setLocal } from "../../../helpers/Local";
import {
  adminReferralConditionGet,
  adminReferralConditionList,
  adminReferralConditionPost,
  adminReferralConditionUpdate,
  delete_referral_conditions,
  getConditionVariable,
  setGlobalLoading,
} from "../../../store/actions";

const { Search } = Input;
let timer = null;

const ConditionSearch = ({ setResource, setFirstProps }) => {
  const loading = useSelector((state) => state.adminReferralCondition.loading);
  const loading_condition_type = useSelector((state) => state.procedureDetail.loading_condition_type);
  const dispatch = useDispatch();
  const [conditionID, setConditionId] = useState(0);
  const [activeDelete, setActiveDelete] = useState(-1);
  const [headerTitle, setHeaderTitle] = useState("");
  const referralConditions = useSelector((state) => state.adminReferralCondition.referralConditions);

  const [activeRow, setActiveRow] = useState(-1);
  const [dataRow, setDataRow] = useState([]);
  const [loadingLocal, setLoadingLocal] = useState(false);
  const [search, setSearch] = useState("");
  const emptyRows = [];

  const handleSelect = async (row, index) => {
    await setActiveRow(index);
    if (conditionID !== row?.id) {
      setFirstProps && setFirstProps(false);
      setConditionId(row?.id);
      setResource(row?.id);
      setLocal("id_procedure_type_icd", row?.id);
      await dispatch(adminReferralConditionGet(row?.id));
      await dispatch(getConditionVariable(row?.id));
      setFirstProps && setFirstProps(true);
    }
  };

  const handleAddCondition = async () => {
    if (!search.trim()) {
      return;
    }
    setLoadingLocal(true);
    const data = {
      name: search,
      text_template: "",
    };
    const addsuccess = await dispatch(adminReferralConditionPost(data));
    if (addsuccess) {
      setSearch("");
      setLoadingLocal(false);
      dispatch(adminReferralConditionList());
    }
  };

  useEffect(() => {
    dispatch(adminReferralConditionList());
  }, []);

  const handleSearch = (e) => {
    const value = e.target?.value;
    setActiveRow(-1);
    setSearch(value);
  };

  const handleDelete = async (row, index) => {
    if (activeDelete !== row?.id) {
      setActiveDelete(row?.id);
      return;
    }
    setActiveDelete(-1);
    const deleteSuccess = await dispatch(delete_referral_conditions(row?.id));
    const loadSuccess = await dispatch(adminReferralConditionList());
    if (deleteSuccess && loadSuccess) {
      setActiveRow(-1);
    }
  };

  useEffect(() => {
    const arrayFilter = [...referralConditions].reverse();
    const arraySucces = arrayFilter.filter((item) => {
      return item?.value.toLowerCase().includes(search.toLowerCase());
    });
    setDataRow([...arraySucces]);
  }, [referralConditions, search]);

  const onChangeDetail = async (e, row) => {
    setHeaderTitle(e.target?.value);
    clearTimeout(timer);
    const params = {
      value: e.target?.value,
    };
    timer = setTimeout(async () => {
      await dispatch(adminReferralConditionUpdate(row?.id, params));
      await dispatch(adminReferralConditionGet(row?.id));
      await dispatch(adminReferralConditionList());
    }, 500);
  };

  useEffect(() => {
    dispatch(setGlobalLoading(loadingLocal || loading || loading_condition_type));
  }, [loading, loadingLocal, loading_condition_type]);

  return (
    <div className="patientSearch">
      <div className="patientSearch__filter">
        <div className="patientSearch__filter-search">
          <Search value={search} placeholder="Search Condition" onChange={handleSearch} suffix={<SearchSVG />} />
        </div>
        <div className="patientSearch__filter-action">
          <button className="patientSearch__filter-add" onClick={handleAddCondition}>
            <span>Add</span>
            <span>+</span>
          </button>
        </div>
      </div>
      <div className="fullContainer" style={{ position: "relative" }}>
        <div className="tableSection" style={{ height: "100%" }}>
          <div className="tableContainer" style={{ overflow: "scroll" }}>
            <div className="tableList">
              {(dataRow || []).map((row, index) => (
                <div
                  className={`tableItem yellow ${activeRow === index ? "active" : ""}`}
                  key={`resultTable-${index}`}
                  onClick={() => {
                    handleSelect(row, index);
                  }}
                  style={{ paddingRight: "10px" }}
                >
                  {activeRow === index ? (
                    <div className="td with-icon" style={{ flex: 1, paddingRight: "12px" }}>
                      <Input
                        value={headerTitle}
                        autoFocus={true}
                        onChange={(e) => {
                          onChangeDetail(e, row);
                        }}
                        onFocus={() => {
                          setHeaderTitle(row?.value);
                        }}
                      />
                    </div>
                  ) : (
                    <div className="td with-icon" style={{ flex: 1, paddingRight: "12px" }}>
                      <p>{row?.value}</p>
                    </div>
                  )}
                  {row?.is_default === 1 ? null : (
                    <DeleteIcon icon="can" handleClick={() => handleDelete(row, index)} selected={activeDelete === row?.id} />
                  )}
                </div>
              ))}
              {(emptyRows || []).map((row, index) => (
                <div className={`tableItem`} key={`empty-resultTable-${index}`}>
                  <div className="td" style={{ width: "50%" }} />
                  <div className="td" style={{ width: "50%" }} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConditionSearch;
