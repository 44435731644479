import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import ClinicalCalendar from './components/ClinicalCalendar';
import {
  getUltrasoundEvents,
  setUltrasoundData,
  getProcedureLocations,
  getOnlyProviders,
} from '../../store/actions';

const ProcedureCalendar = (props) => {
  const events = useSelector((state) => state.ultrasound.events);
  const activeOpenings = useSelector(
    (state) => state.ultrasound.activeOpenings,
  );
  const closedDates = useSelector((state) => state.ultrasound.closedDates);
  const openingTime = useSelector((state) => state.ultrasound.openingTime);
  const closingTime = useSelector((state) => state.ultrasound.closingTime);

  const locations = useSelector((state) => state.common.procedureLocations);
  const providers = useSelector((state) => state.common.only_providers);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setUltrasoundData('resource', null));
    const date = moment(new Date()).format('MM/DD/YYYY');
    dispatch(setUltrasoundData('loadingEvents', true));
    dispatch(getUltrasoundEvents({ date }));
  }, []);

  useEffect(() => {
    if (!locations) {
      dispatch(getProcedureLocations());
    }
    if (!providers) {
      dispatch(getOnlyProviders());
    }

  }, [events, locations, providers]);

  const handleEvents = (date, location, provider) => {
    dispatch(setUltrasoundData('loadingEvents', true));
    dispatch(getUltrasoundEvents({ date, location, provider }));
  };

  const onClickEvent = (event) => {
    if (props.onHandleClick && event.patient_id) {
      props.onHandleClick(event);
    }
  };

  return (
    <div
      className="calendarContainer"
      style={{ position: 'relative', left: 10, right: 10 }}
    >
      <ClinicalCalendar
        activeID={props?.activeID}
        onClickEvent={onClickEvent}
        eventData={events || []}
        fetchEvents={handleEvents}
        locations={locations || []}
        providers={providers || []}
        activeOpenings={activeOpenings || []}
        closedDates={closedDates || []}
        openingTime={openingTime || ''}
        closingTime={closingTime || ''}
      />
    </div>
  );
};

export default ProcedureCalendar;
