import React from 'react';
import TableBlock from './TableBlock';

const BlockComponent = ({
  props,
  valueHeader,
  valueHeader2,
  valueFind,
  leftRight,
  hpi_conditions,
}) => {
  const entity = props.contentState.getEntity(props.block.getEntityAt(0));
  const type = entity.getType();
  if (type == 'TABLE') {
    return (
      <TableBlock
        valueHeader2={valueHeader2}
        valueHeader={valueHeader}
        valueFind={valueFind}
        blockProps={props.blockProps}
        block={props.block}
        hpi_conditions={hpi_conditions}
        contentState={props.contentState}
        leftRight={leftRight}
      />
    );
  }
  return null;
};

export default BlockComponent;
