import { DatePicker, Select, TimePicker } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLocal } from "../../../helpers/Local";
import {
  getTimeSlots,
  get_plan_patient,
  rescheduleProcedure,
  saveUltrasoundSchedule,
  setGlobalLoading,
  setProcedureData,
} from "../../../store/actions";
import { ExpandSVG, LeftCharvenSVG } from "../icons/SVGIcon";

const { Option } = Select;
let timer = null;

const getValue = (options, target, key) => {
  if (key && options) {
    const opt = options.find((option) => option[key] == target);
    if (opt) {
      return opt?.value;
    }
    return "";
  }
  return "";
};

const DiseaseComponent = ({
  procedure_id_row,
  handleContinue,
  nextSave,
  titleProps,
  display,
  titlePlan,
  activeRow,
  onButton,
  plans,
  planTypes,
  typePlanId,
  currentPlanId,
  activeStatus,
  is_locked,
  ...restProps
}) => {
  const current_patient_id = getLocal("current_patient_id");
  const current_appointment_id = getLocal("current_appointment_id");
  const data = useSelector((state) => state.patientSchedule);
  const loading = useSelector((state) => state.procedure.loadingSchedule);
  const loadingFirst = useSelector((state) => state.procedure.loadingTimeSlots);
  const timeSlotsCheck = useSelector((state) => state.procedure.timeSlots);
  const providers = useSelector((state) => state.procedure.providers);
  const locations = useSelector((state) => state.procedure.locations);
  const [timeSlots, setTimeSlots] = useState([]);
  const [lock, setLock] = useState(false);

  const dispatch = useDispatch();

  const [activeCustom, setActiveCustom] = useState(false);

  const [customAppointment, setCustomAppointment] = useState({});
  const [checkDetail, setCheckDetail] = useState({});
  const [appointment_date_check, setAppointment_date] = useState(null);
  const [endarterectomy, setEndarterectomy] = useState([]);
  const firstClinic = useSelector((state) => state.procedure.timeSlot);
  const [timeOption, setTimeOption] = useState({
    location: "",
    provider: "",
    period: "",
    prev_date: "",
  });

  useEffect(() => {
    if (firstClinic == -1) {
      setLock(true);
    } else {
      setLock(false);
    }
  }, [firstClinic, typePlanId]);

  useEffect(() => {
    if (checkDetail && Object.keys(checkDetail).length > 0) {
      (timeSlotsCheck || []).map((slot, index) => {
        if (moment(slot.start).format("MM/DD/YYYY") === moment(checkDetail?.ultrasound?.appointment_date).format("MM/DD/YYYY")) {
          dispatch(setProcedureData("timeSlot", index));
        }
      });
    } else {
      dispatch(setProcedureData("timeSlot", -1));
      setAppointment_date(null);
    }
  }, [checkDetail, timeSlotsCheck, typePlanId]);

  useEffect(() => {
    setTimeSlots(timeSlotsCheck);
  }, [timeSlotsCheck, typePlanId]);

  const saveAllDate = (type) => {
    if (type === "time") {
      if (timeSlots && timeSlots[0]) {
        const checkTimeSlots = timeSlots[0];
        if (checkTimeSlots) {
          if (endarterectomy && !endarterectomy.some((r) => r.start === checkTimeSlots.start)) {
            setEndarterectomy([...endarterectomy, checkTimeSlots]);
          }
        }
      }
    }
  };

  const removeState = () => {
    dispatch(setProcedureData("timeSlot", -1));
    setTimeOption({
      location: undefined,
      provider: undefined,
      period: undefined,
      prev_date: undefined,
    });
    setCustomAppointment({});
    setAppointment_date(null);
  };

  const fetchFrist = async () => {
    removeState();
    await setCheckDetail({});
    if (plans && plans.length > 0) {
      const check = plans.find((r) => r.condition_id == typePlanId);
      if (check) {
        const checkDetail = (check["plan"] || []).filter((r) => r.entity_id == procedure_id_row);
        if (checkDetail && checkDetail[0]) {
          await setCheckDetail(checkDetail[0]);
        } else {
          await setCheckDetail(null);
        }
      }
    }
  };

  useEffect(() => {
    fetchFrist();
  }, [procedure_id_row, plans, typePlanId, activeRow]);

  useEffect(() => {
    if (checkDetail && Object.keys(checkDetail).length > 0) {
      if (checkDetail?.ultrasound?.appointment_date) {
        const value = moment(checkDetail?.ultrasound?.appointment_date).format("MM/DD/YYYY");
        setAppointment_date(value || null);
      } else {
        setAppointment_date(null);
      }
    } else {
      dispatch(setProcedureData("timeSlot", -1));
      setAppointment_date(null);
    }
  }, [checkDetail, typePlanId]);

  const handleCustomChange = (idx, type, value) => {
    const customAppointments = { ...customAppointment };
    customAppointments[idx][type] = value;
    setCustomAppointment(customAppointments);
  };

  const toggleCustom = (type) => {
    const isCustomArray = { ...activeCustom };
    const customAppointments = { ...customAppointment };
    isCustomArray[type] = !isCustomArray[type];
    customAppointments[type] = isCustomArray[type]
      ? {
          location: "",
          date: "",
          time: "",
          provider: "",
        }
      : {};

    setActiveCustom(isCustomArray);
    setCustomAppointment(customAppointments);
  };

  const callApiDetail = async () => {
    clearTimeout(timer);
    await dispatch(setGlobalLoading(true));
    timer = setTimeout(async () => {
      if (procedure_id_row) {
        await dispatch(rescheduleProcedure(current_patient_id, procedure_id_row, {}, false));
        await dispatch(setGlobalLoading(false));
      }
    }, 100);
  };

  useEffect(() => {
    callApiDetail();
  }, [procedure_id_row, typePlanId]);

  const setFiirst = (index) => {
    dispatch(setProcedureData("timeSlot", index));
  };

  const handleMoreDates = (type) => {
    saveAllDate(type);
    if (timeSlots && timeSlots.length > 0 && type === "time") {
      const option = {
        ...timeOption,
        prev_date: moment(timeSlots[timeSlots.length - 1].start).format("MM/DD/YYYY"),
      };
      setTimeOption(option);
      dispatch(setProcedureData("loadingTimeSlots", true));
      dispatch(getTimeSlots(data && data.selectedPatientResource && data.selectedPatientResource?.id, procedure_id_row, option, false, false));
      setFiirst(-1);
    }
  };

  const previous = (type) => {
    if (endarterectomy && endarterectomy.length > 0 && type === "time") {
      const option = {
        ...timeOption,
        prev_date: moment(endarterectomy[endarterectomy.length - 1].start).format("MM/DD/YYYY"),
      };
      setTimeOption(option);
      dispatch(setProcedureData("loadingTimeSlots", true));
      dispatch(getTimeSlots(data && data.selectedPatientResource && data.selectedPatientResource?.id, procedure_id_row, option));
      setFiirst(-1);
      const aa = endarterectomy;
      if (aa) {
        aa.splice(endarterectomy.length - 1, 1);
        setEndarterectomy(aa);
      }
    }
  };

  const saveSchedule = async (firstClinicCheck, timeSlotsCheck) => {
    const timeSlot =
      timeSlotsCheck && firstClinicCheck && firstClinicCheck > -1
        ? timeSlotsCheck[firstClinicCheck]
        : timeSlotsCheck && timeSlotsCheck[0]
        ? timeSlotsCheck[0]
        : "";
    if (timeSlot) {
      const params = {
        procedure_date: moment(timeSlot.start).format("MM/DD/YYYY"),
        procedure_time: moment(timeSlot.start).format("hh:mm A"),
        procedure_location: timeSlot.location,
        procedure_provider: timeSlot.doctor,
        procedure_technician: timeSlot.provider,
        plan_id: currentPlanId,
        doc_slot: timeSlot?.slot,
      };
      dispatch(setGlobalLoading(true));
      const loadingSuccess = await dispatch(setProcedureData("loadingSchedule", true));
      const SaveSuccess = await dispatch(saveUltrasoundSchedule(procedure_id_row, params));
      const loadList = await dispatch(get_plan_patient(current_patient_id, current_appointment_id));
      dispatch(setGlobalLoading(false));
      if (nextSave) {
        nextSave(loadList);
        setFiirst(-1);
        setAppointment_date(null);
      }
    }
  };

  const selectTimeSlot = (row, index) => {
    if (!is_locked) {
      setFiirst(index);
      if (row?.start && row.start.includes(" ")) {
        const mixCheck = row?.start.split(" ");
        if (mixCheck[0]) {
          setAppointment_date(mixCheck[0]);
        }
      }
    }
  };

  const handleChangeSlots = (type) => (value) => {
    const prev_date =
      firstClinic !== -1
        ? moment(timeSlots[firstClinic].start).format("MM/DD/YYYY")
        : timeSlots[0]
        ? moment(timeSlots[0].start).format("MM/DD/YYYY")
        : "";
    const newOption = { ...timeOption, prev_date, [type]: value };
    setTimeOption(newOption);
    dispatch(setProcedureData("loadingTimeSlots", true));
    dispatch(getTimeSlots(current_patient_id, procedure_id_row, newOption));
    setFiirst(-1);
  };

  const handleTimeSlot = (value, index) => {
    const mixSlot = [...timeSlots];
    mixSlot[index]["start_slot"] = value;
    const dateSlot = moment()
      .hours(8)
      .minutes(0)
      .add(value * 15, "minutes")
      .format("HH:mm:ss");
    if (mixSlot[index]["start"] && mixSlot[index]["start"].includes(" ")) {
      const mixSplit = mixSlot[index]["start"].split(" ");
      if (mixSplit[0]) {
        mixSlot[index]["start"] = `${mixSplit[0]} ${dateSlot}`;
      }
    }
    if (timeSlots[index].start) {
      const value = moment(timeSlots[index].start).format("MM/DD/YYYY");
      setAppointment_date(value || "");
    }
    setFiirst(index);
    setTimeSlots(mixSlot);
  };

  const checkActive = (slot) => {
    if (appointment_date_check && moment(slot.start).format("MM/DD/YYYY") === moment(appointment_date_check).format("MM/DD/YYYY")) {
      return "active";
    }
    return "";
  };

  const onHandleClick = () => {
    if (!loading && !loadingFirst) {
      saveSchedule(firstClinic, timeSlots);
    }
  };

  return (
    <>
      <div className="resourceContent">
        <React.Fragment key={`clinic-appointment-${0}`}>
          <div className="resourceInfo d-flex" style={{ justifyContent: "space-between" }}>
            <b>{`${titlePlan} (Ultrasound)`}</b>
          </div>

          <div style={{ marginBottom: "4px" }}>
            <div className="resourceInfo between mt-2 sortableHeader" style={{ background: "#f4f7fb" }}>
              <div
                className={`th sortable w-25 hide-icon`}
                style={{
                  paddingLeft: "2px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  alignSelf: "stretch",
                  borderLeft: "1px solid #dfe3e6",
                }}
              >
                <Select
                  disabled={is_locked}
                  placeholder="Location"
                  value={timeOption.location}
                  suffixIcon={<span />}
                  allowClear={false}
                  style={{
                    maxWidth: "100%",
                    width: "100%",
                    background: "transparent",
                    borderBottom: 0,
                    boxShadow: "none",
                  }}
                  onChange={handleChangeSlots("location")}
                >
                  {(locations || []).map((loc, index) => (
                    <Option key={`location-${loc?.id}-${index}`} value={loc?.id}>
                      {loc?.value}
                    </Option>
                  ))}
                </Select>
              </div>
              <div
                className={`th sortable w-25 hide-icon`}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  alignSelf: "stretch",
                  borderLeft: "1px solid #dfe3e6",
                }}
              >
                Date
                <div>
                  <button
                    disabled={is_locked}
                    className="more-dates backward"
                    style={{
                      marginRight: "0.5rem",
                      padding: "2px",
                      minWidth: "unset",
                      width: "auto",
                      height: "auto",
                      border: "none",
                      background: "transparent",
                      color: "#3d70be",
                      marginTop: 0,
                    }}
                    onClick={() => {
                      previous("time");
                    }}
                  >
                    <LeftCharvenSVG />
                  </button>
                  <button
                    disabled={is_locked}
                    className="more-dates forward"
                    style={{
                      padding: "2px",
                      minWidth: "unset",
                      width: "auto",
                      height: "auto",
                      border: "none",
                      background: "transparent",
                      color: "#3d70be",
                      marginTop: 0,
                    }}
                    onClick={() => {
                      handleMoreDates("time");
                    }}
                  >
                    <LeftCharvenSVG />
                  </button>
                </div>
              </div>
              <div
                className={`th sortable w-25 hide-icon`}
                style={{
                  paddingLeft: "2px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  alignSelf: "stretch",
                  borderLeft: "1px solid #dfe3e6",
                }}
              >
                <Select
                  disabled={is_locked}
                  placeholder="Time"
                  value={timeOption.period}
                  suffixIcon={<span />}
                  onChange={handleChangeSlots("period")}
                  style={{
                    maxWidth: "100%",
                    width: "100%",
                    background: "transparent",
                    borderBottom: 0,
                    boxShadow: "none",
                  }}
                >
                  <Option value="am">AM</Option>
                  <Option value="pm">PM</Option>
                </Select>
              </div>
              <div
                className={`th sortable w-25 hide-icon`}
                style={{
                  paddingLeft: "2px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  alignSelf: "stretch",
                  borderLeft: "1px solid #dfe3e6",
                }}
              >
                <Select
                  disabled={is_locked}
                  placeholder="Provider"
                  value={timeOption.provider}
                  suffixIcon={<span />}
                  allowClear={false}
                  mod="multiple"
                  onChange={handleChangeSlots("provider")}
                  style={{
                    maxWidth: "100%",
                    width: "100%",
                    background: "transparent",
                    borderBottom: 0,
                    boxShadow: "none",
                  }}
                >
                  {(providers || []).map((provider, index) => (
                    <Option key={`provider-${provider?.id}-${index}`} value={provider?.id}>
                      {provider?.value}
                    </Option>
                  ))}
                </Select>
              </div>
            </div>
            {!loadingFirst &&
              (timeSlots || []).map((slot, index) => (
                <React.Fragment key={`ime-slots-${index}`}>
                  <div
                    className={`resourceInfo between t-row 
                       ${checkActive(slot) == "active" && firstClinic == index && firstClinic !== -1 ? "active" : ""}`}
                    style={{ alignItems: "center" }}
                    onClick={() => selectTimeSlot(slot, index)}
                  >
                    <div className="w-25">{getValue(locations, slot.location, "id")}</div>
                    <div className="w-25">{slot.start && moment(slot.start).format("MM/DD/YYYY")}</div>
                    <div
                      className="w-25"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        paddingLeft: 0,
                        marginTop: 0,
                      }}
                    >
                      <Select
                        disabled={is_locked}
                        placeholder="Timer check all"
                        value={slot?.start_slot}
                        suffixIcon={<ExpandSVG />}
                        allowClear={false}
                        style={{
                          maxWidth: "100%",
                          width: "100%",
                        }}
                        onChange={(value) => {
                          handleTimeSlot(value, index);
                        }}
                      >
                        {(slot?.slots || []).map((time, index) => (
                          <Option key={`time-${time}-${index}`} value={time}>
                            {moment()
                              .hours(8)
                              .minutes(0)
                              .add(time * 15, "minutes")
                              .format("hh:mm A")}
                          </Option>
                        ))}
                      </Select>
                    </div>
                    <div className="w-25">{getValue(providers, slot.doctor, "id")}</div>
                  </div>
                  <hr className="small-block" />
                </React.Fragment>
              ))}
            {loadingFirst && (
              <React.Fragment key={`time-slots-1`}>
                <div className={`resourceInfo between t-row`} style={{ alignItems: "center" }}>
                  <div className="w-25"></div>
                  <div className="w-25"></div>
                  <div className="w-25"></div>
                  <div className="w-25"></div>
                </div>
                <hr className="small-block" />
                <div className={`resourceInfo between t-row`} style={{ alignItems: "center" }}>
                  <div className="w-25"></div>
                  <div className="w-25"></div>
                  <div className="w-25"></div>
                  <div className="w-25"></div>
                </div>
                <hr className="small-block" />
                <div className={`resourceInfo between t-row`} style={{ alignItems: "center" }}>
                  <div className="w-25"></div>
                  <div className="w-25"></div>
                  <div className="w-25"></div>
                  <div className="w-25"></div>
                </div>
              </React.Fragment>
            )}
          </div>

          {activeCustom["first"] && (
            <div className="resourceInfo between mt-2 sortableHeader" style={{ background: "#f4f7fb" }}>
              <div
                className={`th sortable w-25 hide-icon`}
                style={{
                  paddingLeft: "2px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  alignSelf: "stretch",
                  borderLeft: "1px solid #dfe3e6",
                }}
              >
                <Select
                  disabled={is_locked}
                  placeholder="Location"
                  value={customAppointment["first"].location || undefined}
                  suffixIcon={<span />}
                  allowClear={false}
                  style={{
                    maxWidth: "100%",
                    width: "100%",
                    background: "transparent",
                    borderBottom: 0,
                    boxShadow: "none",
                  }}
                  onChange={(value) => handleCustomChange("first", "location", value)}
                >
                  {(locations || []).map((loc, index) => (
                    <Option key={`location-${loc?.id}-${index}`} value={loc?.id}>
                      {loc?.value}
                    </Option>
                  ))}
                </Select>
              </div>

              <div
                className={`th sortable w-25 hide-icon`}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  alignSelf: "stretch",
                  paddingLeft: 0,
                  borderLeft: "1px solid #dfe3e6",
                }}
              >
                <DatePicker
                  disabled={is_locked}
                  onChange={(value) => {
                    handleCustomChange("first", "date", moment(value).format("MM/DD/YYYY"));
                  }}
                  style={{
                    width: "100%",
                    minWidth: "100%",
                    background: "transparent",
                    boxShadow: "none",
                    borderBottom: 0,
                  }}
                  format={"MM/DD/YYYY"}
                  placeholder="Date"
                  suffixIcon={<ExpandSVG />}
                />
              </div>

              <div
                className={`th sortable w-25 hide-icon`}
                style={{
                  paddingLeft: "2px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  alignSelf: "stretch",
                  borderLeft: "1px solid #dfe3e6",
                }}
              >
                <TimePicker
                  disabled={is_locked}
                  format={"h:mm A"}
                  use12Hours
                  placeholder="Time"
                  style={{
                    maxWidth: "100%",
                    width: "100%",
                    background: "transparent",
                    boxShadow: "none",
                    borderBottom: 0,
                  }}
                  disabledHours={() => [0, 1, 2, 3, 4, 5, 20, 21, 22, 23]}
                  minuteStep={15}
                  onChange={(value) => handleCustomChange("first", "time", moment(value).format("h:mm A"))}
                />
              </div>

              <div
                className={`th sortable w-25 hide-icon`}
                style={{
                  paddingLeft: "2px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  alignSelf: "stretch",
                  borderLeft: "1px solid #dfe3e6",
                }}
              >
                <Select
                  disabled={is_locked}
                  placeholder="Provider"
                  value={customAppointment["first"].provider || undefined}
                  suffixIcon={<span />}
                  allowClear={false}
                  style={{
                    maxWidth: "100%",
                    width: "100%",
                    background: "transparent",
                    borderBottom: 0,
                    boxShadow: "none",
                  }}
                  onChange={(value) => handleCustomChange("first", "provider", value)}
                >
                  {(providers || []).map((provider, index) => (
                    <Option key={`provider-${provider?.id}-${index}`} value={provider?.id}>
                      {provider?.value}
                    </Option>
                  ))}
                </Select>
              </div>
            </div>
          )}
        </React.Fragment>
      </div>
      {activeStatus || !lock || firstClinic != -1 ? (
        <div className="actionContent" style={{ paddingRight: "10px", marginTop: 0, minHeight: "16px" }}>
          <button
            disabled={is_locked}
            className={`bottom-btn ${is_locked ? "disable-btn" : ""}`}
            onClick={() => {
              onHandleClick();
            }}
          >
            Continue
          </button>
        </div>
      ) : null}
    </>
  );
};

export default DiseaseComponent;
