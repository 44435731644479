import React from 'react';
import { connect } from 'react-redux';
import AutoCompleteComponent from '../../../common/components/AutoComplete';
import {
  addPatientNote,
  deletePatientNote, getPatientNotes
} from '../../../store/actions';


class NotesComponent extends React.Component {
  state = {
    rows: [],
    searchInput: '',
  };

  componentDidMount() {
    const {resource} = this.props;
    this.props.getPatientNotes(resource?.id);
  }

  render() {
    const {allNotes} = this.props;
    let {rows, searchInput} = this.state;
    if (allNotes.length) {
      if (allNotes.length != rows.length) {
        rows = [];
        for (const notes of allNotes) {
          rows.push(`${notes.notes}`);
        }
        this.setState({
          ...this.state,
          rows,
        });
      }
    }

    return (
      <div className="resourceContent">
        <div className="resourceInfo d-flex">
          <AutoCompleteComponent
            title="previous-medical"
            rows={rows}
            removeItem={this.removeRow}
            addItem={this.addRow}
            handleSearch={this.handleSearch}
            placeholder={'Add Information Note'}
            disableSearchIcon={true}
            selectItem={this.addNote}
          />
        </div>
      </div>
    );
  }

  handleSearch = (search) => {
    this.setInput(search);
  };
  addRow = () => {
    const {rows, searchInput} = this.state;
    const newRows = [...rows, {label: searchInput}];
    this.setRows(newRows);
  };
  removeRow = (item, rowIndex) => {
    this.props.deletePatientNote(rowIndex);
  };
  setRows = (rows) => {
    this.setState({
      ...this.state,
      rows,
    });
  };
  setInput = (searchInput) => {
    this.setState({
      ...this.state,
      searchInput,
    });
  };
  addNote = () => {
    const {searchInput} = this.state;
    const {resource} = this.props;
    this.props.addPatientNote(resource?.id, searchInput);
  };
}

const mapStateToProps = (state) => ({
  allNotes: state.patientSchedule.allNotes,
});

const mapDispatchToProps = {
  getPatientNotes,
  addPatientNote,
  deletePatientNote,
};

export default connect(mapStateToProps, mapDispatchToProps)(NotesComponent);
