import React from 'react';
import { connect } from 'react-redux';
import AutoCompleteComponent from '../../../common/components/AutoComplete';
import ToastrMsg from '../../../common/components/Toast';
import { getLocal } from "../../../helpers/Local";
import {
  addPatientNote,
  deletePatientNote, delete_patient_notes, getPatientNotes, get_patient_notes,
  save_note_as_pdf, save_patient_notes, setGlobalLoading, setPriorData
} from "../../../store/actions";
class NotesUltrasoundComponent extends React.Component {
  state = {
    type: '',
    msg: '',
    status: null,
    rows: [],
    rowID: [],
    searchInput: "",
    current_patient_id: getLocal("cvai-current-user"),
  };

  async componentDidMount() {
    this.setState({
      current_patient_id: getLocal("cvai-current-user")
    })
    const { customResource, resource } = this.props;
    if (customResource || resource) {
      this.props.setGlobalLoading(true);
      await this.props.get_patient_notes(customResource?.id || resource?.id);
      this.props.setGlobalLoading(false);
    }
  }

  componentWillReceiveProps(nextProps) {
    let { rows, rowID } = this.state;
    this.setState({ ...this.state });
    if (nextProps.patientsNote.length) {
      if (nextProps.patientsNote.length != rows.length) {
        rows = [];
        rowID = [];
        for (const notes of nextProps.patientsNote) {
          rows.push({ note: `${notes?.notes}`, name: `${notes?.user?.name}` });
          rowID.push(`${notes?.id}`);
        }
        this.setState({
          ...this.state,
          rows: [...rows],
          rowID: [...rowID],
        });
      }
    } else {
      this.setState({
        rows: [],
        rowID: [],
      });
    }
  }

  render() {
    const { rows, isToaster, type, msg, status } = this.state;
    return (
      <div className="resourceContent">
        <div className="resourceInfo d-flex">
          <AutoCompleteComponent
            title="previous-medical"
            rows={rows}
            saveNoteAsPDF={this.saveNoteAsPDF}
            removeItem={this.removeRow}
            twoColum={true}
            isIntraOperative={true}
            keyLabel="note"
            keyType="name"
            addItem={this.addRow}
            handleSearch={this.handleSearch}
            placeholder={'Add Information Note'}
            disableSearchIcon={true}
            disableOptions={true}
            selectItem={this.addNote}
            minRows={this.props.minRows || 8}
          />
          {isToaster && <ToastrMsg type={type} msg={msg} status={status} />}
        </div>
      </div>
    );
  }

  handleSearch = (search) => {
    this.setInput(search);
  };
  addRow = () => {
    const { rows, searchInput } = this.state;
    const newRows = [...rows, { label: searchInput }];
    this.setRows(newRows);
  };
  saveNoteAsPDF = async (noteObj) => {
    const { customResource, resource } = this.props;
    const { note, name } = noteObj;
    this.props.setGlobalLoading(true);
    if (!note || !name) {
      this.setState({ rows: [], rowID: [], searchInput: '', loading: false });
    } else {
      const params = {
        patient_id: customResource?.id || resource?.id,
        note: `${note} - ${name}`,
      };
      const result = await this.props.save_note_as_pdf(params);
      if (result?.status === 'success') {
        this.setState({
          status: 'success',
          isToaster: true,
          type: 'Success!',
          msg: 'PDF successfully generated.',
        });
      } else {
        this.setState({
          status: 'failed',
          isToaster: true,
          type: 'Failed!',
          msg: 'Failed to generate PDF.',
        });
      }
      setTimeout(() => {
        this.setState({
          status: null,
          isToaster: false,
          type: '',
          msg: '',
        });
      }, 2000);
    }
    this.props.setGlobalLoading(false);
  };
  removeRow = async (item, rowIndex) => {
    const { customResource, resource } = this.props;
    if (!this.state.rowID[rowIndex]) {
      this.setState({ rows: [], rowID: [], searchInput: '' });
      this.props.setGlobalLoading(false);
    } else {
      await this.props.delete_patient_notes(this.state.rowID[rowIndex]);
      await this.props.get_patient_notes(customResource?.id || resource?.id);
    }
  };
  setRows = (rows) => {
    this.setState({
      ...this.state,
      rows,
    });
  };
  setInput = (searchInput) => {
    this.setState({
      ...this.state,
      searchInput,
    });
  };
  addNote = async () => {
    const { searchInput } = this.state;
    const { resource, customResource } = this.props;
    if (customResource || resource) {
      const formData = new FormData();
      formData.append('notes', searchInput);
      formData.append('patient_id', customResource?.id || resource?.id);
      formData.append('user_id', this.state.current_patient_id);
      await this.props.save_patient_notes(formData);
      await this.props.get_patient_notes(customResource?.id || resource?.id);
      await this.props.setPriorData('loadingResource', false);
    }
  };
}

const mapStateToProps = (state) => ({
  allNotes: state.patientSchedule.allNotes,
  loadingNotes: state.newPatient.loadingNotes,
  resource: state.newPatient.resource,
  patientsNote: state.newPatient.patientsNote,
});

const mapDispatchToProps = {
  getPatientNotes,
  addPatientNote,
  deletePatientNote,
  save_patient_notes,
  get_patient_notes,
  save_note_as_pdf,
  delete_patient_notes,
  setPriorData,
  setGlobalLoading,
};

export default connect(mapStateToProps, mapDispatchToProps)(NotesUltrasoundComponent);
